import React from 'react';
import PropTypes from 'prop-types';

// MUI
import {
  Paper,
  Typography,
  Button,
  Fade,
  TextField,
  Grid,
  Collapse,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

// Styled components
import {
  TitleStyle,
  MainPaperStyle,
  InputFieldSpacing,
  DateSelectorWrapper,
} from './EmailTheFuture.style';

import { Divider } from '../../../Divider/Divider';

export function EmailTheFuture({
  title = '',
  emailVal = '',
  textVal = '',
  activeButton = '',
  dateVal = '',
  errors = {},
  nextFunc = () => {},
  validator = () => {},
  setEmailVal = () => {},
  setTextVal = () => {},
  setDateVal = () => {},
  ...props
}) {
  return (
    <Fade in timeout={200}>
      <Paper style={MainPaperStyle} {...props}>
        <Typography style={TitleStyle} variant="h4" color="primary">
          {title}
        </Typography>
        <InputFieldSpacing>
          <TextField
            fullWidth
            variant="outlined"
            id="emailInput"
            label="Email Address"
            type="text"
            name="email"
            value={emailVal}
            placeholder="Enter their email here..."
            onChange={e => setEmailVal(e.target.value)}
            onBlur={e => validator(e)}
            error={errors?.submitErrors?.email || false}
            helperText={errors?.submitErrors?.email?.[0] || ' '}
          />
        </InputFieldSpacing>
        <InputFieldSpacing>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            id="textInput"
            label="Your Message"
            type="text"
            name="text"
            value={textVal}
            placeholder="Enter your message here..."
            onChange={e => setTextVal(e.target.value)}
            onBlur={e => validator(e)}
            error={errors?.submitErrors?.text || false}
            helperText={errors?.submitErrors?.text?.[0] || ' '}
          />
        </InputFieldSpacing>
        <Divider logo />
        <DateSelectorWrapper>
          <Typography align="left" gutterBottom>
            When would you like this email to be sent?
          </Typography>
          <Grid container spacing={1}>
            {[
              { val: 1, dur: 'week' },
              { val: 6, dur: 'month' },
              { val: 1, dur: 'year' },
              { val: 5, dur: 'year' },
            ].map(({ val, dur }) => (
              <Grid item xs={6} sm={3}>
                <Button
                  variant={
                    activeButton?.val === val && activeButton?.dur === dur
                      ? 'contained'
                      : 'outlined'
                  }
                  color="primary"
                  style={{ width: '100%', height: '100%' }}
                  onClick={() => setDateVal({ val, dur })}
                >
                  {val} {`${dur}${val > 1 ? 's' : ''}`}
                </Button>
              </Grid>
            ))}
            <span
              style={{
                color: 'hsl(0, 92%, 33%)',
                fontSize: '0.8rem',
                fontWeight: '400',
                letterSpacing: '0.03333em',
                fontFamily: 'Maven Pro, Helvetica, Arial, sans-serif',
              }}
            >
              {errors?.submitErrors?.dateToSend?.[0] || ''}
            </span>
            <Collapse in={dateVal !== ''} style={{ width: '100%' }}>
              <div className="dateDisplay">
                <Typography>
                  Delivery date: {new Date(dateVal).toDateString()}
                </Typography>
              </div>
            </Collapse>
          </Grid>
        </DateSelectorWrapper>

        <Button
          onClick={() => nextFunc()}
          color="primary"
          size="large"
          fullWidth
          variant="contained"
        >
          Send&nbsp;&nbsp;
          <SendIcon />
        </Button>
      </Paper>
    </Fade>
  );
}

EmailTheFuture.propTypes = {
  /**
   * What should the title of this page be?
   */
  title: PropTypes.string,
  /**
   * What is the recepient's email address?
   */
  emailVal: PropTypes.string,
  /**
   * What is the body of the email?
   */
  textVal: PropTypes.string,
  /**
   * When should this email be scheduled for?
   */
  dateVal: PropTypes.instanceOf(Date),
  /**
   * Please supply a field validation function
   */
  validator: PropTypes.func,
  /**
   * Please supply an object which contains validation errors (if any)
   */
  errors: PropTypes.shape({ errors: PropTypes.array }),
  /**
   * Please supply a function for advancing to the next step
   */
  nextFunc: PropTypes.func,
};

EmailTheFuture.defaultProps = {
  title: 'Email The Future',
  emailVal: '',
  textVal: '',
  dateVal: '',
  errors: {},
  nextFunc: () => {},
  validator: () => {},
};
