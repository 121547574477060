import React from 'react';
import * as S from './Button.style';

import Spinner from './Spinner';

const SmallSpinner = () => <Spinner width="10" height="10" />;

const Button = props => {
  const { 
    text, 
    isPrimaryButton, 
    isSecondaryButton,
    isUnderlinedButton, 
    isSuccessButton, 
    isLoading, 
    isWarningButton, 
    isAcceptButton, 
    inviteSentButton, 
    isCommentWarningButton, 
    children 
  } = props;

  if (isPrimaryButton)
    return ( 
      <S.IsPrimaryButton {...props}>
        {isLoading && <SmallSpinner />}
        {text || children}
      </S.IsPrimaryButton>
    );

    if (isWarningButton)
    return ( 
      <S.IsWarningButton {...props}>
        {isLoading && <SmallSpinner />}
        {text || children}
      </S.IsWarningButton>
    );

    if (inviteSentButton)
    return (
      <S.inviteSentButton {...props}>
        {isLoading && <SmallSpinner />}
        {text || children}
      </S.inviteSentButton>
    );

    if (isSuccessButton)
    return (
      <S.IsSuccessButton {...props}>
        {isLoading && <SmallSpinner />}
        {text || children}
      </S.IsSuccessButton>
    );

    if (isSecondaryButton)
    return (
      <S.IsSecondaryButton {...props}>
        {isLoading && <SmallSpinner />}
        {text || children}
      </S.IsSecondaryButton>
    );

    if (isUnderlinedButton)
    return (
      <S.IsUnderlinedButton {...props}>
        {isLoading && <SmallSpinner />}
        {text || children}
      </S.IsUnderlinedButton>
    );

    if (isWarningButton)
    return (
      <S.IsWarningButton {...props}>
        {isLoading && <SmallSpinner />}
        {text || children}
      </S.IsWarningButton>
    );

    if (isAcceptButton)
    return (
      <S.IsAcceptButton {...props}>
        {isLoading && <SmallSpinner />}
        {text || children}
      </S.IsAcceptButton>
    );

    if (isCommentWarningButton)
    return (
      <S.IsCommentWarningButton {...props}>
        {isLoading && <SmallSpinner />}
        {text || children}
      </S.IsCommentWarningButton>
    )

  return (
    <S.Button {...props}>
      {isLoading && <SmallSpinner />}
      {text || children}
    </S.Button>
  );
};

export default Button;
