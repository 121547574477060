import styled from 'styled-components';

import { breakpoints } from '../../../constants/breakPoints';
import { colors } from '../../../styles/theme';

export const Main = styled.main`
  margin: 4rem auto !important;
  max-width: 100rem;
  margin: 0 auto;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  form {
    display: flex;
    align-items: center;
    flex-direction: column;

    label {
      width: 100%;
    }
  }
  h1 {
    color: ${colors.mainGreen};
  }
  p,
  h2 {
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }
  @media ${breakpoints.mobileM} {
    margin: 30px 2rem 5rem 2rem;
  }

  @media ${breakpoints.mobileL} {
    margin: 30px 2rem 5rem 2rem;
  }

  @media ${breakpoints.laptop} {
    padding: 0rem 2rem 5rem 23rem;
  }

  & > * {
    margin: 0.5rem 0;
  }
  .StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;

export const PinkHeader = styled.h2`
  padding-top: 1.5rem;
  color: ${colors.pink};
  text-transform: none;
  font-size: 1.7;
`;

export const Wrapper = styled.section`
  text-align: center;
`;

export const FeaturesWrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3rem;
`;

export const Feature = styled.div`
  width: 20rem;
  h3 {
    white-space: 0px;
    color: ${colors.pink};
    font-size: 1.3rem;
    text-transform: none;
    font-weight: bold;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  border: 1px dashed #eaeaea;
  min-height: 3rem;
  width: 100%;
`;

export const PremiumWrapper = styled.form`
  width: 100%;
  max-width: 700px;
  margin: 0 auto !important;

  /* & > * {
    margin: 0.5rem 0;
  } */
  .StripeElement {
    height: 40px;
    padding: 10px 12px;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;

export const FooterButton = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  width: inherit;
`;

export const ParagraphWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
`;

export const Icon = styled.img`
  width: 40px;
  padding: 0px 10px;
`;

export const Tip = styled.div`
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
  position: absolute;
  right: -30px;
  cursor: pointer;
  border: black 1px solid;
  border-radius: 50%;
  padding: 3px;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;

  span {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 105%;
  }

  &:hover span {
    visibility: visible;
  }
`;

export const InputCardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
`;

export const ErrorText = styled.p`
  color: red;
`;
