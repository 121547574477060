// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// nanoid
import { nanoid } from 'nanoid';

// MUI
import {
  Paper,
  Typography,
  Button,
  Grid,
  Divider,
  TextField,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

// Styled Components
import { EditRelationshipStyles } from './EditRelationship.style';

import {
  friendRelationships,
  familyRelationships,
} from './DefinedRelationships';

export const EditRelationship = ({
  friend,
  updateFunc,
  cancelFunc,
  ...props
}) => {
  // Extract
  const { name, sharedWithEmail, relationships } = friend;
  const [checkboxes, setCheckboxes] = useState(relationships || {});
  const [nickname, setNickname] = useState(friend?.nickname);
  const [nicknameFocused, setNicknameFocused] = useState(false);

  const renderTitle = () => (
    <>
      <Typography variant="h4">
        Your relationship {name && name !== '' ? `with ${name}` : ''}
      </Typography>
      {sharedWithEmail && sharedWithEmail !== '' && (
        <Typography variant="body1">{sharedWithEmail}</Typography>
      )}
    </>
  );

  const renderNicknameSection = () => (
    <div className="groupContainer">
      <Typography variant="h6">
        {`${friend?.nickname ? 'Update' : 'Add'} Nickname`}
      </Typography>
      <br />
      <TextField
        fullWidth
        label={`${friend?.name ? `${friend?.name}'s` : ''} Nickname`}
        helperText={
          nicknameFocused
            ? `What do you call ${friend?.name ? friend?.name : 'them'}?`
            : ' '
        }
        variant="outlined"
        className="addNicknameInputField"
        value={nickname}
        onFocus={() => setNicknameFocused(true)}
        onBlur={() => setNicknameFocused(false)}
        onChange={e => setNickname(e.target.value)}
      >
        Enter a name
      </TextField>
    </div>
  );

  const toggleCheckbox = e => {
    setCheckboxes({ ...checkboxes, [e.target.name]: e.target.checked });
  };

  const renderChecklist = ({ title, array }) => (
    <div className="checkboxesGroup">
      <FormLabel component="legend" className="groupTitle">
        {title}
      </FormLabel>
      <Divider />
      <FormGroup>
        {array.map(relationship => (
          <FormControlLabel
            key={nanoid()}
            className={checkboxes[relationship] ? 'checked' : 'unchecked'}
            control={
              <Checkbox
                checked={checkboxes?.[relationship]}
                onChange={toggleCheckbox}
                name={relationship}
              />
            }
            label={relationship}
          />
        ))}
      </FormGroup>
    </div>
  );

  const noChangesMade = () =>
    checkboxes === relationships && friend?.nickname === nickname;

  return (
    <EditRelationshipStyles>
      <Paper className="paperStyles" {...props}>
        {renderTitle()}
        {renderNicknameSection()}
        <div className="groupContainer">
          <Typography variant="h6" gutterBottom>
            {relationships ? 'Update Relationships' : 'Add Relationships'}
          </Typography>
          <div className="scrollContainer">
            {renderChecklist({ title: 'Friends', array: friendRelationships })}
            {renderChecklist({ title: 'Family', array: familyRelationships })}
          </div>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              disabled={noChangesMade()}
              variant="contained"
              color="primary"
              onClick={() =>
                updateFunc({
                  ...friend,
                  relationships: checkboxes,
                  nickname,
                })
              }
            >
              Update
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button fullWidth variant="contained" onClick={cancelFunc}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </EditRelationshipStyles>
  );
};

EditRelationship.propTypes = {
  /**
   * Please provide a `friend` object, containing an `id`, and optionally containing `email, name, nickname` and `relationships`
   */
  friend: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    nickname: PropTypes.string,
    relationships: PropTypes.instanceOf(Map),
  }),
  /**
   * Provide a function to update the relationship after changes have been made.
   */
  updateFunc: PropTypes.func,
  /**
   * Provide a function to cancel the changes and close the modal / page.
   */
  cancelFunc: PropTypes.func,
};

EditRelationship.defaultProps = {
  friend: {},
  updateFunc: () => {},
  cancelFunc: () => {},
};
