// React
import React from 'react';
import PropTypes from 'prop-types';

// MUI
import { LinearProgress } from '@material-ui/core';

// Dependencies - Social Media Sharing
import { FacebookShareButton, WhatsappShareButton } from 'react-share';

// Icons
import { FaFacebook } from 'react-icons/fa';
import { ImWhatsapp } from 'react-icons/im';

// Styled Components
import { SocialMediaButtonsStyles } from './SocialMediaButtons.style';

export const SocialMediaButtons = ({ shareLink, messageBody, ...props }) => (
  <SocialMediaButtonsStyles {...props}>
    <div className="buttonsWrapper">
      <div className="button">
        <FacebookShareButton url={shareLink} quote={messageBody}>
          <div className="icon" style={{ backgroundColor: '#E8F3FE' }}>
            {shareLink ? (
              <FaFacebook className="fb" />
            ) : (
              <LinearProgress style={{ width: '8rem' }} color="secondary" />
            )}
          </div>
        </FacebookShareButton>
        <p>Facebook</p>
      </div>

      <div className="button">
        <WhatsappShareButton url={shareLink} title={messageBody} separator=": ">
          <div className="icon" style={{ backgroundColor: '#ECFAF1' }}>
            {shareLink ? (
              <ImWhatsapp className="wa" />
            ) : (
              <LinearProgress style={{ width: '8rem' }} color="secondary" />
            )}
          </div>
        </WhatsappShareButton>
        <p>Whatsapp</p>
      </div>
    </div>
  </SocialMediaButtonsStyles>
);

SocialMediaButtons.propTypes = {
  /**
   * Please provide the link you would like to share
   */
  shareLink: PropTypes.string,
  /**
   * Please provide the body of the message to appear when shared
   */
  messageBody: PropTypes.string,
};

SocialMediaButtons.defaultProps = {
  shareLink: undefined,
  messageBody: undefined,
};
