import React from 'react';

const RightArrow = props => (
  <svg
    width={props.height}
    height={props.width}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.32812 3.0625L11.2656 7L7.32812 10.9375"
      stroke={props.color || 'currentColor'}
      strokeWidth="1.3125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7188 7H2.73438"
      stroke={props.color || 'currentColor'}
      strokeWidth="1.3125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RightArrow;
