import styled, {keyframes} from 'styled-components';

const spin = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const PageBackground = styled.main`
  min-height: 100vh;
  background: linear-gradient(0deg, var(--primary-navy), var(--secondary-blue), var(--secondary-aqua)) !important; 
`;

export const StepsWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
`;
