export const TitleStyle = {
  lineHeight: 1.25,
  paddingBottom: '1rem',
};

export const SubtitleStyle = {
  lineHeight: 1.25,
  marginBottom: '1.5rem',
};

export const MainPaperStyle = {
  padding: '2rem',
  margin: '0.5rem auto 3rem',
  textAlign: 'center',
  maxWidth: '600px'
};

export const ImageButtonStyle = {
  margin: '1rem',
};
