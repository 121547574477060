/* eslint-disable */
import React, { Component } from "react";
import {
  MEMORIES_URL,
  ONBOARDING_FLOW,
  FRIENDS_AND_FAMILY_URL,
  MY_FRIEND_MEMORIES,
} from "../../../constants/constantUrls";
import { NavLink } from "react-router-dom";
import history from "../../../history";
import * as S from "../Chat/style";
import axios from "axios";
// MUI Components
import {
  Button,
  Paper,
  Grid,
  Typography,
  Modal,
  Backdrop,
  ButtonBase,
  Avatar,
  Divider,
  TextField,
} from "@material-ui/core";

// MUI Icons
import MemoryIcon from "@material-ui/icons/Category";
import Plus from "@material-ui/icons/Add";
import MilestoneIcon from "@material-ui/icons/Star";
import Photos from "@material-ui/icons/PhotoCamera";
import Groups from "@material-ui/icons/People";
import Friends from "@material-ui/icons/EmojiPeople";
import Profile from "@material-ui/icons/Face";
import Stories from "@material-ui/icons/MenuBook";
import Camera from "@material-ui/icons/PhotoCamera";
import Left from "@material-ui/icons/ArrowLeft";
import Right from "@material-ui/icons/ArrowRight";
import AddIcon from "@material-ui/icons/Add";
import Email from "@material-ui/icons/MailOutline";
import Social from "@material-ui/icons/WhatsApp";
import Link from "@material-ui/icons/Link";

import * as C from "../../../constants/constantUrls";
import * as yup from "yup";

// Image Placeholders
import imagePlaceHolder from "../../../assets/person-placeholder.jpg";

import Confetti from "react-confetti";
import Joyride from "react-joyride";
import { sizeNum } from "../../../constants/breakPoints";

import { PaperStyle, ProgressWrapper } from "./style";
import { CircularProgressbar } from "react-circular-progressbar";
import { FaCheckCircle } from "react-icons/fa";
import CallToActionButton from "../../Common/GroupComponents/Buttons/CallToActionButton";
import happy from "../../../assets/onboarding/happiness.png";
import nostalgia from "../../../assets/onboarding/nostalgia.png";
import critters from "../../../assets/onboarding/critters.png";
import advice from "../../../assets/onboarding/advice.png";
import logo from "../../../assets/onboarding/logo-white.png";
import group70s from "../../../assets/70s.png";
import group80s from "../../../assets/80s.png";
import group90s from "../../../assets/90s.png";
import groupPets from "../../../assets/dave.jpeg";
import GroupDash from "../Groups/GroupDashboard";

// Friends' activity
import { FriendsActivity } from "../../../NewComponents/Dashboard/Blocks/FriendsActivity/FriendsActivity";
// Invite Friends
import { InviteFriends } from "../../../NewComponents/Dashboard/Blocks/InviteFriends/InviteFriends";
import FriendModalContent from "../../Common/FriendModal";

// Milestone Memories Modal
import MilestoneMemoriesModal from "../MilestoneMemoriesPage";
// New Timeline
import { NewTimeline } from "../../../NewComponents/Dashboard/Blocks/NewTimeline/NewTimeline";
import { RemainingAllowance } from "../../../NewComponents/Dashboard/Blocks/NewTimeline/RemainingAllowance/RemainingAllowance";
// Features
import { CurrentFeatures as Features } from "../../../NewComponents/Dashboard/Blocks/Features/Features.stories";
import LoadingAnimation from "../../Common/Animations/Loading";
import PresentIcon from "@material-ui/icons/CardGiftcard";
import iternalBoxWithBonus from "../../../NewComponents/Pages/ClaimBonus/icons/iternal_box.svg";
import ViewGroupButton from "../../Common/GroupComponents/Buttons/ViewGroupButton";
import { PromptGenerator } from "../../../NewComponents/Pages/CreateMemory/PromptGenerator/PromptGenerator";

// API
import {
  linkTokens,
  memories,
  milestones,
  prompts,
  users,
  permissionAccess,
  accountChecklist,
  competitions,
} from "../../../api";

import PromptButtons from "../../Common/PromptButtons/index";
import AlbumButton from "../../../assets/dashboard/albums-button.png";
import GroupButton from "../../../assets/dashboard/groups-button.png";
import FriendsList from "../../Common/Tables/FriendsList";

// Icons
import { FaRegQuestionCircle } from "react-icons/fa";

// Swal
import Swal from "sweetalert2";

import { ShareLinkWrapper } from "../../Common/Inputs";
import ReactGA from "react-ga";

import { API_PERMISSION_ACCESS_EMAIL } from "../../../constants/apiRoutes";

const emailSchema = yup
  .array(
    yup
      .string()
      .email("please enter valid email")
      .required("required")
  )
  .max(5, "Sorry, you can only add up to 5 emails at a time");

class Dashboard extends Component {
  state = {
    show: false,
    isBoxVisible: true,
    memories: undefined,
    milestones: [],
    prompts: [],
    friendsList: [],
    friendsActivity: [],
    userImage: {
      imagePath: "",
    },
    emailInput: "",
    userName: "",
    userId: "",
    hometown: "",
    isPremium: false,
    extraMemories: false,
    shareLink: "",
    gatherLink: "",
    friendsImages: [],
    friendsMemories: [],
    permissionAccessEmails: [],
    showFriendsModal: false,
    showEmailModal: false,
    showSocialModal: false,
    showLinkModal: false,
    errors: {},
    isLoading: true,
    isShowing: false,
    accountLevel: -1,
    checklist: [
      {
        name: 1,
        value: "1. Upload a profile picture",
        checked: false,
        link: "/basics",
      },
      {
        name: 2,
        value: "2. Create a video memory",
        checked: false,
        link: "/create-memory",
      },
      {
        name: 3,
        value: "3. Tag 3 friends in your memories",
        checked: false,
        link: "/create-memory",
      },
    ],
    checklistStageTwo: [
      {
        name: 1,
        value: "1. Create 15 memories",
        checked: false,
        link: "/create-memory",
      },
      {
        name: 2,
        value: "2. Add 8 friends",
        checked: false,
        link: "/share-memories",
      },
    ],
    confetti: false,
    run: false,
    disableBeacon: false,
    joyrideSteps: [
      {
        target: "#timeline",
        content:
          "This is your personalised timeline - you can view all of your stories here!",
        disableBeacon: true,
        floaterProps: { disableAnimation: true },
      },
      {
        target: "#friends",
        content:
          "This is where you can find your Iternal friends; why not invite someone so you can view their stories as well?",
        disableBeacon: true,
        floaterProps: { disableAnimation: true },
      },
      {
        target: "#prompt",
        content:
          "Not sure what to write? When you select a Category Button, you'll be given a set of questions about that subject that you may answer in order to complete your autobiography.",
        disableBeacon: true,
        floaterProps: { disableAnimation: true },
      },
      {
        target: "#photos",
        content:
          "You can make photo albums here with all of your favorite pictures. You can invite others to join in and share their own photos",
        disableBeacon: true,
        floaterProps: { disableAnimation: true },
      },
      {
        target: "#groups",
        content:
          "Here you can create groups for your family, friends, special events, sports teams, community groups, anything you wish....",
        disableBeacon: true,
        floaterProps: { disableAnimation: true },
      },
      // {
      //   target: "#friends-activity",
      //   content: "This is where you can see your friends' latest memories.",
      //   disableBeacon: true,
      //   floaterProps: { disableAnimation: true },
      // },
      // {
      //   target: "#stages",
      //   content:
      //     "Share this link to get your loved ones to leave memories on your timeline.",
      //   disableBeacon: true,
      //   floaterProps: { disableAnimation: true },
      // },
      // {
      //   target: "#features",
      //   content:
      //     "Rediscover forgotten memories and preserve them with our ever-growing list of features!",
      //   disableBeacon: true,
      //   floaterProps: { disableAnimation: true },
      // },
      // {
      //   target: "#invite-friends",
      //   content:
      //     "Add your friends email addresses here so they can view your memories.",
      //   disableBeacon: true,
      //   floaterProps: { disableAnimation: true },
      // },
    ],
    highlightedElement: 1,
    milestoneOpen: false,
    groups: [],
    prompt: {},
  };

  async componentDidMount() {
    this.loadData();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  async loadData() {
    await this.getPromptData();
    await this.getMilestoneData();
    await this.getMemoryData();
    await this.getUserData();
    await this.getFriendsList();
    // await this.fetchLinkToken();
    // await this.fetchPermissionAccess();
    // await this.getReferralCode();
    await this.getUserGroups();
    this.setState({ isLoading: false });
  }

  async getUserGroups() {
    const { data } = await axios.get("/api/groups");
    this.setState({ groups: data });
  }

  async getReferralCode() {
    if (this.props.location?.state?.referralCode) {
      const competition = await competitions.getCompetitionByCode({
        competitionCode: this.props.location?.state?.referralCode,
      });
      if (competition && competition.data !== null) {
        // handleChangeState({ ...data, isLoggedIn: true, competition: competition });
        // this.setState({ isLoading: false });
        // return history.push({
        //   pathname: ONBOARDING_FLOW,
        //   state: {
        //     competition: competition,
        //   },
        // });
      }
      const referredByUser = await users.getNameAndIdFromLinkToken({
        shortId: this.props.location?.state?.referralCode,
      });
      if (referredByUser && referredByUser.data !== null) {
        const {
          data,
          error,
        } = await permissionAccess.getAllTakenPermissionAccess({
          options: { redirectToRelatedPage: true },
        });
        const filteredFriends = data.filter(
          (d) => d?.owner?._id === referredByUser?.data?.userId
        );
        if (filteredFriends.length === 0) {
          const permissionAccess = await axios.post(
            API_PERMISSION_ACCESS_EMAIL,
            {
              sharedWithEmail: this.props.email,
              sharedWithId: this.props.id,
              owner: referredByUser.data.userId,
            }
          );
          // handleChangeState({ ...data, isLoggedIn: true });
          // this.setState({ isLoading: false });
          // return history.push({
          //   pathname: ONBOARDING_FLOW,
          //   state: { isReferral: !!shortId, shortId },
          // });
          if (permissionAccess) {
            Swal.fire({
              type: "success",
              title: `You and ${referredByUser.data.name} are friends!`,
            });
          }
        }
      }
    }
  }

  async getPromptData() {
    const { data, error } = await prompts.getMemoryPrompts({});
    this.setState({
      prompts: data,
    });
  }

  async getMilestoneData() {
    const { data, error } = await milestones.getUserMilestones({});
    this.setState({
      milestones: data,
    });
  }

  async getMemoryData() {
    const { data, error } = await memories.getAll({});
    if (!error) {
      this.setState({
        memories: data,
      });
    }
  }

  async getUserData() {
    const { data, error } = await users.getUserBasicProfile();
    if (!error && data) {
      let { name, hometown, image } = data;
      if (!name) {
        history.push(ONBOARDING_FLOW);
      }
      if (!image || Object.keys(image).length === 0) {
        image = {};
        image.pathname = imagePlaceHolder;
      }
      this.setState({
        userName: name,
        userImage: image,
        hometown,
      });
    }
    const { data: userData } = await users.getUserInfoFromToken();
    if (userData && userData.isPremium) {
      this.setState({
        isPremium: userData.isPremium,
      });
    }
    if (userData && userData?.extraMemories) {
      this.setState({
        extraMemories: userData?.extraMemories ? userData.extraMemories : 0,
      });
    }
  }

  async getFriendsList() {
    const { data, error } = await permissionAccess.getAllTakenPermissionAccess({
      options: { redirectToRelatedPage: true },
    });

    if (!error) {
      const friendsImages = this.friendsRecentActivity(data);
      const friendsMemories = this.getAllFriendsMemories(data);
      const friendsActivity = this.sortMemories(friendsMemories);
      this.setState({
        friendsList: data,
        friendsImages,
        friendsActivity,
      });
    }
  }

  friendsRecentActivity(friendsList) {
    const friendsImages = [];
    friendsList.map(function(item) {
      if (item?.owner?.abouts[0]?.image) {
        let { image } = item.owner.abouts[0];
        if (!image || Object.keys(image).length === 0) {
          image = {};
          image.imagePath = imagePlaceHolder;
        }
        friendsImages.push(image);
      } else {
        const image = { imagePath: imagePlaceHolder };
        friendsImages.push(image);
      }
      return item;
    });
    return friendsImages;
  }

  getAllFriendsMemories(friendsList) {
    const friendsMemories = [];
    friendsList.map(function(item) {
      const { memories, name } = item.owner;
      const { shortId } = item.owner.linktoken;
      let image = {};
      if (item?.owner?.abouts[0]?.image) {
        image = item.owner.abouts[0]?.image;
        if (!image || Object.keys(image).length === 0) {
          image = {};
          image.imagePath = imagePlaceHolder;
        }
      } else {
        image = { imagePath: imagePlaceHolder };
      }
      memories?.map(function(memory) {
        const data = {
          name,
          memory,
          image,
          shortId,
        };
        friendsMemories.push(data);
        return memory;
      });
      return item;
    });
    return friendsMemories;
  }

  sortMemories(activity) {
    activity.sort(function(a, b) {
      if (a.memory.createdAt > b.memory.createdAt) return -1;
      if (a.memory.createdAt < b.memory.createdAt) return 1;
      return 0;
    });
    return activity;
  }

  async fetchLinkToken() {
    const {
      data,
      error,
    } = await linkTokens.getActiveLinkTokenForLoggedInUser();
    if (!error) {
      this.setState({
        shareLink: `${window.location.origin}/share/${data.shortId}`,
        gatherLink: `${window.location.origin}/create/${data.shortId}`,
      });
    }
  }

  splitEmailsList(emailsAsString) {
    // split on "," & ";" and " "
    const splittedEmails = emailsAsString.split(/[, ;]/g);
    return splittedEmails.filter((item) => !!item).map((item) => item.trim());
  }

  async fetchPermissionAccess() {
    const {
      data,
      error,
    } = await permissionAccess.getAllGivenPermissionAccess();
    if (!error) {
      const permissionAccessEmails = data.map((obj) => {
        return obj.sharedWithEmail;
      });
      this.setState({
        permissionAccessEmails,
      });
    }
  }

  async onClickCreateAccountChecklist() {
    const { error } = await accountChecklist.createAccountChecklist({
      accountChecklist: {
        level: 1,
      },
    });
    if (!error) {
      this.setState({
        accountLevel: 1,
        confetti: true,
      });
      setTimeout(() => {
        this.setState({
          confetti: false,
        });
      }, 6000);
    }
  }

  async onClickUpdateAccountChecklist(level) {
    const {
      data: accountChecklistData,
    } = await accountChecklist.getAccountChecklistById({
      id: this.state.userId,
    });

    const { error } = await accountChecklist.updateAccountChecklist({
      accountChecklist: {
        level,
        userId: this.state.userId,
        accountChecklistId: accountChecklistData.accountChecklistId,
      },
    });
    if (!error) {
      this.setState({
        accountLevel: level,
        confetti: true,
      });
      setTimeout(() => {
        this.setState({
          confetti: false,
        });
      }, 6000);
    }
  }

  toggleInfo = () => {
    this.setState({ show: !this.state.show });
  };

  toggleBox = () => {
    this.setState((prevState) => ({ isBoxVisible: !prevState.isBoxVisible }));
  };

  startJoyride = () => {
    this.setState({ run: true });
  };

  joyrideCallback = (data) => {
    const { status, action } = data;
    if (
      status.includes("skipped") ||
      status.includes("finished") ||
      action.includes("close")
    ) {
      this.setState({ run: false });
    }
  };

  handleMilestoneOpen = (milestone) => {
    this.setState({ milestoneOpen: milestone });
  };

  handleMilestoneClose = () => {
    this.setState({ milestoneOpen: false });
  };

  showFriendsModal = () => {
    this.setState({ showFriendsModal: true });
  };

  closeFriendsModal = () => {
    this.setState({ showFriendsModal: false });
  };

  showEmailModal = () => {
    this.setState({ showFriendsModal: false });
    this.setState({ showEmailModal: true });
  };

  closeEmailModal = () => {
    this.setState({ showEmailModal: false });
  };

  showSocialModal = () => {
    this.setState({ showFriendsModal: false });
    this.setState({ showSocialModal: true });
  };

  closeSocialModal = () => {
    this.setState({ showSocialModal: false });
  };

  showLinkModal = () => {
    this.setState({ showFriendsModal: false });
    this.setState({ showLinkModal: true });
  };

  closeLinkModal = () => {
    this.setState({ showLinkModal: false });
  };

  moveHighlightedElement = (val) => {
    // Update State
    const memoryIndex = this.state.highlightedElement + val;
    this.setState({ highlightedElement: memoryIndex });

    //FIXME: Scroll not working right
    // try {
    // // Get container
    // const timelineContainer = document.querySelector('#timelineWrapper');
    // // Get memory to highlight
    // const memoryToHighlight = document.querySelector(`#timelineMemory${memoryIndex}`);
    // // Get position of memory to highlight within container
    // const memoryPosition = memoryToHighlight.offsetTop;
    // // Scroll to memory
    // timelineContainer.scrollTop = memoryPosition - 250;}
    // catch(err) {
    // return null;
    // }
  };

  getRandomPrompt = () => {
    const randomPos = Math.floor(Math.random() * this.state.prompts.length);
    this.setState({ prompt: this.state.prompts[randomPos] });
  };

  render() {
    const { history } = this.props;

    const {
      memories,
      milestones,
      prompts,
      userName,
      userImage: { imagePath },
      friendsActivity,
      confetti,
      run,
      joyrideSteps,
      isPremium,
      milestoneOpen,
      highlightedElement,
      extraMemories,
      gatherLink,
      isLoading,
      groups,
      prompt,
      showFriendsModal,
      showEmailModal,
      showSocialModal,
      showLinkModal,
    } = this.state;
    let filteredMemories = [];
    let memoryLength = 0;
    if (memories) {
      filteredMemories = memories?.filter(
        (memory) => memory?.author && memory?.author?.name
      );
      memoryLength = memories?.length - filteredMemories.length;
    }
    const handleChange = (e) => {
      this.setState({ emailInput: e.target.value });
    };

    const RenderGroupListOrNothing = () => {
      if (groups.length > 0) {
        return (
          <Grid
            container
            spacing={2}
            style={{
              padding: "0rem",
              // height: window.innerWidth >= 1280 ? '70vh' : '30vh',
              // height: "25vh",
              overflowX: "",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
            }}
          >
            {groups.map((group) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={12}
                style={{
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <Paper
                  elevation={2}
                  onClick={() => history.push(`/group/${group.groupShortCode}`)}
                  style={{
                    padding: "0.5rem",
                    display: "flex",
                    justifyContent: "space-around",
                    width: "90px",
                    height: "90px",
                    flexDirection: "column",
                    background: "white",
                    borderRadius: "20px",

                    color: "#0A2156",
                  }}
                >
                  <Avatar
                    src={
                      group?.profilePicture?.imagePath
                        ? group.profilePicture.imagePath
                        : require("../../../assets/orange_logo.png")
                    }
                    alt="Your profile avatar"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "50px",
                      height: "50px",

                      margin: "0 auto",
                    }}
                  />

                  <Typography
                    color="primary"
                    variant="body2"
                    style={{
                      display: "block" /* or inline-block */,
                      textOverflow: "ellipsis",
                      wordWrap: "break-word",
                      overflow: "hidden",
                      maxHeight: "2.6rem",
                      lineHeight: "1.3rem",
                    }}
                  >
                    {group.groupName}
                  </Typography>

                  {/* <ViewGroupButton
                    title="View"
                    clickFunction={() =>
                      history.push(`/group/${group.groupShortCode}`)
                    }
                  /> */}
                </Paper>
              </Grid>
            ))}
          </Grid>
        );
      } else {
        return (
          <Grid
            container
            spacing={2}
            style={{
              padding: "0rem",
              // height: window.innerWidth >= 1280 ? '70vh' : '30vh',
              // height: "25vh",
              overflowX: "",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              marginTop: "2rem",
              marginBottom: "1rem",
            }}
          >
            <GroupDash />
          </Grid>
        );
      }
    };

    const handleClick = async () => {
      if (this.state.emailInput && this.state.emailInput.length > 0) {
        const splitEmail = this.splitEmailsList(this.state.emailInput);
        emailSchema
          .validate(splitEmail)
          .then(async () => {
            let duplicateEmail = false;
            splitEmail.map((email) => {
              duplicateEmail = this.state.permissionAccessEmails.includes(
                email
              );
              return email;
            });
            const ownEmail = splitEmail.includes(this.props.email);
            if (ownEmail) {
              return Swal.fire({
                title: "You can't add yourself!",
                timer: 3000,
                type: "warning",
              });
            }
            if (!duplicateEmail) {
              const { error } = await permissionAccess.create({
                sharedWithEmails: splitEmail,
              });
              if (!error) {
                this.fetchPermissionAccess();
                document.getElementById("emailInput").value = "";
                return Swal.fire({
                  title: "Email added",
                  timer: 3000,
                  type: "success",
                });
              }
            } else {
              return Swal.fire({
                title: "You have already added this email",
                timer: 3000,
                type: "error",
              });
            }
          })
          .catch((error) => {
            return Swal.fire({
              title: "Please enter a valid email address",
              timer: 3000,
              type: "error",
            });
          });
      } else {
        return Swal.fire({
          title: "Please enter an email address first",
          timer: 3000,
          type: "error",
        });
      }
    };
    const createLinkCopy = (
      <>
        Want to get your friends to leave their favourite stories of you on your
        timeline?
        <br />
        Click the button below to copy your invite link and share it with them.
      </>
    );

    const calculateAvailableMemories = () => {
      if (extraMemories === false) {
        return 20;
      }
      if (extraMemories === 0) {
        return 20;
      }
      if (extraMemories > 0) {
        return Math.max(20 + extraMemories);
      }
    };

    const friendScroll = (direction) => {
      const container = document.querySelector(".friend-images");
      const value = direction === "left" ? -76 : 76;
      container.scrollBy({
        left: value,
        behavior: "smooth",
      });
    };

    const categoryScroll = (direction) => {
      const container = document.querySelector(".prompt-badges");
      const value = direction === "left" ? -126 : 126;
      container.scrollBy({
        left: value,
        behavior: "smooth",
      });
    };

    if (isLoading) {
      return (
        <S.LoadWrapper>
          {/* <div style={{ 
            position: 'absolute',
            width: '300px',
            height: '200px',
            top: '30%',
            left: '50%',
            // margin: -100px 0 0 -150px,
            background: 'red'
           }}> */}
          <LoadingAnimation
            width={window.innerWidth < 600 ? window.innerWidth * 0.9 : 500}
            height={window.innerWidth < 600 ? window.innderWidth * 0.9 : 500}
          />
          {/* </div> */}
        </S.LoadWrapper>
      );
    } else {
      return (
        <S.Wrapper style={{ postion: "relative" }}>
          {/* INITIALISE JOYRIDE */}
          <Joyride
            callback={(e) => this.joyrideCallback(e)}
            steps={joyrideSteps}
            continuous={true}
            run={run}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            styles={{
              options: {
                primaryColor: "#0bafaa",
                textColor: "#222222",
                zIndex: 1000,
              },
            }}
          />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={milestoneOpen}
            onClose={this.handleMilestoneClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "1000",
            }}
          >
            <MilestoneMemoriesModal
              isModal
              milestone={milestoneOpen}
              getMilestonesFunc={() => this.getMilestoneData()}
              closeButtonFunc={this.handleMilestoneClose}
            />
          </Modal>

          {confetti && (
            <Confetti
              width={window.innerWidth}
              heigh={window.innerHeight}
              recycle={false}
              numberOfPieces={500}
            />
          )}

          {/* HEADER */}
          {/* <Grid container>
            <Grid 
              item 
              xs={12} sm={12} md={9}
              style={{
                marginTop: '-1rem'
              }}
            >
              <Typography variant="h3" gutterBottom style={{ textAlign: window.innerWidth > sizeNum.tabletXL ? 'left' : 'center', marginBottom: '1.5rem',  }}>
                {userName? `Hey ${userName}, Welcome Back` : `Welcome Back`}
              </Typography>
            </Grid>
            <Grid 
              item 
              xs={12} sm={12} md={3}
              style={{ 
                textAlign: window.innerWidth > sizeNum.tabletXL ? 'right' : 'center', 
                paddingTop: window.innerWidth > sizeNum.tabletXL ? '' : 'inherit', 
                paddingBottom: window.innerWidth > sizeNum.tabletXL ? '' : 'inherit',
                marginBottom: '1rem'
              }}
            >
            

            </Grid>
          </Grid> */}

          <Grid container spacing={2}>
            <Grid
              item
              sm={12}
              lg={8}
              style={{
                width: "100%",
                paddingLeft: window.innerWidth < 900 ? "0rem" : "4rem",
                paddingRight: window.innerWidth < 900 ? "0rem" : "4rem",
              }}
            >
              <Paper
                id="timeline"
                elevation={0}
                style={{
                  minHeight: "calc(100% - 65px)",
                  padding: "",
                  textAlign: "center",
                  background: "none",
                }}
              >
                <Grid
                  id="friends-mobile-div"
                  style={{
                    display: window.innerWidth < 1280 ? "flex" : "none",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    xs={12}
                    id="friend-images"
                    className="friend-images"
                    style={{
                      // justifyContent:'center',
                      width: "100%",
                      overflowX: "",
                      overflowY: "scroll",
                      display: "flex",
                      justifySelf: "center",
                      alignSelf: "center",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <div
                      // onClick={() => history.push(C.MY_FRIENDS_MEMORIES)}
                      onClick={() => this.showFriendsModal({})}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignContent: "center",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        padding: "0.5rem",
                        cursor: "pointer",
                      }}
                    >
                      <Avatar
                        style={{
                          width: "56px",
                          height: "56px",
                          backgroundColor: "#0000",
                          color: "var(--primary-gold)",
                          border: "2px dashed var(--primary-gold)",
                        }}
                      >
                        <span style={{ fontSize: "3rem", fontWeight: "bold" }}>
                          +
                        </span>
                      </Avatar>
                      <Typography
                        variant="body2"
                        style={{
                          color: "white",
                          textAlign: "center",
                          lineHeight: "1.5rem",
                          maxHeight: "3rem",
                        }}
                      >
                        Add Friend
                      </Typography>
                    </div>
                    {this.state.friendsList.map((item, index) => {
                      return (
                        <div
                          key={item?._id}
                          onClick={() =>
                            history.push(
                              `/share/${item?.owner?.linktoken?.shortId}`
                            )
                          }
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: "0.5rem",
                            cursor: "pointer",
                          }}
                        >
                          <Avatar
                            style={{ width: "60px", height: "60px" }}
                            src={item?.owner?.abouts[0]?.image?.imagePath}
                          >
                            {item?.owner?.name[0]}
                          </Avatar>
                          <Typography
                            variant="body2"
                            style={{
                              color: "white",
                              textAlign: "center",
                              lineHeight: "1.5rem",
                              maxHeight: "3rem",
                            }}
                          >
                            {item?.owner?.name}
                          </Typography>
                        </div>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {/* <Typography
                      variant="h4"
                      style={{
                        marginTop: window.innerWidth < 600 ? "1rem" : "0rem",
                        display: window.innerWidth < 1280 ? "none" : "block",
                      }}
                    > */}

                    {/* </Typography> */}
                    <Typography
                      variant="h4"
                      style={{
                        color: "white",
                        width: window.innerWidth < 1280 ? "100%" : "85%",
                        fontSize: window.innerWidth < 500 ? "2rem" : "2.5rem",
                        textAlign: "center",
                        marginTop: "1rem",
                      }}
                    >
                      My Stories
                    </Typography>
                    <FaRegQuestionCircle
                      style={{
                        cursor: "pointer",
                        color: "white",
                        fontSize: "2.5rem",
                        width: "15%",
                        display: window.innerWidth < 1280 ? "none" : "flex",
                      }}
                      onClick={this.startJoyride}
                    />
                  </Grid>
                </Grid>

                <NewTimeline
                  memories={memories}
                  milestones={milestones}
                  getMilestonesFunc={this.getMilestoneData}
                  prompts={prompts}
                  highlightedElement={highlightedElement}
                  viewingFriendsMemory={false}
                  profile={{ picture: imagePath, name: userName }}
                  editMilestoneFunc={this.handleMilestoneOpen}
                  id="timelineWrapper"
                  style={{
                    height: window.innerWidth < 1280 ? "70vh" : "85vh",
                    overflow: "auto",
                    position: "initial",
                    background: "none",
                    borderRadius: "1rem 1rem 0 0",
                  }}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <NavLink
                    to={{
                      pathname: C.PROMPTS_URL,
                      state: {
                        refreshNeeded: true,
                      },
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      fullWidth
                      style={{
                        fontWeight: "700",
                        marginTop: "1rem",
                        marginBottom: "2rem",
                        display: window.innerWidth > 1022 ? "none" : "flex",
                      }}
                    >
                      <MemoryIcon />
                      &nbsp;&nbsp;View Story Prompts
                      <br />
                    </Button>
                  </NavLink>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <Grid item xs={12} sm={6}>
                    <Button 
                      variant="contained" 
                      color="secondary" 
                      size="large"
                      fullWidth
                      onClick={() => this.handleMilestoneOpen({})}
                    >
                      <MilestoneIcon/>&nbsp;&nbsp;Add Milestone
                    </Button>
                  </Grid> */}
                </Grid>
              </Paper>
            </Grid>

            <Grid
              container
              xs={12}
              lg={4}
              spacing={3}
              style={{
                display: window.innerWidth < 1280 ? "none" : "flex",
                flexDirection: "row",
                alignContent: "space-around",
              }}
            >
              <Grid item xs={12} style={{}}>
                <Modal
                  open={showEmailModal}
                  onClose={this.closeEmailModal}
                  id="showEmailModal"
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                >
                  <Paper
                    style={{
                      borderRadius: "20px",
                      maxWidth: "530px",
                      minHeight: "298px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      padding: "2rem 2rem 1rem 2rem",
                      outline: "none",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color="primary"
                      style={{ marginBottom: "1rem" }}
                    >
                      Invite family and friends to view your stories
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ marginBottom: "1rem" }}
                    >
                      To allow your friends and family to view your shared
                      stories, please enter their email addresses.
                    </Typography>

                    <TextField
                      fullWidth
                      // value={email}
                      // onChange={(e) => setEmail(e.target.value)}
                      variant="outlined"
                      label="Your friends' email"
                      placeholder="Write your friends' email here..."
                      helperText="Tip: You can invite multiple friends' by seperating their emails with a comma"
                    />
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1rem",
                        marginBottom: "-1rem",
                      }}
                    >
                      <CallToActionButton title="Add Friends" />
                    </Grid>
                    {/* {errors?.uploaderEmail && (
                  <Typography
                    style={{
                      color: "var(--secondary-cherry)",
                      textAlign: "center",
                    }}
                  >
                    * Please enter a valid email
                  </Typography> */}
                  </Paper>
                </Modal>

                <Modal
                  open={showSocialModal}
                  onClose={this.closeSocialModal}
                  id="showSocialModal"
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                >
                  <Paper
                    style={{
                      borderRadius: "20px",
                      width: "472px",
                      height: "298px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",

                      outline: "none",
                    }}
                  >
                    Im the Social Modal
                  </Paper>
                </Modal>

                <Modal
                  open={showLinkModal}
                  onClose={this.closeLinkModal}
                  id="showLinkModal"
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                >
                  <Paper
                    style={{
                      borderRadius: "20px",
                      width: "472px",
                      height: "298px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",

                      outline: "none",
                    }}
                  >
                    Im the Link Modal
                  </Paper>
                </Modal>

                <Modal
                  open={showFriendsModal}
                  onClose={this.closeFriendsModal}
                  id="showFriendsModal"
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                >
                  <FriendModalContent
                    name={userName}
                    onClose={this.closeFriendsModal}
                  />
                </Modal>
                <Typography
                  gutterBottom
                  style={{
                    fontSize: "1.8rem",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Friends
                </Typography>
                <Grid
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <Grid
                    item
                    xs={1}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Left
                      onClick={() => friendScroll("left")}
                      color="secondary"
                      style={{
                        marginBottom: "0rem",
                        fontSize: window.innerWidth < 400 ? "5rem" : "6rem",
                        alignSelf: "center",
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                  <Grid
                    xs={8}
                    id="friend-images"
                    className="friend-images"
                    style={{
                      width: "100%",
                      overflowX: "",
                      overflowY: "scroll",
                      display: "flex",
                      justifySelf: "center",
                      alignSelf: "center",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <div
                      // onClick={() => history.push(C.MY_FRIENDS_MEMORIES)}
                      onClick={() => this.showFriendsModal({})}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignContent: "center",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        padding: "0.5rem",
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      <Avatar
                        style={{
                          width: "56px",
                          height: "56px",
                          backgroundColor: "#0000",
                          color: "var(--primary-gold)",
                          border: "2px dashed var(--primary-gold)",
                        }}
                      >
                        <span style={{ fontSize: "3rem", fontWeight: "bold" }}>
                          +
                        </span>
                      </Avatar>
                      <Typography
                        variant="body2"
                        style={{
                          color: "white",
                          textAlign: "center",
                          lineHeight: "1.5rem",
                          maxHeight: "3rem",
                        }}
                      >
                        Add Friend
                      </Typography>
                    </div>
                    {this.state.friendsList.map((item, index) => {
                      return (
                        <div
                          key={item?._id}
                          onClick={() =>
                            history.push(
                              `/share/${item?.owner?.linktoken?.shortId}`
                            )
                          }
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: "0.5rem",
                            cursor: "pointer",
                          }}
                        >
                          <Avatar
                            style={{ width: "60px", height: "60px" }}
                            src={item?.owner?.abouts[0]?.image?.imagePath}
                          >
                            {item?.owner?.name[0]}
                          </Avatar>
                          <Typography
                            variant="body2"
                            style={{
                              color: "white",
                              textAlign: "center",
                              lineHeight: "1.5rem",
                              maxHeight: "3rem",
                            }}
                          >
                            {item?.owner?.name}
                          </Typography>
                        </div>
                      );
                    })}
                  </Grid>
                  {/* <FriendsList
                    id="invite-friends"
                    history={history}
                    shareMemoriesLink={FRIENDS_AND_FAMILY_URL}
                    handleChange={handleChange}
                    handleClick={handleClick}
                    style={{ color: "white" }}
                  /> */}
                  <Grid
                    item
                    xs={1}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Right
                      color="secondary"
                      onClick={() => friendScroll("right")}
                      style={{
                        marginBottom: "0rem",
                        fontSize: window.innerWidth < 400 ? "5rem" : "6rem",
                        alignSelf: "center",
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                </Grid>

                <div
                  style={{ marginBottom: "2rem", marginTop: "1rem" }}
                  id="prompt"
                >
                  <Typography
                    gutterBottom
                    style={{
                      fontSize: "1.8rem",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    Story Prompts
                  </Typography>
                  <PromptButtons
                    scrollFunc={categoryScroll}
                    allPrompts={this.state.prompts}
                  />
                </div>

                <Grid
                  style={{
                    padding: "1rem",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <img
                    id="photos"
                    alt="Album Button"
                    onClick={() => history.push(C.ALBUMS_URL)}
                    src={AlbumButton}
                    style={{ width: "48%", cursor: "pointer" }}
                  />

                  <img
                    id="groups"
                    alt="Group Button"
                    onClick={() => history.push(C.GROUPS_URL)}
                    src={GroupButton}
                    style={{ width: "48%", cursor: "pointer" }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Paper
                  elevation={0}
                  style={{
                    background: "none",
                    position: "relative",
                    padding: "0rem",
                  }}
                >
                  <img
                    src={logo}
                    style={{
                      width: "95%",
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </S.Wrapper>
      );
    }
  }
}

export default Dashboard;
