// import styled from 'styled-components';
import { IternalTheme } from '../../../--Theme/IternalThemeLight';

export const TitleStyle = {
  lineHeight: 1.25,
};

export const SubtitleStyle = {
  lineHeight: 1.25,
  marginBottom: '1.5rem',
};

export const PerkIconStyle = {
  textAlign: 'center',
  width: '100%',
};

export const PerkTitleStyle = {
  marginBottom: '0.5rem',
  fontSize: '1.5rem',
  fontWeight: 'black',
};

export const PerkParagraphStyle = {
  color: IternalTheme.palette.common.slate3,
};

export const MainPaperStyle = {
  padding: '1.5rem',
  maxWidth: '80%',
  margin: '-2rem auto 3rem',
};

export const SubPaperStyle = {
  padding: '1.5rem',
  maxWidth: '80%',
  margin: '0.5rem auto 3rem',
};

export const SubPaperSmallStyle = {
  padding: '1.5rem',
  maxWidth: '80%',
  margin: '0.5rem auto 3rem',
};

export const SubPaperCenteredStyle = {
  textAlign: 'center',
  padding: '1.5rem',
  maxWidth: '80%',
  margin: '0.5rem auto 3rem',
};

export const PerkIconProps = {
  width: '100%',
  style: { fontSize: '6.5rem' },
  color: 'secondary',
};

export const UpgradeNowSection = {
  borderRadius: '0.5rem',
  padding: '1.25rem',
  color: 'black',
  backgroundColor: IternalTheme.palette.primary.light6,
  textAlign: 'center',
};
