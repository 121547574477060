import styled from 'styled-components';

export const Subtitle = styled.p`
  font-size: 1em;
  color: var(--neutral-slate);
  line-height: 1.4rem;
`;

export const PaperStyle = styled.div`
  padding: 2rem;
  text-align: center;

  @media (min-width: 1280px) {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;