import React, { useRef } from 'react';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import withDashedBorder from './withDashedBorder';

const titleSchema = yup
  .string()
  .required('A title is required')
  .min(3, 'Your title needs to be at least 3 characters long');

const Title = ({
  title,
  setTitle,
  setError,
  onFocusChange,
  error,
  helperText,
}) => {
  const validate = () => {
    onFocusChange(false);
    titleSchema
      .validate(title)
      .then(() => {
        setError('');
      })
      .catch(err => {
        setError(err.errors);
      });
  };

  const onChange = e => {
    setTitle(e.target.value);
    setError('');
  };

  const titleRef = useRef()

  return (
    <TextField
      ref={titleRef}
      label="Memory Title"
      placeholder="Write your title here..."
      variant="outlined"
      multiline
      value={title}
      onChange={onChange}
      onBlur={validate}
      onFocus={(e) => onFocusChange(e, titleRef)}
      style={{ width: '100%', scrollMargin: '11rem' }}
      error={error}
      helperText={helperText}
    />
  );
};

export default withDashedBorder(Title, 'title');
