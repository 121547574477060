import React, { useState, useEffect } from 'react';
import vmsg from 'vmsg';
import * as Sentry from '@sentry/browser';
import * as LogRocket from '../../../helpers/logRocket';
import Icon from '../Icon/Icon';
import MediaCapturer from 'react-multimedia-capture';
import Microphone from "@material-ui/icons/MicRounded";

import {
  FaImages,
  FaMicrophone,
  FaPencilAlt,
  FaTimesCircle,
  FaCamera,
  FaEdit,
  FaAngleDown,
  FaPlus,
  FaLightbulb,
  FaTimes
} from "react-icons/fa";

import {
  VoiceRecordingWrapper,
  VoiceIconWrapper,
  MessageDiv,
  AlternativeButton,
  AlternativeText,
  Message,
  AudioPlayer,
} from './style';
import { Typography } from '@material-ui/core';

const recorder = new vmsg.Recorder({
  wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
});

const Voice = ({
  voice,
  setVoice,
  setActiveInput,
  renderAlternativeChoice,
  setDescription,
  setVideo,
  tempVoice,
  setIsRecording,
  isRecording,
  setError: setParentError,
  isGroup = false,
  theme,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [recordingFile, setRecordingFile] = useState(null);
  const [error, setError] = useState(false);
  const [granted, setGranted] = useState('');
  const [rejectedReason, setRejectedReason] = useState('');
  const [recording, setRecording] = useState(false);
  useEffect(() => {
    if (voice && typeof voice === 'object') {
      setRecordingFile(URL.createObjectURL(voice));
    }
  }, [voice]);

  useEffect(
    () => () => {
      setIsLoading(false);
      setParentError('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const record = async () => {
    setIsLoading(true);

    // handle stop and set recording
    if (recorder && isRecording) {
      const blob = await recorder.stopRecording();
      setIsLoading(false);
      setIsRecording(false);
      setParentError('');

      if (blob && typeof blob === 'object') {
        setRecordingFile(URL.createObjectURL(blob));
        setVoice(blob);
      }
    } else {
      // handle recording
      try {
        await recorder.initAudio();
        await recorder.initWorker();
        setIsLoading(false);
        setIsRecording(true);
        setParentError('');
        recorder.startRecording();

        setRecordingFile(null);
        setVoice('');
      } catch (e) {
        setIsLoading(false);
        LogRocket.error('recording error', e);
        Sentry.captureException(e);
        setError(true);
      }
    }
  };

	const handleGranted = () => {
    setGranted(true);
    console.log(granted);
		console.log('Permission Granted!');
  }
  
	const handleDenied = (err) => {
    setRejectedReason(err.name);
    console.log(rejectedReason);
		console.log('Permission Denied!', err);
  }
  
	const handleStart = (stream) => {
		setRecording(true);

		console.log('Recording Started.');
  }
  
	const handleStop = (blob) => {
		setRecording(false);

		console.log('Recording Stopped.');
		downloadAudio(blob);
  }
  
	const handleError = (err) => {
		console.log(err);
  }
  
	const handleStreamClose = () => {
		setGranted(false);
  }
  
	const downloadAudio = (blob) => {
    setRecordingFile(URL.createObjectURL(blob));
    setVoice(blob);
	}

  const convertHSLToHSLA = (value) => {
    const sep = value.indexOf(",") > -1 ? "," : "";
    const hsl = value.substr(4).split(")")[0].split(sep);
    
    let h = hsl[0],
        s = hsl[1],
        l = hsl[2];
    
    const hsla = `hsla(${h} ${s} ${l} / 0.12)`;
    return (hsla)
  }

  /////////////
  if (isLoading) return <Message>Loading...</Message>;

  return (
    <VoiceRecordingWrapper>
      {/* RECORDING BUTTON RENDER */}

      {!error && !voice && !recordingFile ? (
        <>
          {navigator.userAgent.includes('Android') ? (
            <MediaCapturer
              constraints={{ audio: true }}
              mimeType="audio/webm"
              timeSlice={10}
              onGranted={handleGranted}
              onDenied={handleDenied}
              onStart={handleStart}
              onStop={handleStop}
              onError={handleError} 
              onStreamClosed={handleStreamClose}
              render={({ start, stop }) => 
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                  {recording ? (
                    <>
                      <div 
                        style={{
                          width: '6rem',
                          height: '6rem',
                          borderRadius: '50%',
                          padding: '1rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          backgroundColor:convertHSLToHSLA(!recording ? theme.palette.primary.light : 'hsl(0, 92%, 33%)'),
                          border: `solid 0.25rem ${!recording ? theme.palette.primary.main : 'hsla(0 92% 33% / 1)'}`,
                        }}
                        onClick={stop}
                      >
                        <FaMicrophone 
                          fill={'hsla(0 92% 33% / 1)'} 
                          height="100%" 
                          style={{ width: '50%', height: '50%', justifyContent: 'center' }} 
                        />
                      </div> 
                      <Message>Recording...</Message>
                      <Message>Click The Microphone To Stop Recording</Message>
                    </>
                  ) : (
                    <>
                      <div 
                        style={{
                          width: '6rem',
                          height: '6rem',
                          borderRadius: '50%',
                          padding: '1rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          backgroundColor:convertHSLToHSLA(!recording ? theme.palette.primary.light : 'hsl(0, 92%, 33%)'),
                          border: `solid 0.25rem ${!recording ? theme.palette.primary.main : 'hsla(0 92% 33% / 1)'}`,
                        }}
                        onClick={start}
                      >
                        <FaMicrophone 
                          fill={theme.palette.primary.dark} 
                          height="100%" 
                          style={{ width: '50%', height: '50%', justifyContent: 'center' }} 
                        />
                      </div> 
                      <Message>Click The Microphone To Start Recording</Message>
                    </>
                  )}
              </div>
            } />
          ) : (
            <>
              <div 
                style={{
                  width: '6rem',
                  height: '6rem',
                  borderRadius: '50%',
                  padding: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  backgroundColor:convertHSLToHSLA(!isRecording ? theme.palette.primary.light : 'hsl(0, 92%, 33%)'),
                  border: `solid 0.25rem ${!isRecording ? theme.palette.primary.main : 'hsla(0 92% 33% / 1)'}`,
                }}
                onClick={() => {
                  setVoice('');
                  setRecordingFile(null);
                  record();
                }}
              >
                {!isRecording ? (
                  <FaMicrophone 
                    color={theme.palette.primary.dark} 
                    height="100%" 
                    style={{ width: '50%', height: '50%', justifyContent: 'center' }}
                  />
                ) : (
                  <FaMicrophone 
                    fill={'hsla(0 92% 33% / 1)'} 
                    height="100%" 
                    style={{ width: '50%', height: '50%', justifyContent: 'center' }} 
                  />
                )}
              </div>
              <>
                {isRecording ? (
                  <>
                  <Message>Recording...</Message>
                  <Message style={{textAlign:"center"}}>Click The Microphone To Stop Recording</Message>
                  </>
                ) :(
                  <Message style={{textAlign:"center"}}>Click The Microphone To Start Recording</Message>
                )}
              </>
            </>
          )}

        </>
      ) : (
        <>
          {!error && (recordingFile || voice) && (
            <Typography variant="h5" style={{ textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>Great! Listen back to your memory below...</Typography>
          )}
        </>
      )}

      {/* AUDIO PLAYER RENDER */}
      {!error && !isRecording && (voice || recordingFile) && (
        <>
          <AudioPlayer
            controlsList="nodownload"
            src={
              recordingFile ||
              (tempVoice && tempVoice.length ? tempVoice : voice)
            }
            controls
          />
          {navigator.userAgent.includes('Android') ? (
            <AlternativeButton
              onClick={() => {
                setVoice('');
                setRecordingFile(null);
              }}
            >
              <FaMicrophone color={theme.palette.primary.dark} />
              <AlternativeText>Not Happy? Click To Record Again</AlternativeText>
            </AlternativeButton>
          ) : (
            <AlternativeButton
              onClick={() => {
                record();
              }}
            >
              <FaMicrophone color={theme.palette.primary.dark} />
              <AlternativeText>Not Happy? Click To Record Again</AlternativeText>
            </AlternativeButton>
          )}
          
        </>
      )}
      {error && (
        <>
          <Message>
            Ooops, there's an issue with recording. We've logged this and will
            work to fix it. Please use a different device to record or click
            below to write your memory.
          </Message>
        </>
      )}
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
      {!isRecording &&
        renderAlternativeChoice(
          'pencil',
          'Write your memory instead',
          () => {
            if (isRecording) {
              setIsRecording(false);
              record();
            }
            setVoice(null);
            setDescription('');
            setVideo(null);
            setActiveInput('description');
          },
        )}
      {!isRecording && !isGroup &&
        renderAlternativeChoice(
          'camera',
          'Record using video instead',
          () => {
            if (isRecording) {
              setIsRecording(false);
              record();
            }
            setVoice(null);
            setDescription('');
            setVideo(null)
            setActiveInput('video');
          },
        )}
      </div>

    </VoiceRecordingWrapper>
  );
};

export default Voice;
