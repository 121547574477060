import React from 'react';
import { FaImages } from 'react-icons/fa';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withDashedBorder from './withDashedBorder';

import { ImgGrayText } from './style';

const Image = ({ onClick }) => (
  <ImgGrayText style={{ cursor: 'pointer', textAlign: 'center' }} onClick={onClick}>
    <Button variant="contained" color="primary" className="button"  style={{  borderRadius: '2rem', border:'none', color:'white', backgroundColor:''}}
>
      <FaImages className="icon" />
    </Button>
    <Typography>
      <span className="text" style={{color:''}}>Upload Photos</span>
    </Typography>
  </ImgGrayText>
);

export default withDashedBorder(Image, 'image');
