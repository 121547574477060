import styled, { keyframes } from 'styled-components';

export const PaperWrapper = styled.div`
  .onboardingPaper {
    padding: 1rem;
    max-width: 600px;
    margin: 1.25rem;
    transition: all 0.25s ease !important;
  }
  @media (min-width: 768px) {
    .onboardingPaper {
      padding: 2rem !important;
    }
  }
  @media (min-width: 1201px) {
    .onboardingPaper {
      padding: 2rem !important;
    }
  }
`;

export const MemoryGraphicsWrapper = styled.div`
  position: relative;
  height: 25vh;
  width: 100%;
  padding-bottom: 1rem;
  display: block;
  overflow: hidden;
  text-align: center;

  #onboardingMemoryGraphic1,
  #onboardingMemoryGraphic2,
  #onboardingMemoryGraphic3,
  #onboardingMemoryGraphic4 {
    position: absolute;
    border-radius: 5px;
    max-height: 60%;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.125);
    pointer-events: none;
  }

  #onboardingMemoryGraphic1 {
    left: 0%;
    top: 4rem;
  }

  #onboardingMemoryGraphic2 {
    left: 10%;
    top: 3rem;
  }

  #onboardingMemoryGraphic3 {
    left: 20%;
    top: 2rem;
  }

  #onboardingMemoryGraphic4 {
    left: 30%;
    top: 1rem;
  }

  @media (min-width: 500px) {
    #onboardingMemoryGraphic2 {
      left: 15%;
    }
    #onboardingMemoryGraphic3 {
      left: 30%;
    }
    #onboardingMemoryGraphic4 {
      left: 45%;
    }
  }

  @media (min-width: 600px) {
    #onboardingMemoryGraphic2 {
      left: 18%;
    }
    #onboardingMemoryGraphic3 {
      left: 36%;
    }
    #onboardingMemoryGraphic4 {
      left: 54%;
    }
  }
`;

export const HighlightGlow = keyframes`
	0% {box-shadow: 0px 0px 0px hsla(178, 88%, 36%,0.30);}
	40% {box-shadow: 0px 0px 20px hsla(178, 88%, 36%,0.65);}
	100% {box-shadow: 0px 0px 50px hsla(178, 88%, 36%,0);}
`;

export const AddFirstMemoryButton = styled.div`
  button {
    padding: 1.25rem 0rem !important;
    font-size: 1.25rem;
  }

  .isGlowing {
    border-radius: 0.5rem;
    margin: 0px !important;
    animation: ${HighlightGlow} 1.5s linear infinite;
  }
`;
