import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import history from '../../../history';
import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/Logo/Logo_Orange.json';

import {
  Paper,
  Typography,
  Button,
  Grid,
  Slide,
  Fade,
  Card
} from '@material-ui/core';

// Styled Components
import { PaperWrapper, AddFirstMemoryButton } from '../Onboarding.style';

import { MEMORIES_URL, DASHBOARD_URL, CREATE_MEMORY_URL } from '../../../constants/constantUrls'

// Custom components
import { ImageButton } from '../../ImageButton/ImageButton';
import { ReactComponent as GiftIcon } from '../../../assets/gift.svg';
import { Divider } from '../../Divider/Divider';

export function OnboardingStep5({
  giftMemories = [],
  ...props
}) {
  /* Transition States */
  const [cardTransition, setCardTransition] = useState(false);
  const [titleTransition, setTitleTransition] = useState(false);
  const [descriptionTransition, setDescriptionTransition] = useState(false);
  const [inputTransition, setInputTransition] = useState(false);
  const [buttonTransition, setButtonTransition] = useState(false);

  /* Scroll Anchor */
  const buttonRef = useRef();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  /* Transition Timings */
  useEffect(() => {
    setTimeout(() => {
      setCardTransition(true);
    }, 500);
    setTimeout(() => {
      setTitleTransition(true);
    }, 1000);
    setTimeout(() => {
      setDescriptionTransition(true);
    }, 1500);
    setTimeout(() => {
      setInputTransition(true);
    }, 2000);
    setTimeout(() => {
      setButtonTransition(true);
    }, 2500);
  });

  return (
    <Slide direction="up" in={cardTransition} timeout={500}>
      <PaperWrapper>
        <Paper className="onboardingPaper" {...props}>
          <Fade in={titleTransition} timeout={1000}>
            <Typography variant="h4" color="primary">
              {giftMemories.length > 1 ? `You've got some memories waiting` : `You've got a memory waiting`}
            </Typography>
          </Fade>
          <br />
          <Fade in={descriptionTransition} timeout={1000}>
            <>
              <Typography margin="normal" variant="body1">
                Getting a timeline started can be a little daunting. Lucky for you, you’ve already got a head start!
                <br/>
                You must have some great people around you, take a look at the memories you’ve already been sent:
              </Typography>
              {/* <Lottie
                options={defaultOptions}
                height={400}
                width={400}
              /> */}
            </>
          </Fade>
          <br />
          <Fade in={inputTransition} timeout={1000}>
            <>
              {giftMemories.map((memory) => (
                <Paper elevation={0} style={{ padding: '1rem', border: '2px solid #ddd', borderRadius: '2rem', margin: '0.5rem' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <Typography>
                        <span style={{ fontWeight: 'bold' }}>
                          {memory.title}
                        </span>
                        {memory?.memory?.length > 0 && (<span style={{ color: '#777' }}><br />{memory.memory.length < 50 ? memory.memory : `${memory.memory.slice(0, 49)}...`}</span>)}
                        <br />
                        From: {memory.author.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {/* <div style={{ position: 'absolute', top: '50%', '-ms-transform': 'translateY(-50%)', transform: 'translateY(-50%)' }}> */}
                      <NavLink
                        to={{
                          pathname: `${MEMORIES_URL}/${memory._id}`,
                          state: {
                            details: memory,
                            viewingFriendsMemory: false,
                          },
                        }}
                        style={{ textDecoration: 'none' }}
                      >
                        <Button color="primary" variant="contained">View Memory</Button>
                      </NavLink>
                      {/* </div> */}
                      
                    </Grid>
                  </Grid>
                </Paper>
              ))}
              <Divider text="OR" />
              <NavLink
                to={{
                  pathname: CREATE_MEMORY_URL,
                  state: {
                    refreshNeeded: true,
                  },
                }}
                style={{ textDecoration: 'none' }}
              >
                <Button style={{ width: '100%' }} variant="contained" color="primary">Create A memory</Button>
              </NavLink>
              <NavLink
                to={{
                  pathname: DASHBOARD_URL,
                }}
                style={{ textDecoration: 'none' }}
              >
                <Button style={{ width: '100%', marginTop: '1rem' }} variant="outlined" color="primary" >Go To My Dashboard</Button>
              </NavLink>
            </>
          </Fade>
        </Paper>
      </PaperWrapper>
    </Slide>
  );
}
