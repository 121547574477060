import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  /* justify-content: space-around;
  margin: 1rem auto; */
`;

export const SocialItem = styled.div`
  margin-right:1rem;
  margin-top:1rem;
`;
