import React from 'react';

const Breadcrumb = props => (
  //   fill={props.color || "currentColor"}
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="3.5" stroke={props.color || 'currentColor'} />
  </svg>
);

export default Breadcrumb;
