import styled, { css } from 'styled-components';
import { breakpointsMax } from '../../../constants/breakPoints';

export const PrivacySettingsWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const PrivacySection = styled.div`
  padding: 1.25rem;
  background: hsla(178, 63%, 46%, 0.1);
  border-radius: 1rem;
  margin-bottom: 2rem;
`;
export const VideoButtonWrapper = styled.div`
  button {
    font-size: 25px;
  }
`;

export const FlexWrapper = styled.div`
  .columns {
    width: 100% !important;
    margin: 0;
  }
  width: 100%;
  margin-bottom: 0rem;
  &:last-child {
    margin-bottom: 0rem;
  }
`;

export const DashedWrapper = styled.div`
  /* display:flex; */
  // flex-wrap: wrap;
  /* may break COPY LINK  */
  /* flex-direction:column;   */
  input,
  textarea {
    border: ${({ error }) =>
      error ? 'var(--input-error-border)' : 'var(--input-border)'};
    width: 100%;
    font-weight: 400;
    outline: none;
    transition: all ease 0.2s;
    background: none;
    /* padding: 15px; */
    border-width: 2px;
    border-radius: 5px;

    :active,
    :focus {
      border: var(--input-focus-border);
    }
  }
`;

const sharedInputStyle = css`
  border: none;
  font-weight: bold;
  font-size: 14px;
  color: var(--neutral-dark);
  min-width: 200px;
  margin-bottom: 1rem;

  :focus {
    border: none;
    outline: none;
  }
`;
export const InputComponent = styled.input`
  ${sharedInputStyle}
`;

export const VoiceOrDescriptionWrapper = styled.div`
  .has-text-centered {
    display: flex;
    justify-content: center;
  }

  @media ${breakpointsMax.laptop} {
    .has-text-centered {
      display: block;
    }
  }
`;

export const Or = styled.p`
  font-weight: bold;
  font-size: 18px;
  color: var(--neutral-slate)
  margin: 1.25rem auto;
`;

export const Textarea = styled.textarea`
  ${sharedInputStyle};
  height: ${({ isLarge }) => (isLarge ? '250px' : '18px')};
  resize: ${({ isLarge }) => (isLarge ? 'auto' : 'none')};
  overflow: ${({ isLarge }) => (isLarge ? 'auto' : 'hidden')};
  width: ${({ isLarge }) => (isLarge ? '100%' : 'auto')};
`;

export const GrayText = styled.p`
  .button {
    border-radius: 10rem;
    height: 10rem;
    border: solid 3px #43cdc6;
    width: 10rem;
    :hover {
      border: solid 3px var(--secondary-aqua);
    }
    @media ${breakpointsMax.mobileXL} {
      height: 7rem;
      width: 7rem;
    }
    @media ${breakpointsMax.mobileL} {
      height: 5rem;
      width: 5rem;
    }
    @media ${breakpointsMax.mobileM} {
      height: 4rem;
      width: 4rem;
    }
  }

  .icon {
    width: 100%;
    height: 100%;
  }

  .text {
    font-size: 1.8rem;
    color: var(--secondary-aqua);
    font-weight: 600;
    @media ${breakpointsMax.mobileXL} {
      font-size: 1.5rem;
      font-weight: 600;
    }
    @media ${breakpointsMax.mobileL} {
      font-size: 1.3rem;
      font-weight: 600;
    }
    @media ${breakpointsMax.mobileM} {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

export const ImgGrayText = styled.p`
  width: 100%;
  /* text-align: center; */
  margin: 0 auto;
  .button {
    border-radius: 10rem;
    height: 10rem;
    border: solid 3px #43cdc6;
    width: 10rem;
    :hover {
      border: solid 3px var(--secondary-aqua);
    }
    @media ${breakpointsMax.mobileXL} {
      height: 7rem;
      width: 7rem;
    }
    @media ${breakpointsMax.mobileL} {
      height: 5rem;
      width: 5rem;
    }
  }

  .icon {
    width: 80%;
    height: 80%;
  }

  .text {
    font-size: 1.8rem;
    color: var(--secondary-aqua);
    font-weight: 600;
    @media ${breakpointsMax.mobileXL} {
      font-size: 1.5rem;
      font-weight: 600;
    }
    @media ${breakpointsMax.mobileL} {
      font-size: 1.3rem;
      font-weight: 600;
    }
    @media ${breakpointsMax.mobileM} {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
`;

export const Placeholder = styled.img`
  margin: 0.75rem auto !important;
  max-height: 450px !important;
  max-width: 70%;
  border-radius: 1rem;
`;

export const DescriptionWrapper = styled.div`
  /* display: flex;
  flex-direction: column; */
`;

export const AlternativeButton = styled.button`
  border: none;
  font-size: 16px;
  margin: 10px 10px;
  cursor: pointer;
  color: black;
  border: solid 2px primary;
  background-color: #0000;
  &:hover {
    text-decoration: underline;
  };
  
`;

export const AlternativeText = styled.p`
  font-size: 1rem;
`;

export const ShareLinkText = styled.p`
  font-weight: bold;
  color: var(--secondary-aqua);
  word-wrap: break-word;
  line-height: 30px;
  margin-right: 1rem;
`;

export const CopyText = styled.p`
  font-weight: bold;
  font-size: 10px;
  color: #bdbdbd;
  display: block;
`;
export const CardSubText = styled.p`
  font-weight: bold;
  font-size: 1rem;
  color: var(--neutral-dark);
  margin-top: 1rem;
  text-align: left;
`;

export const ButtonWrapper = styled.div`
  /* margin: 2rem auto; */
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  button {
    margin: 0px 10px !important;
  }
  .display-mobile {
    display: none;
  }
  @media ${breakpointsMax.tablet} {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    p {
      margin: 0px 10px;
    }
    button {
      margin: 0px;
      svg {
        margin: 0px;
      }
    }
    .display-mobile {
      display: block;
    }
    .display-desktop {
      display: none;
    }
  }
`;

export const Error = styled.p`
  color: var(--secondary-cherry);
  text-align: left;
  margin-top: 0.5rem;
  font-size: 1rem;
  margin-left: 1rem;
`;

export const VoiceRecordingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  @media ${breakpointsMax.mobileXL} {
    .video-player-wrapper {
      div {
        width: 100% !important;
        height: 100% !important;
      }
      video {
        width: 240px !important;
        height: 180px !important;
      }
    }
  }

  /** classes which are automatically generated by the video recorder plugin */
  .WroCl {
    min-height: 500px;
    margin-bottom: 1rem;
  }
  .MccLt {
    align-items: center;
    height: 100%;
    padding: 0;
  }
`;

export const AudioPlayer = styled.audio`
  height: 46px;
  padding-bottom: 1rem;
`;

export const MessageDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

export const VoiceIconWrapper = styled.div`
  width: 90px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin: 1rem auto;

  color: ${({ recording }) =>
    recording ? `var(--secondary-cherry)` : `var(--neutral-dark)`};

  :after {
    content: '';
    position: absolute;
    width: 67px;
    height: 67px;
    border: 1px solid #cdcdcd;
    border-radius: 50%;
    animation: ${({ recording }) => recording && 'spin 2s linear infinite'};
    border-top: ${({ recording }) => recording && 'none'};

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export const StopIcon = styled.div`
  width: 20px;
  height: 20px;
  background-color: var(--secondary-cherry);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Message = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
  padding: 1rem;
`;

export const DateOptionWrapper = styled.div`
  button {
    margin-right: 10px;
  }
`;

export const FullDateWrapper = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-left: 0px;
  }
  input {
    margin-bottom: 20px;
  }
`;

export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin-left: 0px;
  }
  input {
    border: ${({ error }) =>
      error ? 'var(--input-error-border)' : 'var(--input-border)'};
    width: 100%;
    font-weight: 400;
    outline: none;
    transition: all ease 0.2s;
    background: none;
    padding: 15px;
    border-width: 2px;
    border-radius: 5px;

    :active,
    :focus {
      border: var(--input-focus-border);
    }
  }
`;

export const CalendarInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const TrashIconWrapper = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--neutral-slate);
  * {
    pointer-events: none;
  }
`;

export const ToggleWrapper = styled.div`
  h3 {
    font-weight: bold;
    font-size: 16px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  .react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4d4d4d;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: #19ab27;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: #128d15;
  }

  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4d4d4d;
    border-radius: 50%;
    background-color: #fafafa;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19ab27;
  }

  .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
    -moz-box-shadow: 0px 0px 3px 2px #0099e0;
    box-shadow: 0px 0px 2px 3px #0099e0;
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
    -moz-box-shadow: 0px 0px 5px 5px #0099e0;
    box-shadow: 0px 0px 5px 5px #0099e0;
  }
`;

// Privacy toggle
export const PrivacyToggleWrapper = styled.div`
  h3 {
    font-weight: bold;
    font-size: 16px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${breakpointsMax.laptop} {
    display: block;
  }

  .privacy-toggle {
    .react-toggle-track {
      width: 60px;
      height: 30px;
    }

    .react-toggle-thumb {
      width: 28px;
      height: 28px;
    }
  }

  .react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4d4d4d;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: #19ab27;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: #128d15;
  }

  .react-toggle-track-check {
    position: absolute;
    /* width: 14px;
  height: 10px; */
    top: 0px;
    bottom: 0px;
    margin-top: 5px;
    margin-bottom: auto;
    line-height: 0;
    left: 10px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    position: absolute;
    /* width: 10px;
  height: 10px; */
    top: 0px;
    bottom: 0px;
    margin-top: 5px;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4d4d4d;
    border-radius: 50%;
    background-color: #fafafa;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 31px;
    border-color: #19ab27;
  }

  .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
    -moz-box-shadow: 0px 0px 3px 2px #0099e0;
    box-shadow: 0px 0px 2px 3px #0099e0;
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
    -moz-box-shadow: 0px 0px 5px 5px #0099e0;
    box-shadow: 0px 0px 5px 5px #0099e0;
  }
`;

export const EmailRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin:1rem 0rem;
  line-break: anywhere;
  /* @media ${breakpointsMax.laptop} {
    display:block;
    
  }  */
`;

export const EmailText = styled.p`
  @media ${breakpointsMax.laptop} {
    margin-botton: 10px !important;
  }
`;

export const Img = styled.img`
  width: 10vw;
  max-width: 5rem;
  height: auto;
  animation: rotation 5s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const FeaturesBlockStyles = styled.div`
  transition: all 0.25s ease !important;
  text-align: center;
  a {
    text-decoration: none !important;
    color: black !important;
  }

  .paper {
    padding: 1rem;
    min-height: 40.5vh;
    margin: 0.5rem;
  }
  .scrollButtonsContainer {
    display: flex;
    flex-direction: row;
    margin: 0rem;
    padding: 1.25rem 1.25rem 0rem 1.25rem;
    button {
      font-size: 1.25rem;
      float: left;
      width: 50%;
      margin-right: 2%;
    }
  }

  .featureContainer {
    display: flex;
    flex-direction: row;
    padding: 1.25rem 0.75rem;
    margin: 1rem;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .featureCard {
    :hover {
      transform: scale(1.075);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      transition: all 0.25s ease !important;
    }
  }
`;
