// React
import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

// Swal
import Swal from "sweetalert2";

// MUI
import { Paper, Typography, Button, Grid } from "@material-ui/core";

// Icons
import { FaRegClone } from "react-icons/fa";
import Next from "@material-ui/icons/ExitToApp"

// Custom Components
import { Divider } from "../../Divider/Divider";
import { MemoryCard } from "../../Dashboard/Blocks/NewTimeline/ContentCards/MemoryCard/MemoryCard";
import { SocialMediaButtons } from "../../SocialMediaButtons/SocialMediaButtons";

import {
  CREATE_MEMORY_URL,
  DASHBOARD_URL,
  PROMPTS_URL,
} from "../../../constants/constantUrls";
import { PROMPT_URL } from "../../../constants/apiRoutes";

export const SuccessScreen = ({
  memory = {},
  friends = [],
  shareLink = "",
  messageBody = "",
  sendMemoryFunc = () => {},
  ...props
}) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    Swal.fire({
      title: "Copied to Clipboard",
      type: "success",
      timer: 2000,
    });
  };

  return (
    <Paper
      elevation={3}
      style={{
        padding: window.innerWidth < 600 ? "1rem" : "2rem",
        textAlign: "center",
        maxWidth: "800px",
        width: "100%",
        marginLeft: window.innerWidth < 1024 ? "2rem" : "9rem",
        marginRight: "2rem",
        marginTop: window.innerWidth < 1024 ? "1rem" : "0rem",
      }}
      {...props}
    >
      <Typography
        color="primary"
        variant="h4"
        gutterBottom
        style={{ marginBottom: "1rem", fontSize: window.innerWidth < 600 ? "1.5rem" :"2rem" }}
      >
        {memory.isDraft ? "You've saved a draft!" : "You Created A Story!"}
      </Typography>
     
      {!memory.isDraft && (
        <Typography style={{ marginBottom: "1rem" }}>
          If you've added family or Friends to Iternal, we&apos;ve let them know
          that you've made a new story!
        </Typography>
      )}
      <Grid item={12} >
      <Grid item xs={12} style={{}}>
          <NavLink
            to={{
              pathname: PROMPTS_URL,
              state: {
                refreshNeeded: true,
              },
            }}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              style={{
                marginBottom: "2rem",
                // borderRadius: "100px",
               
                minHeight: "50px",
                fontWeight: "700",
                boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
              }}
            >
              Create Another Story&nbsp;&nbsp;
              <Next />
            </Button>
          </NavLink>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ background:'#FBFBFB', paddingTop:'1rem', borderRadius:'0px', display:'flex', justifyContent:'center', marginBottom:'1rem'}}>
     
        <Grid item xs={12} sm={7} style={{ display:'flex', alignSelf:'center', justifySelf:'center' }}>
          <MemoryCard
            {...memory}
            preview
            style={{
              width: "100%",
              textAlign: "left",
              maxWidth: "400px",
              margin: "0 auto",
              height:'100%'
            }}
          />
        </Grid>
        {!memory.isDraft && (
          <Grid item xs={12} md={5}>
            <Grid container spacing={3} style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
  
              <Typography
                variant="h5"
                gutterBottom
                color="primary"
                style={{ textAlign: "left", margin: "1rem auto 0rem" }}
              >
                Share on Socials
              </Typography>
              <Grid item={12} style={{display:'flex', }}>
              <Grid xs={12} style={{marginTop:'-1rem'}}>
              <SocialMediaButtons
                shareLink={shareLink}
                messageBody={messageBody}
              />
              </Grid>
              </Grid>
              <Typography
                variant="h5"
                gutterBottom
                color="primary"
                style={{ textAlign: "left", margin: "1rem auto 0rem" }}
              >
                Share via Link
              </Typography>
              <Grid item xs={12}>
                <div
                  role="button"
                  tabIndex={0}
                  style={{
                    cursor: "pointer",
                    padding: "1rem",
                    margin: "0rem auto",
                    backgroundColor: "rgb(170 170 170 / 23%)",
                    borderRadius: "0.5rem",
                  }}
                  onClick={() => copyToClipboard(shareLink)}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={10} style={{ overflow: "auto" }}>
                      <Typography style={{ textAlign: "left" }}>
                        {shareLink}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <FaRegClone style={{ height: "100%" }} />
                    </Grid>
                  </Grid>
                </div>
                <Typography variant="body2" style={{ textAlign: "left" }}>
                  * Click to copy to clipboard
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* <Grid item xs={12} style={{ marginTop: '1rem' }}>
          <Paper elevation={3} style={{ padding: '2rem', maxHeight: '400px', overflow: 'auto', width: '80%', margin: '0 auto' }}>
            <Grid container spacing={3}>
              {friends.map(friend => (
                <Grid item xs={6} sm={4} >
                  <Paper elevation={0} style={{ padding: '1rem' }}>
                    <Avatar
                      src={friend.image ? friend.image : ''}
                      alt="Profile avatar"
                      style={{ margin: '0rem auto 1rem' }}
                    >
                      {friend.name ? generateUserInitials(friend.name) : 'U'}
                    </Avatar>
                    <p>{friend.name}</p>
                    {friend.sent ? (
                        <Button variant="contained" color="primary" disabled fullWidth>
                          <Typography>Sent</Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => sendMemoryFunc(friend)}
                        >
                          Send
                        </Button>
                      )}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid> */}
      </Grid>
      <Divider logo />
      <Grid container spacing={3} style={{  }}>
       
        <Grid item xs={12}>
          <NavLink
            to={{
              pathname: DASHBOARD_URL,
            }}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="outlined"
              color="secondary"
              style={{
                marginTop: "1rem",
                borderRadius: "100px",
                minWidth: "164px",
                minHeight: "50px",
                fontWeight: "700",
                boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
              }}
            >
              View All Your Stories
            </Button>
          </NavLink>
        </Grid>
      </Grid>
    </Paper>
  );
};

SuccessScreen.propTypes = {
  /**
   * Enter a memory
   */
  memory: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    date: PropTypes.string,
    dateAge: PropTypes.number,
    dateType: PropTypes.oneOf(["full", "age"]),
    description: PropTypes.string,
    images: PropTypes.arrayOf({
      _id: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    isDraft: PropTypes.bool,
    isPublic: PropTypes.bool,
    privacySetting: PropTypes.oneOf([
      "Only Me",
      "Friends & Family",
      "Specific Groups",
      "Public",
    ]),
    tempImages: PropTypes.arrayOf({
      _id: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    user: PropTypes.string,
    video: PropTypes.string,
    voice: PropTypes.string,
  }),
  /**
   * Add Friends to list
   */
  friends: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      owner: PropTypes.string,
      nickname: PropTypes.string,
      relationships: PropTypes.arrayOf(PropTypes.string),
      sharedWithId: PropTypes.string,
      sharedWithEmail: PropTypes.string,
    })
  ),
  /**
   * Enter a link for the memory
   */
  shareLink: PropTypes.string,
  /**
   * Enter a message for sharing on socials
   */
  messageBody: PropTypes.string,
  /**
   * A function for sending a memory
   */
  sendMemoryFunc: PropTypes.func,
};

SuccessScreen.defaultProps = {
  memory: {},
  friends: [],
  shareLink: "",
  messageBody: "",
  sendMemoryFunc: () => {},
};
