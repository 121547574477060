import styled from 'styled-components';

import { breakpointsMax } from '../../../constants/breakPoints';

// SuccessPage
export const TitleWrapper = styled.div`
  width: 80%;
  margin: auto;
  h2,
  p {
    text-align: center;
  }

  @media ${breakpointsMax.tablet} {
    width: 100%;
  }
`;

export const SuccessPageWrapper = styled.main`
  padding: 2rem 3rem 1rem 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  max-width: 1200px;

  @media ${breakpointsMax.laptop} {
    max-width: inherit;
    padding: 1rem 2rem;
  }
`;

export const Box = styled.div`
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  display: block;
  padding: 2.25rem;
  margin-bottom: 2rem;
  width: 100%;
`;

export const ShareSection = styled.div`
  width: 80%;
  margin: auto;
  @media ${breakpointsMax.tablet} {
    width: 100%;
  }
`;

export const SectionTitle = styled.h2`
  text-align: left;
  margin-bottom: 20px;
  font-size: 20px;
`;

export const ShareIcon = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 1000px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;

  .fb {
    color: #2196f3;
  }

  .wa {
    color: #65cf72;
  }

  svg {
    width: 30px;
    height: 30px;
  }
`;
export const Error = styled.p`
  color: var(--secondary-cherry);
  text-align: left;
  margin-top: 0.5rem;
  font-size: 1rem;
  margin-left: 1rem;
`;

export const ShareIconWrapper = styled.div``;

export const ShareIconGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 30px;
`;

export const LinkSection = styled.div`
  width: 80%;
  margin: auto auto 50px;
  word-break: break-all;
  @media ${breakpointsMax.tablet} {
    width: 100%;
  }
`;
export const LinkWrapper = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f9fa;
  padding: 20px 0px;
  border-radius: 10px;
  margin: auto;
  cursor: pointer;
  color: #888888;
  font-weight: bold;
  p {
    margin-left: 20px;
  }
  svg {
    margin-right: 20px;
    margin-left: 1rem;
  }

  @media ${breakpointsMax.tablet} {
    width: 100%;
    svg {
      margin-right: 20px;
      width: 25px;
      height: 25px;
    }
  }
`;

export const EmailSection = styled.div``;
export const InviteSection = styled.div`
  width: 80%;
  margin: auto;
  @media ${breakpointsMax.tablet} {
    width: 100%;
  }
`;

export const GroupedInput = styled.div`
  margin: auto;
  margin-bottom: 20px;
  .grouped-input-field {
    input {
      height: 46px;
      margin: 0px;
    }
  }
  @media ${breakpointsMax.tablet} {
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const FriendList = styled.div`
  margin-bottom: 30px;
`;

export const FriendIitemWrapper = styled.div`
  width: 80%;
  margin: auto;

  button {
    margin: 0px;
  }
  p {
    text-align: left;
  }
  @media ${breakpointsMax.tablet} {
    width: 100%;
    p {
      width: 60%;
    }
    button {
      margin: 0 0px 0px 25px;
      font-size: 12px;
      padding: 5px;
    }
  }
`;

export const FriendItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const SuccessTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1rem;
  padding-left: 1rem;
`;

export const Title = styled.h1`
  color: var(--secondary-aqua);
  font-size: 2rem;
  line-height: 36px;
  font-weight: 900;
  margin-bottom: 0.5rem;
`;

export const BodyText = styled.p`
  margin-bottom: 30px;
  line-height: 24px;
  text-align: left;
`;

export const ButtonSection = styled.section`
  display: flex;
  padding-top: 1.5rem;
  justify-content: center;
  border-top: 1px solid #e0e0e0;
  button {
    margin: 0px 10px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
`;

export const ButtonText = styled.p`
  text-decoration: underline;
  text-align: center;
  padding-left: 0.5rem;
  font-weight: bold;
`;
