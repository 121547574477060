import axios from 'axios';

import {
  API_PROMPTS_URL,
  API_PROMPT_URL,
} from '../constants/apiRoutes';

import makeError from './makeError';

// get all memories created by this user
const getAllPrompts = async ({ options } = {}) => {
    try {
        const { data } = await axios.get(`${API_PROMPTS_URL}`);
        return { data };
    } catch (error) {
        return { error: makeError(error, options) };
    }
};

const getCategoryPrompts = async ({ category, options } = {}) => {
    try {
        const { data } = await axios.get(`${API_PROMPTS_URL}/${category}`);
        return { data };
    } catch (error) {
        return { error: makeError(error, options) };
    }
}

const getPrompt = async ({ promptId, options } = {}) => {
  try {
    const { data } = await axios.get(`${API_PROMPT_URL}/${promptId}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) }
  }
}

const getMemoryPrompts = async ({ options } = {}) => {
  try {
    const { data } = await axios.get('/api/memory-prompts');
    return { data };
  } catch (error) {
    return { error: makeError(error, options) }
  }
}

const prompts = {
    getAllPrompts,
    getCategoryPrompts,
    getPrompt,
    getMemoryPrompts,
};

export default prompts;
