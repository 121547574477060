import React, { useState } from "react";
import PropTypes from "prop-types";

// MUI
import { Grid, Typography, ButtonBase, Collapse } from "@material-ui/core";

// Icons
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import RefreshIcon from "@material-ui/icons/Refresh";

// Styles
import {
  ButtonStyle,
  IconWrapper,
  PromptTextStyle,
  CategoryTextStyle,
  SpinningGenerator,
  SpinAgainTextStyle,
  AfterPromptTextStyle,
} from "./PromptGenerator.style";

export function PromptGenerator({
  prompt = "",
  prompts,
  promptCategory,
  showRandomPrompt = () => {},
  ...props
}) {
  const [isScrolling, setIsScrolling] = useState(false);
  return (
    <ButtonBase
      style={ButtonStyle}
      variant="outlined"
      onClick={() => {
        if (!isScrolling) {
          showRandomPrompt();
          setIsScrolling(true);
          setTimeout(() => setIsScrolling(false), 2000);
        }
      }}
      disableRipple={true}
      {...props}
    >

      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={2}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >

          <IconWrapper>
            {prompt.length > 0 ? (
              <>
                <RefreshIcon fontSize="medium" style={{}} />
                <Typography style={SpinAgainTextStyle}>
                  Spin <br></br>Again
                </Typography>
              </>
            ) : (
              <EmojiObjectsIcon style={{ fontSize: "2.8rem" }} />
            )}
          </IconWrapper>
        </Grid>


        <Grid item xs={12} sm={10} md={10}>
          {!isScrolling && (
            <Collapse in={!prompt} timeout={750}>
              <Typography style={AfterPromptTextStyle}>

                <div
                  style={{
                    display: window.innerWidth < 400 ? "none" : "block",
                  }}
                >
                  Looking for inspiration?{" "}
                </div>
                <div
                  style={{
                    display: window.innerWidth > 400 ? "none" : "block",
                    fontWeight: "600",
                    marginBottom: "0.5rem",
                  }}
                >
                  Need inspiration?{" "}
                </div>

                Click this box for a story prompt...
              </Typography>
            </Collapse>
          )}

          {isScrolling ? (
            <>
              <Collapse in={isScrolling} timeout={0}>
                <SpinningGenerator>
                  <b>
                    <span>
                      What was your first ever job?
                      <br />
                      What was the best job you ever had - not in financial
                      terms?
                      <br />
                      What was the first record you remember buying for
                      yourself?
                      <br />
                      Did you ever do anything you can't believe you got away
                      with?
                      <br />
                      Did you ever go to any notorious office parties?
                      <br />
                      Did you ever go out on a first date? Where was it, and who
                      with?
                      <br />
                      Do you remember the first person you kissed?
                      <br />
                      Do you remember the first time you tried your favourite
                      food?
                      <br />
                      How did you meet your current / life partner?
                      <br />
                      Did you ever have a particularly memorable night out?
                      <br />
                      What was the best present you've ever received?
                      <br />
                      What was the best present you've ever received?
                    </span>
                  </b>
                </SpinningGenerator>
              </Collapse>
            </>
          ) : (
            <Collapse in={!isScrolling} timeout={750}>
              <Collapse in={prompt} timeout={750}>
                <Typography style={CategoryTextStyle}>
                  What can you say about....
                </Typography>
                <Typography style={AfterPromptTextStyle}>{prompt}</Typography>
              </Collapse>

              <Collapse in={promptCategory} timeout={750}>
                {/* <Typography style={CategoryTextStyle}>
                  {promptCategory && `Category: ${promptCategory}`}
                </Typography> */}
              </Collapse>
            </Collapse>
          )}
        </Grid>
      </Grid>
    </ButtonBase>
  );
}

PromptGenerator.propTypes = {
  /**
   * What should the prompt say?
   */
  prompt: PropTypes.string,
  /**
   * What is the category of this prompt?
   */
  promptCategory: PropTypes.string,
  /**
   * Please provide a function for displaying a random prompt
   */
  showRandomPrompt: PropTypes.func,
};

PromptGenerator.defaultProps = {
  prompt: undefined,
  promptCategory: undefined,
  showRandomPrompt: () => {},
};
