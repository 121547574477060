import React from 'react';

const QuestionMark = ({ width, height, color, theme }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 18"
    fill={color || '#E8529B'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.456 12.936C3.456 12.072 3.608 11.32 3.912 10.68C4.216 10.024 4.736 9.464 5.472 9C5.76 8.808 6.088 8.608 6.456 8.4C6.84 8.192 7.2 7.952 7.536 7.68C7.872 7.392 8.152 7.056 8.376 6.672C8.616 6.272 8.736 5.8 8.736 5.256C8.736 4.808 8.648 4.416 8.472 4.08C8.312 3.728 8.096 3.44 7.824 3.216C7.552 2.976 7.24 2.8 6.888 2.688C6.536 2.56 6.168 2.496 5.784 2.496C5.384 2.496 5 2.56 4.632 2.688C4.28 2.8 3.952 2.96 3.648 3.168C3.344 3.376 3.072 3.624 2.832 3.912C2.592 4.184 2.392 4.472 2.232 4.776L0.816 3.84C1.024 3.344 1.304 2.904 1.656 2.52C2.008 2.136 2.408 1.816 2.856 1.56C3.304 1.288 3.776 1.088 4.272 0.959999C4.784 0.815999 5.304 0.743999 5.832 0.743999C6.424 0.743999 7.008 0.839999 7.584 1.032C8.16 1.208 8.672 1.488 9.12 1.872C9.568 2.256 9.928 2.736 10.2 3.312C10.472 3.888 10.608 4.568 10.608 5.352C10.608 5.96 10.52 6.48 10.344 6.912C10.184 7.344 9.968 7.72 9.696 8.04C9.424 8.36 9.104 8.64 8.736 8.88C8.368 9.12 7.984 9.352 7.584 9.576C7.296 9.736 7.008 9.904 6.72 10.08C6.432 10.256 6.176 10.472 5.952 10.728C5.744 10.968 5.568 11.264 5.424 11.616C5.296 11.968 5.232 12.408 5.232 12.936H3.456ZM3.504 18V15.216H5.28V18H3.504Z"
      fill="#E8529B"
    />
  </svg>
);

export default QuestionMark;
