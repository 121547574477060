import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import loader from '../../../assets/loader.svg';

import ProfileMenu from '../../Common/ProfileMenu';
// import pinkArrow from '../../../assets/pink-arrow-right.svg';
import { CHAT_URL, ABOUT_SECTION_URL } from '../../../constants/constantUrls';
import { API_ABOUT_ME_PROFILE } from '../../../constants/apiRoutes';

import * as S from './style';

const apiCall = id => axios.get(API_ABOUT_ME_PROFILE, id);

export default class About extends Component {
  state = {
    data: null,
    loaded: false,
  };

  componentDidMount = () => {
    const { id } = this.props;
    apiCall(id)
      .then(({ data }) => this.setState({ data: data.data, loaded: true }))
      .catch(err => console.log(err));
  };

  renderSectionsOrNothing = () => {
    const { data } = this.state;

    return typeof data !== 'string' && data.length > 0 ? (
      <>
        <S.A to={{ pathname: CHAT_URL, state: 'about' }}>
          <S.P>Click here to add more!</S.P>
          {/* <img alt="Arrow right" src={pinkArrow} /> */}
        </S.A>
        {data.map((sectionDetails, idx) => (
          <>
            <S.SectionContainer key={idx}>
              <Link
                to={{
                  pathname: `${ABOUT_SECTION_URL}`,
                  state: { sectionDetails },
                }}
                style={{ textDecoration: 'none', width: '100%' }}
              >
                <S.Line />
                <S.AboutWrapper>
                  <S.TitleWrapper>
                    <S.Title>{sectionDetails._id}</S.Title>
                  </S.TitleWrapper>
                  {/* <img alt="Arrow right" src={pinkArrow} /> */}
                </S.AboutWrapper>
              </Link>
            </S.SectionContainer>
          </>
        ))}
      </>
    ) : (
      <S.A to={{ pathname: CHAT_URL, state: 'about' }}>
        <S.P>
          You haven&apos;t added anything to your profile yet. Add something
          now.
        </S.P>
        {/* <img alt="Arrow right" src={pinkArrow} /> */}
      </S.A>
    );
  };

  render() {
    const { loaded } = this.state;

    if (!loaded) {
      return (
        <div>
          <img alt="loader" src={loader} />
        </div>
      );
    }

    return (
      <>
        <S.Main>
          <S.H1>
            About Me{' '}
          </S.H1>

          {this.renderSectionsOrNothing()}
        </S.Main>
        <S.Nav>
          <ProfileMenu />
        </S.Nav>
      </>
    );
  }
}
