import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import ScrollRestoration from 'react-scroll-restoration';
import axios from 'axios';
import initialState from '../constants/initialState';

// COMMON
import PrivateRoute from './Common/PrivateRoute';
import NotPrivateRoute from './Common/NotPrivateRoute';

// COMPONENTS
import Dashboard from './Pages/Dashboard';
import SignUp from './Pages/SignUp';
import LogIn from './Pages/LogIn';
import Chat from './Pages/Chat';
import Basics from './Pages/ProfileBasics';
import About from './Pages/ProfileAbout';
import AboutSection from './Pages/ProfileAboutSection';
import ThisOrThat from './Pages/ProfileThisOrThat';
import Memories from './Pages/ProfileMemories';
import Privacy from './Pages/Privacy';
import Error404 from './Pages/Error404';
import Memory from './Pages/ProfileMemoriesViewEdit';
import ResetPassword from './Pages/ResetPassword';
import ShareMemory from './Pages/ShareMemory';
import CreateAndEditMemory from './Pages/CreateAndEditMemory';
import OnboardingFlow from './Pages/OnboardingFlow';
import FriendsAndFamily from './Pages/FriendsAndFamily';
import MyFriendMemories from './Pages/MyFriendMemories';
import SuccessSaveMemory from './Pages/SuccessSaveMemory';
import MyFriendsMemories from './Pages/MyFriendsMemories';
import goPremium from './Pages/goPremium';
import ShareMyFriendMemories from './Pages/ShareMyFriendMemories';
import HelpCenter from './Pages/HelpCenter';
import EmailTheFuture from './Pages/EmailTheFuture';
import Bonus from './Pages/Bonus';
import MilestoneMemoriesPage from './Pages/MilestoneMemoriesPage';
import CreateOtherPage from './Pages/CreateOther';
import SuccessSaveOther from './Pages/SuccessSaveMemoryOther';
import GiftMemory from './Pages/GiftMemory';
import SuccessLanding from './Pages/SuccessLanding';
import GroupsPage from './Pages/Groups/AllGroups';
import GroupPage from './Pages/Groups/Group';
import CreateGroupPage from './Pages/Groups/CreateGroup';
import GroupAdminPage from './Pages/Groups/GroupAdmin';
import GroupMemberPage from './Pages/Groups/GroupMembers';
import AlbumsPage from './Pages/Albums';
import AlbumPage from './Pages/Album';
import PromptPage from './Pages/Prompts';
import InvitePage from './Pages/InviteFriend';
import PreviewInvite from './Pages/PreviewInvite';

// ROUTES
import {
  LANDING_URL,
  LOGIN_URL,
  SIGNUP_URL,
  PRIVACY_URL,
  CHAT_URL,
  BASICS_URL,
  MEMORIES_URL,
  ABOUT_URL,
  THISORTHAT_URL,
  RESET_URL,
  ABOUT_SECTION_URL,
  MEMORY_URL,
  SHARE_MEMORY_URL,
  CREATE_MEMORY_URL,
  ONBOARDING_FLOW,
  FRIENDS_AND_FAMILY_URL,
  MY_FRIEND_MEMORIES,
  MY_FRIENDS_MEMORIES,
  PREVIEW_MEMORY_URL,
  SUCCESS_SAVE_MEMORY_URL,
  GO_PREMIUM,
  GOOGLE_LOGIN_URL,
  GOOGLE_SIGNUP_URL,
  DASHBOARD_URL,
  HELP_CENTER_URL,
  EMAIL_THE_FUTURE_URL,
  BONUS_URL,
  MILESTONES_URL,
  CREATE_OTHER_URL,
  SUCCESS_SAVE_OTHER_URL,
  GIFT_MEMORY_URL,
  GROUPS_URL,
  GROUPS_CREATE_URL,
  GROUP_URL,
  GROUP_ADMIN_URL,
  GROUP_MEMBER_URL,
  ALBUMS_URL,
  ALBUM_URL,
  INVITE_URL,
} from '../constants/constantUrls';

import { API_LOGOUT_URL } from '../constants/apiRoutes';

export default props => {
  const {
    handleChangeState,
    isLoggedIn,
    windowWidth,
    competition = {},
  } = props;
  const [imagesArray, setImagesArray] = useState([]);
  const [voice, setVoice] = useState('');
  const [video, setVideo] = useState('');
  const [imagesArrayRequest, setImagesArrayRequest] = useState([]);
  const [memoryId, setMemoryId] = useState(null);
  const [tempImages, setTempImages] = useState([]);
  const [tempVoice, setTempVoice] = useState(null);
  const [tempVideo, setTempVideo] = useState(null);
  const [isPublic, setIsPublic] = useState(false);
  const [taggedEmails, setTaggedEmails] = useState([]);
  const [privacySetting, setPrivacySetting] = useState('Friends & Family');

  const [editExisting, setEditExisting] = useState(false);
  // used for memory media upload
  const uploadObject = {
    imagesArray,
    setImagesArray,
    voice,
    setVoice,
    video,
    setVideo,
    imagesArrayRequest,
    setImagesArrayRequest,
    tempImages,
    setTempImages,
    tempVoice,
    setTempVoice,
    tempVideo,
    setTempVideo,
    isPublic,
    setIsPublic,
    taggedEmails,
    setTaggedEmails,
    privacySetting,
    setPrivacySetting,
  };

  const onIdle = () => {
    if (isLoggedIn) {
      axios
        .get(API_LOGOUT_URL)
        .then(() => {
          handleChangeState({
            ...initialState,
            windowWidth: window.innerWidth,
          });
          window.location = LOGIN_URL;
        })
        .catch(err => console.error(err));
    }
  };

  useIdleTimer({
    onIdle,
    timeout: 15 * 60 * 1000,
    throttle: 500,
  });

  return (
    <>
      <ScrollRestoration />
      <Switch>
        {/* PUBLIC ROUTES */}
        <Route
          exact
          path={LANDING_URL}
          render={linkProps =>
            isLoggedIn ? (
              <Redirect to={DASHBOARD_URL} />
            ) : (
              <LogIn
                handleChangeState={handleChangeState}
                {...linkProps}
                {...props}
              />
            )
          }
        />

        <Route
          exact
          path={MY_FRIEND_MEMORIES}
          render={routerProps => {
            if (isLoggedIn) {
              return (
                <PrivateRoute
                  exact
                  path={MY_FRIEND_MEMORIES}
                  Component={MyFriendMemories}
                  handleChangeState={handleChangeState}
                  isLoggedIn={isLoggedIn}
                  header
                  {...props}
                />
              );
            }
            const {
              match: {
                // eslint-disable-next-line no-unused-vars
                params: { shortId },
              },
            } = routerProps;
            return (
              <ShareMyFriendMemories
                {...props}
                {...routerProps}
                windowWidth={windowWidth}
              />
            );
          }}
        />

        <Route exact path={PRIVACY_URL} component={Privacy} />
        {/* <NotPrivateRoute
          exact
          path={`${RESET_URL}/:token?`}
          component={(linkProps) => {
            const {
              location: { state },
            } = linkProps;
            // check if user request comes from profile page
            if (state && state.resetAsLoggedIn) {
              return <ResetPassword {...linkProps} {...props} />;
            }
            return isLoggedIn ? (
              <Redirect to={DASHBOARD_URL} />
            ) : (
              <ResetPassword {...linkProps} {...props} />
            );
          }}
        /> */}
        <Route
          exact
          path={`${RESET_URL}/:token?`}
          render={linkProps => {
            const {
              location: { state },
            } = linkProps;
            // check if user request comes from profile page
            if (state && state.resetAsLoggedIn) {
              return <ResetPassword {...linkProps} {...props} />;
              // return <ResetPassword {...linkProps} {...props} />;
            }
            return isLoggedIn ? (
              <Redirect to={DASHBOARD_URL} />
            ) : (
              <ResetPassword {...linkProps} {...props} />
            );
          }}
        />
        <Route
          exact
          path={SHARE_MEMORY_URL}
          // uses render attribute so that we can pass the windowWidth as a prop
          render={routerProps => (
            <ShareMemory
              {...props}
              {...routerProps}
              windowWidth={windowWidth}
            />
          )}
        />

        <NotPrivateRoute
          exact
          path={CREATE_OTHER_URL}
          Component={CreateOtherPage}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        <NotPrivateRoute
          exact
          path="/mothers-day"
          Component={SuccessLanding}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header={false}
          {...props}
        />

        {/* PRIVATE ROUTES */}

        <PrivateRoute
          exact
          path={MY_FRIENDS_MEMORIES}
          Component={MyFriendsMemories}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        <PrivateRoute
          exact
          path={GO_PREMIUM}
          Component={goPremium}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        <PrivateRoute
          exact
          path={CHAT_URL}
          Component={Chat}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />
        <PrivateRoute
          exact
          path={ALBUMS_URL}
          Component={AlbumsPage}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />
        <NotPrivateRoute
          exact
          path={ALBUM_URL}
          Component={AlbumPage}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />
        <PrivateRoute
          exact
          path={BASICS_URL}
          Component={Basics}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />
        <PrivateRoute
          exact
          path="/prompts"
          Component={PromptPage}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />
        <PrivateRoute
          exact
          path={ABOUT_URL}
          Component={About}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />
        <PrivateRoute
          exact
          path={ABOUT_SECTION_URL}
          Component={AboutSection}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />
        <PrivateRoute
          exact
          path={MEMORIES_URL}
          Component={Memories}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />
        <PrivateRoute
          exact
          path={PREVIEW_MEMORY_URL}
          Component={Memory}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          viewMode="preview"
          // create and view memory props chain
          setMemoryId={setMemoryId}
          memoryId={memoryId}
          editExisting={editExisting}
          setEditExisting={setEditExisting}
          uploadObject={uploadObject}
          {...props}
        />
        <PrivateRoute
          exact
          path={MEMORY_URL}
          Component={Memory}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          viewMode="view"
          header
          // create and view memory props chain
          setMemoryId={setMemoryId}
          memoryId={memoryId}
          editExisting={editExisting}
          setEditExisting={setEditExisting}
          uploadObject={uploadObject}
          {...props}
        />
        <PrivateRoute
          exact
          path={THISORTHAT_URL}
          Component={ThisOrThat}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />
        <PrivateRoute
          exact
          path={`${CREATE_MEMORY_URL}/:category?`}
          Component={CreateAndEditMemory}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          // create and view memory props chain
          setMemoryId={setMemoryId}
          memoryId={memoryId}
          editExisting={editExisting}
          setEditExisting={setEditExisting}
          uploadObject={uploadObject}
          {...props}
        />

        <PrivateRoute
          exact
          path={SUCCESS_SAVE_MEMORY_URL}
          Component={SuccessSaveMemory}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        <PrivateRoute
          exact
          path={GROUPS_URL}
          Component={GroupsPage}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        <PrivateRoute
          exact
          path={GROUPS_CREATE_URL}
          Component={CreateGroupPage}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        <PrivateRoute
          exact
          path={GROUP_ADMIN_URL}
          Component={GroupAdminPage}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          {...props}
        />

        <PrivateRoute
          exact
          path={GROUP_MEMBER_URL}
          Component={GroupMemberPage}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          {...props}
        />

        <PrivateRoute
          exact
          path={INVITE_URL}
          Component={InvitePage}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        <NotPrivateRoute
          exact
          path={GROUP_URL}
          Component={GroupPage}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          {...props}
        />

        <NotPrivateRoute
          exact
          path={SUCCESS_SAVE_OTHER_URL}
          Component={SuccessSaveOther}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        {/* <PrivateRoute
          exact
          path={FRIENDS_AND_FAMILY_URL}
          Component={FriendsAndFamily}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          isEditing
          {...props}
        /> */}

        <PrivateRoute
          exact
          path={ONBOARDING_FLOW}
          Component={OnboardingFlow}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          // header
          competition={competition}
          noMenu
          {...props}
        />

        <PrivateRoute
          exact
          path={DASHBOARD_URL}
          Component={Dashboard}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        <PrivateRoute
          exact
          path={MILESTONES_URL}
          Component={MilestoneMemoriesPage}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        <PrivateRoute
          exact
          path={EMAIL_THE_FUTURE_URL}
          Component={EmailTheFuture}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        <PrivateRoute
          exact
          path={HELP_CENTER_URL}
          Component={HelpCenter}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        <PrivateRoute
          exact
          path={`${BONUS_URL}/:userId?/:bonusId?`}
          Component={Bonus}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        <PrivateRoute
          exact
          path={GIFT_MEMORY_URL}
          Component={GiftMemory}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        {/* SIGN UP LOG IN */}
        <Route
          path={`${SIGNUP_URL}`}
          exact
          render={linkProps =>
            isLoggedIn ? (
              <Redirect
                to={{
                  pathname: DASHBOARD_URL,
                  state: { referralCode: linkProps.match?.params?.id },
                }}
              />
            ) : (
              <SignUp
                handleChangeState={handleChangeState}
                {...linkProps}
                {...props}
              />
            )
          }
        />

        <NotPrivateRoute
          exact
          path={`${SIGNUP_URL}/:id?`}
          Component={PreviewInvite}
          handleChangeState={handleChangeState}
          isLoggedIn={isLoggedIn}
          header
          {...props}
        />

        {/* new route for signup w/ :id */}
        <Route
          path={`${GOOGLE_SIGNUP_URL}/:id?`}
          exact
          render={linkProps =>
            isLoggedIn ? (
              <Redirect to={DASHBOARD_URL} />
            ) : (
              <SignUp
                handleChangeState={handleChangeState}
                {...linkProps}
                {...props}
              />
            )
          }
        />
        <Route
          exact
          path={`${LOGIN_URL}/:id?`}
          render={linkProps =>
            isLoggedIn ? (
              <Redirect to={DASHBOARD_URL} />
            ) : (
              <LogIn
                handleChangeState={handleChangeState}
                {...linkProps}
                {...props}
              />
            )
          }
        />
        <Route
          exact
          path={`${GOOGLE_LOGIN_URL}/:id?`}
          render={linkProps =>
            isLoggedIn ? (
              <Redirect to={DASHBOARD_URL} />
            ) : (
              <LogIn
                handleChangeState={handleChangeState}
                {...linkProps}
                {...props}
              />
            )
          }
        />
        <Route component={Error404} />
      </Switch>
    </>
  );
};
