import styled from 'styled-components';

export const Title = styled.h2`
  color: var(--primary-navy);
  font-size: 1.7rem;
  margin-bottom: 2rem;
`;

export const Description = styled.p`
  font-size: 1rem;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  & button {
    margin: 0;
  }
`;
