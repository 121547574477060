export const colors = {
  primary: '#10c1b8',
  success: '#48c774',
  warning: '#F14668',
  darkSuccess: '#30b960',
  secondary: '#0bafaa',
  darkBlue:'#0a2156',
  tertiary: '#0a2156',  
  tertiarySecondary: '#0e2d6b',
  darkBlueSecondary: '#071b44',
  white: '#FFFFFF',
  black: '#2B2B2B',
  error: '#E82C48',
  lightGray: '#aaaaaa',
  // pink: '#e8529b',
  gray: '#eaeaea',
  darkGray:'#969696',
  lightBlack: '#747373',
  mainGreen: '#66CC66',
  lightPink: '#FDFAFB',
};

const shadows = {
  primary: '2px 4px 4px rgba(102, 204, 102, 0.25)',
  secondary: '2px 4px 4px rgba(232, 82, 155, 0.25)',
};

const borders = {
  input: `1px solid ${colors.primary}`,
  inputFocus: `1px solid ${colors.secondary}`,
  inputError: `1px solid ${colors.error}`,
  recording: `1px solid ${colors.pink}`,
};

const theme = {
  colors,
  shadows,
  borders,
};

export default theme;
