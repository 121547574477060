// React
import React, { useState } from 'react';

// API
import { milestones } from '../../../api';

// Styles
import { PageWrapper } from './style';

// Custom Components
import { MilestoneMemories } from '../../../NewComponents/Pages/CreateMemory/MilestoneMemory/MilestoneMemories';

const MilestoneMemoriesPage = ({
  milestone = false,
  isModal = false,
  getMilestonesFunc = () => {},
  closeButtonFunc = () => {},
}) => {
  const [description, setDescription] = useState(milestone?.descriptionVal);
  const [date, setDate] = useState(milestone?.dateVal || new Date());
  const [category, setCategory] = useState(milestone?.categoryVal);
  const [title, setTitle] = useState(milestone?.title);

  const submitButtonFunc = async () => {
    const { data, error } = await milestones.createMilestone({
      milestone: {
        description,
        date,
        title,
        category,
      },
    });

    if (data && isModal) {
      getMilestonesFunc();
    }
    if (data && !error) {
      closeButtonFunc();
    }
  };

  const updateButtonFunc = async () => {
    const { data, error } = await milestones.updateMilestone({
      milestone: {
        title,
        date,
        category,
        description,
        milestoneId: milestone?._id,
      },
    });

    if (data && isModal) {
      getMilestonesFunc();
    }

    if (data && !error) {
      closeButtonFunc();
    }
  };

  const deleteButtonFunc = async () => {
    const { data, error } = await milestones.deleteMilestone({
      id: milestone?._id,
    });

    if (data && isModal) {
      await getMilestonesFunc();
    }

    if (data && !error) {
      closeButtonFunc();
    }
  };

  return (
    <PageWrapper>
      <MilestoneMemories
        isModal={isModal}
        isEditing={milestone?._id}
        descriptionVal={description}
        setDescriptionVal={setDescription}
        dateVal={date}
        setDateVal={setDate}
        categoryVal={category}
        setCategoryVal={setCategory}
        title={title}
        setTitle={setTitle}
        submitButtonFunc={submitButtonFunc}
        updateButtonFunc={updateButtonFunc}
        deleteButtonFunc={deleteButtonFunc}
        closeButtonFunc={closeButtonFunc}
      />
    </PageWrapper>
  );
};

export default MilestoneMemoriesPage;
