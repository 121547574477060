import axios from 'axios';

import { 
  API_ALBUM_URL, 
  API_ALBUMS_URL, 
  API_ALBUM_PHOTO_URL,
  API_ALBUM_COMMENT_URL,
} from '../constants/apiRoutes';

import makeError from './makeError';

const getAlbum = async ({ albumId, options }) => {
  try {
    const { data } = await axios.get(`${API_ALBUM_URL}/${albumId}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const getUserAlbums = async ({ options }) => {
  try {
    const { data } = await axios.get(`${API_ALBUMS_URL}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const getAlbumPhoto = async ({ photoId, options }) => {
  try {
    const { data } = await axios.get(`${API_ALBUM_PHOTO_URL}/${photoId}`);
    if (data) {
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
}

const postAlbum = async ({ album, options }) => {
  try {
    const { data } = await axios.post(`${API_ALBUM_URL}`, album);
    if (data) {
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const postAlbumPhoto = async ({ albumPhoto, options }) => {
  try { 
    const { data } = await axios.post(`${API_ALBUM_PHOTO_URL}`, albumPhoto);
    if (data) {
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
}

const postAlbumComment = async ({ albumComment, options }) => {
  try {
    const { data } = await axios.post(API_ALBUM_COMMENT_URL, albumComment);
    if (data) {
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
}

const updateAlbum = async ({ album, options }) => {
  try {
    const { data } = await axios.patch(API_ALBUM_URL, album);
    if (data) {
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
}

const updateAlbumPhoto = async ({ photo, options }) => {
  try {
    const { data } = await axios.patch(API_ALBUM_PHOTO_URL, photo);
    if (data) {
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
}

const deleteAlbum = async ({ albumId, options }) => {
  try {
    const { data } = await axios.delete(`${API_ALBUM_URL}/${albumId}`);
    if (data) {
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
}

const deleteAlbumPhoto = async ({ photoId, options }) => {
  try {
    const { data } = await axios.delete(`${API_ALBUM_PHOTO_URL}/${photoId}`);
    if (data) {
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
}

const deleteAlbumComment = async ({ commentId, options }) => {
  try {
    const { data } = await axios.delete(`${API_ALBUM_COMMENT_URL}/${commentId}`);
    if (data) {
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
}

const album = {
  getAlbum,
  getUserAlbums,
  getAlbumPhoto,
  postAlbum,
  postAlbumPhoto,
  postAlbumComment,
  updateAlbum,
  updateAlbumPhoto,
  deleteAlbum,
  deleteAlbumPhoto,
  deleteAlbumComment,
};

export default album;
