import styled from 'styled-components';
import Button from '../../Common/Button';

export const Main = styled.main`
  background-color: var(--primary);
  height: 100vh;
  width: 100vw;
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
`;

export const Section = styled.section`
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: var(--secondary-aqua);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

export const H1 = styled.h1`
  color: var(--white);
  font-size: 2rem;
  line-height: 36px;
  font-weight: 600;
  text-transform: none;
  padding: 1rem 1rem;
  text-align: center;
`;

export const H1Caps = styled(H1)`
  text-transform: uppercase;
`;

export const Img = styled.img`
  width: 50vw;
  max-width: 20rem;
  height: auto;
  animation: rotation 5s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const ImgStill = styled(Img)`
  animation: none;
  width: initial;
`;

export const P = styled.p`
  color: var(--white);
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const ButtonSection = styled.section`
  display: flex;
  flex-flow: row wrap;
  margin: 2rem 0;

  button {
    font-size: 1rem;
    width: 8rem;
    padding: 0.5rem;
    margin: 0.5rem;
  }
`;

export const ButtonLoading = styled(Button)``;
