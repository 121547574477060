import React, { Component } from "react";
import axios from "axios";

import { emitter } from "@marvelapp/react-ab-test";
import { Link } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { FaFacebook } from "react-icons/fa";
import TextLoop from "react-text-loop";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MuiButton from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import HeaderLogout from "../../Common/HeaderLogout";
import { sizeNum } from "../../../constants/breakPoints";
import Carousel from "react-material-ui-carousel";

// Icons
import { createErrors, validate } from "../../../helpers/validation";
import { LogoWrapper } from "./style";
import logo from "../../../assets/og-logo.svg";
import whitelogo from "../../../assets/onboarding/logo-white.png";

// STYLING
import * as S from "./style";

// API
import { competitions, users } from "../../../api";

// A/B TESTING IMPORTS
import Button from "../../Common/Button";
import Spinner from "../../Common/Spinner";

import { Divider } from "../../../NewComponents/Divider/Divider";

// ROUTES
import {
  ONBOARDING_FLOW,
  MY_FRIEND_MEMORIES,
  DASHBOARD_URL,
  PRIVACY_URL,
  LOGIN_URL,
} from "../../../constants/constantUrls";
import {
  API_SIGNUP_URL,
  API_GOOGLE_LOGIN_URL,
  API_GOOGLE_SIGNUP_URL,
  API_FACEBOOK_LOGIN_URL,
  API_FACEBOOK_SIGNUP_URL,
  API_PERMISSION_ACCESS_EMAIL,
} from "../../../constants/apiRoutes";

import ReactGA from "react-ga";
// ---------------- sign up components ----------------
// import { SignUp as SignUpComponent } from '../../../NewComponents/LoginSignUp/Sign Up/SignUp';

// A/B Testing

// experimentDebugger.enable();
// emitter.defineVariants(
//   'signUpPageExperiment',
//   ['old-signup', 'new-signup'],
//   [50, 50],
// );
// const mixpanel = Mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY);

export default class SignUp extends Component {
  state = {
    requiredFields: {
      email: "",
      password: "",
      agreeTerms: true,
      referralCode: "",
    },
    mailingList: true,
    showPassword: false,
    errors: {},
    nameMemoryOwner: "",
    shareMemory: false,
    shortId: null,
    isLoading: false,
    showSubmitErrors: false,
    singleError: false,
    magicFreebiesId: "",
  };

  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const { id } = this.props.match.params;
    const { search } = this.props.location;
    this.setState({ isLoading: true });
    if (id) {
      this.setState({ isLoading: false });
      const { requiredFields } = this.state;
      requiredFields["referralCode"] = id;
      this.setState({ requiredFields });
    }
    if (search) {
      if (search.includes("c_id")) {
        this.setState({ magicFreebiesId: search.replace("?c_id=", "") });
      }
    }
    return this.setState({
      isLoading: false,
      shareMemory: false,
    });
  }

  handleInput = (e) => {
    const { requiredFields } = this.state;
    requiredFields[e.target.name] = e.target.value;
    this.setState({ requiredFields });
  };

  // validates form fields and creates errors
  validator = async (e) => {
    // get current input
    const {
      target: { name },
    } = e;

    const {
      requiredFields: { email, password },
      errors,
    } = this.state;

    const checkedEmail = email.replace(/\s+/g, "");
    const { requiredFields } = this.state;
    requiredFields["email"] = checkedEmail;
    this.setState({ requiredFields });

    let { singleError } = this.state;

    this.setState({ showSubmitErrors: false });
    try {
      // validate all fields
      const signUpData = { email: checkedEmail, password };
      const validated = await validate("signUp", signUpData);

      if (validated) {
        // reset error state
        this.setState({ errors: {} });
      }
    } catch (error) {
      // all form errors (to check submission)
      const submitErrors = createErrors(error);

      if (submitErrors[name]) {
        singleError = { [name]: submitErrors[name] };
      } else {
        singleError = "";
      }

      this.setState({
        errors: { ...errors, submitErrors },
        singleError,
      });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { history, handleChangeState } = this.props;

    const {
      requiredFields,
      mailingList,
      shareMemory,
      userId,
      shortId,
      errors,
    } = this.state;

    const {
      email,
      name,
      password,
      confirmPassword,
      agreeTerms,
      referralCode,
    } = requiredFields;
    this.setState({ isLoading: true });

    try {
      // validate form
      const signUpData = {
        email,
        password,
        confirmPassword,
        agreeTerms,
      };
      const validated = await validate("signUp", signUpData);
      if (validated) {
        this.setState({ errors: {} });

        const userInfo = { email, name, password, referralCode, mailingList };
        if (shareMemory) {
          userInfo.referredBy = userId;
        }
        const signedUp = await axios.post(API_SIGNUP_URL, { userInfo });

        if (signedUp) {
          const { data } = signedUp;
          ReactGA.event({
            category: "Sign Up",
            action: "Signed Up",
          });
          if (
            this.state.magicFreebiesId &&
            this.state.magicFreebiesId.length > 0
          ) {
            axios.get(
              `https://www.veneficus.co.uk/tp/?vtcid=${this.state.magicFreebiesId}`
            );
          }
          if (referralCode) {
            if (referralCode === "groups") {
              handleChangeState({ ...data, isLoggedIn: true, groups: true });
              this.setState({ isLoading: false });
              return history.push({
                pathname: ONBOARDING_FLOW,
                state: { groups: true },
              });
            }
            const competition = await competitions.getCompetitionByCode({
              competitionCode: referralCode,
            });
            if (competition && competition.data !== null) {
              handleChangeState({
                ...data,
                isLoggedIn: true,
                competition: competition,
              });
              this.setState({ isLoading: false });
              return history.push({
                pathname: ONBOARDING_FLOW,
                state: {
                  competition: competition,
                },
              });
            }
            const referredByUser = await users.getNameAndIdFromLinkToken({
              shortId: referralCode,
            });
            if (referredByUser && referredByUser.data !== null) {
              const permissionAccess = await axios.post(
                API_PERMISSION_ACCESS_EMAIL,
                {
                  sharedWithEmail: data.email,
                  sharedWithId: data.id,
                  owner: referredByUser.data.userId,
                }
              );
              handleChangeState({ ...data, isLoggedIn: true });
              this.setState({ isLoading: false });
              return history.push({
                pathname: ONBOARDING_FLOW,
                state: { isReferral: !!shortId, shortId },
              });
            }
            handleChangeState({ ...data, isLoggedIn: true });
            this.setState({ isLoading: false });
            return history.push({
              pathname: ONBOARDING_FLOW,
              state: { isReferral: !!shortId, shortId },
            });
          } else {
            handleChangeState({ ...data, isLoggedIn: true });
            this.setState({ isLoading: false });
            return history.push({
              pathname: ONBOARDING_FLOW,
              state: { isReferral: !!shortId, shortId },
            });
          }
        }
      }
    } catch (error) {
      this.setState({ isLoading: false });

      // frontend validation
      if (error.name === "ValidationError") {
        const submitErrors = createErrors(error);

        this.setState({
          errors: { ...errors, submitErrors },
        });
      }

      // backend validation
      if (
        error.response &&
        error.response.data.error.includes("Email already taken")
      ) {
        errors.submitError = error.response.data.error;

        this.setState({
          errors: {
            ...errors,
          },
        });
      }
    }
  };

  onButtonSubmit = (e) => {
    emitter.emitWin("signUpPageExperiment");
  };

  createValidationError = async (error) => {
    // frontend validation
    this.setState({ isLoading: false });
    if (error.name === "ValidationError") {
      const submitErrors = createErrors(error);

      this.setState({
        errors: { ...error, submitErrors },
      });
    }
    if (
      error.response &&
      error.response.data.error.includes(
        "Login failed. Email already used by Iternal account"
      )
    ) {
      error.submitError = error.response.data.error;

      this.setState({
        errors: {
          ...error,
        },
      });
    }
    if (
      error.response &&
      error.response.data.error.includes("Email already taken")
    ) {
      error.submitError = error.response.data.error;

      this.setState({
        errors: {
          ...error,
        },
      });
    }
  };

  responseFacebook = async (response) => {
    const { email } = response;
    const facebookId = response.id;
    const { history, handleChangeState, match } = this.props;
    const { id: shortId } = match.params;
    const { referralCode } = this.state.requiredFields;

    this.setState({ isLoading: true });
    try {
      const facebookLoginData = { email, facebookId };
      const validated = await validate("facebookLogin", facebookLoginData);
      if (validated) {
        this.setState({ errors: {} });
        const loggedIn = await axios.post(
          API_FACEBOOK_LOGIN_URL,
          facebookLoginData
        );
        if (!loggedIn.data.no_account_found) {
          const { data } = loggedIn;
          handleChangeState({ ...data, isLoggedIn: true });
          this.setState({ isLoading: false });
          return history.push(
            shortId
              ? MY_FRIEND_MEMORIES.replace(":shortId", shortId)
              : DASHBOARD_URL
          );
        }
        try {
          const signUpData = { email, facebookId, agreeTerms: true };
          const validated = await validate("facebookSignUp", signUpData);
          if (validated) {
            this.setState({ errors: {} });
            const userInfo = {
              email,
              facebookId,
              mailingList: true,
              referralCode,
            };
            const signedUp = await axios.post(API_FACEBOOK_SIGNUP_URL, {
              userInfo,
            });
            if (signedUp) {
              const { data } = signedUp;
              ReactGA.event({
                category: "Sign Up",
                action: "Signed Up Via Facebook",
              });
              if (
                this.state.magicFreebiesId &&
                this.state.magicFreebiesId.length > 0
              ) {
                axios.get(
                  `https://www.veneficus.co.uk/tp/?vtcid=${this.state.magicFreebiesId}`
                );
              }
              if (referralCode) {
                if (referralCode === "groups") {
                  handleChangeState({
                    ...data,
                    isLoggedIn: true,
                    groups: true,
                  });
                  this.setState({ isLoading: false });
                  return history.push({
                    pathname: ONBOARDING_FLOW,
                    state: { groups: true },
                  });
                }
                const competition = await competitions.getCompetitionByCode({
                  competitionCode: referralCode,
                });
                if (competition && competition.data !== null) {
                  handleChangeState({
                    ...data,
                    isLoggedIn: true,
                    competition: competition,
                  });
                  this.setState({ isLoading: false });
                  return history.push({
                    pathname: ONBOARDING_FLOW,
                    state: { competition: competition },
                  });
                }
                const referredByUser = await users.getNameAndIdFromLinkToken({
                  shortId: referralCode,
                });
                if (referredByUser && referredByUser.data !== null) {
                  const permissionAccess = await axios.post(
                    API_PERMISSION_ACCESS_EMAIL,
                    {
                      sharedWithEmail: data.email,
                      sharedWithId: data.id,
                      owner: referredByUser.data.userId,
                    }
                  );
                  handleChangeState({ ...data, isLoggedIn: true });
                  this.setState({ isLoading: false });
                  return history.push({
                    pathname: ONBOARDING_FLOW,
                    state: { isReferral: !!shortId, shortId },
                  });
                }
                handleChangeState({ ...data, isLoggedIn: true });
                this.setState({ isLoading: false });
                return history.push({
                  pathname: ONBOARDING_FLOW,
                  state: { isReferral: !!shortId, shortId },
                });
              } else {
                handleChangeState({ ...data, isLoggedIn: true });
                this.setState({ isLoading: false });
                return history.push({
                  pathname: ONBOARDING_FLOW,
                  state: { isReferral: !!shortId, shortId },
                });
              }
            }
          }
        } catch (error) {
          this.createValidationError(error);
        }
      }
    } catch (error) {
      this.createValidationError(error);
    }
  };

  responseGoogle = async (response) => {
    this.setState({ isLoading: true });
    try {
      const { googleId, email } = response.profileObj;
      const { history, handleChangeState, match } = this.props;
      const { id: shortId } = match.params;
      const { referralCode } = this.state.requiredFields;

      const googleLoginData = { email, googleId };
      const validated = await validate("googleLogin", googleLoginData);
      if (validated) {
        this.setState({ errors: {} });
        const loggedIn = await axios.post(
          API_GOOGLE_LOGIN_URL,
          googleLoginData
        );
        if (!loggedIn.data.no_account_found) {
          const { data } = loggedIn;
          handleChangeState({ ...data, isLoggedIn: true });
          this.setState({ isLoading: false });
          return history.push(
            shortId
              ? MY_FRIEND_MEMORIES.replace(":shortId", shortId)
              : DASHBOARD_URL
          );
        }
        try {
          // validate form
          const signUpData = { email, googleId, agreeTerms: true };
          const validated = await validate("googleSignUp", signUpData);
          if (validated) {
            this.setState({ errors: {} });
            const userInfo = {
              email,
              googleId,
              mailingList: true,
              referralCode,
            };
            const signedUp = await axios.post(API_GOOGLE_SIGNUP_URL, {
              userInfo,
            });
            if (signedUp) {
              ReactGA.event({
                category: "Sign Up",
                action: "Signed Up Via Google",
              });
              const { data } = signedUp;
              if (
                this.state.magicFreebiesId &&
                this.state.magicFreebiesId.length > 0
              ) {
                axios.get(
                  `https://www.veneficus.co.uk/tp/?vtcid=${this.state.magicFreebiesId}`
                );
              }
              if (referralCode) {
                if (referralCode === "groups") {
                  handleChangeState({
                    ...data,
                    isLoggedIn: true,
                    groups: true,
                  });
                  this.setState({ isLoading: false });
                  return history.push({
                    pathname: ONBOARDING_FLOW,
                    state: { groups: true },
                  });
                }
                const competition = await competitions.getCompetitionByCode({
                  competitionCode: referralCode,
                });
                if (competition && competition.data !== null) {
                  this.setState({ isLoading: false });
                  handleChangeState({
                    ...data,
                    isLoggedIn: true,
                    competition: competition,
                  });
                  return history.push({
                    pathname: ONBOARDING_FLOW,
                    state: { competition: competition },
                  });
                }
                const referredByUser = await users.getNameAndIdFromLinkToken({
                  shortId: referralCode,
                });
                if (referredByUser && referredByUser.data !== null) {
                  const permissionAccess = await axios.post(
                    API_PERMISSION_ACCESS_EMAIL,
                    {
                      sharedWithEmail: data.email,
                      sharedWithId: data.id,
                      owner: referredByUser.data.userId,
                    }
                  );
                  handleChangeState({ ...data, isLoggedIn: true });
                  this.setState({ isLoading: false });
                  return history.push({
                    pathname: ONBOARDING_FLOW,
                    state: { isReferral: !!shortId, shortId },
                  });
                }
                handleChangeState({ ...data, isLoggedIn: true });
                this.setState({ isLoading: false });
                return history.push({
                  pathname: ONBOARDING_FLOW,
                  state: { isReferral: !!shortId, shortId },
                });
              } else {
                this.setState({ isLoading: false });
                handleChangeState({ ...data, isLoggedIn: true });
                return history.push({
                  pathname: ONBOARDING_FLOW,
                  state: { isReferral: !!shortId, shortId },
                });
              }
            }
          }
        } catch (error) {
          this.createValidationError(error);
        }
      }
    } catch (error) {
      this.createValidationError(error);
    }
  };

  handleCheckbox = (e) => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const {
      errors,
      shareMemory,
      shortId,
      isLoading,
      requiredFields,
      showSubmitErrors,
      singleError,
      showPassword,
    } = this.state;

    const { password } = requiredFields;
    const { windowWidth } = this.props;

    const slides = [
      {
        img:
          "https://iternal.life/wp-content/uploads/2021/03/create_and_save_your_memories.png",
        title: "Save your memories",
        body: "Sign up for free and tell your story in your own words",
      },
      {
        img:
          "https://iternal.life/wp-content/uploads/2021/03/see_your_life_in_perspective.png",
        title: "See your life in perspective",
        body:
          "Bring your story to life with your personalised timeline of memories",
      },
      {
        img:
          "https://iternal.life/wp-content/uploads/2021/03/share_with_loved_ones.png",
        title: "Share with loved ones",
        body: "Securely tag people in your memories and share them directly",
      },
    ];

    return (
      <S.SignUpBackgroundWrapper>
        {/* {(!shareMemory || windowWidth >= sizeNum.laptop) && (
          <HeaderLogout windowWidth={windowWidth} noBackground />
        )} */}
        <Grid container xs={12}>
          <Grid item xs={6}>
            <img
              src={whitelogo}
              style={{
                maxHeight: "40px",
                marginLeft: "1.5rem",
                marginTop: "1.5rem",
                display: window.innerWidth > 900 ? "none" : "inline-flex",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginLeft: window.innerWidth < 400 ? "-2rem" : "0rem",
                marginRight: window.innerWidth < 400 ? "-2rem" : "0rem",
              }}
            >
              <S.StyledExtLink
                href={LOGIN_URL}
                rel="noopener noreferrer"
                target=""
                style={{
                  backgroundColor: "#0A2156",
                  padding: "1rem",
                  color: "white",
                  borderRadius: "100px",
                  boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',
                  textDecoration: "none",
                  marginTop: "1rem",
                  marginRight: "1rem",
                  display: window.innerWidth < 500 ? "none" : "block",
                }}
              >
                Sign in
              </S.StyledExtLink>
            </div>
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            minHeight: "90vh",
            marginLeft: "2rem",
            marginRight: "2rem",
            boxSizing: "border-box",
          }}
        >
          {/* {shareMemory && (
            <Header nameMemoryOwner={nameMemoryOwner} contentType="signup" />
          )} */}
          <section
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              display: window.innerWidth < 900 ? "none" : "inline-flex",
            }}
          >
            <div style={{}}>
              <img
                src={whitelogo}
                style={{ maxWidth: "80%", alignItems: "center" }}
              />
            </div>
          </section>

          <section
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {/* <LogoWrapper>
              <a
                href="https://iternal.life/"
                target="_blank"
                rel="noopener noreferrer"
              
              >
                <img alt="Iternal logo" src={logo} />
              </a>
            </LogoWrapper> */}
            <form onSubmit={(e) => this.handleSubmit(e)} style={{ flex: 0 }}>
              <Grid>
                <S.Fieldset
                  id="signup-whitebox"
                  style={{
                    maxWidth: "550px",
                    borderRadius: "20px",
                    padding: window.innerWidth < 600 ? "1rem" : "3rem",

                    margin: "2rem auto",
                    padding: window.innerWidth < 600 ? "1rem" : "3rem",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h4"
                        gutterBottom
                        style={{
                          textAlign: "left",
                          fontWeight: "bolder",
                          fontSize: window.innerWidth < 430 ? "2rem" : "2.5rem",
                        }}
                      >
                        Create Account
                      </Typography>
                      <p
                        style={{
                          fontSize: window.innerWidth < 430 ? "0.8rem" : "1rem",
                          marginBottom:
                            window.innerWidth < 430 ? "1.5rem" : "1rem",
                        }}
                      >
                        Already have an account?{" "}
                        <S.StyledExtLink
                          href={LOGIN_URL}
                          rel="noopener noreferrer"
                          target=""
                          style={{ color: "#0A2156" }}
                        >
                          Sign in
                        </S.StyledExtLink>
                      </p>
                      <S.Legend>Sign Up Form</S.Legend>
                      <S.Row>
                        <label
                          className="label"
                          style={{
                            marginBottom:
                              window.innerWidth < 430 ? "0rem" : "0.5rem",
                            marginBottom:
                              window.innerWidth < 430 ? "0rem" : "0.5rem",
                            marginTop:
                              window.innerWidth < 430 ? "0rem" : "1.5rem",

                            fontWeight: "bold",
                            fontSize:
                              window.innerWidth < 430 ? "0.8rem" : "1rem",
                          }}
                        >
                          Email Address
                        </label>
                        <S.Input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Your email address"
                          onChange={this.handleInput}
                          onBlur={(e) => this.validator(e)}
                          // onKeyPress={e => this.validator(e)}
                          error={
                            errors.submitErrors && errors.submitErrors.email
                          }
                        />
                        <S.Label htmlFor="email">Email address</S.Label>

                        {showSubmitErrors ? (
                          <S.ErrorMsg>
                            {errors.submitErrors && errors.submitErrors.email}
                          </S.ErrorMsg>
                        ) : (
                          <S.ErrorMsg>
                            {singleError.email && singleError.email}
                          </S.ErrorMsg>
                        )}
                      </S.Row>
                      <S.Row
                        style={{ marginTop: "1rem", marginBottom: "1rem" }}
                      >
                        <label
                          className="label"
                          style={{
                            marginBottom:
                              window.innerWidth < 430 ? "0rem" : "0.5rem",
                            fontWeight: "bold",
                            fontSize:
                              window.innerWidth < 430 ? "0.8rem" : "1rem",
                          }}
                        >
                          Password
                        </label>
                        <S.Input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          id="password"
                          placeholder="Your password"
                          value={(password || "").replace(/\s+/gi, " ")}
                          onChange={this.handleInput}
                          onBlur={(e) => this.validator(e)}
                          // onKeyPress={e => this.validator(e)}
                          error={
                            errors.submitErrors && errors.submitErrors.password
                          }
                        />
                        <S.Label htmlFor="password">Password</S.Label>
                        {showSubmitErrors ? (
                          <S.ErrorMsg>
                            {errors.submitErrors &&
                              errors.submitErrors.password}
                          </S.ErrorMsg>
                        ) : (
                          <S.ErrorMsg>
                            {singleError.password && singleError.password}
                          </S.ErrorMsg>
                        )}
                        <S.CheckboxLabel
                          style={{
                            marginTop:
                              window.innerWidth < 430 ? "0.5rem" : "1rem",
                          }}
                        >
                          Show Password
                          <S.Checkbox
                            type="checkbox"
                            name="showPassword"
                            id="showPassword"
                            onChange={(e) => this.handleCheckbox(e)}
                          />
                          <S.StyledCheckbox />
                        </S.CheckboxLabel>
                      </S.Row>
                      <S.Row marginBottom="1rem" />

                      {errors.submitError && (
                        <S.SubmitErrorMsg>
                          {errors.submitError}
                        </S.SubmitErrorMsg>
                      )}
                      <S.ButtonWrapper>
                        <MuiButton
                          style={{
                            height: "3rem",
                            fontSize:
                              window.innerWidth < 430 ? "0.9rem" : "1.1rem",
                            fontWeight: "bold",
                            borderRadius: "100px",
                            boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',

                            margin: window.innerWidth < 430 ? "0rem" : "1rem",
                          }}
                          color="secondary"
                          type="submit"
                          variant="contained"
                          disabled={isLoading}
                          onClick={() => {
                            this.setState({ showSubmitErrors: true });
                          }}
                        >
                          Sign Up
                        </MuiButton>
                        {isLoading && <Spinner />}
                      </S.ButtonWrapper>
                      <S.Row marginTop="15px">
                        {showSubmitErrors ? (
                          <S.ErrorMsg>
                            {errors.submitErrors &&
                              errors.submitErrors.agreeTerms}
                          </S.ErrorMsg>
                        ) : (
                          <S.ErrorMsg>
                            {singleError.agreeTerms && singleError.agreeTerms}
                          </S.ErrorMsg>
                        )}
                      </S.Row>
                      <S.SignInText>
                        <p
                          style={{
                            color: "grey",
                            fontSize:
                              window.innerWidth < 430 ? "0.8rem" : "1rem",
                          }}
                        >
                          <span>or Login with</span>
                        </p>
                      </S.SignInText>

                      <S.SocialLoginWrapper>
                        <GoogleLogin
                          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                          buttonText="Sign Up with Google"
                          onSuccess={(e) => this.responseGoogle(e)}
                          onFailure={(e) => this.responseGoogle(e)}
                          cookiePolicy="single_host_origin"
                        />
                        {/* <FacebookLogin
                          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                          fields="name,email,picture"
                          callback={(e) => this.responseFacebook(e)}
                          textButton="Sign Up with Facebook"
                          icon={<FaFacebook />}
                          disableMobileRedirect
                        /> */}
                        <p
                          style={{
                            paddingTop:
                              window.innerWidth < 430 ? "0.5rem" : "1rem",
                            textAlign: "center",
                            fontSize:
                              window.innerWidth < 430 ? "0.8rem" : "1rem",
                          }}
                        >
                          By signing up you agree to our{" "}
                          <S.StyledExtLink
                            href={PRIVACY_URL}
                            rel="noopener noreferrer"
                            target="_blank"
                            style={{ color: "#0A2156" }}
                          >
                            Ts and Cs
                          </S.StyledExtLink>
                        </p>
                      </S.SocialLoginWrapper>
                    </Grid>
                  </Grid>
                </S.Fieldset>
              </Grid>
            </form>
            {/* <S.LogInWrapper>
              <p style={{ marginTop: "15px", color: "white" }}>
                Already have an account?
              </p>
              <Link
                to={shortId ? `${LOGIN_URL}/${shortId}` : LOGIN_URL}
                style={{
                  fontSize: "20px",
                  fontWeight: "bolder",
                  textDecoration: "none",
                }}
              >
                <MuiButton variant="contained" color="secondary">
                  Log In
                </MuiButton>
              </Link>
            </S.LogInWrapper> */}
            {/* </Variant>
            </Experiment> */}
          </section>
        </div>
      </S.SignUpBackgroundWrapper>
    );
  }
}
