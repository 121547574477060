import React from 'react';
import PropTypes from 'prop-types';

// MUI
import { Paper, Typography, Grid, Button, Fade, Zoom } from '@material-ui/core';

// Icons
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import UnlimitedAnimation from './unlimited.webm';

// Styled components
import {
  TitleStyle,
  SubtitleStyle,
  PerkIconStyle,
  PerkTitleStyle,
  PerkParagraphStyle,
  MainPaperStyle,
  SubPaperStyle,
  SubPaperSmallStyle,
  SubPaperCenteredStyle,
  PerkIconProps,
  UpgradeNowSection,
} from './Upsell.style';

export function Upsell({ title, subtitle, nextFunc = () => {}, ...props }) {
  function renderPerkCard({
    style,
    variant = 'outlined',
    elevation = 0,
    icon,
    perkTitle,
    perkText,
    children,
  }) {
    return (
      <Zoom in timeout={1350}>
        <Paper variant={variant} elevation={elevation} style={style}>
          <Grid container spacing={2}>
            {icon && (
              <Grid item sm={3} xs={12}>
                <div style={PerkIconStyle}>{icon}</div>
              </Grid>
            )}
            <Grid item sm={icon ? 9 : 12} xs={12}>
              <Typography style={PerkTitleStyle}>{perkTitle}</Typography>

              <Typography style={PerkParagraphStyle} paragraph>
                {perkText}
              </Typography>

              {children}
            </Grid>
          </Grid>
        </Paper>
      </Zoom>
    );
  }

  return (
    <Fade in timeout={200}>
      <Paper style={MainPaperStyle} {...props}>
        <Typography style={TitleStyle} variant="h4" color="primary">
          {title}
        </Typography>
        <Typography style={SubtitleStyle} variant="h6">
          {subtitle}
        </Typography>

        {renderPerkCard({
          perkTitle: 'Unlimited storage',
          perkText: (
            <>
              You want to be remembered for eternity, right? That takes more
              than just a few memories to capture all those family holidays,
              questionable haircuts and favourite recipe recommendations.
              <br />
              <br />
              In fact, it takes so many memories to capture what makes you
              unique, that with Premium we thought we&apos;d give you unlimited
              story storage!
            </>
          ),
          style: SubPaperStyle,
          icon: (
            <>
              <video width="100%" autoPlay muted loop>
                <source src={UnlimitedAnimation} type="video/ogg" />
                <AllInclusiveIcon {...PerkIconProps} />
              </video>
            </>
          ),
        })}

        {renderPerkCard({
          style: SubPaperSmallStyle,
          icon: <FiberNewIcon {...PerkIconProps} />,
          perkTitle: 'Early access to new features',
          perkText: (
            <>
              We&apos;re hard at work developing new features. As a Premium
              member you&apos;ll get them before anyone else, and at no extra
              cost.
            </>
          ),
        })}

        {renderPerkCard({
          style: SubPaperSmallStyle,
          icon: <CreditCardIcon {...PerkIconProps} />,
          perkTitle: 'Cancel anytime',
          perkText: `No commitments - if you change your mind, you can easily cancel your Premium plan.`,
        })}

        {renderPerkCard({
          style: SubPaperCenteredStyle,
          elevation: 5,
          perkTitle: 'One great price, no hidden fees',
          perkText: (
            <>
              Get Iternal Premium for just £20 per year -
              <br />
              No hidden monthly costs.
              <br />
              No additional charges.
            </>
          ),
          children: (
            <div style={UpgradeNowSection}>
              <Typography variant="h6">only £20/year</Typography>
              <Button
                onClick={() => nextFunc()}
                color="secondary"
                size="large"
                variant="contained"
                style={{
          marginTop: '1rem',
          borderRadius: '100px',
          Width: '164px',
          minHeight: '50px',
          fontWeight:"700",
          boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',}}
              >
                Upgrade now
              </Button>
            </div>
          ),
        })}
      </Paper>
    </Fade>
  );
}

Upsell.propTypes = {
  /**
   * What should the title of this page be?
   */
  title: PropTypes.string,
  /**
   * What should the subtitle of this page be?
   */
  subtitle: PropTypes.string,
  /**
   * What should the "Upgrade Now" button link to
   */
  link: PropTypes.string,
  /**
   * Please supply a function for advancing to checkout
   */
  nextFunc: PropTypes.func,
};

Upsell.defaultProps = {
  title: 'Upgrade to premium',
  subtitle: 'Get ever-growing benefits for just £20/year',
  link: null,
  nextFunc: () => {},
};
