import React, { useEffect, useRef, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Button,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PromptButtons from "../PromptButtons";
import { CREATE_MEMORY_URL, SHARE_MEMORY_URL } from "../../../constants/constantUrls";

//Badges
import AllPrompts from "../../../assets/prompts/all.png";
import Autobiography from "../../../assets/prompts/autobiography.png";
import Wellbeing from "../../../assets/prompts/wellbeing.png";
import Family from "../../../assets/prompts/family.png";
import Childhood from "../../../assets/prompts/childhood.png";
import Teenager from "../../../assets/prompts/teenager.png";
import Adult from "../../../assets/prompts/adult.png";
import Hobbies from "../../../assets/prompts/hobbies.png";
import Relationships from "../../../assets/prompts/relationships.png";
import Entertainment from "../../../assets/prompts/entertainment.png";
import Reminisce from "../../../assets/prompts/reminisce.png";
import Future from "../../../assets/prompts/future.png";
import LeftArrow from "../../../assets/prompts/leftarrow.png";
import RightArrow from "../../../assets/prompts/rightarrow.png";

import LoadingAnimation from "../Animations/Loading";

import { prompts } from '../../../api';
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#E9F3F5",
  },
  inline: {
    display: "inline",
  },
}));

const PromptPicker = (props) => {
  const [chosenCategory, setChosenCategory] = useState('');
  const [allPrompts, setAllPrompts] = useState([]);
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const badges = [
    {
      id: 1,
      src: AllPrompts,
      name: "all prompts badge",
      category: "all"
    },
    {
      id: 2,
      src: Autobiography,
      name: "autobiography badge",
      category: "autobiography"
    },
    {
      id: 3,
      src: Wellbeing,
      name: "wellbeing badge",
      category: "wellbeing"
    },
    {
      id: 4,
      src: Family,
      name: "family badge",
      category: "family"
    },
    {
      id: 5,
      src: Childhood,
      name: "childhood badge",
      category: "childhood"
    },
    {
      id: 6,
      src: Teenager,
      name: "teenager badge",
      category: "teenager"
    },
    {
      id: 7,
      src: Adult,
      name: "adult badge",
      category: "adult"
    },
    {
      id: 9,
      src: Hobbies,
      name: "hobbies badge",
      category: "hobbies"
    },
    {
      id: 10,
      src: Relationships,
      name: "relationship badge",
      category: "relationships"
    },
    {
      id: 11,
      src: Entertainment,
      name: "entertainment badge",
      category: "entertainment"
    },
    {
      id: 12,
      src: Future,
      name: "future badge",
      category: "future"
    },
    {
      id: 13,
      src: Reminisce,
      name: "reminisce badge",
      category: "reminisce"
    },
  ];

  useEffect(() => {
    if (props?.chosenCategory) {
      changeCategory(props?.chosenCategory || 'all')
    } else {
      getAllPrompts();
    }
  }, []);

  const getAllPrompts = async () => {
    const prompts = await getPrompts();
    setAllPrompts(prompts);
    setFilteredPrompts(prompts);
  }

  const getPrompts = async () => {
    try {
      const { data } = await prompts.getMemoryPrompts({});
      setIsLoading(false);
      setAllPrompts(data);
      setFilteredPrompts(data);
      return data;
    } catch (err) {
      console.log(err)
    }
  }

  const decideImage = (category) => {
    const filteredCategories = badges.filter((badge) => badge?.category?.toLowerCase() === category.toLowerCase());
    if (filteredCategories?.length > 0) {
      return filteredCategories[0]?.src;
    } else {
      return AllPrompts;
    }
  }

  const classes = useStyles();

  const categoryScroll = direction => {
    const container = document.querySelector('.prompt-badges');
    const value = direction === 'left' ? (window.innerWidth < 1024 ? -96 : -121) : (window.innerWidth < 1024 ? 96 : 121);
    container.scrollBy({
      left: value,
      behavior: 'smooth',
    });
  };

  const changeCategory = async category => {
    const prompts = await getPrompts();
    setAllPrompts(prompts);
    setChosenCategory(category);
    if (category === 'all') {
      setFilteredPrompts(prompts);
    } else {
      setFilteredPrompts(prompts.filter(prompt => prompt?.category.toLowerCase() === category.toLowerCase()))
    }
  }

  const filterCategory = async category => {
    setChosenCategory(category)
    if (category === 'all') {
      setFilteredPrompts(allPrompts);
    } else {
      setFilteredPrompts(allPrompts.filter(prompt => prompt?.category.toLowerCase() === category.toLowerCase()))
    }
  }

  const getWrittenPercentage = () => {
    const completed = filteredPrompts.filter(prompt => prompt?.completed);
    const percentage = (completed?.length / filteredPrompts?.length) * 100;
    return Math.round(percentage).toString() 
  }

  if (isLoading) {
    return (
      <LoadingAnimation
        width={window.innerWidth < 600 ? window.innerWidth * 0.9 : 500}
        height={window.innerWidth < 600 ? window.innderWidth * 0.9 : 500}
      />
    )
  } else {
    return (
      <Grid id="prompt-picker">
        <PromptButtons clickFunction={filterCategory} scrollFunc={categoryScroll} allPrompts={allPrompts}/>
        <Paper elevation={0}
          style={{
            marginTop: window.innerWidth < 1024 ? "1rem" : "1.5rem",
            marginLeft: window.innerWidth < 450 ? "0.5rem" : "0rem",
            marginRight: window.innerWidth < 450 ? "0.5rem" : "0rem",
            height: window.innerHeight < 700 ? "60vh" : "60vh",
            overflowY: "scroll",
          }}
        >
          <Typography
            color="primary"
            style={{
              textAlign: window.innerWidth < 600 ? "center" : "left",
              fontWeight: "900",
              fontSize: window.innerWidth < 600 ? "1.5rem" : "2rem",
              marginBottom: "2rem",
              marginLeft: window.innerWidth < 600 ? "0rem" : "2rem",
              paddingTop: window.innerWidth < 1024 ? "1rem" : "2rem",
              textTransform: 'capitalize'
            }}
          >
            {chosenCategory === 'all' ? "All Prompts" : `${chosenCategory} Prompts`}
          </Typography>
  
          {/* Example Prompt */}
          <List
            className={classes.root}
            style={{
              height: window.innerHeight < 700 ? "35vh" : "37vh",
              overflowY: "scroll",
              marginTop: window.innerWidth < 1024 ? "-1rem" : "0rem",
            }}
          >
            {filteredPrompts?.map(prompt => {
              return (
                <>
                  <Grid container id="prompt">
                    <Grid
                      xs={12}
                      md={7}
                      lg={7}
                      item
                      id="prompt-question"
                    >
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar
                          style={{
                            display: window.innerWidth > 450 ? "block" : "none",
                          }}
                        >
                          <Avatar alt="family" src={decideImage(prompt?.category)} />
                        </ListItemAvatar>
        
                        <ListItemText primary={prompt?.prompt}></ListItemText>
                      </ListItem>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      lg={5}
                      xl={5}
                      id="prompt-response"
                      style={{
                        // background: "brown",
                        display: "flex",
                        flexDirection:
                          window.innerWidth > 600 ? "row" : "column-reverse",
                      }}
                    >
                      <Grid
                        container
                        id="prompt-buttons"
                        xs={12}
                        sm={8}
                        md={5}
                        lg={8}
                        xl={8}
                        spacing={1}
                        style={{
                          // background: "pink",
                          display: "flex",
                          alignContent: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {prompt?.completed ? (
                          <Grid item id="answer-button">
                            <NavLink
                              to={{
                                pathname: `${SHARE_MEMORY_URL?.replace(':id', prompt?.memoryId)}`,
                              }}
                              style={{ textDecoration: 'none' }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                style={{
                                  borderRadius: "100px",
                                  fontWeight: "bold",
                                  marginLeft: "1rem",
                                  width: "115px",
                                }}
                                
                              >
                                {" "}View{" "}
                              </Button>
                            </NavLink>
                          </Grid>
                        ) : (
                          <Grid item id="answer-button">
                            {props?.isModal ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => props?.selectPromptFunc(prompt)}
                                style={{
                                  borderRadius: "100px",
                                  fontWeight: "bold",
                                  marginLeft: "1rem",
                                  width: "115px",
                                }}
                              >
                                {" "}Answer{" "}
                              </Button>
                            ) : (
                              <NavLink
                                to={{
                                  pathname: `${CREATE_MEMORY_URL}`,
                                  search: `?prompt=${prompt?._id}`,
                                  state: { refreshNeeded: true },
                                }}
                                style={{ textDecoration: 'none' }}
                              >
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  style={{
                                    borderRadius: "100px",
                                    fontWeight: "bold",
                                    marginLeft: "1rem",
                                    width: "115px",
                                  }}
                                >
                                  {" "}Answer{" "}
                                </Button>
                              </NavLink>
                            )}

  
                          </Grid>
                        )}
  
                        {/* <Grid item id="friend-button">
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              borderRadius: "100px",
                              fontWeight: "bold",
                              marginLeft: "1rem",
                              width: "115px",
                            }}
                          >
                            {" "}
                            Ask Friend{" "}
                          </Button>
                        </Grid> */}
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        sm={4}
                        md={7}
                        lg={5}
                        xl={6}
                        style={{
                          display: "flex",
                          alignContent: "center",
                        }}
                      >
                        <ListItemText align="right" primary="Completed" />
  
                        <Checkbox
                          style={{ marginTop: "-0.25rem" }}
                          checked={prompt?.completed}
                          disabeled
                          disableRipple
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider
                    variant="inset"
                    component="li"
                    style={{ marginTop: "1rem" }}
                  />
                </>
                
              )
            })}
          </List>
          <Grid style={{display:'flex', justifyContent: window.innerWidth < 600 ? "center" :'flex-end', marginTop:'1rem', marginBottom:'1rem', marginRight:'1rem', marginLeft:'1rem' }}>
          <div style={{backgroundColor:'#0bafaa', borderRadius:'100px', width:'240px',height:'50px', justifyContent:'center', alignContent:'center' }}>
          <Typography
            color="primary"
            align="right"
            style={{
              fontWeight: "900",
              fontSize: window.innerWidth < 600 ? "1.3rem" : "1.5rem",
              color:'white',
              marginTop:'0.5rem',
              textAlign:'center'
           
            }}
          >
            {getWrittenPercentage()}% Completed
            {/* 75% Completed */}
          </Typography>
          </div>
          </Grid>
        </Paper>
      </Grid>
    );
  }

 
};
export default PromptPicker;
