import React from 'react';
import Header from './Header/Header';

// CONSTANTS
import { sizeNum } from '../../constants/breakPoints';

// STYLING
import * as S from './HeaderLogout.style';
import logo from '../../assets/iternal-new.svg';

const HeaderLogout = props => { 
  return(
    <>
      {props.windowWidth >= sizeNum.laptop ? (
        <Header loggedOut noBackground={props?.noBackground} />
      ) : (
        <S.Header>
          <S.LogoWrapper>
            <a href="https://www.iternal.app">
              <img alt="Iternal logo" src={logo} />
            </a>
          </S.LogoWrapper>
        </S.Header>
      )}
    </>
  )
};

export default HeaderLogout;
