//
// React
import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

//Bottom Nav
import Groups from "@material-ui/icons/People";
import Friends from "@material-ui/icons/EmojiPeople";
import Profile from "@material-ui/icons/Face";
import Stories from "@material-ui/icons/MenuBook";

import * as C from "../../../../constants/constantUrls";
import history from "../../../../history";

// Components
import {
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Fade,
  Collapse,
  Zoom,
  Grid,
  Modal,
  Select,
  InputLabel,
  Switch,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";

import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

import imageCompression from "browser-image-compression";
import ReactGA from "react-ga";
import axios from "axios";
import Swal from "sweetalert2";
import { SwatchesPicker } from "react-color";
import ColorContrastChecker from "color-contrast-checker";
import HeaderShareMemory from "../../../Common/HeaderShareMemory";
import imagePlaceHolder from "../../../../assets/person-placeholder.jpg";
import MemoriesTable from "../../../Common/Tables/MemoriesTable";
import { NewTimeline } from "../../../../NewComponents/Dashboard/Blocks/NewTimeline/NewTimeline";
import BackArrow from "../../../Common/GroupComponents/Buttons/BackArrow";
import CallToActionButton from "../../../Common/GroupComponents/Buttons/CallToActionButton";
import WarningPopup from "../../../Common/GroupComponents/WarningPopup";
import { ImageButton } from "../../../../NewComponents/ImageButton/UnsplashImageButton";
// Constants
import { SIGNUP_URL, GROUPS_URL } from "../../../../constants/constantUrls";
import { linkTokens, memories } from "../../../../api";
import backgrounds from "../../../Common/GroupComponents/Assets/Backgrounds";
import colors from "../../../Common/GroupComponents/Assets/Colors";

import Navbar from "../../../Common/GroupComponents/Navbar";
import { IternalTheme } from "../../../../NewComponents/--Theme/IternalThemeLight";

// Styles
import { Main, MemoryButtonWrapper } from "./style";

const GroupAdminPage = (props) => {
  const { shortId } = props.match.params;
  const [groups, setGroups] = useState([]);
  const [createModal, setCreateModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [profilePicture, setProfilePicture] = useState({});
  const [tempProfilePicture, setTempProfilePicture] = useState("");
  const [groupVisibility, setGroupVisibility] = useState(false);
  const [whoCanAddMemory, setWhoCanAddMemory] = useState(false);
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [noImagePopup, setNoImagePopup] = useState(false);
  const [groupMemories, setGroupMemories] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [groupData, setGroupData] = useState({});
  const [hasJoinedGroup, setHasJoinedGroup] = useState(false);
  const [groupMemberStatus, setGroupMemberStatus] = useState("");
  const [hasEditOccured, setHasEditOccured] = useState(false);
  const [groupColor, setGroupColor] = useState({});
  const [background, setBackground] = useState({});
  const [selectedBackground, setSelectedBackground] = useState("");
  const [unsplashBackgrounds, setUnsplashBackgrounds] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [useUnsplash, setUseUnsplash] = useState(true);
  const [tempOwnBackground, setTempOwnBackground] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [theme, setTheme] = useState(createMuiTheme(IternalTheme));

  const imageInput = useRef();
  const backgroundInput = useRef();

  useEffect(() => {
    // get user's groups
    getGroup();
  }, [getGroup, props]);

  useEffect(() => {
    if (!hasEditOccured) {
      setHasEditOccured(true);
    }
  }, [
    groupName,
    groupDescription,
    profilePicture,
    groupVisibility,
    whoCanAddMemory,
    groupColor,
    hasEditOccured,
  ]);

  useEffect(() => {
    getUnsplashImages();
  }, []);

  const getUnsplashImages = async () => {
    try {
      const { data } = await axios.get(
        `https://api.unsplash.com/photos/?query=wallpaper&per_page=30&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
      );
      const allUnsplash = data.map((image) => {
        return {
          imgId: image?.id,
          downloadLink: image?.links?.download_location,
          userName: image?.user?.name,
          userLink: `${image?.user?.links?.html}?utm_source=Iternal&utm_medium=referral`,
          imagePath: image?.urls?.thumb,
          type: "unsplash",
        };
      });
      setUnsplashBackgrounds(allUnsplash);
    } catch {
      setUseUnsplash(false);
      setUnsplashBackgrounds(backgrounds);
    }
  };

  const searchUnsplashImages = async () => {
    const { data } = await axios.get(
      `https://api.unsplash.com/search/photos/?query=${searchQuery}&per_page=30&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
    );
    const allUnsplash = data?.results.map((image) => {
      return {
        imgId: image?.id,
        downloadLink: image?.links?.download_location,
        userName: image?.user?.name,
        userLink: `${image?.user?.links?.html}?utm_source=Iternal&utm_medium=referral`,
        imagePath: image?.urls?.thumb,
        type: "unsplash",
      };
    });
    setUnsplashBackgrounds(allUnsplash);
  };

  const getGroup = async () => {
    const { data } = await axios.get(`/api/group/${shortId}`);
    if (data) {
      setGroupMemories(data[0]?.groupMemories);
      setGroupMembers(data[0]?.groupMembers);
      setGroupData(data[0]);
      if (props?.id && props?.isLoggedIn) {
        if (
          data[0].groupMembers.find(
            (x) => x.userId.toString() === props.id.toString()
          )
        ) {
          const status = data[0].groupMembers.find(
            (x) => x.userId.toString() === props.id.toString()
          )?.role;
          if (status === "member") {
            props.history.push(`/group/${shortId}`);
          }
          setHasJoinedGroup(true);
          setGroupMemberStatus(
            data[0].groupMembers.find(
              (x) => x.userId.toString() === props.id.toString()
            )?.role
          );
          setGroupName(data[0]?.groupName);
          setProfilePicture(
            data[0]?.temporaryProfilePicture
              ? { imagePath: data[0]?.temporaryProfilePicture }
              : {}
          );
          // const iternalBackground = backgrounds.find(
          //   bg =>
          //     bg?.imagePath ===
          //     data[0]?.temporaryBackgroundPicture,
          // );
          // if (iternalBackground && iternalBackground?.name) {
          //   setSelectedBackground(iternalBackground?.name);
          //   setBackground({ imagePath: iternalBackground?.imagePath });
          if (data[0]?.temporaryBackgroundPicture) {
            setBackground({
              imagePath: data[0]?.temporaryBackgroundPicture,
              type: "existing",
            });
          }
          setGroupDescription(data[0]?.groupDescription);
          setTempProfilePicture(data[0]?.profilePicture?.imagePath || "");
          setGroupColor(data[0]?.groupColor);
          switch (data[0]?.groupPrivacy) {
            case "private":
              setGroupVisibility(false);
              setWhoCanAddMemory(false);
              break;
            case "readOnly":
              setGroupVisibility(true);
              setWhoCanAddMemory(false);
              break;
            case "public":
              setGroupVisibility(true);
              setWhoCanAddMemory(true);
              break;
            default:
              setGroupVisibility(false);
              setWhoCanAddMemory(false);
          }

          if (props?.id && props?.isLoggedIn) {
            if (
              data[0].groupMembers.find(
                (x) => x.userId.toString() === props.id.toString()
              )
            ) {
              setHasJoinedGroup(true);
              setGroupMemberStatus(
                data[0].groupMembers.find(
                  (x) => x.userId.toString() === props.id.toString()
                )?.role
              );
            }
          }
          if (data[0]?.groupColor) {
            const { main, light, dark, contrastText } = data[0]?.groupColor;
            theme.palette.primary.main = main;
            theme.palette.primary.light = light;
            theme.palette.primary.dark = dark;
            theme.palette.primary.contrastText = contrastText;
          }

          setHasEditOccured(false);
        } else {
          props.history.push(`/group/${shortId}`);
        }
      }
    }
    setIsLoading(false);
  };

  const directUploadToGoogle = async (e) => {
    // const userId = createForOther ? uploaderId : user.id;
    const { files } = e.target;
    const allFiles = Array.from(files);
    const compressionOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    allFiles.map(async (image) => {
      const compressedImage = await imageCompression(image, compressionOptions);

      const {
        data: { generatedName } = {},
        error,
      } = await memories.uploadImage({
        image: compressedImage,
        userId,
      });

      const imgUrl = URL.createObjectURL(compressedImage);
      setTempProfilePicture(imgUrl);
      setProfilePicture({ imagePath: generatedName });
    });
  };

  const uploadUnsplashToGoogle = async (blob, imageId) => {
    const compressionOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedImage = await imageCompression(blob, compressionOptions);
    compressedImage.name = imageId;
    const { data: { generatedName } = {}, error } = await memories.uploadImage({
      image: compressedImage,
      userId,
    });

    const imgUrl = URL.createObjectURL(compressedImage);
    return generatedName;
    // console.log(generatedName);
    // console.log(imgUrl);
  };

  const backgroundUploadToGoogle = async e => {
    const { files } = e.target;
    const allFiles = Array.from(files);
    const compressionOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const image = allFiles[0]
    const compressedImage = await imageCompression(image, compressionOptions);

    const {
      data: { generatedName } = {},
      error,
    } = await memories.uploadImage({
      image: compressedImage,
      userId,
    });
    setBackground({ imagePath: generatedName, type: 'native' })
    const imgUrl = URL.createObjectURL(compressedImage);
    setTempOwnBackground(imgUrl)
  }

  const downloadBackground = async () => {
    if (background?.type === "unsplash") {
      const { data } = await axios.get(
        `${background?.downloadLink}/?client_id=5-PHC5FWG4TUMyVF6gYmN2VPL_-lG06u5QVyPc6o3LI`
      );
      if (data) {
        const { data: photoData } = await axios.get(data?.url, {
          responseType: "arraybuffer",
        });
        if (photoData) {
          const imagePath = await uploadUnsplashToGoogle(
            new Blob([new Uint8Array(photoData)], { type: "image/jpeg" }),
            background?.imgId
          );
          return imagePath;
        }
      }
    } else if (background?.type === "native") {
      return background?.imagePath;
    } else if (background?.type === "upload") {
      return background?.imagePath;
    } else if (background?.type === "existing") {
      return background?.imagePath;
    }
  };

  const resetInputs = () => {
    setGroupName("");
    setGroupDescription("");
    setProfilePicture({});
    setTempProfilePicture("");
    setGroupVisibility(false);
    setWhoCanAddMemory(false);
  };

  const getPrivacySetting = () => {
    if (!groupVisibility) {
      return "private";
    }
    if (groupVisibility && !whoCanAddMemory) {
      return "readOnly";
    }
    if (groupVisibility && whoCanAddMemory) {
      return "public";
    }
  };

  const getLightColor = (color) => {
    const light = {
      h: color.h,
      s: color.s - 0.15 < 0 ? 0 : color.s - 0.15,
      l: color.l + 0.1 > 1 ? 1 : color.l + 0.1,
      a: color.a,
    };
    return light;
  };

  const getDarkColor = (color) => {
    const dark = {
      h: color.h,
      s: color.s + 0.05 > 1 ? 1 : color.s + 0.05,
      l: color.l - 0.1 < 0 ? 0 : color.l - 0.1,
      a: color.a,
    };
    return dark;
  };

  const handleColorChange = (color, event) => {
    const { hsl, hex } = color;
    const main = hsl;
    const light = getLightColor(hsl);
    const dark = getDarkColor(hsl);
    let contrastText;
    const ccc = new ColorContrastChecker();
    const white = "#fff";
    const black = "#000";
    if (ccc.isLevelAA(hex, white, 14)) {
      contrastText = white;
    } else {
      contrastText = black;
    }
    setGroupColor({
      main: `hsl(${main.h}, ${main.s * 100}%, ${main.l * 100}%)`,
      light: `hsl(${light.h}, ${light.s * 100}%, ${light.l * 100}%)`,
      dark: `hsl(${dark.h}, ${dark.s * 100}%, ${dark.l * 100}%)`,
      contrastText,
    });
  };

  const updateGroup = async () => {
    /* privacy setting:
     * if groupVisibility is false - private
     * if groupVisibility is true, whoCanAddMemory is false - readOnly
     * if groupVisibility is true, whoCanAddMemory is true - public
     */
    setIsCreating(true);
    const groupPrivacy = getPrivacySetting();
    const timelineCategories = [
      { name: "Group History" },
      { name: "Our Stories" },
    ];
    const groupType = "free";
    const imagePath = await downloadBackground();
    try {
      const { data } = await axios.patch("/api/groups", {
        groupId: groupData?._id,
        groupName,
        groupDescription,
        profilePicture,
        groupPrivacy,
        timelineCategories,
        groupType,
        groupColor,
        background: { imagePath },
      });
      if (data) {
        props.history.push(`/group/${data?.groupShortCode}`);
      }
      setIsCreating(false);
    } catch (err) {
      setIsCreating(false);
      Swal.fire({
        type: "warning",
        confirmButtonColor: "var(--secondary-blue)",
        cancelButtonColor: "var(--secondary-cherry)",
        title: err.response.data.error,
      });
    }

    // create owner groupMember object in backend using auth
  };

  if (isLoading) {
    return <Main />;
  }
  return (
    <>
      <Navbar
        {...props}
        customTheme={theme}
        isLoggedIn={isLoggedIn}
        hasJoinedGroup={hasJoinedGroup}
        groupMemberStatus={groupMemberStatus}
        groupId={groupData?._id}
        groupShortCode={shortId}
        pendingInvites={
          groupMembers.filter(
            (member) => member?.invitationStatus === "pending"
          ).length
        }
        joinGroupFunction={async () => {}}
      />
   
      <Main>
        <Modal open={noImagePopup} onClose={() => setNoImagePopup(false)}>
          {/* no image popup */}
          <WarningPopup
            submitButtonText="Continue Without"
            closeButtonText="Add A Photo Now"
            submitFunction={async () => {
              await updateGroup();
              setNoImagePopup(false);
            }}
            closeFunction={() => setNoImagePopup(false)}
            title="Are you sure you want to continue without adding a picture?"
            text="You can choose to add this later instead but adding one will make your timeline look more personal."
          />
        </Modal>
        <Grid container>

          <Grid item xs={1} style={{}}>

            <BackArrow
              clickFunction={() => props.history.push(`/group/${shortId}`)}
            />
          </Grid>

          <Grid item xs={12} style={{}}>

            <Typography
              align="center"
              variant="h4"
              gutterBottom
              style={{
                color: "white",
                fontSize: window.innerWidth < 1024 ? "3rem" : "4rem",
              }}
            >

              Edit Group

            </Typography>
          </Grid>
        </Grid>
        <Paper
          style={{
            maxWidth: "1000px",
            margin: "0 auto",
            textAlign: "center",
            borderRadius: "7px",
            backgroundColor: "white",
            padding: "1rem",
            marginTop: "1rem",
            marginBottom: "6rem",

          }}
          elevation={3}
        >
          <Typography
            variant="body1"
            style={{
              textAlign: "left",
              marginTop: "1rem",
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            Here you can change any of the information displayed on your
            timeline. You can also change your privacy options if you wish to
            change who can see your group.
          </Typography>
          <div

          className="GroupInfo"
          style={{ textAlign: 'left', marginTop: '1rem', padding: '2rem 2rem 0rem 2rem' }}
        >
          <Typography variant="h5" color="primary" style={{ marginBottom: '2rem' }}>
            <span style={{ fontWeight: 'bold' }}>Group Information</span>
          </Typography>
          <Grid
            container
            spacing={3}
            direction={window.innerWidth < 600 ? 'column-' : 'unset'}
          >
            <Grid item xs={12}  className="TextInputs">
              <TextField
                value={groupName}
                onChange={text => setGroupName(text.target.value)}
                variant="outlined"
                placeholder="What would you like your group to be called?"
                label="Group Name"
                inputProps={{ maxLength: 60}}
                fullWidth
                required
                style={{ marginBottom: '1rem' }}
              />
              <TextField
                value={groupDescription}
                onChange={text => setGroupDescription(text.target.value)}
                variant="outlined"
                placeholder="Add a description for your group"
                label="About Your Group"
                inputProps={{ maxLength: 200}}
                fullWidth
                multiline
                rows={3}
              />
              <Typography
                variant="body1"
                style={{
                  textAlign: 'left',
                  margin: '1rem 0',
                }}
              >
                Your group has <span style={{ fontWeight: 'bold' }}>{groupMembers.length}</span> member{groupMembers.length !== 1 && 's'} and <span style={{ fontWeight: 'bold' }}>{groupMemories.length}</span> memor{groupMemories.length === 1 ? 'y' : 'ies'}!
              </Typography>
            </Grid>
          </Grid>
        </div>
        
        <div
          className="PrivacySettings"
          style={{ textAlign: 'left', marginTop: '1rem' }}
        >
          <Typography
            variant="h5" color="primary"
            style={{ paddingLeft: '2rem',  }}
          >
            <span style={{ fontWeight: 'bold' }}>Privacy Information</span>
          </Typography>
          <Typography
            variant="body1"
            style={{
              textAlign: 'left',
              marginTop: '2rem',
              paddingLeft: '2rem',
              paddingRight: '2rem',
              marginBottom: '1rem',
            }}
          >
         
          </Typography>
          <Typography style={{ marginTop: '1rem', paddingLeft: '2rem' }}>
            <span style={{ fontWeight: 'bold' }}>
              Who would you like to be able to see your timeline?
            </span>
          </Typography>
          <div
            className="VisibilitySlider"
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
          >
            <Grid container spacing={1} alignItems="center" justify="center">
              <Grid item>Group Members</Grid>
              <Grid item>
                <Switch
                  value={groupVisibility}
                  checked={groupVisibility}
                  onChange={() => {
                    setGroupVisibility(prev => !prev)
                  }}
                  color="primary"
                  style={{ color: '#10c1b8' }}
                />
              </Grid>

              <Grid item>Public</Grid>
            </Grid>
          </div>
        </div>

        {/* Show if group visibility Set to Members of the Public */}
        {groupVisibility && (
          <div className="MemorySettings" style={{ textAlign: 'left' }}>
            <Typography
              variant="h6"
              style={{ marginBottom: '', padding: '2rem' }}
            >
              <span style={{ fontWeight: 'bold' }}>Adding Stories</span>
            </Typography>
            <Typography
              variant="body1"
              style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
            >
           
              As the group owner of a public timeline, you can choose who will
              be able to contribute to the group. Don’t worry if you’re not
              sure, you can always change your mind later.
            </Typography>
            <Typography style={{ marginTop: '1rem', padding: '2rem' }}>
              <span style={{ fontWeight: 'bold' }}>
                Who would you like to be able to add stories
              </span>
            </Typography>
            <div
              className="AddMemorySlider"
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
            >
              <Grid container spacing={1} alignItems="center" justify="center">
                <Grid item>Group Members Only</Grid>
                <Grid item>
                  <Switch
                    value={whoCanAddMemory}
                    checked={whoCanAddMemory}
                    onChange={() => setWhoCanAddMemory(prev => !prev)}
                    color="primary"
                    style={{ color: '#10c1b8' }}
                  />
                </Grid>
                <Grid item>Members Of The Public</Grid>
              </Grid>
            </div>
          </div>
        )}
        {/* End Of Change */}
        {/* Show if Set to Group Members Only  */}

        {!groupVisibility && (
          <div className="MemorySettings" style={{ textAlign: 'left' }}>
            <Typography
              variant="h6"
              style={{ marginBottom: '', padding: '2rem' }}
            >
              <span style={{ fontWeight: 'bold' }}>Adding Stories</span>
            </Typography>
            <Typography
              variant="body1"
              style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
            >
              Your group is completely private to your members. Only people you add to the group will be able to see and add stories to your group.
              </Typography>
          </div>
        )}
        {/* End Of Change */}

        <div
          className="PersonaliseSettings"
          style={{ textAlign: 'left', marginTop: '3rem' }}
        >
          <Typography variant="h5" color="primary" style={{ paddingLeft: '2rem' }} gutterBottom>
            <span style={{ fontWeight: 'bold' }}>Customise Your Group</span>
          </Typography>
          <Typography
            variant="body1"
            style={{
              textAlign: 'left',
              marginTop: '2rem',
              paddingLeft: '2rem',
              paddingRight: '2rem',
              marginBottom: '2rem',
            }}
          >
            You can customise the look of your timeline, using millions of combinations. You can choose your main colour, group picture and even a background image so no timeline will ever look the same as another. 
           
          </Typography>
          {/* <Typography variant="body1" style={{ padding: '2rem' }}>
          </Typography> */}
          <Grid container spacing={2}>
          
            <Grid
              item
              xs={12}
              sm={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {groupColor?.main && (
                    <Paper
                      style={{
                        minHeight: "50px",
                        width: "260px",
                        height: "100%",
                        backgroundColor: groupColor?.main
                          ? groupColor.main
                          : "",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "1rem",
                        marginRight: "1rem",
                        marginLeft: "1rem",
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                        fontSize: "1.5rem",
                        paddingRight: "1rem",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem",
                      }}
                    >
                      <p
                        style={{
                          color: groupColor?.contrastText
                            ? groupColor.contrastText
                            : "black",
                        }}
                      >
                        {groupName?.length > 0
                          ? "Colour Theme"
                          : "Colour Theme"}
                      </p>
                    </Paper>
                  )}
                  {!groupColor?.main && (
                    <Paper
                      style={{
                        width: "260px",
                        minHeight: "50px",

                        height: "100%",
                        backgroundColor: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "1rem",
                        marginRight: "1rem",
                        marginLeft: "1rem",
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                        fontSize: "1.5rem",
                        paddingRight: "1rem",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem",
                      }}
                    >
                      <p
                        style={{
                          color: "white",
                        }}
                      >
                        Colour Theme
                      </p>
                    </Paper>
                  )}
                </Grid>
                <SwatchesPicker onChange={handleColorChange} colors={colors} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    marginTop: "1rem",
                    marginLeft: "2rem",
                    marginRight: "2rem",
                    textAlign: "center",
                  }}
                  gutterBottom
                >
                  <span style={{ fontWeight: "bold" }}>
                    Choose a Group Picture
                  </span>
                </Typography>
                <Grid container id="group-picture-container">
                  <input
                    ref={imageInput}
                    accept="image/*"
                    type="file"
                    onChange={directUploadToGoogle}
                    style={{ display: "none" }}
                    multiple={false}
                  />
                </Grid>
                <img
                  src={tempProfilePicture}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "40vh",
                    textAlign: "center",
                    marginLeft: "2rem",
                    marginRight: "2rem",
                  }}
                />
                <CallToActionButton
                  clickFunction={() => imageInput.current.click()}
                  variant="contained"
                  title="Select Group Picture"
                  style={{
                    marginTop: "2rem",
                    // backgroundColor: '#1D6A91',
                    // color: 'white',
                    minHeight: "80px",
                    borderRadius: "100px",
                  }}
                >
                  <AddAPhotoIcon style={{ marginRight: "1rem" }} />
                </CallToActionButton>
              </Grid>
            </Grid>

            <div container id="backgrounds-grid" style={{ padding: "2rem" }}>
              <Typography
                variant="h6"
                style={{ marginTop: "1rem" }}
                gutterBottom
              >
                <span style={{ fontWeight: "bold" }}>
                  Choose a Background Image
                </span>
              </Typography>
              <input
                ref={backgroundInput}
                accept="image/*"
                type="file"
                onChange={backgroundUploadToGoogle}
                style={{ display: 'none' }}
                multiple={false}
              />
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <CallToActionButton
                  clickFunction={() => backgroundInput.current.click()}
                  variant="contained"
                  title="Upload Background Image"
                  style={{
                    marginTop: '2rem',
                    minHeight: '80px',
                    borderRadius: '100px',
                    
                  }}
                />
                {tempOwnBackground?.length > 0 && (
                  <img src={tempOwnBackground} style={{ maxWidth: '300px', maxHeight: '200px', marginTop: '1rem'  }}/>
                )}
              </div>
              {useUnsplash && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <TextField
                      value={searchQuery}
                      onChange={(text) => setSearchQuery(text.target.value)}
                      variant="outlined"
                      placeholder="Type a word to choose a suitable background picture, then click search"
                      style={{ borderRadius: "0%" }}
                      fullWidth
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => searchUnsplashImages()}
                      disabled={searchQuery.length === 0}
                      style={{ borderRadius: "0px 50px 50px 0px" }}
                    >
                      Search
                    </Button>
                  </div>
                  <Typography>
                    Photos by{" "}
                    <a href="https://unsplash.com/?utm_source=Iternal&utm_medium=referral">
                      Unsplash
                    </a>
                  </Typography>
                </>
              )}
              <Grid
                container
                style={{
                  maxHeight: "40vh",
                  overflowY: "scroll",
                  padding: "1rem",
                  border: "1px solid var(--neutral-mist)",
                  borderRadius: "7px",
                }}
              >
                {unsplashBackgrounds.map((bg) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    style={{ padding: "0.5rem" }}
                  >
                    {useUnsplash ? (
                      <ImageButton
                        isHighlighted={selectedBackground === bg.imgId}
                        image={bg.imagePath}
                        userName={bg.userName}
                        userLink={bg.userLink}
                        onClick={() => {
                          setSelectedBackground(bg.imgId);
                          setBackground(bg);
                        }}
                      />
                    ) : (
                      <ImageButton
                        isHighlighted={selectedBackground === bg.name}
                        image={bg.url}
                        onClick={() => {
                          setSelectedBackground(bg.name);
                          setBackground(bg);
                        }}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",

              flexDirection: "column",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
          >
            <CallToActionButton
              clickFunction={async () => {
                if (tempProfilePicture.length === 0) {
                  setNoImagePopup(true);
                } else {
                  await updateGroup();
                }

                // history.push('')
              }}
              variant="contained"
              color="primary"
              title="Update Group"
              disabled={groupName.length === 0 || !hasEditOccured || isCreating}
            />
          </Grid>
        </Paper>
      </Main>
    </>
  );
};

export default GroupAdminPage;
