export const getDateSegments = date => {
  const _date = new Date(date);

  let month = `${_date.getMonth() + 1}`;
  let day = `${_date.getDate()}`;
  const year = _date.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return { year, month, day };
};

// formats a JS date to 'yyyy-mm-dd'
export const formatDate = date => {
  if (date) {
    const { day, year, month } = getDateSegments(date);
    return [year, month, day].join('-');
  }

  return undefined;
};

export const formatCleanDate = date => {
  if (date) {
    const { day, year, month } = getDateSegments(date);
    return [day, month, year].join('-');
  }

  return undefined;
};

export const formatCleanYear = date => {
  if (date) {
    const { year } = getDateSegments(date);
    return year
  }

  return undefined;
}

// export default formatDate, formatCleanDate;
