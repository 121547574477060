import styled, { css } from 'styled-components';

const sharedStyles = css`
  background-color: var(--white);
  font-weight: 600;
  ${'' /* text-transform: uppercase; */}
  border: ${({ border }) => border || 'solid 2px transparent'};
  border-radius: 5px;
  font-size: 16px;
  transition: 0.5s;
  padding: 12px;
  margin: 0 auto;
  cursor: pointer;
`;

// default
export const Button = styled.button`
  ${sharedStyles};
  a {
    text-decoration: none;
    color: var(--neutral-dark);
  }

  ${props =>
    !props.disabled &&
    ` &:hover {
      background-color: var(--secondary-cherry);
      color: var(--primary-navy);
    }`}`;

export const IsPrimaryButton = styled.button`
  ${sharedStyles};
  background-color: var(--secondary-aqua);
  color: var(--neutral-white);
  border: ${'solid 2px primary'};
  svg {
    margin-right:10px;
  }

  a {
    text-decoration: none;
    color: var(--black);
  }


  &:hover {
    background-color: hsl(178, 63%, 46%);
    color: var(--neutral-white);
    border: solid 2px hsl(178, 63%, 46%);
  }
`;


export const inviteSentButton = styled.button`
  ${sharedStyles};
  background-color:none;
  color: $var(--primary-navy);

  svg {
    margin-right:10px;
  }

  a {
    text-decoration: none;
    color: var(--black);
  }
`;

export const IsSuccessButton = styled.button`
  ${sharedStyles};
  background-color: var(--primary-navy);
  color: var(--neutral-white);
  border: ${'solid 2px primary'};

  svg {
    margin-right:10px;
  }

  a {
    text-decoration: none;
    color: var(--black);
  }

  &:hover {
    background-color: var(--neutral-dark);
    color: $var(--neutral-white);
    border: solid 2px var(--neutral-dark);
  }
`;

export const IsSecondaryButton = styled.button`
  ${sharedStyles};
  background-color: var(--secondary-aqua);
  color: var(--neutral-white);
  border: ${'solid 2px secondary'};

  a {
    text-decoration: none;
    color: var(--black);
  }

  &:hover {
    background-color: var(--secondary-aqua);
    color: var(--neutral-white);
    border: solid 2px var(--secondary-aqua);
  }
`;

export const IsUnderlinedButton = styled.button`
    background-color: var(--white);
    border: none;
    font-size: 16px;
    text-align: left;
    margin: 10px 10px;
    cursor: pointer;
    background-color: #ffffff;
    color: #aaaaaa;
    border: solid 2px primary;

    &:hover {
      text-decoration: underline;
    }
`;

export const IsTertiaryButton = styled.button`
  ${sharedStyles};
  background-color: var(--secondary-aqua);
  color: var(--neutral-white);
  border: ${'solid 2px tertiary'};

  a {
    text-decoration: none;
    color: var(--black);
  }

  &:hover {
    background-color: var(--secondary-aqua);
    color: var(--neutral-white);
    border: solid 2px var(--secondary-aqua);
  }

`;

export const IsDisabledButton = styled.button`
  ${sharedStyles};
  background-color: var(--neutral-mist);
  color: var(--neutral-white);
  border: ${'solid 2px lightGray'};

  a {
    text-decoration: none;
    color: var(--black);
  }

  &:hover {
    background-color: var(--neutral-slate);
    color: var(--neutral-white);
    border: solid 2px var(--neutral-slate);
  }
`;

export const IsCommentWarningButton = styled.button`
  background-color: transparent;
  color: var(--secondary-cherry);
  text-decoration:underline;
  border:none;
  cursor: pointer;
  font-size:1rem;
  font-weight: 600;
  padding:10px 0px;

  a {
    text-decoration: none;
    color: var(--black);
  }

  &:hover {
    background-color: none;
    color: var(--secondary-cherry);
  }
`;


export const IsWarningButton = styled.button`
  ${sharedStyles};
  background-color: var(--secondary-cherry);
  color: var(--neutral-white);
  border: ${'solid 2px warning'};

  svg {
    margin-right:10px;
  }

  a {
    text-decoration: none;
    color: var(--black);
  }

  &:hover {
    background-color: var(--secondary-cherry);
    color: var(--neutral-white);
    border: solid 2px var(--secondary-cherry);
  }
`;

export const IsAcceptButton = styled.button`
  background-color: transparent;
  color: var(--primary-navy);
  text-decoration:underline;
  border:none;
  cursor: pointer;
  font-size:1rem;
  font-weight: 600;
  padding:10px 0px;

  a {
    text-decoration: none;
    color: var(--black);
  }

  &:hover {
    background-color: none;
    color: var(--primary-navy);
  }
`;