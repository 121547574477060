import React from 'react';

const Microphone = ({ width, backgroundColor, color, theme }) => (
  <svg
    width={width}
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17" cy="17" r="17" />
    <g clipPath="url(#clip0)">
      <path
        d="M21.8851 16.6748C21.7053 16.6748 21.5594 16.8204 21.5594 17.0005V18.9545C21.5594 21.4687 19.5142 23.5139 17 23.5139C14.4859 23.5139 12.4406 21.4687 12.4406 18.9545V17.0005C12.4406 16.8204 12.2947 16.6748 12.115 16.6748C11.9352 16.6748 11.7893 16.8204 11.7893 17.0005V18.9545C11.7893 21.7178 13.9531 23.9793 16.6744 24.1486V25.7936H15.046C14.8662 25.7936 14.7203 25.9392 14.7203 26.1192C14.7203 26.2993 14.8662 26.4449 15.046 26.4449H18.9541C19.1338 26.4449 19.2797 26.2993 19.2797 26.1192C19.2797 25.9392 19.1338 25.7936 18.9541 25.7936H17.3257V24.1486C20.047 23.9793 22.2108 21.7175 22.2108 18.9545V17.0005C22.2108 16.8204 22.0649 16.6748 21.8851 16.6748Z"
        fill="#222"
      />
      <path
        d="M17.0001 22.5365C18.9753 22.5365 20.5825 20.9293 20.5825 18.9541V11.138C20.5825 9.16285 18.9753 7.55566 17.0001 7.55566C15.0249 7.55566 13.4177 9.16285 13.4177 11.138V18.9541C13.4177 20.9293 15.0249 22.5365 17.0001 22.5365Z"
        fill="#222"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          x="7.55566"
          y="7.55566"
          width="18.8889"
          height="18.8889"
          fill="#222"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Microphone;
