import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { breakpoints, breakpointsMax } from '../../constants/breakPoints';

// import { mobile }

export const Header = styled.header`
  width: 100vw;
  background-color: var(--primary);
  height: ${props => (props.menuOpen ? '100vh' : '3rem')};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 4;

  @media ${breakpoints.mobileL}, ${breakpointsMax.laptop} {
    height: 5.5rem;
    align-items: center;
  }

  svg {
    margin: auto 1rem;
  }

  a {
    margin: auto 0.2rem;
  }

  img {
    width: 4rem;
    margin: auto 1rem;
  }
`;

// Button with styles to animate on click to change to an X
export const MenuButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  text-transform: uppercase;
  color: var(--white);
  font-weight: 500;
  transition: all 0.3s ease-in;
  margin: 0;
  z-index: 5;
  position: relative;
  height: 3rem;
  width: 3rem;
  /* display: flex;
  align-items: center; */

  p {
    text-align: center;
  }

  ::before,
  ::after {
    content: '';
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    background-color: white;
    transition: transform 0.3s ease-in;
  }

  ::before {
    transform: translateY(0.5rem);
    /* top: 0.5rem; */
    top: 0;
  }
  ::after {
    transform: translateY(-0.5rem);
    bottom: 0;
    /* bottom: 0.5rem; */
  }

  ${props =>
    props.menu
      ? `
      font-size: 0;
      ::before,
      ::after {
        width: 80%;
      }
      ::before {
        transform: translateY(1.35rem) rotate(45deg);
      }
      ::after {
        transform: translateY(-1.35rem) rotate(-45deg);
      }
  `
      : null}
`;

export const MenuNotificationButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  text-transform: uppercase;
  color: var(--white);
  font-weight: 500;
  transition: all 0.3s ease-in;
  margin: 0;
  z-index: 5;
  position: relative;
  height: 3rem;
  width: 3rem;
  /* display: flex;
  align-items: center; */
  font-size: 25px;
  padding-left: 1rem;
  p {
    text-align: left;
  }

  /*::before,
  ::after {
    content: '';
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    background-color: white;
    transition: transform 0.3s ease-in;
  }*/

  ::before {
    transform: translateY(0.5rem);
    /* top: 0.5rem; */
    top: 0;
  }
  ::after {
    transform: translateY(-0.5rem);
    bottom: 0;
    /* bottom: 0.5rem; */
  }

  ${props =>
    props.menu
      ? `
      font-size: 0;
      ::before,
      ::after {
        width: 75%;
      }
      ::before {
        transform: translateY(1.35rem) rotate(45deg);
      }
      ::after {
        transform: translateY(-1.35rem) rotate(-45deg);
      }
  `
      : null}
`;

export const Menu = styled.nav`
  width: 100vw;
  height: 92vh;
  background-color: var(--primary);
  position: fixed;
  top: 8vh;

  section {
    display: flex;
    flex-flow: column nowrap;
    align-content: flex-start;
  }

  a {
    font-weight: 600;
    color: white;
    text-decoration: none;
    margin: 0.5rem 2rem;
  }
`;

export const MenuBig = styled.section`
  font-size: 2.5rem;
  padding-top: 0.5rem;
  padding-left: ${props => !props.desktop && '2rem'};
  text-transform: uppercase;

  a {
    margin: 0.5rem 0;
  }
`;

export const MenuMed = styled.section`
  font-size: 1.5rem;
  padding-left: 2rem;
  margin: 0.4rem 0;
`;

export const MenuSmall = styled.section`
  font-size: 1.13rem;
  position: ${props => props.fixed && 'fixed'};
  text-transform: uppercase;
  bottom: 2rem;
`;

export const Feedback = styled.a`
  /* font-size: 1.13rem; */
  position: fixed;
  bottom: 1.9rem;
  right: -0.7rem;
  text-align: center;
  cursor: pointer;
`;

export const LogOut = styled.button`
  border: none;
  padding: 0;
  background: none;
  font-size: 1.13rem;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  text-decoration: none;
  padding-left: ${props => !props.desktop && '2rem'};
  cursor: pointer;
  text-align: left;
`;

// DESKTOP STYLING
export const Sidebar = styled.header`
  overflow-y: auto;
  position: fixed;
  height: 100vh;
  width: 260px;
  background: var(--primary);
  top: 0;
  padding: 45px 30px 15px;
  z-index: 5;
  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: green;
    /* outline: 1px solid slategrey; */
  }
`;

export const Logo = styled.img`
  height: 40px;
`;

export const DesktopMenu = styled.nav`
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  height: calc(100% - 7rem);
  padding-top: 1rem;

  section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  a {
    font-weight: 600;
    color: white;
    text-decoration: none;
    margin: 0.5rem 0;
  }
`;

export const TopLink = styled(NavLink)`
  font-size: 3.5rem;
  text-transform: uppercase;
  color: var(--white);
  position: relative;
  text-decoration: none;
  font-weight: 700;
`;

export const DesktopLink = styled(NavLink)`
  color: var(--white);
  font-size: 21px;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  padding-bottom: 10px;

  svg {
    margin: 0.2rem 1rem;
  }
`;

export const StyledButton = styled.a`
  position: fixed;
  z-index: 999;
  bottom: 15vh;
  width: 40px;
  height: 100px;
  right: -1px;
  outline: none;
  text-decoration: none;
  background-color: var(--secondary);
  /* border: var(--primary) 2px solid; */
  color: var(--white);
  border-radius: 8px 0 0 8px;
  padding: 0;
  cursor: pointer;
  transition: all ease-in 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    width: 45px;
  }
`;

export const ButtonText = styled.p`
  font-size: 16px;
  transform: rotate(-90deg);
  margin: 0;
`;
