import React, { useEffect, useRef } from 'react';
import * as yup from 'yup';

import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import withDashedBorder from './withDashedBorder';

const descriptionSchema = yup
  .string()
  .required('A description is required')
  .min(3, 'Your description needs to be at least 3 characters long');

const Description = ({
  isTextareaFocused,
  setIsTextareaFocused,
  description,
  setDescription,
  setError,
  onFocusChange,
  createForOther=false,
}) => {
  const validate = () => {
    onFocusChange(false);
    descriptionSchema
      .validate(description)
      .then(() => {
        setError('');
      })
      .catch(err => {
        setError(err.errors && err.errors[0]);
      });
  };

  useEffect(
    () => () => {
      setError('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onChange = e => {
    setDescription(e.target.value);
    setError('');
  };

  const descriptionRef = useRef();
  return (
    <TextField
      ref={descriptionRef}
      label={"Your memory"}
      multiline
      rows={6}
      onFocus={(e) => {
        onFocusChange(e, descriptionRef);
        setIsTextareaFocused(true);
      }}
      value={description}
      onChange={onChange}
      onBlur={validate}
      placeholder={createForOther ? "Write your favourite memory of them in here! Need some inspirations? How about how you two met? Or the funniest conversation you had?" : "Write your memory here..."}
      variant="outlined"
      style={{ scrollMargin: '11rem', width: '100%' }}
    />
    // <TextareaAutosize 
    //   ref={descriptionRef}
    //   label={createForOther ? "Write your favourite memory of them in here! Need some inspirations? How about how you two met? Or the funniest conversation you had?" : "Your memory"}
    //   multiline
    //   minRows={4}
    //   onFocus={(e) => {
    //     onFocusChange(e, descriptionRef);
    //     setIsTextareaFocused(true);
    //   }}
    //   value={description}
    //   onChange={onChange}
    //   onBlur={validate}
    //   placeholder="Write your memory here..."
    //   variant="outlined"
    //   style={{ scrollMargin: '11rem', width: '100%' }}
    // />
  );
};

export default withDashedBorder(Description, 'description');
