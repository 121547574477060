import React, { useEffect, useState } from 'react';
// import * as yup from 'yup';

import { FaLock, FaGlobeAmericas } from 'react-icons/fa';
import Toggle from 'react-toggle';
import {
  PrivacySection,
  PrivacyToggleWrapper,
  PrivacySettingsWrapper,
} from './style';

import {
  friendRelationships,
  familyRelationships,
} from '../../../NewComponents/Pages/FriendsAndFamily/EditRelationship/DefinedRelationships';

import {
  permissionAccess
} from '../../../api';

import { PrivacySettings } from '../../../NewComponents/Pages/CreateMemory/PrivacySettings/PrivacySettings';
// import { permissionAccess } from '../../../api';

// const emailSchema = yup
//   .array(
//     yup
//       .string()
//       .email('please enter valid email')
//       .required('required'),
//   )
//   .max(5, 'Sorry, you can only add up to 5 emails at a time');
const definedRelationships = [...friendRelationships, ...familyRelationships];

export default function PrivacyWrapper({
  privacySetting,
  setPrivacySetting,
  taggedEmails,
  setTaggedEmails,
  checklist,
  setChecklist,
  setError,
  onFocusChange,
}) {
  const [friends, setFriends] = useState(undefined);
  // const [checklist, setChecklist] = useState(undefined);
  // console.log(privacySetting);
  // console.log(taggedEmails);

  useEffect(() => {
    const getFriends = async () => {
      const { data } = await permissionAccess.getAllGivenPermissionAccess();
      // console.log(data);
      setFriends(data);
    };

    const generateChecklist = () => {
      const generatedChecklist = {};

      // Add friends to checklist
      definedRelationships.forEach(rel => {
        generatedChecklist[rel] = false;
      });

      // Add relationships to checklist
      if (friends) {
        friends.forEach(friend => {
          generatedChecklist[friend?.sharedWithEmail] = false;
        });
      }

      if (taggedEmails) {
        // Tick friends/groups already tagged
        taggedEmails.forEach(tag => {
          // Tick friends
          if (tag?.taggedEmail) {
            // console.log(`Tried to tick ${tag?.taggedEmail}`);
            generatedChecklist[tag?.taggedEmail] = true;
          }
          // Tick groups
          if (tag?.taggedGroup) {
            // console.log(`Tried to tick ${tag?.taggedGroup}`);
            generatedChecklist[tag?.taggedGroup] = true;
          }
        });
      }
      setChecklist(generatedChecklist);
    }

    const getData = async () => {
      if (!friends) {
        await getFriends();
      }
      if (!checklist) {
        await generateChecklist();
      }
    };

    getData();
  }, [taggedEmails]);

  return (
    <PrivacySettingsWrapper>
      <PrivacySettings 
        privacySetting={privacySetting} 
        setPrivacySetting={setPrivacySetting}
        taggedEmails={taggedEmails}
        setTaggedEmails={setTaggedEmails}
        friends={friends}
        checklist={checklist}
        setChecklist={setChecklist}
        definedRelationships={definedRelationships}
      />
    </PrivacySettingsWrapper>
  );
}
