// React
import React from 'react';
import { Link, NavLink } from 'react-router-dom';

// Axios
import axios from 'axios';

// nanoid
import { nanoid } from 'nanoid';

// Icons
import HomeIcon from '@material-ui/icons/Home';
import AddIcon from '@material-ui/icons/Add';
import PhotoAlbumIcon from '@material-ui/icons/CameraAlt';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import GroupIcon from '@material-ui/icons/Group';
import Friends from '@material-ui/icons/EmojiPeople';
import Profile from '@material-ui/icons/Face';
import Categories from '@material-ui/icons/Category';

// MUI
import {
  Button,
  Collapse,
  Grid,
  SwipeableDrawer,
  Typography,
} from '@material-ui/core';

// Breakpoints
import { FaCrown } from 'react-icons/fa';
import { sizeNum } from '../../../constants/breakPoints';

// Logos
import iternal from '../../../assets/iternal-new.svg';
import iternalCircle from '../../../assets/iternal_logo_symbol.svg';
import google from '../../../assets/onboarding/google.svg';
import apple from '../../../assets/onboarding/apple.svg';

// Style
import './Header.scss';
import * as S from '../Header.style';

// Helpers
import { handleLogout } from '../../../helpers';

// Constants
import { API_PROFILE_BASICS_URL } from '../../../constants/apiRoutes';
import * as C from '../../../constants/constantUrls';

import { NavUserSection } from '../../../NewComponents/Navigation/UserSection/NavUserSection';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      memoryOptions: false,
      profileOptions: false,
      tags: [],
      pendingComments: [],
      approvedComments: [],
      showModal: false,
      userName: '',
      userPhoto: '',
      showDrawer: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleMemoryOptions = this.toggleMemoryOptions.bind(this);
    this.toggleProfileOptions = this.toggleProfileOptions.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  async componentDidMount() {
    // Get username and user image

    axios.get(API_PROFILE_BASICS_URL).then(async ({ data }) => {
      const { name } = data;
      this.setState({
        userName: name,
      });

      try {
        const {
          image: { imagePath },
        } = data;
        const imageBase64 = await axios
          .get(`${imagePath}`, {
            responseType: 'arraybuffer',
          })
          .then(response =>
            Buffer.from(response.data, 'binary').toString('base64'),
          );

        this.setState({
          userPhoto: imageBase64,
        });
      } catch {
        this.setState({
          userPhoto: ' ',
        });
      }
    });
  }

  toggleMenu = () => {
    this.setState(state => ({
      menuOpen: !state.menuOpen,
      memoryOptions: false,
      profileOptions: false,
    }));
  };

  closeMenu = () => {
    this.setState(() => ({
      menuOpen: false,
      memoryOptions: false,
      profileOptions: false,
      showDrawer: false,
    }));
  };

  toggleMemoryOptions = () => {
    this.setState(state => ({
      memoryOptions: !state.memoryOptions,
      profileOptions: false,
    }));
  };

  toggleProfileOptions = () => {
    this.setState(state => ({
      profileOptions: !state.profileOptions,
      memoryOptions: false,
    }));
  };

  toggleModal = () => {
    this.setState(prevState => ({ showModal: !prevState.showModal }));
  };

  decideBackground = () => {
    if (
      process.env?.REACT_APP_BRAND &&
      process.env?.REACT_APP_BRAND === 'GrandAppeal'
    ) {
      return '#359cd8';
    }
    if (this.props?.noBackground) {
      return 'none';
    }
  };

  decideLogo = () => {
    const isSmallScreen = sizeNum.laptop > window.innerWidth;
    if (
      process.env?.REACT_APP_BRAND &&
      process.env?.REACT_APP_BRAND === 'GrandAppeal'
    ) {
      return 'https://mk0thegrandappeqavfh.kinstacdn.com/wp-content/themes/snap/dist/logo-tga.svg';
    }
    return isSmallScreen ? iternalCircle : iternal;
  };

  handleDrawerOpen = () => {
    this.setState({ showDrawer: !this.state.showDrawer });
  };

  render() {
    const { location, isLoggedIn, isPremium } = this.props;

    const { showDrawer } = this.state;

    // Menu options are visible if we are not on an onboarding journey/path
    const menuOptionsVisible =
      location &&
      location.pathname &&
      location.pathname.includes('onboarding') === false;
    const isSmallScreen = sizeNum.laptop > window.innerWidth;

    return (
      <div
        id="navigation"
        className="container-fluid"
        // style={{ background: this.decideBackground() }}
        style={{ background: '#0a2156', color: 'white', overflow: 'scroll' }}
      >
        <div className="flex" style={{ justifyContent: 'center' }}>
          <div className="menu-container">
            <S.MenuButton
              onClick={() => {
                this.handleDrawerOpen();
              }}
              menu={this.state.menuOpen}
            >
              <p>Menu</p>
            </S.MenuButton>
          </div>
          <SwipeableDrawer
            anchor="left"
            open={showDrawer}
            onClose={() => this.handleDrawerOpen()}
            onOpen={() => this.handleDrawerOpen()}
          >
            <div
              style={{
                width: 280,
                height: '100%',
                backgroundColor: 'var(--primary-navy)',
                overflow: 'scroll',
              }}
            >
              {isLoggedIn && (
                <div>
                  <Collapse in>
                    <div
                      className="navigation-link-container d-block"
                      style={{
                        // border: "1px solid white",
                        padding: '1rem',
                        borderRadius: '20px',
                        marginTop: '2rem',
                        color: 'white',
                      }}
                    >
                      <NavUserSection
                        style={{ margin: '2rem' }}
                        userName={this.state.userName}
                        userPhoto={this.state.userPhoto}
                        signOutFunc={() =>
                          handleLogout(
                            this.props.history,
                            this.props.handleChangeState,
                          )
                        }
                        profileURL={C.BASICS_URL}
                        createMemoryURL={C.CREATE_MEMORY_URL}
                        memoriesURL={C.MEMORIES_URL}
                        dashboardURL={C.DASHBOARD_URL}
                        openNotificationsFunc={this.toggleModal}
                        closeMobileMenuFunc={this.closeMenu}
                      />
                      {[
                        {
                          text: ' Home',
                          icon: <HomeIcon fontSize="inherit" />,
                          url: C.DASHBOARD_URL,
                        },
                        {
                          text: 'Prompts',
                          icon: <Categories fontSize="inherit" />,
                          url: C.PROMPTS_URL,
                        },
                        {
                          text: ` Friends`,
                          icon: <Friends fontSize="inherit" />,
                          url: C.MY_FRIENDS_MEMORIES,
                        },
                        {
                          text: ' Groups',
                          icon: <GroupIcon fontSize="inherit" />,
                          url: C.GROUPS_URL,
                        },
                        {
                          text: ' Albums',
                          icon: <PhotoAlbumIcon fontSize="inherit" />,
                          url: C.ALBUMS_URL,
                        },
                        {
                          text: ' My Profile',
                          icon: <Profile fontSize="inherit" />,
                          url: C.BASICS_URL,
                        },
                        {
                          text: ' Go Premium',
                          icon: <FaCrown />,
                          url: C.GO_PREMIUM,
                        },
                      ].map(el => {
                        if (el.text === 'Go Premium') {
                          if (!isPremium) {
                            return (
                              <NavLink
                                to={el.url}
                                onClick={this.closeMenu}
                                style={{
                                  textDecoration: 'none',
                                  fontSize: '1.5rem',
                                }}
                              >
                                <div
                                  className="navigation-link"
                                  role="button"
                                  tabIndex="0"
                                  key={nanoid()}
                                  style={{
                                    borderRadius: '5px',
                                    marginTop:
                                      window.innerHeight < 700
                                        ? '0rem'
                                        : '0.5rem',
                                    padding: '0.5rem',
                                    paddingLeft: '1rem',
                                    paddingRight: '1rem',
                                    color: 'white',
                                    fontSize:
                                      window.innerHeight < 700
                                        ? '1rem'
                                        : '1.2rem',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignContent: 'center',
                                  }}
                                >
                                  >{el.icon}
                                  {el.text}
                                  {el.src}
                                </div>
                              </NavLink>
                            );
                          }
                        } else {
                          return (
                            <NavLink
                              to={el.url}
                              onClick={this.closeMenu}
                              style={{
                                textDecoration: 'none',
                                fontSize: '1rem',
                              }}
                            >
                              <div
                                className="navigation-link"
                                role="button"
                                tabIndex="0"
                                key={nanoid()}
                                style={{
                                  borderRadius: '5px',
                                  marginTop:
                                    window.innerHeight < 700
                                      ? '0rem'
                                      : '0.5rem',
                                  padding: '0.5rem',
                                  paddingLeft: '1rem',
                                  paddingRight: '1rem',
                                  color: 'white',
                                  fontSize:
                                    window.innerHeight < 700
                                      ? '1rem'
                                      : '1.2rem',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignContent: 'center',
                                }}
                              >
                                {el.icon}
                                <Typography>&nbsp;</Typography>
                                {el.text}
                                {el.src}
                              </div>
                            </NavLink>
                          );
                        }
                      })}
                      <Grid
                        spacing={1}
                        container
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '1rem',
                        }}
                        id="mobile-badges"
                      >
                        <Grid
                          item
                          xs={12}
                          spacing={3}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            flexDirection: 'row',
                            maxWidth: '300px',
                            marginBottom:
                              window.innerHeight < 900 ? '1rem' : '2rem',
                            marginTop:
                              window.innerHeight < 550 ? '-1rem' : '1rem',
                            // background:'pink',
                          }}
                        >
                          <a
                            href="https://play.google.com/store/apps/details?id=com.iternal.app&gl=GB"
                            target="_blank"
                          >
                            <img
                              src={google}
                              style={{ width: '120px', marginRight: '1rem' }}
                            />
                          </a>
                          <div
                            style={{
                              marginTop:
                                window.innerWidth > 1080 ? '0' : '0rem',
                            }}
                          >
                            <a
                              href="https://apps.apple.com/app/id1596916812"
                              target="_blank"
                            >
                              <img src={apple} style={{ width: '108px' }} />
                            </a>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            marginTop:
                              window.innerHeight < 750 ? '0rem' : '2rem',
                          }}
                        >
                          <div
                            className="navigation-link logout"
                            role="button"
                            tabIndex="0"
                            style={{
                              fontSize:
                                window.innerHeight < 700 ? '1rem' : '1.2rem',
                              alignContent: 'center',
                              justifyContent: 'left',
                            }}
                            onClick={() =>
                              handleLogout(
                                this.props.history,
                                this.props.handleChangeState,
                              )
                            }
                          >
                            <SignOutIcon
                              style={{
                                fontSize:
                                  window.innerHeight < 700 ? '1rem' : '1.2rem',
                              }}
                            />{' '}
                            Logout
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Collapse>
                </div>
              )}

              {!isLoggedIn && (
                <div>
                  <Collapse in>
                    <div className="navigation-link-container d-block">
                      {[
                        {
                          text: 'Login',
                          url: C.LOGIN_URL,
                        },
                        {
                          text: 'Sign Up',
                          url: C.SIGNUP_URL,
                        },
                      ].map(el => (
                        <div
                          className="navigation-link"
                          role="button"
                          tabIndex="0"
                        >
                          <NavLink to={el.url}>{el.text}</NavLink>
                        </div>
                      ))}
                    </div>
                  </Collapse>
                </div>
              )}
            </div>
          </SwipeableDrawer>
          <Link
            to={C.DASHBOARD_URL}
            className={isSmallScreen ? 'logo-container' : ''}
          >
            <img
              src={this.decideLogo()}
              alt="Iternal Logo"
              style={{ width: '130px' }}
            />
          </Link>
          {isLoggedIn ? (
            <div className="button-container">
              <Button
                variant="contained"
                color="secondary"
                onClick={this.closeMenu}
                style={{
                  marginTop: '0rem',
                  marginRight: window.innerWidth < 500 ? '0rem' : '1rem',
                  borderRadius: '100px',
                  Width: '164px',
                  minHeight: '50px',
                  fontWeight: '700',
                  boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',
                }}
              >
                <Link
                  to={{
                    pathname: C.CREATE_MEMORY_URL,
                    state: {
                      refreshNeeded: true,
                    },
                  }}
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  <AddIcon fontSize="inherit" />
                  {window.innerWidth > 376 ? 'Add Story' : 'Add'}
                </Link>
              </Button>
            </div>
          ) : (
            <div className="button-container">
              <Button
                variant="contained"
                color="primary"
                onClick={this.closeMenu}
              >
                <Link
                  to={{
                    pathname: C.SIGNUP_URL,
                  }}
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  Sign Up
                </Link>
              </Button>
            </div>
          )}
        </div>
        {isLoggedIn && menuOptionsVisible && (
          <div>
            <Collapse in={isSmallScreen ? this.state.menuOpen : true}>
              <div
                className="navigation-link-container d-block"
                style={{
                  // border: "1px solid white",
                  padding: '1rem',
                  borderRadius: '20px',
                  marginTop: '2rem',
                }}
              >
                <NavUserSection
                  style={{ margin: '2rem' }}
                  userName={this.state.userName}
                  userPhoto={this.state.userPhoto}
                  signOutFunc={() =>
                    handleLogout(
                      this.props.history,
                      this.props.handleChangeState,
                    )
                  }
                  profileURL={C.BASICS_URL}
                  createMemoryURL={C.CREATE_MEMORY_URL}
                  memoriesURL={C.MEMORIES_URL}
                  dashboardURL={C.DASHBOARD_URL}
                  openNotificationsFunc={this.toggleModal}
                  closeMobileMenuFunc={this.closeMenu}
                />
                {[
                  {
                    text: 'Home',
                    icon: <HomeIcon fontSize="inherit" />,
                    url: C.DASHBOARD_URL,
                  },

                  /* {
                    text: "My Stories",
                    icon: <Stories fontSize="inherit" />,
                    url: C.MEMORIES_URL,
                  }, */
                  {
                    text: 'Prompts',
                    icon: <Categories fontSize="inherit" />,
                    url: C.PROMPTS_URL,
                  },
                  {
                    text: `Friends`,
                    icon: <Friends fontSize="inherit" />,
                    url: C.MY_FRIENDS_MEMORIES,
                  },
                  {
                    text: 'Groups',
                    icon: <GroupIcon fontSize="inherit" />,
                    url: C.GROUPS_URL,
                  },
                  {
                    text: 'Albums',
                    icon: <PhotoAlbumIcon fontSize="inherit" />,
                    url: C.ALBUMS_URL,
                  },

                  // {
                  //   text: 'My Memories',
                  //   icon: <PhotoAlbumIcon fontSize="inherit" />,
                  //   url: C.MEMORIES_URL,
                  // },

                  // {
                  //   text: `Add People`,
                  //   icon: <SupervisedUserCircleIcon fontSize="inherit" />,
                  //   url: C.FRIENDS_AND_FAMILY_URL,
                  // },

                  /* {
                    text: `Add People`,
                    icon: <SupervisedUserCircleIcon fontSize="inherit" />,
                    url: C.DASHBOARD_URL,
                  }, */
                  {
                    text: 'My Profile',
                    icon: <Profile fontSize="inherit" />,
                    url: C.BASICS_URL,
                  },
                  {
                    text: 'Go Premium',
                    icon: <FaCrown />,
                    url: C.GO_PREMIUM,
                  },

                  // {
                  //   text: 'This or That',
                  //   icon: <QuestionAnswerIcon fontSize="inherit" />,
                  //   url: C.THISORTHAT_URL,
                  // },
                  // {
                  //   text: 'Email The Future',
                  //   icon: <EmailIcon fontSize="inherit" />,
                  //   url: C.EMAIL_THE_FUTURE_URL,
                  // },
                ].map(el => {
                  if (el.text === 'Go Premium') {
                    if (!isPremium) {
                      return (
                        <NavLink
                          to={el.url}
                          onClick={this.closeMenu}
                          style={{ textDecoration: 'none', fontSize: '1rem' }}
                        >
                          <div
                            className="navigation-link"
                            role="button"
                            tabIndex="0"
                            key={nanoid()}
                            style={{
                              // border: "solid 1px white",
                              borderRadius: '5px',
                              marginTop: '0.5rem',
                              // marginLeft: "1rem",
                              padding: '0.5rem',
                              paddingLeft: '1rem',
                              paddingRight: '1rem',
                            }}
                          >
                            {el.icon}
                            {el.text}
                            {el.src}
                          </div>
                        </NavLink>
                      );
                    }
                  } else {
                    return (
                      <NavLink
                        to={el.url}
                        onClick={this.closeMenu}
                        style={{ textDecoration: 'none', fontSize: '1rem' }}
                      >
                        <div
                          className="navigation-link"
                          role="button"
                          tabIndex="0"
                          key={nanoid()}
                          style={{
                            // border: "solid 1px white",
                            borderRadius: '5px',
                            marginTop: '0.5rem',
                            // marginLeft: "1rem",
                            padding: '0.5rem',
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                          }}
                        >
                          {el.icon}
                          {el.text}
                          {el.src}
                        </div>
                      </NavLink>
                    );
                  }
                })}
                <Grid
                  spacing={1}
                  id="desktop-badges"
                  container
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '2rem',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: window.innerHeight < 700 ? 'none' : 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      maxWidth: '120px',
                      marginBottom: window.innerHeight < 900 ? '1rem' : '2rem',
                      marginTop: window.innerHeight < 900 ? '0rem' : '1rem',
                    }}
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.iternal.app&gl=GB"
                      target="_blank"
                    >
                      <img
                        src={google}
                        style={{
                          width: '120px',
                          marginRight: '1rem',
                          marginBottom: '1rem',
                        }}
                      />
                    </a>
                    <div
                      style={{
                        marginTop: window.innerWidth > 1080 ? '0' : '0rem',
                      }}
                    >
                      <a
                        href="https://apps.apple.com/app/id1596916812"
                        target="_blank"
                      >
                        <img src={apple} style={{ width: '120px' }} />
                      </a>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: window.innerHeight < 850 ? '0rem' : '0rem',
                    }}
                  >
                    <div
                      className="navigation-link logout"
                      role="button"
                      tabIndex="0"
                      onClick={() =>
                        handleLogout(
                          this.props.history,
                          this.props.handleChangeState,
                        )
                      }
                    >
                      <SignOutIcon fontSize="inherit" style={{}} /> Logout
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Collapse>
          </div>
        )}

        {!isLoggedIn && (
          <div>
            <Collapse in={isSmallScreen ? this.state.menuOpen : true}>
              <div className="navigation-link-container d-block">
                {[
                  {
                    text: 'Login',
                    url: C.LOGIN_URL,
                  },
                  {
                    text: 'Sign Up',
                    url: C.SIGNUP_URL,
                  },
                ].map(el => (
                  <div className="navigation-link" role="button" tabIndex="0">
                    <NavLink to={el.url}>{el.text}</NavLink>
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
        )}
      </div>
    );
  }
}

export default Header;
