import styled from 'styled-components';

export const DividerWrapper = styled.p`
  margin: 1rem 3rem 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 1);

  ::before,
  ::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.85);
  }

  :not(:empty)::before {
    margin-right: 0.5em;
  }

  :not(:empty)::after {
    margin-left: 0.5em;
  }
`;

export const IternalLogoWrapper = styled.img`
  width: 1rem;
  user-select: none;
`;
