import axios from 'axios';
import Swal from 'sweetalert2';

import {
  API_GIFT_URL,
} from '../constants/apiRoutes';

import makeError from './makeError';

const getGiftedBonuses = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${API_GIFT_URL}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const sendGiftedBonus = async ({ bonusId, sharedWithEmail, options }) => {
  try {
    const { data } = await axios.post(API_GIFT_URL, {
      sharedWithEmail,
      bonusId,
    });
    if (data) {
      Swal.fire({
        title: `You've sent your bonus to ${sharedWithEmail} successfully!`,
        type: 'success',
        timeout: 3000,
      });
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const giftedBonus = {
    sendGiftedBonus,
    getGiftedBonuses,
};

export default giftedBonus;
