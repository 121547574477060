import React, { Component } from 'react';

export default class Privacy extends Component {
  state = {};

  render() {
    return (
      <>
        <h1>Privacy</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.{' '}
        </p>
      </>
    );
  }
}
