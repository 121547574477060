import axios from 'axios';
import { API_LINK_TOKENS, API_LINK_TOKEN, API_SHARE_LINK_TOKEN } from '../constants/apiRoutes';
import makeError from './makeError';

const getActiveLinkTokenForLoggedInUser = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(API_LINK_TOKENS);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const getUserInfoAndMemoriesAndByToken = async ({ shortId, options }) => {
  try {
    const url = API_LINK_TOKEN.replace(':shortId', shortId);

    const { data } = await axios.get(url, {
      params: { expand: ['memories', 'users', 'abouts'] },
    });
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const getSharedUserInfoAndMemoriesAndByToken = async ({ shortId, options }) => {
  try {
    const url = API_SHARE_LINK_TOKEN.replace(':shortId', shortId);

    const { data } = await axios.get(url, {
      params: { expand: ['memories', 'users', 'abouts'] },
    });
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const linkTokens = {
  getActiveLinkTokenForLoggedInUser,
  getUserInfoAndMemoriesAndByToken,
  getSharedUserInfoAndMemoriesAndByToken,
};

export default linkTokens;
