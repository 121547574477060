import React from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { Line, AnswerSection } from './style';

import deleteCross from '../../../assets/delete-cross.svg';
import { CHAT_URL } from '../../../constants/constantUrls';

import * as S from './style';

export const AnswerDisplay = ({ answerData, deleteThisOrThat }) => {
  let firstAnswerTrue;
  let secondAnswerTrue;
  if (answerData.options[0] === answerData.answer) {
    firstAnswerTrue = true;
  } else if (answerData.options[1] === answerData.answer) {
    secondAnswerTrue = true;
  }
  return (
    <AnswerSection>
      <p className={firstAnswerTrue ? 'left correct' : 'left'}>
        {answerData.options[0]}
      </p>
      <Line />
      <p className={secondAnswerTrue ? 'right correct' : 'right'}>
        {answerData.options[1]}
      </p>
      <FaTimes
        onClick={deleteThisOrThat}
        alt="Delete this answer"
        src={deleteCross}
        style={{ marginTop: '20px' }}
      />
    </AnswerSection>
  );
};

export const NoAnswersToDisplay = () => (
  <Link to={{ pathname: CHAT_URL, state: 'thisOrThat' }}>
    <S.P>You haven&apos;t answered anything yet!</S.P>
  </Link>
);
