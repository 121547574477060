import styled from 'styled-components';

import { breakpointsMax, breakpoints } from '../../../constants/breakPoints';

export const Main = styled.main`
  max-width: 100rem;
  margin: 0 auto;
  ${'' /* background: linear-gradient(90deg, #0A2156, #1d6a91,  #0bafaa); */}


  @media ${breakpoints.laptop} {
    padding: 2rem 3rem 1rem 280px;
  }

  @media ${breakpointsMax.tablet} {
    padding: 1rem 0.5rem;
    // margin-top: 50px;
  }

  section {
    h1 {
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  .premium-block {
    .premium-block-icon {
      margin-bottom: 1rem;
      svg {
        width: 3rem;
        height: 3rem;
      }
    }
    p {
      margin-bottom: 1rem;
    }
  }
`;

export const H1 = styled.h1`
  margin: 2rem 0;
  text-transform: none;
  color: var(--neutral-dark);
`;

export const P = styled.p`
  margin-bottom: 2rem;
  text-align: center;
`;

export const Input = styled.input`
  /* border-style: hidden hidden solid hidden;
  border-color: var(--primary);
  width: 100%; */
  border: ${({ error }) =>
    error ? 'var(--input-error-border)' : 'var(--input-border)'};
  width: 100%;
  font-weight: 400;
  outline: none;
  transition: all ease 0.2s;
  background: none;
  padding: 15px;
  border-width: 2px;
  border-radius: 5px;
  :active,
  :focus {
    border: var(--input-focus-border);
  }
`;

export const DateInputWrapper = styled.div`
  border: ${({ error }) =>
    error ? 'var(--input-error-border)' : 'var(--input-border)'};
  width: 100%;
  font-weight: 400;
  outline: none;
  transition: all ease 0.2s;
  background: none;
  /* padding: 15px;  */
  border-width: 2px;
  border-radius: 5px;
  :active,
  :focus {
    border: var(--input-focus-border);
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  .title {
    margin-bottom: 10px;
  }
  label {
    :focus-within {
      outline: #a6c8ff auto 1px;
    }
  }
`;

export const Nav = styled.nav`
  position: fixed;
  bottom: 0px;
  background-color: var(--white);
`;

export const ErrorMsg = styled.p`
  color: red;
  font-size: 14px;
  align-self: flex-end;
  padding-top: 0.25rem;
`;

export const SubmitErrorMsg = styled.p`
  color: red;
  font-size: 14px;
  width: 100%;
  text-align: center;
  margin: 1rem 0;
`;

export const SubscriptionCancelAt = styled(SubmitErrorMsg)`
  font-size: 18px;
  color: #0a2156;
`;

export const ImgContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  margin-top: 1rem;
`;

export const Img = styled.img`
  width: 100px !important;
  ${'' /* height: 100px !important; */}
`;

export const CrownImg = styled.img`
  margin-bottom: -5px;
`;

export const ImgInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  + label {
    text-align: center;
    font-size: 1.25em;
    font-weight: 700;
    background-color: black;
    display: inline-block;
    font-style: normal;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 0.8rem;
    text-decoration-line: underline;
    padding: 10px;
    background: white;
    border: 0px;
  }
`;

export const CalendarWrapper = styled.div`
  input {
    border: none;
    background: transparent;
    cursor: pointer;
    width: 160%;
  }
  .react-datepicker__current-month {
    padding-bottom: 1rem;
    padding-top: 0.1rem;
  }
`;

export const MobileCalendarWrapper = styled.div`
  input {
    border: none;
    background: transparent;
    cursor: pointer;
  }
`;

export const Button = styled.button`
  background-color: var(--primary);
  opacity: ${props => (props.disabled ? '0.4' : '1')};
  color: white;
  padding: 1rem 2rem;
  border-radius: 4px;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: 0.5s;
  outline: none;
  ${props => {
    if (!props.disabled) {
      return ` &:hover {
        background-color: var(--secondary);
        color: var(--white);
      }`;
    }
  }}
`;

export const ActionButton = styled.button`
  margin: 1.2rem 2rem;
  display: flex;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;
  color: var(--neutral-dark);
  cursor: pointer;
  align-items: center;

  span {
    text-align: left;
  }
`;

export const DeleteAccountButton = styled.button`
  margin: 1.2rem 2rem;
  display: flex;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;
  color: var(--secondary-cherry);
  cursor: pointer;
  align-items: center;

  span {
    text-align: left;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${breakpointsMax.mobileXL} {
    flex-direction: column;
    width: 100%;
    padding-right: 1rem;
  }

  h1 {
    margin-right: 1rem;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  label {
    width: 100%;
    padding-right: 1rem;
    &:last-child {
      margin-right: 0px;
    }
    p {
      margin-bottom: 10px;
    }
  }
`;

export const MobileFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
export const Fieldset = styled.fieldset`
  padding: 2rem;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  display: block;
  padding: 3.25rem;
  margin-bottom: 2rem;
  @media ${breakpointsMax.tablet} {
    padding: 2rem;
  }
`;

export const ProfileDataWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  label {
    margin: 0px 10px 0px 0px;
    cursor: pointer;
  }
  button {
    margin: 0px;
    cursor: pointer;
  }
`;

export const ActionSection = styled.div`
  display: flex;
  justify-content: center;
  button {
    border: none;
    background: none;
    font-size: 0.9rem;
    font-weight: 700;
    & :hover {
      cursor: pointer;
    }

    span {
      width: 100%;
    }

    img {
      padding: 1rem;
    }
  }
`;
