import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { breakpoints } from '../../../constants/breakPoints';

export const Main = styled.main`
  margin: 30px 2rem 5rem 2rem;
  max-width: 100rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;

  @media ${breakpoints.laptop} {
    margin-top: 2rem;
    padding: 0rem 1rem 1rem 20px;
  }
`;

export const H1 = styled.h1`
  margin: 2rem 0;
`;

export const P = styled.p`
  margin-bottom: 0.5rem;
  text-align: center;

  a {
    font-weight: 700;
    color: var(--secondary);
    text-decoration: underline;
  }
`;

export const A = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  margin-top: 1.5rem;
  align-items: center;

  p {
    font-weight: normal;
    color: var(--black);
    font-size: 1.25rem;
  }

  img {
    margin-left: 0.5rem;
  }
`;

export const Nav = styled.nav`
  position: fixed;
  bottom: 0px;
  background-color: var(--white);
`;

export const Line = styled.div`
  border: 1px solid var(--primary);
  width: 100%;
`;

export const SectionContainer = styled.div`
  padding-top: 2rem;
  width: 100%;
`;

export const AboutSectionContainer = styled.div`
  width: 100%;
  padding: 0.8rem;
`;

export const AboutWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--black);
  text-decoration: none;
  margin: 1rem auto;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 0.8rem;
`;

export const Title = styled.p`
  font-weight: bold;
  font-size: 24px;
`;

export const QuestionTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
`;

export const Message = styled.p`
  font-weight: 300;
  font-size: 16px;
`;

export const Answer = styled.p`
  font-weight: 300;
  font-size: 16px;
`;

export const AnswerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const Button = styled.button`
  border: none;
  background: none;
  align-self: flex-start;
  cursor: pointer;
`;
