import React from 'react';
import * as S from './style';

import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css'; 
// import ReactPlayer from 'react-player'
// import useModal from '../../../helpers/hooks/useModal';
// import Modal from './recentArticleModal';



export default function HelpCenter() {

  // const { isShowing, toggle } = useModal();


  const DummyContent = () => (
    <p style={{ padding: '18px' }}>
      Yes, we take data security very seriously and we work hard behind the scenes to ensure everything is protected and secure. We will never share or sell your data so don’t ever have to worry about that!
    </p>
  );

  const DummyContent2 = () => (
    <p style={{ padding: '18px' }}>
      Yes, we are offering iternal for FREE to all new users whilst we are in BETA. We hope you will take this opportunity to join us and get started on creating your memories.
    </p>
  );

  const DummyContent3 = () => (
    <p style={{ padding: '18px' }}>
      The team are working really hard to get this super cool feature live as soon as possible. Make sure you have opted in to receive our updates and you’ll be one of the first to know.
    </p>
  );


  const DummyContent4 = () => (
    <p style={{ padding: '18px' }}>
      Well, we haven’t quite found the secret to living forever….yet! but…. we do know that with your iternal account your digital legacy can live on forever.
    </p>
  );

  const DummyContent5 = () => (
    <p style={{ padding: '18px' }}>
      Currently we have an open limit, whilst we are in BETA we want our users to get most out of using iternal. We may revisit these limits in the future but we will always ensure we communicate clearly and honestly with our users.
    </p>
  );






  return (
    <S.Main>
      <S.MainTitle>Help center</S.MainTitle>
      {/* Video section */}

      {/* <h2 className="help-center-title">How to videos</h2> */}
      {/* <S.VideoGrid>

        <S.GridItem>
          <S.VideoTitle>Video Title</S.VideoTitle>
          <ReactPlayer
              url="https://youtu.be/luai0p0y2zE"
              controls
              playbackRate = {2}
              width = "100%"
              height = "100%"
              className="video-player"
          />
        </S.GridItem>

        <S.GridItem>
          <S.VideoTitle>Video Title</S.VideoTitle>
          <ReactPlayer
              url="https://youtu.be/luai0p0y2zE"
              controls
              playbackRate = {2}
              width = "100%"
              height = "100%"
              className="video-player"
          />
        </S.GridItem>


      </S.VideoGrid> */}

      {/* Text help videos */}
      {/* <h2 className="help-center-title">Recent Articles</h2>
      <S.ArticleGrid>
        <S.GridItem onClick={toggle}>
          <>
            Article One
          </>
          <Modal isShowing={isShowing} hide={toggle} />
        </S.GridItem>
        <S.GridItem onClick={toggle}>
          <>
            Article Two
          </>
          <Modal isShowing={isShowing} hide={toggle} />
        </S.GridItem>
        <S.GridItem onClick={toggle}>
          <>
            Article three
          </>
          <Modal isShowing={isShowing} hide={toggle} />
        </S.GridItem>
      </S.ArticleGrid> */}


      {/* FAQ section */}
      <h2 className="help-center-title">FAQ Section</h2>
      <S.FaqSection>

        <Accordion atomic={true}>

        <AccordionItem title="Is my data secure?">
          <DummyContent />
        </AccordionItem>

        <AccordionItem title="Is it FREE?">
          <DummyContent2 />
        </AccordionItem>

        <AccordionItem title="When will I get my own AI?">
          <DummyContent3 />
        </AccordionItem>

        <AccordionItem title="Can I really live forever?">
          <DummyContent4 />
        </AccordionItem>

        <AccordionItem title="Is there a limit to how many memories I can store?">
          <DummyContent5 />
        </AccordionItem>

        </Accordion>
      </S.FaqSection>
    </S.Main>
  );
}

