import React from 'react';

const ThisOrThat = props => (
  <svg
    viewBox="0 -3 472 472"
    width={props.width}
    height={props.height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M464.969 288.504l-69.352-141.527H426.5a8 8 0 0 0 0-16H244v-33.32c22.348-3.958 39.414-24.18 39.414-48.473C283.414 22.062 262.144 0 236 0s-47.414 22.062-47.414 49.184c0 24.293 17.066 44.515 39.414 48.472v33.32H45.5a8 8 0 0 0 0 16h30.883L7.035 288.505A8.001 8.001 0 0 0 0 296.44c0 45.38 39.531 82.301 88.125 82.301s88.125-36.922 88.125-82.3a8 8 0 0 0-7.031-7.938L99.867 146.977H228v264H115.102a8.217 8.217 0 0 0-8.102 8.156v38.82a8.1 8.1 0 0 0 8.102 8.024h241.796a8.1 8.1 0 0 0 8.102-8.024v-38.82a8.22 8.22 0 0 0-8.102-8.156H244v-264h128.133L302.78 288.504a8 8 0 0 0-7.031 7.937c0 45.38 39.531 82.301 88.125 82.301S472 341.82 472 296.442a8 8 0 0 0-7.031-7.938zM204.586 49.184C204.586 30.887 218.68 16 236 16s31.414 14.887 31.414 33.184c0 18.3-14.094 33.187-31.414 33.187s-31.414-14.887-31.414-33.187zM88.125 158.906l63.242 129.07H24.883zm0 203.371c-36.824 0-67.293-25.3-71.602-58.3h143.204c-4.309 33-34.778 58.3-71.602 58.3zM349 449.977H123v-23h226zm98.117-162H320.633l63.242-129.07zm-63.242 74.3c-36.824 0-67.293-25.3-71.602-58.3h143.204c-4.309 33-34.778 58.3-71.602 58.3zm0 0"
      fill={props.color || 'currentColor'}
    />
  </svg>
);

export default ThisOrThat;
