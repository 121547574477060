import styled from 'styled-components';

import { breakpointsMax } from '../../../constants/breakPoints';

export const Main = styled.main`
  display: flex;
  max-width: 100rem;
  margin: 0 auto;
  flex-direction: column;
  padding: 2rem 3rem 1rem 280px;

  @media ${breakpointsMax.laptop} {
    padding: 1rem;
  }
  .book {
    transform-style: preserve-3d;
    position: relative;
    height: 300px;
    cursor: pointer;
    backface-visibility: visible;
  }

  .front, .back, .page1, .page2, .page3, .page4, .page5, .page6 {
    transform-style: preserve-3d;
    position: absolute;
    width: 200px;
    height: 100%;
    top: 0; left: 0;
    transform-origin: left center;
    transition: transform .5s ease-in-out, box-shadow .35s ease-in-out;
  }
  
  .front, .back {
    background: navy;
  }
  
  .front, .page1, .page3, .page5 {
    border-bottom-right-radius: .5em;
    border-top-right-radius: .5em;
  }
  
  .back, .page2, .page4, .page6 {
    border-bottom-right-radius: .5em;
    border-top-right-radius: .5em;
  }
  
  .page1 { 
    background: #efefef;
  }
  
  .page2 {
    background: #efefef;
  }
  
  .page3 {
    background: #f5f5f5;
  }
  
  .page4 {
    background: #f5f5f5;
  }
  
  .page5 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fafafa;
  }
  
  .page6 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fdfdfd;
  }
  
  .book:hover .front {
    transform: rotateY(-160deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
  }
  
  .book:hover .page1 {
    transform: rotateY(-150deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
  }
  
  .book:hover .page2 {
    transform: rotateY(-30deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
  }
  
  .book:hover .page3 {
    transform: rotateY(-140deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
  }
  
  .book:hover .page4 {
    transform: rotateY(-40deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
  }
  
  .book:hover .page5 {
    transform: rotateY(-130deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
  }
  
  .book:hover .page6 {
    transform: rotateY(-50deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
  }
  
  .book:hover .back {
    transform: rotateY(-20deg) scale(1.1);
  }
`
