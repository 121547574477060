import styled from 'styled-components';
import { breakpointsMax } from '../../../constants/breakPoints';

export const Line = styled.div`
  border: 1px solid var(--primary);
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  /* display:flex; */

  button {
    margin:5px;
  }
  @media ${breakpointsMax.desktop} {
    align-items: center;
    justify-content: center;
  }
`

export const GridWrapper = styled.div `
  display:grid;
  grid-template-columns:1fr 1fr 1fr 1fr;
  grid-gap:1rem;
  text-align:center;  
  margin-top:20px;  
  @media ${breakpointsMax.laptopL} {
    grid-template-columns:1fr 1fr 1fr;
    button {
      margin:5px; 
    }
  }

  @media ${breakpointsMax.tablet} {

    grid-template-columns:1fr 1fr;
  }
  @media ${breakpointsMax.mobileXL} {
    grid-template-columns:1fr;
  }
`

export const MemoryWrapper = styled.div`
  display:flex;
  margin: 2rem 0rem;
  justify-content:space-between;
  background: ;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; 

  @media ${breakpointsMax.mobileXL} {
    margin: 2rem 1rem;
  }
`;

export const MemoryCard = styled.div`
  .bg_image {
    margin:1rem auto;

  }
`

export const MemoryCardWrapper = styled.div`
  margin:1.5rem;

`

export const TitleAndDate = styled.div`
  display: flex;
  /* width: 30%; */
`;

export const DateWrapper = styled.div`  
  display: flex;
  font-weight:normal;
  font-size: 1rem;
  width:96px;
  color: var(--neutral-dark);
  span {
    margin-right:0.2rem;
  }
  @media ${breakpointsMax.tablet} {
    flex-direction:column;
  }

  @media ${breakpointsMax.mobileL} {
    display: none;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-left: 3rem;
  @media ${breakpointsMax.tablet} {
    margin-left: 0rem;
  }
`;

export const TagWrapper = styled.div ` 
  width: 60px;
`

export const LinkWrapper = styled.div `
  display: flex;
  /* width: 10%; */
  justify-content:flex-end;
  color: var(--primary-gold); 
  font-weight:normal;
  text-decoration:underline;
`

export const EndWrapper = styled.div`
  display:flex;
  width:96px;
`

export const Title = styled.p`
  ${'' /* color: var(--primary); */}
  color: var(--neutral-dark);
  font-size: 1rem;
  font-weight: 600; 
  margin-bottom:1.5rem;
  font-decoration:underline;
`;

export const FriendTitle = styled.p`
  color: var(--neutral-dark);
  font-size: 1.5rem;
  font-weight: 900; 
  margin-bottom:0.5rem;
`;

export const Subtitle = styled.p`
  color: var(--neutral-slate);
  font-size: 0.9rem;
  font-weight: 900; 

`;

export const Span = styled.span`
  font-weight: 900;
  font-style: normal;
`;

export const DaySpan = styled(Span)`
  font-size: 45px;
`;

export const MonthSpan = styled(Span)`
  font-size: 14px;
`;

export const YearSpan = styled(Span)`
  font-size: 14px;
`;

export const Draft = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: var(--secondary-cherry);
`;

export const TimelineWrapper = styled.div`
.vertical-timeline::before {
  background: black;
}

.vertical-timeline-element-icon {
  box-shadow:none;
  border:1px solid grey;
}

.vertical-timeline-element-content {
  border:1px solid grey;
  box-shadow:none;
  border-top: 3px solid #2196f3;
}

.vertical-timeline-element-date {
  margin:0rem 1rem;
  font-weight:bold !important;
}

.timeline-item-title {
  background:none !important;
  line-height: 1.5;
  padding: 0px !important;
}

.display-mobile {
    display:none;
  }

.display-desktop {
    display:block !important;
}

.timeline-main-wrapper {
  padding: 0 !important
}


@media ${breakpointsMax.tablet} {
    .display-mobile {
      display:block !important;
    }

    .display-desktop {
      display:none !important;
    }
  }
`