// React
import React from "react";
import PropTypes from "prop-types";

// Moment (Date formatting)
import moment from "moment";

// MUI Icons - Timeline Dot
import TextMemoryIcon from "@material-ui/icons/Subject";
import PhotoMemoryIcon from "@material-ui/icons/Photo";
import VideoMemoryIcon from "@material-ui/icons/Videocam";
import MixedMediaMemoryIcon from "@material-ui/icons/PermMedia";

// MUI Lab
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@material-ui/lab";

// Lazyload
import LazyLoad from "react-lazyload";

// Memory Card
import { MemoryCard } from "./ContentCards/MemoryCard/MemoryCard";
// Prompt Card
import { PromptCard } from "./ContentCards/PromptCard/PromptCard";
// Milestone Card
import { MilestoneCard } from "./ContentCards/MilestoneCard/MilestoneCard";

// Styled Components
import { TimelineStyles } from "./NewTimeline.style";

export function NewTimeline({
  memories,
  milestones,
  getMilestonesFunc,
  prompts,
  showPrompts,
  minimumTimelineLength,
  highlightedElement,
  profile = {},
  viewingFriendsMemory = false,
  isCompetition = false,
  editMilestoneFunc,
  noLogin = false,
  ...props
}) {
  const renderMemory = (memory, i) => {
    // Determine Date Display
    const renderDate = () => {
      const { dateType, date } = memory;
      const ddmmmyyy = moment(date).format("DD MMM YYYY");
      const yyyy = moment(date).format("YYYY");

      return dateType === "full" ? ddmmmyyy : yyyy;
    };

    // Determine icon
    const renderIcon = () => {
      const { icon, tempImages, tempVideo, tempVoice, description } = memory;

      // Has a custom icon type
      if (icon) {
        return icon;
      }
      // Has multiple media types
      if (
        tempImages?.length > 0 &&
        tempVideo?.length > 0 &&
        tempVoice?.length > 0
      ) {
        return <MixedMediaMemoryIcon />;
      }
      // Has video only
      if (tempVideo?.length > 0) {
        return <VideoMemoryIcon />;
      }
      // Has photos only
      if (tempImages?.length > 0) {
        return <PhotoMemoryIcon />;
      }
      // Has description only
      if (description?.length > 0) {
        return <TextMemoryIcon />;
      }
      // Has none - render blank timeline dot
      return null;
    };

    // Determine whether the memory is highlighted
    // FIXME: CHANGED BOTH TO NO HIGHLIGHT UNTIL SCROLL FIX
    const highlight =
      highlightedElement === i + 1 ? "no_highlight" : "no_highlight";

    const formattedDate = renderDate();

    return (
      <LazyLoad height={200} offset={500} overflow once>
        <TimelineItem
          key={memory._id}
          className={`singleMemoryWrapper ${highlight}`}
        >
          <TimelineOppositeContent className="timelineDatesWrapper" />
          <TimelineSeparator
            id="friends-timeline"
            className="timelineDotsAndConnectorsWrapper"
            style={{ display: window.innerWidth < 600 ? "none" : "flex" }}
          >
            <TimelineDot color="primary" style={{ padding: "0.25rem" }}>
              {renderIcon()}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className="timelineMemoryCardsWrapper">
            <MemoryCard
              style={{ marginLeft: "rem" }}
              {...memory}
              profile={profile}
              viewingFriendsMemory={viewingFriendsMemory}
              formattedDate={formattedDate}
              isCompetition={isCompetition}
              noLogin={noLogin}
            />
          </TimelineContent>
        </TimelineItem>
      </LazyLoad>
    );
  };

  const renderPrompt = (prompt, i) => {
    // Determine whether the prompt is highlighted
    // TODO: CHANGED BOTH TO NO HIGHLIGHT UNTIL SCROLL FIX
    const highlight =
      highlightedElement === i + 1 ? "no_highlight" : "no_highlight";

    return (
      <LazyLoad height={200} offset={200} overflow once>
        <TimelineItem
          key={prompt._id}
          className={`singleMemoryWrapper ${highlight}`}
        >
          <TimelineOppositeContent className="timelineDatesWrapper" />
          <TimelineSeparator
          id="first questions"
            className="timelineDotsAndConnectorsWrapper"
            style={{display: window.innerWidth < 600 ? "none" : "flex"  }}
          >
            <TimelineDot color="primary" />
            <svg />
            <TimelineConnector className="timelineConnector" />
          </TimelineSeparator>
          <TimelineContent className="timelineMemoryCardsWrapper">
            <PromptCard {...prompt} />
          </TimelineContent>
        </TimelineItem>
      </LazyLoad>
    );
  };

  const renderMilestone = (milestone, i) => {
    // Render date
    const renderDate = () => {
      const { date } = milestone;
      return moment(date).format("DD MMM YYYY");
    };

    // Determine whether the prompt is highlighted
    // TODO: CHANGED BOTH TO NO HIGHLIGHT UNTIL SCROLL FIX
    const highlight =
      highlightedElement === i + 1 ? "no_highlight" : "no_highlight";

    const formattedDate = renderDate();

    return (
      <LazyLoad
        height={200}
        offset={200}
        overflow
        once
        style={{ display: "none" }}
      >
        <TimelineItem
          key={milestone._id}
          className={`singleMemoryWrapper ${highlight}`}
        >
          <TimelineOppositeContent className="timelineDatesWrapper">
            {/* <Typography variant="body1" color="textSecondary">
                {renderDate()}
              </Typography> */}
          </TimelineOppositeContent>
          <TimelineSeparator
            className="timelineDotsAndConnectorsWrapper"
            style={{ display: window.innerWidth < 600 ? "none" : "flex" }}
          >
            <TimelineDot color="secondary" />
            <TimelineConnector className="timelineConnector" />
          </TimelineSeparator>
          <TimelineContent className="timelineMemoryCardsWrapper">
            <MilestoneCard
              {...milestone}
              editFunc={editMilestoneFunc}
              editMilestoneFunc={editMilestoneFunc}
              formattedDate={formattedDate}
            />
          </TimelineContent>
        </TimelineItem>
      </LazyLoad>
    );
  };

  // Collect, organize, and render all timeline content
  const generateTimelineContent = () => {
    const memoriesToRender = memories?.map((el) => ({ ...el, type: "memory" }));
    const milestonesToRender = milestones?.map((el) => ({
      ...el,
      type: "milestone",
    }));
    const filteredPrompts = prompts?.filter((el) => !el.completed);
    const promptsToRender = filteredPrompts?.map((el) => ({
      ...el,
      type: "prompt",
    }));

    // Collect all content to render
    let timelineContent = [];
    if (memoriesToRender) {
      timelineContent.push(...memoriesToRender);
    }
    if (milestonesToRender) {
      timelineContent.push(...milestonesToRender);
    }

    // Sort by date - newest first
    timelineContent = timelineContent.sort(
      (a, b) => Date.parse(b.date) - Date.parse(a.date)
    );

    // Add prompts, if needed
    const numberOfPromptsNeeded =
      minimumTimelineLength - timelineContent.length;
    if (showPrompts && promptsToRender && numberOfPromptsNeeded > 0) {
      timelineContent.push(...promptsToRender.slice(0, numberOfPromptsNeeded));
    }

    return timelineContent.map((content, i) => {
      switch (content.type) {
        case "memory":
          return renderMemory(content, i);
        case "milestone":
          return renderMilestone(content, i);
        case "prompt":
          return renderPrompt(content, i);
        default:
          return null;
      }
    });
  };

  return (
    <TimelineStyles {...props}>
      <Timeline align="left" className="timeline">
        {generateTimelineContent()}
      </Timeline>
    </TimelineStyles>
  );
}

NewTimeline.propTypes = {
  /**
   * Provide an array of memories
   */
  memories: PropTypes.arrayOf({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    date: PropTypes.string,
    dateAge: PropTypes.string,
    dateType: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf({
      _id: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    isDraft: PropTypes.bool,
    isPublic: PropTypes.bool,
    taggedEmails: PropTypes.array,
    tempImages: PropTypes.arrayOf({
      _id: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    user: PropTypes.string,
    video: PropTypes.string,
    voice: PropTypes.string,
  }),
  /**
   * Provide an array of milestones
   */
  milestones: PropTypes.arrayOf({
    _id: PropTypes.string,
    user: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    date: PropTypes.string,
  }),
  /**
   * Provide a function for re-fetching all milestones currently displayed
   */
  getMilestonesFunc: PropTypes.func,
  /**
   * Provide an array of memory prompts
   */
  prompts: PropTypes.arrayOf({
    _id: PropTypes.string,
    prompt: PropTypes.string,
    title: PropTypes.string,
    category: PropTypes.string,
  }),
  /**
   * Should memory prompts be displayed for this timeline?
   */
  showPrompts: PropTypes.bool,
  /**
   * If the sum of memories and milestones is lower than this number, the remainder will be populated with memory prompts.
   * `minimumTimelineLength - (memories.length + milestones.length)`
   */
  minimumTimelineLength: PropTypes.number,

  /**
   * Which element in the timeline should be highlighted as the current 'active' element?
   */
  highlightedElement: PropTypes.number,
};

NewTimeline.defaultProps = {
  // Memories and Milestones
  memories: undefined,
  milestones: undefined,
  getMilestonesFunc: () => {},
  // Prompts
  prompts: undefined,
  showPrompts: true,
  minimumTimelineLength: 5,
  // Visual
  highlightedElement: undefined,
};
