// renders flows managed by dialogflow
// takes flow keyword as prop and handles respective flows
// if user types in message -> server sends it to dialogflow and receives bot response
// api route for flow interaction /api/bot/messages

import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

// STYLE
import {
  ConversationView,
  MessageBox,
  Form,
  BottomDiv,
  ButtonWrapper,
} from './style';

import { MessageWrapper, OptionWrapper } from '../Chat';
import * as S from '../Chat/style';

// COMMON
import Icon from '../../Common/Icon/Icon';
import Button from '@material-ui/core/Button';

// CONSTANTS
import { dialogflowPayload } from '../../../constants/dialogflow';
import { BASICS_URL, CHAT_URL } from '../../../constants/constantUrls';
import { API_STORE_FLOW } from '../../../constants/apiRoutes';

// sends intent to sever
const getIntent = async (eventName, id) =>
  await axios.post('/api/bot/messages', { event: eventName, userId: id });

export default class DialogflowChat extends Component {
  // userInput contains user text input
  // conversation holds/ concatinates each message of conversation
  state = {
    userInput: '',
    conversation: [],
    intent: '',
    onboardingUserData: {},
    inputType: 'text',
    options: [],
    endFlow: false,
    endOnboardingFlow: false,
    intentFlow: null,
    aboutFollowUpOption: false,
    buttonLoading: false,
    loadingMsg: false,
    inputReady: false,
  };

  componentDidMount() {
    // gets props from chat component deciding which dialogflow to be triggered
    const { flow, user } = this.props;

    getIntent(flow, user)
      .then(({ data }) => {
        const { messages, intent, finishedFlows } = data;

        this.renderBotMsg(messages, this.state.inputType, intent);

        // if there are no flows then tell user and ask what else they want to do
        if (finishedFlows) {
          return this.setState({
            intent,
            aboutFollowUpOption: true,
          });
        }
        return this.setState({
          intent,
          intentFlow: intent,
          aboutFollowUpOption: false,
        });
      })
      .catch(err => console.log('submit msg err', err));
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () =>
    this.messagesEnd && this.messagesEnd.scrollIntoView({ behavior: 'smooth' });

  // sends user message to server and answers with bot response
  msgToServer = async (msg, id) => {
    this.setState({
      loadingMsg: true,
    });
    return await axios.post('/api/bot/messages', { msg, user: id });
  };

  handleEndQuestions = () => {
    const { user } = this.props;

    // setup userInput
    const msgHuman = {
      text: "I'd rather not say",
      type: 'user',
      time: Date.now(),
      intent: this.state.intentFlow,
    };

    // append to conversation
    this.setState({
      conversation: [...this.state.conversation, msgHuman],
      inputType: 'text',
    });

    getIntent('endQuestions', user)
      .then(({ data }) => {
        const { messages, endFlow } = data;
        this.renderBotMsg(messages);

        // detect if endFlow and run function to store conversation and get next question
        if (endFlow) {
          const { conversation, intentFlow } = this.state;
          const filteredMsg = conversation.filter(
            msg =>
              msg.intent.includes(intentFlow) &&
              msg.text !== 'No worries. What would you like to do now?',
          );
          axios
            .post(API_STORE_FLOW, {
              messages: filteredMsg,
              flow: intentFlow,
            })
            .then(this.setState({ aboutFollowUpOption: true }))
            .catch(err => console.log(err));
        }
      })
      .catch(err => console.log('submit msg err', err));
  };

  triggerExplainAppFlow = () => {
    const { user } = this.props;
    this.setState({ inputReady: false });

    getIntent('explainApp', user)
      .then(({ data }) => {
        const { messages } = data;
        this.renderBotMsg(messages);
      })
      .catch(err => console.log('submit msg err', err));
  };

  handleEnter = e => {
    const key = e.key || e.keyCode;
    if (key === 13 || key === 'Enter') {
      this.clickButton.focus();
    }
  };

  // loops over array of dialogflow bot responses and concats them to conversation array in state
  renderBotMsg = (array, type, intent) => {
    // only set input to be ready once final message displayed
    setTimeout(() => {
      this.setState({ inputReady: true });
    }, 1500 * (array.length - 1));
    array.forEach((msg, index) => {
      const msgBot = {
        text: type === 'payload' ? msg : msg.text.text[0],
        type: 'bot',
        time: Date.now(),
        intent: intent || this.state.intentFlow,
      };
      if (index > 0) {
        setTimeout(() => {
          this.setState(
            {
              loadingMsg: false,
              conversation: [...this.state.conversation, msgBot],
            },
            () => {
              if (index < array.length - 1) {
                this.setState({
                  loadingMsg: true,
                });
              }
            },
          );
        }, 1500 * index);
      } else {
        this.setState({
          loadingMsg: false,
          conversation: [...this.state.conversation, msgBot],
          inputReady: false,
        });
      }
      /*
      return this.setState({
        conversation: [...this.state.conversation, msgBot]
      });
      */
    });
  };

  // allows the displayed value to update as the user types
  handleChange = e => {
    const { value } = e.target;
    this.setState({ userInput: value });
  };

  // handle custom click
  handleOptionClick = option => {
    this.setState({ inputReady: false });
    if (option === "I'd rather not say") {
      return this.handleEndQuestions();
    }
    if (option === 'findOut') {
      return this.triggerExplainAppFlow();
    }

    this.setState({ userInput: option }, () => this.submitMessage());
  };

  // if freeFormText append to conversation but don't send to dialogflow
  handleFreeformText = () => {
    const { userInput } = this.state;
    if (!userInput) return;
    // setup userInput
    const msgHuman = {
      text: userInput,
      type: 'user',
      time: Date.now(),
      intent: this.state.intentFlow,
    };

    // append to conversation
    this.setState({ conversation: [...this.state.conversation, msgHuman] });

    // clear input field
    this.setState({ userInput: '' });
  };

  focusInput = () => {
    if (this.inputField) {
      this.inputField.focus();
    }
  };

  // function to create user message and send to dflow
  submitMessage = async () => {
    const { user } = this.props;
    const { userInput, intentFlow } = this.state;

    // setup userInput
    if (userInput && userInput !== '' && userInput !== 'finish') {
      const msgHuman = {
        text: userInput,
        type: 'user',
        time: Date.now(),
        intent: this.state.intentFlow,
      };

      // if any remaining userInput append to conversation
      this.setState({ conversation: [...this.state.conversation, msgHuman] });
    }

    // if user says 'I'd rather not say' end flow and store conversation
    const newState = {};
    const message = userInput;
    // post request -> sends user input and receives dflow response object as set in /bot/messages
    this.msgToServer(message, user)
      .then(({ data }) => {
        const {
          messages,
          intent,
          onboardingParams,
          payloadResponse,
          endFlow,
          endOnboardingFlow,
        } = data;
        newState.intent = intent;

        if (endOnboardingFlow) {
          newState.endOnboardingFlow = true;
        }
        // deal with custom payloads
        if (payloadResponse) {
          const { botMsgs, questionType, options } = payloadResponse;
          newState.inputType = questionType;
          newState.options = options;
          this.renderBotMsg(botMsgs, 'payload');
        } else {
          // store onboarding parameters in state
          if (onboardingParams && intentFlow === 'Onboarding') {
            newState.onboardingUserData = onboardingParams;
          }
          newState.inputType = 'text';
          this.renderBotMsg(messages, 'text');
        }
        this.setState({ ...newState });

        // detect if endFlow and run function to store conversation and get next question
        if (endFlow) {
          const { conversation, intentFlow } = this.state;
          // filter only the msgs that belong to the intent flow

          const filteredMsg = conversation.filter(
            msg =>
              msg.intent.includes(intentFlow) &&
              msg.text !== 'No worries. What would you like to do now?',
          );

          axios
            .post(API_STORE_FLOW, {
              messages: filteredMsg,
              flow: intentFlow,
            })
            .then(this.setState({ aboutFollowUpOption: true }))
            .catch(err => console.log(err));
        }
      })
      .catch(err => console.log('submit msg err', err));

    // clear input field
    this.setState({ userInput: '' });
    this.focusInput();
  };

  // sends user msg to server and receives bot response
  // adds messages to conversation and resets state
  handleSubmit = async e => {
    e.preventDefault();

    const { inputType } = this.state;
    // if freeformText don't submit message
    if (inputType === dialogflowPayload.questionTypes.freeformText) {
      return this.handleFreeformText();
    }

    // makes sure user inputs something before sending
    if (!this.state.userInput.trim()) return;

    return this.submitMessage();
  };

  handleOptions = option => {
    const { conversation } = this.state;
    const { history, flow, user } = this.props;
    this.setState({ buttonLoading: true });
    switch (option) {
      case 'go to profile':
        history.push(BASICS_URL);
        break;
      case 'keep chatting':
        history.push({
          pathname: CHAT_URL,
          state: { conversation },
        });
        break;
      case 'do something else':
        history.push({
          pathname: CHAT_URL,
          state: { conversation },
        });

        break;
      case 'keep answering questions about myself':
        getIntent(flow, user)
          .then(({ data }) => {
            const { messages, intent, finishedFlows } = data;
            this.renderBotMsg(messages, this.state.inputType, intent);
            // if there are no flows then tell user and ask what else they want to do
            if (finishedFlows) {
              return this.setState({
                intent: this.state.intentFlow,
                aboutFollowUpOption: true,
                buttonLoading: false,
              });
            }
            return this.setState({
              intent,
              intentFlow: intent,
              buttonLoading: false,
              aboutFollowUpOption: false,
            });
          })
          .catch(err => console.log('submit msg err', err));
        break;

      default:
    }
  };

  render() {
    const {
      conversation,
      userInput,
      intentFlow,
      inputType,
      options,
      onboardingUserData: { name, hometown, birthday },
      aboutFollowUpOption,
      buttonLoading,
      loadingMsg,
      inputReady,
      endOnboardingFlow,
    } = this.state;

    const {
      activateInput,
      deactivateInput,
      inputActive,
      textRows,
    } = this.props;
    // loops over conversations and creates chatbubbles for human/bot
    const chat = conversation.map((msg, idx) => {
      // picks onboarding summary of user inputs and formats those inputs (mainly needed for birthdate)
      let onBoardingSummary;
      // instead of dialogflow response repeating all variables we form a custom response with formatted paramters
      if (msg.text === 'onboarding_summary_check') {
        onBoardingSummary = [
          <MessageWrapper
            key="summaryOnboardingA"
            type="bot"
            text={`All saved! You're ${name}, you were born on ${moment(
              birthday,
            ).format(
              'YYYY-MM-DD',
            )}, and you live in ${hometown}. If that doesn't sound right you can go to your profile and edit things any time.`}
          />,
          <OptionWrapper
            key="findOutMore"
            option={"Let's get started!"}
            onClick={() => window.location.reload()}
          />,
        ];

        return onBoardingSummary.map(e => e);
      }

      if (msg.text === 'finish_explanation') {
        // don't add trigger message to array
        conversation.pop();
        return ['keep chatting', 'go to profile'].map((option, index) =>
          buttonLoading ? (
            index === 0 ? (
              <h3 key={Date.now() / Math.random()}>loading...</h3>
            ) : null
          ) : (
            <OptionWrapper
              key={index}
              option={option}
              onClick={() => this.handleOptions(option)}
            />
          ),
        );
      }
      return (
        <MessageWrapper
          key={msg.text + msg.user + idx}
          type={msg.type}
          text={
            onBoardingSummary && onBoardingSummary.length > 0
              ? onBoardingSummary.map(e => e)
              : msg.text
          }
        />
      );
    });
    return (
      <div data-testid="df-wrapper">
        <ConversationView data-testid="conversation-view">
          {chat}
          {loadingMsg && <MessageWrapper type="bot" text="..." />}
          {aboutFollowUpOption &&
            [
              'do something else',
              intentFlow === 'Onboarding'
                ? 'go to profile'
                : 'keep answering questions about myself',
            ].map((option, index) =>
              buttonLoading ? (
                index === 0 ? (
                  <h3 key={Date.now() / Math.random()}>loading...</h3>
                ) : null
              ) : (
                <OptionWrapper
                  key={index}
                  option={option}
                  onClick={() => this.handleOptions(option)}
                />
              ),
            )}

          {inputType === 'button' &&
            inputReady &&
            options.map((option, index) => (
              <OptionWrapper
                key={index}
                option={option.content}
                onClick={() => {
                  this.handleOptionClick(option.content);
                }}
              />
            ))}
        </ConversationView>
        {inputType === dialogflowPayload.questionTypes.freeformText && (
          <ButtonWrapper>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.handleOptionClick('finish');
              }}
            >Finish</Button>
          </ButtonWrapper>
        )}
        {inputType === dialogflowPayload.questionTypes.textSkip && (
          <ButtonWrapper>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleEndQuestions}
            >I'd rather not say</Button>
          </ButtonWrapper>
        )}
        {inputType !== dialogflowPayload.questionTypes.button &&
          !aboutFollowUpOption &&
          !endOnboardingFlow && (
            <MessageBox data-testid="message-box">
              <Form onSubmit={this.handleSubmit}>
                <S.InputWrapper inputActive={inputActive}>
                  <S.InputChat
                    ref={el => {
                      this.inputField = el;
                    }}
                    placeholder="Type here..."
                    className="text-input"
                    autoFocus
                    data-testid="message-input"
                    rows={textRows}
                    onChange={this.handleChange}
                    onFocus={activateInput}
                    onBlur={deactivateInput}
                    inputActive={inputActive}
                    value={userInput}
                    onKeyDown={this.handleEnter}
                  />
                  <S.Button
                    onClick={() =>
                      inputType === dialogflowPayload.questionTypes.freeformText
                        ? this.handleFreeformText
                        : this.handleSubmitMessage
                    }
                    inputActive={inputActive}
                    ref={el => {
                      this.clickButton = el;
                    }}
                  >
                    <Icon
                      icon="send"
                      width="2rem"
                      margin="0"
                      color="var(--secondary-aqua)"
                    />
                  </S.Button>
                </S.InputWrapper>
              </Form>
            </MessageBox>
          )}
        {conversation && conversation.length > 4 && (
          <BottomDiv
            ref={el => {
              this.messagesEnd = el;
            }}
          />
        )}
      </div>
    );
  }
}
