/* eslint-disable camelcase */
import axios from 'axios';
import Swal from 'sweetalert2';

import { API_PAYMENTS_BASE_URL } from '../constants/apiRoutes';

import makeError from './makeError';

const create = async ({ stripe, card, email, name, options } = {}) => {
  try {
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        email,
        name,
      },
    });

    if (result.error) {
      if (result.error && result.error.type === 'invalid_request_error') {
        // change this
        throw new Error('please enter the name on the card');
      }
      throw result.error;
    } else {
      const {
        data: { subscription },
      } = await axios.post(API_PAYMENTS_BASE_URL, {
        paymentMethodId: result.paymentMethod.id,
      });
      if (subscription) {
        const { latest_invoice } = subscription;
        const { payment_intent } = latest_invoice;
        const { client_secret, status } = payment_intent;

        if (status === 'requires_action') {
          const confirmResult = await stripe.confirmCardPayment(client_secret);
          if (confirmResult.error) {
            return { error: makeError(confirmResult.error, options) };
          }
          return {
            error: null,
          };
        }
      }
    }

    return {
      error: null,
    };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const cancel = async ({ options } = {}) => {
  try {
    const {
      data: { subscriptionCancelAt },
    } = await axios.delete(API_PAYMENTS_BASE_URL);

    Swal.fire({
      title: 'Your subscription has been deleted successfully',
      type: 'success',
    });

    return {
      data: { subscriptionCancelAt },
      error: null,
    };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const payments = {
  create,
  cancel,
};

export default payments;
