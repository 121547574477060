import React, { useState, useEffect } from 'react';

// Components
import {
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Fade,
  Collapse,
  Zoom,
  Grid,
  Modal,
  Select,
  InputLabel,
  Switch,
} from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker as MaDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Swal from 'sweetalert2';
import CancelActionButton from '../../../Common/GroupComponents/Buttons/CancelActionButton';
import CallToActionButton from '../../../Common/GroupComponents/Buttons/CallToActionButton';
import Voice from '../../../Common/Inputs/Voice';
import Icon from '../../../Common/Icon/Icon';

export default function CreateMemoryPopup({
  editingMemory={},
  editMemoryFunc=()=>{},
  deleteMemoryFunc=()=>{},
  timelines=[],
  customTheme,
  onCloseFunction=()=>{},
}) {
  const [memoryId, setMemoryId] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState(new Date);
  const [memoryType, setMemoryType] = useState(false);
  const [title, setTitle] = useState('');

  const onDateChange = calDate => {
    setDate(calDate);
  };

  useEffect(() => {
    setMemoryId(editingMemory?._id);
    setTitle(editingMemory?.title);
    setMemoryType(editingMemory?.memoryType === 0 ? false : true);
    setDescription(editingMemory?.description);
    setDate(editingMemory?.date)
  }, [])
  return (
    <Paper
      id="create-memory-popup"
      elevation={1}
      style={{
        position: 'absolute',
        textAlign: 'left',
        width: '60%',
        backgroundColor: '',
        color: '',
        padding: '3rem',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90%',
        maxWidth: '85vw',
        borderRadius: '2rem',
        overflowY: 'scroll',
      }}
    >
      {/* Name and Email on same line */}
      {/* Date and Title on same line  */}
      {/* Written Memory - Not Logged In */}
      {/* Written Memory - Logged In */}
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button
          variant="text"
          color="primary"
          onClick={() => onCloseFunction()}
          style={{
            postion: 'absolute',
    top: '-0.5rem',
    right: '-1rem'
          }}
        >
          <Icon icon="close" />
        </Button>
      </Grid>
      
      <Grid container spacing={2} id="written-memory-fields">
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold', marginBottom: '1rem' }}
          >
            <b>Edit Memory</b>
          </Typography>
          <Typography variant="body1">
            As admin you are able to make any edits to any of the stories that have been posted and change their location to the correct timeline. 
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography style={{ paddingTop: '' }}>Title Your Story</Typography>
          <TextField
            id="story-title"
            value={title}
            onChange={text => setTitle(text.target.value)}
            variant="outlined"
            placeholder="Name Your Story"
            // label="Story Title"
            fullWidth
            style={{ marginBottom: '1rem' }}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography style={{ paddingTop: '' }}>Add a Date</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MaDatePicker
              openTo="year"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              placeholder="Select a date"
              fullWidth
              onChange={onDateChange}
              maxDate={new Date().toISOString().split('T')[0]}
              value={date || new Date()}
              style={{ marginBottom: '1rem' }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        {description.length > 0 && (
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="center"
            style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
          >
            <Grid item xs={12}>
              <Typography style={{ paddingTop: '' }}>
                Tell Your Story
              </Typography>
              <TextField
                id="user-story"
                value={description}
                onChange={text => setDescription(text.target.value)}
                variant="outlined"
                placeholder="Write your story here"
                // label="Your Story"
                fullWidth
                multiline
                rows={4}
                style={{ marginBottom: '1rem' }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      
      <Grid container spacing={1} alignItems="center" justify="center">
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bolder',
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {' '}
            Story Location{' '}
          </Typography>
        </Grid>

        <Grid item>{timelines[0]?.name ? timelines[0]?.name : 'History Timeline'}</Grid>

        <Switch
          value={memoryType}
          checked={memoryType}
          onChange={() => setMemoryType(prev => !prev)}
          color="primary"
          // style={{ color: '#10c1b8' }}
          style={{ color: customTheme.palette.primary.main }}
        />

        <Grid item>{timelines[1]?.name ? timelines[1]?.name : 'Our Stories'}</Grid>

        <Grid container spacing={1} alignItems="center" justify="center">
          {/* <Grid item>
            <CancelActionButton title="Clear Memory" />
          </Grid> */}
          <Grid item>
            <CallToActionButton
              title="Update Story"
              customColor={customTheme.palette.primary.main}
              customFontColor={customTheme.palette.primary.contrastText}
              clickFunction={async () => {
                await editMemoryFunc({
                  memory: {
                    title,
                    description,
                    date,
                    memoryType
                  },
                  memoryId
                })
              }}
            />
          </Grid>
          <Grid item>
            <CallToActionButton
            variant='outlined'
              title="Delete Story"
              customColor={'var(--secondary-cherry)'}
              customFontColor={'#fff'}
              clickFunction={async () => {
                await deleteMemoryFunc(memoryId)
              }}
            />
          </Grid>
        
        </Grid>
      </Grid>
    </Paper>
  );
}
