import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import * as logRocket from './helpers/logRocket';

import App from './App';
import ErrorBoundary from './Components/Common/ErrorBoundary';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
  logRocket.init();
}

const UnsecuredPage = () => (
  <div style={{ color: 'white' }}>
    <h1>
      If you see this page, the Iternal link you have clicked on is under
      Clickjacking security attack.
    </h1>
    <h2>
      Please inform team with the reference of the application from where you
      clicked this link.
    </h2>
    <h2>
      Click{' '}
      <a
        href={window.self.location.href}
        title="Web Application"
        target="blank"
      >
        here
      </a>{' '}
      to access Iternal safely.
    </h2>
  </div>
);

if (window.self === window.top) {
  ReactDOM.render(
    process.env.NODE_ENV === 'production' ? (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    ) : (
      <App />
    ),
    document.getElementById('root'),
  );
} else {
  ReactDOM.render(<UnsecuredPage />, document.getElementById('root'));
}
