/* eslint-disable */
// React
import React, { useState, useEffect } from "react";
import Icon from "../../Common/Icon/Icon";
import {
  formatJSDate,
  formatCleanJSDate,
  formatCleanJSYear,
} from "../../../helpers";

import { NavLink } from "react-router-dom";

import {
  CREATE_MEMORY_URL,
  DASHBOARD_URL,
} from "../../../constants/constantUrls";
// API
import memories from "../../../api/memories";
import comments from "../../../api/comments";
import reactions from "../../../api/reactions";
import users from "../../../api/users";
import { permissionAccess } from "../../../api";

import { colors } from "../../../styles/theme";
import ReactPlayer from "react-player";
import imagePlaceHolder from "../../../assets/person-placeholder.jpg";

import { Divider } from "../../../NewComponents/Divider/Divider";
import { API_VIEWED_MEMORY_URL } from "../../../constants/apiRoutes";
// Styles
import "react-image-carousel/lib/css/main.min.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import {
  PageWrapper,
  HeadingWrapper,
  CommentHeadingWrapper,
  Heading,
  DateLineWrapper,
  Date,
  MemoryDetailsWrapper,
  Description,
  VoiceRecording,
  MemoryDescription,
  MemoryGalleryWrapper,
  SubHeading,
  Gallery,
  PhotoContainer,
  AudioPlayer,
  CardWrapper,
  BackWrapper,
  MemoryAdditionWrapper,
  TextareaWrapper,
} from "./style";

import Footer from "../../Common/Footer";
import Swal from "sweetalert2";

// MUI Component
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Modal from "@material-ui/core/Modal";
import {
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

import { Typography } from "@material-ui/core";
import ImageGallery from "react-image-gallery";

// Components
import { ReactionsSection } from "../../../NewComponents/Pages/ViewMemory/ReactionsSection/ReactionsSection";

import { Carousel } from "../../../NewComponents/Pages/ViewMemory/Carousel/Carousel";
import axios from "axios";
import ReactGA from "react-ga";

const ViewAndPreviewMemory = ({
  viewMode = "view",
  location,
  uploadObject = {},
  memoryId,
  setMemoryId,
  editExisting,
  setEditExisting,
  data,
  history,
  props,
}) => {
  // viewMode: viewing own memory, preview: preview own memory,
  const [viewingFriendsMemory, setViewingFriendsMemory] = useState(null);
  const [memory, setMemory] = useState(null);
  const [memoryComments, setMemoryComments] = useState([]);
  const [newComment, setNewComment] = useState();
  const [userId, setUserId] = useState("");
  const [canComment, setCanComment] = useState(false);
  const [memoryReactions, setMemoryReactions] = useState([]);
  const [profile, setProfile] = useState({});
  const [showReactionsPopup, setShowReactionsPopup] = useState(false);
  const [popupValue, setPopupValue] = useState(0);

  // upload variables coming from parent (Components/index)
  let { imagesArray: images = [], voice = "", video = "" } = uploadObject;
  const {
    tempImages,
    setTempImages,
    tempVoice,
    setTempVoice,
    tempVideo,
    setTempVideo,
  } = uploadObject;

  const generateUserInitials = (userStr) =>
    userStr
      .split(" ")
      .reduce((acc, cur) => acc + cur[0], "")
      .toUpperCase()
      .substring(0, 3);
  // when viewing an existing memory, fetches it from the database
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    async function fetchData() {
      try {
        // check if viewing a friend's memory
        if (location.state && location.state.viewingFriendsMemory) {
          setViewingFriendsMemory(location.state.viewingFriendsMemory);
        }
        if (location?.state?.profile && location?.state?.profile) {
          setProfile(location.state.profile);
        }
        // view own memory -> sets state
        if (viewMode === "view" && location.state && location.state.details) {
          const memId = location.state.details._id;
          setMemoryId(memId);

          // gets meory details including firebase storage links for viewing / previewing
          const storedMemory = await memories.getMemoryById({ id: memId });
          if (storedMemory) {
            const {
              title,
              description,
              date,
              dateType,
              dateAge,
              images,
              voice,
              video,
              _id,
              user: userId,
              isDraft,
              createdAt,
              updatedAt,
              tempVoice,
              tempImages,
              tempVideo,
              isPublic,
              taggedEmails,
              privacySetting,
              author,
              promptId,
            } = storedMemory.data;
            // store memory details
            setMemory({
              title,
              description,
              date,
              dateType,
              dateAge,
              images,
              voice,
              video,
              _id,
              userId,
              isDraft,
              isPublic,
              taggedEmails,
              createdAt,
              updatedAt,
              privacySetting,
              author,
              promptId,
            });
            setTempImages(tempImages);
            setTempVoice(tempVoice);
            setTempVideo(tempVideo);

            const {
              data: commentData,
              error,
            } = await comments.getMemoryComments({ memory: _id });
            if (commentData) {
              setMemoryComments(commentData);
            }
            if (error) {
              console.log(error);
            }
            const { data: userData } = await users.getUserInfoFromToken({});
            setUserId(userData.id);
            if (userData?.id.toString() !== userId.toString()) {
              axios.post(API_VIEWED_MEMORY_URL, {
                memoryId: memId,
                userId: userData?.id,
              });
            }
          }
        }
        // viewing individual memories
        if (viewMode === "viewOther" && data) {
          if (data.loggedIn) {
            const isLoggedIn = await users.isLoggedIn();
            if (isLoggedIn) {
              setUserId(data.props.id);
              const {
                data: permissionData,
                error,
              } = await permissionAccess.getAllTakenPermissionAccess();
              if (error) {
                console.log(error);
              }
              if (data.props.id.toString() !== data.userId.toString()) {
                axios.post(API_VIEWED_MEMORY_URL, {
                  memoryId: data?._id,
                  userId: data?.props?.id,
                });
              }
              permissionData.map((permission) => {
                if (permission.owner._id === data.userId) {
                  setCanComment(true);
                }
                if (permission.sharedWithId === data.userId) {
                  setCanComment(true);
                }
                return permission;
              });
            }
          }
          setMemory(data);
          const {
            data: commentData,
            error,
          } = await comments.getMemoryComments({ memory: data._id });
          if (error) {
            console.log(error);
          }
          if (commentData) {
            setMemoryComments(commentData);
          }
        }
      } catch (error) {
        throw new Error("Error loading memory");
      }
    }
    fetchData();
  }, [
    viewMode,
    location.state,
    setMemoryId,
    setMemory,
    setMemoryComments,
    setTempImages,
    setTempVoice,
    setTempVideo,
    data,
  ]);

  // Get reactions
  async function getReactions() {
    let memoryId;
    if (viewMode === "view" && location.state && location.state.details) {
      memoryId = location.state.details._id;
    } else if (viewMode === "viewOther" && data) {
      memoryId = data._id;
    } else {
      return;
    }
    const { data: reactionData } = await reactions.getReactionsByMemoryId({
      memoryId,
    });
    setMemoryReactions(reactionData);
  }

  useEffect(() => {
    getReactions();
  }, [setMemoryReactions]);

  // initialises variables with placeholder data
  let title = "Your memory is loading";
  let tempDate = "01-01-2020";
  let date = "01-01-2020";
  let description = "Please wait for your memory to load";
  let isPublic = false;
  let taggedEmails = [];
  let dateType = "";
  let dateAge = 0;
  let privacySetting = "Friends & Family";
  let author = {};
  let _id = "";
  let promptId = "";

  // changes variables according to viewMode
  // view existing memory
  if (viewMode === "view" && memory) {
    ({
      title,
      date,
      dateType,
      dateAge,
      description,
      images,
      voice,
      video,
      isPublic,
      taggedEmails,
      privacySetting,
      author,
      _id,
      promptId,
    } = memory);
    date = formatJSDate(date);
    if (dateType === "age") tempDate = formatCleanJSYear(date);
    else tempDate = formatCleanJSDate(date);
  }

  // preview memory
  else if (viewMode === "preview" && location.state) {
    ({
      title,
      date,
      dateType,
      dateAge,
      description,
      images,
      isPublic,
      taggedEmails,
      privacySetting,
      author,
      promptId,
    } = location.state);
    date = formatJSDate(date);
    if (dateType === "age") tempDate = formatCleanJSYear(date);
    else tempDate = formatCleanJSDate(date);
    // get temp storage links
    setTempImages(location.state.tempImages);
    setTempVoice(location.state.tempVoice);
    setTempVideo(location.state.tempVideo);
  }
  // view individual memories of others
  else if (viewMode === "viewOther" && memory) {
    ({
      title,
      date,
      dateType,
      dateAge,
      memory: description,
      images,
      voiceRecording: voice,
      videoRecording: video,
      isPublic,
      taggedEmails,
      privacySetting,
      author,
      _id,
      promptId,
    } = memory);
    date = formatJSDate(date);
    if (dateType === "age") tempDate = formatCleanJSYear(date);
    else tempDate = formatCleanJSDate(date);
  }

  // renders gallery
  const renderGallery = (data) => {
    return (
      <PhotoContainer>
        <Carousel
          tempImages={
            data.tempImages && data.tempImages.length
              ? data.tempImages
              : data.images
          }
          video={data.video}
          tempVideo={data.tempVideo}
          voice={data.voice}
          tempVoice={data.tempVoice}
        />
      </PhotoContainer>
    );
  };

  const reactionButtonFunction = (buttonText, memoryId) => {
    const reactionId = memoryReactions.filter(
      (el) => el.userId === userId
    )?.[0]?.["_id"];
    return memoryReactions.some(
      (el) => el.userId == userId && el.reactionType === buttonText
    )
      ? reactions.deleteReaction({ reactionId })
      : memoryReactions.some((el) => el.userId == userId)
      ? reactions.updateReaction({
          reaction: {
            reactionType: buttonText,
            memoryId,
            userId,
            memoryId,
            reactionId,
          },
        })
      : reactions.createReaction({
          reaction: { reactionType: buttonText, memoryId },
        });
  };

  const onCommentChange = (e) => {
    const { value: newValue } = e.target;
    setNewComment(newValue);
  };

  const addComment = async () => {
    ReactGA.event({
      category: "Memory",
      action: "Added Comment",
    });
    if (userId === memory.userId) {
      await comments.createComment({
        comment: {
          text: newComment,
          memoryId: memory._id,
          approval: "approved",
        },
      });
    } else {
      await comments.createComment({
        comment: {
          text: newComment,
          memoryId: memory._id,
          approval: "pending",
        },
      });
    }
    const { data } = await comments.getMemoryComments({ memory: memory._id });
    setMemoryComments(data);
    setNewComment("");
  };

  const approveComment = async (comment) => {
    const { error } = await comments.updateComment({
      comment: {
        image: comment.image,
        text: comment.text,
        memoryId: comment.memoryId,
        commentId: comment.commentId,
        userId: comment.userId,
        approval: "approved",
      },
    });
    if (!error) {
      ReactGA.event({
        category: "Memory",
        action: "Approved Comment",
      });
      Swal.fire({
        title: "Comment approved!",
        type: "success",
        timer: 3000,
      });
      const { data } = await comments.getMemoryComments({
        memory: comment.memoryId,
      });
      setMemoryComments(data);
    }
  };

  const declineComment = async (comment) => {
    const { error } = await comments.updateComment({
      comment: {
        text: comment.text,
        memoryId: comment.memoryId,
        commentId: comment.commentId,
        userId: comment.userId,
        approval: "declined",
      },
    });
    if (!error) {
      ReactGA.event({
        category: "Memory",
        action: "Declined Comment",
      });
      Swal.fire({
        title: "Comment declined!",
        type: "success",
        timer: 3000,
      });
      const { data } = await comments.getMemoryComments({
        memory: comment.memoryId,
      });
      setMemoryComments(data);
    }
  };

  const deleteComment = async (comment) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#66CC66",
      cancelButtonColor: "#8a8787",
      confirmButtonText: "Yes, delete",
    }).then(async (result) => {
      if (result.value) {
        const { error } = await comments.updateComment({
          comment: {
            text: comment.text,
            memoryId: comment.memoryId,
            commentId: comment.commentId,
            userId: comment.userId,
            approval: "declined",
          },
        });
        if (!error) {
          ReactGA.event({
            category: "Memory",
            action: "Delete Comment",
          });
          Swal.fire({
            title: "Comment deleted!",
            type: "success",
            timer: 3000,
          });
          const { data } = await comments.getMemoryComments({
            memory: comment.memoryId,
          });
          setMemoryComments(data);
        }
      }
    });
  };

  const getReactionLength = (reactionType) => {
    return memoryReactions.filter((el) => el.reactionType === reactionType)
      .length;
  };

  const getReactionTypes = (reactionType) => {
    return memoryReactions.filter((el) => el.reactionType === reactionType);
  };

  const getListItem = (list) => {
    if (list.length === 0) {
      return (
        <ListItem>
          <ListItemText primary="No one's reacted yet. Try sending this memory to some people" />
        </ListItem>
      );
    } else {
      return list.map((item) => (
        <ListItem>
          <ListItemAvatar>
            <Avatar
              src={item?.about[0]?.image?.imagePath}
              alt="Your profile avatar"
            >
              {item?.user[0]?.name
                ? generateUserInitials(item.user[0].name)
                : "U"}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={item.user[0].name}
            secondary={item.reactionType}
          />
        </ListItem>
      ));
    }
  };

  const TabPanel = (props) => {
    const { children, value, index, list, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <List style={{ width: "100%" }}>{getListItem(list)}</List>
        )}
      </div>
    );
  };

  const allData = {
    title,
    date,
    dateType,
    dateAge,
    description,
    voice,
    video,
    images,
    tempImages,
    tempVoice,
    tempVideo,
    isPublic,
    taggedEmails,
    privacySetting,
    author,
    _id,
    promptId,
  };

  return (
    <PageWrapper>
      <Modal
        open={showReactionsPopup}
        onClose={() => setShowReactionsPopup(false)}
      >
        <div style={{ position: "relative", height: "100%" }}>
          <Paper
            style={{
              maxWidth: "800px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "1rem",
              maxHeight: "90%",
              width: "100%",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={10} sm={10} md={11}>
                <Tabs
                  value={popupValue}
                  onChange={(event, newVal) => setPopupValue(newVal)}
                  variant="scrollable"
                  scrollButtons={true}
                  allowScrollButtonsMobile
                >
                  <Tab label={`All ${memoryReactions.length}`} />
                  <Tab label={`Happy ${getReactionLength("Happy")}`} />
                  <Tab label={`Nostalgic ${getReactionLength("Nostalgic")}`} />
                  <Tab
                    label={`Heartwarming ${getReactionLength("Heartwarming")}`}
                  />
                  <Tab
                    label={`Virtual Hug ${getReactionLength("Virtual Hug")}`}
                  />
                  <Tab
                    label={`Supportive ${getReactionLength("Supportive")}`}
                  />
                  <Tab label={`Moving ${getReactionLength("Moving")}`} />
                </Tabs>
              </Grid>
              <Grid item xs={2} sm={2} md={1} style={{ textAlign: "right" }}>
                <Button onClick={() => setShowReactionsPopup(false)}>X</Button>
              </Grid>
            </Grid>
            <TabPanel value={popupValue} index={0} list={memoryReactions}>
              Item One
            </TabPanel>
            <TabPanel
              value={popupValue}
              index={1}
              list={getReactionTypes("Happy")}
            >
              Item Two
            </TabPanel>
            <TabPanel
              value={popupValue}
              index={2}
              list={getReactionTypes("Nostalgic")}
            >
              Item Three
            </TabPanel>
            <TabPanel
              value={popupValue}
              index={3}
              list={getReactionTypes("Heartwarming")}
            >
              Item Four
            </TabPanel>
            <TabPanel
              value={popupValue}
              index={4}
              list={getReactionTypes("Virtual Hug")}
            >
              Item Five
            </TabPanel>
            <TabPanel
              value={popupValue}
              index={5}
              list={getReactionTypes("Supportive")}
            >
              Item Six
            </TabPanel>
            <TabPanel
              value={popupValue}
              index={6}
              list={getReactionTypes("Moving")}
            >
              Item Seven
            </TabPanel>
          </Paper>
        </div>
      </Modal>
      {/* <BackWrapper>
        {(viewMode === 'viewOther' || viewMode === 'view') && history && (
          <BackWrapper onClick={() => history.goBack()} style={{ marginLeft: window.innerWidth < 1024 ? "0rem" : '6rem' }}>
            <Icon icon="back" height="3.5em" color={colors.secondary} />
          </BackWrapper>
        )}
      </BackWrapper> */}
      {/* Viewing your own memory */}
      <Paper
        elevation={3}
        style={{
          marginBottom: "1rem",
          marginLeft: window.innerWidth < 1024 ? "0rem" : "3rem",
        }}
      >
        <HeadingWrapper>
          <Typography
            variant="h4"
            color="primary"
            style={{ marginLeft: "-1rem", marginTop: "-1rem", fontSize: window.innerWidth < 600 ? "1.5rem" :"2rem" }}
          >
            {title} 
          </Typography>
        </HeadingWrapper>
        <MemoryDetailsWrapper>
          <Grid
            container
            spacing={3}
            style={{ display: "flex", justifyContent: "center", marginTop: window.innerWidth < 128 ? "0rem" :"2rem" }}
          >
            {(images.length > 0 ||
              video?.length > 0 ||
              voice?.length > 0 ||
              description?.length > 0) && (
              <Grid item sm={12} lg={7} xl={6} style={{ width: "100%" }}>
                <MemoryGalleryWrapper>
                  <Gallery>
                    <div className="columns is-multiline">
                      {renderGallery(allData)}
                    </div>
                  </Gallery>
                </MemoryGalleryWrapper>
                <Grid>
                  <Grid style={{ display: "flex", marginTop: "1rem" }}>
                    <Grid xs={6}>
                      {profile && profile?.name && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            src={profile?.picture}
                            alt="Your profile avatar"
                          >
                            {profile?.name
                              ? generateUserInitials(profile?.name)
                              : "U"}
                          </Avatar>
                          &nbsp;&nbsp;
                          <Typography style={{ fontWeight: "500" }}>
                            {profile?.name}
                          </Typography>
                        </div>
                      )}
                    </Grid>
                    <Grid
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignContent: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <Date>{tempDate}</Date>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12}>
                  <Description>
                    {description && (
                      <MemoryDescription>
                        <p>{description}</p>
                      </MemoryDescription>
                    )}
                  </Description>
                </Grid>
                {author && author?.name && (
                  <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      Story written by:  {author?.name}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            <Grid sm={12} lg={5} xl={6} style={{display:'flex', flexDirection: window.innerWidth > 1279 ? "column" : "column-reverse",
}}>
              <Grid xs={12} style={{marginTop: window.innerWidth < 1280 ? "0rem" :'-4rem'}}>
                <ReactionsSection
                  memoryReactions={memoryReactions}
                  userId={userId}
                  memoryId={allData._id}
                  reactionButtonFunction={reactionButtonFunction}
                  getReactions={getReactions}
                  viewingFriendsMemory={viewMode !== "viewOther"}
                  showPopupFunc={setShowReactionsPopup}
                />
              </Grid>
              <Grid item xs={12} style={{display:'flex', justifyContent:'center', marginBottom:'2rem'}}>
          <NavLink
            to={{
              pathname: DASHBOARD_URL,
            }}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              color="secondary"
              style={{
                marginTop: "1rem",
                borderRadius: "100px",
                minWidth: "164px",
                minHeight: "50px",
                fontWeight: "700",
                boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
              }}
            >
             Back To Your Stories
            </Button>
          </NavLink>
        </Grid>
            </Grid>
          </Grid>
        </MemoryDetailsWrapper>
        {!viewingFriendsMemory && (
          <Footer
            viewMode={viewMode}
            allData={allData}
            memoryId={memoryId}
            editExisting={editExisting}
            setEditExisting={setEditExisting}
          />
        )}
      </Paper>
      {!viewingFriendsMemory && viewMode === "view" ? (
        <Paper
          elevation={3}
          style={{
            padding: "2rem",
            marginBottom: "4rem",
            marginLeft: window.innerWidth < 1024 ? "0rem" : "3rem",
          }}
        >
          <Typography
            variant="h5"
            color="primary"
            gutterBottom
            style={{
              textAlign: "left",
              marginBottom: "2rem",
              marginTop: "-1rem",
            }}
          >
          Story Additions
          </Typography>
          <Grid container spacing={3} style={{display:'flex'}}>
            <Grid xs={12} lg={7}>
            <MemoryAdditionWrapper>
            {memoryComments &&
              memoryComments.length > 0 &&
              memoryComments.map((comment) => {
                return (
                  <div
                    className={
                      comment.approval === "declined"
                        ? ""
                        : "memoryAdditionItem"
                    }
                    key={comment.commentId}
                    style={{ textAlign: "left" }}
                  >
                    {comment.approval === "pending" ? (
                      <Grid container spacing={0}>
                        <Grid item xs={2} sm={2} md={1}>
                          {/* eslint-disable-next-line jsx-a11y/alt-text */}
                          <Avatar
                            src={comment?.profileData[0]?.image?.imagePath}
                            alt="Your profile avatar"
                          >
                            {profile?.name
                              ? generateUserInitials(
                                  comment.profileData[0].name
                                )
                              : "U"}
                          </Avatar>
                        </Grid>
                        <Grid item xs={10} sm={10} md={11}>
                          <Typography>
                            {comment.profileData[0].name}{" "}
                            <span
                              style={{
                                color: "var(--neutral-slate)",
                                fontSize: "0.8rem",
                              }}
                            >
                              {formatCleanJSDate(comment.createdAt)}
                            </span>
                          </Typography>
                          <Typography style={{ fontSize: "0.9rem" }}>
                            {comment.text}
                          </Typography>
                        </Grid>
                        <div className="pendingButtonGroup">
                          <Button
                            variant="contained"
                            onClick={() => approveComment(comment)}
                            style={{
                              backgroundColor: "var(--primary-navy)",
                              color: "white",
                            }}
                          >
                            Approve comment
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => declineComment(comment)}
                            style={{
                              backgroundColor: "var(--secondary-cherry)",
                              color: "white",
                            }}
                          >
                            Decline comment
                          </Button>
                        </div>
                      </Grid>
                    ) : (
                      comment.approval === "approved" && (
                        <Grid container spacing={0}>
                          {/* eslint-disable-next-line jsx-a11y/alt-text */}
                          <Grid item xs={2} sm={2} md={1}>
                            <Avatar
                              src={comment?.profileData[0]?.image?.imagePath}
                              alt="Your profile avatar"
                            >
                              {profile?.name
                                ? generateUserInitials(
                                    comment.profileData[0].name
                                  )
                                : "U"}
                            </Avatar>
                          </Grid>
                          <Grid item xs={8} sm={8} md={10}>
                            <Typography>
                              {comment.profileData[0].name}{" "}
                              <span
                                style={{
                                  color: "var(--neutral-slate)",
                                  fontSize: "0.8rem",
                                }}
                              >
                                {formatCleanJSDate(comment.createdAt)}
                              </span>
                            </Typography>
                            <Typography style={{ fontSize: "0.9rem" }}>
                              {comment.text}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sm={2} md={1}>
                            <Button
                              onClick={() => deleteComment(comment)}
                              style={{ width: "100%", fontSize: "0.8rem" }}
                            >
                              <DeleteIcon size="small" />
                            </Button>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </div>
                );
              })}
          </MemoryAdditionWrapper>
            </Grid>
            <Grid xs={12} lg={5}>
            <TextField
            variant="outlined"
            type="text"
            label="Add to this story"
            placeholder="Add to the story..."
            value={newComment}
            onChange={onCommentChange}
            style={{ width: "100%",  }}
          />
          <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={addComment}
              style={{
                borderRadius: "100px",
                fontWeight: "700",
                height: "50px",
                boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                minWidth: "164px",
                marginTop: "1rem",
                marginBottom:'1rem'
              }}
            >
              <AddIcon style={{ fontSize: "1.5rem" }} />
              Add Comment
            </Button>
          </Grid>

            </Grid>
          </Grid>
    
       
          <Divider logo />
      
        </Paper>
      ) : /* Viewing Friends Memory */
      viewingFriendsMemory ? (
        <>
          <Paper
            elevation={3}
            style={{
              padding: "2rem",
              marginBottom: "5rem",
              marginLeft: window.innerWidth < 1024 ? "0rem" : "3rem",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              color="primary"
              style={{ textAlign: "left", marginBottom: "1rem" }}
            >
              Add to this Story
            </Typography>
            <Grid container spacing={3} style={{display:'flex'}}>
            <Grid xs={12} lg={7}>
            <MemoryAdditionWrapper>
              {memoryComments &&
                memoryComments.length > 0 &&
                memoryComments.map((comment) => {
                  return (
                    <div
                      className={
                        comment.approval === "declined"
                          ? ""
                          : "memoryAdditionItem"
                      }
                      key={comment.commentId}
                      style={{ textAlign: "left", marginTop: "1rem" }}
                    >
                      {comment.approval === "approved" ? (
                        <Grid container spacing={0}>
                          {/* eslint-disable-next-line jsx-a11y/alt-text */}
                          <Grid item xs={2} sm={2} md={1}>
                            <Avatar
                              src={comment?.profileData[0]?.image?.imagePath}
                              alt="Your profile avatar"
                            >
                              {profile?.name
                                ? generateUserInitials(
                                    comment.profileData[0].name
                                  )
                                : "U"}
                            </Avatar>
                          </Grid>
                          <Grid item xs={8} sm={8} md={10}>
                            <Typography>
                              {comment.profileData[0].name}{" "}
                              <span
                                style={{
                                  color: "var(--neutral-slate)",
                                  fontSize: "0.8rem",
                                }}
                              >
                                {formatCleanJSDate(comment.createdAt)}
                              </span>
                            </Typography>
                            <Typography style={{ fontSize: "0.9rem" }}>
                              {comment.text}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sm={2} md={1}>
                            {comment.userId === userId && (
                              <Button
                                onClick={() => deleteComment(comment)}
                                style={{ width: "100%", fontSize: "0.8rem" }}
                              >
                                <DeleteIcon size="small" />
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      ) : (
                        comment.approval === "pending" &&
                        comment.userId === userId && (
                          <Grid container spacing={0}>
                            {/* eslint-disable-next-line jsx-a11y/alt-text */}
                            <Grid item xs={2} sm={2} md={1}>
                              <Avatar
                                src={comment?.profileData[0]?.image?.imagePath}
                                alt="Your profile avatar"
                              >
                                {profile?.name
                                  ? generateUserInitials(
                                      comment.profileData[0].name
                                    )
                                  : "U"}
                              </Avatar>
                            </Grid>
                            <Grid item xs={8} sm={8} md={10}>
                              <Typography>
                                {comment.profileData[0].name}{" "}
                                <span
                                  style={{
                                    color: "var(--neutral-slate)",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  {formatCleanJSDate(comment.createdAt)}{" "}
                                  (Pending Approval)
                                </span>
                              </Typography>
                              <Typography style={{ fontSize: "0.9rem" }}>
                                {comment.text}
                              </Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} md={1}>
                              <Button
                                onClick={() => deleteComment(comment)}
                                style={{ width: "100%", fontSize: "0.8rem" }}
                              >
                                <DeleteIcon size="small" />
                              </Button>
                            </Grid>
                          </Grid>
                        )
                      )}
                    </div>
                  );
                })}
            </MemoryAdditionWrapper>
            </Grid>
        
            <Grid xs={12} lg={5}>
            <TextField
              variant="outlined"
              type="text"
              label="Add to this story"
              placeholder="Add to the story..."
              value={newComment}
              onChange={onCommentChange}
              style={{ width: "100%" }}
            />
            <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={addComment}
                style={{
                  borderRadius: "100px",
                  fontWeight: "700",
                  height: "50px",
                  boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                  marginTop: "1rem",
                }}
              >
                <AddIcon style={{ fontSize: "1.5rem" }} />
                Add to story
              </Button>
            </Grid>

            </Grid>
            </Grid>
            
       
          </Paper>
        </>
      ) : (
        <Paper
          elevation={3}
          style={{
            padding: "2rem",
            marginLeft: window.innerWidth < 1024 ? "0rem" : "3rem",
            maxWidth: "100%",
            minHeight: window.innerWidth < 1300 ? "10vh" :'25vh',
            textAlign: "center",
          }}
        >
          {canComment ? (
            <>
              <Typography
                variant="h5"
                gutterBottom
                style={{ textAlign: "left", marginBottom: "1rem" }}
              >
                Add To This Story{" "}
              </Typography>
              <TextField
                variant="outlined"
                type="text"
                label="Add more to this story... "
                placeholder="Add more to this story..."
                value={newComment}
                onChange={onCommentChange}
                style={{ width: "100%" }}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={addComment}
                style={{
                  borderRadius: "100px",
                  fontWeight: "700",
                  height: "50px",
                  boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                  marginTop: "2rem",
                }}
              >
                              <AddIcon style={{ fontSize: "1.5rem" }} />

              Add to Story
              </Button>
            </>
          ) : (
         
            <Typography color="primary" variant="h5" gutterBottom style={{ textAlign: "left" }}>
              Comments
            </Typography>
          )}
  
          <MemoryAdditionWrapper>
            {memoryComments &&
              memoryComments.length > 0 &&
              memoryComments.map((comment) => {
                return (
                  <div
                    className={
                      comment.approval === "declined"
                        ? ""
                        : "memoryAdditionItem"
                    }
                    key={comment.commentId}
                    style={{ textAlign: "left" }}
                  >
                    {comment.approval === "approved" ? (
                      <Grid container spacing={0}>
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        <Grid item xs={2} sm={2} md={1}>
                          <Avatar
                            src={comment?.profileData[0]?.image?.imagePath}
                            alt="Your profile avatar"
                          >
                            {profile?.name
                              ? generateUserInitials(
                                  comment.profileData[0].name
                                )
                              : "U"}
                          </Avatar>
                        </Grid>
                        <Grid item xs={8} sm={8} md={10}>
                          <Typography>
                            {comment.profileData[0].name}{" "}
                            <span
                              style={{
                                color: "var(--neutral-slate)",
                                fontSize: "0.8rem",
                              }}
                            >
                              {formatCleanJSDate(comment.createdAt)}
                            </span>
                          </Typography>
                          <Typography style={{ fontSize: "0.9rem" }}>
                            {comment.text}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={1}>
                          {comment.userId === userId ? (
                            <Button
                              onClick={() => deleteComment(comment)}
                              style={{ width: "100%", fontSize: "0.8rem" }}
                            >
                              <DeleteIcon size="small" />
                            </Button>
                          ) : (
                            userId === memory.userId && (
                              <Button
                                onClick={() => deleteComment(comment)}
                                style={{ width: "100%", fontSize: "0.8rem" }}
                              >
                                <DeleteIcon size="small" />
                              </Button>
                            )
                          )}
                        </Grid>
                      </Grid>
                    ) : comment.approval === "pending" &&
                      comment.userId === memory.props.id ? (
                      <Grid container spacing={0}>
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        <Grid item xs={2} sm={2} md={1}>
                          <Avatar
                            src={comment?.profileData[0]?.image?.imagePath}
                            alt="Your profile avatar"
                          >
                            {profile?.name
                              ? generateUserInitials(
                                  comment.profileData[0].name
                                )
                              : "U"}
                          </Avatar>
                        </Grid>
                        <Grid item xs={8} sm={8} md={10}>
                          <Typography>
                            {comment.profileData[0].name}{" "}
                            <span
                              style={{
                                color: "var(--neutral-slate)",
                                fontSize: "0.8rem",
                              }}
                            >
                              {formatCleanJSDate(comment.createdAt)}
                            </span>
                          </Typography>
                          <Typography style={{ fontSize: "0.9rem" }}>
                            {comment.text}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={1}>
                          <Button
                            onClick={() => deleteComment(comment)}
                            style={{ width: "100%", fontSize: "0.8rem" }}
                          >
                            <DeleteIcon size="small" />
                          </Button>
                        </Grid>
                      </Grid>
                    ) : comment.approval === "pending" &&
                      userId === memory.userId ? (
                      <Grid container spacing={0}>
                        <Grid item xs={2} sm={2} md={1}>
                          {/* eslint-disable-next-line jsx-a11y/alt-text */}
                          <Avatar
                            src={comment?.profileData[0]?.image?.imagePath}
                            alt="Your profile avatar"
                          >
                            {profile?.name
                              ? generateUserInitials(
                                  comment.profileData[0].name
                                )
                              : "U"}
                          </Avatar>
                        </Grid>
                        <Grid item xs={10} sm={10} md={11}>
                          <Typography>
                            {comment.profileData[0].name}{" "}
                            <span
                              style={{
                                color: "var(--neutral-slate)",
                                fontSize: "0.8rem",
                              }}
                            >
                              {formatCleanJSDate(comment.createdAt)}
                            </span>
                          </Typography>
                          <Typography style={{ fontSize: "0.9rem" }}>
                            {comment.text}
                          </Typography>
                        </Grid>
                        <div className="pendingButtonGroup">
                          <Button
                            variant="contained"
                            onClick={() => approveComment(comment)}
                            style={{
                              backgroundColor: "var(--primary-navy)",
                              color: "white",
                            }}
                          >
                            Approve comment
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => declineComment(comment)}
                            style={{
                              backgroundColor: "var(--secondary-cherry)",
                              color: "white",
                            }}
                          >
                            Decline comment
                          </Button>
                        </div>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
          </MemoryAdditionWrapper>
        </Paper>
      )}
    </PageWrapper>
  );
};

export default ViewAndPreviewMemory;
