// Explanation Page of onboarding

import React, { useState, useEffect } from "react";

// MUI
import {
  Paper,
  Typography,
  Button,
  Slide,
  Fade,
  Grid,
} from "@material-ui/core";

// Styled Components
import { PaperWrapper } from "../Onboarding.style";

// Graphic
import { MemoriesGraphic } from "./MemoriesGraphic";
import balloons from "../../../assets/onboarding/balloons.jpg";

import { ReactComponent as GiftIcon } from "../../../assets/gift.svg";

export function OnboardingStep1({ nextFunc, competition = {}, ...props }) {
  /* Transition States */
  const [cardTransition, setCardTransition] = useState(false);
  const [titleTransition, setTitleTransition] = useState(false);
  const [paragraphTransition, setParagraphTransition] = useState(false);
  const [buttonTransition, setButtonTransition] = useState(false);
  /* Transition Timings */
  useEffect(() => {
    setTimeout(() => {
      setCardTransition(true);
    }, 500);
    setTimeout(() => {
      setTitleTransition(true);
    }, 1000);
    setTimeout(() => {
      setParagraphTransition(true);
    }, 1000);
    setTimeout(() => {
      setButtonTransition(true);
    }, 1000);
  });

  return (
    <Grid container spacing={0} style={{ margin: "0px", padding: "0" }}>
      <Grid
        item
        id="welcome-picture"
        xs={12}
        md={6}
        lg={6}
        style={{
          backgroundColor: "",
          display: window.innerWidth < 1280 ? "none" : "block",
          height: "100vh",
        }}
      >
        <img
          src={balloons}
          style={{
            height: window.innerWidth < 600 ? "300px" : "100%",
            width: "100%",
            objectFit: window.innerWidth < 1280 ? "fill" : "fill",
            backgroundPosition: "top",
            padding: "-1rem",
          }}
        />
      </Grid>

      <Grid
        item
        id="welcome-text"
        xs={12}
        lg={6}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: window.innerWidth < 800 ? "1rem" : "5rem",
        }}
      >
        <div
          style={{
            // border: "solid 1px white",
            borderRadius: "20px",
            padding: "2rem",
          }}
        >
          <Typography
            gutterBottom
            style={{
              textAlign: "left",
              color: "white",
              fontWeight: "bolder",
              fontSize: window.innerWidth < 600 ? "2rem" : "2.5rem",
            }}
          >
            Welcome to Iternal
          </Typography>

          <>
            <Typography
              variant="body1"
              style={{
                paddingBottom: "1rem",
                color: "white",
                fontSize: window.innerWidth < 600 ? "1rem" : "1.2rem" ,
              }}
            >
              Iternal is all about celebrating life, through the hundreds of
              stories we’ve all created on our own unique experience.
              <br />
              <br />
              Whether it's your own story or a loved one's, we believe that
              everyone has a right to have their story in their own words, and
              share that story with whoever they want.
              <br />
              <br />
              We're going to ask you a few questions to get to know you, then
              we'll start adding your first story to your very own timeline!
            </Typography>
            {competition && competition?.data && (
              <div
                style={{
                  padding: "1rem",
                  backgroundColor: "#e26713",
                  borderRadius: "1rem",
                  margin: "1rem",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                    <GiftIcon width={50} height={50} />
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      style={{
                        color: "var(--neutral-white)",
                        fontWeight: "bold",
                      }}
                    >
                      You've also been entered into our {competition.data.prize}{" "}
                      giveaway!
                      <br />
                      Click 'Get Started' for more chances to win!
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}
          </>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="secondary"
              onClick={nextFunc}
              style={{
                height: "3rem",
                fontSize: "1.1rem",
                fontWeight: "bold",
                borderRadius: "100px",
                maxWidth: "432px",
                boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
              }}
            >
              Let's Go!
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
