import React from 'react';

const BreadcrumbSelected = props => (
  //   fill={props.color || "currentColor"}
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill={props.color || 'currentColor'} />
  </svg>
);

export default BreadcrumbSelected;
