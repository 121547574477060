import styled from 'styled-components';
import { breakpointsMax } from '../../../constants/breakPoints';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  padding: 2rem 3rem 1rem 280px;
  .bg_image {
    margin: 1rem auto;
  }

  @media ${breakpointsMax.laptop} {
    padding: 5rem 1rem 1rem 1rem;
  }
`;
