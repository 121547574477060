import axios from 'axios';
import Swal from 'sweetalert2';

import {
  API_ACCOUNT_CHECKLIST_URL,
} from '../constants/apiRoutes';

import makeError from './makeError';

const getAccountChecklistById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${API_ACCOUNT_CHECKLIST_URL}/${id}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const createAccountChecklist = async ({ accountChecklist, options }) => {
  const {
    level,
  } = accountChecklist;

  try {
    const { data } = await axios.post(`${API_ACCOUNT_CHECKLIST_URL}`, {
      level
    });

    if (data) {
      Swal.fire({
        title: 'You completed stage 1!',
        type: 'success',
        timer: 2000,
      });
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const updateAccountChecklist = async ({ accountChecklist, options }) => {
  const {
    level,
    userId,
    accountChecklistId,
  } = accountChecklist;

  try {
    const { data } = await axios.patch(`${API_ACCOUNT_CHECKLIST_URL}`, {
      level,
      userId,
      accountChecklistId,
    });
    if (data) {
      Swal.fire({
        title: `You completed stage ${level}!`,
        type: 'success',
        timer: 2000,
      });
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const deleteAccountChecklist = async ({ id, options }) => {
  try {
    const { data } = await axios.delete(`${API_ACCOUNT_CHECKLIST_URL}/${id}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const accountChecklist = {
    getAccountChecklistById,
    createAccountChecklist,
    updateAccountChecklist,
    deleteAccountChecklist,
};

export default accountChecklist;
