import React, { useState } from 'react';
import Swal from 'sweetalert2';
import history from '../../../history';
import memories from '../../../api/memories';

import { FooterWrapper, IconWrapper } from './style';

import Icon from '../Icon/Icon';
import Card from '../Inputs/ShareLinkWrapper';

import {
  CREATE_MEMORY_URL,
  PREVIEW_MEMORY_URL,
  MEMORIES_URL,
  DASHBOARD_URL,
} from '../../../constants/constantUrls';

import { Modal, Paper } from '@material-ui/core';
import ShareLinkWrapper from '../Inputs/ShareLinkWrapper';

const Footer = ({
  validateMemory,
  loading,
  viewMode,
  allData,
  memoryId,
  setEditExisting,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // previewing memories from create / edit page
  const PreviewMemory = () => (
    <IconWrapper
      disabled={loading}
      onMouseDown={async () => {
        // check if all inputs are correct before redirection
        const validated = await validateMemory({
          previewMode: 'preview',
          memoryId,
        });

        //  redirects to the preview memory page
        if (validated) {
          // update storage links for temp. preview
          return memories
            .getStorageLink({ memory: validated })
            .then(({ data }) => {
              validated.tempVoice = data.voice;
              validated.tempImages = data.images;
              validated.tempVideo = data.video;

              history.push(PREVIEW_MEMORY_URL, validated);
            });
        }
      }}
    >
      <Icon height="25px" icon="preview" />
      Preview
    </IconWrapper>
  );

  // saves or updates memory as draft
  const SaveMemoryAsDraft = () => (
    <IconWrapper
      disabled={loading}
      onMouseDown={() =>
        validateMemory({
          type: 'saveDraft',
          memoryId,
        })
      }
    >
      <Icon height="25px" icon="save" />
      Save draft
    </IconWrapper>
  );

  // saves or updates memory
  // const SaveAndShareMemory = () => (
  //   <IconWrapper
  //     disabled={loading}
  //     onMouseDown={() => {
  //       validateMemory({
  //         type: 'saveShare',
  //         memoryId,
  //       });
  //     }}
  //   >
  //     <Icon height="25px" icon="checkmark" />
  //     Save and share
  //   </IconWrapper>
  // );

  // takes user to create / edit component
  const EditMemory = ({ text }) => (
    //  SET DATA AS MEMORY ???
    <IconWrapper
      onClick={() => {
        // console.log('edit mem', allData);
        setEditExisting(true);
        history.push(CREATE_MEMORY_URL, {
          allData,
          memoryId,
        });
      }}
    >
      <Icon width="25px" icon="editPencil" />
      <span>{text}</span>
    </IconWrapper>
  );
  // deletes memory
  const DeleteMemory = () => (
    <IconWrapper
      onClick={() =>
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#66CC66',
          cancelButtonColor: '#8a8787',
          confirmButtonText: 'Yes, delete',
        }).then(result => {
          if (result.value) {
            memories
              .deleteMemory({ id: memoryId })
              .then(() => history.push(DASHBOARD_URL));
          }
        })
      }
    >
      <Icon width="25px" icon="trash" />
      Delete
    </IconWrapper>
  );

  // creates sharing link for individual memory
  const shareFunc = () => {
    // generate shareable url for memory
    // designed to work both locally and when hosted
    const url = window.location.href.split('/');
    const urlToShare = `${url[0]}//${url[2]}/share/memory/${memoryId}`;
    return (
      <div
        style={{
          alignItems: 'center',
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'row',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          zIndex: '1000',
        }}
      >
        <Paper
          elevation={3}
          style={{
            padding: '2rem',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <ShareLinkWrapper shareLink={urlToShare} copy={<>Click the button below to copy your sharing code.</>} share={true}/>
          
        </Paper>
      </div>
    )
    // Swal.fire({
    //   type: 'info',
    //   text: 'Copy and share this link with others to share this memory!',
    //   input: 'text',
    //   inputValue: urlToShare,
    // });
  };

  return (
    <FooterWrapper>
      {/* creating a memory */}
      {viewMode === 'creation' && (
        <>
          <SaveMemoryAsDraft />
          <PreviewMemory memoryId={memoryId} />
          {/* <SaveAndShareMemory /> */}
        </>
      )}
      {/* viewing a memory */}
      {viewMode === 'view' && (
        <>
          <EditMemory text="Edit" />
          <DeleteMemory />
          <IconWrapper onClick={handleOpen}>
            <Icon width="25px" icon="shareNetwork" color={'#fff'} fill='white' style={{ fill: 'white' }}/>
            Share
          </IconWrapper>
          <Modal
            open={open}
            onClose={handleClose}
            style={{ zIndex: '1050' }}
          >
            {shareFunc()}
          </Modal>
        </>
      )}
      {/* previewing a memory */}
      {viewMode === 'preview' && (
        <>
          <EditMemory text="Back to editor" />
        </>
      )}
    </FooterWrapper>
  );
};

export default Footer;
