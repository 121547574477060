// React
import React from 'react';
import PropTypes from 'prop-types';

// MUI Core
import { Paper, Button, Typography, Chip } from '@material-ui/core';

// MUI Icons - Milestone Chip
import StarIcon from '@material-ui/icons/Star';
import EditIcon from '@material-ui/icons/Create';

// MUI Icons - Milestone Memories
import Birthday from '@material-ui/icons/Cake';
import Anniversary from '@material-ui/icons/Favorite';
import Holiday from '@material-ui/icons/BeachAccess';
import Relocation from '@material-ui/icons/LocalShipping';

// MUI Lab
import TimelineContent from '@material-ui/lab/TimelineContent';

// Styled Components
import { ContentCardStyles } from '../ContentCards';

export function MilestoneCard({
  _id,
  user,
  date,
  dateType,
  createdAt,
  updatedAt,
  title,
  description,
  category,
  isHighlighted,
  formattedDate,
  editFunc,
  ...props
}) {
  // Content rendering
  const renderShortenedText = (text, length) =>
    length < text?.length ? `${text?.slice(0, length)}...` : text || null;
  const renderMilestoneIcon = () => {
    switch (category) {
      case 'Birthday':
        return <Birthday />;
      case 'Holiday':
        return <Holiday />;
      case 'Anniversary':
        return <Anniversary />;
      case 'Relocation':
        return <Relocation />;
      default:
        return null;
    }
  };
  const renderMilestoneChip = () => {
    const milestoneChipProps = {
      label: 'Milestone'.toUpperCase(),
      icon: <StarIcon />,
      color: 'primary',
    };
    return <Chip  {...milestoneChipProps} className="milestoneChip" />;
  };
  const renderCategoryChip = () => {
    const categoryChipProps = {
      label: category,
      icon: renderMilestoneIcon(),
      color: 'default',
    };
    return <Chip {...categoryChipProps} className="milestoneChip" />;
  };

  // Milestone Memory
  return (
    <ContentCardStyles {...props}>
      <TimelineContent className="cardWrapper">
        <Paper
          elevation={isHighlighted ? 15 : 3}
          className="card milestoneCard"
        >
          <div className="cardContentWrapper">
            <div className="editButtonWrapper">
              <Button
                color="secondary"
                onClick={() =>
                  editFunc({
                    isModal: true,
                    _id,
                    categoryVal: category,
                    descriptionVal: description,
                    dateVal: date,
                    title,
                  })
                }
              >
                Edit&nbsp;
                <EditIcon fontSize="small" />
              </Button>
            </div>
            <Typography variant="body1" color="textSecondary">
              {formattedDate}
            </Typography>

            <Typography
              className="title milestoneTitle"
              variant="h6"
              component="h1"
              color="primary"
              style={{textTransform:'capitalize'}}
            >
              {renderShortenedText(title, 100)}
            </Typography>

            <br />
            <>{renderMilestoneChip()}</>
            <>{renderCategoryChip()}</>
            <Typography className="description">
              {renderShortenedText(description, 500)}
            </Typography>
          </div>
        </Paper>
      </TimelineContent>
    </ContentCardStyles>
  );
}

MilestoneCard.propTypes = {
  /**
   * The unique ID of this memory
   */
  _id: PropTypes.string,
  /**
   * The user account that created this memory
   */
  user: PropTypes.string,
  /**
   * The date this memory happened
   */
  date: PropTypes.string,
  /**
   * The type of date display used for this memory (`full` if the user has specified an exact date, `age` if the user has specified how old they remember being when this memory happened)
   */
  dateType: PropTypes.oneOf(['full', 'age']),
  /**
   * The date this memory was created
   */
  createdAt: PropTypes.string,
  /**
   * The date this memory was last updated
   */
  updatedAt: PropTypes.string,
  /**
   * The title of this memory
   */
  title: PropTypes.string,
  /**
   * The user's description of this memory
   */
  description: PropTypes.string,
  /**
   * The category of the memory prompt or milestone
   */
  category: PropTypes.string,
  /**
   * Please provide a function for opening a page/modal to edit the currently displayed milestone
   */
  editFunc: PropTypes.func,

  // Other props
  /**
   * Should this card be highlighted?
   */
  isHighlighted: PropTypes.bool,
};
MilestoneCard.defaultProps = {
  // Memory
  _id: undefined,
  user: undefined,
  date: undefined,
  dateType: 'full',
  createdAt: undefined,
  updatedAt: undefined,
  title: undefined,
  description: undefined,
  category: undefined,
  editFunc: () => {},
  getMilestonesFunc: () => {},
  isHighlighted: false,
};
