import React, { useContext, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';
import Modal from '../FixedModal';
import { payments } from '../../../api';

import { Title, Description, FooterWrapper } from './style';
import CardSection from './Form';
import UserContext from '../../../context/user';
import Button from '@material-ui/core/Button';

// TODO handle modal hide/show
export default function CheckoutForm({ isShowing, hide, done }) {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { name, email } = useContext(UserContext);
  const [introduction, setIntroduction] = useState(true);
  const reset = () => {
    setIntroduction(true);
    hide();
  };
  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    setIsLoading(true);
    const card = elements.getElement(CardElement);

    const { error } = await payments.create({ stripe, card, email, name });
    if (!error) {
      await Swal.fire({
        title: 'you are successfully subscribed',
        type: 'success',
      });
      if (done && done instanceof Function) done();
    }
    setIsLoading(false);
  };

  return (
    isShowing && (
      <Modal hide={reset}>
        <Title>upgrade to premium now</Title>
        {introduction ? (
          <>
            <Description>
              pay £20 for annual Subscription
              <br />
              so you can store unlimited stories
            </Description>
            <FooterWrapper>
              <Button
                isWhiteGreen
                text="upgrade"
                onClick={() => {
                  setIntroduction(false);
                }}
              />
            </FooterWrapper>
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <CardSection />
            <FooterWrapper>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!stripe || !elements || isLoading}
                isLoading={isLoading}
              >
                Subscribe
              </Button>
            </FooterWrapper>
          </form>
        )}
      </Modal>
    )
  );
}
