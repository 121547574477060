import styled from 'styled-components';
import { breakpointsMax } from '../../../../constants/breakPoints';

export const Main = styled.main`
  display: flex;
  max-width: 100rem;
  margin: 0 auto;
  flex-direction: column;
  padding: 2rem 3rem 1rem 280px;

  @media ${breakpointsMax.laptop} {
    padding: 1rem 0.5rem;
  }

  @media ${breakpointsMax.tablet} {
    .friend-card-block {
      height: 100% !important;
    }
  }
`;