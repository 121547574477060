import styled from 'styled-components';
import { breakpointsMax } from '../../../constants/breakPoints';

export const Main = styled.main`
  display: flex;
  max-width: 100rem;
  margin: 0 auto;
  flex-direction: column;
  padding: 2rem 3rem 1rem 280px;

  @media ${breakpointsMax.laptop} {
    padding: 1rem 0.5rem;
  }

  @media ${breakpointsMax.tablet} {
    .friend-card-block {
      height: 100% !important;
    }
  }

  .friend-card-block {
    height: 300px;
  }
  .margin-auto {
    margin: 0 auto;
  }

  form {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    label {
      width: 100%;
    }
  }
`;

export const MemoryButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  /* padding: 0rem 3rem 1rem 140px;   */
  margin-bottom: 20px;
  button {
    margin: 0px 10px;
    width: 200px;
  }
  @media ${breakpointsMax.tablet} {
    padding: 0rem;
  }
`;
