import styled from "styled-components";

export const PolaroidWrapper = styled.div`
  ${"" /* border-left: 0.75rem solid white;
  border-right: 0.75rem solid white;
  border-top: 1rem solid white;
  border-bottom: 1rem solid white; */}
  display: inline-block;
  width: auto;
  img {
    max-width: 30rem;
    object-fit: contain;
    cursor: pointer;
  }
  ${'' /* border-radius: 0.5rem; */}
  :hover,
  :active {
    transform: scale(1.02);
  

    
  }
`;
