// React
import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';



// Bottom nav
import Groups from "@material-ui/icons/People";
import Friends from "@material-ui/icons/EmojiPeople";
import Profile from "@material-ui/icons/Face";
import Stories from "@material-ui/icons/MenuBook";
import Camera from "@material-ui/icons/PhotoCamera"


import * as C from "../../../constants/constantUrls";
import history from "../../../history";


// MUI
import { Paper, Typography, Button, Grid } from '@material-ui/core';

// Custom Components
import { Divider } from '../../Divider/Divider';
import { MemoryCard } from '../../Dashboard/Blocks/NewTimeline/ContentCards/MemoryCard/MemoryCard';

import {
  CREATE_OTHER_URL,
  DASHBOARD_URL,
  SIGNUP_URL
} from '../../../constants/constantUrls';

export const SuccessScreenOther = ({
  memory = {},
  shortId = '',
  userName = '',
  messageBody = '',
  sendMemoryFunc = () => {},
  isLoggedIn = false,
  ...props
}) => {
  return (
    <Paper
      elevation={3}
      style={{
        padding: '2rem',
        textAlign: 'center',
        maxWidth: '800px',
        width: '80%',
        marginBottom: window.innerWidth < 1024 ? "8rem" : "2rem",
        marginLeft: window.innerWidth < 300 ? "1rem" : '2rem',
        marginRight: window.innerWidth < 300 ? "1rem" : '2rem',

      }}
      {...props}
    >
     
      <Typography variant="h4" gutterBottom color="primary" style={{ marginBottom: '2rem' }}>
        You Created a memory for {userName}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MemoryCard
            {...memory}
            preview
            style={{
              width: '100%',
              textAlign: 'left',
              maxWidth: '400px',
              margin: '0 auto',
            }}
            viewingFriendsMemory
            noLogin={!isLoggedIn}
          />
        </Grid>
      </Grid>
      <Divider logo />
      <Grid container spacing={3} style={{ marginTop: '1rem' }}>
        <Grid item xs={12}>
          <NavLink
            to={{
              pathname: CREATE_OTHER_URL.replace(':shortId', shortId),
              state: {
                dontShowPopup: true,
              }
            }}
            style={{ textDecoration: 'none' }}
          >
            <Button variant="contained" color="secondary" fullWidth style={{    marginTop: '1rem',
          borderRadius: '100px',
  
          minWidth: '164px',
          minHeight: '50px',
          fontWeight:"700",
          boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',}}>
              Create Another Story for {userName}
            </Button>
          </NavLink>
        </Grid>
        <Grid item xs={12}>
          {isLoggedIn ? (
           <NavLink
              to={{
                pathname: DASHBOARD_URL,
              }}
              style={{ textDecoration: 'none' }}
            >
              <Button variant="outlined" color="secondary" fullWidth>
                Go To Dashboard
              </Button>
            </NavLink>
          ) : (            
            <div>
              <Divider logo />
              <Typography style={{ marginBottom: '1rem' }}>
                No account? Why not create an account today and check out {userName}'s timeline, or start leaving your own memories! It only takes a minute.
              </Typography>
              <NavLink
                to={{
                  pathname: SIGNUP_URL,
                }}
                style={{ textDecoration: 'none' }}
              >
                <Button variant="outlined" color="primary" fullWidth>
                  Create an Account
                </Button>
              </NavLink>
            </div>
          )}
          
        </Grid>
      </Grid>
    </Paper>
  );
};

SuccessScreenOther.propTypes = {
  /**
   * Enter a memory
   */
  memory: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    date: PropTypes.string,
    dateAge: PropTypes.number,
    dateType: PropTypes.oneOf(['full', 'age']),
    description: PropTypes.string,
    images: PropTypes.arrayOf({
      _id: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    isDraft: PropTypes.bool,
    isPublic: PropTypes.bool,
    privacySetting: PropTypes.oneOf([
      'Only Me',
      'Friends & Family',
      'Specific Groups',
      'Public',
    ]),
    tempImages: PropTypes.arrayOf({
      _id: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    user: PropTypes.string,
    video: PropTypes.string,
    voice: PropTypes.string,
    author: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  /**
   * Add Friends to list
   */
  friends: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      owner: PropTypes.string,
      nickname: PropTypes.string,
      relationships: PropTypes.arrayOf(PropTypes.string),
      sharedWithId: PropTypes.string,
      sharedWithEmail: PropTypes.string,
    }),
  ),
  /**
   * Enter a link for the memory
   */
  shareLink: PropTypes.string,
  /**
   * Enter a message for sharing on socials
   */
  messageBody: PropTypes.string,
  /**
   * A function for sending a memory
   */
  sendMemoryFunc: PropTypes.func,
};

SuccessScreenOther.defaultProps = {
  memory: {},
  friends: [],
  shareLink: '',
  messageBody: '',
  sendMemoryFunc: () => {},
};
