// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// nanoid
import { nanoid } from 'nanoid';

// Media Carousel
import { Carousel as ReactResponsiveCarousel } from 'react-responsive-carousel';

// MUI Lazy Loading Image
import Image from 'material-ui-image';

// Styled Components
import { CarouselStyles } from './Carousel.style';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

export function Carousel({
  title,
  tempImages,
  video,
  tempVideo,
  voice,
  tempVoice,
  promptImages,
  ...props
}) {
  const [isSingle, setIsSingle] = useState(false);
  // Carousel Props
  const CarouselProps = {
    // Functionality
    showArrows: true,
    showStatus: false,
    showThumbs: false,
    dynamicHeight: false,
    // Touch
    emulateTouch: true,
    swipeable: true,
    infinteLoop: true,
    useKeyboardArrows: true,
    // Slideshow
    // WARNING: Do NOT use js Infinity as interval value! Chaos ensues...
    autoPlay: false,
    interval: 99999999,
    stopOnHover: true,
  };

  const renderVideo = () => {
    if (tempVideo) {
      return (
        [
          <div className="videoWrapper" key={nanoid()}>
            <video autoPlay={false} controls loop>
              <track kind="captions" />
              <source src={tempVideo} />
              Sorry, your current browser can&apos;t seem to play this video.
              Please try a different device or update to a newer version.
            </video>
          </div>,
        ]
      )
    } else if (video) {
      return (
        [
          <div className="videoWrapper" key={nanoid()}>
            <video autoPlay={false} controls loop>
              <track kind="captions" />
              <source src={video} />
              Sorry, your current browser can&apos;t seem to play this video.
              Please try a different device or update to a newer version.
            </video>
          </div>,
        ]
      )
    } else {
      return null
    }
  }

  const renderVoice = () => {
    if (tempVoice) {
      return (
        [
          <div className="voiceWrapper" key={nanoid()}>
            <AudioPlayer
              src={tempVoice}
              showSkipControls={false}
              showJumpControls={false}
              // other props here
            />
          </div>,
        ]
      )
    } else if (voice) {
      return (
        [
          <div className="voiceWrapper" key={nanoid()}>
            <AudioPlayer
              src={voice}
              showSkipControls={false}
              showJumpControls={false}
              // other props here
            />
          </div>,
        ]
      )
    } else {
      return null;
    }
  }

  const renderImages = () =>
    tempImages.length > 0
      ? tempImages.map(({ _id, imagePath }, i) => (
          <div className="photoWrapper" key={_id}>
            <Image
              style={{ position: 'inherit !important' }}
              className="photoMain"
              src={imagePath}
              cover
              aspectRatio={16 / 9}
              alt={`${title} - ${i + 1}`}
            />
            {/* <img className="photoBackgroundBlur" src={imagePath} alt="" /> */}
          </div>
        ))
      : null;

  // renderContent() takes the above three functions and returns an array of content to render, compatible with the format required by ReactResponsiveCarousel
  const renderContent = () => {
    let content = [];

    content.push(renderVideo());
    content.push(renderVoice());
    content.push(renderImages());

    content = content.filter(el => el != null);
    if (content[0]?.length === 1 && content.length === 1 && !isSingle) {
      setIsSingle(true);
    }
    return content;
  };
  return (
    <CarouselStyles {...props} isSingle={isSingle}>
      <ReactResponsiveCarousel {...CarouselProps}>
        {renderContent()}
      </ReactResponsiveCarousel>
    </CarouselStyles>
  );
}

Carousel.propTypes = {
  title: PropTypes.string,
  tempImages: PropTypes.arrayOf({
    _id: PropTypes.string,
    imagePath: PropTypes.string,
  }),
  video: PropTypes.string,
  tempVideo: PropTypes.string,
  voice: PropTypes.string,
  tempVoice: PropTypes.string,
  promptImages: PropTypes.arrayOf({
    _id: PropTypes.string,
    alt: PropTypes.string,
    imagePath: PropTypes.string,
  }),
};

Carousel.defaultProps = {
  title: 'Your memory',
  tempImages: [],
  video: undefined,
  tempVideo: undefined,
  voice: undefined,
  tempVoice: undefined,
  promptImages: [],
};
