import React, { useState, useEffect, useRef, useContext } from 'react';
import VideoRecorder from 'react-video-recorder';
import ReactPlayer from 'react-player';
// import * as ffmpeg from 'ffmpeg.js/ffmpeg-mp4.js';
// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import Swal from 'sweetalert2';
import MediaCapturer from 'react-multimedia-capture';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import logo from '../../../assets/i-logo-orange.png';
import { Message, VoiceRecordingWrapper, Img } from './style';
import axios from 'axios';
import { memories } from '../../../api';
import UserContext from '../../../context/user';
import UploadFileIcon from '@material-ui/icons/Publish';
import VideocamIcon from '@material-ui/icons/Videocam';

const Video = ({
  video,
  setVideo,
  setVoice,
  setActiveInput,
  renderAlternativeChoice,
  setDescription,
  setIsRecording,
  isRecording,
  tempVideo,
  setError: setParentError,
  userId,
  isUploading,
  setIsUploading
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [recordingFile, setRecordingFile] = useState(null);
  const [error, setError] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [granted, setGranted] = useState('');
  const [rejectedReason, setRejectedReason] = useState('');
  const [recording, setRecording] = useState(false);
  const [paused, setPaused] = useState(false);
  const [progress, setProgress] = useState(0);
  const [videoScreen, setVideoScreen] = useState(0);
  const user = useContext(UserContext);
  const videoInput = useRef();

  useEffect(() => {
    if (video && typeof video === 'object') {
      setRecordingFile(URL.createObjectURL(video));
    }
  }, [video]);

  useEffect(
    () => () => {
      setIsLoading(false);
      setParentError('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (progress === 100) {
      setIsUploading(false);
    } else if (progress !== 0) {
      setIsUploading(true);
    } else {
      setIsUploading(false);
    }
  }, [progress])

  const directUploadToGoogle = async e => {
    const userId = user.id;
    const { files } = e.target;
    const allFiles = Array.from(files);
    if (allFiles.length === 0) {
      return
    }
    const errors = [];
    setIsUploading(true);
    const video = allFiles[0];
    const {
      data: { generatedName } = {},
      error,
    } = await memories.uploadVideo({
      video,
      userId: userId || 'temp',
    }, setProgress);
    if (error) errors.push(error);
    setRecordingFile(URL.createObjectURL(video));
    setVideo(generatedName);
  };

  const endVideo = async blob => {
    setIsProcessing(true);
    if (
      blob.type === 'video/webm;codecs=h264' &&
      !navigator.userAgent.includes('Firefox') &&
      !navigator.userAgent.includes('iPhone') &&
      !navigator.userAgent.includes('Android')
    ) {
      const { data } = await axios.post('/api/ffmpeg', blob, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${blob._boundary}`,
        },
      });
      const byteCharacters = atob(data);
      const byteArrays = [];
    
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
    
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
    
      blob = new Blob(byteArrays, {type: 'video/mp4'});
      Swal.fire({
        title: 'Your video has finished processing',
        timer: 1500,
        type: 'success',
      });
    }
    setIsProcessing(false);
    setRecordingFile(URL.createObjectURL(blob));
    setVideo(blob);
  };

  if (isLoading) return <Message>Loading...</Message>;
  // Swal.fire({
  //   text: navigator.userAgent
  // });
  // ///////////////
  const handleGranted = () => {
    setGranted(true);
    console.log(granted);
    console.log('Permission Granted!');
  };
  const handleDenied = err => {
    setRejectedReason(err.name);
    console.log(rejectedReason);
    console.log('Permission Denied!', err);
  };
  const handleStart = stream => {
    setRecording(true);

    setStreamToVideo(stream);
    console.log('Recording Started.');
  };
  // const handleStop = blob => {
  //   setRecording(false);

  //   releaseStreamFromVideo();

  //   console.log('Recording Stopped.');
  //   downloadVideo(blob);
  // };
  const handlePause = () => {
    releaseStreamFromVideo();
    console.log(paused);
    setPaused(true);
  };
  const handleResume = stream => {
    setStreamToVideo(stream);

    setPaused(false);
  };
  const handleError = err => {
    console.log(err);
    setError(err);
  };
  const handleStreamClose = () => {
    setGranted(false);
  };
  const setStreamToVideo = stream => {
    const video = document.getElementById('video');
    if (window.URL) {
      video.srcObject = stream;
    } else {
      video.srcObject = stream;
    }
  };
  const releaseStreamFromVideo = () => {
    document.getElementById('video').src = '';
  };
  // const downloadVideo = blob => {
  //   setRecordingFile(URL.createObjectURL(blob));
  //   setVideo(blob);
  // };
  // ///////////////
  return (
    <VoiceRecordingWrapper>
      <div 
        style={{ 
          display: 'flex', 
          flexDirection: 'row', 
          justifyContent: 'space-around', 
          width: '100%', 
          marginBottom: '2rem' 
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => videoInput.current.click()}
          style={{ width: '40%', padding: '1rem', borderRadius: '1rem' }}
        >
          <span 
            style={{ 
              color: 'white', 
              fontSize: window.innerWidth < 500 ? "1rem" :'1.1rem', 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              flexDirection: window.innerWidth < 600 ? 'column' : 'row'
            }}
          >
            <UploadFileIcon />&nbsp;Upload A Video
          </span>
          
        </Button>
        <Button 
          variant="contained"
          color="primary"
          onClick={() => {
            if(!error && video && recordingFile && !isProcessing && !recording ) {
              Swal.fire({
                title: 'Are you sure?',
                text: "You will lose the video you have uploaded.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#66CC66',
                cancelButtonColor: '#8a8787',
                confirmButtonText: 'Yes'
              }).then(result => {
                if (result.value) {
                  setVideo(null);
                  setRecordingFile('');
                  setVoice(null);
                  setDescription('');
                }
              });
            }
            setVideoScreen(1)
          }}
          style={{ width: '40%', padding: '1rem', borderRadius: '1rem'  }}
        >          
          <span 
            style={{ 
              color: 'white', 
              fontSize: window.innerWidth < 500 ? "1rem" :'1.1rem', 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              flexDirection: window.innerWidth < 600 ? 'column' : 'row'
            }}
          >
            <VideocamIcon />&nbsp;Record A Video
          </span>
        </Button>
      </div>
      {videoScreen === 1 && (
        <>
          {!error && !video && !recordingFile && !isProcessing && (
            <>
              <Typography
                style={{
                  textAlign: 'left !important',
                  marginBottom: '1rem',
                  fontSize: '1.4rem !important',
                }}
              >
                <div style={{ fontSize: '1.35rem' }}>
                  Record a short, minute-long memory
                </div>
              </Typography>
              {navigator.userAgent.includes('Pixel') &&
              !navigator.userAgent.includes('Firefox') ? (
                <MediaCapturer
                  constraints={{ audio: true, video: true }}
                  timeSlice={10}
                  onGranted={handleGranted}
                  onDenied={handleDenied}
                  onStart={handleStart}
                  onStop={endVideo}
                  onPause={handlePause}
                  onResume={handleResume}
                  onError={handleError}
                  onStreamClosed={handleStreamClose}
                  mimeType={
                    navigator.userAgent.includes('Chrome') &&
                    (navigator.userAgent.includes('Macintosh') ||
                      navigator.userAgent.includes('Windows'))
                      ? 'video/webm;codecs=h264'
                      : ''
                  }
                  render={({ start, stop }) => (
                    <div style={{ textAlign: 'center' }}>
                      {/* {!granted && <button onClick={request}>Get Permission</button>} */}
                      <video
                        autoPlay
                        muted
                        id="video"
                        hidden={!recording}
                        style={{ width: '100%', transform: 'scaleX(-1)' }}
                      />
                      {recording ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={stop}
                        >
                          Stop recording
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={start} style={{ marginBottom: '1rem' }}>
                          Start recording
                        </Button>
                      )}
                    </div>
                  )}
                />
              ) : navigator.userAgent.includes('Chrome') &&
                (navigator.userAgent.includes('Macintosh') ||
                  navigator.userAgent.includes('Windows')) ? (
                <VideoRecorder
                  isOnInitially={false}
                  isFlipped={true}
                  showReplayControls
                  replayVideoAutoplayAndLoopOff
                  onRecordingComplete={videoBlob => {
                    endVideo(videoBlob);
                  }}
                  timeLimit={60000}
                  mimeType="video/webm;codecs=h264"
                />
              ) : (
                <VideoRecorder
                  isOnInitially={false}
                  isFlipped={true}
                  showReplayControls
                  replayVideoAutoplayAndLoopOff
                  onRecordingComplete={videoBlob => {
                    endVideo(videoBlob);
                  }}
                  timeLimit={60000}
                />
              )}
            </>
          )}
        </>
      )}
      {/* RECORDING BUTTON RENDER */}
      <input 
        ref={videoInput}
        accept="video/*"
        type="file"
        onChange={directUploadToGoogle}
        style={{ display: 'none' }}
      />
      
      {!error && isProcessing && (
        <>
          <Img alt="Spinning Iternal logo" src={logo} />
          <br />
          <p>We're just processing your video</p>
        </>
      )}

      {!error && video && recordingFile && !isProcessing && (
        <>
          <div className="video-player-wrapper">
            <ReactPlayer
              controls
              url={[{ src: recordingFile, type: 'video/mp4' }]}
            />

          </div>
          {isUploading && (
              <>
                <CircularProgress 
                  color='primary'
                  value={progress}
                  variant="determinate"
                  size={60}
                  style={{ marginTop: '1rem' }}
                />
                <Typography>{progress}%</Typography>
              </>
            )}
          {!isRecording &&
            renderAlternativeChoice(
              'camera',
              'Not Happy? Press to re-record',
              () => {
                setVideo(null);
                setRecordingFile('');
                setVoice(null);
                setDescription('');
              },
            )}
        </>
      )}
      {error && (
        <>
          <Message>
            Ooops, there's an issue with recording. We've logged this and will
            work to fix it. Please use a different device to record or click
            below to write your memory.
          </Message>
        </>
      )}
      {!error && video && !recordingFile && !isProcessing && tempVideo && (
        <>
          <div className="video-player-wrapper">
            <ReactPlayer
              controls
              url={[{ src: tempVideo, type: 'video/mp4' }]}
            />
            {isUploading && (
              <CircularProgress 
                color='primary'
                size='50'
                value={progress}

              />
            )}
          </div>

          {!isRecording &&
            renderAlternativeChoice(
              'camera',
              'Not Happy? Press to re-record',
              () => {
                setVideo(null);
                setRecordingFile('');
                setVoice(null);
                setDescription('');
              },
            )}
        </>
      )}
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        {!isRecording &&
          renderAlternativeChoice('pencil', 'Write your memory instead', () => {
            setVideo(null);
            setVoice(null);
            setDescription('');
            setActiveInput('description');
          })}
        {!isRecording &&
          renderAlternativeChoice('microphone', 'Record using audio instead', () => {
            setVideo(null);
            setVoice(null);
            setDescription('');
            setActiveInput('voice');
          })}
      </div>
    </VoiceRecordingWrapper>
  );
};

export default Video;
