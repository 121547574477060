import React from 'react';

const Send = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 490.282 490.282"
    width={props.height}
    height={props.width}
  >
    <path
      d="M.043 245.197c.6 10.1 7.3 18.6 17 21.5l179.6 54.3 6.6 123.8c.3 4.9 3.6 9.2 8.3 10.8 1.3.5 2.7.7 4 .7 3.5 0 6.8-1.4 9.2-4.1l63.5-70.3 90 62.3c4 2.8 8.7 4.3 13.6 4.3 11.3 0 21.1-8 23.5-19.2l74.7-380.7c.9-4.4-.8-9-4.2-11.8-3.5-2.9-8.2-3.6-12.4-1.9l-459 186.8c-9.3 4.2-15 13.4-14.4 23.5zm226 168.9l-4.1-78.1 46 31.8-41.9 46.3zm165.4 9.5l-163.8-113.4 229.7-222.2-65.9 335.6zm40.7-345.4l-227.1 219.7-179.4-54.2 406.5-165.5z"
      fill={props.color || 'currentColor'}
    />
  </svg>
);

export default Send;
