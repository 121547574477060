import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import history from '../../../history';
import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/Logo/Logo_Orange.json';

import {
  Paper,
  Typography,
  Button,
  Grid,
  Slide,
  Fade,
  Card
} from '@material-ui/core';

// Styled Components
import { PaperWrapper, AddFirstMemoryButton } from '../Onboarding.style';

import { MEMORIES_URL, DASHBOARD_URL, CREATE_MEMORY_URL, GROUPS_CREATE_URL } from '../../../constants/constantUrls'

// Custom components
import { ImageButton } from '../../ImageButton/ImageButton';
import { ReactComponent as GiftIcon } from '../../../assets/gift.svg';
import { Divider } from '../../Divider/Divider';

export function OnboardingStep6({
  ...props
}) {
  /* Transition States */
  const [cardTransition, setCardTransition] = useState(false);
  const [titleTransition, setTitleTransition] = useState(false);
  const [descriptionTransition, setDescriptionTransition] = useState(false);
  const [inputTransition, setInputTransition] = useState(false);
  const [buttonTransition, setButtonTransition] = useState(false);

  /* Scroll Anchor */
  const buttonRef = useRef();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  /* Transition Timings */
  useEffect(() => {
    setTimeout(() => {
      setCardTransition(true);
    }, 500);
    setTimeout(() => {
      setTitleTransition(true);
    }, 1000);
    setTimeout(() => {
      setDescriptionTransition(true);
    }, 1500);
    setTimeout(() => {
      setInputTransition(true);
    }, 2000);
    setTimeout(() => {
      setButtonTransition(true);
    }, 2500);
  });

  return (
    <Slide direction="up" in={cardTransition} timeout={500}>

        <Paper style={{ padding: window.innerWidth < 600 ? '1rem' : '2rem', width:window.innerWidth < 800 ? "90%" :'50%', margin: window.innerWidth < 600 ? '1rem' : '2rem'}}>
          <Fade in={titleTransition} timeout={1000}>
            <Typography variant="h4" color="primary" style={{fontSize: window.innerWidth < 600 ? '1.7rem' : '2rem', textAlign: window.innerWidth < 600 ? 'center' :'left'}}>
              Let's Set Up Your Group
            </Typography>
          </Fade>
          <br />
          <Fade in={descriptionTransition} timeout={1000}>
            <>
              <Typography margin="normal" variant="body1" style={{textAlign: window.innerWidth < 600 ? 'center' :'left'}}>
                We noticed you came to create a group!
                <br/><br/>
                </Typography>
                <Typography margin="normal" variant="body1" style={{textAlign: window.innerWidth < 600 ? 'center' :'center'}}>

                <iframe width="100%" height="415" src="https://www.youtube.com/embed/O2xOIUgf0yo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style={{}}></iframe>
                <br/><br/>
                Click the button below to get started.
   
      </Typography>
          
              {/* <Lottie
                options={defaultOptions}
                height={400}
                width={400}
              /> */}
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '1.5rem' }} >
                <NavLink
                  to={{
                    pathname: GROUPS_CREATE_URL,
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{
            height: "3rem",
            fontSize: "1.1rem",
            fontWeight: "bold",
            borderRadius: "100px",
            boxShadow: "rgba(0, 0, 0, 0.95) 0px 25px 20px -20px",
            maxWidth: "432px",
          }}
                    onClick
                  >
                    Create A Group
                  </Button>
                </NavLink>
                <NavLink
                  to={{
                    pathname: DASHBOARD_URL,
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="text"
                    color="primary"
                    style={{marginTop: '0.5rem', textDecoration:'underline' }}
                  >
                    Go to your timeline
                  </Button>
                </NavLink>
              </div>
            </>
          </Fade>
        </Paper>

    </Slide>
  );
}
