import axios from 'axios';

import { API_BONUS_URL, API_BONUS_DETAILS_URL } from '../constants/apiRoutes';

import makeError from './makeError';

const getUserBonuses = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${API_BONUS_URL}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const getBonusDetails = async ({ bonusId, options }) => {
  try {
    const { data } = await axios.get(`${API_BONUS_DETAILS_URL}/${bonusId}`);
    return { data };
  } catch (error) {
    // A little ugly but it works
    if (error.response.data.error.includes('Cast to ObjectId failed')) {
      return { data: false };
    }
    return { error: makeError(error, options) };
  }
};

const claimBonus = async ({ bonusId, userId, options }) => {
  try {
    const { data } = await axios.post(`${API_BONUS_URL}/${userId}/${bonusId}`);
    if (data) {
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const bonus = {
  getUserBonuses,
  getBonusDetails,
  claimBonus,
};

export default bonus;
