import styled from 'styled-components';
import { breakpointsMax } from '../../../../constants/breakPoints';

export const Main = styled.main`
  display: flex;
  margin: 64px auto 0;
  flex-direction: column;
  min-height: 100vh;
  background-color: #FCF8F7;

  @media (max-width: 600px) {
    margin: 56px auto 0;
  }

  @media ${breakpointsMax.tablet} {
    .friend-card-block {
      height: 100% !important;
    }
  }

  .custom_swal_z {
    z-index: 2000
  }
`;