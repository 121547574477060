// React
import React from 'react';
import PropTypes from 'prop-types';

// Swal
// import Swal from 'sweetalert2';

// MUI
import {
  Paper,
  Typography,
  Button,
  IconButton,
  TextField,
  Grid,
  Collapse,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

// MUI Date
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// MUI Icons
import CloseIcon from '@material-ui/icons/Close';
import LightbulbIcon from '@material-ui/icons/EmojiObjects';
import EventIcon from '@material-ui/icons/Event';

// Custom Components
import { ImageButton } from '../../../ImageButton/ImageButton';
import { Divider } from '../../../Divider/Divider';

// Images
import Birthday from './images/birthday.jpg';
import Anniversary from './images/anniversary.jpg';
import Holiday from './images/holiday.jpg';
import Relocation from './images/relocation.jpg';

// Styled Components
import { MilestoneMemoriesStyles } from './MilestoneMemories.style';

const images = { Birthday, Anniversary, Holiday, Relocation };

const milestoneCategoryDescriptions = {
  Birthday: {
    title: 'Write about one of your birthdays',
    description: (
      <ul>
        <li>
          <span role="img" aria-label="next-arrow">
            ➡️
          </span>{' '}
          Was this your own birthday or someone else&apos;s?
        </li>
        <li>
          <span role="img" aria-label="next-arrow">
            ➡️
          </span>{' '}
          Perhaps it was the birthday of on of your kids/grandkids?
        </li>
        <li>
          <span role="img" aria-label="next-arrow">
            ➡️
          </span>{' '}
          Was it a landmark birthday?
        </li>
        <li>
          <span role="img" aria-label="next-arrow">
            ➡️
          </span>{' '}
          Did you give/receive any big or thoughtful gifts?
        </li>
        <li>
          <span role="img" aria-label="next-arrow">
            ➡️
          </span>{' '}
          Did you spend quality time together with your family, or have a night
          out with your best friends?
        </li>
        <li>
          <span role="img" aria-label="next-arrow">
            ➡️
          </span>{' '}
          Perhaps it was a relaxing evening with your partner?
        </li>
        <li>
          <span role="img" aria-label="next-arrow">
            ➡️
          </span>{' '}
          It could even be a birthday you spent on your own!
        </li>
      </ul>
    ),
  },
  Anniversary: {
    title: 'Write about an anniversary',
    description:
      'Was it one for a relationship? An engagement? A wedding? Was it was a simple and quiet evening spent at home, or a more special treat? Were there any special gifts or surprises? What was the most romantic thing you did for your partner? What about the most romantic thing they did for you?',
  },
  Holiday: {
    title: 'Write about one of your memorable holidays',
    description:
      'Was it a quick weekend getaway? A long-distance road trip? A holiday that was close to home, or abroad? Did you travel with friends, with family, or on your own?',
  },
  Relocation: {
    title: 'Write about a time when you relocated',
    description:
      'Did you move for university? Perhaps you moved abroad for the first time? Maybe you moved in with friends or with your partner? Or you bought a new home?',
  },
};

export const MilestoneMemories = ({
  isModal = false,
  isEditing = false,
  categoryVal = '',
  setCategoryVal = () => {},
  title = '',
  setTitle = () => {},
  dateVal = new Date(),
  setDateVal = () => {},
  descriptionVal = '',
  setDescriptionVal = () => {},
  closeButtonFunc = () => {},
  submitButtonFunc = () => {},
  updateButtonFunc = () => {},
  deleteButtonFunc = () => {},
  ...props
}) => {
  const makeImageButtonProps = textVal => ({
    image: images[textVal],
    text: textVal,
    onClick: () => setCategoryVal(textVal),
    isHighlighted: categoryVal === textVal,
  });

  return (
    <MilestoneMemoriesStyles>
      <Paper
        elevation={3}
        style={{
          maxWidth: '800px',
          margin: '0 auto 5rem auto',
          padding: '2rem',
          height: '100%',
          maxHeight: isModal ? '600px' : 'none',
          overflow: isModal ? 'scroll' : 'none',
        }}
        {...props}
      >
        <Grid container spacing={2}>
          <Grid item xs={isModal ? 11 : 12}>
            <Typography variant="h4" gutterBottom align="center">
              {isEditing ? `Edit "${title}"` : 'Create a Milestone'}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              aria-label="close"
              onClick={closeButtonFunc}
              style={{ display: !isModal && 'none' }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        {!isEditing && (
          <div className="milestoneInfoText">
            <Alert variant="filled" severity="info">
              Milestones are key dates in your life that punctuate your
              timeline.
              <br />
              These don&apos;t count against your memory allocation, so you can
              make as many Milestones as you like.
            </Alert>
            <br />
            <br />
          </div>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Select your milestone:</Typography>
            <br />

            <Grid container spacing={1}>
              <Grid item xs={6} sm={3}>
                <ImageButton {...makeImageButtonProps('Birthday')} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ImageButton {...makeImageButtonProps('Anniversary')} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ImageButton {...makeImageButtonProps('Holiday')} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ImageButton {...makeImageButtonProps('Relocation')} />
              </Grid>
            </Grid>
            <br />

            <Collapse in={categoryVal}>
              <Alert
                severity="info"
                variant="outlined"
                iconMapping={{ info: <LightbulbIcon fontSize="inherit" /> }}
              >
                <AlertTitle style={{ fontWeight: 'bold' }}>
                  {milestoneCategoryDescriptions?.[categoryVal]?.title}
                </AlertTitle>
                {milestoneCategoryDescriptions?.[categoryVal]?.description}
              </Alert>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Divider logo />
            <Typography>
              {isEditing ? 'Edit title' : 'Give it a title'}
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              id="titleInput"
              placeholder="Enter your title here"
              value={title}
              onChange={e => setTitle(e.target.value)}
              style={{
                marginTop: '1rem',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider logo />
            <Typography>
              {isEditing ? 'Edit date' : 'When did it happen?'}
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                inputVariant="outlined"
                margin="normal"
                id="datePicker"
                format="dd/MM/yyyy"
                openTo="year"
                value={dateVal}
                onChange={setDateVal}
                keyboardIcon={
                  <>
                    <p style={{ fontSize: '0.85rem' }}>Click Me</p>&nbsp;
                    <EventIcon />
                  </>
                }
                InputAdornmentProps={{
                  position: 'end',
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <Divider logo />
            <Typography>
              {isEditing ? 'Edit description' : 'Tell us about it'}
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              id="nameInput"
              multiline
              rows={3}
              value={descriptionVal}
              placeholder="Describe your milestone"
              helperText={
                descriptionVal.length >= 150
                  ? `${descriptionVal.length}/500`
                  : ``
              }
              onChange={e => setDescriptionVal(e.target.value.slice(0, 500))}
              style={{
                marginTop: '1rem',
              }}
            />
          </Grid>
        </Grid>
        <br />

        <Button
          className="button"
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          onClick={isEditing ? updateButtonFunc : submitButtonFunc}
        >
          {isEditing ? 'Edit Milestone' : 'Add Milestone'}
        </Button>
        {isEditing && (
          <Button
            className="deleteButton button"
            size="large"
            color="inherit"
            variant="contained"
            fullWidth
            onClick={deleteButtonFunc}
          >
            Delete milestone
          </Button>
        )}
        <Button
          className="button"
          size="large"
          variant="outlined"
          color="primary"
          fullWidth
          onClick={closeButtonFunc}
        >
          {isEditing ? 'Cancel' : `← Back`}
        </Button>
      </Paper>
    </MilestoneMemoriesStyles>
  );
};

MilestoneMemories.propTypes = {
  /**
   * Enter a description for each property above it like so
   */
  text: PropTypes.string,
};

MilestoneMemories.defaultProps = {
  text: 'hello',
};
