import React from 'react';

const ZigzagLine = ({ width, color, theme }) => (
  <svg
    width={width}
    height="12"
    viewBox="0 0 47 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="45.3282"
      y1="2.97078"
      x2="37.665"
      y2="10.6339"
      stroke="hsl(222, 80%, 35%)"
      strokeWidth="2"
    />
    <line
      x1="30.2652"
      y1="1.82024"
      x2="37.9288"
      y2="9.48379"
      stroke="hsl(222, 80%, 35%)"
      strokeWidth="2"
    />
    <line
      x1="30.5491"
      y1="2.70711"
      x2="22.886"
      y2="10.3703"
      stroke="hsl(222, 80%, 35%)"
      strokeWidth="2"
    />
    <line
      x1="15.4862"
      y1="1.55657"
      x2="23.1497"
      y2="9.22011"
      stroke="hsl(222, 80%, 35%)"
      strokeWidth="2"
    />
    <line
      x1="15.7703"
      y1="2.70711"
      x2="8.10718"
      y2="10.3703"
      stroke="hsl(222, 80%, 35%)"
      strokeWidth="2"
    />
    <line
      x1="0.707351"
      y1="1.55657"
      x2="8.3709"
      y2="9.22011"
      stroke="hsl(222, 80%, 35%)"
      strokeWidth="2"
    />
  </svg>
);

export default ZigzagLine;
