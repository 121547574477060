import styled from 'styled-components';
import { IternalTheme } from '../../--Theme/IternalThemeLight';

export const FourOhFourWrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 650px;
  margin: 0 auto !important;
  padding-top: 5rem;

  .titleSection {
    width: 30%;
    margin: 0 auto;
    color: ${IternalTheme.palette.primary};
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.015);
    border-radius: 0.5rem;
  }

  .descriptonSection {
    font-size: 1.5rem;
    color: ${IternalTheme.palette.common.slate};
    padding: 2rem;
  }

  .timerSection {
    padding: 2rem;
    font-size: 1rem;
    background: rgba(0, 0, 0, 0.01);
    border-radius: 0.5rem;
    line-height: 2.5;
  }

  .homeButton {
    margin: 1rem;
  }
`;
