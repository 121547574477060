import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

// NOTE: I could not get the global variables to work in the tertiary statements
export const Link = styled(RouterLink)`
  color: ${props =>
    props.secondary ? '#e8529b' : props.black ? '#000' : '#66CC66'}
  font-weight: 700;
  text-decoration: underline ${props =>
    props.secondary ? '#e8529b' : props.black ? '#000' : '#66CC66'}
`;
