import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { MEMORIES_URL } from '../../../constants/constantUrls';
import { getDateSegments } from '../../../helpers/formatDate';

import {
  MemoryWrapper,
  TitleAndDate,
  DateWrapper,
  Title,
  TitleWrapper,
  TagWrapper,
  LinkWrapper,
  EndWrapper,
  Draft,
} from './style';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const MemoriesTable = ({ memories, viewingFriendsMemory, noLogin, profile={} }) =>
  memories.map(({ _id, title, date, dateType, isDraft, isPublic }, i) => {
    const { day, month, year } = getDateSegments(date);
    return (
      <Fragment key={_id}>
        {!noLogin ? (
          <Link
            to={{
              pathname: `${MEMORIES_URL}/${_id}`,
              state: {
                details: memories[i],
                profile,
                viewingFriendsMemory,
              },
            }}
            style={{ textDecoration: 'none', width: '100%' }}
          >
            {/* <Line /> */}
            <MemoryWrapper>
              <TitleAndDate>
                {dateType === "age" ? (
                  <DateWrapper>
                    <span>{year}</span>
                  </DateWrapper>
                ) : (
                  <DateWrapper>
                    <span>{day}</span>
                    <span>{months[month - 1]}</span>
                    <span>{year}</span>
                  </DateWrapper>
                )}
              </TitleAndDate>
              <TitleWrapper>
                  <Title>{title}</Title>
              </TitleWrapper>
              <EndWrapper>
                <TagWrapper>
                  {isDraft ? <span class="tag">Draft</span> : ""}
                  {isPublic ? <span class="tag is-success">Public</span> : ""}
                </TagWrapper>
                <LinkWrapper>
                  View
                </LinkWrapper>
              </EndWrapper>
            </MemoryWrapper>
          </Link>
        
          ) : (
          <Link
            to={{
              pathname: `/share/memory/${_id}`,
              state: {
                details: memories[i],
                profile,
                viewingFriendsMemory,
              },
            }}
            style={{ textDecoration: 'none', width: '100%' }}
          >
            {/* <Line /> */}
            <MemoryWrapper>
              <TitleAndDate>
                <DateWrapper >
                  {isDraft && <Draft>Draft</Draft>}
                    <span>{day}</span>
                    <span>{months[month - 1]}</span>
                    <span>{year}</span>
                </DateWrapper>
              </TitleAndDate>
              <TitleWrapper>
                  <Title>{title}</Title>
              </TitleWrapper>
              <EndWrapper>
                <TagWrapper>
                  {isPublic ? <span class="tag is-success">Public</span> : ""}
                </TagWrapper>
                <LinkWrapper>
                  View
                </LinkWrapper>
              </EndWrapper>
            </MemoryWrapper>
          </Link>
          )
        }

      </Fragment>
    );
  });

export default MemoriesTable;
