import axios from 'axios';
import Swal from 'sweetalert2';

import {
  API_COMMENTS_URL,
} from '../constants/apiRoutes';

import makeError from './makeError';

const getCommentById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${API_COMMENTS_URL}/${id}`);
    
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

// get all memories created by this user
const getMemoryComments = async ({ memory, options }) => {
  try {
    const { data } = await axios.get(`${API_COMMENTS_URL}/${memory}`, {
      params: { memory },
    });
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const createComment = async ({ comment, options }) => {
  const {
    text,
    memoryId,
    approval,
  } = comment;

  try {
    const { data } = await axios.post(`${API_COMMENTS_URL}`, {
      text,
      memoryId,
      approval,
    });

    if (data) {
      Swal.fire({
        title: "You've added to the memory!",
        type: 'success',
        timeout: 3000,
      });
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const updateComment = async ({ comment, options }) => {
  const {
    text,
    memoryId,
    approval,
    commentId,
    userId
  } = comment;

  try {
    const { data } = await axios.patch(`${API_COMMENTS_URL}`, {
        text,
        memoryId,
        approval,
        commentId,
        userId
    });
    if (data) {
      Swal.fire({
        title: 'Your addition has been updated!',
        type: 'success',
        timeout: 3000,
      });
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const deleteComment = async ({ id, options }) => {
  try {
    const { data } = await axios.delete(`${API_COMMENTS_URL}/${id}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const comments = {
    getCommentById,
    getMemoryComments,
    createComment,
    updateComment,
    deleteComment,
};

export default comments;
