import React, { useState, useEffect } from 'react';
import axios from 'axios';
import history from '../../../history';
import { PageBackground, StepsWrapper } from './style';
import { CREATE_MEMORY_URL } from '../../../constants/constantUrls';
import { API_UPDATE_USERINFO_URL, API_ONBOARDING_STEP_URL } from '../../../constants/apiRoutes';
import { milestones, giftMemory } from '../../../api';

// New Onboarding
import { OnboardingStep1 } from '../../../NewComponents/OnboardingSteps/Step 1/OnboardingStep1';
// import { OnboardingStep2 } from '../../../NewComponents/OnboardingSteps/Step 2/OnboardingStep2';
import { OnboardingStep3 } from '../../../NewComponents/OnboardingSteps/Step 3/OnboardingStep3';
import { OnboardingStep4 } from '../../../NewComponents/OnboardingSteps/Step 4/OnboardingStep4';
import { OnboardingStep5 } from '../../../NewComponents/OnboardingSteps/Step 5/OnboardingStep5';
import { OnboardingStep6 } from '../../../NewComponents/OnboardingSteps/Step 6/OnboardingStep6';
import ReactGA from 'react-ga';

export default function OnboardingFlow(props, location) {
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [birthday, setBirthday] = useState(new Date);
  const [category, setCategory] = useState('');
  const [hometown, setHometown] = useState('');
  const [giftMemories, setGiftMemories] = useState([]);
  const [isGroup, setIsGroup] = useState(false);
  const [prompt, setPrompt] = useState({});
  const [profilePicture, setProfilePicture] = useState('');
  // const [accountType, setAccountType] = useState('');
  // Note: Location must be destructured before accessing
  const { competition, groups } = props;

  // Check if this registration is a referral
  // if (isReferral) {
  //   cards[0].referralId = shortId;
  // }
  // Advance steps
  useEffect(() => {
    getGiftMemories();
    getIsGroupSignup();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []); 

  useEffect(() => {
    
  }, [prompt]);

  const getGiftMemories = async () => {
    const { data } = await giftMemory.getGiftMemories();
    setGiftMemories(data);
  }

  const getIsGroupSignup = () => {
    if (groups) {
      setIsGroup(true);
    }
  }

  const nextStep = () => {
    axios.patch(API_ONBOARDING_STEP_URL, { step });
    ReactGA.event({
      category: 'Onboarding',
      action: `Step ${step}`,
    });
    setStep(step + 1);
  };

  const skipStep = () => {
    axios.patch(API_ONBOARDING_STEP_URL, { step: 4 });
    setStep(4);
  }
  
  const groupStep = () => {
    axios.patch(API_ONBOARDING_STEP_URL, { step: 5 });
    setStep(5);
  }

  // Set category and scroll to button
  const changeCategory = (category) => {
    setCategory(category);
    // setTimeout(()  => {
    //   buttonRef.current.scrollIntoView({ behavior: 'smooth' });
    // }, 250);
  };

  // Validate name and birthday, and submit
  async function handleSubmit() {
    try {
      // check if user already has profile (if not create one)
      const hasProfile = props.profile && Object.keys(props.profile).length > 0;
      const updateProfile = await axios.post(API_UPDATE_USERINFO_URL, {
        state: { name, birthday, hometown },
        hasNoProfile: !hasProfile,
      });
      if(name) {
        props.handleChangeState({name: name});
      }
      // if profile picture, update profile picture
      // put imagepath to database
      if (profilePicture.length > 0) {
        await axios.put(API_UPDATE_USERINFO_URL, {
          imagePath: profilePicture,
        });
      }
      if (updateProfile) {
        console.log('Updated');
        // const milestone = await milestones.createMilestone({
        //   milestone: {
        //     title: "The day I joined Iternal",
        //     category: "Unique Moment",
        //     date: new Date(),
        //   },
        //   popup: false,
        // })
      }
      if (isGroup) {
        groupStep();
        return
      }
      if (giftMemories.length > 0) {
        skipStep();
      } else {
        nextStep();
      }
      
    } catch (err) {
      console.error(`ERR: Save error ${err}`);
    }
  }

  return (
    <PageBackground>
      <StepsWrapper>
        {step === 1 ? <OnboardingStep1 nextFunc={nextStep} competition={competition} /> : null}
        
        {/* {step === 2 ? (
          <OnboardingStep2 
            accountTypeVal={accountType} 
            setAccountTypeVal={setAccountType} 
            nextFunc={nextStep} 
          />
        ) : null} */}

        {step === 2 ? (
          <OnboardingStep3
          
            nameVal={name}
            setNameVal={setName}
            dateVal={birthday}
            setDateVal={setBirthday}
            hometownVal={hometown}
            setHometownVal={setHometown}
            profilePicture={profilePicture}
            setProfilePicture={setProfilePicture}
            nextFunc={e => handleSubmit(e)}
            props={props}
          />
        ) : null}

        {step === 3 ? (
          <OnboardingStep4
            category={category}
            changeCategory={changeCategory}
            prompt={prompt}
            setPrompt={setPrompt}
            pathToNextStep="/create-memory"
            competition={competition}
          />
        ) : null}

        {step === 4 ? (
          <OnboardingStep5
            giftMemories={giftMemories}
          />
        ) : null}

        {step === 5 ? (
          <OnboardingStep6 />
        ) : null}
      </StepsWrapper>
    </PageBackground>
  );
}
