import React from 'react';

// MUI
import CircularProgress from '@material-ui/core/CircularProgress';

// Styled components
import {
  LoadingTextStyle,
  LoaderStyle,
  TitleAnimationWrapper,
} from './Loading.style';

export function Loading() {
  return (
    <>
      <TitleAnimationWrapper>
        <h1 style={LoadingTextStyle}>Loading</h1>
      </TitleAnimationWrapper>
      <LoaderStyle>
        <CircularProgress
          color="secondary"
          variant="indeterminate"
          size={300}
          thickness={12}
        />
      </LoaderStyle>
    </>
  );
}
