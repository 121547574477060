import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Icon from '../../Common/Icon/Icon';
import deleteIcon from '../../../assets/delete-cross.svg';
import { CHAT_URL, ABOUT_URL } from '../../../constants/constantUrls';
import {
  API_DELETE_ANSWER_URL,
  API_DELETE_ANSWER_FLOW_URL,
} from '../../../constants/apiRoutes';
import history from '../../../history';

import * as S from '../ProfileAbout/style';

export default class AboutSection extends Component {
  state = { answers: [], sectionDetails: {}, loading: true };

  componentDidMount() {
    this.updateState();
  }

  // update state
  updateState = () => {
    const propState = this.props.location.state;
    if (propState === undefined) {
      return history.push(ABOUT_URL);
    }
    const { sectionDetails } = propState;
    const { flow } = sectionDetails;
    this.setState({ answers: flow, sectionDetails, loading: false });
  };

  // gives user option to delete answer
  handleDelete = ids => {
    try {
      ids.forEach(id => {
        axios.delete(API_DELETE_ANSWER_URL, { data: { id } });
      });
      return Swal.fire({
        title: 'Question deleted!',
        type: 'success',
      }).then(() => this.updateState());
    } catch (err) {
      return Swal.fire({
        title: 'Error deleting Answer!',
        type: 'warning',
      });
    }
  };

  handleDeleteFlow = id => {
    Swal.fire({
      title: 'Wanna delete this question?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E82C48',
      confirmButtonText: 'Yes, delete',
    })
      .then(result => {
        if (result.value) {
          return axios.delete(API_DELETE_ANSWER_FLOW_URL, { data: { id } });
        }
        return null;
      })
      .then(result => {
        if (result) {
          this.setState({
            answers: this.state.answers.filter(({ _id }) => _id !== id),
          });
        }
      });
  };

  // handles pop up interaction
  handleSwal = (array, id) => {
    Swal.fire({
      title: 'Wanna delete this question?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#66CC66',
      cancelButtonColor: '#8a8787',
      confirmButtonText: 'Yes, delete',
    }).then(result => {
      if (result.value) {
        this.handleDelete(this.getIdsDelete(array, id));
      }
    });
  };

  // checks which message ids should be deleted
  getIdsDelete = (msgArray, questionId) => {
    // get index of target question (the one user selects to delete)
    const targetQuestionIdx = msgArray
      .map((msg, idx) => (msg._id === questionId ? idx : null))
      .filter(idx => idx !== null)[0];

    // get question idxs of whole array
    const questionIdxs = msgArray
      .map((msg, idx) =>
        msg.sender === 'bot' && msg.text[msg.text.length - 1] === '?'
          ? idx
          : null,
      )
      .filter(q => q !== null);
    // get area to splice array beginning with target question
    // ending is either next question or (in case no next question exists) end of array
    const end = questionIdxs.map((q, idx, arr) =>
      q === targetQuestionIdx && arr[idx + 1] ? arr[idx + 1] : -1,
    )[0];

    // splice array with target question and end before next question or end of array
    const idsToDelete = msgArray
      .splice(targetQuestionIdx, end - 1 > -1 ? end - 1 : msgArray.length)
      .map(msg => msg._id);
    return idsToDelete;
  };

  // renders questions and answers
  renderConversation = msgArray =>
    msgArray.map((el, idx) => {
      // check if question and push all answers until next one
      if (el.sender === 'bot' && el.text[el.text.length - 1] === '?') {
        return (
          <Fragment key={idx}>
            <S.QuestionWrapper>
              <S.QuestionTitle>{el.text}</S.QuestionTitle>
              <S.Button onClick={() => this.handleSwal(msgArray, el._id)}>
                <img alt="Delete Question" src={deleteIcon} />
              </S.Button>
            </S.QuestionWrapper>
          </Fragment>
        );
      }
      // if user -> render answer
      if (el.sender === 'user') {
        return (
          <Fragment key={idx}>
            <S.AnswerWrapper>
              <S.Answer>{el.text}</S.Answer>
            </S.AnswerWrapper>
          </Fragment>
        );
      }
      return null;
    });

  // renders flow sections and uses renderConversation function to render chat
  renderSectionDetails = () => {
    const { answers } = this.state;

    return answers.map(({ flowName, messages, _id, ...data }, idx) => {
      // filters bot messages that are no questions -> not relevant for rendering
      messages.sort((el1, el2) => new Date(el1.time) - new Date(el2.time));

      const filteredMsg = this.filterMsgArr(messages);
      return (
        <S.AboutSectionContainer key={idx}>
          {filteredMsg.length > 0 ? (
            <>
              <S.Line />
              <S.TitleWrapper>
                <S.Title>{flowName}</S.Title>
                <S.Button onClick={() => this.handleDeleteFlow(_id)}>
                  <img alt="Delete Question" src={deleteIcon} />
                </S.Button>
              </S.TitleWrapper>
            </>
          ) : (
            ''
          )}
          {this.renderConversation(messages)}
        </S.AboutSectionContainer>
      );
    });
  };

  // deletes all irrelevant messages for rendering
  // -> bot responses that are no questions
  filterMsgArr = messages =>
    messages.filter(msg => {
      const question =
        msg.sender === 'bot' && msg.text[msg.text.length - 1] === '?';
      return msg.sender !== 'bot' && !question;
    });

  renderAddMore = () => (
    <>
      {' '}
      <S.P>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.{' '}
        <a href={CHAT_URL}>Add to your profile here</a>
      </S.P>
    </>
  );

  render() {
    const { loading } = this.state;
    if (loading) {
      return <h1>Loading</h1>;
    }

    const { sectionDetails } = this.state;
    const { flow } = sectionDetails;
    const { messages } = flow[0];
    const filteredMessages = this.filterMsgArr(messages);

    return (
      <>
        <S.Main>
          <S.H1>
            {sectionDetails._id}{' '}
          </S.H1>
          {filteredMessages.length > 0
            ? this.renderSectionDetails()
            : this.renderAddMore()}
          <S.Button
            onClick={() => {
              history.push(ABOUT_URL);
            }}
          >
            <Icon icon="back" height="2.5em" color="var(--secondary)" />
          </S.Button>
        </S.Main>
        {/* <S.Nav>
          <ProfileMenu />
        </S.Nav> */}
      </>
    );
  }
}
