// React
import React from 'react';
import PropTypes from 'prop-types';

// MUI
import { Paper, Typography, Button, ButtonBase } from '@material-ui/core';
import LockedFeatureIcon from '@material-ui/icons/Lock';

// Styles
import { FeaturesBlockStyles } from './Features.style';

export const Features = ({ featureCards, userHasPremium, ...props }) => {
  const scroll = direction => {
    const container = document.querySelector('.featureContainer');
    const value = direction === 'left' ? -170 : 170;
    container.scrollBy({
      left: value,
      behavior: 'smooth',
    });
  };

  return (
    <FeaturesBlockStyles>
      <Paper className="paper" elevation={3} {...props}>
        <Typography variant="h4" align="center">
          Features
        </Typography>
        <div className="scrollButtonsContainer" container>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              scroll('left');
            }}
          >
            &larr;
          </Button>

          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              scroll('right');
            }}
          >
            &rarr;
          </Button>
        </div>

        <div className="featureContainer">
          {featureCards.map(el => {
            const lockedPremiumFeature = el.premiumFeature && !userHasPremium;

            return (
              <a href={el.url}>
                <ButtonBase
                  className="featureCard"
                  style={{
                    background: `${
                      lockedPremiumFeature ? 'rgba(0,0,0,0.10)' : el.background
                    }`,
                  }}
                >
                  <div className="icon">
                    {lockedPremiumFeature ? (
                      <LockedFeatureIcon style={{ opacity: '0.30' }} />
                    ) : (
                      el.icon
                    )}
                  </div>
                  <Typography
                    style={{ opacity: lockedPremiumFeature ? '0.3' : '1' }}
                    className="text"
                  >
                    {el.text}
                  </Typography>
                  <Typography className="CTAtext" variant="body1">
                    {lockedPremiumFeature ? 'Get Premium →' : 'Try it →'}
                  </Typography>
                </ButtonBase>
              </a>
            );
          })}
        </div>
      </Paper>
    </FeaturesBlockStyles>
  );
};

Features.propTypes = {
  /**
   * Pass an array of cards (containing `text`, `icon`, `background`, and `url`) for each feature
   */
  featureCards: PropTypes.arrayOf({
    text: PropTypes.string,
    icon: PropTypes.object,
    background: PropTypes.string,
    url: PropTypes.string,
    premiumFeature: PropTypes.bool,
  }),
  /**
   * Is the user premium?
   */
  userHasPremium: PropTypes.bool,
};

Features.defaultProps = {
  featureCards: [],
  userHasPremium: false,
};
