import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoints, breakpointsMax } from '../../../constants/breakPoints';

// export const PageWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   padding: 2rem 3rem 1rem 280px;
//   // background: linear-gradient(105deg, #0bafaa77, #1d6a9177, #0a215699) !important;

//   @media ${breakpointsMax.laptop} {
//     padding: 0.5rem;
//   }
// `;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem 1rem 280px;
  ${'' /* background: linear-gradient(90deg, #0A2156, #1d6a91,  #0bafaa) !important; */}

  @media ${breakpointsMax.laptop} {
    padding: 0.5rem;
  }
`;

export const HeadingWrapper = styled.div` 
  /* @media ${breakpoints.laptop} {

  } */
  text-align:center; 
`;

export const Heading = styled.h1`
  margin: 0rem 0rem;
  text-align: center;
  line-height: 44px;
`;

export const InfoLink = styled(Link)`
  display: block;
  font-weight: bold;
  font-size: 12px;
  text-decoration-line: underline;
  color: var(--secondary-aqua);
  margin-top: 1rem;
`;

export const Body = styled.div`
  /* display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center; */
`;

export const FooterWrapper = styled.footer`
  position: fixed;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  right: 0;
  left: 0;
  height: 4.25rem;
  border-top: 1px solid var(--secondary-aqua);
  background: var(--neutral-white);

  @media ${breakpoints.laptop} {
    left: 22-rem;
  }
`;

export const HeadingDescription = styled.p`
  /* margin: 2rem auto; */
  text-align: center;
  margin-top: 1rem;
  /* margin-bottom:3rem;  */
`;
