import React from 'react';

const EditPencil = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8145 0.239796C18.7477 0.166464 18.6668 0.107425 18.5766 0.0662462C18.4864 0.0250673 18.3888 0.00260388 18.2897 0.000213241C18.1905 -0.0021774 18.092 0.0155543 17.9999 0.0523372C17.9078 0.08912 17.8241 0.14419 17.7539 0.214219L17.189 0.776453C17.1205 0.844969 17.082 0.93787 17.082 1.03473C17.082 1.1316 17.1205 1.2245 17.189 1.29301L17.7069 1.81003C17.7408 1.84414 17.7812 1.87121 17.8256 1.88968C17.8701 1.90814 17.9177 1.91765 17.9659 1.91765C18.014 1.91765 18.0616 1.90814 18.1061 1.88968C18.1505 1.87121 18.1909 1.84414 18.2248 1.81003L18.7756 1.26196C19.0542 0.983808 19.0803 0.530733 18.8145 0.239796Z"
      fill={props.color ? props.color : 'white'}
    />
    <path
      d="M16.0467 1.91784L7.80185 10.1481C7.75187 10.1979 7.71553 10.2597 7.69635 10.3276L7.31498 11.4635C7.30584 11.4943 7.3052 11.527 7.31311 11.5582C7.32102 11.5893 7.33719 11.6178 7.35992 11.6405C7.38265 11.6632 7.4111 11.6794 7.44226 11.6873C7.47342 11.6952 7.50613 11.6946 7.53695 11.6855L8.67192 11.3041C8.73982 11.2849 8.80163 11.2486 8.85142 11.1986L17.0817 2.95279C17.1578 2.87583 17.2005 2.77194 17.2005 2.66368C17.2005 2.55542 17.1578 2.45153 17.0817 2.37457L16.6272 1.91784C16.5502 1.841 16.4458 1.79785 16.337 1.79785C16.2282 1.79785 16.1238 1.841 16.0467 1.91784V1.91784Z"
      fill={props.color ? props.color : 'white'}
    />
    <path
      d="M15.453 6.6525L9.88591 12.2305C9.67074 12.4462 9.40629 12.6063 9.11541 12.6969L7.93248 13.0928C7.65174 13.1721 7.35494 13.1751 7.07266 13.1015C6.79038 13.0279 6.53284 12.8804 6.32656 12.6741C6.12028 12.4678 5.97274 12.2103 5.89913 11.928C5.82552 11.6457 5.82852 11.3489 5.90779 11.0682L6.30378 9.88523C6.39409 9.59444 6.55383 9.33 6.76919 9.11473L12.3472 3.54674C12.3984 3.49567 12.4332 3.43058 12.4473 3.35971C12.4615 3.28884 12.4543 3.21536 12.4266 3.14858C12.399 3.0818 12.3522 3.02471 12.2921 2.98453C12.2321 2.94435 12.1614 2.92289 12.0892 2.92285H2.55768C1.87934 2.92285 1.22879 3.19232 0.749129 3.67198C0.26947 4.15164 0 4.8022 0 5.48054V16.442C0 17.1204 0.26947 17.7709 0.749129 18.2506C1.22879 18.7303 1.87934 18.9997 2.55768 18.9997H13.5192C14.1975 18.9997 14.8481 18.7303 15.3277 18.2506C15.8074 17.7709 16.0769 17.1204 16.0769 16.442V6.91056C16.0768 6.83829 16.0554 6.76765 16.0152 6.70758C15.975 6.64751 15.9179 6.60071 15.8511 6.57308C15.7844 6.54546 15.7109 6.53826 15.64 6.5524C15.5691 6.56654 15.5041 6.60137 15.453 6.6525Z"
      fill={props.color ? props.color : 'white'}
    />
  </svg>
);

export default EditPencil;
