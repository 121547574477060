import React from 'react';

const Calendar = ({ width, color, theme }) => (
  <svg
    width={width}
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 14.4375C12.5188 14.4375 13.75 13.2063 13.75 11.6875C13.75 10.1687 12.5188 8.9375 11 8.9375C9.48122 8.9375 8.25 10.1687 8.25 11.6875C8.25 13.2063 9.48122 14.4375 11 14.4375Z"
      fill='#222'
    />
    <path
      d="M18.5625 6.1875H16.0273C15.8984 6.1875 15.7386 6.10414 15.614 5.97266L14.4994 4.21352C14.4817 4.1856 14.4621 4.15903 14.4405 4.13402C14.0555 3.685 13.5352 3.4375 12.9766 3.4375H9.02344C8.46484 3.4375 7.94449 3.685 7.55949 4.13402C7.53793 4.15903 7.51825 4.1856 7.50062 4.21352L6.38602 5.97523C6.29062 6.07922 6.15656 6.19008 6.01562 6.19008V5.84633C6.01562 5.66399 5.94319 5.48912 5.81426 5.36019C5.68533 5.23126 5.51046 5.15883 5.32812 5.15883H4.29688C4.11454 5.15883 3.93967 5.23126 3.81074 5.36019C3.68181 5.48912 3.60938 5.66399 3.60938 5.84633V6.19008H3.4375C2.89067 6.19065 2.36639 6.40813 1.97972 6.7948C1.59305 7.18147 1.37557 7.70574 1.375 8.25258V16.5C1.37557 17.0468 1.59305 17.5711 1.97972 17.9578C2.36639 18.3444 2.89067 18.5619 3.4375 18.5625H18.5625C19.1093 18.5619 19.6336 18.3444 20.0203 17.9578C20.4069 17.5711 20.6244 17.0468 20.625 16.5V8.25C20.6244 7.70317 20.4069 7.17889 20.0203 6.79222C19.6336 6.40555 19.1093 6.18807 18.5625 6.1875ZM11 15.8125C10.1842 15.8125 9.38663 15.5706 8.70827 15.1173C8.02992 14.6641 7.50121 14.0198 7.189 13.2661C6.87679 12.5123 6.7951 11.6829 6.95426 10.8828C7.11342 10.0826 7.50629 9.34758 8.08318 8.77068C8.66008 8.19379 9.39508 7.80092 10.1953 7.64176C10.9954 7.4826 11.8248 7.56429 12.5786 7.8765C13.3323 8.18871 13.9766 8.71742 14.4298 9.39577C14.8831 10.0741 15.125 10.8717 15.125 11.6875C15.1237 12.7811 14.6888 13.8296 13.9154 14.6029C13.1421 15.3763 12.0936 15.8112 11 15.8125Z"
      fill='#222'
    />
  </svg>
);

export default Calendar;
