export const friendRelationships = [
  'Friend',
  'Close Friend',
  'Friend from School',
  'Friend from Work',
  'Acquaintance',
];
export const familyRelationships = [
  'Great-grandfather',
  'Great-grandmother',
  'Great-uncle',
  'Grandfather',
  'Grandmother',
  'Great-aunt',
  'Uncle',
  'Aunt',
  'Father',
  'Mother',
  'Uncle (Husband of Aunt)',
  'Sister',
  'Brother-in-law',
  'Brother',
  'Sister-in-law',
  'Husband',
  'Wife',
  'Cousin',
  'Cousin’s wife',
  'Cousin',
  'Cousin’s husband',
  'Nephew',
  'Niece',
  'Son',
  'Daughter-in-law',
  'Daughter',
  'Son-in-law',
  'First cousin once removed',
  'Grandson',
  'Granddaughter',
];
