import styled from 'styled-components';
import { breakpoints, breakpointsMax } from '../../../constants/breakPoints';

export const CardWrapper = styled.div`
  background-color: #fff;
  border-radius: 6px;
  color: #4a4a4a;
  display: block;
  padding: 0rem 2rem;
  text-align: left;
  /* width: 100%; */
  @media ${breakpointsMax.tablet} {
  }
`;

export const TitleFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${breakpointsMax.tablet} {
    display: block;
    button {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const CardLabel = styled.label``;

export const Card = styled.div`
  p {
    font-size: 1rem;
  }
`;

export const CardTitle = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: var(--neutral-dark);
  /* margin: 2rem auto 0.5rem; */
  @media ${breakpointsMax.tablet} {
    font-size: 22px;
  }
`;

export const CardParagraph = styled.p`
  margin: 1rem 0rem;
`;

export const InviteFriendWrapper = styled.div`
  .invite-friend-input {
    input {
      height: 46px;
    }
  }
`;

export const RequiredSpan = styled.span`
  font-weight: 500;
  font-size: 32px;
  color: var(--neutral-dark);
`;

export const CardDescription = styled.p`
  margin: 1rem 0rem 2rem;
  line-height: 1.5;
`;

export const Number = styled.div`
  font-weight: 200;
  font-size: 50px;
  color: #747373;
  margin-right: 1rem;
  display: none;

  @media ${breakpoints.laptop} {
    display: block;
  }
`;

export const Section = styled.section`
  margin: 0 auto;
  width: 100%;
  @media ${breakpointsMax.tablet} {
    .field.is-grouped {
      display: block !important;
    }
  }
`;

export const CardError = styled.p`
  color: var(--secondary-cherry);
  text-align: center;
  margin-top: 0.5rem;
  font-size: 1rem;
  margin-left: 1rem;
`;

export const ButtonDesktopWrapper = styled.div`
  @media ${breakpointsMax.tablet} {
    display: none;
  }
`;

export const ButtonMobileWrapper = styled.div`
  display: none;
  @media ${breakpointsMax.tablet} {
    display: block;
    text-align: center;
    button {
      text-align: center;
    }
  }
`;
