// React
import React from 'react';
import PropTypes from 'prop-types';

// React Router
import { NavLink } from 'react-router-dom';

// MUI
import { Paper, Typography, Grid, Button , Avatar} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TextMemoryIcon from '@material-ui/icons/Subject';
import PhotoMemoryIcon from '@material-ui/icons/Photo';
import VideoMemoryIcon from '@material-ui/icons/Videocam';
import MixedMediaMemoryIcon from '@material-ui/icons/PermMedia';


import { NoContentWrapper } from './FriendsActivity.style';
import { formatCleanDate } from '../../../../helpers/formatDate';

// Image Placeholders
import imagePlaceHolder from '../../../../assets/person-placeholder.jpg';
import FriendsActivityMiroodle2 from '../../../../assets/miroodles/friends_activity_2.svg';

export const FriendsActivity = ({
  friendsActivity = [],
  memoriesLink = '',
  friendTimelineLink = '',
  ...props
}) => {
  const generateUserInitials = userStr =>
    userStr
      .split(' ')
      .reduce((acc, cur) => acc + cur[0], '')
      .toUpperCase()
      .substring(0, 3);
  const renderIcon = (mem) => {
    const { images, videoRecording, voiceRecording, memory } = mem;

    // Has a custom icon type
    // if (icon) {
    //   return icon;
    // }
    // Has multiple media types
    if (
      images?.length > 0 &&
      videoRecording?.length > 0 &&
      voiceRecording?.length > 0
    ) {
      return <MixedMediaMemoryIcon style={{ fontSize: 'inherit' }} />;
    }
    // Has video only
    if (videoRecording?.length > 0) {
      return <VideoMemoryIcon style={{ fontSize: 'inherit' }}  />;
    }
    // Has photos only
    if (images?.length > 0) {
      return <PhotoMemoryIcon style={{ fontSize: 'inherit' }}  />;
    }
    // Has description only
    if (memory?.length > 0) {
      return <TextMemoryIcon style={{ fontSize: 'inherit' }}  />;
    }
    // Has none - render blank timeline dot
    return null;
  };

  return (
    <Paper
      elevation={3}
      style={{
        height: '100%',
      }}
      {...props}
    >
      <div style={{ padding: '1rem', textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Friends&apos; Memories
        </Typography>
        {friendsActivity.length > 0 ? (
          <div
            style={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              height: window.innerWidth >= 1280 ? '70vh' : '30vh',
              borderRadius: '0.25rem',
              // background: 'rgba(0,0,0,0.010)',
            }}
          >
            <ul style={{ padding: '1rem' }}>
              {friendsActivity.map((item, i) => (
                <Paper key={i} style={{ padding: '1rem', textAlign: 'left', marginBottom: '1rem', boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.2)' }}>
                  <Grid container spacing={1}>
                    <Grid item xs={3} sm={2} md={3}>
                      <NavLink 
                        to={{
                          pathname: `${friendTimelineLink.replace(
                            ':shortId',
                            item.shortId,
                          )}`,
                          state: {
                            details: item.memory.owner,
                            viewingFriendsMemory: true,
                          },
                        }}
                        style={{ 
                          textDecoration: 'none', 
                          color: 'black', 
                          width: '100%', 
                          textAlign: 'center', 
                          display: 'flex', 
                          flexWrap: 'wrap', 
                          alignContent: 'center', 
                          height: '100%',
                          flexDirection: 'column',
                          justifyContent: 'center'
                        }}
                      >
                      {/* <div style={{ display: 'inline-block' }}> */}
                        <Avatar
                          src={
                            item.image.imagePath
                          }
                          alt="Your profile avatar"
                          style={{ width: '50px', height: '50px', margin: '0 auto' }}
                        >
                          {item.name ? generateUserInitials(item.name) : 'U'}
                        </Avatar>

                          <Typography variant="body1" style={{ fontSize: '0.9rem', fontWeight: 'bold', margin: '0.2rem auto 0 auto' }}>
                            {item.name}
                          </Typography>
                        
                      {/* </div> */}
                      {/* <Grid container spacing={1}>
                        <Grid item xs={3} sm={2}>
                          <Avatar
                            src={
                              item.image.imagePath
                            }
                            alt="Your profile avatar"
                            style={{ margin: '0 auto' }}
                          >
                            {item.name ? generateUserInitials(item.name) : 'U'}
                          </Avatar>
                        </Grid>
                        <Grid item xs={9} sm={10}>
                          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                            {' '}{item.name}
                          </Typography>
                        </Grid>
                      </Grid> */}
                      </NavLink>
                    </Grid>
                    <Grid item xs={9} sm={10} md={9} style={{ background: 'white', padding: '0.5rem', display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', flexDirection: 'column', justifyContent: 'center'  }}>
                      <Typography variant="body1">{renderIcon(item.memory)}&nbsp;&nbsp;<span style={{ fontWeight: 'bold' }}>{item.memory.title}</span></Typography>
                      <Typography variant="body2">Created on:&nbsp;{formatCleanDate(item.memory.createdAt)}</Typography>
                      <NavLink
                        to={{
                          pathname: `${memoriesLink}/${item.memory._id}`,
                          state: {
                            details: item.memory,
                            viewingFriendsMemory: true,
                            profile: {picture: item?.image?.imagePath, name: item.name},
                          },
                        }}
                        style={{ textDecoration: 'none', width: '100%' }}
                      >
                        <Button 
                          variant="contained" 
                          color="secondary"
                          style={{ marginTop: '0.5rem', width: '100%', maxWidth: '200px' }}
                        >
                          View Memory
                        </Button>
                      </NavLink>
                    </Grid>
                  </Grid>
                  {/* <Link
                    to={{
                      pathname: `${memoriesLink}/${item.memory._id}`,
                      state: {
                        details: item.memory,
                        viewingFriendsMemory: true,
                      },
                    }}
                    style={{ textDecoration: 'none', width: '100%' }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={3} style={{ margin: 'auto' }}>
                        <figure style={{ textAlign: 'left' }}>
                          <div
                            className="bg_image"
                            style={{
                              backgroundImage: `url(${item.image.imagePath ||
                                imagePlaceHolder})`,
                              backgroundSize: 'cover',
                              height: '50px',
                              width: '50px',
                              borderRadius: '1000px',
                              backgroundPosition: 'center',
                              color: '#f5f5f5',
                            }}
                          />
                        </figure>
                      </Grid>
                      <Grid item xs={6} style={{ margin: 'auto' }}>
                        <p
                          style={{
                            textAlign: 'left',
                            marginBottom: '0.2rem',
                            color: 'var(--neutral-slate)',
                          }}
                        >
                          {item.name}
                        </p>
                        <p
                          style={{
                            textAlign: 'left',
                            color: 'var(--neutral-mist)',
                          }}
                        >
                          {item.memory.title}
                        </p>
                      </Grid>
                      <Grid item xs={3} style={{ margin: 'auto' }}>
                        <div>View</div>
                      </Grid>
                    </Grid>
                  </Link> */}
                </Paper>
              ))}
            </ul>
          </div>
        ) : (
          <NoContentWrapper>
            <img
              className="miroodleImage"
              src={FriendsActivityMiroodle2}
              alt={`Placeholder for your friends' activity`}
            />
            <Typography
              variant="body1"
              style={{ maxWidth: '60%', margin: '0 auto' }}
            >
              This is where you can see your friends&apos; activity - when they
              share stories with you, you will see them here
            </Typography>
          </NoContentWrapper>
        )}
      </div>
    </Paper>
  );
}



FriendsActivity.propTypes = {
  /**
   * Enter a description for each property above it like so
   */
  friendsActivity: PropTypes.array,
  memoriesLink: PropTypes.string,
};

FriendsActivity.defaultProps = {
  friendsActivity: [],
  memoriesLink: '',
};
