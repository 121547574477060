import styled from 'styled-components';
import { breakpoints, breakpointsMax } from '../../../constants/breakPoints';

export const PageWrapper = styled.div`
  padding: 30px 2rem 5rem 2rem;
  display: flex;
  flex-direction: column;
  /* Breaks words that are so long that they overflow the container element */

  @media ${breakpoints.laptop} {
    padding: 2rem 3rem 2rem 280px;
  }

  @media ${breakpoints.desktop} {
    padding-left: 35rem;
  }

  @media ${breakpointsMax.tablet} {
    padding: 1rem 0.5rem;
  }
`;

export const TextareaWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;

  textarea {
    margin-bottom: 10px;
  }
  button {
    text-align: right;
    margin-right: 0px;
  }
`;

export const CardWrapper = styled.div`
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  display: block;
  padding: 2.25rem;
  margin-bottom: 2rem;
  text-align: left;
  width: 100%;
  margin-bottom: 2rem;

  @media ${breakpointsMax.tablet} {
    padding: 1.25rem;
    .columns {
      width: 100% !important;
      margin: 0;
    }
  }
`;

export const HeadingWrapper = styled.div`
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 2rem 2rem 0.5rem;
  text-align: left;
  @media ${breakpointsMax.tablet} {
    h1 {
      margin-bottom: 1rem;
    }
  }

  /* border-bottom:1px solid #C4C4C4; */
`;

export const CommentHeadingWrapper = styled.div`
  padding-bottom: 1rem;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  align-items: center;
  @media ${breakpointsMax.tablet} {
    h1 {
      margin-bottom: 1rem;
    }
    flex-direction: column;
    align-items: flex-start;
  }

  /* border-bottom:1px solid #C4C4C4; */
`;


export const Heading = styled.h1`
  color: var(--neutral-dark);
`;

export const DateLineWrapper = styled.div`
  text-align: right;
  p {
    font-weight: bold;
  }
`;

export const Date = styled.p`
  line-height: 1.5;
  font-size: 1rem;
  font-style: normal;
  font-weight: bold;
  color: var(--neutral-slate);
  padding-bottom: 1rem;
`;

export const MemoryDetailsWrapper = styled.div`
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;


  .react-player__preview {
    background: black;
  }

  @media ${breakpoints.laptop} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const Description = styled.div`
  .video-styles {
    width: auto;
    height: auto;
  }
  .video-styles video {
    max-height: 60vh;
    margin: 0;
  }
  .video-react {
    padding: 0px !important;
  }
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: left;
  position: relative;

  @media ${breakpointsMax.tablet} {
    .video-styles {
      width: 100% !important;
      height: 100% !important;
    }
  }
`;

export const MemoryDescription = styled.div`
  white-space: pre-line;
  width: 100%;
  margin-right: 3.33%;
  margin-bottom: 3.33%;
  text-align: left;
  display: inline-block;

  p {
    line-height: 1.5rem;
  }

  @media ${breakpoints.laptop} {
    font-size: 1.2rem;
  }
  @media ${breakpointsMax.tablet} {
    width: 100%;
  }
`;

export const VoiceRecording = styled.div`
  width: 100%;
  /* min-width: 290px; */
`;

export const Player = styled.div``;

export const AudioPlayer = styled.audio`
  height: 46px;
  // padding-bottom: 1rem;
  // :hover {
  //   transition: all 0.2s ease;
  //   transform: scale(1.1);
  //   filter: drop-shadow(2px 3px 3px #333);
  // }
  :-webkit-media-controls-play-button {
    background-color: var(--secondary-aqua)
  }
`;

export const MemoryGalleryWrapper = styled.div`
  /* display: flex;
  flex-direction: column; */

  /* @media ${breakpoints.laptop} {
    width: 50%;
  } */
`;

export const SubHeading = styled.h2`
  padding-bottom: 1.5rem;
`;

export const PhotoContainer = styled.div`
  .image-gallery-image {
    max-height: 30vh !important
  }
  .image-gallery-thumbnail:hover {
    border: 4px solid var(--secondary-blue)
  }
  .image-gallery-svg {
    stroke: var(--neutral-white);
    :hover {
      stroke: var(--secondary-aqua);
    }
  }
  .image-gallery-icon {
    color: var(--neutral-white)
    stroke: var(--neutral-white) !important
  }
  .image-gallery-thumbnail.active {
    border: 4px solid var(--secondary-blue)
  }
`;

export const Gallery = styled.div`
  /* display: flex;
    width: 25%;
    flex-direction: column; */
`;

export const BackWrapper = styled.button`

  width: 100%;
  border: none;
  background: none;
  /* cursor: pointer; */
  text-align: left;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0rem;

  div {
    width: 100%;
  }
`;

// modal styles
export const ModalButton = styled.button`
  background: var(--primary);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px;
  color: var(--white);
  font-weight: bold;
  align-self: ${props => (props.right ? 'flex-end' : 'auto')};
  margin-bottom: 2rem;
  border-radius: 5px;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px;
  color: var(--white);
  font-weight: bold;
  align-self: ${props => (props.right ? 'flex-end' : 'auto')};
`;

export const ModalButtonWrapper = styled.div`
  text-align: center;
  .step-text {
    font-size: 1.5rem;
  }
`;

export const ModalBackground = styled.div`
  background-color: #0000009e;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  overflow: scroll;

  /* gives a small margin to all descendants */
  & > * {
    margin: 0.5rem 0;
  }
`;

export const ModalWrapper = styled(CardWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 70%;
  margin: auto;
`;

export const PageTitle = styled.h1`
  margin: 0.5rem auto;
`;

export const MemoryAdditionWrapper = styled.div`
  .memoryAdditionItem {
    margin-bottom: 20px;
    display: flex;

    img {
      // width: 30px !important;
      // height: 30px !important;
      // margin-right: 15px;
    }
  }
  .pendingButtonGroup {
    button {
      margin-right: 10px;
    }
  }
  p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    line-height: 1.5rem;
  }
  span {
    font-size: 1rem;
  }
`;
