import React, { useRef } from 'react';
import * as yup from 'yup';
import {
  DatePicker as MaDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import withDashedBorder from './withDashedBorder';
import { users } from '../../../api';
import { IternalSwitch } from '../../../NewComponents/Switch/Switch';

const dateSchema = yup
  .date()
  .typeError('Memory date is required')
  .required('Memory date is required');

const ageSchema = yup.object().shape({
  maxAge: yup.number(),
  age: yup
    .number()
    .required('Memory age is required')
    .min(0, 'Age must be a positive number')
    .integer('Age must be a whole number')
    .max(yup.ref('maxAge'), 'Age cannot be higher than your current age'),
});

const DateInput = ({
  date,
  setDate,
  setError,
  onFocusChange,
  dateType,
  setDateType,
  dateAge,
  setDateAge,
  noDate,
}) => {
  let maxAge = 0;
  const calculateAge = dob => {
    const diff_ms = Date.now() - new Date(dob).getTime();
    const age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  const calculateDate = dob => {
    const year = new Date(dob).getFullYear();
    const yearOfAge = Number(year) + Number(dateAge);
    const dateOfAge = new Date(yearOfAge, 0, 1);
    return dateOfAge;
  };

  const validate = async () => {
    onFocusChange(false);
    if (dateType === 'age') {
      const { data, error } = await users.getUserBasicProfile();
      if (error) {
        console.log(error);
      }
      const { birthday } = data;
      const tempAge = calculateAge(birthday);
      const tempDate = calculateDate(birthday);
      maxAge = tempAge;
      ageSchema
        .validate({ maxAge, age: dateAge })
        .then(() => {
          setDate(tempDate);
          setError('');
        })
        .catch(err => {
          setDate(null);
          setError(err.errors && err.errors[0]);
        });
      dateSchema
        .validate(date)
        .then(() => {
          setError('');
        })
        .catch(err => {
          setError(err.errors && err.errors[0]);
        });
    } else if (dateType === 'full') {
      dateSchema
        .validate(date)
        .then(() => {
          setError('');
        })
        .catch(err => {
          setError(err.errors && err.errors[0]);
        });
    }
  };

  const onChange = calDate => {
    onFocusChange(false);
    setDate(calDate);
    setError('');
  };

  const onAgeChange = tempAge => {
    onFocusChange(false);
    if (!tempAge.target.value) {
      setDateAge(0);
    } else {
      setDateAge(Number(tempAge.target.value));
    }
    setError('');
  };

  const toggleType = () => {
    if (dateType === '' || dateType === 'full') {
      setDateType('age');
    }
    if (dateType === 'age') {
      setDateType('full');
    }
  };

  const textDateRef = useRef();

  return (
    noDate ? (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MaDatePicker
            label="Date"
            openTo="year"
            format="dd/MM/yyyy"
            inputVariant="outlined"
            placeholder="Select a date"
            onChange={onChange}
            onBlur={validate}
            onFocus={onFocusChange}
            maxDate={new Date().toISOString().split('T')[0]}
            value={date || new Date()}
            style={{ scrollMargin: '11rem' }}
          />
        </MuiPickersUtilsProvider>
      </div>
    ) : (
      <div>
        <IternalSwitch
          option1="Enter a Date"
          option2="Enter Your Age"
          onChange={toggleType}
          startingCheck={dateType==='age' ? true : false}
        />
        <br />
        {dateType === 'age' ? (
          <p style={{ marginTop: '1.5rem' }}>
            I was &nbsp;&nbsp;
            <TextField
              ref={textDateRef}
              style={{ marginTop: '-1.25rem', width: '7rem', scrollMargin: '11rem' }}
              error={dateAge < 0}
              type="number"
              onBlur={validate}
              onChange={onAgeChange}
              label="Age"
              placeholder="Your age"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onFocus={(e) => onFocusChange(e, textDateRef)}
            />
            &nbsp;&nbsp; years old
          </p>
        ) : (
          <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MaDatePicker
                label="Date"
                openTo="year"
                format="dd/MM/yyyy"
                inputVariant="outlined"
                placeholder="Select a date"
                onChange={onChange}
                onBlur={validate}
                onFocus={onFocusChange}
                maxDate={new Date().toISOString().split('T')[0]}
                value={date || new Date()}
                style={{ scrollMargin: '11rem' }}
              />
            </MuiPickersUtilsProvider>
          </>
        )}
      </div>
    )
  );
};
export default withDashedBorder(DateInput, 'date');
