import React, { useState, useEffect } from 'react';
import { FaEdit, FaVideo, FaMicrophone } from 'react-icons/fa';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
  GrayText,
  VideoButtonWrapper,
  DescriptionWrapper,
} from './style';

import Swal from 'sweetalert2';

import Description from './Description';
import Voice from './Voice';
import Video from './Video';
import { Typography } from '@material-ui/core';

import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import MicNoneOutlinedIcon from '@material-ui/icons/MicNoneOutlined';
import SubjectRoundedIcon from '@material-ui/icons/SubjectRounded';

const VoiceOrDescription = ({
  setVoice,
  voice,
  setVideo,
  video,
  description,
  setDescription,
  tempVoice,
  tempVideo,
  setError,
  setIsRecording,
  isRecording,
  onFocusChange,
  setTitle,
  category,
  createForOther,
  isUploading,
  setIsUploading
}) => {
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  // const [prompt, setPrompt] = useState('');
  // const [promptQuestions, setPromptQuestions] = useState([]);
  // const [title, setTitle] = useState('');

  const [activeInput, setActiveInput] = useState('');
  useEffect(() => {
    if (
      isRecording ||
      (voice && (typeof voice === 'object' || typeof voice === 'string'))
    ) {
      setActiveInput('voice');
    }
    if (description.length > 0) {
      setActiveInput('description');
    }
    if (video && (typeof video === 'object' || typeof video === 'string')) {
      setActiveInput('video');
    }
    // if (category.length > 0) {
    //   getPrompts(category);
    // } else {
    //   getPrompts('');
    // }
  }, [voice, description, video, setActiveInput, isRecording, category]);

  const popUpChoice = (clickEvents) => {
    if (isRecording ||
        (voice && (typeof voice === 'object' || typeof voice === 'string')) ||
        description.length > 0 ||
        (video && (typeof video === 'object' || typeof video === 'string'))
    ) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You will lose the memory you have recorded so far",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#66CC66',
        cancelButtonColor: '#8a8787',
        confirmButtonText: 'Yes'
      }).then(result => {
        if (result.value) {
          clickEvents()
        }
      });
    } else {
      clickEvents()
    }
  }

  // renders option to do it the other way (write -> record ...)
  const renderAlternativeChoice = (icon, text, clickEvents) => (
    <Button
      style={{ opacity: '0.5', margin: '0.5rem 0.75rem', padding: '0.75rem' }}
      variant="outlined"
      onClick={() => popUpChoice(clickEvents)}
    >
      {text}
    </Button>
  );

  return (
    <>
      {/* WRITE OR RECORD */}
      {!activeInput && !voice && !description && !video && (
        <Grid container spacing={1} style={{ maxWidth: '700px', width: '90%', margin: '0 auto' }}>
          <Grid item xs={4} sm={4} style={{ textAlign: 'center' }}>
            <VideoButtonWrapper
              onClick={() => {
                setActiveInput('description');
                setVoice(null);
                setVideo(null);
              }}
              style={{ cursor: 'pointer', }}
            >
              <GrayText>
                <Button
                  variant="contained"
                  color="primary"
                  className="button"
                  style={{  borderRadius: '2rem', border:'none', color:'white', }}

                >
                  <SubjectRoundedIcon className="icon" />
                </Button>
                <Typography>
                  <span className="text" style={{color:''}}>Write</span>
                </Typography>
              </GrayText>
            </VideoButtonWrapper>
          </Grid>
          <Grid item xs={4} sm={4} style={{ textAlign: 'center' }}>
            <VideoButtonWrapper
              onClick={() => {
                setActiveInput('video');
                setDescription('');
                setVoice(null);
              }}
              style={{ cursor: 'pointer',  }}
            >
              <GrayText>
                <Button
                  variant="contained"
                  className="button"
                  color="primary"
                  style={{  borderRadius: '2rem', border:'none', color:'white', backgroundColor:''}}
                >
                  <VideocamOutlinedIcon className="icon" style={{color:'white'}}/>
                </Button>
                <Typography>
                  <span className="text" style={{color:''}}>Video</span>
                </Typography>
              </GrayText>
            </VideoButtonWrapper>
          </Grid>
          <Grid item xs={4} sm={4} style={{ textAlign: 'center' }}>
            <VideoButtonWrapper
              onClick={() => {
                setActiveInput('voice');
                setDescription('');
                setVideo(null);
              }}
              style={{ cursor: 'pointer' }}
            >
              <GrayText>
                <Button
                  variant="contained"
                  color="primary"
                  className="button"
                  style={{  borderRadius: '2rem', border:'none', color:'white', backgroundColor:''}}
 
                >
                  <MicNoneOutlinedIcon className="icon" />
                </Button>
                <Typography>
                  <span className="text" style={{color:''}}>Audio</span>
                </Typography>
              </GrayText>
            </VideoButtonWrapper>
          </Grid>
        </Grid>
      )}
      {/* WRITE */}
      <div>
        {activeInput === 'description' && (
          <DescriptionWrapper>
            <Description
              onFocusChange={onFocusChange}
              isTextareaFocused={isTextareaFocused}
              setIsTextareaFocused={setIsTextareaFocused}
              showIcon={!isTextareaFocused}
              setDescription={setDescription}
              description={description}
              setError={setError}
              createForOther={createForOther}
            />
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              {renderAlternativeChoice(
                'microphone',
                'Record using audio instead',
                () => {
                  setActiveInput('voice');
                  setDescription('');
                  setVoice('');
                },
              )}
              {renderAlternativeChoice(
                'camera',
                'Record using video instead',
                () => {
                  setActiveInput('video');
                  setDescription('');
                  setVoice('');
                  setVideo('');
                },
              )}
            </div>
          </DescriptionWrapper>
        )}
        {/* RECORD AUDIO */}
        {activeInput === 'voice' && (
          <DescriptionWrapper>
            <Voice
              setDescription={setDescription}
              tempVoice={tempVoice}
              voice={voice}
              setVoice={setVoice}
              setVideo={setVideo}
              setActiveInput={setActiveInput}
              activeInput={activeInput}
              renderAlternativeChoice={renderAlternativeChoice}
              setIsRecording={setIsRecording}
              isRecording={isRecording}
              setError={setError}
            />
          </DescriptionWrapper>
        )}
        {/* RECORD VIDEO */}
        {activeInput === 'video' && (
          <DescriptionWrapper>
            <Video
              setDescription={setDescription}
              tempVideo={tempVideo}
              video={video}
              setVideo={setVideo}
              setVoice={setVoice}
              setActiveInput={setActiveInput}
              activeInput={activeInput}
              renderAlternativeChoice={renderAlternativeChoice}
              setIsRecording={setIsRecording}
              isRecording={isRecording}
              setError={setError}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
            />
          </DescriptionWrapper>
        )}
      </div>
    </>
  );
};

export default VoiceOrDescription;
