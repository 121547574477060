import React, { Component } from 'react';
import axios from 'axios';
import { API_RESET_PASSWORD } from '../../../constants/apiRoutes';

// COMMON
import HeaderLogout from '../../Common/HeaderLogout';
import Header from '../../Common/Header/Header';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Icon from '../../Common/Icon/Icon';
import Grid from '@material-ui/core/Grid';

import * as S from './style';

import EmailInput from './EmailInput';
import ChangePassword from './ChangePasswrd';
import { BASICS_URL } from '../../../constants/constantUrls';
import logo from "../../../assets/onboarding/logo-white.png";



const Content = ({
  message,
  validToken,
  history,
  token,
  showMessage,
  toggleLoading,
}) => {
  if (message) {
    return (
      <S.Message size="2rem">
        {message}
        <br />
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => history.push('/login')}
          style={{ marginTop: '2rem' }}
        >Home</Button>
      </S.Message>
    );
  }
  if (validToken) {
    return (
      <ChangePassword
        history={history}
        token={token}
        showMessage={showMessage}
      />
    );
  }
  return <EmailInput showMessage={showMessage} toggleLoading={toggleLoading} />;
};

export default class ResetPassword extends Component {
  state = {
    validToken: false,
    message: '',
    loading: false,
  };

  componentDidMount() {
    const {
      match: {
        params: { token },
      },
    } = this.props;

    if (token) {
      this.setState({
        validToken: true,
        token,
      });
    }
  }

  handleSubmitSetPassword = values => {
    axios.post(API_RESET_PASSWORD, values).then(() => {
      this.setState({ loading: false }, () => {});
    });
  };

  showMessage = (message, login) => {
    this.setState({
      message,
      login,
    });
  };

  toggleLoading = () => {
    const { loading } = this.state;
    this.setState({
      loading: !loading,
    });
  };

  render() {
    const { message, validToken, token, loading } = this.state;
    const { windowWidth, history, isLoggedIn } = this.props;

    return (
      <>
        {!isLoggedIn && (
          <HeaderLogout windowWidth={windowWidth} title="reset password" />
        )}
        {isLoggedIn && (
          <Header isLoggedIn={true} {...this.props} />
        )}

        <S.Main>
        <Grid
                  container
                  spacing={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "4rem",
                    marginTop:'2rem',

                  }}
                >
                   <img src={logo} style={{width: window.innerWidth < 600 ? '60%' : "50%",  display: window.innerWidth < 1023 ? "flex" : "none",
}}/>
                  </Grid>
          <form>
            <Paper elevation={3} style={{ padding: '2rem', maxWidth: '600px', margin: '0 auto',  }}>
              {isLoggedIn && (
                <>
                  {' '}
                  <div style={{display:'flex', justifyContent:'flex-start'}}>
                  <Button 
                    variant="contained"
                    color="primary"
                    style={{marginBottom:'1rem'}}
                    onClick={() => history.push(BASICS_URL)}
                  >
                    <Icon icon="back" height="2.5em" />
                  </Button>
                  </div>
                </>
              )}
              {loading ? (
                <S.Message size="3.5rem">Sending Email...</S.Message>
              ) : (
                <Content
                  message={message}
                  validToken={validToken}
                  history={history}
                  token={token}
                  showMessage={this.showMessage}
                  toggleLoading={this.toggleLoading}
                />
              )}
            </Paper>
          </form>
     
        </S.Main>

      </>
    );
  }
}
