import axios from 'axios';
import Swal from 'sweetalert2';

import { API_MILESTONES_URL, API_MILESTONE_URL } from '../constants/apiRoutes';

import makeError from './makeError';

const getMilestoneById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${API_MILESTONE_URL}/${id}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

// get all memories created by this user
const getUserMilestones = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(API_MILESTONES_URL);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const createMilestone = async ({ milestone, popup=true, options }) => {
  const { title, date, category, description } = milestone;

  try {
    const { data } = await axios.post(`${API_MILESTONE_URL}`, {
      title,
      date,
      category,
      description,
    });

    if (data) {
      if (popup) {
        Swal.fire({
          title: 'Milestone created!',
          type: 'success',
        });
      }
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const updateMilestone = async ({ milestone, options }) => {
  const { title, date, category, description, milestoneId } = milestone;

  try {
    const { data } = await axios.patch(`${API_MILESTONE_URL}`, {
      title,
      date,
      category,
      description,
      milestoneId,
    });
    if (data) {
      Swal.fire({
        title: 'Milestone updated!',
        type: 'success',
      });
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const deleteMilestone = async ({ id, options }) => {
  try {
    const { data } = await axios.delete(`${API_MILESTONE_URL}/${id}`);
    if (data) {
      Swal.fire({
        title: 'Milestone deleted!',
        type: 'success',
      });
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const milestones = {
  getMilestoneById,
  getUserMilestones,
  createMilestone,
  updateMilestone,
  deleteMilestone,
};

export default milestones;
