// React
import React, { useState, useEffect } from 'react';

// Styled Components
import { Main } from './style';
import PromptPicker from '../../Common/PromptPicker';
import BackArrow from '../../../assets/prompts/backcircle.png';
import history from '../../../history';

import ReactGA from 'react-ga';
import { 
  Typography,
  Paper,
  Grid,
  Button,
  Avatar,
  Modal, 
} from '@material-ui/core';

import Swal from 'sweetalert2';

import {
  linkTokens,
  permissionAccess,
  users
} from '../../../api';

import AcceptModal from '../../Common/FriendsModals/AcceptRequestModal';
import SignUpModal from '../../Common/FriendsModals/SignUpModal';
import LogInModal from '../../Common/FriendsModals/LoginModal';
import {
  API_SIGNUP_URL,
  API_UPDATE_USERINFO_URL,
  API_LOGIN_URL,
  API_PERMISSION_ACCESS_EMAIL
} from "../../../constants/apiRoutes";
import { createErrors, validate } from "../../../helpers/validation";
import axios from 'axios';
import { NewTimeline } from '../../../NewComponents/Dashboard/Blocks/NewTimeline/NewTimeline';
import LoadingAnimation from "../../Common/Animations/Loading";

export default (props) => {
  const [profilePicture, setProfilePicture] = useState({});
  const [takenFriends, setTakenFriends] = useState([]);
  const [givenFriends, setGivenFriends] = useState([]);
  const [memories, setMemories] = useState([]);
  const [invitee, setInvitee] = useState({});
  const [inviteeName, setInviteeName] = useState('');
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showSignUpModal, setShowSignupModal] = useState(false);
  const [isSignUp, setIsSignUp] = useState(true);
   
  // sign up / log in details
  const [signUpEmail, setSignUpEmail] = useState('');
  const [signUpPassword, setSignUpPassword] = useState('');
  const [signUpName, setSignUpName] = useState('');
  const [signUpDoB, setSignUpDoB] = useState(new Date());

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleLanding();
  }, []);

  const handleLanding = async () => {
    const inviteeAccount = await getShortIdAccount();
    setInvitee(inviteeAccount)
    if (props?.isLoggedIn) {
      console.log(props);
      const {
        givenFriends,
        takenFriends
      } = await getFriendMemories();
      if (props?.id === inviteeAccount?.user?._id) {
        props.history.push('/dashboard')
      }
      else {
        // in given, the person accepting is the owner of the permissionAccess
        const filteredGiven = givenFriends.filter(f => f?.sharedWithId === inviteeAccount?.user?._id);
        console.log(filteredGiven);
        // in taken, the person accepting is the sharedWithId of the permissionAccess
        const filteredTaken = takenFriends.filter(f => f?.owner?._id === inviteeAccount?.user?._id);
        console.log(takenFriends);
        console.log(filteredTaken);
        // if invitee account email is in friends cop out
        // const filteredGiven = givenFriends.filter(f => f?)
        if (filteredGiven?.length > 0 && filteredTaken?.length > 0) {
          props.history.push(`/share/${props?.match?.params?.id}`);
        }
        setIsLoading(false);
        setShowAcceptModal(true);
      }
    } else {
      setIsLoading(false);
      setShowSignupModal(true);
    }
  }

  const getFriendMemories = async () => {
    const { data: takenData } = await permissionAccess.getAllTakenPermissionAccess({
      options: { redirectToRelatedPage: true },
    });
    
    const { data: givenData } = await permissionAccess.getAllGivenPermissionAccess({
      options: {redirectToRelatedPage: true},
    })
    return {
      givenFriends: givenData,
      takenFriends: takenData
    }
  };

  const getShortIdAccount = async () => {
    try {
      const { data } = await linkTokens.getSharedUserInfoAndMemoriesAndByToken({
        shortId: props?.match?.params?.id
      });
      if (data) {
        setInviteeName(data?.user?.name);
        setProfilePicture(data?.user?.abouts[0]?.image);
        setMemories(data?.user?.memories);
        console.log(data?.user?.memories)
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  }

  const acceptInvitation = async () => {
    console.log("Accepted")
    // create connection both ways for mems
    // redirect to new friends memories
    const permissionAccess = await axios.post(
      API_PERMISSION_ACCESS_EMAIL,
      {
        sharedWithEmail: props?.email,
        sharedWithId: props?.id,
        owner: invitee?.user?._id,
      }
    );
    if (permissionAccess) {
      history.push(`/share/${props?.match?.params?.id}`)
    }
  }

  const declineInvitation = async () => {
    props.history.push('/dashboard');
  }

  const signUp = async () => {
    const { history, handleChangeState } = props;
    try {
      const signUpData = {
        email: signUpEmail,
        password: signUpPassword,
        dateOfBirth: signUpDoB,
        name: signUpName,
      };
      const validated = await validate("groupSignUp", signUpData);
      if (validated) {
        const userInfo = {
          email: signUpEmail,
          password: signUpPassword,
          name: signUpName,
          referralCode: props?.match?.params?.id,
          mailingList: true,
        };
        const signedUp = await axios.post(API_SIGNUP_URL, { userInfo });
        if (signedUp) {
          const { data } = signedUp;

          const hasProfile =
            props.profile && Object.keys(props.profile).length > 0;
          const updateProfile = await axios.post(API_UPDATE_USERINFO_URL, {
            state: { name: signUpName, birthday: signUpDoB, hometown: "" },
            hasNoProfile: !hasProfile,
          });
          if (signUpName) {
            props.handleChangeState({ name: signUpName });
          }
          ReactGA.event({
            category: "Sign Up",
            action: "Signed Up By Referral",
          });
          handleChangeState({ ...data, isLoggedIn: true });
          window.location.reload(false);
        }
      } else {
        // setIsSigningUp(false);
      }
    } catch (error) {
      // setIsSigningUp(false);
      console.log(error);
      console.log(error.response);
      if (error.name === "ValidationError") {
        if (error.errors.length > 0) {
          Swal.fire({
            type: "warning",
            confirmButtonColor: "var(--secondary-blue)",
            cancelButtonColor: "var(--secondary-cherry)",
            title: error.errors[0],
          });
        }
      } else if (
        error.response &&
        error.response.data.error.includes("Email already taken")
      ) {
        Swal.fire({
          type: "warning",
          confirmButtonColor: "var(--secondary-blue)",
          cancelButtonColor: "var(--secondary-cherry)",
          title: error.response.data.error,
        });
      } else {
        Swal.fire({
          type: "warning",
          confirmButtonColor: "var(--secondary-blue)",
          cancelButtonColor: "var(--secondary-cherry)",
          title: "There was an issue signing up, please try again.",
        });
      }
    }
  };

  const logIn = async () => {
    const { history, handleChangeState, match } = props;
    try {
      // validate form
      const loginData = { 
        email: signUpEmail, 
        password: signUpPassword
      };
      const validated = await validate("login", loginData);

      if (validated) {
        const loggedIn = await axios.post(API_LOGIN_URL, loginData);

        if (loggedIn) {
          ReactGA.event({
            category: "Login",
            action: "Logged In Via Referral",
          });
          const { data } = loggedIn;
          handleChangeState({ ...data, isLoggedIn: true });
          window.location.reload(false);
        }
      }
    } catch (error) {

      if (error.name === "ValidationError") {
        if (error.errors.length > 0) {
          Swal.fire({
            type: "warning",
            confirmButtonColor: "var(--secondary-blue)",
            cancelButtonColor: "var(--secondary-cherry)",
            title: error.errors[0],
          });
        }
      } else if (
        error.response &&
        error.response.data.error.includes("Email already taken")
      ) {
        Swal.fire({
          type: "warning",
          confirmButtonColor: "var(--secondary-blue)",
          cancelButtonColor: "var(--secondary-cherry)",
          title: error.response.data.error,
        });
      } else {
        Swal.fire({
          type: "warning",
          confirmButtonColor: "var(--secondary-blue)",
          cancelButtonColor: "var(--secondary-cherry)",
          title: "There was an issue logging, please try again.",
        });
      }

    }
  }

  const renderTimelineOrNothing = () =>
    memories && memories?.length > 0 ? (
      <div
        style={{                               
          height: '59vh', 
          overflow: 'auto', 
          position: 'initial', 
          // background: 'hsla(178, 88%, 36%, 0.08)',
          borderRadius: '1rem 1rem 0 0' 
        }}
      >
        <NewTimeline 
          memories={memories} 
          showPrompts={false} 
          viewingFriendsMemory={true} 
          profile={{
            picture: profilePicture?.imagePath, 
            name: inviteeName
          }}
        />
      </div>
    ) : (
      <div 
        style={{ 
          width: '100%', 
          height: '100%', 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding:'1rem',
        }}
      >
        <p>{inviteeName} hasn&apos;t added any memories yet!</p>
      </div>
      
    );

  if (isLoading) {
    return (
      <Main>
        <LoadingAnimation />
      </Main>
    )
  }

  return (
    <Main>
      <Modal
        open={showAcceptModal}
        // onClose={() => setShowAcceptModal}
      >
        <AcceptModal 
          username={inviteeName}
          profilePicture={profilePicture}
          acceptFunc={acceptInvitation}
          declineFunc={declineInvitation}
        />
      </Modal>
      <Modal
        open={showSignUpModal}
        // onClose={() => setShowSignupModal(false)}
      >
        {isSignUp ? (
          <SignUpModal 
            username={inviteeName}
            switchFunc={() => setIsSignUp(false)}
            email={signUpEmail}
            setEmail={setSignUpEmail}
            password={signUpPassword}
            setPassword={setSignUpPassword}
            name={signUpName}
            setName={setSignUpName}
            dob={signUpDoB}
            setDob={setSignUpDoB}
            signUpFunc={() => signUp()}
          />
        ) : (
          <LogInModal 
            username={inviteeName}
            switchFunc={() => setIsSignUp(true)}
            email={signUpEmail}
            setEmail={setSignUpEmail}
            password={signUpPassword}
            setPassword={setSignUpPassword}
            logInFunc={() => logIn()}
          />
        )}
      </Modal>
      <div 
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography 
          variant='h4'
          gutterBottom
          style={{
            color: 'white',
            fontSize: window.innerWidth < 700 ? "2rem" : "3rem",
   
          }}
        >
          {inviteeName}'s Memories
        </Typography>
        <Paper style={{ padding: '2rem', margin: '2rem', width: '95%' }}>
          <Typography variant="body1">
            Welcome to the memories of {inviteeName}.{' '}
            {inviteeName.split(' ')[0]} has chosen to share these with you.
            Want to do the same?
          </Typography>
          {/* <S.PinkLink color="secondary" to={MY_FRIENDS_MEMORIES}>
            Share your memories with friends
          </S.PinkLink> */}
        </Paper>

        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Paper elevation={3} style={{ padding: '2rem', textAlign: 'center' }}>
              <Avatar
                src={profilePicture?.imagePath}
                style={{
                  width: '100px',
                  height: '100px',
                  margin: '0 auto 1rem'
                }}
              >
                {inviteeName[0]}
              </Avatar>
              <h1>{inviteeName}</h1>
              <p>
                {memories.length}{' '}
                {memories.length === 1 ? 'memories' : 'memory'}
              </p>
              <Button
                variant="contained"
                color="secondary"
                style={{
                  marginTop: '1rem',
                  borderRadius: '100px',
                  Width: '164px',
                  minHeight: '50px',
                  fontWeight:"700",
                  boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',
                }}
              >
                Send A Memory
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Paper elevation={3} style={{ paddingLeft: '1rem', paddingRight:'1rem', marginBottom: window.innerWidth < 1024 ? "8rem" : "2rem", height: '100%' }}>
              {renderTimelineOrNothing()}
            </Paper>
          </Grid>
        </Grid>
      </div>

    </Main>
  );
};
