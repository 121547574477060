import React from 'react';
import { Typography, Paper, Button, Grid } from '@material-ui/core';
import ViewButton from '../Buttons/ViewGroupButton';
import CallToActionButton from '../Buttons/CallToActionButton';
import Icon from '../../Icon/Icon';

export default ({
  submitFunction = () => {},
  closeFunction = () => {},
  onCloseFunction = () => {},
  title = '',
  text = '',
  submitButtonText = '',
  closeButtonText = '',
}) => (
  <Paper
    id="warning-popup"
    style={{
      position: 'absolute',
      textAlign: 'center',
      width: '70%',
      backgroundColor: 'white',
      padding: '3rem',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '80%',
      maxWidth: '85vw',
    }}
  >
    <Grid item xs={12} style={{ textAlign: 'right' }}>
  
        <Button
          variant="text"
          color="primary"
          onClick={closeFunction}
                    style={{
    postion: 'absolute',
    top: '-0.5rem',
    right: '-1rem'
          }}
        >
          <Icon icon="close" />
        </Button>

    </Grid>
    {/* Logo here */}
    <img
      src={require('../../../../assets/HOR_A.png')}
      style={{ maxWidth: '100%', width: '200px' }}
    />
    {/* Close button here */}
    <Typography
      variant="h5"
      gutterBottom
      style={{ textAlign: 'left', marginTop: '1rem' }}
    >
      {title}
    </Typography>
    <Typography variant="body1" style={{ textAlign: 'left', marginTop:'1rem' }}>
      {text}
    </Typography>
    {closeButtonText.length > 0 && (
      <CallToActionButton
        id="closeButton"
        title={closeButtonText}
        clickFunction={closeFunction}
      />
    )}
    {submitButtonText.length > 0 && (
      <CallToActionButton
      variant='outlined'
        id="submitButton"
        title={submitButtonText}
        clickFunction={submitFunction}
      />
    )}
  </Paper>
);
