// React
import React, { useState, useEffect, useRef, useCallback } from "react";
import { NavLink } from "react-router-dom";


// bottom nav
import Groups from "@material-ui/icons/People";
import Friends from "@material-ui/icons/EmojiPeople";
import Profile from "@material-ui/icons/Face";
import Stories from "@material-ui/icons/MenuBook";

import * as C from "../../../../constants/constantUrls";
import history from "../../../../history";


// Components
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Fade,
  Collapse,
  Zoom,
  Grid,
  Modal,
  Select,
  InputLabel,
  Switch,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";

// Components

// icons

import { FaPlus, FaUserPlus, FaUserMinus, FaTrash } from "react-icons/fa";

import imageCompression from "browser-image-compression";
import ReactGA from "react-ga";
import axios from "axios";
import Swal from "sweetalert2";
import InviteMemberPopup from "../../../Common/GroupComponents/InviteMemberPopup";
import HeaderShareMemory from "../../../Common/HeaderShareMemory";
import imagePlaceHolder from "../../../../assets/person-placeholder.jpg";
import MemoriesTable from "../../../Common/Tables/MemoriesTable";
import { NewTimeline } from "../../../../NewComponents/Dashboard/Blocks/NewTimeline/NewTimeline";
import { Divider } from "../../../../NewComponents/Divider/Divider";
import BackArrow from "../../../Common/GroupComponents/Buttons/BackArrow";
import CallToActionButton from "../../../Common/GroupComponents/Buttons/CallToActionButton";
import WarningPopup from "../../../Common/GroupComponents/WarningPopup";
// Constants
import { SIGNUP_URL, GROUPS_URL } from "../../../../constants/constantUrls";
import { linkTokens, memories } from "../../../../api";

// Styles
import { Main, MemoryButtonWrapper } from "./style";

import Navbar from "../../../Common/GroupComponents/Navbar";
import { IternalTheme } from "../../../../NewComponents/--Theme/IternalThemeLight";

const GroupMemberPage = (props) => {
  const { shortId } = props.match.params;
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [groupMembers, setGroupMembers] = useState([]);
  const [approvedGroupMembers, setApprovedGroupMembers] = useState([]);
  const [pendingGroupMembers, setPendingGroupMembers] = useState([]);
  const [groupData, setGroupData] = useState({});
  const [hasJoinedGroup, setHasJoinedGroup] = useState(false);
  const [groupMemberStatus, setGroupMemberStatus] = useState("");
  const [showInviteMemberPopup, setShowInviteMemberPopup] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [theme, setTheme] = useState(createMuiTheme(IternalTheme));

  useEffect(() => {
    // get user's groups
    getGroup();
  }, [getGroup]);

  const getGroup = useCallback(async () => {
    const { data } = await axios.get(`/api/group/${shortId}`);
    if (data) {
      setGroupMembers(data[0]?.groupMembers);
      setApprovedGroupMembers(
        data[0]?.groupMembers.filter(
          (member) => member?.invitationStatus === "accepted"
        )
      );
      setPendingGroupMembers(
        data[0]?.groupMembers.filter(
          (member) => member?.invitationStatus === "pending"
        )
      );
      setGroupData(data[0]);
      if (props?.id && props?.isLoggedIn) {
        if (
          data[0].groupMembers.find(
            (x) => x.userId.toString() === props.id.toString()
          )
        ) {
          const status = data[0].groupMembers.find(
            (x) => x.userId.toString() === props.id.toString()
          )?.role;
          if (status === "member") {
            props.history.push(`/group/${shortId}`);
          }
          setHasJoinedGroup(true);
          setGroupMemberStatus(
            data[0].groupMembers.find(
              (x) => x.userId.toString() === props.id.toString()
            )?.role
          );
          setUserId(props.id.toString());
          if (data[0]?.groupColor) {
            const { main, light, dark, contrastText } = data[0]?.groupColor;
            theme.palette.primary.main = main;
            theme.palette.primary.light = light;
            theme.palette.primary.dark = dark;
            theme.palette.primary.contrastText = contrastText;
          }
        } else {
          props.history.push(`/group/${shortId}`);
        }
      }
    }
    setIsLoading(false);
  });

  const editMember = async (event, member) => {
    const { data } = await axios.patch("/api/group-member", {
      _id: member._id,
      role: event.target.value,
      invitationStatus: member?.invitationStatus,
      isApproval: false,
    });
    if (data) {
      await getGroup();
    }
  };

  const filterMembers = (event) => {
    switch (event.target.value) {
      case "all":
        setApprovedGroupMembers(
          groupMembers.filter(
            (member) => member?.invitationStatus === "accepted"
          )
        );
        break;
      case "owner":
        setApprovedGroupMembers(
          groupMembers.filter(
            (member) =>
              member?.invitationStatus === "accepted" &&
              member?.role === "owner"
          )
        );
        break;
      case "admin":
        setApprovedGroupMembers(
          groupMembers.filter(
            (member) =>
              member?.invitationStatus === "accepted" &&
              member?.role === "admin"
          )
        );
        break;
      case "member":
        setApprovedGroupMembers(
          groupMembers.filter(
            (member) =>
              member?.invitationStatus === "accepted" &&
              member?.role === "member"
          )
        );
        break;
    }
  };

  const approveMember = async (member) => {
    const { data } = await axios.patch("/api/group-member", {
      _id: member._id,
      invitationStatus: "accepted",
      role: "member",
      isApproval: true,
    });
    if (data) {
      await getGroup();
    }
  };

  const declineMember = async (member) => {
    const { data } = await axios.patch("/api/group-member", {
      _id: member._id,
      invitationStatus: "declined",
      role: "member",
      isApproval: false,
    });
    if (data) {
      await getGroup();
    }
  };

  if (isLoading) {
    return <Main />;
  }
  return (
    <>
      <Navbar
        {...props}
        customTheme={theme}
        isLoggedIn={isLoggedIn}
        hasJoinedGroup={hasJoinedGroup}
        groupMemberStatus={groupMemberStatus}
        groupId={groupData?._id}
        groupShortCode={shortId}
        pendingInvites={
          groupMembers.filter(
            (member) => member?.invitationStatus === "pending"
          ).length
        }
        joinGroupFunction={async () => {}}
      />
      <Main>
        <Modal
          open={showInviteMemberPopup}
          onClose={() => setShowInviteMemberPopup(false)}
        >
          <InviteMemberPopup
            groupShortCode={shortId}
            groupName={groupData?.groupName}
            groupId={groupData?._id}
            onCloseFunction={() => setShowInviteMemberPopup(false)}
          />
        </Modal>
        <Grid container>

          <Grid item xs={1} style={{ }}>

            <BackArrow
              clickFunction={() => props.history.push(`/group/${shortId}`)}
            />
          </Grid>


          <Grid item xs={12} style={{  }}>

            <Typography
              align="center"
              variant="h4"
              gutterBottom
              style={{
                color: "white",
                fontSize: window.innerWidth < 1024 ? "3rem" : "4rem",
              }}
            >
             Members Area
            </Typography>
          </Grid>
        </Grid>
        <Paper
          style={{
            maxWidth: "1000px",
            margin: "0 auto",
            textAlign: "center",
            borderRadius: "7px",
            backgroundColor: "white",
            padding: "1rem",
            marginTop: "1rem",

            marginBottom: "6rem",
          }}
          elevation={3}
        >

          <Typography
            variant="body1"
            style={{
              textAlign: "left",
              marginTop: "1rem",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              marginBottom: "2rem",
            }}
          >
            Having multiple contributors are what makes Iternal groups so great!
            Members can add stories and edit their own content which you can use
            to build your History Timeline.
          </Typography>

          <CallToActionButton
            style={{
              marginTop: "2rem",
            }}
            variant="contained"
            id="submitButton"
            title="Add Member"
            startIcon=<FaPlus style={{ fontSize: "0.8rem" }} />
            clickFunction={async () => {
              setShowInviteMemberPopup(true);
            }}
          />

          <div
            className="CurrentMembers"
            style={{ textAlign: "left", marginTop: "", padding: "2rem" }}
          >

            <Typography variant="h5" color="primary" style={{ marginBottom: "1rem" }}>

              <span style={{ fontWeight: "bold" }}>Current Members</span>
            </Typography>

            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                marginTop: "1rem",
                marginBottom: "2rem",
              }}
            >
              As the owner, you can give different roles and responsibilities to
              members of the group. Admins can edit, move or delete stories on
              the timeline, as well as approving members who request to join the
              group. Group owners also have the power to remove members and edit
              all of the information presented on the Story Timeline.
            </Typography>
            <Typography variant="h7" style={{}}>
              <span style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
                Search by type of member:
              </span>
            </Typography>
            <br />
            <Select
              native
              variant="outlined"
              onChange={(e) => filterMembers(e)}
              style={{
                height: "100%",
                width: "50%",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
            >
              <option value="all">All Members</option>
              <option value="owner">Owners</option>
              <option value="admin">Admins</option>
              <option value="member">Members</option>
            </Select>

            <Grid
              container
              spacing={1}
              style={{
                overflow: "scroll",
                maxHeight: "250px",
                marginTop: "1rem",
              }}
            >
              {approvedGroupMembers.map((member, i) => (
                <>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {/* profile pic */}
                      <Grid item xs={2} sm={1} md={1}>
                        <Avatar
                          style={{ marginTop: "0.5rem" }}
                          src={
                            member?.profilePicture?.imagePath
                              ? member.profilePicture.imagePath
                              : require("../../../../assets/orange_logo.png")
                          }
                        />
                      </Grid>
                      {/* name and email */}
                      <Grid item xs={10} sm={6} md={8}>
                        <Typography variant="body1">{member.name}</Typography>
                        <Typography variant="body2">
                          <span style={{ color: "var(--neutral-mist)" }}>
                            {member.userEmail}
                          </span>
                        </Typography>
                      </Grid>
                      {/* Button and tags */}
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={3}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {groupMemberStatus === "owner" &&
                        member.role !== "owner" &&
                        userId !== member?.userId ? (
                          <>
                            <Select
                              native
                              variant="outlined"
                              value={member.role}
                              onChange={(e) => editMember(e, member)}
                              style={{
                                height: "100%",
                                marginRight: "0.5rem",
                                width: "50%",
                              }}
                            >
                              <option value="owner">Owner</option>
                              <option value="admin">Admin</option>
                              <option value="member">Member</option>
                            </Select>
                            <Button
                              variant="contained"
                              onClick={() => {
                                Swal.fire({
                                  title: `This will permanently delete this member from the group!`,
                                  text: "Are you sure you want to do this?",
                                  type: "warning",

                                  showCancelButton: true,
                                  confirmButtonColor: "var(--secondary-blue)",
                                  cancelButtonColor: "var(--secondary-cherry)",
                                  confirmButtonText: "Yes",
                                }).then(async (result) => {
                                  if (result.value) {
                                    const { data } = await axios.delete(
                                      `/api/group-member/${member._id}`
                                    );
                                    if (data) {
                                      await getGroup();
                                    }
                                  }
                                });
                              }}
                              style={{
                                marginLeft: "0.5rem",
                                border: "solid, 1px, #1D6A91",
                                padding: "0.5rem",
                                color: "white",
                                backgroundColor: "var(--secondary-cherry)",
                                fontSize: "0.8rem",
                              }}
                            >
                              <FaTrash style={{ marginRight: "0.5rem" }} />
                              Remove
                            </Button>
                          </>
                        ) : (
                          <Typography
                            style={{
                              marginRight: "1rem",
                              textTransform: "capitalize",
                              width: "100%",
                              textAlign: "right",
                            }}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {member.role}
                            </span>
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Divider />
                  </Grid>
                </>
              ))}
            </Grid>
          </div>
          <div
            className="PendingMembers"
            style={{ textAlign: "left", marginTop: "", padding: "2rem" }}
          >

            <Typography color="primary" variant="h5" style={{ marginBottom: "2rem" }}>

              <span style={{ fontWeight: "bold" }}>Pending Members</span>
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                marginTop: "1rem",
                // paddingLeft: '2rem',
                paddingRight: "2rem",
                marginBottom: "1rem",
              }}
            >
              These are the members that have requested to join the group. If
              you would like them to join, click the Approve Button.
            </Typography>
            <Grid
              container
              spacing={1}
              style={{
                overflow: "scroll",
                maxHeight: "250px",
                marginTop: "1rem",
              }}
            >
              {pendingGroupMembers.map((member, i) => (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {/* profile pic */}
                    <Grid item xs={2} sm={1} md={1}>
                      <Avatar
                        style={{ marginTop: "0.5rem" }}
                        src={
                          member?.profilePicture?.imagePath
                            ? member.profilePicture.imagePath
                            : require("../../../../assets/orange_logo.png")
                        }
                      />
                    </Grid>
                    {/* name and email */}
                    <Grid item xs={10} sm={6} md={6}>
                      <Typography variant="body1">{member.name}</Typography>
                      <Typography variant="body2">
                        <span style={{ color: "var(--neutral-mist)" }}>
                          {member.userEmail}
                        </span>
                      </Typography>
                    </Grid>
                    {/* Button and tags */}
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={5}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        style={{
                          marginRight: "0.5rem",
                          backgroundColor: "#1D6A91",
                        }}
                        onClick={async () => await approveMember(member)}
                      >
                        <FaUserPlus style={{ marginRight: "0.5rem" }} />
                        Approve
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={async () => await declineMember(member)}
                        style={{
                          marginLeft: "0.5rem",
                          border: "solid, 1px, #1D6A91",
                          padding: "0.5rem",
                          color: "white",
                          backgroundColor: "var(--secondary-cherry)",
                          fontSize: "0.8rem",
                        }}
                      >
                        <FaUserMinus style={{ marginRight: "0.5rem" }} />
                        Decline
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            {/* Original Code */}
            {/* <List dense style={{ maxHeight: '200px', overflow: 'scroll' }}>
              {pendingGroupMembers.map((member, i) => (
                <Grid container spacing={2}>
                  <ListItem key={i}>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      style={{
                        display: 'flex',
                        marginBottom: '0.5rem',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={{ marginTop: '0.5rem' }}
                          src={
                            member?.profilePicture?.imagePath
                              ? member.profilePicture.imagePath
                              : require('../../../../assets/orange_logo.png')
                          }
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={member.name}
                        secondary={member.userEmail}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <ListItemSecondaryAction>
                        <Button
                          color="primary"
                          variant="contained"
                          style={{
                            marginRight: '0.5rem',
                            backgroundColor: '#1D6A91',
                          }}
                          onClick={async () => await approveMember(member)}
                        >
                          <FaUserPlus style={{ marginRight: '0.5rem' }} />
                          Approve
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={async () => await declineMember(member)}
                          style={{
                            marginRight: '1rem',
                            border: 'solid, 1px, #1D6A91',
                            paddingTop: '0.5rem',
                          }}
                        >
                          <FaUserMinus style={{ marginRight: '0.5rem' }} />
                          Decline
                        </Button>
                      </ListItemSecondaryAction>
                    </Grid>
                  </ListItem>
                </Grid>
              ))}
            </List> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CallToActionButton
                clickFunction={() => props.history.push(`/group/${shortId}`)}
                variant="contained"
                title="Back To Timeline"
              />
            </div>
          </div>
        </Paper>
      
      </Main>
    </>
  );
};

export default GroupMemberPage;
