// React
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// MUI
import { Paper, Typography, Button, Zoom } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Skeleton } from '@material-ui/lab';

// Styled Components
import { ClaimBonusStyles } from './ClaimBonus.style';

// Iconograpghy
import iternalBoxWithBonus from './icons/iternal_box.svg';
import iternalEmptyBox from './icons/iternal_empty_box.svg';

// Constants
import {
  CREATE_MEMORY_URL,
  DASHBOARD_URL,
} from '../../../constants/constantUrls';

export const ClaimBonus = ({
  userId,
  bonusId,
  claimStep,
  bonusTitle,
  bonusQuantity,
  bonusType,
  claimBonusFunc,
  errors,
}) => {
  if (errors?.alreadyClaimed) {
    claimStep = 2;
  }

  const generateBonusCopy = () => {
    if (bonusType === 'memories') {
      return `memor${bonusQuantity === 1 ? 'y' : 'ies'}`;
    }
    if (bonusType === 'premium') {
      return `day${bonusQuantity === 1 ? '' : 's'} of premium`;
    }
  };

  const renderBonusQtyAndType = () => (
    <Zoom in={claimStep === 1} timeout={2000}>
      <>
        <Typography variant="h2" align="center" className="bonusQuantityBig">
          +{bonusQuantity}
        </Typography>
        <Typography
          variant="h4"
          align="center"
          className="bonusTypeBig"
          gutterBottom
        >
          {generateBonusCopy()}
        </Typography>
      </>
    </Zoom>
  );

  const renderCheckboxIcon = () => (
    <Zoom in={claimStep === 2} timeout={2000}>
      <CheckCircleIcon className="checkboxIcon" color="secondary" />
    </Zoom>
  );

  const renderBonusIcon = () => (
    <img
      className="bonusIcon"
      src={iternalBoxWithBonus}
      alt="Box with memories"
    />
  );

  const renderBonusTitleOrCongrats = () => (
    <Typography variant="h3" align="center" className="bonusTitle" gutterBottom>
      {claimStep === 1 ? bonusTitle : 'Congratulations!'}
    </Typography>
  );

  const renderDescription = () => {
    let descriptionText =
      claimStep === 1
        ? `Get +${bonusQuantity} ${generateBonusCopy()} for FREE by claiming this bonus.`
        : `Your ${bonusQuantity} free ${generateBonusCopy()} have been added to your profile. Why not make use of your bonus and record some memories?`;

    if (errors.alreadyClaimed) {
      descriptionText = 'You have already claimed this bonus.';
    }

    return (
      <Typography
        variant="body1"
        align="center"
        className="bonusDescription"
        gutterBottom
      >
        {descriptionText}
      </Typography>
    );
  };

  const renderCtaButtons = () => {
    const ctaButtonProps = {
      color: 'primary',
      size: 'large',
      fullWidth: true,
    };

    if (claimStep === 1) {
      return (
        <Button
          {...ctaButtonProps}
          variant="contained"
          className="ctaButton"
          onClick={async () => claimBonusFunc()}
        >
          Claim Now &rarr;
        </Button>
      );
    }

    return (
      <>
        <Link
          className="buttonLink"
          to={{
            pathname: CREATE_MEMORY_URL,
            state: {
              refreshNeeded: true,
            },
          }}
        >
          <Button {...ctaButtonProps} variant="contained" className="ctaButton">
            + Add a memory
          </Button>
        </Link>
        <Link
          className="buttonLink"
          to={{
            pathname: DASHBOARD_URL,
            state: {
              refreshNeeded: true,
            },
          }}
        >
          <Button {...ctaButtonProps} variant="outlined" className="ctaButton">
            Take me to my dashboard
          </Button>
        </Link>
      </>
    );
  };

  if (errors) {
    if (errors?.notFound) {
      return (
        <ClaimBonusStyles>
          <Paper elevation={3} className="paper">
            <img
              className="bonusIcon"
              src={iternalEmptyBox}
              alt="Box with memories"
            />
            <Typography
              variant="h3"
              align="center"
              className="bonusTitle"
              gutterBottom
            >
              Hmm...
            </Typography>
            <Typography
              variant="body1"
              align="center"
              className="bonusDescription"
              gutterBottom
            >
              This bonus doesn&apos;t seem to exist! Please try your link again.
              If the issue persists and you&apos;re sure this is a valid bonus,
              get in touch.
            </Typography>
          </Paper>
        </ClaimBonusStyles>
      );
    }
  }

  const isLoading = !bonusType || !bonusQuantity;

  if (isLoading) {
    return (
      <ClaimBonusStyles>
        <Paper elevation={3} className="paper loading">
          <Typography align="center" className="loadingText">
            Loading your bonus...
          </Typography>

          <Skeleton
            className="skeletonText"
            variant="text"
            width={100}
            height={30}
          />
          <Skeleton
            className="skeletonText"
            variant="text"
            width={300}
            height={30}
          />

          <Skeleton
            className="skeletonImage"
            variant="rect"
            height={120}
            width={250}
          />

          <Skeleton
            className="skeletonText"
            variant="text"
            width={400}
            height={20}
          />
          <Skeleton
            className="skeletonText"
            variant="text"
            width={400}
            height={20}
          />
          <Skeleton
            className="skeletonText"
            variant="text"
            width={200}
            height={20}
          />

          <Skeleton
            className="skeletonButton"
            variant="rect"
            height={40}
            width={500}
          />
        </Paper>
      </ClaimBonusStyles>
    );
  }

  return (
    <ClaimBonusStyles>
      <Paper elevation={3} className="paper">
        {claimStep === 1 ? renderBonusQtyAndType() : renderCheckboxIcon()}
        {renderBonusIcon()}
        {renderBonusTitleOrCongrats()}
        {renderDescription()}
        {renderCtaButtons()}
      </Paper>
    </ClaimBonusStyles>
  );
};

ClaimBonus.propTypes = {
  /**
   * What is the user ID of the claimant?
   */
  userId: PropTypes.string,
  /**
   * What is ID of the bonus being claimed?
   */
  bonusId: PropTypes.string,
  /**
   * Has this bonus been claimed already?
   */
  claimStep: PropTypes.number,
  /**
   * What is the title of this bonus?
   */
  bonusTitle: PropTypes.bool,
  /**
   * What is the quantity of the bonus being given (memories or days of premium)?
   */
  bonusQuantity: PropTypes.number,
  /**
   * What is the type of bonus being given? (`memories` or `premium`)
   */
  bonusType: PropTypes.oneOf(['memories', 'premium']),
  /**
   * Provide a function for claiming a bonus
   */
  claimBonusFunc: PropTypes.func,
  /**
   * Provide an object which specifies if the bonus was `notFound` or `alreadyClaimed`
   */
  errors: PropTypes.shape({
    notFound: PropTypes.bool,
    alreadyClaimed: PropTypes.bool,
  }),
};

ClaimBonus.defaultProps = {
  userId: undefined,
  bonusId: undefined,
  claimStep: undefined,
  bonusTitle: undefined,
  bonusQuantity: undefined,
  bonusType: undefined,
  claimBonusFunc: () => {},
  errors: {
    notFound: false,
    alreadyClaimed: false,
  },
};
