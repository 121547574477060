// React
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// Icons - FREE
import CreateMemoryIcon from '@material-ui/icons/AddCircleOutline';
import CreateMilestoneIcon from '@material-ui/icons/Star';
import FriendsAndFamilyIcon from '@material-ui/icons/SupervisedUserCircle';
import EmailTheFutureIcon from '@material-ui/icons/MailOutline';
import ThisOrThatIcon from '@material-ui/icons/Forum';

// Icons - PREMIUM
import ColorizePhotoIcon from '@material-ui/icons/ColorLens';

// Component
import { Features as FeaturesComponent } from './Features';

export default {
  title: 'Dashboard Blocks / Features',
};

const currentFeatures = [
  {
    text: 'Create a memory',
    icon: <CreateMemoryIcon fontSize="large" />,
    background: '#A0C6D9',
    url: '/create-memory',
  },
  {
    text: 'Add Friends & Family',
    icon: <FriendsAndFamilyIcon fontSize="large" />,
    background: '#FFD467',
    url: '/friends-and-family',
  },
  {
    text: `Play "This or That?"`,
    icon: <ThisOrThatIcon fontSize="large" />,
    background: '#F8B689',
    url: '/thisorthat',
  },
  {
    text: 'Create a milestone',
    icon: <CreateMilestoneIcon fontSize="large" />,
    background: '#DA6C6C',
    url: '/milestone',
  },
  {
    text: 'Email the future',
    icon: <EmailTheFutureIcon fontSize="large" />,
    background: '#8499C9',
    url: '/email-the-future',
  },
];

const upcomingFeatures = [
  {
    text: 'Colorize a photo',
    icon: <ColorizePhotoIcon fontSize="large" />,
    background: 'rgb(255, 196, 236)',
    url: '',
    premiumFeature: true,
  },
  {
    text: 'Discover your AI assistant',
    icon: <ColorizePhotoIcon fontSize="large" />,
    background: 'rgb(255, 196, 236)',
    url: '',
    premiumFeature: true,
  },
  {
    text: 'Build your family tree',
    icon: <ColorizePhotoIcon fontSize="large" />,
    background: 'rgb(255, 196, 236)',
    url: '',
    premiumFeature: true,
  },
  {
    text: 'Animate still photos',
    icon: <ColorizePhotoIcon fontSize="large" />,
    background: 'rgb(255, 196, 236)',
    url: '',
    premiumFeature: true,
  },
];

const FeaturesTemplate = args => (
  <Router>
    <FeaturesComponent {...args} />
  </Router>
);

export const CurrentFeatures = FeaturesTemplate.bind({});
CurrentFeatures.args = {
  featureCards: currentFeatures,
  userHasPremium: false,
};

export const ExampleFeatures = FeaturesTemplate.bind({});
ExampleFeatures.args = {
  featureCards: [...currentFeatures, ...upcomingFeatures],
  userHasPremium: false,
};
