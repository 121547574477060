import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderWrapper = styled.div`
  /* margin: 12px auto;
  flex-direction: column;
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  align-items: center; */
  margin:2rem 0rem;
`;

export const nameSpan = styled.span`
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 28px;
  color: var(--neutral-dark);
`;

export const H1 = styled.h1`
  font-size: 2.6rem;
`;

export const HeaderContant = styled.div`
  & span {
    margin: 0.8rem 0px;
    display: block;
    line-height: 20px;
  }
`;

export const PinkLink = styled(Link)`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: var(--secondary-aqua)};
`;
