import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const IternalSwitchStyle = withStyles(theme => ({
  root: {
    width: 42,
    height: 20,
    padding: 5,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.primary.dark,
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.primary.dark,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: '999rem',
    opacity: 1,
    backgroundColor: theme.palette.primary.light,
  },
  checked: {},
}))(Switch);

export const IternalSwitch = ({ option1, option2, startingCheck=false, ...props }) => {
  return (
    <Typography component="div" {...props}>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>{option1}</Grid>
        <Grid item>
          <IternalSwitchStyle checked={startingCheck} />
        </Grid>
        <Grid item>{option2}</Grid>
      </Grid>
    </Typography>
  );
};

IternalSwitch.propTypes = {
  /**
   * What should the switch say on the left side?
   */
  option1: PropTypes.string,
  /**
   * What should the switch say on the right side?
   */
  option2: PropTypes.string,
};

IternalSwitch.defaultProps = {
  option1: 'Off',
  option2: 'On',
};
