import styled from 'styled-components';
import { breakpointsMax } from '../../../../constants/breakPoints';

export const Main = styled.main`
  display: flex;
  margin: 64px auto 0;
  flex-direction: column;
  padding-bottom: 64px;
  
  @media (max-width: 600px) {
    margin: 56px auto 0;
  }

  @media ${breakpointsMax.tablet} {
    .friend-card-block {
      height: 100% !important;
    }
  }
`;