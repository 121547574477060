import styled from 'styled-components';

export const RemainingAllowanceStyles = styled.div`
  text-align: center;
  background-color: rgba(128, 128, 128, 0.05);
  padding: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.05);

  .circularProgress {
    margin: 0 auto !important;
    padding: 1rem;
  }

  .linearProgress {
    margin: 0.75rem auto;
  }

  .allowanceText {
    margin-top:1rem;
    margin-bottom:0rem;

  }

  .emphasis {
    font-size: 1rem;
    font-weight: bold;
    border-bottom: solid 1px rgba(0, 0, 0, 0.25);
  }
`;
