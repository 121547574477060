import axios from 'axios';
import Swal from 'sweetalert2';

import {
  API_LEGACY_URL,
} from '../constants/apiRoutes';

import makeError from './makeError';

const getLegacyContact = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${API_LEGACY_URL}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const createLegacyContact = async ({ legacyContact, options }) => {
  const {
    name,
    email,
    relation='',
  } = legacyContact;

  try {
    const { data } = await axios.post(`${API_LEGACY_URL}`, {
      name,
      email,
      relation,
    });
    
    if (data) {
      Swal.fire({
        title: "You've added your legacy contact",
        type: 'success',
        timeout: 3000,
      });
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const updateLegacyContact = async ({ legacyContact, options }) => {
  const {
    legacyContactId,
    userId,
    email,
    name,
    relation,
  } = legacyContact;

  try {
    const { data } = await axios.patch(`${API_LEGACY_URL}`, {
      legacyContactId,
      userId,
      email,
      name,
      relation,
    });
    if (data) {
      Swal.fire({
        title: 'Your legacy contact has been updated!',
        type: 'success',
        timeout: 3000,
      });
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const deleteLegacyContact = async ({ id, options }) => {
  try {
    const { data } = await axios.delete(`${API_LEGACY_URL}/${id}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const legacyContacts = {
    getLegacyContact,
    createLegacyContact,
    updateLegacyContact,
    deleteLegacyContact,
};

export default legacyContacts;
