import React from "react";
import { Typography, Paper, Button, Grid } from "@material-ui/core";

import Close from "@material-ui/icons/CancelOutlined";

export default function InformationPopUp({ text,  onClose }) {
  return (
    <Paper
      id="information-popup"
      style={{
        position: "absolute",
        maxWidth: "472px",
        backgroundColor: "#0DA7A7",
        padding: "3rem",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "2rem",
        width: '70%'
      }}
    >
      <Grid style={{ display: "flex", justifyContent: "flex-end", marginTop:'-2rem', marginRight:'-2rem' }}>
        <Close onClick={() => onClose()} style={{ color: "white", cursor: 'pointer' }} />
      </Grid>
   
      <Typography
        variant="h5"
        gutterBottom
        style={{ textAlign: "left", marginTop: "3rem", marginBottom:'3rem', color: "white" }}
      >
        {text}
      </Typography>
      <Grid style={{ display: "flex", justifyContent: "flex-end",marginBottom:'-1rem'}}>
        <img
          src={require("../../../../assets/onboarding/logo-white.png")}
          style={{ maxWidth: "100%", width: "100px" }}
        />
      </Grid>
    </Paper>
  );
}
