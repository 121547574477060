import React from 'react';

const Dropdown = ({ width, height, color }) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.293 4.29291L14.7072 5.70712L8.00008 12.4142L1.29297 5.70712L2.70718 4.29291L8.00008 9.5858L13.293 4.29291Z"
      fill={color || 'white'}
    />
  </svg>
);

export default Dropdown;
