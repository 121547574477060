import styled, { keyframes } from 'styled-components';

const FadeInOut = keyframes`
  0% {opacity: 1;}
  50% {opacity: 0.5;}
  100% {opacity: 1;}
`;

export const TitleAnimationWrapper = styled.div`
  animation: ${FadeInOut} 1.5s ease-in-out infinite;
`;

export const LoadingTextStyle = {
  color: 'white',
  margin: 'auto',
  fontSize: '4rem',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  letterSpacing: '0.8rem',
  height: '100%',
  width: '100%',
  textAlign: 'center',
  position: 'absolute',
  top: '39.5%',
};

export const LoaderStyle = styled.div`
  margin: 0 auto !important;
  height: 100%;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 30%;
  opacity: 0.05 !important;
`;
