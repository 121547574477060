import * as yup from 'yup';

// SPECIFIC VALIDATION FUNCTIONS
// CREATE / EDIT MEMORY

// Define tomorrow's date so that users can still post a memory on the day that it happens
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
tomorrow.setHours(0, 0, 0, 0);

//  Validate Description input field in create memory
yup.addMethod(yup.string, 'descriptionValidator', function descriptionValidator(
  length,
) {
  // eslint-disable-next-line func-names
  return this.test(function(value) {
    if (!value) return '';
    return value.length >= length
      ? value
      : this.createError({
          message: `Needs to be at least ${length} character(s) long`,
        });
  });
});

// validates description input in create memory
const optionalOrMin = length => value => {
  if (value) return yup.string().descriptionValidator(length); // if it was a fine value check normally
  return yup.string().ensure(); // this one sets .default('') and coerces null to ''
};

// VALIDATION SCHEMAS
const schemas = {
  signUp: {
    email: yup
      .string()
      .email('* A valid email is required')
      .required('* Please enter your email'),
    password: yup
      .string()
      .min(1, '* A password is required')
      .matches(/[a-z]/g, '* Password requires min. 1 lowercase letter')
      .matches(/[A-Z]/g, '* Password requires min. 1 uppercase letter')
      .matches(/[\d]/g, '* Password requires min. 1 number')
      .matches(
        /[!@#$%^&*()-+_]+/g,
        '* Password requires min. 1 special character',
      ),
    agreeTerms: yup
      .bool()
      .oneOf([true], '* You need to accept the terms of use'),
  },
  groupSignUp: {
    email: yup
      .string()
      .email('* A valid email is required')
      .required('* Please enter your email'),
    password: yup.string().min(1, '* A password is required'),
    // .matches(/[a-z]/g, '* Password requires min. 1 lowercase letter')
    // .matches(/[A-Z]/g, '* Password requires min. 1 uppercase letter')
    // .matches(/[\d]/g, '* Password requires min. 1 number'),
    name: yup.string().required('* Please enter your name'),
    dateOfBirth: yup
      .date()
      .typeError('* A valid date is require')
      .required('* Please enter a date'),
  },
  googleSignUp: {
    email: yup
      .string()
      .email('* A valid email is required')
      .required('* Please enter your email'),
    googleId: yup.string().required('* Invalid Google Login'),
    agreeTerms: yup
      .bool()
      .oneOf([true], '* You need to accept the terms of use'),
  },
  facebookSignUp: {
    email: yup
      .string()
      .email('* A valid email is required')
      .required('* Please enter your email'),
    facebookId: yup.string().required('* Invalid Facebook Login'),
    agreeTerms: yup
      .bool()
      .oneOf([true], '* You need to accept the terms of use'),
  },
  login: {
    email: yup
      .string()
      .email('* A valid email is required')
      .required('* Please enter your email'),
    password: yup.string().min(1, '* Please enter your password'),
  },
  googleLogin: {
    email: yup
      .string()
      .email('* A valid email is required')
      .required('* Please enter your email'),
    googleId: yup.string().required('* Invalid Google Login'),
  },
  facebookLogin: {
    email: yup
      .string()
      .email('* A valid email is required')
      .required('* Please enter your email'),
    facebookId: yup.string().required('* Invalid Facebook Login'),
  },
  createEditMemory: {
    voiceOrDescriptionIncluded: yup
      .bool()
      .oneOf([true], '* Memory details are required')
      .required('* Memory details are required')
      .nullable(),
    title: yup
      .string()
      .min(3, '* Your title needs to be at least 3 characters long')
      .required('* Title is required'),
    date: yup
      .date()
      .typeError(`* A valid date is required`)
      .required('* A valid date is required'),
    images: yup.array(),
    isPublic: yup.bool(),
    description: yup.lazy(optionalOrMin(3)),
  },
  profileBasics: {
    name: yup
      .string()
      .min(3, '* Must be longer than 2 characters')
      .max(20, '* Nice try, nobody has a name that long')
      .required('* Please enter your name'),
    birthday: yup
      .date()
      .typeError('* Please pick a date')
      .required('* A valid birthday is required'),
    hometown: yup.string(),
    email: yup
      .string()
      .email('* Invalid email address')
      .required('* Please enter your email'),
  },
  legacyContact: {
    legacyName: yup.string().required('* Please enter a name'),
    legacyEmail: yup
      .string()
      .email('* Please enter a valid email')
      .required('* Please enter an email'),
    legacyRelation: yup.string(),
  },
  futureEmail: {
    email: yup
      .string()
      .email('* Please enter a valid email')
      .required('* Please enter an email'),
    text: yup
      .string()
      .required('* Please enter some text for your future email'),
    dateToSend: yup
      .date()
      .typeError('* Please provide a valid date')
      .required('* Please provide a date to send the email'),
  },
  createMemoryForFriend: {
    uploaderName: yup.string().required('* Please enter a name'),
    uploaderEmail: yup
      .string()
      .email('* Please enter a valid email')
      .required('* Please enter an email'),
    voiceOrDescriptionIncluded: yup
      .bool()
      .oneOf([true], '* Memory details are required')
      .required('* Memory details are required')
      .nullable(),
    title: yup
      .string()
      .min(3, '* Your title needs to be at least 3 characters long')
      .required('* Title is required'),
    date: yup
      .date()
      .typeError(`* A valid date is required`)
      .required('* A valid date is required'),
    images: yup.array(),
    description: yup.lazy(optionalOrMin(3)),
  },
  groupMemory: {
    title: yup.string().required('Please add a title'),
    date: yup
      .date()
      .typeError(`A valid date is required`)
      .required('A valid date is required'),
    name: yup.string().required('Please enter your name'),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Please enter an email'),
    hasMadeMemory: yup
      .bool()
      .oneOf([true], 'Memory details are required')
      .required('Memory details are required')
      .nullable(),
  },
};

// EXPORT FUNCTIONS TO VALIDATE DATA AND CREATE ERRORS

// validates schema based on form data (e.g. sign up / login)
export const validate = (label, formData) => {
  // create individual schema from schemas object
  const schema = yup.object().shape(schemas[label]);
  return schema.validate(formData, { abortEarly: false });
};

// creates 'readable errors'
export const createErrors = validationErrors => {
  const submitErrors = {};
  if (validationErrors && validationErrors.inner) {
    validationErrors.inner.forEach(element => {
      // if it doesnt exist, make it
      if (!submitErrors[element.path]) {
        submitErrors[element.path] = [];
      }
      // it it does, spread it, and add the message
      submitErrors[element.path] = [
        ...submitErrors[element.path],
        element.message,
      ];
    });
    return submitErrors;
  }
  return {};
};
