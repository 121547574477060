import styled from 'styled-components';

export const SocialMediaButtonsStyles = styled.div`
  margin: 0 auto !important;

  .buttonsWrapper {
    border-radius: 0.5rem;
    /* background: rgba(0, 0, 0, 0.015); */
    width: 100% !important;
    /* margin: 0 auto !important; */
    padding: 0.5rem;
    overflow-x: auto;
    overflow-y: hidden;

    .button {
      padding: 0.75rem;
      display: inline-block;
    }
  }

  .icon {
    width: 80px;
    height: 80px;
    border-radius: 1000px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .fb {
    color: #2196f3;
  }

  .wa {
    color: #65cf72;
  }

  svg {
    width: 30px;
    height: 30px;
  }
`;
