// React
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import GroupPublic from "../GroupPublic";

// bottom nav

// MUI Icons
import MemoryIcon from "@material-ui/icons/Book";
import Plus from "@material-ui/icons/Add";
import MilestoneIcon from "@material-ui/icons/Star";
import Photos from "@material-ui/icons/PhotoCamera";
import Groups from "@material-ui/icons/People";
import Friends from "@material-ui/icons/EmojiPeople";
import Profile from "@material-ui/icons/Face";
import Stories from "@material-ui/icons/MenuBook";
import Camera from "@material-ui/icons/PhotoCamera";
import Add from "@material-ui/icons/Add";
import HelpIcon from "@material-ui/icons/Help";

import * as C from "../../../../constants/constantUrls";

// Components
import {
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Fade,
  Collapse,
  Zoom,
  Grid,
  Modal,
  Select,
  InputLabel,
} from "@material-ui/core";
import ReactGA from "react-ga";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import HeaderShareMemory from "../../../Common/HeaderShareMemory";
import imagePlaceHolder from "../../../../assets/person-placeholder.jpg";
import MemoriesTable from "../../../Common/Tables/MemoriesTable";
import { NewTimeline } from "../../../../NewComponents/Dashboard/Blocks/NewTimeline/NewTimeline";
import ViewGroupButton from "../../../Common/GroupComponents/Buttons/ViewGroupButton";
import CallToActionButton from "../../../Common/GroupComponents/Buttons/CallToActionButton";
import CancelActionButton from "../../../Common/GroupComponents/Buttons/CancelActionButton";

// Constants
import {
  SIGNUP_URL,
  GROUPS_CREATE_URL,
} from "../../../../constants/constantUrls";
import { linkTokens } from "../../../../api";

// Styles
import { Main, MemoryButtonWrapper } from "./style";
import family from "../../../../assets/family.png";
import world from "../../../../assets/world.png";
import half from "../../../../assets/onboarding/half.png";

const GroupsPage = ({ match, history }) => {
  // const { shortId } = match.params;
  const [groups, setGroups] = useState([]);
  const [createModal, setCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    // get user's groups
    getUserGroups();
  }, []);

  /* 
    Functions needed:
    -- Get User's Groups
    -- Create Group
  * */

  const handleClick = (event) => {
    setIsShown((current) => !current);
  };

  const getUserGroups = async () => {
    const { data } = await axios.get("/api/groups");
    setGroups(data);
    setIsLoading(false);
  };

  const RenderGroupListOrNothing = () => {
    if (groups.length > 0) {
      return (
        <Grid>
          <Typography
            align="left"
            color="primary"
            variant="h4"
            style={{
              marginTop: "1rem",
              marginLeft: window.innerWidth < 600 ? "1rem" : "3rem",
              marginRight: window.innerWidth < 600 ? "1rem" : "3rem",
            }}
          >
            Your Groups
          </Typography>
          <Typography
            align="left"
            variant="body1"
            style={{
              marginTop: "1rem",
              marginLeft: window.innerWidth < 600 ? "1rem" : "3rem",
              marginRight: window.innerWidth < 600 ? "1rem" : "3rem",
            }}
          >
            Here are the groups you've joined that you or other Iternal members
            have created.{" "}
          </Typography>

          <Grid
            container
            spacing={2}
            style={{
              // padding: "1rem",
              marginTop: "2rem",
              paddingBottom: "1rem",
              maxHeight: "30vh",
              overflowY: "scroll",
              paddingLeft: window.innerWidth < 1400 ? "1rem" : "4rem",
              paddingRight: window.innerWidth < 1400 ? "1rem" : "6rem",
              display: "flex",
              justifyContent: "center",
              // paddingLeft: "3rem",
              //     paddingRight: "3rem",
            }}
          >
            {groups.map((group) => (
              <Grid
                container
                xs={12}
                sm={6}
                md={4}
                spacing={2}
                style={{
                  // padding: "1rem",
                  paddingBottom: "1.5rem",

                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Paper
                  elevation={0}
                  style={{
                    padding: "2rem",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "150px",
                    height: "150px",
                    borderRadius: "20px",
                    // border: "solid 2px #0bafaa",
                    // boxShadow: 'rgba(0, 0, 0, 0.15) 2.95px 2.95px 2.6px',
                    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 1.6px',

                    backgroundColor: "#e5f3f3",

                    color: "white",
                    margin: "1rem",
                    flexDirection: "column",
                  }}
                >
                  {/* <Typography>{group.groupDescription}</Typography> */}
                  <NavLink
                    to={`/group/${group.groupShortCode}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Avatar
                      src={
                        group?.profilePicture?.imagePath
                          ? group.profilePicture.imagePath
                          : require("../../../../assets/orange_logo.png")
                      }
                      alt="Your profile avatar"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        width: "80px",
                        height: "80px",
                        margin: "0 auto",
                      }}
                    />
                    <Typography
                      style={{
                        textOverflow: "ellipsis",
                        wordWrap: "break-word",
                        overflow: "hidden",
                        maxHeight: "4rem",
                        lineHeight: "2rem",
                        color: "#0bafaa",
                        marginTop: "0.5rem",
                        fontSize: "1.4rem",
                        fontWeight: "bolder",
                      }}
                    >
                      {group.groupName}
                    </Typography>
                    {/* <ViewGroupButton
                    title="View"
                    clickFunction={() =>
                      history.push(`/group/${group.groupShortCode}`)
                    }
                  /> */}

                    {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push(`/group/${group.groupShortCode}`)}
                >
                  View
                </Button> */}
                  </NavLink>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <div>
            <Typography
              align="left"
              color="primary"
              variant="h4"
              style={{
                marginTop: "1rem",
                marginLeft: window.innerWidth < 600 ? "1rem" : "3rem",
              }}
            >
              Public Groups
            </Typography>

            <Typography
              align="left"
              variant="body1"
              style={{
                marginTop: "1rem",
                marginLeft: window.innerWidth < 600 ? "1rem" : "3rem",
                marginRight: window.innerWidth < 600 ? "1rem" : "3rem",
              }}
            >
              Here are some of our example groups made by the Iternal Team for
              you to share your stories with other members
            </Typography>

            <div style={{ width: window.innerWidth < 600 ? "100%" : "90%" }}>
              <Grid
                container
                xs={12}
                spacing={2}
                style={{
                  padding: "1rem",
                  marginTop: "0rem",
                  marginLeft: window.innerWidth < 600 ? "1rem" : "3rem",
                  marginRight: "3rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <GroupPublic />
              </Grid>
            </div>
          </div>
        </Grid>
      );
    }
    return (
      <div>
        <Typography
          align="left"
          color="primary"
          variant="h4"
          style={{
            marginTop: "2rem",
            marginLeft: window.innerWidth < 600 ? "1rem" : "3rem",
          }}
        >
          Public Groups
        </Typography>

        <Typography
          align="left"
          variant="body1"
          style={{
            marginTop: "2rem",
            marginLeft: window.innerWidth < 600 ? "1rem" : "3rem",
            marginRight: window.innerWidth < 600 ? "1rem" : "3rem",
          }}
        >
          Here are some of our example groups for you to share your stories with
          other members
        </Typography>

        <div style={{ width: "90%" }}>
          <Grid
            container
            xs={12}
            spacing={2}
            style={{
              padding: "1rem",
              marginTop: "1rem",
              marginLeft: window.innerWidth < 600 ? "1rem" : "3rem",
              marginRight: window.innerWidth < 600 ? "1rem" : "3rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <GroupPublic />
          </Grid>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <Main />;
  }
  return (
    <Main style={{ marginLeft: window.innerWidth < 1024 ? "0rem" : "3rem" }}>
      {/* <RenderGroupListOrNothing /> */}
      <Typography
        align="center"
        variant="h4"
        gutterBottom
        style={{
          marginBottom: "1rem",
          color: "white",
          fontSize: window.innerWidth < 1024 ? "3rem" : "4rem",
        }}
      >
        Groups
      </Typography>
      <Button
        onClick={handleClick}
        variant="text"
        style={{ fontWeight: "bold", color: "white", marginBottom: "1rem" }}
      >
        <HelpIcon />
        &nbsp; Show me how to create a group
      </Button>
      <Grid
        style={{
          paddingLeft: window.innerWidth < 1024 ? "1rem" : "0rem",
          paddingRight: window.innerWidth < 1024 ? "1rem" : "0rem",
          height: '50vh',

        }}
      >
        <div
          style={{
            maxWidth: "1000px",
            margin: "0 auto",
            textAlign: "center",
            borderRadius: "20px",
            background: "white",
            minHeight: "80vh",
            height: "50vh",
            overflowX: "scroll",
            overflowY: "scroll",
            marginBottom: window.innerWidth < 1024 ? "8rem" : "0rem",
          }}
        >
          <div style={{}}>
            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                marginTop: "0rem",
                paddingLeft: window.innerWidth < 600 ? "1rem" : "3rem",
                paddingRight: window.innerWidth < 600 ? "1rem" : "3rem",
                paddingTop: window.innerWidth < 600 ? "1rem" : "2rem",
                paddingBottom: window.innerWidth < 600 ? "1rem" : "1rem",
              }}
            >
              Welcome to your Iternal Group Portal! Here you will be able to
              access all the different groups you’re part of.{" "}
              <span
                style={{ display: window.innerWidth < 600 ? "none" : "none" }}
              >
                {" "}
                <br></br>
                <Typography variant="body1">
                  {" "}
                  Iternal groups lets you create a rich timeline of history,
                  stories and memories with other like-minded individuals. Share
                  your experiences with other members and watch the group grow…{" "}
                </Typography>
              </span>
            </Typography>
            {/* <Typography
              variant="body1"
              style={{
                textAlign: "left",
                marginTop: "0rem",
                paddingLeft: window.innerWidth < 600 ? "1rem" : "3rem",
                paddingBottom: "1rem",
                paddingRight: window.innerWidth < 600 ? "1rem" : "2rem",
                display: window.innerWidth < 600 ? "none" : "block",
              }}
            >
              Iternal groups lets you create a rich timeline of history, stories
              and memories with other like-minded individuals. Share your
              experiences with other members and watch the group grow…
            </Typography> */}
            {isShown && (
              <iframe
                width="90%"
                height="415"
                src="https://www.youtube.com/embed/O2xOIUgf0yo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                style={{}}
              ></iframe>
            )}
            <CallToActionButton
              title="Create Group"
              startIcon={
                <Add
                  style={{
                    fontSize: "1.5rem",
                    marginRight: "-0.3rem",
                  }}
                />
              }
              clickFunction={() => {
                history.push(GROUPS_CREATE_URL);
              }}
            />

            {/* <Button
            onClick={() => {
              // history.push('')
            }}
            variant="contained"
            color="primary"
            style={{
              marginTop: '1rem',
            }}
          >
            Create New Group
          </Button> */}
          </div>
          <RenderGroupListOrNothing />
        </div>
      </Grid>
    </Main>
  );
};

export default GroupsPage;
