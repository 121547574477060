import React from 'react';
import PropTypes from 'prop-types';

// MUI
import { Paper, Typography, Button, Collapse } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import SendIcon from '@material-ui/icons/Send';

// Helpers
import { formatCleanDate } from '../../../../helpers/formatDate';

import EmailTheFutureIcon from './icon.png';

// Styled components
import {
  TitleStyle,
  SubtitleStyle,
  BlurbStyle,
  MainPaperStyle,
  YourEmailsTitleStyle,
  SubPaperStyle,
} from './Information.style';

export function Information({
  title = '',
  subtitle = '',
  blurb = '',
  yourEmailsTitle = '',
  yourEmailsBlurb = '',
  futureEmails = [],
  nextFunc = () => {},
  ...props
}) {
  const columns = [
    {
      field: 'dateToSend',
      headerName: 'Delivery Date',
      description: 'The date on which the email will arrive',
      width: 160,
    },
    {
      field: 'email',
      headerName: 'To',
      description: 'The email address this letter will be sent to',
      width: 200,
    },
    {
      field: 'length',
      headerName: 'Words',
      description: 'How long this email is',
      width: 130,
    },
  ];

  const rows = futureEmails.map(({ _id, dateToSend, email, message }) => ({
    id: _id,
    dateToSend: formatCleanDate(dateToSend),
    email,
    length: message.toString().length,
  }));

  return (
    <>
      <Paper style={MainPaperStyle} {...props}>
        <Typography style={TitleStyle} variant="h4" color="primary">
          {title}
        </Typography>
        <img
          src={EmailTheFutureIcon}
          alt="Email The Future Icon"
          style={{ height: '100px' }}
        />
        <Typography style={SubtitleStyle} variant="h6">
          {subtitle}
        </Typography>
        <Typography style={BlurbStyle}>{blurb}</Typography>
        <Button
          onClick={() => nextFunc()}
          style={{ width: '80%' }}
          color="primary"
          size="large"
          variant="contained"
        >
          Email The Future
          <SendIcon style={{ paddingLeft: '1rem' }} />
        </Button>
      </Paper>

      <Collapse in={futureEmails.length > 0}>
        <Paper style={SubPaperStyle} {...props}>
          <Typography style={YourEmailsTitleStyle} variant="h5" color="primary">
            {yourEmailsTitle}
          </Typography>
          <Typography style={BlurbStyle}>{yourEmailsBlurb}</Typography>
          <div style={{ height: 350, width: '100%' }}>
            <DataGrid rows={rows} columns={columns} pageSize={20} />
          </div>
        </Paper>
      </Collapse>
    </>
  );
}

Information.propTypes = {
  /**
   * What should the title of this page be?
   */
  title: PropTypes.string,
  /**
   * What should the subtitle of this page be?
   */
  subtitle: PropTypes.string,
  /**
   * What should the blurb of this page be?
   */
  blurb: PropTypes.string,
  /**
   * Provide an array of scheduled emails, with `dateToSend` and `email` keys
   */
  futureEmails: PropTypes.arrayOf({
    dateToSend: PropTypes.instanceOf(Date),
    email: PropTypes.string,
  }),
  /**
   * What should the title of the list of your emails be?
   */
  yourEmailsTitle: PropTypes.string,
  /**
   * What should the blurb of the list of your emails be?
   */
  yourEmailsBlurb: PropTypes.string,
  /**
   * Please supply a function for advancing to creating a future memory
   */
  nextFunc: PropTypes.func,
};

export const altCopywriting = {
  subtitle:
    'Imagine receiving a message from your past self or someone else with predictions and aspirations for the future or reminding you of forgotten things - cool, right?',
  blurb:
    'Use our easy service to draft a message to send to either yourself or a loved one to send to them in the future. You can choose how long to wait until it sends and who it goes to!',
};

Information.defaultProps = {
  title: 'Email The Future',
  subtitle:
    'Imagine receiving a message from your past self or someone else with predictions and aspirations for the future or reminding you of forgotten things - cool, right?',
  blurb:
    'Use our easy service to draft a message to send to either yourself or a loved one to send to them in the future. You can choose how long to wait until it sends and who it goes to!',
  yourEmailsTitle: 'Your Future Emails',
  yourEmailsBlurb: "Here you can see all of the emails you have scheduled to send. Why can't you view them? Our idea for emailing the future is that you write it and forget about it! That way when you or the recipient receive it in however long, you get a lovely surprise!",
  futureEmails: [
    {
      _id: 1,
      dateToSend: new Date(),
      email: 'test@iternal.life',
      message: ['Hello there!'],
    },
    {
      _id: 2,
      dateToSend: new Date(),
      email: 'test2@iternal.life',
      message: ['Hello once again...'],
    },
    {
      _id: 3,
      dateToSend: new Date(),
      email: 'test3@iternal.life',
      message: ['Never gonna give you up, never gonna let you down'],
    },
  ],
  nextFunc: () => {},
};
