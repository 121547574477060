import React from 'react';
import PropTypes from 'prop-types';

// Styled Components
import { DividerWrapper, IternalLogoWrapper } from './Divider.style';

// Iternal Logo Symbol
import IternalLogoSymbol from '../Logo/iternal_logo_symbol.svg';

export const Divider = ({ logo, text, opacity, ...props }) => (
  <DividerWrapper style={{ opacity }} {...props}>
    {logo && <IternalLogoWrapper alt="Divider" src={IternalLogoSymbol} />}
    {logo && text ? <>&nbsp;</> : null}
    {text && text}
  </DividerWrapper>
);

Divider.propTypes = {
  /**
   * Would you like to include the Iternal Logo in this divider?
   */
  logo: PropTypes.bool,
  /**
   * What text should this divier have?
   */
  text: PropTypes.string,
  /**
   * How opaque should the divider be?
   */
  opacity: PropTypes.number,
};

Divider.defaultProps = {
  logo: false,
  text: undefined,
  opacity: 0.2,
};
