import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ModalBackground, ModalContent } from "./style";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Modal,
  Backdrop,
  ButtonBase,
  Avatar,
  TextField,
  Fade,
} from "@material-ui/core";
import Close from "@material-ui/icons/CancelOutlined";
import SocialShare from "../SocialShare";
import EmailList from "../EmailList";
import InformationPopUp from "../FriendsModals/InformationModal";
import Swal from "sweetalert2";
import { linkTokens, permissionAccess } from "../../../api";

const FriendModal = ({ onClose = () => {}, name = "" }) => {
  const [inviteLink, setInviteLink] = useState("some value");
  const [emails, setEmails] = useState([]);
  const [friends, setFriends] = useState([]);
  const [inviteMessage, setInviteMessage] = useState("");
  const [showLinkCopyModal, setShowLinkCopyModal] = useState(false);
  const [showSendRequestModal, setShowSendRequestModal] = useState(false);

  useEffect(() => {
    fetchLinkToken();
    getGivenFriendsList();
  }, []);

  // get invite link
  const fetchLinkToken = async () => {
    const {
      data,
      error,
    } = await linkTokens.getActiveLinkTokenForLoggedInUser();
    if (!error) {
      setInviteLink(`${window.location.origin}/sign-up/${data.shortId}`);
    }
  };

  // get invited friends list
  const getGivenFriendsList = async () => {
    const { data, error } = await permissionAccess.getAllGivenPermissionAccess({
      options: { redirectToRelatedPage: true },
    });
    if (!error) {
      setFriends(data);
    }
  };

  // send invite
  const addNewEmail = async () => {
    // check if email is already in list of emails
    const sharedWith = friends.map((e) => e.sharedWithEmail);
    let emailExists = false;
    emails.map((email) => {
      if (sharedWith.includes(email)) {
        emailExists = true;
      }
    });

    if (emailExists) {
      return Swal.fire({
        title: "This email has already access to your memories",
        text: "Please choose a different one",
        type: "warning",
        confirmButtonColor: "#66CC66",
        cancelButtonColor: "#8a8787",
        confirmButtonText: "Ok",
      });
    }
    if (!emailExists) {
      const { error } = await permissionAccess.create({
        sharedWithEmails: emails,
        customMessage: inviteMessage,
      });

      if (!error) {
        setEmails([]);
        await getGivenFriendsList();
        // ReactGA.event({
        //   category: "Friends",
        //   action: "Added Friend",
        // });
        fireSuccessInviteModal();
      }
    }
  };

  const fireLinkCopyModal = () => {
    setShowLinkCopyModal(true);
    setTimeout(() => {
      setShowLinkCopyModal(false);
    }, 3000);
  };

  const fireSuccessInviteModal = () => {
    setShowSendRequestModal(true);
    setTimeout(() => {
      setShowSendRequestModal(false);
    }, 4000);
  };

  return (
    <ModalBackground>
      <ModalContent>
        <Modal open={showLinkCopyModal}>
          <InformationPopUp
            onClose={() => setShowLinkCopyModal(false)}
            text="You copied the link!"
          />
        </Modal>
        <Modal open={showSendRequestModal}>
          <InformationPopUp
            onClose={() => setShowSendRequestModal(false)}
            text="Your invitation has been sent!"
          />
        </Modal>

        <Paper
          style={{
            textAlign: "center",
            borderRadius: "2rem",
            overflowY: "scroll",
            padding: window.innerWidth < 700 ? "0rem" : "1rem",
            maxHeight: "90vh",
            margin: "1rem",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Close
              color="primary"
              style={{ cursor: "pointer", padding: "1rem" }}
              onClick={() => onClose()}
            />
          </div>
          <Typography
            color="primary"
            gutterBottom
            variant="h4"
            style={{
              display: window.innerWidth < 600 ? "none" : "block",
              fontSize: window.innerWidth < 600 ? "1.5rem" : "2rem",
              marginTop: "-1rem",
            }}
          >
            Invite Your Friends To Iternal
          </Typography>
          <Typography
            color="primary"
            gutterBottom
            style={{
              display: window.innerWidth > 600 ? "none" : "block",
              fontSize: "2rem",
              marginTop: "-1rem",
            }}
          >
            Invite Friends
          </Typography>
          <Typography
            style={{
              fontSize: "1.1rem",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            Sharing stories with your friends has been shown to bring people
            closer together.
            <br />
            <span
              style={{
                fontSize: "1rem",
                display: window.innerWidth < 1280 ? "none" : "block",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              By saving their stories, you ensure that they won't be forgotten
              and you could even discover something new about one another.
            </span>
          </Typography>
          <div style={{ display: window.innerWidth > 1280 ? "none" : "block" }}>
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <SocialShare
                link={inviteLink}
                messageBody={
                  "Hey! I'd like to share my memories with you! To sign up to Iternal, click this link"
                }
                messageTitle={"I'd like to share my stories with you"}
              />
            </div>
            <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
              <TextField
                className="inputRounded"
                variant="outlined"
                color="primary"
                disabled
                value={inviteLink}
                style={{ borderRadius: "0px", width: "60%", height: "50px" }}
              >
                Link To Share
              </TextField>
              <CopyToClipboard
                text={inviteLink}
                onCopy={() => fireLinkCopyModal()}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    borderTopLeftRadius: "0",
                    borderBottomLeftRadius: "0",
                    height: "58px",
                    fontWeight: "bolder",
                  }}
                  s
                >
                  Copy Link
                </Button>
              </CopyToClipboard>
              <Grid></Grid>
            </div>
          </div>
          <Typography
            color="primary"
            style={{
              textAlign: "left",
              marginLeft: "2.5rem",
              marginTop: "1rem",
              marginBottom: "-1rem",
              fontSize: "1.25rem",
            }}
          >
            Invite By Email
          </Typography>

          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              justifyContent: "center",
              // background: "#9ddfdd",
              // background: "#ececec",
              background: "#F3FAFD",
              padding: "0rem",
              margin: "1rem",
              borderRadius: "6px",
              width: "auto",
            }}
          >
            <Grid id="email-list" item xs={12} lg={6} style={{}}>
              <div
                style={{
                  background: "white",
                  paddingTop: "0rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  height: "91%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  borderRadius: "6px",
                }}
              >
                <div>
                  <Typography
                    variant="h5"
                    style={{
                      fontSize: "0.8rem",
                      textAlign: "left",
                      color: "#2f4342",
                    }}
                  >
                    Add email and press enter
                  </Typography>
                  <EmailList emails={emails} setEmails={setEmails} />
                </div>
                {/* <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ borderRadius: "100px", fontWeight: "bold" }}
                    onClick={async() => await addNewEmail()}
                    disabled={emails.length === 0}
                  >
                    Send Email
                  </Button>
                </div> */}
              </div>
            </Grid>

            <Grid id="email-message" item xs={12} lg={6} style={{}}>
              <div
                style={{
                  background: "white",
                  padding: "1rem",
                  borderRadius: "6px",
                  marginTop: window.innerWidth > 1280 ? "0rem" : "-2.5rem",
                  // border: '1px solid rgba(0, 0, 0, 0.23)'
                }}
              >
                <Typography
                  variant="body1"
                  align="left"
                  gutterBottom
                  style={{ marginTop: "0rem" }}
                >
                  I would like to invite you to be my friend on Iternal.
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    fontSize: "0.8rem",
                    textAlign: "left",
                    marginTop: "1rem",
                    color: "#2f4342",
                  }}
                >
                  Add custom message
                </Typography>
                <TextField
                  fullWidth
                  align="left"
                  value={inviteMessage}
                  onChange={(e) => setInviteMessage(e.target.value)}
                  className="TextField-without-border-radius"
                  multiline={5}
                  minRows={5}
                  variant="outlined"
                  // placeholder="Add a message to your friend here"
                  style={{ borderColor: "white" }}
                />

                <Typography
                  variant="body1"
                  align="left"
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                >
                  Click here to join:
                  <br />
                  <span style={{textDecoration:'underline', color:'#0bafaa'}}>
                  {inviteLink}
                  </span>
                  <br />
                  <br />
                  Kind Regards,
                  <br />
                  {name}
                </Typography>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  style={{
                    borderRadius: "100px",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                  onClick={async () => await addNewEmail()}
                  disabled={emails.length === 0}
                >
                  Send Email
                </Button>
              </div>
            </Grid>
          </Grid>

          <div style={{ display: window.innerWidth < 1280 ? "none" : "block" }}>
            <Typography
              color="primary"
              style={{
                textAlign: "left",
                marginLeft: "2.5rem",
                marginTop: "1rem",
                marginBottom: "-1rem",
                fontSize: "1.25rem",
              }}
            >
              Share on Social Media
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <SocialShare
                link={inviteLink}
                messageBody={
                  "Hey! I'd like to share my memories with you! To sign up to Iternal, click this link"
                }
                messageTitle={"I'd like to share my stories with you"}
              />
            </div>
            <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
              <TextField
                className="inputRounded"
                variant="outlined"
                color="primary"
                disabled
                value={inviteLink}
                style={{ borderRadius: "0px", width: "60%", height: "50px" }}
              >
                Link To Share
              </TextField>
              <CopyToClipboard
                text={inviteLink}
                onCopy={() => fireLinkCopyModal()}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    borderTopLeftRadius: "0",
                    borderBottomLeftRadius: "0",
                    height: "58px",
                    fontWeight: "bolder",
                  }}
                  s
                >
                  Copy Link
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        </Paper>
      </ModalContent>
    </ModalBackground>
  );
};

export default FriendModal;
