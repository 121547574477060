import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { size } from '../../constants/breakPoints';

export const Menu = styled.section`
  width: 100%;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  background: white;
  height: 5rem;

  nav {
    width: 100%;
    border-top: 2px solid var(--primary);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
  }

  @media (min-width: ${size.tablet}) {
    display: none;
  }

  /* a {
    height: 100%;
    margin: 0.2rem;
  } */
`;

export const StyledLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 25%;

  p {
    padding-top: 0.5rem;
    text-transform: uppercase;
    font-size: 0.75rem;
  }
`;
