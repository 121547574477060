import styled from 'styled-components';

export const ModalBackground = styled.div`
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  z-index: 999;
  width: 60%;
  min-width: 320px;

  @media (max-width: 1024px) {
    width:80%;
  }

  @media (max-width: 600px) {
    width:100%;
  }

  .inputRounded .MuiOutlinedInput-root{
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
`;
