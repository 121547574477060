import axios from 'axios';

import {
  API_REACTIONS_URL,
  API_REACTIONS_USER_URL,
} from '../constants/apiRoutes';

import makeError from './makeError';

const getReactionsByMemoryId = async ({ memoryId, options }) => {
  try {
    const { data } = await axios.get(`${API_REACTIONS_URL}/${memoryId}`);

    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

// get all memories created by this user
const getReactionsByUserId = async ({ userId, options }) => {
  try {
    const { data } = await axios.get(`${API_REACTIONS_USER_URL}/${userId}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const createReaction = async ({ reaction, options }) => {
  const { memoryId, reactionType } = reaction;

  try {
    const { data } = await axios.post(`${API_REACTIONS_URL}`, {
      memoryId,
      reactionType,
    });

    if (data) {
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const updateReaction = async ({ reaction, options }) => {
  const { reactionId, memoryId, userId, reactionType } = reaction;

  try {
    const { data } = await axios.patch(`${API_REACTIONS_URL}`, {
      reactionId,
      memoryId,
      userId,
      reactionType,
    });
    if (data) {
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const deleteReaction = async ({ reactionId, options }) => {
  try {
    const { data } = await axios.delete(`${API_REACTIONS_URL}/${reactionId}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const reactions = {
  getReactionsByMemoryId,
  getReactionsByUserId,
  createReaction,
  updateReaction,
  deleteReaction,
};

export default reactions;
