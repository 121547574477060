import React from "react";
import PropTypes from "prop-types";

// Router
import { Link } from "react-router-dom";

// MUI
import { Typography, Button, Avatar, Badge } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

// Icons
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from "@material-ui/icons/Settings";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AddIcon from "@material-ui/icons/Add";
import PhotoAlbumIcon from "@material-ui/icons/PhotoAlbum";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import Home from "@material-ui/icons/Home";

// Styled Components
import {
  // SignOutButtonWrapper,
  UserCardBase,
  UserAvatarStyles,
  UsernameStyles,
  SecondaryButtonsWrapper,
  AddMemoryButtonWrapper,
} from "./NavUserSection.style";

export function NavUserSection({
  userName,
  signOutFunc,
  userPhoto,
  profileURL,
  createMemoryURL,
  memoriesURL,
  dashboardURL,
  notificationCount = 0,
  openNotificationsFunc,
  closeMobileMenuFunc,
  ...props
}) {
  const generateUserInitials = (userStr) =>
    userStr
      .split(" ")
      .reduce((acc, cur) => acc + cur[0], "")
      .toUpperCase()
      .substring(0, 3);

  return (
    <UserCardBase {...props} style={{ marginBottom: "2rem" }}>
      {/* <SignOutButtonWrapper>
        {userName ? (
          <Button size="small" onClick={() => signOutFunc()}>
            Sign Out&nbsp;
            <ExitToAppIcon />
          </Button>
        ) : (
          <Skeleton variant="text" animation="wave" width="35%" height={33.5} />
        )}
      </SignOutButtonWrapper> */}
      <UserAvatarStyles>
        {userPhoto ? (
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: profileURL,
              state: {
                refreshNeeded: true,
              },
            }}
          >
            <Avatar
              onClick={closeMobileMenuFunc}
              style={{
                display: window.innerHeight < 640 ? "none" : "",
                marginTop: window.innerHeight < 800 ? "-2rem" : "0rem",
              }}
              src={
                userPhoto.includes("base64")
                  ? userPhoto
                  : `data:image;base64,${userPhoto}`
              }
              alt="Your profile avatar"
            >
              {userName ? generateUserInitials(userName) : ""}
            </Avatar>{" "}
          </Link>
        ) : (
          <Skeleton
            animation="wave"
            style={{ margin: "0 auto !important" }}
            variant="circle"
            width={100}
            height={100}
          />
        )}
      </UserAvatarStyles>
      <UsernameStyles>
        <Typography
          align="center"
          variant="h5"
          style={{ marginTop: window.innerHeight < 640 ? "-2rem" : "0rem" }}
        >
          {userName ? (
            `Hi, ${userName}!`
          ) : (
            <Skeleton animation="wave" variant="text" width="100%" />
          )}
        </Typography>
      </UsernameStyles>
      <SecondaryButtonsWrapper>
        {userName ? (
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: dashboardURL,
              state: {
                refreshNeeded: true,
              },
            }}
          >
            {/* <Button fullWidth onClick={closeMobileMenuFunc} variant="outlined" style={{textAlign:'left'}}>
              <MenuBookIcon style={{}} />
              &nbsp;My Stories
            </Button> */}

            <div
              className="navigation-link"
              role="button"
              tabIndex="0"
              onClick={closeMobileMenuFunc}
              style={{
                // border: "solid 1px white",
                borderRadius: "5px",
                // marginTop: "1rem",
                // marginLeft: "1rem",
                padding: "0.5rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                fontSize: "1.1rem",
              }}
            >
              {/* <Home fontSize="inherit"  style={{}} />
      
              HOME */}
            </div>
          </Link>
        ) : (
          <Skeleton
            animation="wave"
            style={{ margin: "0 auto !important" }}
            variant="text"
            width="80%"
            height={60}
          />
        )}

        {/* {userName ? (
          <div 
            fullWidth
            variant="outlined" 
            onClick={openNotificationsFunc} 
            style={{
              border: "solid 1px white",
              borderRadius: '5px',
              marginTop: "0.5rem",
              // marginLeft: "1rem",
              padding: "0.5rem",
              paddingLeft: "1rem",
              paddingRight:'1rem',
              color:'white',
              textAlign:'left',
              fontSize:'1.1rem',
              cursor: 'pointer'
            }}
          >
            <Badge
              color="error"
              variant="standard"
              badgeContent={notificationCount}
              invisible={notificationCount === 0}
              max={9}
            >
              <NotificationsIcon fontSize="inherit" style={{ color: "white" }} />
            </Badge>
            &nbsp;&nbsp;NOTIFICATIONS
          </div>
        ) : (
          <Skeleton
            animation="wave"
            style={{ margin: "0 auto !important" }}
            variant="text"
            width="80%"
            height={60}
          />
        )} */}
      </SecondaryButtonsWrapper>

      <Link
        style={{ textDecoration: "none" }}
        to={{
          pathname: createMemoryURL,
          state: {
            refreshNeeded: true,
          },
        }}
      >
        <AddMemoryButtonWrapper>
          {userName ? (
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={closeMobileMenuFunc}
              fullWidth
              style={{
                borderRadius: "100px",
                fontWeight: "700",
                marginTop: window.innerHeight < 800 ? "0rem" : "2rem",

                // marginBottom: "1rem",‹
                color: "#0a2156",
                boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                marginBottom: window.innerHeight < 640 ? "-1rem" : "0rem",
              }}
            >
              <AddIcon style={{ paddingRight: "0.3rem" }} /> Add Story
            </Button>
          ) : (
            <Skeleton
              animation="wave"
              style={{ margin: "-40px 0px !important" }}
              variant="text"
              width="100%"
              height={75}
            />
          )}
        </AddMemoryButtonWrapper>
      </Link>
    </UserCardBase>
  );
}

NavUserSection.propTypes = {
  /**
   * Use context to pass on the name of the current user
   */
  userName: PropTypes.string,
  /**
   * Provide a function to perform user sign out
   */
  signOutFunc: PropTypes.func,
  /**
   * Provide the `src` of the user's uploaded photo/avatar (optional)
   */
  userPhoto: PropTypes.string,
  /**
   * Provide a URL to the user profile page
   */
  profileURL: PropTypes.string,
  /**
   * Provide a URL to the Create Memory page
   */
  createMemoryURL: PropTypes.string,
  /**
   * What is the number of unread notifications?
   */
  notificationCount: PropTypes.number,
  /**
   * Provide a function to open the notifications modal
   */
  openNotificationsFunc: PropTypes.func,
  /**
   * Provide a function to close the mobile menu (used when using Link / NavLink buttons)
   */
  closeMobileMenuFunc: PropTypes.func,
};

NavUserSection.defaultProps = {
  userName: undefined,
  signOutFunc: () => {},
  userPhoto: undefined,
  profileURL: "#",
  createMemoryURL: "#",
  notificationCount: 0,
  openNotificationsFunc: () => {},
  closeMobileMenuFunc: () => {},
};
