import React, { useState, useEffect, useRef } from "react";

// MUI
import {
  Paper,
  Typography,
  TextField,
  InputLabel,
  Button,
  Grid,
  Avatar,
  // Slide,
  Fade,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import imageCompression from "browser-image-compression";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import { firebase } from "../../../api";

// Styled Components
import { PaperWrapper } from "../Onboarding.style";
import chair from "../../../assets/onboarding/chair.jpg";
import profile from "../../../assets/onboarding/profile-avatar.png";

export function OnboardingStep3({
  nameVal = "",
  setNameVal = () => {},
  dateVal = new Date(),
  setDateVal = () => {},
  hometownVal = "",
  setHometownVal = () => {},
  profilePicture = "",
  setProfilePicture = () => {},
  nextFunc = () => {},
  props,
}) {
  /* Transition States */
  const [cardTransition, setCardTransition] = useState(false);
  const [titleTransition, setTitleTransition] = useState(false);
  const [inputTransition, setInputTransition] = useState(false);
  const [buttonTransition, setButtonTransition] = useState(false);
  const [tempProfilePicture, setTempProfilePicture] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const imageInput = useRef();
  /* Transition Timings */
  useEffect(() => {
    setTimeout(() => {
      setCardTransition(true);
    }, 500);
    setTimeout(() => {
      setTitleTransition(true);
    }, 1000);
    setTimeout(() => {
      setInputTransition(true);
    }, 1500);
    setTimeout(() => {
      setButtonTransition(true);
    }, 2000);
  });

  const directUploadToGoogle = async (e) => {
    try {
      setIsUploading(true);
      const userId = props.id;
      const { files } = e.target;
      const allFiles = Array.from(files);
      const generatedFiles = [];
      const errors = [];
      const imgURLs = [];
      const compressionOptions = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const image = allFiles[0];
      const compressedImage = await imageCompression(image, compressionOptions);

      const {
        data: { generatedName } = {},
        error,
      } = await firebase.uploadImage({
        image,
        userId,
        isProfile: true,
      });

      const imgURL = URL.createObjectURL(compressedImage);

      if (error) errors.push(error);
      // setShowSpinner(false);

      // state to save the imageName to database
      // setShowSpinner(false);
      if (errors.length === 0) {
        setTempProfilePicture(imgURL);

        // state for display images for preview
        setProfilePicture(generatedName);
      }
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
      console.log(error);
    }
  };

  return (
    <Grid container spacing={0} style={{ margin: "0px", padding: "0" }}>
      <Grid
        item
        id="profile-mainpicture"
        xs={12}
        md={5}
        lg={6}
        style={{
          backgroundColor: "",
          display: window.innerWidth < 1280 ? "none" : "block",
          height: "100vh",
          height: "100vh",
        }}
      >
        <img
          src={chair}
          style={{
            height: window.innerWidth < 960 ? "300px" : "100%",
            width: "100%",
            objectFit: window.innerWidth < 600 ? "fill" : "fill",
            backgroundPosition: "top",
            padding: "-1rem",
          }}
        />
      </Grid>

      <Grid
        item
        id="profile-text"
        xs={12}
        md={7}
        lg={6}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          borderRadius: "20px",
          padding: window.innerWidth < 600 ? "1rem" : "3rem",
          maxWidth: "550px",
          margin: window.innerWidth < 600 ? "1rem" : "5rem auto",
          backgroundColor: "white",
        }}
      >
        <Typography
          gutterBottom
          style={{
            textAlign: "left",
            fontWeight: "bolder",
            fontSize: "2rem",
            display: window.innerWidth < 450 ? "none" : "block",
          }}
        >
          Let&apos;s create your profile
        </Typography>
        <Typography
          gutterBottom
          style={{
            textAlign: "left",
            fontWeight: "bolder",
            fontSize: "2rem",
            display: window.innerWidth > 450 ? "none" : "block",
          }}
        >
          Create your profile
        </Typography>
        {/* </Fade> */}
        <br />
        {/* <Fade in={inputTransition} timeout={1000}> */}
        <div>
          <InputLabel htmlFor="nameInput">What should we call you?</InputLabel>
          <br />
          <TextField
            fullWidth
            variant="outlined"
            id="nameInput"
            placeholder="First Name"
            onChange={(e) => setNameVal(e.target.value)}
          />

          <br />
          <br />
          <br />
          <InputLabel htmlFor="datePicker" style={{ marginTop: "-1rem" }}>
            When is your birthday?
          </InputLabel>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              inputVariant="outlined"
              margin="normal"
              id="datePicker"
              format="dd/MM/yyyy"
              openTo="year"
              value={dateVal}
              onChange={setDateVal}
              keyboardIcon={
                <>
                  <p style={{ fontSize: "0.85rem" }}>Click Me</p>&nbsp;
                  <EventIcon />
                </>
              }
              InputAdornmentProps={{
                position: "end",
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <br />
          <br />
          <br />
          {/* <InputLabel htmlFor="nameInput">
                  Where&apos;s your hometown?
                </InputLabel>
                <br />
                <TextField
                  fullWidth
                  variant="outlined"
                  id="hometownInput"
                  placeholder="Hometown"
                  onChange={e => setHometownVal(e.target.value)}
                /> */}
        </div>
        {/* </Fade> */}
        {/* <br />
            <br /> */}
        {/* <Fade in={buttonTransition} timeout={1000}> */}
        <Grid container style={{ marginBottom: "2rem" }}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar
              src={tempProfilePicture ? tempProfilePicture : profile}
              alt="Your profile avatar"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "150px",
                height: "150px",
                borderRadius: "100%",
                cover: "cover",
              }}
            />
            {/* <img src={profile} ></img> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: window.innerWidth < 600 ? "1rem" : "0rem",
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              disabled={isUploading}
              style={{
                height: "3rem",
                fontSize: "1.1rem",
                fontWeight: "bold",
                borderRadius: "100px",
                boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",

                maxWidth: "432px",
              }}
              onClick={() => imageInput.current.click()}
            >
              Upload Picture
            </Button>
            <input
              ref={imageInput}
              accept="image/*"
              type="file"
              onChange={directUploadToGoogle}
              style={{ display: "none" }}
            />
          </Grid>
        </Grid>
        <Button
          fullWidth
          disabled={nameVal === "" || isUploading}
          size="large"
          variant="contained"
          color="secondary"
          onClick={(e) => nextFunc(e)}
          style={{
            height: "3rem",
            fontSize: "1.1rem",
            fontWeight: "bold",
            borderRadius: "100px",
            boxShadow: "rgba(0, 0, 0, 0.95) 0px 25px 20px -20px",
            maxWidth: "432px",
          }}
        >
          Continue
        </Button>
      </Grid>
      {/* <Button
          fullWidth
          disabled={nameVal === ""}
          size="large"
          variant="contained"
          color="secondary"
          onClick={(e) => nextFunc(e)}
          style={{
            height: "3rem",
            fontSize: "1.1rem",
            fontWeight: "bold",
            borderRadius: "100px",
            boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
            maxWidth: "432px",
          }}
        >
          Continue
        </Button> */}
    </Grid>
    // </Grid>
  );
}
