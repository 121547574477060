import React, { useContext, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import imageCompression from 'browser-image-compression';
import Image from './Image';
import { memories } from '../../../api';
import UserContext from '../../../context/user';
import Spinner from '../Spinner';
import { Placeholder, TrashIconWrapper } from './style';
import Icon from '../Icon/Icon';

const ImageWrapper = ({
  setImagesArray,
  imagesArray,
  setImagesArrayRequest,
  imagesArrayRequest,
  tempImages,
  setTempImages,
  createForOther=false,
  uploaderId,
}) => {
  const user = useContext(UserContext);
  const [showSpinner, setShowSpinner] = useState(false);
  const imageInput = useRef();
  // placeholder rendering sources
  let blobs = [];
  let placeholdersSource = [];
  // if no memory created yet -> initial setup
  if (imagesArray && imagesArray.length > 0) {
    if (tempImages && tempImages.length > 0) {
      placeholdersSource = tempImages;
    } else {
      placeholdersSource = imagesArray;
    }
  }
  // create array of local src (blobs) when user adds new image
  if (imagesArray[0] && typeof imagesArray[0] === 'string') {
    blobs = imagesArray
      .map(img => {
        if (typeof img === 'string') {
          return img;
        }
        return undefined;
      })
      .filter(img => img !== undefined);
  }

  // create joint array of local and remote sources
  if (blobs && blobs.length && tempImages && tempImages.length) {
    placeholdersSource = [...tempImages, ...blobs];
  }

  // deals with uploading to firebase
  const directUploadToGoogle = async e => {
    const userId = createForOther ? uploaderId : user.id;
    const { files } = e.target;
    const allFiles = Array.from(files);
    const generatedFiles = [];
    const errors = [];
    const imgURLs = [];
    const compressionOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    setShowSpinner(true);
    await Promise.all(
      allFiles.map(async image => {
        const compressedImage = await imageCompression(
          image,
          compressionOptions,
        );

        const {
          data: { generatedName } = {},
          error,
        } = await memories.uploadImage({
          image: compressedImage,
          userId,
        });
        const imgURL = URL.createObjectURL(compressedImage);
        generatedFiles.push({ imagePath: generatedName, image: imgURL });
        imgURLs.push(imgURL);
        if (error) errors.push(error);
      }),
    );
    setShowSpinner(false);

    // state to save the imageName to database
    setShowSpinner(false);
    if (errors.length === 0) {
      setImagesArrayRequest([...generatedFiles, ...imagesArrayRequest]);

      // state for display images for preview
      setImagesArray([...imgURLs, ...imagesArray]);
    }
  };

  const deleteImage = image => {
    const imgID = image.target.id;

    if (imgID.includes('https://storage.googleapis.com/')) {
      const path = placeholdersSource.find(e => e.imagePath === imgID).image;
      if (!path) {
        const localPath = tempImages.find(e => e.imagePath === imgID)._id;
        setTempImages(tempImages.filter(e => e._id !== localPath));
        setImagesArray(imagesArray.filter(e => e._id !== localPath));
        setImagesArrayRequest(
          imagesArrayRequest.filter(e => e._id !== localPath),
        );
      } else {
        placeholdersSource = placeholdersSource.filter(e => e.image !== path);
        setTempImages(tempImages.filter(e => e.image !== path));
        setImagesArray(imagesArray.filter(e => e.image !== path));
        setImagesArrayRequest(imagesArrayRequest.filter(e => e.image !== path));
      }
    } else {
      setImagesArray(imagesArray.filter(e => e !== imgID));
      setImagesArrayRequest(imagesArrayRequest.filter(e => e.image !== imgID));
    }
    return Swal.fire({
      title: 'Image removed!',
      timer: 3000,
      type: 'success',
    });
  };

  const renderPlaceholders = imgArr =>
    imgArr.length > 0
      ? imgArr.map((image, _id = Math.random()) => (
          <center
            style={{
              marginTop: '1.25rem',
              background: 'rgba(0,0,0,0.05)',
              borderRadius: '1rem',
            }}
          >
            <Placeholder
              key={`preview-image-${_id}`}
              src={typeof image === 'string' ? image : image.imagePath}
            />
            <TrashIconWrapper
              key={_id}
              onClick={deleteImage}
              style={{
                margin: '0 auto',
                display: 'block',
                background: '#f9f9f9',
                width: '100%',
                padding: '0.5rem',
                borderRadius: '1rem',
                fontSize: '1.2rem',
              }}
              id={typeof image === 'string' ? image : image.imagePath}
            >
              <Icon key={_id} icon="trash" width="15" />
              &nbsp;Delete
            </TrashIconWrapper>
          </center>
        ))
      : null;

  if (showSpinner) {
    return <Spinner />;
  }

  return (
    <div>
      <Image
        onIconClick={() => {
          imageInput.current.click();
        }}
        onClick={() => imageInput.current.click()}
        key={`preview-image-${Math.random()}`}
      />
      <div className="columns is-multiline has-text-centered">
        <input
          ref={imageInput}
          accept="image/*"
          type="file"
          onChange={directUploadToGoogle}
          style={{ display: 'none' }}
          multiple
        />
        {placeholdersSource && placeholdersSource.length > 0
          ? renderPlaceholders(placeholdersSource)
          : renderPlaceholders([])}
      </div>
    </div>
  );
};

export default ImageWrapper;
