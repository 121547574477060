import React from 'react';
import Lottie from 'react-lottie';

import animationData from '../../../../assets/animations/Logo/Logo_White.json';

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const LoadingAnimation = ({ width=300, height=300 }) => (
  <Lottie options={defaultOptions}
    height={height}
    width={width}
  />
);

export default LoadingAnimation;
