import React from "react";

const Close = ({ width, height, color }) => (
  <svg
    width={width || "34"}
    height={height || "34"}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4142 17L24.7782 23.3639L23.364 24.7782L17 18.4142L10.636 24.7782L9.22183 23.3639L15.5858 17L9.22183 10.636L10.636 9.22181L17 15.5858L23.364 9.22181L24.7782 10.636L18.4142 17Z"
      fill={color || "black"}
    />
  </svg>
);

export default Close;
