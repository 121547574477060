import React, { useState, useContext, useEffect } from 'react';

import Swal from 'sweetalert2';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper'
import history from '../../../history';
import { InputComponent } from '../../Common/Inputs/style';

import visa from '../../../assets/payments/visa.svg';
import americanExpress from '../../../assets/payments/american-express.svg';
import mastercard from '../../../assets/payments/mastercard.svg';
import stripePayment from '../../../assets/stripe-payment.png';

import * as S from './style';
// API
import { payments } from '../../../api';
import CardSection from './Form';
import UserContext from '../../../context/user';

import { Upsell as UpsellPage } from '../../../NewComponents/Pages/GoPremium/Upsell/Upsell';
import ReactGA from 'react-ga';

export default () => {
  const [page, setPage] = useState(1);
  const [nameOnCard, setNameOnCard] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [errorName, setErrorName] = useState('');
  const { email } = useContext(UserContext);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  const nameSchema = yup
    .string('Please enter a valid name')
    .matches(/^[A-Z]/, 'Please enter a valid name')
    .strict()
    .min(3, 'Name must be longer than 3 characters')
    .required('Please enter a valid name');

  const handleSubmit = async event => {
    event.preventDefault();
    setErrorName('');
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    setIsLoading(true);
    try {
      await nameSchema.validate(nameOnCard);
    } catch (err) {
      setIsLoading(false);
      return setErrorName(err.message);
    }
    const card = elements.getElement(CardElement);

    const { error } = await payments.create({
      stripe,
      card,
      email,
      name: nameOnCard,
    });
    if (!error) {
      await Swal.fire({
        title: 'You are successfully subscribed',
        type: 'success',
      });
      // redirect to profile on success
      history.push('/basics');
    }
    setIsLoading(false);
  };

  const renderPageOne = () => <UpsellPage nextFunc={() => setPage(2)} />;

  const onChange = event => {
    setNameOnCard(event.target.value);
  };

  const renderPageTwo = () => (
    <div style={{display:'flex', justifyContent:'center'}}>
    <Paper  elevation={3}
        style={{
          textAlign: 'center',
          borderRadius: '7px',
          backgroundColor: 'white',
          minHeight:'60vh',
          padding: '2rem',
          width:'full',
        }}>
      <h3
        style={{
          textTransform: 'uppercase',
          letterSpacing: '4px',
          fontWeight: 'bold',
          fontSize: '1.5rem',
          opacity: '0.85',
        }}
      >
        Secure checkout
      </h3>
      <p>Your total: £20.00</p>
      <p>Pay securely with debit or credit card</p>
      <S.PremiumWrapper onSubmit={handleSubmit}>
        <S.InputWrapper>
          <InputComponent
            style={{
              width: '100%',
              padding: '10px',
              color: '#32325d',
              opacity: '51%',
            }}
            placeholder="Name on card"
            type="text"
            value={nameOnCard}
            onChange={onChange}
          />
        </S.InputWrapper>
        <S.InputCardWrapper>
          <CardSection />
        </S.InputCardWrapper>
        <S.FooterButton>
          <Button variant="outlined" color="primary" style={{
          marginTop: '1rem',
          borderRadius: '100px',
          Width: '164px',
          minHeight: '50px',
          fontWeight:"700",
          boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',}}>
           Go back
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            style={{
          marginTop: '1rem',
          borderRadius: '100px',
          Width: '164px',
          minHeight: '50px',
          fontWeight:"700",
          boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',}}

            disabled={!stripe || !elements || isLoading}
            isLoading={isLoading}
          >
            Subscribe
          </Button>
        </S.FooterButton>
      </S.PremiumWrapper>
      <S.ErrorText>{errorName}</S.ErrorText>
      <S.ParagraphWrapper>
        <p style={{ display: 'inline', marginBottom: '0px' }}>We accept:</p>
        <S.Icon src={visa} style={{ width: '40px' }} alt="visa" />
        <S.Icon
          src={americanExpress}
          style={{ width: '40px', padding: '5px' }}
          alt="american express"
        />
        <S.Icon src={mastercard} style={{ width: '40px' }} alt="mastercard" />
      </S.ParagraphWrapper>
      <p style={{ marginTop: '2rem', opacity: '0.5' }}>
        By continuing you agree to the terms of service and privacy policy
      </p>
      <div>
        <img
          style={{ width: '10rem' }}
          src={stripePayment}
          alt="Secure payments powered by Stripe"
        />
      </div>
    </Paper>
    </div>
  );
  return <S.Main>{page === 1 ? renderPageOne() : renderPageTwo()}</S.Main>;
};
