import React from 'react';
import withDashedBorder from './withDashedBorder';

import TextField from '@material-ui/core/TextField';

const Email = ({ newEmail, setNewEmail }) => {
  const onChange = e => {
    const { value: newValue } = e.target;
    setNewEmail(newValue.toLowerCase());
  };

  return (
    <div>
      <TextField
        variant="outlined"
        placeholder="Type an email address"
        value={newEmail}
        onChange={onChange}
        type="text"
        style={{ width: '100%' }}
      />
    </div>

  );
};

export default withDashedBorder(Email, 'email');
