const API = '/api';

const SIGNUP_URL = '/user/signup'; // to use on the server
const API_SIGNUP_URL = `${API}${SIGNUP_URL}`; // to use on the client

const USERNAME_URL = '/user/name/:id';
const API_USERNAME_URL = `${API}/user/name`;

const USER_NAME_URL = '/user/name/permission/:id';
const API_USER_NAME_URL = `${API}/user/name/permission`;

const IS_LOGGED_IN = '/user/is-logged-in';
const API_IS_LOGGED_IN = `${API}${IS_LOGGED_IN}`;

const LOGIN_URL = '/user/login';
const API_LOGIN_URL = `${API}${LOGIN_URL}`;

const LOGOUT_URL = '/user/logout';
const API_LOGOUT_URL = `${API}${LOGOUT_URL}`;

const USERINFO_URL = '/user/info';
const API_USERINFO_URL = `${API}${USERINFO_URL}`;

const UPDATE_USERINFO_URL = '/user/update-info';
const API_UPDATE_USERINFO_URL = `${API}${UPDATE_USERINFO_URL}`;

const THISORTHAT_URL = '/questions/thisorthat';
const API_THISORTHAT_URL = `${API}${THISORTHAT_URL}`;

const FUTUREMESSAGE_URL = '/questions/futuremessage';
const API_FUTUREMESSAGE_URL = `${API}${FUTUREMESSAGE_URL}`;

const PROFILE_BASICS_URL = '/user/basics';
const API_PROFILE_BASICS_URL = `${API}${PROFILE_BASICS_URL}`;

const PROFILE_MEMORIES_URL = '/user/memories';
const API_PROFILE_MEMORIES_URL = `${API}${PROFILE_MEMORIES_URL}`;

const PROFILE_MEMORY_URL = '/user/memory';
const API_PROFILE_MEMORY_URL = `${API}${PROFILE_MEMORY_URL}`;

const MEMORIES_AND_USER_NAME_BY_TOKEN_URL = '/link-tokens/:shortId';
const API_MEMORIES_AND_USER_NAME_BY_TOKEN_URL = `${API}${MEMORIES_AND_USER_NAME_BY_TOKEN_URL}`;

const PROFILE_THISORTHAT_URL = '/user/thisorthat';
const API_PROFILE_THISORTHAT_URL = `${API}${PROFILE_THISORTHAT_URL}`;

const PROFILE_ABOUT_URL = '/user/about';
const API_PROFILE_ABOUT_URL = `${API}${PROFILE_ABOUT_URL}`;

const UPLOAD_SIGNED_URL = '/upload/signed-url';
const API_UPLOAD_SIGNED_URL = `${API}${UPLOAD_SIGNED_URL}`;

// send email and generate the token
const RESET_PASSWORD = '/user/reset-password';
const API_RESET_PASSWORD = `${API}${RESET_PASSWORD}`;

// set password
const SET_PASSWORD = '/user/set-password';
const API_SET_PASSWORD = `${API}${SET_PASSWORD}`;

// store messages and aboutMe flow
const STORE_FLOW = '/questions/aboutMe';
const API_STORE_FLOW = `${API}${STORE_FLOW}`;

// gets user's about me profile data
const ABOUT_ME_PROFILE = '/user/aboutMe-data';
const API_ABOUT_ME_PROFILE = `${API}${ABOUT_ME_PROFILE}`;

// delete user answer
const DELETE_ANSWER_URL = '/user/aboutMe';
const API_DELETE_ANSWER_URL = `${API}${DELETE_ANSWER_URL}`;

// delete answer flow
const DELETE_ANSWER_FLOW_URL = '/user/aboutMe/flow';
const API_DELETE_ANSWER_FLOW_URL = `${API}${DELETE_ANSWER_FLOW_URL}`;

// route for viewing single memories that can be shared
const SHARE_MEMORY = '/share-memory';
const API_SHARE_MEMORY = `${API}${SHARE_MEMORY}`;

// for api calls
const USERS_BASE_URL = '/users';
const API_USERS_BASE_URL = `${API}${USERS_BASE_URL}`;

const MEMORIES_BASE_URL = '/memories';
const API_MEMORIES_BASE_URL = `${API}${MEMORIES_BASE_URL}`;

const LINK_TOKENS = '/link-tokens';
const API_LINK_TOKENS = `${API}${LINK_TOKENS}`;

const LINK_TOKEN = '/link-tokens/:shortId';
const API_LINK_TOKEN = `${API}${LINK_TOKEN}`;

const PERMISSION_ACCESS = '/permission-access';
const API_PERMISSION_ACCESS = `${API}${PERMISSION_ACCESS}`;

const PERMISSION_ACCESS_MEMORY_SEND = '/permission-access-memory-send';
const API_PERMISSION_ACCESS_MEMORY_SEND = `${API}${PERMISSION_ACCESS_MEMORY_SEND}`;

const PERMISSION_ACCESS_MEMORY_UPDATE = '/permission-access-memory-update';
const API_PERMISSION_ACCESS_MEMORY_UPDATE = `${API}${PERMISSION_ACCESS_MEMORY_UPDATE}`;

const PERMISSION_ACCESS_EMAIL = '/permission-access-email';
const API_PERMISSION_ACCESS_EMAIL = `${API}${PERMISSION_ACCESS_EMAIL}`;

const STOREMEMORY_URL = '/store-memory';
const API_STOREMEMORY_URL = `${API}${STOREMEMORY_URL}`;

const UPDATE_MEMORY_URL = '/update-memory';
const API_UPDATE_MEMORY_URL = `${API}${UPDATE_MEMORY_URL}`;

const GENERATE_STORAGE_URL = '/generate-storage-url';
const API_GENERATE_STORAGE_URL = `${API}${GENERATE_STORAGE_URL}`;

const PAYMENTS_BASE_URL = '/payments';
const API_PAYMENTS_BASE_URL = `${API}${PAYMENTS_BASE_URL}`;

const GOOGLE_LOGIN_URL = '/login/google';
const API_GOOGLE_LOGIN_URL = `${API}${GOOGLE_LOGIN_URL}`;

const GOOGLE_SIGNUP_URL = '/signup/google';
const API_GOOGLE_SIGNUP_URL = `${API}${GOOGLE_SIGNUP_URL}`;

const FACEBOOK_LOGIN_URL = '/login/facebook';
const API_FACEBOOK_LOGIN_URL = `${API}${FACEBOOK_LOGIN_URL}`;

const FACEBOOK_SIGNUP_URL = '/signup/facebook';
const API_FACEBOOK_SIGNUP_URL = `${API}${FACEBOOK_SIGNUP_URL}`;

const APPLE_LOGIN_URL = '/login/apple';
const API_APPLE_LOGIN_URL = `${API}${APPLE_LOGIN_URL}`;

const APPLE_SIGNUP_URL = '/signup/apple';
const API_APPLE_SIGNUP_URL = `${API}${APPLE_SIGNUP_URL}`;

const SHARE_LINK_TOKEN = '/share-link-tokens/:shortId';
const API_SHARE_LINK_TOKEN = `${API}${SHARE_LINK_TOKEN}`;

const ACCOUNT_CHECKLIST_URL = '/account-checklist';
const API_ACCOUNT_CHECKLIST_URL = `${API}${ACCOUNT_CHECKLIST_URL}`;

const COMMENTS_URL = '/comment';
const API_COMMENTS_URL = `${API}${COMMENTS_URL}`;

const TAGS_URL = '/tag';
const API_TAGS_URL = `${API}${TAGS_URL}`;

const OTHER_PROFILE_URL = '/other-user';
const API_OTHER_PROFILE_URL = `${API}${OTHER_PROFILE_URL}`;

const PROMPT_URL = '/prompt';
const API_PROMPT_URL = `${API}${PROMPT_URL}`;

const PROMPTS_URL = '/prompts';
const API_PROMPTS_URL = `${API}${PROMPTS_URL}`;

const LEGACY_URL = '/legacy';
const API_LEGACY_URL = `${API}${LEGACY_URL}`;

const REACTIONS_URL = '/reactions';
const API_REACTIONS_URL = `${API}${REACTIONS_URL}`;

const REACTIONS_USER_URL = '/reactions-user';
const API_REACTIONS_USER_URL = `${API}${REACTIONS_USER_URL}`;

const BONUS_URL = '/bonus';
const API_BONUS_URL = `${API}${BONUS_URL}`;

const GIFT_URL = '/gift';
const API_GIFT_URL = `${API}${GIFT_URL}`;

const BONUS_DETAILS_URL = '/bonusdetails';
const API_BONUS_DETAILS_URL = `${API}${BONUS_DETAILS_URL}`;

const MILESTONE_URL = '/milestone';
const API_MILESTONE_URL = `${API}${MILESTONE_URL}`;

const MILESTONES_URL = '/milestones';
const API_MILESTONES_URL = `${API}${MILESTONES_URL}`;

const COMPETITION_URL = '/competition';
const API_COMPETITION_URL = `${API}${COMPETITION_URL}`;

const CREATE_OTHER_URL = '/create-memory-other';
const API_CREATE_OTHER_URL = `${API}${CREATE_OTHER_URL}`;

const CREATE_COMP_URL = '/create-memory-competition';
const API_CREATE_COMP_URL = `${API}${CREATE_COMP_URL}`;

const ONBOARDING_STEP_URL = '/onboarding-step';
const API_ONBOARDING_STEP_URL = `${API}${ONBOARDING_STEP_URL}`;

const GIFT_MEMORY_URL = '/memory-gift';
const API_GIFT_MEMORY_URL = `${API}${GIFT_MEMORY_URL}`;

const VIEWED_MEMORY_URL = '/viewed-memory/user';
const API_VIEWED_MEMORY_URL = `${API}${VIEWED_MEMORY_URL}`;

const VIEWED_MEMORY_MEM_URL = '/viewed-memory/memory';
const API_VIEWED_MEMORY_MEM_URL = `${API}${VIEWED_MEMORY_MEM_URL}`;

const ALBUM_URL = '/album';
const API_ALBUM_URL = `${API}${ALBUM_URL}`;

const ALBUMS_URL = '/albums';
const API_ALBUMS_URL = `${API}${ALBUMS_URL}`;

const ALBUM_PHOTO_URL = '/album-photo';
const API_ALBUM_PHOTO_URL = `${API}${ALBUM_PHOTO_URL}`;

const ALBUM_COMMENT_URL = '/album-comment';
const API_ALBUM_COMMENT_URL = `${API}${ALBUM_COMMENT_URL}`;

const APP_ALBUM_URL = '/app/album';
const API_APP_ALBUM_URL = `${API}${APP_ALBUM_URL}`;

module.exports = {
  // SERVER
  USERNAME_URL,
  USER_NAME_URL,
  SIGNUP_URL,
  LOGIN_URL,
  LOGOUT_URL,
  USERINFO_URL,
  PROFILE_BASICS_URL,
  PROFILE_ABOUT_URL,
  PROFILE_THISORTHAT_URL,
  PROFILE_MEMORIES_URL,
  PROFILE_MEMORY_URL,
  THISORTHAT_URL,
  FUTUREMESSAGE_URL,
  UPDATE_USERINFO_URL,
  STOREMEMORY_URL,
  RESET_PASSWORD,
  SET_PASSWORD,
  STORE_FLOW,
  ABOUT_ME_PROFILE,
  DELETE_ANSWER_URL,
  SHARE_MEMORY,
  DELETE_ANSWER_FLOW_URL,
  USERS_BASE_URL,
  MEMORIES_BASE_URL,
  LINK_TOKENS,
  LINK_TOKEN,
  PERMISSION_ACCESS,
  PERMISSION_ACCESS_MEMORY_SEND,
  PERMISSION_ACCESS_MEMORY_UPDATE,
  PERMISSION_ACCESS_EMAIL,
  UPLOAD_SIGNED_URL,
  MEMORIES_AND_USER_NAME_BY_TOKEN_URL,
  UPDATE_MEMORY_URL,
  GENERATE_STORAGE_URL,
  PAYMENTS_BASE_URL,
  GOOGLE_LOGIN_URL,
  GOOGLE_SIGNUP_URL,
  FACEBOOK_LOGIN_URL,
  FACEBOOK_SIGNUP_URL,
  APPLE_LOGIN_URL,
  APPLE_SIGNUP_URL,
  SHARE_LINK_TOKEN,
  ACCOUNT_CHECKLIST_URL,
  COMMENTS_URL,
  IS_LOGGED_IN,
  TAGS_URL,
  OTHER_PROFILE_URL,
  PROMPT_URL,
  PROMPTS_URL,
  LEGACY_URL,
  REACTIONS_URL,
  REACTIONS_USER_URL,
  BONUS_URL,
  GIFT_URL,
  BONUS_DETAILS_URL,
  MILESTONE_URL,
  MILESTONES_URL,
  COMPETITION_URL,
  CREATE_OTHER_URL,
  ONBOARDING_STEP_URL,
  GIFT_MEMORY_URL,
  CREATE_COMP_URL,
  VIEWED_MEMORY_URL,
  VIEWED_MEMORY_MEM_URL,
  ALBUM_URL,
  ALBUMS_URL,
  ALBUM_PHOTO_URL,
  ALBUM_COMMENT_URL,
  APP_ALBUM_URL,

  // CLIENT
  API_UPLOAD_SIGNED_URL,
  API_USERNAME_URL,
  API_USER_NAME_URL,
  API_SIGNUP_URL,
  API_LOGIN_URL,
  API_LOGOUT_URL,
  API_USERINFO_URL,
  API_PROFILE_BASICS_URL,
  API_PROFILE_ABOUT_URL,
  API_PROFILE_THISORTHAT_URL,
  API_PROFILE_MEMORIES_URL,
  API_PROFILE_MEMORY_URL,
  API_THISORTHAT_URL,
  API_FUTUREMESSAGE_URL,
  API_UPDATE_USERINFO_URL,
  API_STOREMEMORY_URL,
  API_RESET_PASSWORD,
  API_SET_PASSWORD,
  API_STORE_FLOW,
  API_ABOUT_ME_PROFILE,
  API_DELETE_ANSWER_URL,
  API_SHARE_MEMORY,
  API_DELETE_ANSWER_FLOW_URL,
  API_USERS_BASE_URL,
  API_MEMORIES_BASE_URL,
  API_LINK_TOKENS,
  API_LINK_TOKEN,
  API_PERMISSION_ACCESS,
  API_PERMISSION_ACCESS_MEMORY_SEND,
  API_PERMISSION_ACCESS_MEMORY_UPDATE,
  API_PERMISSION_ACCESS_EMAIL,
  API_MEMORIES_AND_USER_NAME_BY_TOKEN_URL,
  API_UPDATE_MEMORY_URL,
  API_GENERATE_STORAGE_URL,
  API_PAYMENTS_BASE_URL,
  API_GOOGLE_LOGIN_URL,
  API_GOOGLE_SIGNUP_URL,
  API_FACEBOOK_LOGIN_URL,
  API_FACEBOOK_SIGNUP_URL,
  API_APPLE_LOGIN_URL,
  API_APPLE_SIGNUP_URL,
  API_SHARE_LINK_TOKEN,
  API_ACCOUNT_CHECKLIST_URL,
  API_COMMENTS_URL,
  API_IS_LOGGED_IN,
  API_TAGS_URL,
  API_OTHER_PROFILE_URL,
  API_PROMPT_URL,
  API_PROMPTS_URL,
  API_LEGACY_URL,
  API_REACTIONS_URL,
  API_REACTIONS_USER_URL,
  API_BONUS_URL,
  API_GIFT_URL,
  API_BONUS_DETAILS_URL,
  API_MILESTONE_URL,
  API_MILESTONES_URL,
  API_COMPETITION_URL,
  API_CREATE_OTHER_URL,
  API_ONBOARDING_STEP_URL,
  API_GIFT_MEMORY_URL,
  API_CREATE_COMP_URL,
  API_VIEWED_MEMORY_URL,
  API_VIEWED_MEMORY_MEM_URL,
  API_ALBUM_URL,
  API_ALBUMS_URL,
  API_ALBUM_PHOTO_URL,
  API_ALBUM_COMMENT_URL,
  API_APP_ALBUM_URL,
};
