import React from 'react';
import styled, { css, withTheme } from 'styled-components';

import Arrow from './Arrow';
import Basics from './Basics';
import About from './About';
import ThisOrThat from './ThisOrThat';
import Memories from './Memories';
import RightArrow from './RightArrow';
import Send from './Send';
import Back from './Back';
import Share from './Share';
import Delete from './Delete';
import ZigzagLine from './ZigzagLine';
import MemoryZigzag from './MemoryZigzag';
import Calendar from './Calendar';
import Microphone from './Microphone';
import Pencil from './Pencil';
import Camera from './Camera';
import Checkmark from './Checkmark';
import Preview from './Preview';
import Save from './Save';
import Copy from './Copy';
import EditPencil from './EditPencil';
import ShareNetwork from './ShareNetw';
import Trash from './Trash';
import Breadcrumb from './Breadcrumb';
import BreadcrumbSelected from './BreadcrumbSelected';
import VoiceRecord from './VoiceRecord';
import Close from './Close';
import ZigzagVertical from './ZigzagVertical';
import SharePreferences from './SharePref';
import X from './X';
import QuestionMark from './QuestionMark';
import Dropdown from './Dropdown';

const iconStyles = props => css`
  width: ${props.width || '100%'};
  height: ${props.height || '100%'};
  color: ${props.color};
  fill: ${props.color} !important;
  margin: ${props.margin || '0 0 0 0'};
`;

const iconMap = {
  arrow: Arrow,
  basics: Basics,
  about: About,
  thisorthat: ThisOrThat,
  memories: Memories,
  rightArrow: RightArrow,
  send: Send,
  back: Back,
  share: Share,
  delete: Delete,
  zigzagLine: ZigzagLine,
  calendar: Calendar,
  microphone: Microphone,
  pencil: Pencil,
  camera: Camera,
  checkmark: Checkmark,
  preview: Preview,
  save: Save,
  copy: Copy,
  memoryZigzag: MemoryZigzag,
  editPencil: EditPencil,
  shareNetwork: ShareNetwork,
  trash: Trash,
  breadcrumb: Breadcrumb,
  breadcrumbSelected: BreadcrumbSelected,
  voiceRecord: VoiceRecord,
  close: Close,
  zigzagVertical: ZigzagVertical,
  sharePreferences: SharePreferences,
  x: X,
  questionMark: QuestionMark,
  dropdown: Dropdown,
};

const styledIconMap = Object.keys(iconMap).reduce((accum, curr) => {
  const IconSvg = iconMap[curr];

  if (!IconSvg) {
    throw new Error(`Icon ${curr} not found`);
  }

  accum[curr] = styled(IconSvg)(iconStyles);
  return accum;
}, {});

const Icon = props => {
  if (!iconMap[props.icon]) {
    console.warn(`<Icon /> called with invalid icon prop "${props.icon}"`);
    return null;
  }
  const StyledIcon = styledIconMap[props.icon];

  return <StyledIcon {...props} />;
};

export default withTheme(Icon);
