import styled from 'styled-components';

export const MilestoneMemoriesStyles = styled.div`
  /*TODO: Tried to create a SWAL alert to confirm the deletion of a milestone, but to no avail, as it pops up BEHIND the create milestone Modal...*/
  .swal-modal-container {
    z-index: 100000 !important;
  }

  .button {
    margin: 0.25rem auto;
  }

  .deleteButton {
    background: hsl(0, 67%, 53%);
    color: white;

    :hover {
      background: hsl(0, 67%, 73%);
    }
  }
`;
