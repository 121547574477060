import React, { useState, useEffect } from "react";
import { Paper, Typography, Grid, TextField, Button} from "@material-ui/core";




import FriendsCircle from "../../../assets/friends-circle.jpg";
import EmailList from "../../Common/EmailList";
import SocialShare from "../../Common/SocialShare";

export default function InvitePage({}) {
  return (
    <Grid  style={{
 
    position:'absolute',
    left:'25%',
   
    }}>
      <Typography
        variant="h4"
        
        style={{
          marginTop: "1rem",
          marginLeft: window.innerWidth < 600 ? "1rem" : "3rem",
          marginRight: window.innerWidth < 600 ? "1rem" : "3rem",
          color:'white',
          textAlign:'center',
          marginBottom:'2rem',
        }}
      >
        Invite Your Friends To Iternal
      </Typography>
      <Paper
        style={{
          marginLeft: window.innerWidth < 600 ? "1rem" : "3rem",
          marginRight: window.innerWidth < 600 ? "1rem" : "3rem",
          background:"none",
          borderRadius:'20px',
       

        }}
      >
    
        <Grid id="Desktop-container" style={{display:'flex'}}>

          <Grid id="opacity-text" xs={6} style={{}}>
            <Typography variant="body1"  color="white" style={{color:'white', padding:'2rem',}}>
              Sharing stories with your friends has been shown to bring people
              closer together. <br></br> <br></br>By saving their stories, you
              ensure that they won't be forgotten and you could even discover
              something new about one another.
            </Typography>
            <img src={FriendsCircle} />
          </Grid>
          <Grid id="invite-options" xs={6} style={{ padding:'2rem', borderRadius:' 0rem 2rem 2rem 0rem',  background:"white"}}>
            <Typography variant="h5" color="primary" gutterBottom>
              Share your stories
            </Typography>
            <Typography variant="h6" color="primary">
              Send invite by email
            </Typography>
            <EmailList />
            <Typography variant="h6" color="primary">
              Share on socials
            </Typography>
            <SocialShare />
            <Typography variant="h6" color="primary" style={{marginTop:'2rem'}}>
              Copy link{" "}
            </Typography>
            <Grid style={{ marginTop: "1rem",  }}>
            <TextField
            className="inputRounded"
              variant="outlined"
              color="primary"
              placeholder="http://yourlink.com"
              style={{ borderRadius: "0px", width: "60%", height: "50px" }}
            >
              Link To Share
            </TextField>
            <Button
              variant="contained"
              color="secondary"
              style={{
                borderRadius: "0",
                height: "58px",
                fontWeight: "bolder",
              }}
            >
              Copy Link
            </Button>
          </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
