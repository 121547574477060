import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import { permissionAccess, users, memories } from '../../../api';

import { SuccessScreen } from '../../../NewComponents/Pages/SuccessScreen/SuccessScreen';

import history from '../../../history';

import {
  MEMORIES_URL,
  BASICS_URL,
} from '../../../constants/constantUrls';
import {
  SuccessPageWrapper,
} from './style';
import ReactGA from 'react-ga';

const Sucess = props => {
  const [emails, setEmails] = useState([]);
  // const [newEmail, setNewEmail] = useState('');
  // const [error, setError] = useState(null);
  const [newMemory, setNewMemory] = useState({});

  const fetchPermissionAccess = async () => {
    const {
      data,
      error,
    } = await permissionAccess.getAllGivenPermissionAccess();
    if (!error) {
      await Promise.all(
        data.map(async d => {
          if (d.sharedWithId) {
            const { data, error } = await users.getUserNameById({
              id: d.sharedWithId,
            });
            if (!error) {
              d.name = data.name;
              d.sent = false;
            }
          }
        }),
      );
      setEmails(data);
    }
  };

  const fetchNewMemory = async () => {
    const {
      data,
      error,
    } = await memories.getMemoryById({id: props.location.state._id});
    if (!error) {
      setNewMemory(data);
    }
  }

  // const splitEmailsList = emailsAsString => {
  //   // split on "," & ";" and " "
  //   const splittedEmails = emailsAsString.split(/[, ;]/g);
  //   return splittedEmails.filter(item => !!item).map(item => item.trim());
  // };

  // const addNewEmail = async email => {
  //   if (email) {
  //     const sharedWith = emails.map(e => e.sharedWithEmail);
  //     const emailExists = sharedWith.includes(email);
  //     if (emailExists) {
  //       return Swal.fire({
  //         title: 'This email has already access to your memories',
  //         text: 'Please choose a different one',
  //         type: 'warning',
  //         confirmButtonColor: '#66CC66',
  //         cancelButtonColor: '#8a8787',
  //         confirmButtonText: 'Ok',
  //       });
  //     }
  //     if (!emailExists) {
  //       const { error } = await permissionAccess.create({
  //         sharedWithEmails: splitEmailsList(email),
  //         memory: props.location.state,
  //       });

  //       if (!error) {
  //         const addEmails = splitEmailsList(email).map((e, i) => ({
  //           sharedWithEmail: e,
  //           sent: true,
  //           _id: i,
  //         }));
  //         setEmails([...addEmails, ...emails]);
  //         setNewEmail('');
  //         return Swal.fire({
  //           // this Email || Emails
  //           title: 'Email added',
  //           timer: 3000,
  //           type: 'success',
  //         });
  //       }
  //     }
  //   } else {
  //     return Swal.fire({
  //       title: 'You need to add an email first',
  //       type: 'warning',
  //       timer: 3000,
  //     });
  //   }
  // };

  // const sendEmailMemory = async email => {
  //   Swal.fire({
  //     title: 'Memory Sent',
  //     timer: 3000,
  //     type: 'success',
  //   });
  //   const { data } = await permissionAccess.sendMemory({
  //     sharedWithEmail: email,
  //     memory: props.location.state,
  //   });
  //   if (!data) {
  //     console.log('Something went wrong...');
  //   }
  // };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    fetchNewMemory();
    if (props.name) {
      fetchPermissionAccess();
    } else {
      Swal.fire({
        title: 'Your details are required',
        text: 'Please update your profile info',
        type: 'warning',
        confirmButtonColor: '#66CC66',
        cancelButtonColor: '#8a8787',
        confirmButtonText: 'Ok',
      }).then(() => {
        history.push(BASICS_URL);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history, props.name]);

  if (props.location.state === undefined) {
    return <Redirect to={MEMORIES_URL} />;
  }

  const shareLink = `${window.location.origin}/share/memory/${props.location.state._id}`;

  const messageBody = 'Check out this memory I just made on Iternal!';

  const {
    location: {
      // eslint-disable-next-line no-unused-vars
      state: { _id, isDraft },
    },
  } = props;

  // const onIconClick = () => {
  //   copyToClipboard(shareLink);
  // };

  // const onAddEmailClick = () => {
  //   emailSchema
  //     .validate(splitEmailsList(newEmail))
  //     .then(() => {
  //       setError(null);
  //       addNewEmail(newEmail);
  //     })
  //     .catch(err => setError(err.message));
  // };

  // return (
  //   <SuccessPageWrapper>
  //     <Box>
  //       {!isDraft ? (
  //         // save and share
  //         <>
  //           <TitleWrapper>
  //             <SuccessTitleWrapper>
  //               <Title>Congratulations! You saved a new memory.</Title>
  //             </SuccessTitleWrapper>
  //             <BodyText>
  //               We've notified your friends and family about this memory for you,
  //               but you probably know more people who would want to know your story so why
  //               not send it to them now?
  //             </BodyText>
  //           </TitleWrapper>

  //           <ShareSection>
  //             <SectionTitle>Share using social media</SectionTitle>
  //             <ShareIconGrid>
  //               <ShareIconWrapper>
  //                 <FacebookShareButton url={shareLink} quote={messageBody}>
  //                   <ShareIcon style={{ backgroundColor: '#E8F3FE' }}>
  //                     <FaFacebook className="fb" />
  //                   </ShareIcon>
  //                 </FacebookShareButton>
  //                 <p>Facebook</p>
  //               </ShareIconWrapper>
  //               <ShareIconWrapper>
  //                 <WhatsappShareButton
  //                   url={shareLink}
  //                   title={messageBody}
  //                   separator=": "
  //                 >
  //                   <ShareIcon style={{ backgroundColor: '#ECFAF1' }}>
  //                     <ImWhatsapp className="wa" />
  //                   </ShareIcon>
  //                 </WhatsappShareButton>
  //                 <p>Whatsapp</p>
  //               </ShareIconWrapper>
  //             </ShareIconGrid>
  //           </ShareSection>

  //           {/* <EmailSection>
  //           <SectionTitle>
  //             Share memory by email
  //           </SectionTitle>
  //         </EmailSection> */}

  //           <InviteSection>
  //             <SectionTitle>Share memory by email</SectionTitle>

  //             <GroupedInput className="field is-grouped">
  //               <Grid container spacing={1}>
  //                 <Grid item xs={9}>
  //                   <Email
  //                     newEmail={newEmail}
  //                     setNewEmail={setNewEmail}
  //                     style={{ width: '100%' }}
  //                   />
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <Button
  //                     variant="contained"
  //                     color="primary"
  //                     onClick={onAddEmailClick}
  //                     style={{ height: '100%' }}
  //                   >
  //                     Send Memory
  //                   </Button>
  //                 </Grid>
  //               </Grid>
  //               <p>{error}</p>
  //             </GroupedInput>
  //           </InviteSection>

  //           <FriendList>
  //             <FriendIitemWrapper>

  //               {/* <SectionTitle>Friends list</SectionTitle>
  //               {(emails &&
  //                 emails.length > 0 &&
  //                 emails.map(e => (
  //                   <FriendItem key={e._id}>
  //                     {e.name && e.name !== '' ? (
  //                       <p>
  //                         {e.name} ({e.sharedWithEmail})
  //                       </p>
  //                     ) : (
  //                       <p>{e.sharedWithEmail}</p>
  //                     )}
  //                     {e.sent ? (
  //                       <Button variant="contained" color="primary" disabled>
  //                         <FaCheck />
  //                         Memory Sent
  //                       </Button>
  //                     ) : (
  //                       <Button
  //                         variant="contained"
  //                         color="primary"
  //                         onClick={() => {
  //                           const updatedEmails = emails.map(email => {
  //                             if (email === e) {
  //                               email.sent = true;
  //                             }
  //                             return email;
  //                           });
  //                           sendEmailMemory(e.sharedWithEmail);
  //                           setEmails(updatedEmails);
  //                         }}
  //                       >
  //                         Send memory
  //                       </Button>
  //                     )}
  //                   </FriendItem>
  //                 ))) || <p>No access permission given yet!</p>} */}
  //             </FriendIitemWrapper>
  //           </FriendList>

  //           <LinkSection>
  //             <SectionTitle>Memory Link</SectionTitle>
  //             <LinkWrapper onClick={onIconClick}>
  //               <p>{shareLink}</p>
  //               <FaRegClone />
  //             </LinkWrapper>
  //           </LinkSection>

  //           <ButtonSection>
  //             <Button
  //               variant="contained"
  //               color="primary"
  //               text="View all memories"
  //               onClick={() => history.push(MEMORIES_URL)}
  //             >
  //               View all memories
  //             </Button>

  //             <NavLink
  //               to={{
  //                 pathname: CREATE_MEMORY_URL,
  //                 state: {
  //                   refreshNeeded: true,
  //                 },
  //               }}
  //               style={{ textDecoration: 'none' }}
  //             >
  //               <Button variant="contained" color="primary">
  //                 Create new memory
  //               </Button>
  //             </NavLink>
  //           </ButtonSection>
  //         </>
  //       ) : (
  //         // draft
  //         <>
  //           <BodyText>
  //             Congraluations! You have saved a new memory for you as a draft.
  //             Don't forget to finish it at some point. Sharing is caring!
  //           </BodyText>

  //           <ButtonSection>
  //             <NavLink
  //               to={{
  //                 pathname: CREATE_MEMORY_URL,
  //                 state: {
  //                   refreshNeeded: true,
  //                 },
  //               }}
  //               style={{ textDecoration: 'none' }}
  //             >
  //               <Button variant="contained" color="primary">
  //                 Create new memory
  //               </Button>
  //             </NavLink>
  //           </ButtonSection>
  //           <ButtonSection>
  //             <Button
  //               variant="contained"
  //               color="primary"
  //               onClick={() => history.push(MEMORIES_URL)}
  //             >
  //               Go to my memories
  //             </Button>
  //           </ButtonSection>
  //         </>
  //       )}
  //     </Box>
  //   </SuccessPageWrapper>
  // );
  return(
    <SuccessPageWrapper>
      <SuccessScreen memory={newMemory} friends={emails} shareLink={shareLink} messageBody={messageBody} />
    </SuccessPageWrapper>
  )
};

export default Sucess;
