import React from 'react';
import styled from 'styled-components';
import { FaCrown } from "react-icons/fa";

const Button = styled.button`
  background-color: #fff;
  color: var(--primary-gold);
  box-shadow: none;
  padding: 0.5rem;
  border-radius: 100px;
  border: 2px solid var(--primary-gold);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  line-height: 16px;
  cursor: pointer;
  width: 100%
  transition: 0.5s;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ mt }) => mt};
  margin:auto;

  svg {
    width:1.5rem; 
    height:1.5rem;
    margin-right:10px;
  }

  &:hover {
    background-color: var(--primary-gold);
    color: white;
    border: 2px solid vvar(--primary-gold));
  }
  img {
    padding-right: 0.5rem;
  }
`;

const PremiumButton = props => (
  <Button  {...props}>
    <FaCrown /> Go Premium
  </Button>
);
export default PremiumButton;
