import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { breakpoints } from '../../../constants/breakPoints';

export const Main = styled.main`
  padding-top: 1.5rem;


  @media ${breakpoints.laptop} {
    padding: 2rem 1rem 1rem 280px;
  }

  .paperStyle {
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
    max-width: 800px;
  }
`;

export const H1 = styled.h1`
  margin: 2rem 0;

  img {
    max-width: 43%;
  }
`;

export const P = styled.p`
  margin: 2.5rem 1rem;
  text-align: justify;
  line-height: 1.35;

  a {
    font-weight: 700;
    color: var(--secondary-aqua);
    text-decoration: underline;
  }
`;

export const A = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  margin-top: 1.5rem;
  justify-content: center;
  cursor: pointer;

  p {
    font-weight: 700;
    color: var(--neutral-dark);
    font-size: 1.25rem;
  }
  img {
    margin-left: 0.5rem;
  }
`;

export const Nav = styled.nav`
  position: fixed;
  bottom: 0px;
  background-color: var(--neutral-white);
`;

export const AnswerSection = styled.section`
  margin: 0.25rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;

  p {
    font-size: 1rem !important;
    width: 100%;
    height: 100%;
    color: var(--neutral-dark);
    filter: blur(0.85px);
    font-size: 1.2rem;
    opacity: 0.3;
  }
  img {
    cursor: pointer;
  }
  .left {
    text-align: right;
    margin: 1rem 1rem 1rem 0;
  }

  .right {
    text-align: left;
    margin: 1rem 0 1rem 1rem;
  }

  .correct {
    font-size: 1.25rem !important;
    color: var(--secondary-aqua);
    text-decoration: none;
    font-weight: bold;
    opacity: 1;
    filter: blur(0px);
  }
`;

export const Line = styled.div`
  align-self: center;
  height: 3rem;
  width: 0.1rem;
  min-width: 0.1rem;
  background-color: var(--secondary-aqua);
  opacity: 0.25;
`;
