import styled from "styled-components";

export const ContentCardStyles = styled.div`
  // width: calc(100% - 2.25rem);

  .modalContents {
    display: block;
    width: 100% !important;
    max-width: 700px !important;
    max-height: 90vh !important;
    overflow: scroll;
    padding: 1.15rem;
  }

  .cardWrapper {
    width: 100%;
    padding: 0rem !important;
    min-width: 220px !important;
    max-width: 100% !important;
    margin-bottom: 1.25rem;
  }

  .editButtonWrapper {
    text-align: right;
    margin-bottom: -2.25rem;
  }

  .card {
    width: 100% !important;
    min-width: 100%;
    padding: 0rem !important;
    .highlighted {
    }
  }

  .groupCard {
    width: 100% !important;
    min-width: 100%;
    padding: 0rem !important;
    .highlighted {
    }
  }

  .milestoneCard {
    border: 2px solid gold;
  }

  .promptCard {
    border: 3px solid hsla(178, 63%, 46%, 0.25);
  }

  .cardMediaWrapper {
    max-height: 30% !important;
    background: rgba(10, 10, 100, 0.075);
    overflow: hidden;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
  }

  .groupCardMediaWrapper {
    max-height: 30% !important;
    background: rgba(10, 10, 100, 0.075);
    overflow: hidden;
    margin-bottom: 0.75rem;
  }

  .contentWrapper {
    padding: 0 0.75rem 0.75rem;
  }

  .heartContainer {
    stroke: var(--secondary-cherry) !important;
    transition: fill 1s;
  }

  .heartContainer:hover {
    fill: #a3070766 !important;
    transition: fill 0.1s;
  }

  .active.heartContainer:hover {
    fill: var(--secondary-cherry) !important;
    transition: fill 0.1s;
  }
  .active.heartContainer {
    fill: var(--secondary-cherry) !important;
    transition: fill 0.1s;
  }

  .cardContentWrapper {
    padding: 0.75rem 0.75rem;

    .title {
      margin: 0.1rem 0rem;
      font-weight: bold;
    }

    .milestoneTitle {
      font-size: 1.6rem !important;
    }

    .promptTitle {
      margin-bottom: 0.25rem;
    }

    .privacySettingChip {
      margin: 0.5rem 0rem;
    }

    .milestoneChip,
    .promptChip {
      margin: 0.5rem 0.25rem;
      padding: 0.75rem;
      max-width: 15rem;
      width: 100%;
      .MuiChip-label {
        overflow: initial !important;
      }
    }

    .description {
      text-align: justify;
      text-align-last: left;
      line-height: 1.25;
      font-size: 1rem;
      margin: 0.5rem 0rem;
    }

    .reactionsSection {
      width: 100%;
    }

    .CTAButton {
      text-decoration: none;
      color: black;
      button {
        margin: 0.5rem 0rem;
      }
    }
  }
`;

export const GroupContentCardStyles = styled.div`
  // width: calc(100% - 2.25rem);

  .modalContents {
    display: block;
    width: 100% !important;
    max-width: 700px !important;
    max-height: 90vh !important;
    overflow: scroll;
    padding: 1.15rem;
  }

  .cardWrapper {
    width: 100%;
    padding: 0rem !important;
    min-width: 220px !important;
    max-width: 100% !important;
    margin-bottom: 1.25rem;
  }

  .editButtonWrapper {
    text-align: right;
    margin-bottom: -2.25rem;
  }

  .card {
    width: 100% !important;
    min-width: 100%;
    padding: 0rem !important;
    .highlighted {
    }
  }

  .groupCard {
    width: 100% !important;
    min-width: 100%;
    padding: 0rem !important;
    .highlighted {
    }
  }

  .milestoneCard {
    border: 2px solid gold;
  }

  .promptCard {
    border: 3px solid hsla(178, 63%, 46%, 0.25);
  }

  .cardMediaWrapper {
    max-height: 30% !important;
    background: rgba(10, 10, 100, 0.075);
    overflow: hidden;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
  }

  .groupCardMediaWrapper {
    max-height: 30% !important;
    background: rgba(10, 10, 100, 0.075);
    overflow: hidden;
    margin-bottom: 0.75rem;
  }

  .contentWrapper {
    padding: 0 0.75rem 0.75rem;
  }

  .heartContainer {
    stroke: var(--secondary-cherry) !important;
    transition: fill 1s;
  }

  .heartContainer:hover {
    fill: #a3070766 !important;
    transition: fill 0.1s;
  }

  .active.heartContainer:hover {
    fill: var(--secondary-cherry) !important;
    transition: fill 0.1s;
  }
  .active.heartContainer {
    fill: var(--secondary-cherry) !important;
    transition: fill 0.1s;
  }

  .cardContentWrapper {
    padding: 0.75rem 0.75rem;

    .title {
      margin: 0.1rem 0rem;
      font-weight: bold;
    }

    .milestoneTitle {
      font-size: 1.6rem !important;
    }

    .promptTitle {
      margin-bottom: 0.25rem;
      text-transform: capitalize;
    }

    .privacySettingChip {
      margin: 0.5rem 0rem;
    }

    .milestoneChip,
    .promptChip {
      margin: 0.5rem 0.25rem;
      padding: 0.75rem;
      max-width: 15rem;
      width: 100%;
      .MuiChip-label {
        overflow: initial !important;
      }
    }

    .description {
      text-align: justify;
      text-align-last: left;
      line-height: 1.25;
      font-size: 1rem;
      margin: 0.5rem 0rem;
    }

    .reactionsSection {
      width: 100%;
    }

    .CTAButton {
      text-decoration: none;
      color: black;
      button {
        margin: 0.5rem 0rem;
      }
    }
  }
`;
