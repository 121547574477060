import React, { useState } from "react";

// Components
import {
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Fade,
  Collapse,
  Zoom,
  Grid,
  Modal,
  Select,
  InputLabel,
  Switch,
  Tooltip,
  IconButton,
} from "@material-ui/core";

import Camera from "@material-ui/icons/CameraAltTwoTone";
import CreateMemoryButton from "./Buttons/CreateMemoryButtons";
import Lightbulb from "@material-ui/icons/EmojiObjectsOutlined";

import {
  FaImages,
  FaMicrophone,
  FaPencilAlt,
  FaTimesCircle,
  FaCamera,
  FaEdit,
  FaAngleDown,
  FaPlus,
  FaLightbulb,
  FaTimes
} from "react-icons/fa";

import DateFnsUtils from "@date-io/date-fns";
import {
  DatePicker as MaDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Swal from "sweetalert2";
import Icon from "../Icon/Icon";
import CancelActionButton from "./Buttons/CancelActionButton";
import CallToActionButton from "./Buttons/CallToActionButton";
import Voice from "../Inputs/GroupVoice.js";

export default function CreateMemoryPopup({
  storyTitle = "",
  setStoryTitle = () => {},
  userName = "",
  setUserName = () => {},
  userEmail = "",
  setUserEmail = () => {},
  userStory = "",
  setUserStory = () => {},
  storyLocation = "",
  setStoryLocation = () => {},
  image = [],
  setImage = () => {},
  tempImage = "",
  setTempImage = () => {},
  tempAudio = "",
  setTempAudio = () => {},
  setMessage = () => {},
  setIsRecording = () => {},
  isRecording = "",
  setRecordAudio = () => {},
  setMemoryType = () => {},
  memoryType = "",
  recordText = "",
  audio = "",
  date = new Date(),
  setDate = () => {},
  onSubmitFunction = () => {},
  isLoggedIn = false,
  uploadImage = () => {},
  customTheme,
  timelines = [],
  groupMemberStatus = "",
  onCloseFunction = () => {},
  onClose = () => {},
}) {
  // const [storyTitle, setStoryTitle] = useState('');
  // const [userName, setUserName] = useState('');
  // const [userStory, setUserStory] = useState('');
  // const [storyLocation, setStoryLocation] = useState(true);

  const onDateChange = (calDate) => {
    setDate(calDate);
  };

  const convertHSLToHSLA = (value) => {
    const sep = value.indexOf(",") > -1 ? "," : "";
    const hsl = value
      .substr(4)
      .split(")")[0]
      .split(sep);

    let h = hsl[0],
      s = hsl[1],
      l = hsl[2];

    const hsla = `hsla(${h} ${s} ${l} / 0.2)`;
    return hsla;
  };

  return (
    <Paper
      id="create-memory-popup"
      elevation={1}
      style={{
        position: "absolute",
        textAlign: "center",
        width: window.innerWidth < 1500 ? "70%" : "70%",
        maxWidth: "640px",
        backgroundColor: "",
        color: "",
        padding: "1rem",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "90%",
        borderRadius: "1.5rem",
        overflowY: "scroll",
      }}
    >
      <Grid item xs={12} style={{ textAlign: "right" }}>
      <div style={{display:'flex', justifyContent: "flex-end", marginRight: "1rem"}}>
      <div
                      style={{
                      
            marginBottom: window.innerWidth < 880 ? "1rem" : "0rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        height: "2rem",
                        width: "2rem",
                        backgroundColor:  `${customTheme.palette.primary.main}`,
                      
                        cursor: "pointer",
                        marginTop: "1rem",
                    
          
                      }}
                    >
                      <FaTimes
                        fill="white"
                        style={{
                          fontSize: "1rem",
                          width: window.innerWidth < 880 ? "50%" : "50%",
                          height: window.innerWidth < 880 ? "50%" : "50%",
                        }}
                        onClick={() => onClose()}
                      />
                    </div>
                    </div>
        
      </Grid>

      {memoryType === "" && [
        <Typography
          variant="h5"
          color="black"
          style={{
            marginBottom: "1rem",
            fontWeight: "900",
            textAlign: window.innerWidth < 750 ? "center" : "center",
            display: window.innerWidth < 700 ? "none" : "block",
            marginLeft: "1rem"
          }}
        >
          <span
            style={{
              fontWeight: "700",
            }}
          >
            Post a story to the timeline
          </span>
        </Typography>,
        <Typography
          variant="h5"
 
          style={{
            marginBottom: "1rem",
            fontWeight: "900",
            textAlign: window.innerWidth < 750 ? "center" : "center",
            display: window.innerWidth < 700 ? "block" : "none",
            color:'#474646'
          }}
        >
          <span
            style={{
              fontWeight: "900",
            }}
          >
            Post a story
          </span>
        </Typography>,
        <Grid
          item
          sm={12}
          style={{
            margin: "1rem 1rem 0",
          }}
        >
          <Typography
            variant="body1"
            color="black"
            textAlign="center"
            style={{
              marginBottom: "1rem",
              textAlign: "center",
              display: window.innerWidth < 750 ? "none" : "block",
              marginRight:'1rem'
              // textTransform: "uppercase",
            }}
          >
            A story doesn’t have to be long, it might be a 10 second voicenote
            of something funny or a picture taken in a moment you don’t want to
            forget. 
          </Typography>
          <Typography
            variant="body1"
            color="black"
            textAlign="center"
            style={{
              marginBottom: "1rem",
              textAlign: "center",
              display: window.innerWidth > 750 ? "none" : "block",
              textTransform: "",
            }}
          >
        Click one of the icons below to post a story to the timeline
          </Typography>
        </Grid>,
        <div
          style={{
            paddingLeft:'1rem',
            paddingRight:"1rem",
          }}
        >
          <Grid
            container
            spacing={1}
            style={{
              display: "flex",
              flexDirection: window.innerWidth < 650 ? "column" : "row",
              alignContent: "center",
              marginTop: window.innerWidth < 650 ? "1rem" : "2rem",
            marginBottom: window.innerWidth < 650 ? "1rem" : "2rem",
            }}
          >
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                marginTop: "1rem",
            marginBottom: "1rem",
              }}
            >
              <CreateMemoryButton
                text="Audio"
                Icon={FaMicrophone}
                clickFunction={async () => {
                  setMemoryType("audio");
                }}
                theme={customTheme}
              />
              {/* <Button
                variant="contained"
                onClick={() => setMemoryType("audio")}
                color="primary"
                style={{
                  marginTop: window.innerWidth < 880 ? "0rem" : "1rem",
                  visibility: window.innerWidth < 880 ? "hidden" : "default",
                  borderRadius: "100px",
                  minWidth: "164px",
                  padding: "0 1.75rem",
                  minHeight: "50px",
                }}
              >
                Audio Memory
              </Button> */}
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <CreateMemoryButton
                text="Write"
                Icon={FaEdit}
                clickFunction={async () => {
                  setMemoryType("written");
                }}
                theme={customTheme}
              />
              {/* <Button
                variant="contained"
                onClick={() => setMemoryType("written")}
                color="primary"
                style={{
                  marginTop: window.innerWidth < 880 ? "0rem" : "1rem",
                  visibility: window.innerWidth < 880 ? "hidden" : "default",
                  borderRadius: "100px",
                  minWidth: "164px",
                  padding: "0 1.75rem",
                  minHeight: "50px",
                }}
              >
                Write Memory
              </Button> */}
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: "1rem",
            marginBottom: "1rem",
              }}
            >
              <CreateMemoryButton
                text="Photo"
                Icon={FaCamera}
                // clickFunction={() => setRecordText(true)}
                clickFunction={async () => {
                  setMemoryType("photo");
                }}
                theme={customTheme}
              />
              {/* <Button
                variant="contained"
                onClick={() => setMemoryType("photo")}
                color="primary"
                style={{
                  marginTop: window.innerWidth < 880 ? "0rem" : "1rem",
                  visibility: window.innerWidth < 880 ? "hidden" : "block",
                  borderRadius: "100px",
                  minWidth: "164px",
                  padding: "0 1.75rem",
                  minHeight: "50px",
                }}
              >
                Photo Memory
              </Button> */}
            </Grid>
            <Typography
            variant="body1"
            color="black"

            style={{
              marginBottom: "1rem",
              textAlign: "center",
              display: window.innerWidth < 750 ? "none" : "block",
             marginRight: "1rem",
              marginTop:"1rem"
              // textTransform: "uppercase",
            }}
          >
         Click one of the icons above to record a voicenote,
            write a story or upload a photo...
          </Typography>
          </Grid>
        </div>,
      ]}

      {!isLoggedIn && memoryType === "written" && (
        <Grid
          container
          spacing={1}
          id="written-memory-fields"
          style={{ padding: window.innerWidth < 600 ? "1.5rem" : "2rem" }}
        >
          <Typography style={{ marginTop: "-2rem" }}>Memory Title</Typography>
          <TextField
            id="story-title"
            value={storyTitle}
            onChange={(text) => setStoryTitle(text.target.value)}
            variant="outlined"
            placeholder="Name Your Memory"
            // label="Story Title"
            fullWidth
            style={{ marginBottom: "1rem" }}
          />

          <Grid container spacing={1} alignItems="center" justify="center">
            <Grid item xs={12}>
              <Typography style={{ paddingTop: "" }}>Your Name</Typography>
              <TextField
                id="user-name"
                value={userName}
                onChange={(text) => setUserName(text.target.value)}
                variant="outlined"
                placeholder="Enter your name"
                // label="Your Name"
                fullWidth
                style={{marginBottom:"-0.5rem"}}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={12} md={7}>
              <Typography>Enter Your Email</Typography>
              <TextField
                id="user-email"
                value={userEmail}
                onChange={(text) => setUserEmail(text.target.value)}
                variant="outlined"
                placeholder="Enter your email"
                // label="Your Email"
                fullWidth
                style={{}}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography style={{ paddingTop: "" }}>Add a Date</Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MaDatePicker
                  openTo="year"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  placeholder="Select a date"
                  fullWidth
                  onChange={onDateChange}
                  maxDate={new Date().toISOString().split("T")[0]}
                  value={date || new Date()}
                  style={{}}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            {/* <Grid
              item
              xs={3}
              lg={2}
              className="Lightbulb"
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
                flexDirection: "column",
                width: "100%",
                marginTop:  window.innerWidth < 880 ? "0.5rem" : "0.5rem",
              }}
            >
              <Tooltip title="Write about your memory and then click Post To Timeline">
                <IconButton>
                  <Lightbulb fontSize="large" />
                </IconButton>
              </Tooltip>
              <Typography
                variant="body2"
                style={{
                  marginTop: "-0.5rem",
                  marginRight: "1rem",
                  textDecoration: "underline",
                }}
              >
                help
              </Typography>
            </Grid> */}

            <Grid item xs={12}>
              <Typography style={{ paddingTop: "" }}>
                Tell Your Story
              </Typography>
              <TextField
                id="user-story"
                value={userStory}
                onChange={(text) => setUserStory(text.target.value)}
                variant="outlined"
                placeholder="Write your story here"
                // label="Your Story"
                fullWidth
                style={{}}
                multiline
                rows={4}
              />
            </Grid>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "grey",
                marginTop: window.innerWidth < 880 ? "2rem" : "2rem",
                marginBottom: "1rem",
              }}
            ></div>

            <Grid container spacing={1} alignItems="center" justify="center">
              <Grid item xs={12} style={{ textAlign: "center", marginTop: window.innerWidth < 880 ? "0.5rem" :  "0.5rem", marginBottom: window.innerWidth < 880 ? "1rem" :"0.5rem", }}>
                <CallToActionButton
                  title="Post to timeline"
                  customColor={customTheme.palette.primary.main}
                  customFontColor={customTheme.palette.primary.contrastText}
                  clickFunction={() => onSubmitFunction()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* Written Memory - Logged In */}

      {isLoggedIn && memoryType === "written" && (
        <Grid
          container
          spacing={1}
          id="written-memory-fields"
          style={{ padding: window.innerWidth < 600 ? "1.5rem" : "2rem" }}
        >
          <Grid item xs={12}>
            <Typography style={{ marginTop: "-2rem" }}>Memory Title</Typography>
            <TextField
              id="story-title"
              value={storyTitle}
              onChange={(text) => setStoryTitle(text.target.value)}
              variant="outlined"
              placeholder="Name Your Story"
              // label="Memory Title"
              fullWidth
              style={{ marginBottom: "0.5rem" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ paddingTop: "" }}>Add a Date</Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MaDatePicker
                openTo="year"
                format="dd/MM/yyyy"
                inputVariant="outlined"
                placeholder="Select a date"
                fullWidth
                onChange={onDateChange}
                maxDate={new Date().toISOString().split("T")[0]}
                value={date || new Date()}
                style={{ marginBottom: "1rem" }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {/* <Grid
            item
            xs={3}
           
            md={2}
            className="Lightbulb"
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              marginTop: "0.5rem",
            }}
          >
            <Tooltip title="Write about your memory and then click Post To Timeline">
              <IconButton>
                <Lightbulb fontSize="large" />
              </IconButton>
            </Tooltip>
            <Typography
              variant="body2"
              style={{
                marginRight: "1rem",
                textDecoration: "underline",
                marginBottom: "0.5rem",
                marginTop:"-0.5rem",
              }}
            >
              help
            </Typography>
          </Grid> */}

          <Grid
            container
            spacing={1}
            alignItems="center"
            justify="center"
            style={{ }}
          >
            <Grid item xs={12}>
              <Typography style={{ paddingTop: "" }}>
                Tell Your Story
              </Typography>
              <TextField
                id="user-story"
                value={userStory}
                onChange={(text) => setUserStory(text.target.value)}
                variant="outlined"
                placeholder="Write your story here"
                // label="Your Story"
                fullWidth
                multiline
                rows={4}
                style={{ marginBottom: "1rem" }}
              />
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "grey",
              marginTop: "2rem",
              marginBottom: "1rem",
            }}
          ></div>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <CallToActionButton
              title="Post to timeline"
              customColor={customTheme.palette.primary.main}
              customFontColor={customTheme.palette.primary.contrastText}
              clickFunction={() => onSubmitFunction()}
            />
          </Grid>
        </Grid>
      )}

      {/* End of Written Memory */}

      {/* Photo Memory - Not Logged In */}

      {!isLoggedIn && memoryType === "photo" && (
        
          <Grid
            container
            spacing={1}
            id="photo-memory-fields"
            style={{ padding: window.innerWidth < 1000 ? "1rem" : "2rem" }}
          >
            <Grid item xs={12}>
              <Typography style={{marginTop:"-2rem"}}>Title Your Story</Typography>
              <TextField
                id="story-title"
                value={storyTitle}
                onChange={(text) => setStoryTitle(text.target.value)}
                variant="outlined"
                placeholder="Name Your Story"
                // label="Story Title"
                fullWidth
                style={{ marginBottom: "1rem" }}
              />
              <Grid container spacing={1} alignItems="center" justify="center">
                <Grid item xs={12}>
                  <Typography style={{ marginTop: window.innerWidth < 600 ? "-0.5rem" : '0rem',}}>
                    Enter Your Name
                  </Typography>
                  <TextField
                    id="user-name"
                    value={userName}
                    onChange={(text) => setUserName(text.target.value)}
                    variant="outlined"
                    placeholder="Enter your name"
                    // label="Your Name"
                    fullWidth
                    style={{ marginBottom: "1rem" }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ marginBottom: window.innerWidth < 880 ? "-2rem" :  "-4rem" }}>
                <Grid item xs={12} md={6}>
                  <Typography style={{ marginTop: window.innerWidth < 600 ? "-0.5rem" : '0rem',}}>
                    Enter Your Email
                  </Typography>
                  <TextField
                    id="user-email"
                    value={userEmail}
                    onChange={(text) => setUserEmail(text.target.value)}
                    variant="outlined"
                    placeholder="Enter your email"
                    // label="Your Email"
                    fullWidth
                    style={{ marginBottom: "1rem" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ marginTop: window.innerWidth < 600 ? "-1rem" : '0rem',}}>Add a Date</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <MaDatePicker
                      openTo="year"
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      placeholder="Select a date"
                      fullWidth
                      onChange={onDateChange}
                      maxDate={new Date().toISOString().split("T")[0]}
                      value={date || new Date()}
                     
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              className="ImagePicker"
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                flexDirection:
                  window.innerWidth < 600 ? "column" : "column",
              }}
            >
              <img
                src={tempImage}
                style={{
                  maxWidth: "100%",
                  maxHeight: "15rem",
                  textAlign: "center",
                  marginBottom: "1rem",
                  marginTop: window.innerWidth < 880 ? "3rem" : '4rem',
                  cover: "contain",
                }}
              />
               {tempImage.length > 0 ? (
                <>
                  {/* <Button
            customColor={customTheme.palette.primary.main}
            customFontColor={customTheme.palette.primary.contrastText}
            
        style={{borderRadius:"50%"}}
            variant="outlined"
            startIcon /> */}
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  > */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        height: "4rem",
                        width: "4rem",
                        backgroundColor: convertHSLToHSLA(
                          customTheme.palette.primary.light
                        ),
                        border: `solid 0.25rem ${customTheme.palette.primary.main}`,
                        backgroundBlendMode: "lighten",
                        cursor: "pointer",
                        marginTop: "1rem",
                      }}
                    >
                      <FaCamera
                        fill={customTheme.palette.primary.main}
                        style={{
                          fontSize: "1.5rem",
                          width: window.innerWidth < 880 ? "50%" : "50%",
                          height: window.innerWidth < 880 ? "50%" : "50%",
                        }}
                        onClick={() => uploadImage()}
                      />
                    </div>
                    <Typography  
                    variant="body"
                    style={{
                      marginTop: "1rem",
                    }}
     >Click To Change</Typography>
       <Grid container>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "grey",
                        marginTop: "2rem",
                        marginBottom: "0rem",
                      }}
                    ></div>
                    <Grid container>
                      <Grid item xs={12} style={{ marginTop: "2rem", textAlign:'center' }}>
                        <Typography>Tell Your Story</Typography>
                        <TextField
                          id="user-story"
                          value={userStory}
                          onChange={(text) => setUserStory(text.target.value)}
                          variant="outlined"
                          placeholder="Write your story here"
                          // label="Your Story"
                          fullWidth
                          style={{ marginBottom: "1rem" }}
                          multiline
                          rows={2}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <CallToActionButton
                          title="Post to timeline"
                          customColor={customTheme.palette.primary.main}
                          customFontColor={
                            customTheme.palette.primary.contrastText
                          }
                          clickFunction={() => onSubmitFunction()}
                        />
                        </Grid>
                      </Grid>
                    </Grid>
                  {/* </div> */}
                </>
              ) : (
                <>
                  {/* <CallToActionButton
                  customColor={customTheme.palette.primary.main}
                  customFontColor={customTheme.palette.primary.contrastText}
                  clickFunction={() => uploadImage()}
                  title=""
                  variant="outlined"
                  startIcon=<FaCamera style={{ fontSize: "1.5rem" }} />
                />
                Click to Change
                 */}
                 
                 <Grid
              item
              xs={12}
              className="Lightbulb"
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
                marginTop: window.innerWidth < 600 ? "-2.5rem" : '0rem',
                marginBottom: window.innerWidth < 600 ? "0.5rem" : '-4rem'
              }}
            >
              <Tooltip 
                title={
                  <Typography variant='body1'>Click the Camera Icon to choose a photo to add to your memory.</Typography>
                }
              >
                <IconButton>
                  <Lightbulb fontSize="large" />
                </IconButton>
              </Tooltip>
              <Typography
                variant="body2"
                style={{
                  marginTop: "-0.5rem",
                  marginRight: "1rem",
                  textDecoration: "underline",
                }}
              >
                help
              </Typography>
            </Grid>

            <CreateMemoryButton
                    text="Photo"
                    Icon={FaCamera}
                    clickFunction={async () => {
                      uploadImage();
                    }}
                    theme={customTheme}
                  />
                  <Typography
                    variant="body"
                    style={{
                      marginTop: "1rem",
                    }}
                  >
                    Click To Upload
                  </Typography>
                
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "grey",
                      marginTop: "2rem",
                      marginBottom: "1rem",
                    }}
                  ></div>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <CallToActionButton
                      title="Post to timeline"
                      customColor={customTheme.palette.primary.main}
                      customFontColor={customTheme.palette.primary.contrastText}
                      clickFunction={() => onSubmitFunction()}
                    />
                  </Grid>
                </>
              
              )}
              </Grid>
          </Grid>
      )}
      {/* Photo Memory - Logged in */}
      {isLoggedIn && memoryType === "photo" && (
        <div>
          <Grid
            container
            spacing={1}
            id="photo-memory-fields"
            style={{ padding: window.innerWidth < 600 ? "1rem" : "1rem" }}
          >
            <Grid item xs={12}>
              <Typography style={{ marginTop: "-1rem" }}>
                Title Your Story
              </Typography>
              <TextField
                id="story-title"
                value={storyTitle}
                onChange={(text) => setStoryTitle(text.target.value)}
                variant="outlined"
                placeholder="Name Your Story"
                // label="Story Title"
                fullWidth
                style={{}}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ paddingTop: "" }}>Add a Date</Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MaDatePicker
                  openTo="year"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  placeholder="Select a date"
                  fullWidth
                  onChange={onDateChange}
                  maxDate={new Date().toISOString().split("T")[0]}
                  value={date || new Date()}
                  style={{ marginBottom: "1rem" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
           

            <Grid
              item
              xs={12}
              className="ImagePicker"
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                flexDirection:
                  window.innerWidth < 600 ? "column" : "column",
              }}
            >
              <img
                src={tempImage}
                style={{
                  maxWidth: "100%",
                  maxHeight: "15rem",
                  textAlign: "center",
                  cover: "contain",
                }}
              />
              {tempImage.length > 0 ? (
                <>
                  {/* <Button
            customColor={customTheme.palette.primary.main}
            customFontColor={customTheme.palette.primary.contrastText}
            
        style={{borderRadius:"50%"}}
            variant="outlined"
            startIcon /> */}
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: 'blue',
                    }}
                  > */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        height: "4rem",
                        width: "4rem",
                        backgroundColor: convertHSLToHSLA(
                          customTheme.palette.primary.light
                        ),
                        border: `solid 0.25rem ${customTheme.palette.primary.main}`,
                        backgroundBlendMode: "lighten",
                        cursor: "pointer",
                        marginTop: "1rem",
                      }}
                    >
                      <FaCamera
                        fill={customTheme.palette.primary.main}
                        style={{
                          fontSize: "1.5rem",
                          width: window.innerWidth < 880 ? "50%" : "50%",
                          height: window.innerWidth < 880 ? "50%" : "50%",
                        }}
                        onClick={() => uploadImage()}
                      />
                    </div>
                    <Typography  
                    variant="body"
                    style={{
                      marginTop: "1rem",
                    }}
     >Click To Change</Typography>
       <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "grey",
                        marginTop: "1rem",
                        // marginBottom: "1rem",
                      }}
                    ></div>
       <Grid container>
                      <Grid item xs={12} style={{ marginTop: "1rem", textAlign:'center' }}>
                        <Typography>Tell Your Story</Typography>
                        <TextField
                          id="user-story"
                          value={userStory}
                          onChange={(text) => setUserStory(text.target.value)}
                          variant="outlined"
                          placeholder="Write your story here"
                          // label="Your Story"
                          fullWidth
                          style={{ marginBottom: "1rem" }}
                          multiline
                          rows={2}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <CallToActionButton
                          title="Post to timeline"
                          customColor={customTheme.palette.primary.main}
                          customFontColor={
                            customTheme.palette.primary.contrastText
                          }
                          clickFunction={() => onSubmitFunction()}
                        />
                        </Grid>
                      </Grid>
                    
                  {/* </div> */}
                </>
              ) : (
                <>
                  {/* <CallToActionButton
                  customColor={customTheme.palette.primary.main}
                  customFontColor={customTheme.palette.primary.contrastText}
                  clickFunction={() => uploadImage()}
                  title=""
                  variant="outlined"
                  startIcon=<FaCamera style={{ fontSize: "1.5rem" }} />
                />
                Click to Change
                 */}
                  <Grid
              item
              xs={12}
              className="Lightbulb"
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
                marginTop: window.innerWidth < 600 ? "-1.5rem" : '0rem',
                marginBottom: window.innerWidth < 600 ? "0rem" : '-4rem'
              }}
            >
              <Tooltip 
                title={
                  <Typography variant='body1'>Click the Camera Icon to choose a photo to add to your memory.</Typography>
                } 
              >
                <IconButton>
                  <Lightbulb fontSize="large" />
                </IconButton>
              </Tooltip>
              <Typography
                variant="body2"
                style={{
                  marginTop: "-0.5rem",
                  marginRight: "1rem",
                  textDecoration: "underline",
                }}
              >
                help
              </Typography>
            </Grid>
                  <CreateMemoryButton
                    text="Photo"
                    Icon={FaCamera}
                    clickFunction={async () => {
                      uploadImage();
                    }}
                    theme={customTheme}
                  />
                  <Typography
                    variant="body"
                    style={{
                      marginTop: "1rem",
                    }}
                  >
                    Click To Upload
                  </Typography>
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "grey",
                      marginTop: "2rem",
                      marginBottom: "1rem",
                    }}
                  ></div>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <CallToActionButton
                      title="Post to timeline"
                      customColor={customTheme.palette.primary.main}
                      customFontColor={customTheme.palette.primary.contrastText}
                      clickFunction={() => onSubmitFunction()}
                    />
                  </Grid>
                </>
              )}

              {/* <Button onClick={() => uploadImage()} variant="contained" style={{padding:'3rem', borderRadius:'100%', border:'solid, 20px, grey'}}>
            <FaCamera style={{fontSize:"3rem"}}/>
            </Button> */}
            </Grid>
          </Grid>
        </div>
      )}
      {/* End of Photo Memory */}

      {/* Voice Memory */}
      {isLoggedIn && memoryType === "audio" && (
        <Grid
          container
          spacing={1}
          id="voice-memory-fields"
          style={{ padding: window.innerWidth < 600 ? "1.5rem" : "2rem" }}
        >
          <Grid item xs={12}>
            <Typography style={{ marginTop: "-2rem" }}>Memory Title</Typography>
            <TextField
              id="story-title"
              value={storyTitle}
              onChange={(text) => setStoryTitle(text.target.value)}
              variant="outlined"
              placeholder="Name Your Memory"
              // label="Story Title"
              fullWidth
              style={{}}
            />
            <Grid
              container
              alignItems="center"
              justify="center"
              style={{ marginTop: "1rem" }}
            >
              {/* <Grid item xs={12} md={7}>
                <Typography style={{ paddingTop: '' }}>
                  Enter Your Name
                </Typography>
                <TextField
                  id="user-name"
                  value={userName}
                  onChange={text => setUserName(text.target.value)}
                  variant="outlined"
                  placeholder="Enter your name"
                  // label="Your Name"
                  fullWidth
                  style={{ marginBottom: '1rem' }}
                />
              </Grid> */}
              <Grid item xs={12} md={12}>
                <Typography style={{ paddingTop: "" }}>Add a Date</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <MaDatePicker
                    openTo="year"
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    placeholder="Select a date"
                    fullWidth
                    onChange={onDateChange}
                    maxDate={new Date().toISOString().split("T")[0]}
                    value={date || new Date()}
                    style={{}}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            {/* <Typography style={{ paddingTop: '' }}>Enter Your Email</Typography>
            <TextField
              id="user-email"
              value={userEmail}
              onChange={text => setUserEmail(text.target.value)}
              variant="outlined"
              placeholder="Enter your email"
              // label="Your Email"
              fullWidth
              style={{ marginBottom: '1rem' }}
            /> */}

            {/* <Typography style={{ paddingTop: '' }}>Tell Your Story</Typography>
            <TextField
              id="user-story"
              value={userStory}
              onChange={text => setUserStory(text.target.value)}
              variant="outlined"
              placeholder="Write your story here"
              // label="Your Story"
              fullWidth
              style={{ marginBottom: '1rem' }}
            /> */}
          </Grid>
          <Grid
            item
            sm={12}
            className="Lightbulb"
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              flexDirection: "column",
              width: "100%",
              // marginTop: "1rem",
            }}
          >
            <Tooltip 
              title={
                <Typography variant='body1'>Click the microphone to record a voice note. When you have finished speaking click the microphone to stop the recording.</Typography>
              }
            >
              <IconButton>
                <Lightbulb fontSize="large" />
              </IconButton>
            </Tooltip>
            <Typography
              variant="body2"
              style={{
                marginTop: "-0.5rem",
                marginRight: "1rem",
                textDecoration: "underline",
              }}
            >
              help
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            className="Microphone"
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              
            }}
          >
            <div style={{ textAlign: "center", marginTop: "-2rem", }}>
          
              <Voice
                voice={tempAudio}
                setVoice={setTempAudio}
                setDescription={setMessage}
                setIsRecording={setIsRecording}
                isRecording={isRecording}
                setVideo={() => {}}
                setError={() => {}}
                isGroup
                renderAlternativeChoice={() => <></>}
                tempVoice={audio}
                theme={customTheme}
              />
            </div>
          </Grid>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "grey",
              
              marginBottom: "2rem",
            }}
          ></div>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <CallToActionButton
              title="Post to timeline"
              customColor={customTheme.palette.primary.main}
              customFontColor={customTheme.palette.primary.contrastText}
              clickFunction={() => onSubmitFunction()}
            />
          </Grid>
        </Grid>
      )}
      {!isLoggedIn && memoryType === "audio" && (
        <Grid
          container
          spacing={1}
          id="voice-memory-fields"
          style={{ padding: window.innerWidth < 600 ? "1.5rem" : "2rem" }}
        >
          <Grid item sm={12}>
            <Typography style={{ marginTop: "-2rem" }}>Title Your Story</Typography>
            <TextField
              id="story-title"
              value={storyTitle}
              onChange={(text) => setStoryTitle(text.target.value)}
              variant="outlined"
              placeholder="Name Your Story"
              // label="Story Title"
              fullWidth
              style={{ marginBottom: "1rem" }}
            />
            <Grid container spacing={1} alignItems="center" justify="center">
              <Grid item xs={12} md={6}>
                <Typography style={{  }}>
                  Enter Your Name
                </Typography>
                <TextField
                  id="user-name"
                  value={userName}
                  onChange={(text) => setUserName(text.target.value)}
                  variant="outlined"
                  placeholder="Enter your name"
                  // label="Your Name"
                  fullWidth
                  style={{marginBottom: window.innerWidth < 880 ? "0.5rem" : "0rem",}}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography style={{ paddingTop: "" }}>Add a Date</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <MaDatePicker
                    openTo="year"
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    placeholder="Select a date"
                    fullWidth
                    onChange={onDateChange}
                    maxDate={new Date().toISOString().split("T")[0]}
                    value={date || new Date()}
                    style={{}}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <Typography style={{ marginTop: "1rem" }}>
              Enter Your Email
            </Typography>
            <TextField
              id="user-email"
              value={userEmail}
              onChange={(text) => setUserEmail(text.target.value)}
              variant="outlined"
              placeholder="Enter your email"
              // label="Your Email"
              fullWidth
              style={{ marginBottom: "1rem" }}
            />

            {/* <Typography style={{ paddingTop: '' }}>Tell Your Story</Typography>
            <TextField
              id="user-story"
              value={userStory}
              onChange={text => setUserStory(text.target.value)}
              variant="outlined"
              placeholder="Write your story here"
              // label="Your Story"
              fullWidth
              style={{ marginBottom: '1rem' }}
            /> */}
          </Grid>
          <Grid
            item
            sm={12}
            className="Lightbulb"
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              flexDirection: "column",
              width: "100%",
              marginTop: "-1rem",
            }}
          >
            <Tooltip 
              title={
                <Typography variant='body1'>Click the microphone to record a voice note. When you have finished speaking click the microphone to stop the recording.</Typography>
              }
            >
              <IconButton>
                <Lightbulb fontSize="large" />
              </IconButton>
            </Tooltip>
            <Typography
              variant="body2"
              style={{
                marginTop: "-0.5rem",
                marginRight: "1rem",
                textDecoration: "underline",
              }}
            >
              help
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            className="Microphone"
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              marginTop: "-2.5rem",
            }}
          >
            <div style={{ textAlign: "center", marginTop: "", }}>
              <Voice
                voice={tempAudio}
                setVoice={setTempAudio}
                setDescription={setMessage}
                setIsRecording={setIsRecording}
                isRecording={isRecording}
                setVideo={() => {}}
                setError={() => {}}
                isGroup
                renderAlternativeChoice={() => <></>}
                tempVoice={audio}
                theme={customTheme}
              />
            </div>
          </Grid>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "grey",
              marginTop: window.innerWidth < 880 ? "-1rem" : "1rem",
              marginBottom: window.innerWidth < 880 ? "1rem" : "2rem",
            }}
          ></div>

          <Grid
            item
            xs={12}
            style={{ textAlign: "center", marginTop: "" }}
          >
            <CallToActionButton
              title="Post to timeline"
              customColor={customTheme.palette.primary.main}
              customFontColor={customTheme.palette.primary.contrastText}
              clickFunction={() => onSubmitFunction()}
            />
          </Grid>
        </Grid>
      )}
      {/* End of Audio Memory */}
      <Grid container spacing={1} alignItems="center" justify="center">
        {groupMemberStatus === "owner" || groupMemberStatus === "admin" ? (
          <>
            {/* <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 'bolder',
                  marginTop: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {' '}
                Story Location{' '}
              </Typography>
            </Grid>

            <Grid item>
              {timelines[0]?.name ? timelines[0]?.name : 'History Timeline'}
            </Grid>

            <Switch
              value={storyLocation}
              checked={storyLocation}
              onChange={() => setStoryLocation(prev => !prev)}
              color="primary"
              // style={{ color: '#10c1b8' }}
              style={{ color: customTheme.palette.primary.main }}
            />

            <Grid item>
              {timelines[1]?.name ? timelines[1]?.name : 'Our Stories'}
            </Grid> */}
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Paper>
  );
}
