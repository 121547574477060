import styled from 'styled-components';
import { breakpoints, devices } from '../../../constants/breakPoints';

export const Main = styled.main`
  max-width: 600px;
  margin: 0 auto;
  padding: 30px 2rem 5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;


  form {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    label {
      width: 100%;
    }
  }

  @media ${breakpoints.laptop} {
    padding: 2rem 2rem 5rem 25rem;
  }
`;

export const LoadWrapper = styled.section`
  max-width: 100rem;

  @media ${breakpoints.laptop} {
    padding-top: 10rem;
    padding-left: 320px;
  }

  @media ${devices.tablet} and (orientation: portrait) {
    padding-top: 5rem;
  }

  @media ${devices.mobile} {
    padding-top: 4rem;
  }
`;