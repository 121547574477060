import axios from 'axios';

import {
  API_COMPETITION_URL,
} from '../constants/apiRoutes';

import makeError from './makeError';

const getCompetitionByCode = async ({ competitionCode, options } = {}) => {
  try {
    const { data } = await axios.get(`${API_COMPETITION_URL}/${competitionCode}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const competitions = {
    getCompetitionByCode
};

export default competitions;
