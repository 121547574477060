import styled from 'styled-components';

export const ShareModalStyles = styled.div`
  .paperStyles {
    padding: 1.5rem;
  }

  .section {
    background: rgba(0, 0, 0, 0.035);
    border-radius: 0.5rem;
    padding: 0.25rem 0.75rem;
    margin: 0.5rem 0.5rem;
    .sectionTitle {
      font-size: 1.3rem;
      letter-spacing: 0.15rem;
      opacity: 0.5;
      text-transform: uppercase;
      margin: 0.75rem 0rem 0rem 0.75rem;
    }
  }

  .priavcyButtonsSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0.25rem;
    margin: 0.25rem;
    overflow-y: hidden;
    overflow-x: scroll;

    .privacyButton {
      display: block;
      margin: 0.5rem;
      min-width: 12rem;
      min-height: 12rem;
    }

    .privacyIcon {
      transition: all 0.5s ease;
      svg {
        transform: scale(2.35) !important;
        opacity: 0.25;
      }
    }

    .privacyIconHighlighted {
      transition: all 0.5s ease;
      svg {
        transform: scale(3.2) !important;
        opacity: 1;
        fill: white !important;
      }
    }

    .privacyText {
      position: relative;
      bottom: -2rem;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      line-height: 1.45rem;
      font-size: 1.25rem;
      display: block;
    }
  }

  .privacyInfo {
    margin: 0.25rem !important;
  }

  .memoryLinkSection {
    .memoryLinkWrapper {
      cursor: pointer;
      padding: 0.9rem;
      margin: 0.35rem auto;
      background-color: rgb(170 170 170 / 23%);
      border-radius: 0.5rem;
    }
    .memoryLink {
      font-size: 1.25rem;
      overflow-y: hidden;
      overflow-x: scroll;
      mask-image: linear-gradient(to right, white, white, transparent);
      mask-mode: alpha;
    }
    .memoryIcon {
      margin: 0 auto !important;
    }
    .copyLinkHelperText {
      margin-left: 0.75rem !important;
      opacity: 0.5;
    }
  }

  .specificGroupsSection {
    padding: 0.75rem;
    .scrollContainer {
      margin: 1rem 0.25rem;
      max-height: 22rem;
      border-radius: 0.5rem;
      padding: 0.75rem;
      overflow: scroll;
      background: rgba(0, 0, 0, 0.03);
    }
    .listItemsWrapper {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.5rem;
      padding: 0.75rem;
      background: white;
      margin: 0.2rem 0rem 0.5rem 0rem;
    }
    .listTitle {
      font-size: 1.25rem;
      margin-bottom: 0.75rem;
    }

    .listItem {
      width: 100% !important;
      transition: all 0.5s ease;
    }
    .unchecked {
      background: hsla(43, 85%, 50%, 0);
      transition: all 0.5s ease;
    }
    .checked {
      background: hsla(43, 85%, 50%, 0.2);
      transition: all 0.5s ease;
      padding-left: 1%;
    }
    .relationshipChip {
      margin: 0rem 0.15rem;
      cursor: pointer !important;
    }
    .listItemDetails {
      display: block;
    }
    .listItemDivider {
      width: 100%;
      opacity: 0.75 !important;
      margin-left: 0rem !important;
    }
  }

  .socialMediaButtonsWrapper {
    padding: 1rem;
    .roundSocialMediaButton {
      border-radius: 999rem;
      width: 7rem;
      height: 7rem;
      overflow: none;
      border: 1px solid black;
    }
  }
`;
