// React
import React, { useState } from 'react';

// MUI
import { Typography, Collapse } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

// Styled Components
import {
  CardWrapper,
  RequiredSpan,
  CardDescription,
  Section,
  TitleFlexWrapper,
} from './style';

// Input Cards
import {
  Date,
  ImageWrapper,
  Title,
  VoiceOrDescription,
  AddEmailWrapper,
  ShareLinkWrapper,
  PrivacyWrapper,
} from '../Inputs';

// import { PrivacySettings } from '../../../NewComponents/Pages/CreateMemory/PrivacySettings/PrivacySettings';

const Card = ({ card, dataWithHandlers, error, buttons, id }) => {
  const {
    title,
    setTitle,
    description,
    setDescription,
    date,
    setDate,
    dateType,
    setDateType,
    dateAge,
    setDateAge,
    setImagesArrayRequest,
    imagesArrayRequest,
    imagesArray,
    setImagesArray,
    tempVoice,
    tempVideo,
    tempImages,
    setTempImages,
    voice,
    setVoice,
    video,
    setVideo,
    newEmail,
    setNewEmail,
    addNewEmail,
    link,
    setLink,
    setSingleError,
    setIsRecording,
    isRecording,
    isPublic,
    setIsPublic,
    taggedEmails,
    setTaggedEmails,
    category,
    privacySetting,
    setPrivacySetting,
    checklist,
    setChecklist,
    isUploading,
    setIsUploading
  } = dataWithHandlers;
  const [focus, setFocus] = useState(false);
  const onFocusChange = (e, ref) => {
    if (e === false) {
      // onblur
      setFocus(false);
      return;
    }
    // onfocus
    setFocus(true);
    if (ref) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }, 250);
    }
  };

  const setError = errorValue => {
    setSingleError({ error: errorValue, key: card.inputType });
  };
  const buttonInputs = ['privacy'];

  return (
    <Section id={id}>
      <CardWrapper focus={focus}>
        <TitleFlexWrapper>
          {card.title !== '' && (
            <Typography variant="h5" color="primary" gutterBottom >
              {card.title}
            </Typography>
          )}
        </TitleFlexWrapper>
        {card.description !== '' && (
          <CardDescription>{card.description}</CardDescription>
        )}
        {/* <CardLabel>{card.label}</CardLabel> */}
        {card.inputType === 'title' && (
          <Title
            onFocusChange={onFocusChange}
            title={title}
            setTitle={setTitle}
            setError={setError}
            error={error}
          />
        )}
        {card.inputType === 'date' && (
          <Date
            onFocusChange={onFocusChange}
            date={date}
            setDate={setDate}
            setError={setError}
            dateType={dateType}
            setDateType={setDateType}
            dateAge={dateAge}
            setDateAge={setDateAge}
            error={error}
          />
        )}
        {card.inputType === 'voiceOrDescriptionIncluded' && (
          <>
            <VoiceOrDescription
              onFocusChange={onFocusChange}
              voice={voice}
              tempVoice={tempVoice}
              setVoice={setVoice}
              video={video}
              tempVideo={tempVideo}
              setVideo={setVideo}
              description={description}
              setDescription={setDescription}
              setError={setError}
              setIsRecording={setIsRecording}
              isRecording={isRecording}
              setTitle={setTitle}
              title={title}
              category={category}
              error={error}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
            />
          </>
        )}
        {card.inputType === 'images' && (
          <ImageWrapper
            onFocusChange={onFocusChange}
            showIcon
            imagesArray={imagesArray}
            setImagesArray={setImagesArray}
            setImagesArrayRequest={setImagesArrayRequest}
            imagesArrayRequest={imagesArrayRequest}
            tempImages={tempImages}
            setTempImages={setTempImages}
            error={error}
          />
        )}
        {card.inputType === 'email' && (
          <AddEmailWrapper
            newEmail={newEmail}
            setNewEmail={setNewEmail}
            addNewEmail={addNewEmail}
            error={error}
          />
        )}
        {card.inputType === 'shareLink' && (
          <ShareLinkWrapper link={link} setLink={setLink} />
        )}
        {card.inputType === 'privacy' && (
          <>
            <PrivacyWrapper
              onFocusChange={onFocusChange}
              privacySetting={privacySetting}
              setPrivacySetting={setPrivacySetting}
              taggedEmails={taggedEmails}
              setTaggedEmails={setTaggedEmails}
              checklist={checklist}
              setChecklist={setChecklist}
              setError={setError}
              error={error}
            />
          </>
        )}
        <Collapse in={error}>
          <Alert severity="error">
            {typeof error === 'string'
              ? error
              : error && error.map(val => <p>{val}</p>)}
          </Alert>
        </Collapse>
        <div style={{ marginBottom: '1rem', paddingBottom: '1rem' }}>
          {buttonInputs.includes(card.inputType) && <>{buttons()}</>}
        </div>
        
      </CardWrapper>
    </Section>
  );
};

export default Card;
