import axios from 'axios';
import Swal from 'sweetalert2';
import * as logRocket from '../helpers/logRocket';
import firebase from './firebase';

import {
  API_USERS_BASE_URL,
  API_USERINFO_URL,
  API_USERNAME_URL,
  API_USER_NAME_URL,
  API_PROFILE_BASICS_URL,
  API_RESET_PASSWORD,
  API_UPDATE_USERINFO_URL,
  API_IS_LOGGED_IN,
} from '../constants/apiRoutes';
import makeError from './makeError';

const getAll = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(API_USERS_BASE_URL);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const getUserById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${API_USERS_BASE_URL}/${id}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const getUserNameById = async ({ id }) => {
  try {
    const { data } = await axios.get(`${API_USER_NAME_URL}/${id}`);
    return { data };
  } catch (error) {
    return { error: makeError(error) };
  }
};

const getUserInfoFromToken = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(API_USERINFO_URL);
    logRocket.identify(data.user);

    return { data: { ...data.user, isLoggedIn: true } };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const getNameAndIdFromLinkToken = async ({ shortId, options }) => {
  try {
    const { data } = await axios.get(`${API_USERNAME_URL}/${shortId}`);

    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

// gets token to reset user pw
const getResetToken = async ({ isLoggedIn, email, options }) => {
  try {
    const {
      data: { token },
    } = await axios.post(API_RESET_PASSWORD, {
      email,
      isLoggedIn,
    });

    return { token };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const getUserBasicProfile = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(API_PROFILE_BASICS_URL);
    const validUserInfo = data && Object.keys(data).length > 0;
    if (validUserInfo) {
      return { data };
    }
    return { data: [] };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const updateProfileImage = ({ image, userId, done, options }) => {
  try {
    Swal.fire({
      title: '',
      html: "<h1 style='font-size: 1.5rem;'>uploading image...</h1>",
      showConfirmButton: false,
      onOpen: async () => {
        const {
          data: { generatedName } = {},
          error,
        } = await firebase.uploadImage({
          image,
          userId,
          isProfile: true,
        });

        Swal.close();
        if (!error && generatedName) {
          done(true);
          await axios.put(API_UPDATE_USERINFO_URL, {
            imagePath: generatedName,
          });
          return {};
        }
        throw error;
      },
    });
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const isLoggedIn = async ({ options } = {}) => {
  try {
    const { data, error } = await axios.get(API_IS_LOGGED_IN);
    if (!error) return data;
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const users = {
  getAll,
  getUserById,
  getUserNameById,
  getNameAndIdFromLinkToken,
  getUserInfoFromToken,
  getUserBasicProfile,
  getResetToken,
  updateProfileImage,
  isLoggedIn,
};

export default users;
