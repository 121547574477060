import React, { useState } from 'react';
import * as yup from 'yup';

import Email from './Email';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Swal from 'sweetalert2';

import { Error } from './style';

const emailSchema = yup
  .array(
    yup
      .string()
      .email('please enter valid email or emails')
      .required('required'),
  )
  .max(5, 'Sorry, you can add up to 5 emails in one go');

const AddEmailWrapper = ({ newEmail, setNewEmail, addNewEmail, isLoading }) => {
  const [error, setError] = useState(null);

  const splitEmailsList = emailsAsString => {
    // split on "," & ";" and " "
    const splittedEmails = emailsAsString.split(/[, ;]/g);
    return splittedEmails.filter(item => !!item).map(item => item.trim());
  };

  const onClick = () => {
    if (newEmail && newEmail.length > 0) {
      emailSchema
        .validate(splitEmailsList(newEmail))
        .then(() => {
          setError(null);
          addNewEmail(newEmail);
        })
        .catch(err => setError(err.message)
      );
    } else {
      Swal.fire({
        title: 'Please enter an email address first',
        timer: 3000,
        type: 'warning',
      });
    }

  };
  return (
        <Grid container spacing={1}>
          <Grid item xs={9}>
              <Email newEmail={newEmail} setNewEmail={setNewEmail} />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={onClick}
              style={{ height: '100%', width: '100%' }}
            >Add</Button>
          </Grid>
          <Error>{error}</Error>
        </Grid>
  
  );
};

export default AddEmailWrapper;
