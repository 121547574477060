import React from 'react';

import { SpinnerWrapper } from './style';

const Spinner = ({ width, height }) => (
  <SpinnerWrapper>
    <svg
      className="spinner"
      width={width || '65px'}
      height={height || '65px'}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="path"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  </SpinnerWrapper>
);

export default Spinner;
