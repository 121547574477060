import styled, {keyframes} from 'styled-components';

import { breakpointsMax } from '../../../constants/breakPoints';

const spin = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const BackgroundWrapper = styled.section`
  // background: linear-gradient(-45deg, var(--secondary-aqua), var(--secondary-blue), var(--secondary-blue), var(--primary-navy)) !important;
  // background: linear-gradient(-45deg, var(--primary-gold), var(--primary-tangerine)) !important;
  background: linear-gradient(105deg, #0bafaa77, #1d6a9177, #0a215699) !important;
  // animation: ${spin} 15s ease infinite;
  // background-size: 400% 400%;
`;

export const Main = styled.main`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  padding: 2rem 3rem 1rem 280px;
  // background-image: url("https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80");
  // background: linear-gradient(0deg, var(--secondary-aqua), var(--secondary-blue));

  @media ${breakpointsMax.laptop} {
    padding: 1rem;
  }
`;

export const StageTwoMain = styled.main`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  height: 100vh;
  padding: 2rem 3rem 1rem 280px;
  // background-image: url("https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80");
  // background: linear-gradient(0deg, var(--secondary-aqua), var(--secondary-blue));

  @media ${breakpointsMax.laptop} {
    padding: 1rem;
  }
`;
