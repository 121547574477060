import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';

// MUI Components
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Avatar, Button } from '@material-ui/core';
import Camera from '@material-ui/icons/PhotoCamera';

// ICONS
import { FaArrowAltCircleUp } from 'react-icons/fa';

import {
  DatePicker as MaDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import ReactGA from 'react-ga';
import MemoryIcon from '@material-ui/icons/Book';
import Plus from '@material-ui/icons/Add';
import MilestoneIcon from '@material-ui/icons/Star';
import Photos from '@material-ui/icons/PhotoCamera';
import Groups from '@material-ui/icons/People';
import Friends from '@material-ui/icons/EmojiPeople';
import Profile from '@material-ui/icons/Face';
import Stories from '@material-ui/icons/MenuBook';
import { Divider } from '../../../NewComponents/Divider/Divider';
import imagePlaceHolder from '../../../assets/person-placeholder.jpg';
import * as S from './style';
import Spinner from '../../Common/Spinner';
import PaymentPopup from '../../Common/PaymentPopup';
import PremiumButton from '../../Common/PremiumButton';
import crown from '../../../assets/crown.svg';
import Icon from '../../Common/Icon/Icon';
import history from '../../../history';
import CallToActionButton from '../../Common/GroupComponents/Buttons/CallToActionButton';

import {
  API_PROFILE_BASICS_URL,
  API_UPDATE_USERINFO_URL,
} from '../../../constants/apiRoutes';
// API
import {
  users,
  payments,
  downloadAllData,
  legacyContacts,
  bonus,
  giftedBonus,
} from '../../../api';

// bottom nav

// MUI Icons

import * as C from '../../../constants/constantUrls';

import { RESET_URL, GO_PREMIUM } from '../../../constants/constantUrls';
import { validate, createErrors } from '../../../helpers/validation';
import { handleLogout } from '../../../helpers';
import UserContext from '../../../context/user';
// function to capitalize names and places
const capitalize = str => {
  const split = str.split(' ');
  if (split.length === 1) {
    return split[0].charAt(0).toUpperCase() + str.slice(1);
  }
  if (split.length > 1) {
    return split.map(el => el.charAt(0).toUpperCase() + el.slice(1)).join(' ');
  }
  return '';
};

export default class Basics extends Component {
  state = {
    id: '',
    name: '',
    birthday: '',
    hometown: '',
    email: '',
    premiumCheck: {
      isPremium: false,
      subscriptionCancelAt: '',
    },
    image: {
      imagePath: '',
    },
    imageUrl: null,
    errors: {},
    legacyErrors: {},
    isLoading: false,
    isShowing: false,
    expanded: 'panel1',
    legacyName: '',
    legacyEmail: '',
    legacyRelation: '',
    legacyId: '',
    legacyExists: false,
    legacyUserId: '',
    bonuses: [],
    giftedBonuses: [],
    groups: [],
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    // get user info
    ReactGA.pageview(window.location.pathname + window.location.search);
    const { data: userData } = await users.getUserInfoFromToken();

    if (userData) {
      this.setState({
        premiumCheck: {
          isPremium: userData.isPremium,
          subscriptionCancelAt: userData.subscriptionCancelAt,
        },
      });
    }

    // get group information

    const { data: groupsData } = await axios.get('/api/groups');

    if (groupsData) {
      this.setState({
        groups: groupsData,
      });
    }

    axios.get(API_PROFILE_BASICS_URL).then(({ data }) => {
      const { email: userEmail } = this.props;
      const { name, birthday, hometown, email, _id } = data;
      let { image } = data;
      if (!image || Object.keys(image).length === 0) {
        image = {};
        image.pathname = imagePlaceHolder;
      }
      this.setState({ isLoading: false });

      this.setState({
        name,
        birthday,
        hometown,
        email: email || userEmail,
        image,
        id: _id,
      });
    });

    const { data: legacyData } = await legacyContacts.getLegacyContact();
    if (legacyData?.length > 0) {
      this.setState({
        legacyName: legacyData?.[0]?.name,
        legacyEmail: legacyData?.[0]?.email,
        legacyRelation: legacyData?.[0]?.relation,
        legacyId: legacyData?.[0]?._id,
        legacyExists: true,
        legacyUserId: legacyData?.[0]?.userId,
      });
    }

    const { data: bonusData } = await bonus.getUserBonuses();
    if (bonusData?.length > 0) {
      this.setState({ bonuses: bonusData });
    }

    const { data: giftedBonusData } = await giftedBonus.getGiftedBonuses();
    if (giftedBonusData?.length > 0) {
      this.setState({ giftedBonuses: giftedBonusData });
    }
  }

  deleteAccount = () => {
    const { history, handleChangeState } = this.props;

    return Swal.fire({
      title: '',
      html: "<h1 style='font-size: 1.5rem;'>deleting your account...</h1>",
      showConfirmButton: false,
      onOpen: async () => {
        await axios
          .delete(API_PROFILE_BASICS_URL)
          .then(() => {
            Swal.close();
            handleLogout(history, handleChangeState);
          })
          .catch(err => {
            Swal.fire({
              type: 'error',
              title: 'Error',
              text: 'Something went wrong!',
            });
          });
      },
    });
  };

  deleteImageProfile = () => {
    const {
      image: { id },
    } = this.state;
    axios
      .patch(API_PROFILE_BASICS_URL, { imageId: id })
      .then(() => {
        this.setState({
          image: {
            pathname: imagePlaceHolder,
          },
          imageUrl: null,
        });
      })
      .catch(err => {
        const error =
          err.response && err.response.data && err.response.data.error;
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error || 'Something went wrong!',
        });
      });
  };

  canelSubsription = async () =>
    Swal.fire({
      title: '',
      html:
        "<h1 style='font-size: 1.5rem;'>cancelling your subsciption...</h1>",
      showConfirmButton: false,
      onOpen: async () => {
        const { data, error } = await payments.cancel();
        if (data) {
          Swal.fire({
            type: 'success',
            title: 'Success',
            text: 'Deleted your subscription!',
          }).then(() => window.location.reload());
        }

        if (error) {
          Swal.fire({
            type: 'error',
            title: 'Error',
            text: 'Something went wrong!',
          });
        }
      },
    });

  handleInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDatePicker = date => {
    this.setState({ birthday: date });
  };

  // uses validation and sets state accordingly
  validator = async () => {
    const {
      name = '',
      birthday = '',
      hometown = '',
      email = '',
      errors,
    } = this.state;

    try {
      const validated = await validate('profileBasics', {
        name,
        birthday,
        hometown,
        email,
      });
      if (validated) {
        this.setState({ errors: {} });
      }
    } catch (error) {
      const submitErrors = createErrors(error);
      this.setState({ errors: { ...errors, submitErrors } });
    }
  };

  legacyValidator = async () => {
    const {
      legacyName = '',
      legacyEmail = '',
      legacyRelation = '',
      legacyErrors,
    } = this.state;

    try {
      const validated = await validate('legacyContact', {
        legacyName,
        legacyEmail,
        legacyRelation,
      });
      if (validated) {
        this.setState({ legacyErrors: {} });
      }
    } catch (error) {
      const submitErrors = createErrors(error);
      this.setState({ legacyErrors: { ...legacyErrors, submitErrors } });
    }
  };

  // validate form and submit
  handleSubmit = async e => {
    e.preventDefault();
    const { profile } = this.props;
    const { errors } = this.state;

    try {
      // check if user already has profile (if not create one)
      const hasProfile = profile && Object.keys(profile).length > 0;
      this.setState({ isLoading: true });
      const updateProfile = await axios.post(API_UPDATE_USERINFO_URL, {
        state: this.state,
        hasNoProfile: !hasProfile,
      });

      if (updateProfile) {
        this.setState({ isLoading: false });
        return Swal.fire({
          title: hasProfile ? 'Profile updated!' : 'Profile created!',
          type: 'success',
          timer: 3000,
        }).then(() => window.location.reload());
      }
    } catch (err) {
      this.setState({ isLoading: false });

      if (err.response && err.response.data.error.includes('email_1 dup key')) {
        return this.setState({
          errors: {
            ...errors,
            emailSubmit: 'Email already exists',
          },
        });
      }
    }
  };

  handleSubmitImg = async event => {
    const { id } = this.state;
    const {
      files: [image],
    } = event.target;

    if (!image) return;

    const done = success =>
      this.setState({ imageUrl: success ? URL.createObjectURL(image) : null });

    await users.updateProfileImage({
      image,
      userId: id,
      done,
    });
  };

  handleSwal = typeToDelete => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#66CC66',
      cancelButtonColor: '#8a8787',
      confirmButtonText:
        typeToDelete === 'canelSubsription'
          ? 'Yes, cancel my subscription'
          : 'Yes, delete',
    }).then(result => {
      if (result.value) {
        switch (typeToDelete) {
          case 'imageProfile':
            this.deleteImageProfile();
            break;
          case 'account':
            this.deleteAccount();
            break;
          case 'canelSubsription':
            this.canelSubsription();
            break;
          default:
            break;
        }
      }
    });
  };

  handleLegacyCreate = async () => {
    const { data } = await legacyContacts.createLegacyContact({
      legacyContact: {
        name: this.state.legacyName,
        email: this.state.legacyEmail,
        relation: this.state.legacyRelation,
      },
    });
    if (data) {
      this.setState({ legacyExists: true });
      const { data: legacyData } = await legacyContacts.getLegacyContact();
      if (legacyData.length > 0) {
        this.setState({
          legacyName: legacyData[0].name,
          legacyEmail: legacyData[0].email,
          legacyRelation: legacyData[0].relation,
          legacyId: legacyData[0]._id,
          legacyExists: true,
          legacyUserId: legacyData[0].userId,
        });
      }
    }
  };

  handleLegacyUpdate = async () => {
    await legacyContacts.updateLegacyContact({
      legacyContact: {
        legacyContactId: this.state.legacyId,
        userId: this.state.legacyUserId,
        email: this.state.legacyEmail,
        name: this.state.legacyName,
        relation: this.state.legacyRelation,
      },
    });
    const { data: legacyData } = await legacyContacts.getLegacyContact();
    if (legacyData.length > 0) {
      this.setState({
        legacyName: legacyData[0].name,
        legacyEmail: legacyData[0].email,
        legacyRelation: legacyData[0].relation,
        legacyId: legacyData[0]._id,
        legacyExists: true,
        legacyUserId: legacyData[0].userId,
      });
    }
  };

  toggleSubscripeModal = () => {
    this.setState(state => ({ isShowing: !state.isShowing }));
  };

  done = () => {
    this.context.update({ isPremium: true });
    return window.location.reload();
  };

  handleAccordionChange = panel => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  render() {
    const {
      name,
      birthday,
      hometown,
      email,
      errors,
      isLoading,
      isShowing,
      image: { imagePath },
      imageUrl,
      premiumCheck,
      expanded,
      legacyName,
      legacyEmail,
      legacyRelation,
      legacyExists,
      legacyErrors,
      bonuses,
      giftedBonuses,
      groups,
    } = this.state;
    const { email: userEmail } = this.props;

    // const hasProfile = profile && Object.keys(profile).length > 0;

    const isEmpty = [name, email].map(el => !el || !el.length).includes(true);

    const isLegacyEmpty = [legacyName, legacyEmail]
      .map(el => !el || !el.length)
      .includes(true);

    return (
      <>
        <S.Main
          style={{ marginLeft: window.innerWidth < 1024 ? '0rem' : '3rem' }}
        >
          <S.FlexWrapper>
            <S.MobileFlexWrapper />
          </S.FlexWrapper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="h3"
                gutterBottom
                style={{
                  marginBottom: '2rem',
                  color: 'white',
                  fontSize: window.innerWidth < 1024 ? '3rem' : '4rem',
                }}
              >
                Your Profile
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              style={{
                paddingLeft: window.innerWidth < 1024 ? '1rem' : '0rem',
                paddingRight: window.innerWidth < 1024 ? '1rem' : '0rem',
                marginBottom: window.innerWidth < 1024 ? '1rem' : '2rem',
              }}
            >
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={this.handleAccordionChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                      About You
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={0}
                    style={{
                      padding: window.innerWidth < 500 ? '0rem' : '1rem',
                      width: '100%',
                    }}
                  >
                    <S.ProfileDataWrapper style={{ paddingBottom: '2rem' }}>
                      <Grid container style={{}}>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          lg={2}
                          xl={1}
                          style={{
                            display: window.innerWidth < 600 ? 'flex' : 'block',
                            justifyContent: 'center',
                          }}
                        >
                          <S.ImgContainer>
                            <S.Img
                              src={imageUrl || imagePath || imagePlaceHolder}
                            />
                          </S.ImgContainer>
                        </Grid>
                        <Grid item xs={12} sm={9} lg={8} xl={11}>
                          <S.Section>
                            <Typography
                              gutterBottom
                              style={{
                                fontSize:
                                  window.innerWidth < 400 ? '1.5rem' : '2rem',
                                marginBottom: '0rem',
                                marginLeft: '0rem',
                                textAlign:
                                  window.innerWidth < 600 ? 'center' : 'left',
                                display:
                                  window.innerWidth < 600 ? 'flex' : 'block',
                                justifyContent: 'center',
                              }}
                            >
                              {name}
                            </Typography>

                            <div
                              style={{
                                display:
                                  window.innerWidth < 600 ? 'flex' : 'block',
                                flexDirection:
                                  window.innerWidth < 540 ? 'column' : 'row',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant="contained"
                                color="secondary"
                                component="label"
                                style={{
                                  marginBottom:
                                    window.innerWidth < 538 ? '1rem' : '0rem',
                                  borderRadius: '100px',
                                  minWidth: '164px',
                                  minHeight: '50px',
                                  fontWeight: '700',
                                  boxShadow:
                                    'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',
                                  marginTop: '1rem',
                                }}
                              >
                                Upload Image
                                <input
                                  hidden
                                  type="file"
                                  name="avatar"
                                  id="file"
                                  accept="image/png, image/jpeg, image/pjpeg, image/gif"
                                  onChange={this.handleSubmitImg}
                                  onSubmit={this.handleSubmitImg}
                                />
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                // background={theme.palette.error.light}
                                onClick={() => this.handleSwal('imageProfile')}
                                style={{
                                  borderRadius: '100px',
                                  minWidth: '164px',
                                  minHeight: '50px',
                                  fontWeight: '700',
                                  boxShadow:
                                    'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',

                                  marginTop: '1rem',
                                }}
                              >
                                Delete Image
                              </Button>
                            </div>
                          </S.Section>
                        </Grid>
                      </Grid>
                    </S.ProfileDataWrapper>

                    <form method="post">
                      <S.FlexWrapper>
                        <label>
                          <TextField
                            type="text"
                            name="name"
                            variant="outlined"
                            label="Name"
                            value={(name ? capitalize(name) : '').replace(
                              /\s+/gi,
                              ' ',
                            )}
                            onChange={e => this.handleInput(e)}
                            onBlur={() => this.validator()}
                            placeholder="What is your name?"
                            style={{ width: '100%' }}
                          />
                          <S.SubmitErrorMsg>
                            {errors.submitErrors && errors.submitErrors.name}
                          </S.SubmitErrorMsg>
                        </label>

                        <label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <MaDatePicker
                              openTo="year"
                              format="dd/MM/yyyy"
                              inputVariant="outlined"
                              label="Birthday"
                              onChange={date => {
                                this.handleDatePicker(date);
                              }}
                              maxDate={new Date().toISOString().split('T')[0]}
                              value={birthday || undefined}
                              style={{ width: '100%' }}
                            />
                          </MuiPickersUtilsProvider>
                          <S.SubmitErrorMsg>
                            {' '}
                            {errors.submitErrors &&
                              errors.submitErrors.birthday}
                          </S.SubmitErrorMsg>
                        </label>
                      </S.FlexWrapper>

                      <S.FlexWrapper>
                        <label>
                          <TextField
                            type="text"
                            name="hometown"
                            variant="outlined"
                            label="Hometown"
                            value={(hometown
                              ? capitalize(hometown)
                              : ''
                            ).replace(/\s+/gi, ' ')}
                            onChange={e => this.handleInput(e)}
                            onBlur={() => this.validator()}
                            placeholder="What is your hometown?"
                            style={{ width: '100%' }}
                          />
                          <S.SubmitErrorMsg>
                            {' '}
                            {errors.submitErrors &&
                              errors.submitErrors.hometown}
                          </S.SubmitErrorMsg>
                        </label>

                        <label>
                          <TextField
                            type="email"
                            name="email"
                            variant="outlined"
                            label="Email"
                            value={email || ''}
                            onChange={e => this.handleInput(e)}
                            onBlur={() => this.validator()}
                            placeholder="What is your email address?"
                            style={{ width: '100%' }}
                            disabled
                          />
                          <S.ErrorMsg>
                            {(errors.submitErrors &&
                              errors.submitErrors.email) ||
                              errors.emailSubmit}{' '}
                          </S.ErrorMsg>
                        </label>
                      </S.FlexWrapper>

                      <S.ButtonWrapper>
                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          disabled={
                            (errors && Object.keys(errors).length > 0) ||
                            isEmpty ||
                            isLoading
                          }
                          onClick={this.handleSubmit}
                          style={{
                            marginTop: '1rem',
                            borderRadius: '100px',
                            minWidth: '164px',
                            minHeight: '50px',
                            fontWeight: '700',
                            boxShadow:
                              'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',

                            marginBottom: '1rem',
                          }}
                        >
                          Update
                        </Button>
                        {isLoading && <Spinner />}
                      </S.ButtonWrapper>
                    </form>
                    <S.ActionSection>
                      {premiumCheck.isPremium &&
                        !premiumCheck.subscriptionCancelAt && (
                          <S.ActionButton
                            type="button"
                            onClick={() => this.handleSwal('canelSubsription')}
                          >
                            <Icon icon="x" width="16" height="16" />
                            <span>&nbsp;Cancel Subscription</span>
                          </S.ActionButton>
                        )}
                    </S.ActionSection>
                    {premiumCheck.isPremium &&
                      premiumCheck.subscriptionCancelAt && (
                        <S.SubscriptionCancelAt>
                          Your premium subscription ends{' '}
                          {moment(premiumCheck.subscriptionCancelAt).format(
                            'DD-MM-YYYY',
                          )}
                        </S.SubscriptionCancelAt>
                      )}
                  </Paper>
                </AccordionDetails>
              </Accordion>

              {/* <Accordion
                expanded={expanded === 'panelgroups'}
                onChange={this.handleAccordionChange('panelgroups')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panelgroups-content"
                  id="panelgroups-header"
                >
                  <Typography variant="h5" gutterBottom>
                    View Your Groups
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={0}
                    style={{
                      padding: '1rem',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    <p
                      style={{
                        paddingBottom: '2rem',
                        lineHeight: '1.5rem',
                        textAlign: 'center',
                      }}
                    >
                      Groups are a fantastic way of sharing joint memories with
                      the people you care most about. You can view your groups here: 
                      
                    </p>

                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {groups.map((group, index) => (
                        <Paper
                          elevation={3}
                          key={index}
                          style={{
                            padding: '2rem',
                            margin: '1rem',
                            textAlign: 'center',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                        >
                          <Avatar
                            src={group?.profilePicture?.imagePath?.length > 0 ? group?.profilePicture?.imagePath : require('../../../assets/orange_logo.png')}
                            alt="Your group avatar"
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              width: '80px',
                              height: '80px',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }}
                          />
                          <Typography
                            variant="h6"
                            gutterBottom
                            style={{
                              fontWeight: 'bolder',
                              marginTop: '1rem',
                            }}
                          >
                            {group.groupName}
                          </Typography>
                          <Typography variant="h7" gutterBottom>
                            {group.groupDescription}
                          </Typography>
                          <NavLink
                            to={`/group/${group.groupShortCode}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: '1rem',
                              }}
                            >
                              View The {group.groupName} Group
                            </Button>
                          </NavLink>
                        </Paper>
                      ))}
                    </Grid>
                  </Paper>
                </AccordionDetails>
              </Accordion> */}

              <Accordion
                expanded={expanded === 'panel2'}
                onChange={this.handleAccordionChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h5" color="primary" gutterBottom>
                    Legacy Contact
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={0}
                    style={{
                      padding: '1rem',
                      textAlign: 'left',
                      maxWidth: '500px',
                      width: '100%',
                      margin: '0 auto',
                    }}
                  >
                    <p
                      style={{
                        paddingBottom: '2rem',
                        lineHeight: '1.5rem',
                        textAlign: 'center',
                      }}
                    >
                      Adding someone as your Legacy Contact will allow them to
                      administer your account in accordance with your will. They
                      won't be able to remove or edit any of your memories, but
                      will still be able to share them with the ones you love.
                    </p>
                    <TextField
                      type="text"
                      name="legacyName"
                      variant="outlined"
                      value={(legacyName ? capitalize(legacyName) : '').replace(
                        /\s+/gi,
                        ' ',
                      )}
                      onChange={e => this.handleInput(e)}
                      onBlur={() => this.legacyValidator()}
                      placeholder="What is their name?"
                      label="Name *"
                      style={{ width: '100%', margin: '1rem 0' }}
                    />
                    <TextField
                      type="email"
                      name="legacyEmail"
                      variant="outlined"
                      value={legacyEmail || ''}
                      onChange={e => this.handleInput(e)}
                      onBlur={() => this.legacyValidator()}
                      placeholder="What is their email address?"
                      label="Email *"
                      style={{ width: '100%', margin: '1rem 0' }}
                    />
                    <TextField
                      type="text"
                      name="legacyRelation"
                      variant="outlined"
                      value={(legacyRelation
                        ? capitalize(legacyRelation)
                        : ''
                      ).replace(/\s+/gi, ' ')}
                      onChange={e => this.handleInput(e)}
                      onBlur={() => this.legacyValidator()}
                      placeholder="What is your relation to them?"
                      label="Relation"
                      style={{ width: '100%', margin: '1rem 0' }}
                    />
                    <S.ButtonWrapper style={{ paddingTop: '1rem' }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{
                          marginTop: '1rem',
                          borderRadius: '100px',
                          minWidth: '164px',
                          minHeight: '50px',
                          fontWeight: '700',
                          boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',
                          marginBottom: '1rem',
                        }}
                        disabled={
                          (legacyErrors &&
                            Object.keys(legacyErrors).length > 0) ||
                          isLegacyEmpty ||
                          isLoading
                        }
                        onClick={() =>
                          legacyExists
                            ? this.handleLegacyUpdate()
                            : this.handleLegacyCreate()
                        }
                      >
                        {legacyExists ? 'Update' : 'Add'}
                      </Button>
                      {isLoading && <Spinner />}
                    </S.ButtonWrapper>
                  </Paper>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={this.handleAccordionChange('panel3')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography color="primary" variant="h5" gutterBottom>
                    Download My Data
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={0}
                    style={{
                      padding: '1rem',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    <p
                      style={{
                        paddingBottom: '2rem',
                        maxWidth: '500px',
                        width: '100%',
                        margin: '0 auto',
                        lineHeight: '1.5rem',
                      }}
                    >
                      We all like to be control of our data right? Here you can
                      download all your memories to keep offline and share in an
                      easy to read format.
                    </p>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{
                        marginTop: '1rem',
                        borderRadius: '100px',
                        minWidth: '164px',
                        minHeight: '50px',
                        fontWeight: '700',
                        boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',
                        marginBottom: '1rem',
                      }}
                      type="submit"
                      onClick={() => downloadAllData.getAll()}
                    >
                      Download My Data
                    </Button>
                  </Paper>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel6'}
                onChange={this.handleAccordionChange('panel6')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <Typography color="primary" variant="h5" gutterBottom>
                    My Bonuses
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={0}
                    style={{
                      padding: '0rem 1rem 1rem',
                      textAlign: 'left',
                      width: '100%',
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Claimed Bonuses
                    </Typography>
                    <div
                      style={{
                        maxHeight: '8rem',
                        overflow: 'scroll',
                        background: 'hsl(0, 0%, 97%, 1)',
                        padding: '1rem',
                        borderRadius: '.5rem',
                      }}
                    >
                      {bonuses.length > 0 ? (
                        bonuses.map((e, i) => (
                          <div key={i}>
                            {i > 0 && <Divider logo />}
                            <Typography>
                              {e.title}:{' '}
                              {e.type === 'memories' &&
                                `${e.quantity} extra memor${
                                  e.quantity === 1 ? 'y' : 'ies'
                                }`}
                              {e.type === 'premium' &&
                                `${e.quantity} day${e.quantity !== 1 &&
                                  's'} of premium`}
                            </Typography>
                          </div>
                        ))
                      ) : (
                        <Typography>You haven't claimed any bonuses</Typography>
                      )}
                    </div>

                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ marginTop: '1rem' }}
                    >
                      Giftable Bonuses
                    </Typography>
                    <div
                      style={{
                        maxHeight: '8rem',
                        overflow: 'scroll',
                        background: 'hsl(0, 0%, 97%, 1)',
                        padding: '1rem',
                        borderRadius: '.5rem',
                      }}
                    >
                      {giftedBonuses.length > 0 ? (
                        giftedBonuses.map((e, i) => (
                          <div key={i}>
                            {i > 0 && <Divider logo />}
                            <Typography>
                              {e.title}:{' '}
                              {e.type === 'memories' &&
                                `${e.quantity} extra memor${
                                  e.quantity === 1 ? 'y' : 'ies'
                                }`}
                              {e.type === 'premium' &&
                                `${e.quantity} day${e.quantity !== 1 &&
                                  's'} of premium`}
                            </Typography>
                            <Typography>To: {e.recipientEmail}</Typography>
                          </div>
                        ))
                      ) : (
                        <Typography>You haven't gifted any bonuses</Typography>
                      )}
                    </div>
                  </Paper>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel4'}
                onChange={this.handleAccordionChange('panel4')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography color="primary" variant="h5" gutterBottom>
                    Change Password
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={0}
                    style={{
                      padding: '1rem',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{
                        marginTop: '1rem',
                        borderRadius: '100px',
                        minWidth: '164px',
                        minHeight: '50px',
                        fontWeight: '700',
                        boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',
                        marginBottom: '1rem',
                      }}
                      type="submit"
                      onClick={async () => {
                        // get token
                        const { token, error } = await users.getResetToken({
                          isLoggedIn: true,
                          email: userEmail,
                        });

                        if (error) {
                          return (
                            <S.ErrorMsg>Error changing Password</S.ErrorMsg>
                          );
                        }

                        if (token) {
                          // redirect to reset index page with token
                          history.push({
                            pathname: `${RESET_URL}/${token}`,
                            state: { resetAsLoggedIn: true },
                          });
                        }
                      }}
                    >
                      Change Password
                    </Button>
                  </Paper>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel5'}
                onChange={this.handleAccordionChange('panel5')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <Typography color="primary" variant="h5" gutterBottom>
                    Delete Account
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={0}
                    style={{
                      padding: '1rem',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={() => this.handleSwal('account')}
                      style={{
                        color: 'white',
                        backgroundColor: 'var(--secondary-cherry)',
                        borderRadius: '100px',
                        fontWeight: '700',
                        marginTop: '1rem',
                        marginBottom: '1rem',
                        minWidth: '164px',
                        minHeight: '50px',
                        boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',
                      }}
                    >
                      Delete My Account
                    </Button>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              style={{
                paddingLeft: window.innerWidth < 1024 ? '1rem' : '2rem',
                paddingRight: window.innerWidth < 1024 ? '1rem' : '0rem',
              }}
            >
              <Paper
                elevation={3}
                style={{ textAlign: 'left', padding: '1rem' }}
              >
                <div className="premium-block-icon">
                  {!premiumCheck.isPremium && (
                    <FaArrowAltCircleUp
                      style={{ width: '0rem', height: '0rem' }}
                    />
                  )}
                  <Typography
                    variant={premiumCheck.isPremium ? 'h5' : 'h5'}
                    color="primary"
                    style={{
                      marginBottom: '1rem',
                      fontWeight: 'bolder',
                      marginTop: '-1rem',
                    }}
                    gutterBottom
                  >
                    {premiumCheck.isPremium
                      ? 'Premium Member'
                      : 'Upgrade your account?'}
                  </Typography>
                </div>
                {!premiumCheck.isPremium && (
                  <p style={{ lineHeight: '1.5rem', textAlign: 'left' }}>
                    It looks like you're using our free package, learn more
                    about premium by clicking the link below
                  </p>
                )}
                {premiumCheck.isPremium && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <S.CrownImg
                      src={crown}
                      style={{
                        width: '5rem',
                        height: '5rem',
                        margin: '0 auto',
                      }}
                    />
                  </div>
                )}

                {!premiumCheck.isPremium && (
                  <div style={{ paddingTop: '1rem', width: '100%' }}>
                    <PaymentPopup
                      isShowing={isShowing}
                      hide={this.toggleSubscripeModal}
                      done={this.done}
                    />
                    <PremiumButton
                      style={{ marginTop: '1rem' }}
                      mt="0.5rem"
                      onClick={() => history.push(GO_PREMIUM)}
                    />
                  </div>
                )}
              </Paper>
            </Grid>
          </Grid>
        </S.Main>
      </>
    );
  }
}

Basics.contextType = UserContext;
