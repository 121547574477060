import React from 'react';

import Icon from '../Icon/Icon';
import { DashedWrapper, CopyText } from './style';

const IconWraper = ({ type, onIconClick }) => {
  switch (type) {
    case 'title':
    case 'description':
      return <></>
    case 'email':
      return <Icon icon="pencil" onClick={onIconClick} />;

    case 'date':
      return <Icon icon="calendar" onClick={onIconClick} />;

    case 'image':
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          style={{ cursor: 'pointer', padding: '1rem' }}
          onClick={onIconClick}
        >
          <Icon icon="camera" />
        </div>
      );

    case 'voice':
      return <Icon icon="microphone" onClick={onIconClick} />;

    case 'copy':
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div style={{ cursor: 'pointer' }} onClick={onIconClick}>
          <Icon icon="copy" />
          <CopyText>Copy</CopyText>
        </div>
      );

    default:
      return null;
  }
};

export default (WrappedComponent, type) => {
  const hocComponent = ({ onIconClick, ...props }) => (
    <DashedWrapper>
      <WrappedComponent {...props} />
      {props.showIcon && <IconWraper type={type} onIconClick={onIconClick} />}
    </DashedWrapper>
  );

  return hocComponent;
};
