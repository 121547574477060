import React, { useEffect, useRef } from 'react';
import { ModalBackground, ModalContent } from './style';

const Modal = ({ hide, children, noBackground }) => {
  const ref = useRef();
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      hide(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalBackground>
      <ModalContent
        className={noBackground ? 'noBackground' : 'withBackground'}
        ref={ref}
      >
        {children}
      </ModalContent>
    </ModalBackground>
  );
};

export default Modal;
