import styled, { keyframes } from 'styled-components';
import { CardTitle, CardWrapper } from '../../Common/Card/style';
import { breakpoints, breakpointsMax } from '../../../constants/breakPoints';

export const JoyrideButtonWrapper = styled.div`
  text-align: center;
  margin: 0rem auto 1rem;
`;

export const CardsWrapper = styled.div`
  .react-datepicker__input-container {
    width: 300px;
  }
  @media ${breakpointsMax.laptop} {
    .columns {
      width: 100% !important;
    }
  }
`;

export const FormWrapper = styled.div`
  .columns {
    width: 70%;
    margin: 0rem auto 2rem auto;
  }
  @media ${breakpointsMax.laptopL} {
    .columns {
      width: 100% !important;
    }
  }

  .RFS-StepperContainer {
    margin-bottom: 2rem;
  }
  @media ${breakpointsMax.laptop} {
    .columns {
      width: 100% !important;
    }
    .RFS-StepMain {
      margin-bottom: 0.5rem;
    }
    .RFS-StepCircle {
      margin-bottom: 0.5rem;
    }
    .RFS-StepperContainer {
      display: block;
      padding: 0px;
    }
    .RFS-Label {
      margin: 0px;
    }
    .RFS-ConnectorContainer {
      display: none;
    }
    .react-datepicker-wrapper {
      width: auto;
    }
    .react-datepicker__input-container {
      width: 100%;
    }
  }
`;

export const CardsWrapperMobile = styled.div`
  @media ${breakpoints.laptop} {
    display: none;
  }
`;

export const SideMenu = styled.div`
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  display: block;
  padding: 2.25rem;
  margin-bottom: 2rem;
  text-align: left;
  width: 100%;
  cursor: pointer;

  .side-menu-item {
    align-items: center;
    :hover {
      color: black;
      .side-menu-icon {
        background: #10c1b8;
        color: black;
      }
    }
    .side-menu-icon {
      width: 50px;
      height: 50px;
      background: #e5e5e5;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1000px;
      :hover {
        color: black;
      }
    }
  }
`;

// Modal styles

export const ModalButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--secondary-aqua);
  font-weight: bold;
  text-decoration: underline;
  align-self: ${props => (props.right ? 'flex-end' : 'auto')};
  margin-bottom: 2rem;
`;

export const ModalBackground = styled.div`
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  margin: auto;
`;

export const ModalWrapper = styled(CardWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalButtonWrapper = styled.div`
  text-align: center;
  .step-text {
    font-size: 1.5rem;
  }
`;

export const PageTitle = styled.h1`
  margin: 0.5rem auto;
`;

export const Title = styled(CardTitle)`
  text-align: center;
  margin: 1rem auto;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 4rem;
`;

export const highlightGlow = keyframes`
	0% {box-shadow: 0px 0px 0px hsla(178, 88%, 36%,0.25);}
	50% {box-shadow: 0px 0px 20px hsla(178, 88%, 36%,0.75);}
	100% {box-shadow: 0px 0px 50px hsla(178, 88%, 36%,0);}
`;

export const GlowingButton = styled.div`
  margin: 1rem auto 1rem !important;
  text-align: center;
  button {
    margin: 0px !important;
    
    text-align: center;
    width: 100% !important;
    font-size: 1.25rem;
    padding: 0.5rem;
    .glowing-button {
      animation: ${highlightGlow} 1.5s linear infinite;
    }
  }
`;
