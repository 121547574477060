// React
import React, { useState, useEffect, useRef } from 'react';
import history from '../../../history';
import Swal from 'sweetalert2';
import { memories } from '../../../api';
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import {
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Fade,
  Collapse,
  Zoom,
  Grid,
  Avatar,
  Grow,
  AppBar,
  Toolbar,
  Modal,
} from '@material-ui/core';

import { FaImages } from 'react-icons/fa';

import HelpIcon from '@material-ui/icons/Help'

import ShowMoreText from "react-show-more-text";
import { formatJSDate } from '../../../helpers';

import {
  Divider
} from '../../../NewComponents/Divider/Divider';

import {
  NewTimeline
} from '../../../NewComponents/Dashboard/Blocks/NewTimeline/NewTimeline'

import { ReactComponent as IternalLogo } from '../../../assets/iternal-new.svg';
import LogoImg from '../../../assets/HOR_A.png';

import {
  Date as DateInput,
  ImageWrapper,
  Title,
  VoiceOrDescription,
  AddEmailWrapper,
  ShareLinkWrapper,
  PrivacyWrapper,
} from '../../Common/Inputs';

import { CREATE_MEMORY_URL, SUCCESS_SAVE_OTHER_URL } from '../../../constants/constantUrls';

import { BackgroundWrapper, Main } from './style';

import { createErrors, validate } from '../../../helpers/validation';
import imageCompression from 'browser-image-compression';
import ReactGA from 'react-ga';


const emailSchema = yup.string().email('Please enter a valid email');


export default (props) => {
  // Steps
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [hometown, setHometown] = useState('');
  const [image, setImage] = useState([]);
  const [tempImage, setTempImage] = useState('');
  const [memoryList, setMemoryList] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [popupOnLand, setPopupOnLand] = useState(false);
  const [cantSubmit, setCantSubmit] = useState(false);
  const [showRulesPopup, setShowRulesPopup] = useState(false);

  const imageInput = useRef();
  const gridRef = useRef();

  const reset = () => {
    setMessage('');
    setTitle('My Mum');
    setImage([]);
    setTempImage('');
  }

  const isDisabled = () => {
    if (name === '') {
      setCantSubmit(true);
    } else if (email === '') {
      setCantSubmit(true);
    } else if (message === '') {
      setCantSubmit(true);
    } else if (title === '') {
      setCantSubmit(true);
    } else {
      emailSchema.validate(email)
      .then(() => {
        setCantSubmit(false);
      })
      .catch(err => setCantSubmit(true));
    }
    
  }

  const createMemory = async (memoryObj) => {
    // type can be saveShare / saveDraft
    const {
      title,
      date,
      dateType,
      dateAge,
      description,
      voice,
      video,
      images,
      voiceOrDescriptionIncluded,
      userId,
      author
    } = memoryObj
    // store new memory
    const { data, error } = await memories.createForCompetition({
      memory: {
        title,
        date: formatJSDate(date),
        dateType,
        dateAge,
        voice,
        video,
        description,
        images,
        voiceOrDescriptionIncluded,
        userId,
        author,
      },
    }); // CREATE NEW API ROUTE FOR EMAIL
  
    if (!error) {
      reset();
      getMemories();
      ReactGA.event({
        category: 'Memory',
        action: 'Created Comp Memory',
      });
      Swal.fire({
        title: "You've successfully entered!",
        text: "I'm sure you've got plenty more where that came from. Create an Iternal Group dedicated to your Mum and collect stories from all your loved ones; what a gift that will make!",
        type: "success"
      });
    }
  };

  const getMemories = async () => {
    const { data } = await axios.get(`/api/memory-user/623b312e52a6e200180e85b3`)
    const filteredMemories = data.filter(item => item.isPublic);
    setMemoryList(filteredMemories);
    setLoading(false);
  }

  const directUploadToGoogle = async e => { 
    // const userId = createForOther ? uploaderId : user.id;
    const { files } = e.target;
    const allFiles = Array.from(files);
    const compressionOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    allFiles.map(async image => {
      const compressedImage = await imageCompression(
        image,
        compressionOptions,
      );
  
      const {
        data: { generatedName } = {},
        error,
      } = await memories.uploadImage({
        image: compressedImage,
        userId: '623b312e52a6e200180e85b3'
      });
  
      const imgUrl = URL.createObjectURL(compressedImage);
      setTempImage(imgUrl);
      setImage([{imagePath: generatedName}]);
    });
  };

  useEffect(() => {
    setTitle('My Mum');
    ReactGA.pageview(window.location.pathname + window.location.search)
    getMemories();
  }, []);

  useEffect(() => {
    isDisabled();
  }, [title, name, message, hometown, email]);
  
  return (
    loading ? (
      <>
      </>
    ) : (
      <>
        <Modal
          open={popupOnLand}
          onClose={() => setPopupOnLand(false)}
        >
          <div style={{ position: 'relative', height: '100%' }}>
            <Paper 
              elevation={3} 
              style={{ 
                padding: '1rem', 
                maxWidth: '600px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxHeight: '90%',
                width: '90%',
                textAlign: 'center'
              }}
            >
              <Typography variant="body2">
                We want to show mums everywhere just how loved and appreciated they are, with your stories! All you have to do to be in with a chance of winning a £/$50 Amazon gift voucher and some Iternal goodies is submit a story about your mum. This can be as simple as what you think makes her so incredible, or your favourite memory of her. 
                <br/><br/>
                Have you got a photo of your mum you love? A story from your childhood that always makes you smile? Or even a valuable lesson she taught you that you've used hroughout your life? We want to hear it!
                <br/><br/>
                It’s completely free to enter! You just need an email and a heartwarming story. Submit it here - anonymously or not - and we’ll send you an email to confirm your entry.
                <br/><br/>
                Remember, every submission is another chance to win! Competition closes 3rd April 2022 (03/04/2022) 
              </Typography>
              {/* <br />
              <Typography variant="body2">It’s completely free to enter, you don’t even need an Iternal account! You just need an email and a terrifying tale. Submit it here to our Spooky Stories timeline - anonymously or not - and we’ll send you an email to confirm your entry.  Need some inspiration, or just a good scare? Check out some of the submitted entires below… </Typography> */}
              <Button variant='contained' color='primary' onClick={() => setPopupOnLand(false)} style={{ marginTop: '1rem' }}>Enter</Button>
            </Paper>
          </div>
        </Modal>
        <Modal
          open={showRulesPopup}
          onClose={() => setShowRulesPopup(false)}
        >
          <div style={{ position: 'relative', height: '100%' }}>
            <Paper 
              elevation={3} 
              style={{ 
                padding: '1rem', 
                maxWidth: '600px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxHeight: '90%',
                width: '90%',
                textAlign: 'center'
              }}
            >
              <Typography variant="h5" gutterBottom>Rules</Typography>
              <ul style={{ textAlign: 'left' }}>
                <li><Typography variant="body2">1. Entrants must be 18 and over</Typography></li>
                <li><Typography variant="body2">2. Entries can be submitted globally</Typography></li>
                <li><Typography variant="body2">3. Vulgar, discriminatory or otherwise inappropriate language will not be tolerated and will result in immediate expulsion from the giveaway</Typography></li>
                <li><Typography variant="body2">4. Entrants must not disclose the personal information of any other party but their own</Typography></li>
                <li><Typography variant="body2">5. Iternal reserves the right to end or extend the closing date of the giveaway</Typography></li>
                <li><Typography variant="body2">6. Iternal reserves the right to withhold the prize in the event of suspected foul play</Typography></li>
                <li><Typography variant="body2">7. Iternal reserves the right to change the prize, in the event of unforeseen circumstances</Typography></li>
              </ul>
              <Button variant='contained' color='primary' onClick={() => setShowRulesPopup(false)} style={{ marginTop: '1rem' }}>Close</Button>
            </Paper>
          </div>
        </Modal>
        <AppBar position="static" color='white'>
          <Toolbar>
            {/* <Button><IternalLogo fill={'blue'} color={'var(--primary-navy)'}/></Button> */}
            {/* <Link to={'https://iternal.life'} target="_blank" rel="noopener noreferrer"> */}
            <Button onClick={() => window.open("https://iternal.life/")}><img src={LogoImg} style={{ width: '100px', maxHeight: '100%' }}/></Button>
            {/* </Link> */}
            {/* <img src={LogoImg} style={{ width: '100px', height: '100px' }} width={'100px'} height={'100px'}/> */}
            <div style={{ margin: '0 0 0 auto' }}>
              {window.innerWidth > 500 && (
                <>
                  <Button onClick={() => window.open("https://iternal.life/faq/")}>FAQs</Button>
                  <Button onClick={() => window.open("https://iternal.life/blog/")}>Blog</Button>
                </>
              )}
              <Link to={'/login'} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <Button variant="contained" color='primary' style={{ margin: '0 0.2rem' }}>Login</Button>
              </Link>
              <Link to={'/sign-up'} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <Button variant="contained" color='primary' style={{ margin: '0 0.2rem' }}>Sign Up</Button>
              </Link>
            </div>
          </Toolbar>
        </AppBar>
        <Main>
          <Typography variant="h2" gutterBottom style={{ color: 'black', textAlign: 'center' }}>Mother's Day Stories</Typography>
          <Typography variant="body1" style={{ color: 'black', maxWidth: '800px', textAlign: 'center', margin: '1rem auto 0 auto', fontWeight: 'bold' }}>This Mother's Day, share the love and tell the world why your mum is so special! Every loving memory, story or thought you share enters you for the chance to win a £/$50 Amazon gift card and some Iternal goodies! Click below to learn more.</Typography>
          <Button onClick={() => setPopupOnLand(true)} variant='text' style={{ color: 'black', fontWeight: 'bold', marginBottom: '1rem' }}><HelpIcon />&nbsp;&nbsp;<span style={{ textDecoration: 'underline' }}>Click to learn more</span></Button>
          {/* <Typography variant="h3" style={{ color: 'white', textAlign: 'center'}} ></Typography> */}
          <Paper elevation={3} style={{ padding: '1rem', maxWidth: '800px', margin: '0 auto' }}>
            <Grid container spacing={0}>
              {/* <Grid xs={12} sm={3} md={3} style={{ textAlign: 'center' }}>
                <Avatar
                  style={{
                    width: '100px',
                    height: '100px',
                    margin: '0 auto'
                  }}
                >CW</Avatar>
                <Typography style={{ textAlign: 'center' }}>User Card</Typography>
              </Grid>
              <Grid xs={12} sm={9} md={9}>
                
                <ShowMoreText
                  lines={2}
                  more="Show more"
                  less="Show less"
                  expanded={false}
                  truncatedEndingComponent={'... '}
                >
                  
                </ShowMoreText>
              </Grid> */}
            
              <Grid item xs={12} style={{ textAlign: 'center', marginTop: '1rem' }}>
                <Button 
                  fullWidth 
                  id="enter"
                  variant="contained" 
                  color="primary" 
                  size='large' 
                  onClick={() => {
                    const creating = isCreating;
                    setIsCreating(!isCreating);
                    if (!creating) {
                      setTimeout(() => {
                        gridRef.current.scrollIntoView({ behavior: "smooth" });
                      }, 250)
                    }

                  }}
                >
                  Enter Competition
                </Button>
                <Button 
                  variant="text" 
                  color="primary" 
                  onClick={() => {
                    setShowRulesPopup(true);

                  }} 
                  style={{ marginTop: '1rem', textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  Rules
                </Button>
              </Grid>
            </Grid>
            <Divider logo />
            <Collapse in={isCreating} ref={gridRef}>
              {/* <Typography>Create Card</Typography> */}
                <div style={{ width: '90%', background: 'rgba(11, 173, 167, 0.08)', borderRadius: '0.5rem', padding: '1rem', margin: '0 auto' }}>
                  <Grid container spacing={1} >
                    <Grid item xs={12} sm={9}>
                      {/* memory */}
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography>Your Story*</Typography>
                          <TextField 
                            id='message'
                            placeholder='Write your story here'
                            variant="outlined"
                            multiline
                            rows={4}
                            fullWidth 
                            value={message}
                            onChange={(text) => setMessage(text.target.value)}
                            style={{ backgroundColor: 'white', borderRadius: '7px' }}
                          />
                        </Grid>
                        {/* Title */}
                        <Grid item xs={12} sm={6}>
                          <Typography>Title Your Story*</Typography>
                          <TextField 
                            id='title'
                            placeholder='Title'
                            variant="outlined"
                            fullWidth 
                            value={title}
                            onChange={(text) => setTitle(text.target.value)}
                            style={{ backgroundColor: 'white', borderRadius: '7px' }}
                          />
                        </Grid>
                        {/* email */}
                        <Grid item xs={12} sm={6}>
                          <Typography>Your Email*</Typography>
                          <TextField 
                            id="email"
                            placeholder='Email'
                            variant="outlined"
                            fullWidth 
                            value={email}
                            onChange={(text) => setEmail(text.target.value)}
                            style={{ backgroundColor: 'white', borderRadius: '7px' }}
                          />
                        </Grid>
                        {/* name */}
                        <Grid item xs={12} sm={6}>
                        <Typography>Your Name*</Typography>
                          <TextField 
                            id="name"
                            placeholder="Name"
                            variant="outlined"
                            fullWidth
                            value={name}
                            onChange={(text) => setName(text.target.value)}
                            style={{ backgroundColor: 'white', borderRadius: '7px' }}
                          />
                        </Grid>
                        {/* hometown */}
                        <Grid item xs={12} sm={6}>
                          <Typography>Your Hometown</Typography>
                          <TextField 
                            id="hometown"
                            placeholder='Hometown'
                            variant="outlined"
                            fullWidth
                            value={hometown}
                            onChange={(text) => setHometown(text.target.value)}
                            style={{ backgroundColor: 'white', borderRadius: '7px' }} 
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>
                      {tempImage.length > 0 && (
                        <img src={tempImage} style={{ maxWidth: '100px', maxHeight: '100px', marginTop: '1.6rem', textAlign: 'center' }} />
                      )}
                     
                      <div 
                        style={{ width: '100%', borderRadius: '0.5rem', textAlign: 'center', backgroundColor: 'var(--secondary-aqua)', maxHeight: '200px', minHeight: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', alignContent: 'center', cursor: 'pointer', marginTop: '1.6rem' }}
                        onClick={() => imageInput.current.click()}
                      >
                        <FaImages style={{ color: 'white', width: '70%', height: '70%', maxHeight: '3rem' }} />
                        <Typography style={{ color: 'white', fontWeight: 'bold' }}>{tempImage.length > 0 ? 'Change Photo' : 'Add Photo'}</Typography>
                      </div>
                    </Grid>
                    <input
                      ref={imageInput}
                      accept="image/*"
                      type="file"
                      onChange={directUploadToGoogle}
                      style={{ display: 'none' }}
                      multiple={false}
                    />
                    <Typography style={{ fontStyle: 'italic', textAlign: 'center', margin: '0 auto' }}>We only require your email address so that we can contact you with competition news.</Typography>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                      <Button 
                        id='submit'
                        variant='contained' 
                        color='primary'
                        onClick={() => {
                          createMemory({
                            title,
                            date: new Date(),
                            dateType: 'full',
                            dateAge: 0,
                            description: message,
                            voice: null,
                            video: null,
                            images: image,
                            voiceOrDescriptionIncluded: true,
                            userId: '623b312e52a6e200180e85b3', // CHANGE THIS TO ACTUAL TIMELINE
                            author: { name, email, hometown }
                          })
                        }}
                        disabled={cantSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                  
                </div>
              <Divider logo />
            </Collapse>
            <Typography variant='h4' gutterBottom style={{ textAlign: 'center' }}>{memoryList.length} Stories</Typography>
            <NewTimeline 
              memories={memoryList} 
              milestones={[]} 
              getMilestonesFunc={() => {}}
              prompts={[]} 
              highlightedElement={1} 
              viewingFriendsMemory={true}
              profile={{picture: '', name: 'Chris'}}
              editMilestoneFunc={() => {}}
              noLogin={true}
              isCompetition={true}
              id='timelineWrapper' 
              style={{
                height: '68vh', 
                overflow: 'auto', 
                position: 'initial', 
                background: 'hsla(178, 88%, 36%, 0.08)',
                borderRadius: '1rem 1rem 0 0'
              }}
            />
          </Paper>
        </Main>
      </>
    )
  );
};
