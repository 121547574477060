// React
import React, { useState, useEffect } from 'react';

// Confetti
import Confetti from 'react-confetti';

// Styled Components
import { Main } from './style';

// API
import { bonus } from '../../../api';
// import { API_BONUS_DETAILS_URL } from '../../../constants/apiRoutes';

// Custom Component
import { ClaimBonus as ClaimBonusCard } from '../../../NewComponents/Pages/ClaimBonus/ClaimBonus';

import ReactGA from 'react-ga';

export default ({ match }) => {
  // Steps
  const [claimStep, setClaimStep] = useState(1);

  // Bonus details
  const [userId, setUserId] = useState(undefined);
  const [bonusId, setBonusId] = useState(undefined);
  const [bonusTitle, setBonusTitle] = useState(undefined);
  const [bonusQuantity, setBonusQuantity] = useState(undefined);
  const [bonusType, setBonusType] = useState(undefined);
  const [isMultipleRedemption, setIsMultipleRedemption] = useState(undefined);

  // Already claimed or doesn't exist
  const [notFound, setNotFound] = useState(false);
  const [alreadyClaimed, setAlreadyClaimed] = useState(false);

  const [startConfetti, setStartConfetti] = useState(false);
  useEffect(() => {
    // Set userId and bonusId from params safely
    setUserId(match?.params?.userId);
    setBonusId(match?.params?.bonusId);
    ReactGA.pageview(window.location.pathname + window.location.search)
    // Define function for checking bonus details
    const getBonusData = async () => {
      if (bonusId && userId) {
        const { data } = await bonus.getBonusDetails({
          bonusId,
        });

        if (!data) {
          setNotFound(true);
          return;
        }

        if (data) {
          const { title, quantity, type, multipleRedemptions } = data;
          setBonusTitle(title);
          setBonusQuantity(quantity);
          setBonusType(type);
          setIsMultipleRedemption(multipleRedemptions);
        }

        // TODO: Check if bonus has already been redeemed (for single redemption only)
        if (!isMultipleRedemption) {
          // do stuff
          setAlreadyClaimed(false)
        }
      }
    };

    // Call function,
    getBonusData();
  }, [
    userId,
    bonusId,
    isMultipleRedemption,
    match,
    match.params.bonusId,
    match.params.userId,
  ]);

  const claimBonusFunc = async () => {
    const { data } = await bonus.claimBonus({ bonusId, userId });
    if (data) {
      setStartConfetti(true);
      setClaimStep(2);
    }
  };

  const cardProps = {
    userId,
    bonusId,
    claimStep,
    bonusTitle,
    bonusQuantity,
    bonusType,
    claimBonusFunc,
    errors: { notFound, alreadyClaimed },
  };

  return (
    <Main>
      <Confetti
        run={startConfetti}
        width={window.innerWidth}
        heigh={window.innerHeight}
        recycle={false}
        numberOfPieces={500}
      />
      <ClaimBonusCard {...cardProps} />
    </Main>
  );
};
