// React
import React from "react";
import PropTypes from "prop-types";

// MUI
import { Typography, Button, Chip, Grid, Tooltip } from "@material-ui/core";

// MUI Reaction Icons
import HappyIcon from "@material-ui/icons/SentimentVerySatisfied";
import HeartwarmingIcon from "@material-ui/icons/Favorite";
import VirtualHugIcon from "@material-ui/icons/AccessibilityNew";
import SupportiveIcon from "@material-ui/icons/ThumbUp";
import { ReactComponent as NostalgicIcon } from "./nostalgic_icon.svg";
import { ReactComponent as MovingIcon } from "./moving_icon_2.svg";

// Styles
import { ReactionsSectionWrapper } from "./ReactionsSection.styled";

// const NostalgicIcon = () => (
//   <img src={NostalgicIconSrc} alt="" className="nostalgicIcon" />
// );

// Define Reaction Buttons
const ReactionButtons = [
  { icon: <HappyIcon />, text: "Happy" },
  {
    icon: <NostalgicIcon className="nostalgicIcon" />,
    text: "Nostalgic",
  },
  { icon: <HeartwarmingIcon />, text: "Heartwarming" },
  { icon: <VirtualHugIcon />, text: "Virtual Hug" },
  { icon: <SupportiveIcon />, text: "Supportive" },
  { icon: <MovingIcon className="movingIcon" />, text: "Moving" },
];

export const ReactionsSection = ({
  memoryReactions,
  userId,
  memoryId,
  reactionButtonFunction,
  getReactions,
  viewingFriendsMemory,
  showPopupFunc,
  ...props
}) => {
  const renderReactionsSummaryText = () => {
    const you = memoryReactions.some((el) => el.userId === userId)
      ? "You "
      : "";

    const and =
      you !== "" &&
      memoryReactions.filter((el) => el.userId !== userId).length > 0
        ? "and"
        : "";

    const otherPeople =
      memoryReactions.filter((el) => el.userId !== userId).length > 0
        ? `${memoryReactions.filter((el) => el.userId !== userId).length} ${
            memoryReactions.filter((el) => el.userId !== userId).length === 1
              ? "other person"
              : "other people"
          }`
        : "";

    const reactedToThis =
      memoryReactions.length > 0 ? " reacted to this memory" : "";

    if (memoryReactions.length > 0) {
      return `${you} ${and} ${otherPeople} ${reactedToThis}`;
    }
    if (!viewingFriendsMemory) {
      return "This memory doesn't have any reactions yet";
    }
    return "Be the first to react to this memory!";
  };

  const userReactedToThisPost = (button) =>
    memoryReactions.some(
      (el) => el.userId === userId && el.reactionType === button.text
    );

  const getNumberOfReactionsPerButton = (button) =>
    memoryReactions.filter((el) => el.reactionType === button.text).length;

  const handleButtonClick = async (button) => {
    if (userId === "") {
      return;
    }
    await reactionButtonFunction(button.text, memoryId);
    await getReactions();
  };

  const generateTooltip = (button) => {
    const tooltips = memoryReactions.filter(
      (el) => el.reactionType === button.text
    );
    if (tooltips.length === 0) {
      return (
        <React.Fragment>
          <Typography color="inherit">
            No {button.text} Reactions Yet
          </Typography>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {tooltips.map((tooltip) => (
            <Typography color="inherit">{tooltip.user[0].name}</Typography>
          ))}
        </React.Fragment>
      );
    }
  };

  return (
    <ReactionsSectionWrapper {...props}>
      <Typography variant="h5" color="primary" className="title">
        Reactions{userId && userId.toString().length ? "  " : ""}
       
      </Typography>

      <Typography variant="body1" className="subtitle" gutterBottom>
        {renderReactionsSummaryText()}
      </Typography>
      <Grid container>
        {ReactionButtons.map((button) => (
          <Grid xs={12} md={12}>
            <Tooltip title={generateTooltip(button)}>
              <Button
                variant={
                  userReactedToThisPost(button) ? "contained" : "outlined"
                }
                color="primary"
                onClick={() => handleButtonClick(button)}
              >
                {button.icon}&nbsp;
                {button.text}
                &nbsp;
                <Chip
                  className={
                    getNumberOfReactionsPerButton(button) > 0
                      ? "counterVisible"
                      : "counterHidden"
                  }
                  color="primary"
                  label={getNumberOfReactionsPerButton(button)}
                />
              </Button>
            </Tooltip>
            
          </Grid>
        ))}
      </Grid>
      {userId && userId.toString().length > 0 && (
          <Typography
            onClick={() => showPopupFunc(true)}
            style={{
              fontSize: "1.1rem",
              cursor: "pointer",
              textDecoration: "underline",
              color: "var(--secondary-aqua)",
              textAlign:'center'
            }}
          >
            <br></br>
            Click to see who reacted
          </Typography>
        )}
    </ReactionsSectionWrapper>
  );
};

ReactionsSection.propTypes = {
  /**
   * Pass on an array of reactions, containing `_id`, `userId`, `memoryId`, and `reactionType`
   */
  memoryReactions: PropTypes.arrayOf({
    _id: PropTypes.string,
    userId: PropTypes.string,
    memoryId: PropTypes.string,
    reactionType: PropTypes.string,
  }),
  /**
   * Pass on the userId of the current logged in user
   */
  userId: PropTypes.string,
  /**
   * Pass on the memoryId of the current memory being viewed
   */
  memoryId: PropTypes.string,
  /**
   * Pass on a function to be used for the onClick of each button
   */
  reactionButtonFunction: PropTypes.func,
  /**
   * Pass on a function to be used for fetching the list of reactions for the current memory
   */
  getReactions: PropTypes.func,
  /**
   * Specify `false` to disable onClick button functions
   */
  viewingFriendsMemory: PropTypes.bool,
};

ReactionsSection.defaultProps = {
  memoryReactions: [],
  userId: "",
  memoryId: "",
  reactionButtonFunction: () => {},
  getReactions: () => {},
  viewingFriendsMemory: false,
};
