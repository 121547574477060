import React from 'react';

// MUI
import { Paper, Typography } from '@material-ui/core';

// Constants
import {
  FRIENDS_AND_FAMILY_URL,
  SIGNUP_URL,
  MY_FRIENDS_MEMORIES
} from '../../../constants/constantUrls';

//
import * as S from './style';

const Content = ({ nameMemoryOwner = 'N/A', contentType }) => {
  switch (contentType) {
    case 'signup':
      return (
        <Paper style={{ padding: '2rem',   }}>
          <Typography variant="body1">
            To view these memories we first need you to sign up. This is because{' '}
            {nameMemoryOwner.split(' ')[0]}’s memories are very precious and we
            can’t just share them with anyone.
          </Typography>
          <Typography variant="body1">
            By signing in we can make sure you have permission from{' '}
            {nameMemoryOwner} to see their memories.
          </Typography>
        </Paper>
      );

    case 'login':
      return (
        <Paper style={{ padding: '2rem' }}>
          <Typography variant="body1">
            To view these memories we first need you to login in. This is
            because {nameMemoryOwner.split(' ')[0]}’s memories are very precious
            and we can’t just share them with anyone.
          </Typography>
          <Typography variant="body1">
            By logging in we can make sure you have permission from{' '}
            {nameMemoryOwner} to see their memories.
          </Typography>
        </Paper>
      );

    case 'share':
      return (
        <Paper style={{ padding: '2rem',  marginRight:'1rem', marginLeft:'1rem', textAlign:'center', display: window.innerWidth <  700 ? "none" : "block" }}>
          <Typography variant="body1">
            Welcome to the memories of {nameMemoryOwner}.{' '}
            {nameMemoryOwner.split(' ')[0]} has chosen to share these with you.
            Want to do the same?
          </Typography>
          <S.PinkLink color="secondary" to={MY_FRIENDS_MEMORIES}>
          <br></br>
            Invite Your Friends To Iternal
          </S.PinkLink>
        </Paper>
      );

    case 'sharePublic':
      return (
        <Paper style={{ padding: '2rem' }}>
          <Typography variant="body1">
            You&apos;re seeing the memories that {nameMemoryOwner} has chosen to
            make public. To see their private memories, sign up to Iternal and
            ask them to add you
          </Typography>
          <br />
          <S.PinkLink to={SIGNUP_URL}>Sign up to Iternal now</S.PinkLink>
        </Paper>
      );

    default:
      return null;
  }
};

const HeaderShareMemory = ({ nameMemoryOwner = 'N/A', contentType }) => (
  <>
    <S.HeaderWrapper>
    <Typography
        align="center"
        variant="h4"
        gutterBottom
        style={{
          marginBottom: window.innerWidth < 700 ? "1rem" : "2rem",
          color: "white",
          fontSize: window.innerWidth < 700 ? "2rem" : "3rem",
        }}
      >
  
        {nameMemoryOwner && `${nameMemoryOwner}'s`} Memories
      </Typography>
      <Content contentType={contentType} nameMemoryOwner={nameMemoryOwner} />
    </S.HeaderWrapper>
  </>
);

export default HeaderShareMemory;
