import React from 'react';

const ShareNetwork = ({ width, height, color, theme }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7517 12.3661C13.3813 12.3659 13.0148 12.4409 12.6742 12.5863C12.3336 12.7318 12.026 12.9449 11.77 13.2125L5.41062 9.63453C5.53381 9.17194 5.53381 8.68517 5.41062 8.22258L11.77 4.64457C12.2331 5.12468 12.8553 5.41947 13.5202 5.47373C14.185 5.52799 14.8468 5.33798 15.3816 4.93931C15.9164 4.54063 16.2875 3.96065 16.4254 3.30801C16.5632 2.65537 16.4584 1.97485 16.1305 1.39395C15.8027 0.81304 15.2742 0.371605 14.6443 0.15234C14.0143 -0.0669258 13.326 -0.048977 12.7083 0.202823C12.0906 0.454624 11.5859 0.922998 11.2888 1.5202C10.9916 2.11741 10.9224 2.80246 11.0941 3.44703L4.73472 7.02504C4.35486 6.62897 3.86518 6.35561 3.32867 6.24013C2.79217 6.12465 2.23339 6.17233 1.72421 6.37704C1.21502 6.58174 0.778736 6.93411 0.471466 7.38882C0.164196 7.84352 0 8.37976 0 8.92855C0 9.47734 0.164196 10.0136 0.471466 10.4683C0.778736 10.923 1.21502 11.2754 1.72421 11.4801C2.23339 11.6848 2.79217 11.7325 3.32867 11.617C3.86518 11.5015 4.35486 11.2281 4.73472 10.8321L11.0941 14.4101C10.9467 14.9652 10.9767 15.5525 11.1801 16.0897C11.3834 16.6268 11.7498 17.0868 12.2279 17.4051C12.706 17.7234 13.2717 17.884 13.8457 17.8645C14.4197 17.8449 14.9732 17.646 15.4285 17.2959C15.8838 16.9457 16.218 16.4618 16.3842 15.9121C16.5505 15.3623 16.5404 14.7743 16.3555 14.2305C16.1705 13.6868 15.82 13.2146 15.353 12.8802C14.886 12.5458 14.3261 12.366 13.7517 12.3661Z"
      fill={color ? color : 'black'}
    />
  </svg>
);

export default ShareNetwork;
