// React
import React, { useState, useEffect, useRef, useCallback } from "react";
import { NavLink } from "react-router-dom";
import * as yup from "yup";
import { nanoid } from "nanoid";

// bottom nav
import Groups from "@material-ui/icons/People";
import Friends from "@material-ui/icons/EmojiPeople";
import Profile from "@material-ui/icons/Face";
import Stories from "@material-ui/icons/MenuBook";
import Camera from "@material-ui/icons/PhotoCamera";

import * as C from "../../../../constants/constantUrls";
import history from "../../../../history";

// Components
import Swal from "sweetalert2";
import imageCompression from "browser-image-compression";
import InviteMemberPopup from "../../../Common/GroupComponents/InviteMemberPopup";

import {
  Paper,
  Typography,
  Button,
  TextField,
  Modal,
  Grid,
  Avatar,
  AppBar,
  Tab,
  Tabs,
  ListItem,
  List,
  ListItemText,
  ListItemSecondaryAction,
  Select,
  createMuiTheme,
  ThemeProvider,
  FormControl,
  InputLabel,
  MenuItem,
  Divider,
} from "@material-ui/core";
import Lightbox from "react-image-lightbox";
import {
  FaImages,
  FaMicrophone,
  FaPencilAlt,
  FaTimesCircle,
  FaCamera,
  FaEdit,
  FaAngleDown,
  FaPlus,
  FaShare,
  FaLightbulb,
} from "react-icons/fa";
import DateFnsUtils from "@date-io/date-fns";
import HeaderShareMemory from "../../../Common/HeaderShareMemory";
import imagePlaceHolder from "../../../../assets/person-placeholder.jpg";
import MemoriesTable from "../../../Common/Tables/MemoriesTable";
import { GroupTimeline } from "../../../../NewComponents/Dashboard/Blocks/NewTimeline/GroupTimeline";

// Constants
import { SIGNUP_URL } from "../../../../constants/constantUrls";
import { linkTokens, memories } from "../../../../api";
// import { Divider } from '../../../../NewComponents/Divider/Divider';

import { formatJSDate } from "../../../../helpers";

import CreateMemoryPopup from "../../../Common/GroupComponents/CreateMemoryPopup";
import EditGroupMemoryPopup from "../EditGroupMemory";

import Voice from "../../../Common/Inputs/Voice";
import Icon from "../../../Common/Icon/Icon";

import { createErrors, validate } from "../../../../helpers/validation";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

import {
  DatePicker as MaDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
// Styles
import { Main, MemoryButtonWrapper } from "./style";
import ReactGA from "react-ga";
import axios from "axios";
// import { BottomDiv } from '../DialogflowChat/style';
import Navbar from "../../../Common/GroupComponents/Navbar";
import TimelineSwitch from "../../../Common/GroupComponents/Buttons/TimelineSwitch";
import CreateMemoryButton from "../../../Common/GroupComponents/Buttons/CreateMemoryButtons";

import { IternalTheme } from "../../../../NewComponents/--Theme/IternalThemeLight";

import { orange } from "@material-ui/core/colors";

import {
  API_SIGNUP_URL,
  API_UPDATE_USERINFO_URL,
} from "../../../../constants/apiRoutes";
import CallToActionButton from "../../../Common/GroupComponents/Buttons/CallToActionButton";

const dateSchema = yup
  .date()
  .typeError("Memory date is required")
  .required("Memory date is required");

const GroupPage = (props) => {
  const { shortId } = props.match.params;
  const [groupData, setGroupData] = useState({});
  const [groupMembers, setGroupMembers] = useState([]);
  const [groupMemories, setGroupMemories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState([]);
  const [tempImage, setTempImage] = useState("");
  const [audio, setAudio] = useState("");
  const [tempAudio, setTempAudio] = useState(undefined);
  const [isRecording, setIsRecording] = useState(false);
  const [cantSubmit, setCantSubmit] = useState(false);
  const [date, setDate] = useState(new Date());
  const [recordAudio, setRecordAudio] = useState(false);
  const [recordText, setRecordText] = useState(false);
  const [userId, setUserId] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [hasJoinedGroup, setHasJoinedGroup] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [groupMemberStatus, setGroupMemberStatus] = useState("");
  const [showMembers, setShowMembers] = useState(false);
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [isLoadingJoin, setIsLoadingJoin] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [showCreateMemoryPopup, setShowCreateMemoryPopup] = useState(false);
  const [showEditMemoryPopup, setShowEditMemoryPopup] = useState(false);
  const [editingMemory, setEditingMemory] = useState({});
  const [storyLocation, setStoryLocation] = useState(false);
  const [theme, setTheme] = useState(createMuiTheme(IternalTheme));
  const [memoryType, setMemoryType] = useState("");
  const [userInvitationStatus, setUserInvitationStatus] = useState("");
  const [filterName, setFilterName] = useState("");
  const [pubNoAcc, setPubNoAcc] = useState(false);
  const [showInviteMemberPopup, setShowInviteMemberPopup] = useState(false);

  const imageInput = useRef();
  const timelineRef = useRef();
  const gridRef = useRef();

  useEffect(() => {
    if (props?.id && props?.isLoggedIn) {
      setUserId(props.id);
      setName(props.name);
      setEmail(props.email);
      setIsLoggedIn(true);
    }
    getGroup();
  }, [getGroup]);

  const getGroup = async () => {
    const { data } = await axios.get(`/api/group/${shortId}`);
    if (data) {
      if (data[0]?.groupMemories?.length > 0) {
        const recentMemory = data[0]?.groupMemories.sort(function(a, b) {
          return new Date(b.date) - new Date(a.date);
        });
        setGroupMemories(recentMemory);
      }
      setGroupMembers(data[0]?.groupMembers);
      setGroupData(data[0]);
      if (props?.id && props?.isLoggedIn) {
        if (
          data[0].groupMembers.find(
            (x) => x.userId.toString() === props.id.toString()
          )
        ) {
          setHasJoinedGroup(true);
          setGroupMemberStatus(
            data[0].groupMembers.find(
              (x) => x.userId.toString() === props.id.toString()
            )?.role
          );
          setUserInvitationStatus(
            data[0].groupMembers.find(
              (x) => x.userId.toString() === props.id.toString()
            )?.invitationStatus
          );
        }
      }
      if (data[0]?.groupColor) {
        const { main, light, dark, contrastText } = data[0]?.groupColor;
        theme.palette.primary.main = main;
        theme.palette.primary.light = light;
        theme.palette.primary.dark = dark;
        theme.palette.primary.contrastText = contrastText;
      }
    }
    setLoading(false);
  };
  /* 
    Functions needed:
  * */

  const directUploadToGoogle = async (e) => {
    // const userId = createForOther ? uploaderId : user.id;
    const { files } = e.target;
    const allFiles = Array.from(files);
    const compressionOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    allFiles.map(async (image) => {
      const compressedImage = await imageCompression(image, compressionOptions);

      const {
        data: { generatedName } = {},
        error,
      } = await memories.uploadImage({
        image: compressedImage,
        userId: groupData._id,
      });

      const imgUrl = URL.createObjectURL(compressedImage);
      setTempImage(imgUrl);
      setImage([{ imagePath: generatedName }]);
    });
  };

  const isDisabled = () => {
    if (message === "") {
      setCantSubmit(true);
    } else if (title === "") {
      setCantSubmit(true);
    }
  };

  const updateMemory = async ({ memory, memoryId }) => {
    try {
      const { title, description, date, memoryType } = memory;

      const validated = await validate("editGroupMemory", {
        title,
        date,
      });
      if (validated) {
        const { data } = await axios.patch("/api/group-memory", {
          memory: {
            title,
            memory: description,
            date,
            memoryType: memoryType ? 1 : 0,
          },
          memoryId,
        });
        if (data) {
          Swal.fire({
            type: "success",
            title: `You've update the memory: ${
              title.length > 0 ? title : ""
            }!`,
          });
          resetInputs();
          await getGroup();
          setShowEditMemoryPopup(false);
          setEditingMemory({});
        }
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        if (error.errors.length > 0) {
          Swal.fire({
            fontWeight: "bold",
            type: "warning",
            title: error.errors[0],
          });
        }
      }
    }
  };

  const deleteMemory = async (memoryId) => {
    try {
      Swal.fire({
        title: `This will permanently delete this memory!`,
        text: "Are you sure you want to do this?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: theme.palette.primary.main,
        cancelButtonColor: "#8a8787",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          const { data } = await axios.delete(`/api/group-memory/${memoryId}`);
          if (data) {
            resetInputs();
            await getGroup();
            setShowEditMemoryPopup(false);
            setEditingMemory({});
          }
        }
      });
    } catch (error) {}
  };

  const createMemory = async () => {
    // store new memory

    let hasMadeMemory = false;
    try {
      if (tempAudio || message.length > 0 || image.length > 0) {
        hasMadeMemory = true;
      }

      const memoryData = {
        title,
        date,
        name,
        email,
        hasMadeMemory,
      };
      const validated = await validate("groupMemory", memoryData);
      if (validated) {
        const chooseTimeline = () => {
          if (groupMemberStatus === "admin" || groupMemberStatus === "owner") {
            return storyLocation ? 1 : 0;
          } else {
            return 1;
          }
        };
        if (tempAudio && typeof tempAudio !== "string") {
          const {
            data: { generatedName },
          } = await memories.uploadAudio({
            voice: tempAudio,
            userId,
          });
          const { data } = await axios.post("/api/group-memory", {
            title,
            date: formatJSDate(date),
            voiceRecording: generatedName,
            videoRecording: undefined,
            memory: message,
            images: image,
            isPublic: true,
            privacySetting: "Public",
            authorId: userId,
            groupId: groupData._id,
            memoryType: chooseTimeline(),
            author:
              userId.length > 0
                ? {
                    name,
                    email,
                    userId,
                  }
                : {
                    name,
                    email,
                  },
          });
          if (data) {
            setShowCreateMemoryPopup(false);
            if (!isLoggedIn) {
              setPubNoAcc(true);
              setShowSignUp(true);
            } else {
              Swal.fire({
                type: "success",
                confirmButtonColor: "var(--secondary-blue)",
                cancelButtonColor: "#8a8787",

                title: `Congrats! You just posted a memory${
                  groupData?.groupName?.length > 0
                    ? ` to ${groupData?.groupName}`
                    : ""
                }!`,
              });
            }
            resetInputs();
            await getGroup();
            setMemoryType("");
          }
        } else {
          const { data } = await axios.post("/api/group-memory", {
            title,
            date: formatJSDate(date),
            voiceRecording: audio,
            videoRecording: undefined,
            memory: message,
            images: image,
            isPublic: true,
            privacySetting: "Public",
            authorId: userId,
            groupId: groupData._id,
            memoryType: chooseTimeline(),
            author:
              userId.length > 0
                ? {
                    name,
                    email,
                    userId,
                  }
                : {
                    name,
                    email,
                  },
          });
          if (data) {
            setShowCreateMemoryPopup(false);
            if (!isLoggedIn) {
              setPubNoAcc(true);
              setShowSignUp(true);
            } else {
              Swal.fire({
                type: "success",
                confirmButtonColor: "var(--secondary-blue)",
                cancelButtonColor: "#8a8787",

                title: `Congrats! You just posted a memory${
                  groupData?.groupName?.length > 0
                    ? ` to ${groupData?.groupName}`
                    : ""
                }!`,
              });
            }
            resetInputs();
            await getGroup();
            setMemoryType("");
          }
        }
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        if (error.errors.length > 0) {
          Swal.fire({
            fontWeight: "bold",
            confirmButtonColor: "var(--secondary-blue)",
            cancelButtonColor: "var(--secondary-cherry)",
            type: "warning",
            title: error.errors[0],
          });
        }
      }
    }
  };

  const resetInputs = () => {
    setMessage("");
    setTitle("");
    setImage([]);
    setTempImage("");
    setAudio("");
    setTempAudio(undefined);
    setDate(new Date());
    setRecordAudio(false);
    setRecordText(false);
    setMemoryType("");
  };

  const RenderGroupListOrNothing = () => <Grid container spacing={2} />;

  const openEditMemory = (memory) => {
    setEditingMemory(memory);
    setShowEditMemoryPopup(true);
  };

  const generateGroupInitials = (userStr) =>
    userStr
      .split(" ")
      .reduce((acc, cur) => acc + cur[0], "")
      .toUpperCase()
      .substring(0, 3);

  const onDateChange = (calDate) => {
    setDate(calDate);
  };

  const onDobChange = (calDate) => {
    setDateOfBirth(calDate);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  const onClose = () => {
    setShowCreateMemoryPopup(false);
    setMemoryType("");
  };

  const TabPanel = () => {
    // const { children, value, index, name, pos, ...other } = props;

    // const filteredMemories = groupMemories.filter(
    //   memory => memory?.memoryType === tabValue,
    // );
    // console.log('or here')
    return (
      <div
        role="tabpanel"
        style={{
          background: "white",
        }}
      >
        <Grid container spacing={1}>
          <Grid item sm={12} md={8}>
            {groupMemories.length > 0 ? (
              <GroupTimeline
                memories={groupMemories}
                customTheme={theme}
                milestones={[]}
                getMilestonesFunc={() => {}}
                prompts={[]}
                viewingFriendsMemory={false}
                profile={{ picture: "", name: "Chris" }}
                editMilestoneFunc={() => {}}
                id="timelineWrapper"
                groupMemberStatus={groupMemberStatus}
                openEditMemoryFunc={openEditMemory}
                userId={userId}
                style={{
                  height: "68vh",
                  overflow: "auto",
                  position: "initial",
                  borderRadius: "1rem 1rem 0 0",
                }}
              />
            ) : (
              <GroupTimeline
                memories={[
                  {
                    approved: true,
                    author: {
                      email: "hello@iternal.life",
                      name: "Iternal",
                    },
                    authorImage: {
                      imagePath: require("../../../../assets/orange_logo.png"),
                    },
                    createdAt: new Date(),
                    date: new Date(),
                    groupId: groupData?._id,
                    images: [],
                    isPublic: true,
                    memory:
                      tabValue === 0
                        ? "When you or any other member contributes any historical or factual information about your group, you'll be able to find it all here in chronological order."
                        : "When you or any other member contributes a story, memory or experience to the group, you'll be able to find them here in chronological order.",
                    privacySetting: "Public",
                    tempImages: [],
                    title:
                      tabValue === 0
                        ? "This is where you'll find your group's history"
                        : "This is where you'll find the memories and stories of all your members",
                    voiceRecording: "",
                  },
                ]}
                customTheme={theme}
                milestones={[]}
                getMilestonesFunc={() => {}}
                prompts={[]}
                viewingFriendsMemory={false}
                profile={{ picture: "", name: "Chris" }}
                editMilestoneFunc={() => {}}
                id="timelineWrapper"
                // groupMemberStatus={groupMemberStatus}
                openEditMemoryFunc={() => {}}
                style={{
                  height: "68vh",
                  overflow: "auto",
                  position: "initial",
                  borderRadius: "1rem 1rem 0 0",
                }}
              />
            )}
          </Grid>
          <Grid item sm={12} md={4}>
            <Paper elevation={1}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  Sort Your Stories
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={filterName}
                  onChange={handleFilter}
                  label="Sort Your Stories"
                >
                  <MenuItem value={"date-newest"}>
                    Date Order - New to Old{" "}
                  </MenuItem>
                  <MenuItem value={"date-oldest"}>
                    Date Order - Old to New{" "}
                  </MenuItem>
                  <MenuItem value={"createdAt-newest"}>
                    Most Recently Added{" "}
                  </MenuItem>
                  <MenuItem value={"createdAt-oldest"}>
                    First Stories Created{" "}
                  </MenuItem>
                </Select>
              </FormControl>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  };

  const handleFilter = async (event) => {
    setFilterName(event.target.value);
    switch (event.target.value) {
      case "date-newest":
        await setGroupMemories(
          await groupMemories.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          )
        );
        break;
      case "date-oldest":
        await setGroupMemories(
          await groupMemories.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
        );
        break;
      case "createdAt-newest":
        const createdAtNewest = groupMemories
          .slice()
          .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
        setGroupMemories(createdAtNewest);
        break;
      case "createdAt-oldest":
        const createdAtOldest = groupMemories
          .slice()
          .sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
        setGroupMemories(createdAtOldest);
        break;
      default:
        break;
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [requestedToJoin, setRequestedToJoin] = useState(
    "You have already requested to join this group. You will receive an email if your request is accepted by a group admin."
  );

  const joinGroup = async () => {
    // check if user is logged in, if they are then run post, otherwise bring up sign up popup
    // if they've already created a memory, autofill email and name, will just require password and D.O.B
    setIsLoadingJoin(true);

    const { data } = await axios.post("/api/group-member", {
      groupId: groupData._id,
      groupShortCode: shortId,
    });
    if (data) {
      await getGroup();
    }
    setIsLoadingJoin(false);
  };

  const editMember = async (event, member) => {
    const { data } = await axios.patch("/api/group-member", {
      _id: member._id,
      role: event.target.value,
    });
    if (data) {
      await getGroup();
    }
  };

  const removeMember = async () => {};

  const generateMemberListItem = (member, pos) => (
    <div key={pos}>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <Typography>{member.userEmail}</Typography>
        </Grid>
        <Grid item xs={4}>
          {/* {groupMemberStatus === 'member' || member?.userId === userId ? ( */}
          <Typography style={{ textTransform: "capitalize" }}>
            {member.role}
          </Typography>
          {/* ) : (
              <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                  <Select 
                    native
                    fullWidth
                    variant="outlined"
                    value={member.role} 
                    onChange={e => editMember(e, member)}
                    style={{ height: '100%',
                     }}
                  >
                    <option value={'member'}>Member</option>
                    <option value={'admin'}>Admin</option>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button variant="contained" style={{ backgroundColor: 'var(--secondary-cherry)', color: 'white', width: '70%', }}>
                    Remove
                  </Button> 
             
                  
                </Grid>
              </Grid>
            )} */}
        </Grid>
      </Grid>
    </div>
  );

  const handleSignUp = async () => {
    const { history, handleChangeState } = props;
    setIsSigningUp(true);
    try {
      const signUpData = {
        email,
        password,
        dateOfBirth,
        name,
      };
      const validated = await validate("groupSignUp", signUpData);
      if (validated) {
        const userInfo = {
          email,
          name,
          password,
          referralCode: groupData.groupName,
          mailingList: true,
        };
        const signedUp = await axios.post(API_SIGNUP_URL, { userInfo });
        if (signedUp) {
          const { data } = signedUp;

          const hasProfile =
            props.profile && Object.keys(props.profile).length > 0;
          const updateProfile = await axios.post(API_UPDATE_USERINFO_URL, {
            state: { name, birthday: dateOfBirth, hometown: "" },
            hasNoProfile: !hasProfile,
          });
          if (name) {
            props.handleChangeState({ name });
          }
          ReactGA.event({
            category: "Sign Up",
            action: "Signed Up By Group",
          });
          handleChangeState({ ...data, isLoggedIn: true });
          await joinGroup();
          setIsSigningUp(false);
          setShowSignUp(false);
          window.location.reload(false);
        }
      } else {
        setIsSigningUp(false);
      }
    } catch (error) {
      setIsSigningUp(false);
      if (error.name === "ValidationError") {
        if (error.errors.length > 0) {
          Swal.fire({
            type: "warning",
            confirmButtonColor: "var(--secondary-blue)",
            cancelButtonColor: "var(--secondary-cherry)",
            title: error.errors[0],
          });
        }
      }
      if (
        error.response &&
        error.response.data.error.includes("Email already taken")
      ) {
        Swal.fire({
          type: "warning",
          confirmButtonColor: "var(--secondary-blue)",
          cancelButtonColor: "var(--secondary-cherry)",
          title: error.response.data.error,
        });
      }
    }
  };

  const getBackgroundStyle = () => {
    if (groupData?.background?.imagePath) {
      if (
        theme.palette.primary.contrastText === "#fff" ||
        theme.palette.primary.contrastText === "#ffffff"
      ) {
        return {
          backgroundImage: `linear-gradient(#ffffffd6, #ffffffd6), url(${groupData?.background?.imagePath})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: 0,
          width: "100%",
        };
      }
      if (
        theme.palette.primary.contrastText === "#000" ||
        theme.palette.primary.contrastText === "#000000"
      ) {
        return {
          backgroundImage: `url(${groupData?.background?.imagePath})`,
          backgroundColor: "#535353",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
          borderRadius: 0,
          width: "100%",
        };
      }
    } else {
      return {
        borderRadius: 0,
        width: "100%",
      };
    }
  };

  const getDescriptionColor = () => {
    if (groupData?.background?.imagePath) {
      return theme.palette.primary.contrastText === "#fff" ||
        theme.palette.primary.contrastText === "#ffffff"
        ? "#000"
        : "#fff";
    }
    return "#000";
  };

  if (loading) {
    return <Main />;
  }
  return (
    <ThemeProvider theme={theme}>
      <Navbar
        {...props}
        customTheme={theme}
        isLoggedIn={isLoggedIn}
        hasJoinedGroup={hasJoinedGroup}
        groupMemberStatus={groupMemberStatus}
        groupId={groupData?._id}
        groupShortCode={shortId}
        pendingInvites={
          groupMembers.filter(
            (member) => member?.invitationStatus === "pending"
          ).length
        }
        joinGroupFunction={async () => {
          isLoggedIn ? await joinGroup() : setShowSignUp(true);
        }}
      />

      <Main
        background={`url(${groupData?.background?.imagePath})` || "inherit"}
      >
        <Modal
          open={showEditMemoryPopup}
          onClose={() => setShowEditMemoryPopup(false)}
        >
          <EditGroupMemoryPopup
            editingMemory={editingMemory}
            editMemoryFunc={updateMemory}
            deleteMemoryFunc={deleteMemory}
            timelines={groupData?.timelineCategories}
            customTheme={theme}
            onCloseFunction={() => setShowEditMemoryPopup(false)}
          />
          {/* EditMemoryPopup */}
        </Modal>
        <Modal
          open={showCreateMemoryPopup}
          onClose={() => setShowCreateMemoryPopup(false)}
        >
          <CreateMemoryPopup
            storyTitle={title}
            setStoryTitle={setTitle}
            userName={name}
            setUserName={setName}
            userEmail={email}
            setUserEmail={setEmail}
            userStory={message}
            setUserStory={setMessage}
            image={image}
            setImage={setImage}
            tempImage={tempImage}
            setTempImage={setTempImage}
            date={date}
            setDate={setDate}
            onSubmitFunction={createMemory}
            storyLocation={storyLocation}
            setStoryLocation={setStoryLocation}
            customTheme={theme}
            isLoggedIn={isLoggedIn}
            uploadImage={() => imageInput.current.click()}
            setMemoryType={setMemoryType}
            tempAudio={tempAudio}
            setTempAudio={setTempAudio}
            isRecording={isRecording}
            setIsRecording={setIsRecording}
            memoryType={memoryType}
            timelines={groupData?.timelineCategories}
            groupMemberStatus={groupMemberStatus}
            onCloseFunction={() => setShowCreateMemoryPopup(false)}
            onClose={onClose}
          />
        </Modal>
        {/* Member Modal */}
        <Modal open={showMembers} onClose={() => setShowMembers(false)}>
          <Paper
            style={{
              position: "absolute",
              width: "70%",
              backgroundColor: "white",
              padding: "3rem",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxHeight: "80%",
              maxWidth: "85vw",
            }}
          >
            <Typography variant="h4" gutterBottom>
              Members of {groupData?.groupName}
            </Typography>
            {groupMembers.map((member, i) => (
              <>{generateMemberListItem(member, i)}</>
            ))}
          </Paper>
        </Modal>
        {/* Invite Member */}
        <Modal
          open={showInviteMemberPopup}
          onClose={() => setShowInviteMemberPopup(false)}
        >
          <InviteMemberPopup
            groupShortCode={shortId}
            groupName={groupData?.groupName}
            groupId={groupData?._id}
            onCloseFunction={() => setShowInviteMemberPopup(false)}
          />
        </Modal>
        {/* Sign up modal */}
        <Modal open={showSignUp} onClose={() => setShowSignUp(false)}>
          <Paper
            id="create-memory-popup"
            elevation={1}
            style={{
              position: "absolute",
              textAlign: "left",
              width: window.innerWidth < 1500 ? "80%" : "60%",
              maxWidth: "600px",
              backgroundColor: "",
              color: "",
              padding: "1rem",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxHeight: "90%",
              borderRadius: "2rem",
              overflowY: "scroll",
            }}
          >
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="text"
                color="primary"
                onClick={() => setShowSignUp(false)}
                style={{
                  postion: "absolute",
                  top: "-0.5rem",
                  right: "-1rem",
                  marginBottom: "-2rem",
                }}
              >
                <Icon icon="close" />
              </Button>
            </Grid>
            {pubNoAcc ? (
              <div style={{ padding: "1rem", textAlign: "center" }}>
                <Typography variant="h5">
                  Congrats! You just posted a story to {groupData.groupName}
                </Typography>
                <br></br>
                <Typography variant="body1">
                  You're not a member of this group yet, but in 2 minutes you
                  could be. Create a free account and join the group to get
                  access to these great features:
                  <br></br>
                  <br></br>
                  <div
                    style={{
                      textAlign: "left",
                      display: "flex",
                      paddingLeft: window.innerWidth < 900 ? "0rem" : "3rem",
                      paddingRight: window.innerWidth < 900 ? "0rem" : "3rem",

                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="body1">
                      <li>A personal timeline to keep your own stories</li>
                      <li>Choose your own profile picture</li>
                      <li>Like and comment on other peoples stories</li>
                      <li>Create your own customisable groups</li>
                      <li>Easy access to groups you have joined</li>
                      <li>Post stories in an instant</li>
                    </Typography>
                    <br></br>
                  </div>
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: window.innerWidth < 700 ? "column" : "row",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                  }}
                >
                  <CallToActionButton
                    clickFunction={() => setPubNoAcc(false)}
                    title="Join Group"
                  />
                  <CallToActionButton
                    variant="outlined"
                    clickFunction={() => {
                      setPubNoAcc(false);
                      setShowSignUp(false);
                    }}
                    title="Maybe Later"
                  />
                </div>
              </div>
            ) : (
              <>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ textAlign: "center" }}
                >
                  Sign Up
                </Typography>
                <Typography style={{ textAlign: "left" }}>Name*</Typography>
                <TextField
                  variant="outlined"
                  placeholder="Your Name"
                  fullWidth
                  value={name}
                  onChange={(text) => setName(text.target.value)}
                  style={{ marginBottom: "1rem" }}
                />
                <Typography style={{ textAlign: "left" }}>Email*</Typography>
                <TextField
                  variant="outlined"
                  placeholder="Your Email"
                  fullWidth
                  value={email}
                  onChange={(text) => setEmail(text.target.value)}
                  style={{ marginBottom: "1rem" }}
                />
                <Typography style={{ textAlign: "left" }}>Password*</Typography>
                <TextField
                  variant="outlined"
                  placeholder="Your Password"
                  type="password"
                  fullWidth
                  value={password}
                  onChange={(text) => setPassword(text.target.value)}
                  style={{ marginBottom: "1rem" }}
                />
                <Typography style={{ textAlign: "left" }}>
                  Date of Birth*
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <MaDatePicker
                    openTo="year"
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    placeholder="Select your birthday"
                    fullWidth
                    onChange={onDobChange}
                    maxDate={new Date().toISOString().split("T")[0]}
                    value={dateOfBirth || new Date()}
                    style={{
                      scrollMargin: "11rem",
                      backgroundColor: "white",
                      borderRadius: "7px",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <CallToActionButton
                    variant="contained"
                    color="primary"
                    title="Sign Up and Join Group"
                    fullWidth
                    style={{ marginTop: "1rem" }}
                    clickFunction={() => handleSignUp()}
                    disabled={isSigningUp}
                  ></CallToActionButton>
                </div>
              </>
            )}
          </Paper>
        </Modal>
        {showImagePopup && (
          <Lightbox
            mainSrc={tempImage}
            onCloseRequest={() => setShowImagePopup(false)}
            reactModalStyle={{ zIndex: 1021 }}
          />
        )}

        <Paper elevation={0} style={getBackgroundStyle()}>
          <Grid
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              // background: "green",
            }}
          >
            {/* <Grid
              item
              xs={4}
              style={{
               marginRight:"5rem",
              }}
            >
              <Avatar
                src={
                  groupData?.profilePicture?.imagePath
                    ? groupData.profilePicture.imagePath
                    : require("../../../../assets/orange_logo.png")
                }
                alt="Your profile avatar"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",

                  width: window.innerWidth < 960 ? "150px" : "35vh",
                  height: window.innerWidth < 960 ? "150px" : "35vh",
                  maxHeight: window.innerWidth > 960 ? "40vh" : "40vh",
                  borderRadius: "100%",
                  cover: "cover",
                  marginTop: window.innerWidth < 960 ? "2rem" : "5rem",
                  visibility: groupData?.profilePicture?.imagePath
                    ? "default"
                    : "hidden",
                }}
              >
                {groupData?.groupName
                  ? generateGroupInitials(groupData.groupName)
                  : "G"}
              </Avatar>
            </Grid> */}
            <Grid
              container
              style={{
                marginBottom: "2rem",
                margin: "0 auto",
                padding: "2rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",

                      // alignItems: 'left',
                      // marginLeft:'1rem',
                      // marginTop:'1rem',
                    }}
                  >
                    {/* <Typography
                  variant="header1"
                  style={{
                    textAlign: 'left',
                    fontWeight: 'bolder',
                    fontSize: window.innerWidth < 960 ? '4.5rem' : '7.5rem',
                    color: getDescriptionColor(),
                  }}
                >
                  Our&nbsp;
                </Typography> */}
                    {groupData?.groupPrivacy === "private" && !hasJoinedGroup && (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Avatar
                          id="private-group-avatar"
                          src={
                            groupData?.profilePicture?.imagePath
                              ? groupData.profilePicture.imagePath
                              : require("../../../../assets/orange_logo.png")
                          }
                          alt="Your profile avatar"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",

                            width: window.innerWidth < 600 ? "100px" : "25vh",
                            height: window.innerWidth < 600 ? "100px" : "25vh",
                            maxHeight:
                              window.innerWidth > 960 ? "40vh" : "40vh",
                            borderRadius: "20%",
                            cover: "cover",
                            marginTop:
                              window.innerWidth < 960 ? "1rem" : "1rem",
                            marginBottom: "2rem",
                            visibility: groupData?.profilePicture?.imagePath
                              ? "default"
                              : "hidden",
                          }}
                        >
                          {groupData?.groupName
                            ? generateGroupInitials(groupData.groupName)
                            : "G"}
                        </Avatar>
                      </div>
                    )}
                    {groupData?.groupPrivacy === "private" &&
                      hasJoinedGroup &&
                      userInvitationStatus === "pending" && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Avatar
                            id="private-group-avatar"
                            src={
                              groupData?.profilePicture?.imagePath
                                ? groupData.profilePicture.imagePath
                                : require("../../../../assets/orange_logo.png")
                            }
                            alt="Your profile avatar"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",

                              width: window.innerWidth < 600 ? "100px" : "25vh",
                              height:
                                window.innerWidth < 600 ? "100px" : "25vh",
                              maxHeight:
                                window.innerWidth > 960 ? "40vh" : "40vh",
                              borderRadius: "20%",
                              cover: "cover",
                              marginTop:
                                window.innerWidth < 960 ? "1rem" : "1rem",
                              marginBottom: "2rem",
                              visibility: groupData?.profilePicture?.imagePath
                                ? "default"
                                : "hidden",
                            }}
                          >
                            {groupData?.groupName
                              ? generateGroupInitials(groupData.groupName)
                              : "G"}
                          </Avatar>
                        </div>
                      )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        maxWidth: "100%",
                      }}
                    >
                      <Typography
                        variant="header1"
                        color="primary"
                        style={{
                          textAlign: "center",
                          fontWeight: "bolder",
                          fontSize: window.innerWidth < 960 ? "2rem" : "5vw",
                          textTransform: "capitalize",
                          maxWidth: "80%",
                          justifySelf: "center",
                          alignSelf: "center",
                          padding: "0rem",
                        }}
                      >
                        {groupData.groupName}
                      </Typography>
                    </div>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        maxWidth: "100%",
                      }}
                    >
                      <Typography
                        variant=""
                        style={{
                          color: getDescriptionColor(),
                          fontWeight: "normal",

                          maxWidth: "682px",
                          textAlign: "center",
                          fontSize: window.innerWidth < 960 ? "1rem" : "1.2rem",

                          marginTop: "1rem",
                          lineHeight: "2rem",
                          textTransform: "capitalize",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {groupData?.groupDescription}
                      </Typography>
                    </div>
                    <input
                      ref={imageInput}
                      accept="image/*"
                      type="file"
                      onChange={directUploadToGoogle}
                      style={{ display: "none" }}
                      multiple={false}
                    />
                    {/* <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setShowCreateMemoryPopup(true)}
                >
                  Create Memory
                </Button> */}
                  </div>
                </Grid>
                {/* // Show if group is private and user is not requested to group yet */}
              </Grid>

              {groupData?.groupPrivacy === "private" && !hasJoinedGroup && (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  style={{
                    marginTop: "2rem",
                    display: "flex",
                    alignContent: "center",

                    flexDirection: "column",
                  }}
                >
                  <Grid>
                    <Typography
                      variant=""
                      color="primary"
                      style={{
                        marginTop: "3rem",
                        fontWeight: "bolder",
                        marginBottom: "2rem",
                        textAlign: "center",
                        fontSize: window.innerWidth < 600 ? "1rem" : "1.5rem",
                      }}
                    >
                      This group is set to private
                    </Typography>
                    {!hasJoinedGroup && (
                      <Grid
                        container
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: window.innerHeight < 700 ? "1rem" : "2rem",
                        }}
                      >
                        <CallToActionButton
                          variant="contained"
                          color="primary"
                          title="Request to Join"
                          style={{
                            boxShadow:
                              "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                            marginBottom: "30vh",
                          }}
                          disabled={isLoadingJoin}
                          clickFunction={async () => {
                            isLoggedIn
                              ? await joinGroup()
                              : setShowSignUp(true);
                          }}
                        ></CallToActionButton>
                        <div style={{ minHeight: "50vh" }}></div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              {/* // Show if group is private and user has requested to join the group8 */}
              {groupData?.groupPrivacy === "private" &&
                hasJoinedGroup &&
                userInvitationStatus === "pending" && (
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    style={{
                      marginTop: "2rem",
                      display: "flex",
                      alignContent: "center",
                    }}
                  >
                    <Typography
                      variant=""
                      color="primary"
                      style={{
                        marginTop: "3rem",
                        fontWeight: "bolder",
                        marginBottom: "2rem",
                        textAlign: "center",
                        fontSize: window.innerWidth < 960 ? "1rem" : "1.5rem",
                      }}
                    >
                      This group is set to private
                    </Typography>
                    {!hasJoinedGroup && (
                      <CallToActionButton
                        variant="contained"
                        color="primary"
                        title="Request to Join"
                        style={{
                          marginTop: "1rem",
                          borderRadius: "100px",
                          width: "200px",
                          padding: "1rem",
                          margin: "0 auto",
                          marginBottom: "30vh",
                        }}
                        disabled={isLoadingJoin}
                        clickFunction={async () => {
                          isLoggedIn ? await joinGroup() : setShowSignUp(true);
                        }}
                      ></CallToActionButton>
                    )}
                    {hasJoinedGroup && (
                      <Typography
                        variant="body1"
                        color="primary"
                        style={{
                          textAlign: "center",
                          color: getDescriptionColor(),
                        }}
                      >
                        {requestedToJoin}
                      </Typography>
                    )}
                  </Grid>
                )}

              {/* Show if Group is Public or User is in group */}
            </Grid>
          </Grid>
          {groupData?.groupPrivacy === "public" ||
          (hasJoinedGroup && userInvitationStatus === "accepted") ? (
            <>
              {/* <Typography
                    variant="h5"
                    color="primary"
                    style={{
              
                      marginBottom:'1rem',
                      fontWeight: 'bold',
                      textAlign:'center'
                    
                    }}
                  >
                    Post A Memory
                  </Typography>
              <Grid container   style={{
                    marginTop: '1rem',   
                    display: 'flex',
                justifyContent: 'space-around',
                padding:'1rem',
               }}>
                <Grid item>
                  <Grid container spacing={3}  >
                    <Grid item xs={4}>
                      <CreateMemoryButton
                        text="Upload Photo"
                        Icon={FaCamera}
                        // clickFunction={() => setRecordText(true)}
                        clickFunction={async () => {
                          setShowCreateMemoryPopup(true);
                          setMemoryType('photo');
                        }}
                        theme={theme}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CreateMemoryButton
                        text="Voice Note"
                        Icon={FaMicrophone}
                        clickFunction={async () => {
                          setShowCreateMemoryPopup(true);
                          setMemoryType('audio');
                        }}
                        theme={theme}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CreateMemoryButton
                        text="Write Memory"
                        Icon={FaEdit}
                        clickFunction={async () => {
                          setShowCreateMemoryPopup(true);
                          setMemoryType('written');
                        }}
                        theme={theme}
                      />
                    </Grid>
                  </Grid>
                  </Grid>
                  </Grid> */}
            </>
          ) : (
            <>
              {/* <Typography>{groupData?.groupPrivacy}</Typography>
                  <Typography>{groupMemberStatus}</Typography> */}
            </>
          )}
          {groupData?.groupPrivacy === "public" ||
          groupData?.groupPrivacy === "readOnly" ||
          (hasJoinedGroup && userInvitationStatus === "accepted") ? (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
                marginBottom: window.innerWidth < 960 ? "3rem" : "2rem",
                flexDirection:
                  window.innerWidth < 450 ? "column-reverse" : "row",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginBottom: "1rem",
                  borderRadius: "100px",
                  minWidth: "200px",
                  minHeight: "50px",
                  marginRight: window.innerWidth < 450 ? "0rem" : "0.5rem",
                }}
                onClick={() => {
                  timelineRef.current.scrollIntoView({ behavior: "smooth" });
                }}
              >
                <FaAngleDown
                  style={{
                    color: theme.palette.primary.contrastText,
                    width: "50%",
                    height: "50%",
                    maxHeight: "2rem",
                    marginRight: "-20px",
                    marginLeft: "-20px",
                  }}
                />
                See Timeline
              </Button>

              {/* <Button
                variant="contained"
                color="primary"
                style={{
                  marginTop: window.innerWidth < 450 ? "1rem" : "0rem",
                  marginBottom: window.innerWidth < 450 ? "2rem" : "1rem",
                  borderRadius: "100px",
                  minWidth: "200px",
                  minHeight: "50px",
                  marginLeft: window.innerWidth < 450 ? "0rem" : "0.5rem",
                }}
                onClick={() => {
                  setShowCreateMemoryPopup(true);
                }}
              >
                <FaPlus
                  style={{
                    color: theme.palette.primary,
                    width: "50%",
                    height: "50%",
                    maxHeight: "1.3rem",
                    marginRight: "-20px",
                    marginLeft: "-20px",
                  }}
                />
                Post A Memory
              </Button> */}
            </div>
          ) : (
            <></>
          )}
        </Paper>
        {groupData?.groupPrivacy === "public" ||
        groupData?.groupPrivacy === "readOnly" ||
        (hasJoinedGroup && userInvitationStatus === "accepted") ? (
          <Paper
            elevation={3}
            style={{ borderRadius: "0px", background: "#FCF8F7" }}
          >
            <div
              style={{
                maxWidth: "1200px",
                padding: "2rem",
                margin: "0 auto",
                background: "#FCF8F7",
              }}
            >
              <div
                role="tabpanel"
                style={{
                  background: "#FCF8F7",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  direction={
                    window.innerWidth < 960 ? "column-reverse" : "unset"
                  }
                  ref={timelineRef}
                >
                  <Grid item sm={12} md={7} style={{ width: "100%" }}>
                    {groupMemories.length > 0 ? (
                      <GroupTimeline
                        memories={groupMemories}
                        customTheme={theme}
                        milestones={[]}
                        getMilestonesFunc={() => {}}
                        prompts={[]}
                        viewingFriendsMemory={false}
                        profile={{ picture: "", name: "Chris" }}
                        editMilestoneFunc={() => {}}
                        id="timelineWrapper"
                        groupMemberStatus={groupMemberStatus}
                        openEditMemoryFunc={openEditMemory}
                        userId={userId}
                        style={{
                          height: "68vh",
                          overflow: "auto",
                          position: "initial",
                          borderRadius: "1rem 1rem 0 0",
                        }}
                      />
                    ) : (
                      <GroupTimeline
                        memories={[
                          {
                            approved: true,
                            author: {
                              email: "hello@iternal.life",
                              name: "Iternal",
                            },
                            authorImage: {
                              imagePath: require("../../../../assets/orange_logo.png"),
                            },
                            createdAt: new Date(),
                            date: new Date(),
                            groupId: groupData?._id,
                            images: [],
                            isPublic: true,
                            memory:
                              tabValue === 0
                                ? "This is where you'll find the memories and stories of all your members. To get started just click the Post A Story button."
                                : "When you or any other member contributes a story, memory or experience to the group, you'll be able to find them here in chronological order.",
                            privacySetting: "Public",
                            tempImages: [],
                            title:
                              tabValue === 0
                                ? "This is where you'll find your group's history"
                                : "This is where you'll find the memories and stories of all your members",
                            voiceRecording: "",
                          },
                        ]}
                        refObj={timelineRef}
                        customTheme={theme}
                        milestones={[]}
                        getMilestonesFunc={() => {}}
                        prompts={[]}
                        viewingFriendsMemory={false}
                        profile={{ picture: "", name: "Chris" }}
                        editMilestoneFunc={() => {}}
                        id="timelineWrapper"
                        // groupMemberStatus={groupMemberStatus}
                        openEditMemoryFunc={() => {}}
                        style={{
                          height: "68vh",
                          overflow: "auto",
                          position: "initial",
                          borderRadius: "1rem 1rem 0 0",
                        }}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={5}
                    style={{
                      padding: window.innerWidth < 960 ? "0rem" : "2rem",
                      paddingLeft: window.innerWidth < 960 ? "0rem" : "0rem",
                      borderRadius: "0px",
                    }}
                  >
                    <Grid item sm={12}>
                      <Paper
                        elevation={1}
                        style={{
                          padding: window.innerWidth < 960 ? "1rem" : "2rem",
                          borderRadius: "0px",
                          marginLeft: window.innerWidth < 960 ? "1rem" : "0rem",
                          marginRight:
                            window.innerWidth < 960 ? "1rem" : "0rem",
                        }}
                      >
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="demo-simple-select-outlined-label">
                            Sort Your Stories{" "}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={filterName}
                            onChange={handleFilter}
                            label="Sort Your Stories"
                          >
                            <MenuItem value={"date-newest"}>
                              Event Date - Most Recent{" "}
                            </MenuItem>
                            <MenuItem value={"date-oldest"}>
                              Event Date - Least Recent
                            </MenuItem>
                            <MenuItem value={"createdAt-newest"}>
                              Upload Date - Most Recent{" "}
                            </MenuItem>
                            <MenuItem value={"createdAt-oldest"}>
                              Upload Date - Least Recent{" "}
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <Grid
                          container
                          spacing={2}
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            lg={6}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                marginTop: "1rem",
                                borderRadius: "100px",
                                minWidth: "200px",
                                minHeight: "50px",
                                marginLeft: "0.5rem",
                              }}
                              onClick={() => {
                                setShowCreateMemoryPopup(true);
                              }}
                            >
                              <FaPlus
                                style={{
                                  color: theme.palette.primary,
                                  width: "50%",
                                  height: "50%",
                                  maxHeight: "1.3rem",
                                  marginRight: "-20px",
                                  marginLeft: "-20px",
                                }}
                              />
                              Post A Story
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            lg={6}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                marginTop: "1rem",
                                borderRadius: "100px",
                                minWidth: "200px",
                                minHeight: "50px",
                                marginLeft: "0.5rem",
                                alignSelf: "center",
                              }}
                              onClick={async () => {
                                setShowInviteMemberPopup(true);
                              }}
                            >
                              <FaShare
                                style={{
                                  color: theme.palette.primary,
                                  width: "50%",
                                  height: "50%",
                                  maxHeight: "1.3rem",
                                  marginRight: "-20px",
                                  marginLeft: "-20px",
                                }}
                              />
                              Share Group
                            </Button>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Avatar
                            src={
                              groupData?.profilePicture?.imagePath
                                ? groupData.profilePicture.imagePath
                                : require("../../../../assets/orange_logo.png")
                            }
                            alt="Your profile avatar"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",

                              width: window.innerWidth < 960 ? "150px" : "35vh",
                              height:
                                window.innerWidth < 960 ? "150px" : "35vh",
                              maxHeight:
                                window.innerWidth > 960 ? "40vh" : "40vh",
                              borderRadius: "100%",
                              cover: "cover",
                              marginTop:
                                window.innerWidth < 960 ? "2rem" : "3rem",
                              marginBottom:
                                window.innerWidth < 960 ? "0rem" : "3rem",
                              display:
                                window.innerWidth < 960 ? "none" : "block",
                              visibility: groupData?.profilePicture?.imagePath
                                ? "default"
                                : "hidden",
                            }}
                          >
                            {groupData?.groupName
                              ? generateGroupInitials(groupData.groupName)
                              : "G"}
                          </Avatar>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>

                {/* <TabPanel>
                Item {value.name}
              </TabPanel> */}
              </div>
            </div>
          </Paper>
        ) : (
          <></>
        )}
      </Main>
    </ThemeProvider>
  );
};

export default GroupPage;
