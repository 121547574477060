import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Main } from './CardScroll.style';
import {
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  ButtonBase,
  CircularProgress,
  Fade,
  Collapse,
  Zoom,
  Grid,
} from '@material-ui/core';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { IternalTheme } from "../../--Theme/IternalThemeLight";
import { ALBUM_URL } from '../../../constants/constantUrls';
import placeholder from '../../../assets/i-logo-orange.png'

const theme = createMuiTheme(IternalTheme);

const useStyles = makeStyles({
  image: {
    position: "relative",
    height: 400,
    borderRadius: '7px',
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
   
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: '7px',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
    borderRadius: '7px',
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create("opacity"),
    borderRadius: '7px',
  },
  imageBackdropHighlighted: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.8,
    transition: theme.transitions.create("opacity"),
    borderRadius: '7px',
  },
  imageTitle: {
    marginTop: "-16.5px",
    fontSize: "1.3rem",
    lineHeight: "1",
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) +
      6}px`,
    textAlign: "center",
    textTransform: "capitalize",
  },
  linkContainer: {
    borderRadius: '7px',
    '&:hover': {
      '& $attributionLink': {
        // display: 'block',
      }
    }
  },
  imageTitleNoText: {
    fontSize: "1.3rem",
    lineHeight: "1",
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) +
      6}px`,
    textAlign: "center",
    textTransform: "capitalize",
  },
  attributionLink: {
    bottom: 0, 
    left: 0,
    color: 'white', 
    textOverflow: 'ellipsis', 
    position: 'absolute',
    textAlign: 'center',
    boxSizing: 'border-box',
    // fontFamily: 'cursive',
    fontSize: '1.25rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    background: 'linear-gradient(0deg, black, transparent)',
    borderRadius: '0px 0px 7px 7px',
    "&:hover": {
      background: 'linear-gradient(0deg, #000B, transparent)',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    transition: theme.transitions.create("opacity"),
  },
});

export const CardScroll = ({ 
  text, 
  albumId='',
  cover={imagePath: ''},
  isHighlighted=false,
  width=200,
  ...props 
}) => {
  const classes = useStyles();
  return (
    <div {...props}>
      <NavLink
        to={{
          pathname: `${ALBUM_URL.replace(':albumId', albumId)}`,
          state: {refreshNeeded: true}
        }}
        style={{ textDecoration: 'none', }}
      >
        <ButtonBase
          focusRipple
          key={albumId}
          className={classes.image}
          focusVisibleClassName={classes.focusVisible}
          style={{
            width,
            height:'204px', 
            
          }}
        >
          <span
            className={classes.imageSrc}
            style={{
              borderRadius:'0px', 
              backgroundImage: 
                cover?.imagePath?.length > 0 ?
                `url(${cover?.imagePath})` :
                `url(${placeholder})`,
            }}
          />
          <span
            className={
              isHighlighted
                ? classes.imageBackdropHighlighted
                : classes.imageBackdrop
            }
          />
          <span className={classes.imageButton}>
            <Typography
              component="span"
              variant="body2"
              color="inherit"
              className={text?.length > 0 ? classes.imageTitle : classes.imageTitleNoText}
            >
              <Fade
                in={false}
                style={{ display: "block", margin: "0 auto",}}
              >
                <CheckCircleIcon style={{ fontSize: "1.25rem" }} />
              </Fade>
              <span className={classes.imageMarked} />
            </Typography>
          </span>
          {/* {text?.length > 0 && (
            <div
              className={classes.linkContainer}
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              }}
            >
              <Typography class={classes.attributionLink}>{text}</Typography>
            </div> */}
        </ButtonBase>
      </NavLink>
    </div>
  )
};

CardScroll.propTypes = {
  /**
   * Enter a description for each property above it like so
   */
  text: PropTypes.string,
};

CardScroll.defaultProps = {
  text: 'My Album',
};
