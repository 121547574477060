// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// MUI Core
import { Paper, Typography, Button, Chip, Grid } from '@material-ui/core';

// MUI Icons - Public/Private Chip
import DraftIcon from '@material-ui/icons/Create';
import PublicIcon from '@material-ui/icons/Public';
import SharedIcon from '@material-ui/icons/People';
import OnlyMeIcon from '@material-ui/icons/Lock';
import ShareIcon from '@material-ui/icons/Share';

// MUI Lab
import TimelineContent from '@material-ui/lab/TimelineContent';

// Modal
import Modal from '../../../../../../Components/Common/FixedModal/index';

// Constants
import { MEMORIES_BASE_URL } from '../../../../../../constants/apiRoutes';

// Styled Components
import { ContentCardStyles } from '../ContentCards';

// Carousel
import { Carousel } from '../../../../../Pages/ViewMemory/Carousel/Carousel';

// Sharing Modal
import { ShareModal } from '../../../../../Pages/CreateMemory/ShareModal/ShareModal';

export function MemoryCard({
  _id,
  createdAt,
  date,
  dateAge,
  dateType,
  description,
  category,
  images,
  isDraft,
  isPublic,
  privacySetting,
  taggedEmails,
  tempImages,
  title,
  updatedAt,
  user,
  video,
  tempVideo,
  voice,
  tempVoice,
  isHighlighted,
  shareFunc,
  author,
  viewingFriendsMemory = false,
  isCompetition = false,
  formattedDate,
  noLogin = false,
  profile,
  ...props
}) {
  // Collect memory details
  const memory = {
    _id,
    createdAt,
    date,
    dateAge,
    dateType,
    description,
    images,
    isDraft,
    isPublic,
    privacySetting,
    taggedEmails,
    tempImages,
    title,
    updatedAt,
    user,
    video,
    tempVideo,
    voice,
    tempVoice,
  };
  // Track if memory privacy status is being edited
  const [privacyState, setPrivacyState] = useState(privacySetting);
  const [isEditingSharing, setIsEditingSharing] = useState(false);

  // Convert legacy privacy setting to new privacy setting string
  const getPrivacySettingString = () => {
    switch (true) {
      case privacyState !== undefined:
        return privacyState;
      case isDraft === true:
        return undefined;
      case isPublic === false:
        return 'Friends & Family';
      case isPublic === true:
        return 'Public';
      default:
        break;
    }
  };

  useEffect(() => {
    setPrivacyState(privacySetting);
  }, [memory, privacySetting]);

  // Content rendering
  const renderPrivacySetting = () => {
    const chipProps = {
      className: 'privacySettingChip',
      color: 'primary',
      variant: 'outlined',
      icon: false,
      label: false,
      onClick: () => setIsEditingSharing(true),
    };
    // Make non-clickable if displaying a friend's memory
    if (viewingFriendsMemory) {
      chipProps.clickable = false;
      chipProps.onClick = () => {};
    }
    // Private Memory
    if (privacyState === 'Only Me') {
      chipProps.label = 'Only Me';
      chipProps.icon = <OnlyMeIcon />;
      chipProps.color = 'default';
    }
    // Private Memory
    if (privacyState === 'Specific Groups') {
      chipProps.label = 'Specific Groups';
      chipProps.icon = <SharedIcon />;
      chipProps.color = 'default';
    }
    // Public Memory
    if ((!privacyState && isPublic) || privacyState === 'Public') {
      chipProps.label = 'Public';
      chipProps.icon = <PublicIcon />;
      chipProps.color = 'primary';
    }
    // Friends & Family
    if ((!privacyState && !isPublic) || privacyState === 'Friends & Family') {
      chipProps.label = 'Friends & Family';
      chipProps.icon = <SharedIcon />;
      chipProps.color = 'default';
    }
    // Draft Memory
    if (isDraft) {
      chipProps.label = 'Draft';
      chipProps.icon = <DraftIcon />;
      chipProps.color = 'default';
      chipProps.onClick = () => {};
    }

    return <Chip {...chipProps} />;
  };
  const renderShortenedText = (text, length) =>
    length < text?.length ? `${text?.slice(0, length)}...` : text || null;
  const renderCTAButtonText = () =>
    isDraft ? `Complete This Story` : `View This Story`;

  const renderShareModal = () => (
    <Modal
      isShowing={isEditingSharing}
      hide={() => setIsEditingSharing(false)}
      closeAfterTransition
      hideBackground
    >
      <ShareModal
        className="modalContents"
        memory={{ ...memory, privacySetting: getPrivacySettingString() }}
        setPrivacyStateFunc={setPrivacyState}
        closeFunc={() => setIsEditingSharing(false)}
      />
    </Modal>
  );

  return (
    <ContentCardStyles {...props}>
      {isEditingSharing && renderShareModal()}
      <TimelineContent className="cardWrapper">
        <Paper  elevation={isHighlighted ? 15 : 2} className="card" style={{}}>
          <div className="cardMediaWrapper">
            <Carousel {...memory} />
          </div>
          <div className="cardContentWrapper">
          <Grid container style={{display:'flex', flexDirection:'row-reverse', alignContent:'center'}}>
              
                <Grid xs={12} sm={6} style={{display:'flex', justifyContent:'flex-end'}}>
                {renderPrivacySetting()}
                </Grid>
                <Grid item xs={12} sm={6} style={{display:'flex', justifyContent:'flex-start', alignContent:'center'}}>
              
              <Typography variant="body1" color="textSecondary">
                {formattedDate}
              </Typography>
              </Grid>
            </Grid>
            <NavLink
              className="CTAButton"
              to={{
                pathname: noLogin
                  ? `/share/memory/${_id}`
                  : `${MEMORIES_BASE_URL}/${_id}`,
                state: {
                  details: memory,
                  profile,
                  viewingFriendsMemory,
                },
              }}
            >
              <Typography color="primary" className="title" style={{fontSize:'1.5rem', textTransform:'capitalize'}} >
                {renderShortenedText(title, 100)}
              </Typography>
       
            </NavLink>
          
            <Typography className="description">
              {isCompetition ? description : renderShortenedText(description, 150)}
            </Typography>
            {author && author?.name && (
              <>
                <Typography>Written by: {author?.name}</Typography>
                {isCompetition && author?.hometown && (
                  <Typography>From: {author?.hometown}</Typography>
                )}
              </>
            )}
        
            <div>
              <NavLink
                className="CTAButton"
                to={{
                  pathname: noLogin
                    ? `/share/memory/${_id}`
                    : `${MEMORIES_BASE_URL}/${_id}`,
                  state: {
                    details: memory,
                    profile,
                    viewingFriendsMemory,
                  },
                }}
              >
                <Button
                  className="viewMemoryButton"
                  color="secondary"
                  variant="contained"
                  style={{borderRadius:'100px', fontWeight:'700'}}
                >
                  {renderCTAButtonText()}
                </Button>
              </NavLink>
              &nbsp;
              {!viewingFriendsMemory && (
                <Button
                  className="shareMemoryButton"
                  color="primary"
                  variant="outlined"
                  style={{borderRadius:'10px', fontWeight:'700', padding:'0.5rem', marginLeft:'0.5rem'}}
                  onClick={() => setIsEditingSharing(true)}
                >
                  <ShareIcon />
                  &nbsp;&nbsp;Share
                </Button>
              )}
              {/* <Chip>{chipProps}}</Chip> */}
            </div>
           
          </div>
        </Paper>
      </TimelineContent>
    </ContentCardStyles>
  );
}

MemoryCard.propTypes = {
  // Memory props
  /**
   * The unique ID of this memory
   */
  _id: PropTypes.string,
  /**
   * The date this memory was created
   */
  createdAt: PropTypes.string,
  /**
   * The date this memory happened
   */
  date: PropTypes.string,
  /**
   * How old the user remember being when this memory happened (used only if `dateType` is set to `age`)
   */
  dateAge: PropTypes.number,
  /**
   * The type of date display used for this memory (`full` if the user has specified an exact date, `age` if the user has specified how old they remember being when this memory happened)
   */
  dateType: PropTypes.oneOf(['full', 'age']),
  /**
   * The user's description of this memory
   */
  description: PropTypes.string,
  /**
   * An array of images uploaded for this memory, each element being an object with a unique `_id` and `imagePath`
   */
  images: PropTypes.arrayOf({
    _id: PropTypes.string,
    imagePath: PropTypes.string,
  }),
  /**
   * Is this memory unfinished / unpublished / draft status?
   */
  isDraft: PropTypes.bool,
  /**
   * Is this memory shared publicly?
   */
  isPublic: PropTypes.bool,
  /**
   * An array of user email addresses which were tagged in this memory. Can be left blank if no other users were tagged.
   */
  taggedEmails: PropTypes.arrayOf(PropTypes.string),
  /**
   * An array of images uploaded for this memory, where each `imagePath` provides a direct URL to view the image source, authenticated with a token
   */
  tempImages: PropTypes.arrayOf({
    _id: PropTypes.string,
    imagePath: PropTypes.string,
  }),
  /**
   * The title of this memory
   */
  title: PropTypes.string,
  /**
   * A date specifying when the memory was last updated
   */
  updatedAt: PropTypes.string,
  /**
   * The user account that created this memory
   */
  user: PropTypes.string,
  /**
   * The video uploaded for this memory (provided as a path, can be blank)
   */
  video: PropTypes.string,
  /**
   * The voice recording uploaded for this memory (provided as a path, can be blank)
   */
  voice: PropTypes.string,

  // Other props

  /**
   * Provide a function to open the privacy setting modal/page for the current memory shown
   */
  openPrivacySettingsFunc: PropTypes.func,
  /**
   * Provide a function to open the sharing modal / navigate to the sharing page
   */
  shareFunc: PropTypes.func,
  /**
   * Should this card be highlighted?
   */
  isHighlighted: PropTypes.bool,
};
MemoryCard.defaultProps = {
  // Memory
  _id: undefined,
  title: undefined,
  date: undefined,
  dateType: 'full',
  dateAge: undefined,
  description: undefined,
  user: undefined,
  isDraft: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  isPublic: false,
  taggedEmails: undefined,
  images: undefined,
  tempImages: undefined,
  voice: undefined,
  video: undefined,
  // Other props
  openPrivacySettingsFunc: () => {},
  shareFunc: () => {},
  isHighlighted: false,
};
