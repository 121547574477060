import React, { Component } from "react";
import Swal from "sweetalert2";
import axios from "axios";

import { LOGIN_URL } from "../../../constants/constantUrls";
import { API_SET_PASSWORD } from "../../../constants/apiRoutes";

// COMMON
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import * as S from "./style";

class ChangePassword extends Component {
  state = {
    errors: {},
    requiredFields: {
      password: "",
      confirmPassword: "",
    },
  };

  handleInput = (e) => {
    const { requiredFields } = this.state;
    requiredFields[e.target.name] = e.target.value;
    this.setState({ requiredFields });
  };

  passwordValidate = () => {
    const { errors, requiredFields } = this.state;
    const { password, confirmPassword } = requiredFields;

    // regex for password validation
    const passwordPattern = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/g
    );

    if (!passwordPattern.test(password)) {
      errors.password =
        "* requires min. 8 characters, 1 uppercase character and 1 number";
    }

    if (password !== confirmPassword) {
      errors.confirmPassword = "* Passwords do not match";
    } else if (errors.confirmPassword === "* Passwords do not match") {
      delete errors.confirmPassword;
    }

    this.setState({ errors });
    const errorsArr = Object.keys(errors);
    return errorsArr.length < 1;
  };

  handleBlur = (e) => {
    const { errors } = this.state;
    if (e.target.value === "") {
      errors[e.target.name] = "* This input is required";
    } else if (errors[e.target.name]) {
      delete errors[e.target.name];
    }

    this.setState({ errors });

    if (e.target.name === "confirmPassword" || e.target.name === "password") {
      this.passwordValidate();
    }
  };

  handleSubmitReset = (e) => {
    e.preventDefault();
    const {
      requiredFields: { password },
    } = this.state;
    const { token } = this.props;

    if (password && this.passwordValidate()) {
      const errors = {};
      this.setState({ errors }, () => {
        axios
          .post(API_SET_PASSWORD, { password, token })
          .then(() => {
            const { history } = this.props;
            Swal.fire({
              title: "Password updated!",
              type: "success",
            }).then(() => history.push(LOGIN_URL));
          })
          .catch((err) => {
            const { showMessage } = this.props;
            showMessage(err);
          });
      });
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <>
        <S.Message>Reset your password</S.Message>
        <Paper elevation={0} style={{ padding: "1rem", textAlign: "center" }}>
          <S.Row>
            <TextField
              variant="outlined"
              type="password"
              name="password"
              id="password"
              label="New Password"
              placeholder="New Password"
              onChange={this.handleInput}
              onBlur={this.handleBlur}
              error={errors.password}
            ></TextField>
            <S.Label htmlFor="password">Password</S.Label>
            <S.ErrorMsg>{errors.password}</S.ErrorMsg>
          </S.Row>
          <S.Row>
            <TextField
              variant="outlined"
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              label="Confirm Password"
              placeholder="Confirm your password"
              onChange={this.handleInput}
              onBlur={this.handleBlur}
              error={errors.confirmPassword}
            ></TextField>
          </S.Row>
          <Button
           style={{
              marginTop: "1rem",
              borderRadius: "100px",
              Width: "164px",
              minHeight: "50px",
              fontWeight: "700",
              boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
            }}
            variant="contained"
            color="secondary"
            onClick={this.handleSubmitReset}
            type="button"
            >
            Change Password
          </Button>
        </Paper>
      </>
    );
  }
}

export default ChangePassword;
