import React from 'react';

const Preview = ({ width, color }) => (
  <svg
    width={width}
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 15.625C14.2259 15.625 15.625 14.2259 15.625 12.5C15.625 10.7741 14.2259 9.375 12.5 9.375C10.7741 9.375 9.375 10.7741 9.375 12.5C9.375 14.2259 10.7741 15.625 12.5 15.625Z"
      fill={color || 'black'}
    />
    <path
      d="M23.9668 11.6504C22.6748 9.65234 20.9985 7.95508 19.1196 6.7417C17.041 5.39795 14.7461 4.6875 12.4834 4.6875C10.4072 4.6875 8.36572 5.28076 6.41552 6.45068C4.42675 7.64355 2.625 9.38623 1.06006 11.6299C0.883388 11.8834 0.78609 12.1838 0.780526 12.4928C0.774962 12.8018 0.861382 13.1055 1.02881 13.3652C2.31836 15.3833 3.97802 17.083 5.82763 18.2798C7.91015 19.6289 10.1514 20.3125 12.4834 20.3125C14.7642 20.3125 17.064 19.6079 19.1338 18.2754C21.0117 17.0659 22.6846 15.3623 23.9717 13.3477C24.1333 13.0939 24.2188 12.7992 24.2179 12.4983C24.2171 12.1975 24.1299 11.9032 23.9668 11.6504ZM12.5 17.1875C11.5729 17.1875 10.6666 16.9126 9.89576 16.3975C9.12491 15.8824 8.5241 15.1504 8.16931 14.2938C7.81453 13.4373 7.7217 12.4948 7.90257 11.5855C8.08343 10.6762 8.52988 9.841 9.18543 9.18544C9.84099 8.52988 10.6762 8.08344 11.5855 7.90257C12.4948 7.7217 13.4373 7.81453 14.2938 8.16932C15.1504 8.5241 15.8824 9.12491 16.3975 9.89576C16.9126 10.6666 17.1875 11.5729 17.1875 12.5C17.1861 13.7428 16.6918 14.9342 15.813 15.813C14.9342 16.6918 13.7428 17.1861 12.5 17.1875Z"
      fill={color || 'black'}
    />
  </svg>
);

export default Preview;
