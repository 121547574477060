// React
import React, { useState, useEffect, useRef } from "react";

// Styled Components
import { Main, Book } from "./style";
import FileUploader from "../../Common/FileUploader";
import { CardScroll } from "../../../NewComponents/Albums/CardScroll/CardScroll";
import ReactGA from "react-ga";
import CallToActionButton from "../../Common/GroupComponents/Buttons/CallToActionButton";

import Groups from "@material-ui/icons/People";
import Friends from "@material-ui/icons/EmojiPeople";
import Stories from "@material-ui/icons/MenuBook";
import Camera from "@material-ui/icons/PhotoCamera";

import * as C from "../../../constants/constantUrls";
import history from "../../../history";
import Add from "@material-ui/icons/Add";
import PublicAlbums from "./publicAlbums";

import {
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Fade,
  Collapse,
  Zoom,
  Grid,
  Modal,
} from "@material-ui/core";
import imageCompression from "browser-image-compression";
import Close from "@material-ui/icons/CancelOutlined";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { album, memories } from "../../../api";
import folder from "../../../assets/folder.png";
import SocialShare from "../../../Components/Common/SocialShare";

const fileTypes = ["JPG", "PNG", "GIF"];
export default (props) => {
  const [file, setFile] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [showCreateAlbumModal, setShowCreateAlbumModal] = useState(false);
  const [albumName, setAlbumName] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [coverImagePath, setCoverImagePath] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const imageInput = useRef();

  const handleChange = (file) => {
    setFile(Array.from(file));
    // console.log(file);
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    getAlbums();
  }, []);

  const getAlbums = async () => {
    const { data } = await album.getUserAlbums({});
    if (data) {
      const createdAtNewest = data
        .slice()
        .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
      setAlbums(createdAtNewest);
    }
  };

  const directUploadToGoogle = async (e) => {
    // const userId = createForOther ? uploaderId : user.id;
    setIsUploading(true);
    const { files } = e.target;
    const allFiles = Array.from(files);
    const compressionOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    allFiles.map(async (image) => {
      const compressedImage = await imageCompression(image, compressionOptions);

      const {
        data: { generatedName } = {},
        error,
      } = await memories.uploadImage({
        image: compressedImage,
        userId: props.id,
      });

      const imgUrl = URL.createObjectURL(compressedImage);
      setCoverImage(imgUrl);
      setCoverImagePath(generatedName);
      setIsUploading(false);
    });
  };

  const postAlbum = async () => {
    const { data } = await album.postAlbum({
      album: {
        name: albumName,
        cover: {
          imagePath: coverImagePath,
        },
      },
    });
    if (data) {
      setShowCreateAlbumModal(false);
      props.history.push(`/album/${data?._id}`);
    }
  };

  return (
    <Main>
     
      {/* // Create Album Modal */}
      <Modal
        open={showCreateAlbumModal}
        onClose={() => setShowCreateAlbumModal(false)}
        id="create-group-popup"
      >
        <Paper
          elevation={1}
          style={{
            position: "absolute",
            textAlign: "center",
            backgroundColor: "",
            color: "",
            left: "50%",
            transform: "translate(-50%, -50%)",
            top: "50%",
            maxHeight: "90vh",
            maxWidth: window.innerWidth < 900 ? "80%" : "50%",
            width: "90%",
            borderRadius: "2rem",
            overflow: "scroll",
            paddingLeft: window.innerWidth < 600 ? "1rem" : "3rem",
            paddingRight: window.innerWidth < 600 ? "1rem" : "3rem",
          }}
        >
          <div style={{ position: "absolute", top: "20px", right: "20px" }}>
            <Close
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={() => setShowCreateAlbumModal(false)}
            />
          </div>
          <Typography
            variant="h4"
            color="primary"
            align="left"
            gutterBottom
            style={{ marginTop: "2rem" }}
          >
            Create Album
          </Typography>

          <Typography variant="body1" align="left" gutterBottom>
            What would you like to call this album?
          </Typography>

          <TextField
            id="album-title"
            value={albumName}
            onChange={(text) => setAlbumName(text.target.value)}
            variant="outlined"
            placeholder="Title your album"
            inputProps={{ maxLength: 20 }}
            // label="Story Title"
            fullWidth
            style={{}}
          />
          {/* <div style={{ marginTop: "2rem" }}>
            <img src={folder} style={{}} /> */}
          {/* <FileUploader
              handleChange={directUploadToGoogle}
              name="file"
              types={fileTypes}
              maxSize={200000}
              multiple={true}
            /> */}
          {/* </div> */}
          {/* To do hook up to file uploader */}
          {/* <Typography>
            Drag your photos here to start uploading
            <br></br>- OR -
          </Typography>
          <CallToActionButton
            variant="contained"
            color="secondary"
            title="Choose Photos"
            // clickFunction={async () => await postAlbum()}
          ></CallToActionButton> */}

          <Typography
            variant="h5"
            color="primary"
            align="left"
            gutterBottom
            style={{ marginTop: "2rem" }}
          >
            Pick Cover Photo
          </Typography>
          <Typography
            variant="body1"
            align="left"
            gutterBottom
            style={{ marginBottom: "2rem" }}
          >
            Choose a photo to be the main picture of your photo album.
          </Typography>
          <Grid container id="cover-photo-div" style={{}}>
            <Grid item xs={12}>
              <input
                ref={imageInput}
                accept="image/*"
                type="file"
                onChange={directUploadToGoogle}
                style={{ display: "none" }}
                multiple={false}
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection:
                    window.innerWidth < 900 ? "column-reverse" : "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-around",
                  marginLeft: "0 auto",
                  marginRight: "0 auto",
                }}
              >
                <CallToActionButton
                  clickFunction={() => imageInput.current.click()}
                  variant="contained"
                  title="Pick Cover Photo"
                  style={{
                    minHeight: "80px",
                    borderRadius: "100px",
                  }}
                >
                  {/* <AddAPhotoIcon style={{ marginBottom: '1rem' }} />  */}
                </CallToActionButton>
                {isUploading && (
                  <CircularProgress
                    color="secondary"
                    width={200}
                    height={200}
                  />
                )}
                {coverImage && !isUploading && (
                  <img
                    src={coverImage}
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: "1rem",
                      objectFit: "cover",
                    }}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          {/* <Typography
            variant="h5"
            color="primary"
            align="left"
            gutterBottom
            style={{ marginTop: "2rem" }}
          >
            Collaborate With Friends{" "}
          </Typography>
          <Typography
            variant="body1"
            align="left"
            gutterBottom
            style={{ marginBottom: "2rem" }}
          >
            Building albums is a lot easier when we do it together. Every friend
            or family member you add here will be able to see all the photos in
            this album and add their own photos.{" "}
          </Typography> */}
          {/* To do Connect Friends To Album */}
          {/* <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Add friends' emails"
                placeholder="friend@email.com"
                helperText={
                  "Tip: Add multiple friends by seperating their emails with a comma"
                }
                variant="outlined"
                className="addFriendInputField"
                // value={newEmail}
                // onFocus={() => setInputFocused(true)}
                // onBlur={() => setInputFocused(false)}
                // onChange={(e) => setNewEmail(e.target.value)}
              >
                Enter a name
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                className="addFriendButton"
                // disabled={waitingToAdd}
                // onClick={() => addFriendFunc(newEmail)}
                style={{
                  minHeight: "50px",
                  fontWeight: "700",
                  boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                }}
              >
                Add friend
              </Button>
            </Grid>
          </Grid> */}
          {/* <Grid item xs={12}>
            <Typography align="center" style={{ marginBottom: "1rem" }}>
              - OR -
            </Typography>
            <Typography>
              Click the button below to copy your unique sign-up link to share
              with your loved ones, or share through social media platforms
            </Typography>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                // onClick={() => {
                //   if (navigator.share) {
                //     navigator.share({
                //       title: "Iternal",
                //       text: "Invite loved ones to Iternal",
                //       url: inviteLink,
                //     });
                //   } else {
                //     copyInviteLinkFunc(inviteLink);
                //   }
                // }}
                style={{
                  marginTop: "2rem",
                  // borderRadius: "100px",
                  minWidth: "164px",
                  minHeight: "50px",
                  fontWeight: "700",
                  boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                }}
              >
                Click to Copy Link
              </Button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <SocialShare
                // link={inviteLink}
                // messageBody={
                //   "Hey! I'd like to share my memories with you! To sign up to Iternal, click this link"
                // }
                // messageTitle={"I'd like to share my memories with you"}
                />

            </div>
          </Grid> */}
          {/* </div> */}
          <div>
            <Typography
              variant="h5"
              color="primary"
              align="left"
              gutterBottom
              style={{ marginTop: "2rem" }}
            >
              And You're Done!
            </Typography>
            <Typography
              variant="body1"
              align="left"
              gutterBottom
              style={{ marginBottom: "2rem" }}
            >
              Click the button to create your new photo album.
            </Typography>
          </div>
          <div style={{ marginBottom: "2rem" }}>
            <CallToActionButton
              variant="contained"
              color="secondary"
              startIcon={
                <Add style={{ fontSize: "1.5rem", marginRight: "-0.3rem" }} />
              }
              title="Create Album"
              clickFunction={async () => await postAlbum()}
              disabled={albumName.length === 0}
            ></CallToActionButton>
          </div>
        </Paper>
      </Modal>

      <Typography
        align="center"
        variant="h3"
        gutterBottom
        style={{
          marginBottom: "2rem",
          color: "white",
          fontSize: window.innerWidth < 1024 ? "3rem" : "4rem",
        }}
      >
        Photo Albums
      </Typography>
      <Grid
        style={{
          paddingLeft: window.innerWidth < 600 ? "0rem" : "4rem",
          paddingRight: window.innerWidth < 600 ? "0rem" : "4rem",
        }}
      >
        <div
          // elevation={3}
          style={{
            maxWidth: "1000px",
            margin: "0 auto",
            textAlign: "center",
            borderRadius: "20px",
            background: "white",
            overflowX: "",
            overflowY: "scroll",
            marginBottom: window.innerWidth < 1024 ? "8rem" : "2rem",
          }}
        >
          <div style={{}}>
            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                marginTop: "2rem",
                paddingLeft: window.innerWidth < 600 ? "1rem" : "3rem",
                paddingRight: window.innerWidth < 600 ? "1rem" : "3rem",
                marginBottom: "1rem",
              }}
            >
              Welcome to your Iternal Photo Albums portal! Photo Albums allow
              you and your friends to add multiple photos to one designated
              area. Here you can create, access and edit all of your photo
              albums in one neat place.
            </Typography>

            {/* <Typography
              variant="body1"
              style={{
                textAlign: "left",
                marginTop: "1rem",
                paddingLeft: window.innerWidth < 600 ? "1rem" : "3rem",
                paddingRight: window.innerWidth < 600 ? "1rem" : "3rem",
                paddingBottom: "1rem",
                display: window.innerWidth < 500 ? "none" : "none",
              }}
            >
              Photo Albums allow you and your friends to add multiple photos to
              one designated area. Tell the bigger story of the album or add
              details to each individual photo; the choice is yours.
            </Typography> */}

            {/* <CallToActionButton
          variant="contained"
          color="secondary"
          title="Create New Album"
         
          clickFunction={async () => await postAlbum()}
        >
          Create New Album
        </CallToActionButton> */}

            <CallToActionButton
              variant="contained"
              color="secondary"
              title="Create Album"
              startIcon={
                <Add style={{ fontSize: "1.5rem", marginRight: "-0.3rem" }} />
              }
              clickFunction={() => setShowCreateAlbumModal(true)}
            ></CallToActionButton>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                overflowY: "scroll",
                flexWrap: "wrap",
                justifyContent: "center",
                maxHeight: "45vh",
                marginTop: "4rem",
                // marginLeft: window.innerWidth < 600 ? "0rem" : "1.5rem",
                marginBottom: "0rem",
           
              }}
            >
            
              {albums.map((album) => (
                <div
                  style={{
                    padding: "0.2rem",
                    minWidth: "230px",
                    height: "265px",
                    background: "#F2F2F1",
                    marginLeft: "2rem",
                    marginRight: "1rem",
                    boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                    marginBottom: "2rem",
                  }}
                >
                  <div style={{ padding: "1rem" }}>
                    <CardScroll
                      text={album.name}
                      cover={album?.cover}
                      albumId={album?._id}
                    />
                    <div style={{ height: "100%" }}>
                      <Typography
                        color="primary"
                        variant="body1"
                        align="center"
                      >
                        {" "}
                        {album.name}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Typography
              variant="h4"
              align="left"
              color="primary"
              style={{
                textAlign: "left",
                paddingLeft: window.innerWidth < 600 ? "1rem" : "3rem",
                paddingRight: window.innerWidth < 600 ? "1rem" : "3rem",
                marginBottom: "2rem",
              }}
            >
              Nostalgia Vaults
            </Typography>
            <Typography
              align="left"
              variant="body1"
              style={{
                marginTop: "1rem",
                marginLeft: window.innerWidth < 600 ? "1rem" : "3rem",
                marginRight: window.innerWidth < 600 ? "1rem" : "3rem",
              }}
            >
   Here are some of our example photo albums to help take you down memory lane.
            </Typography>
            <div>
              <PublicAlbums />
            </div>
          </div>
        </div>
      </Grid>
    </Main>
  );
};
