import styled from 'styled-components';

export const FriendsListStyles = styled.div`
  width: 100%;
  .paperStyles {
    padding: 1rem;
    ${'' /* min-width: 220px; */}
  }

  .addFriendButton {
    height: 100% !important;
    max-height: 3.5rem;
    line-height: 1.25;
  }

  .displayBlock {
    display: block !important;
  }

  .relationshipsSection {
    // background: rgba(0, 0, 0, 0.025);
    border-radius: 0.5rem;
    margin: 0.5rem auto !important;
    padding: 0.25rem;
    .relationshipChip {
      margin: 0.4rem 0.25rem;
    }
  }

  .manageFriendButtons {
    margin: 0.25rem 0.2rem;
  }
`;
