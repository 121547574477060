import React from 'react';
import Button from '@material-ui/core/Button';

export default function CallToActionButton({ 
  title,
  clickFunction=()=>{},
  customColor='',
  customFontColor='',
  disabled=false,
  variant='contained',
  startIcon="",
  endIcon=""
}) {
  // const decideColor = () => {
  //   if (customColor.length > 0) {

  //   } else {
  //     return '#1D6A91'
  //   }
  // }
  const convertHSLToHSLA = (value) => {
    const sep = value.indexOf(",") > -1 ? "," : "";
    const hsl = value.substr(4).split(")")[0].split(sep);
    
    let h = hsl[0],
        s = hsl[1],
        l = hsl[2];
    
    const hsla = `hsla(${h} ${s} ${l} / 0.2)`;
    return (hsla)
  }
  return (
    <div id="call-to-action-button">
      <Button
        variant={variant}
        onClick={() => clickFunction()}
        color="secondary"
        startIcon={startIcon}
        endIcon={endIcon}
        style={{
          marginTop: '1rem',
          borderRadius: '100px',
          // backgroundColor: variant === "outlined" ? convertHSLToHSLA(customColor) : "inherit" ,
          // color: variant === "outlined" ? customColor: 'white',
          minWidth: '164px',
          minHeight: '50px',
          fontWeight:"700",
          boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',
          // boxShadow: 'rgba(0, 0, 0, 0.95) 0px 25px 20px -20px'
          
        }}
        disabled={disabled}
      >
        {title}
      </Button>
    </div>
  );
}