import Swal from 'sweetalert2';

// REF: https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
const copyTextToClipboard = str => {
  try {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    Swal.fire('Done!', 'The link has been copied to your clipboard', 'success');
  } catch (error) {
    Swal.fire('Error!', 'error during coping the link', 'error');
  }
};

export default copyTextToClipboard;
