import React from 'react';

const X = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 17 18"
    fill={props.color || `var(--neutral-slate)`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="-1"
      x2="21.1251"
      y2="-1"
      transform="matrix(0.681508 0.731811 -0.681508 0.731811 1 1.68292)"
      stroke="#E8529B"
      strokeWidth="2"
    />
    <line
      y1="-1"
      x2="21.1251"
      y2="-1"
      transform="matrix(0.681509 -0.731809 0.681509 0.731809 2.01807 16.9871)"
      stroke="#E8529B"
      strokeWidth="2"
    />
  </svg>
);

export default X;
