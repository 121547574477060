import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  Button,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { FaFacebook } from "react-icons/fa";

import Close from "@material-ui/icons/CancelOutlined";
import CallToActionButton from "../../GroupComponents/Buttons/CallToActionButton";

export default function LogInModal({ 
  username='',
  switchFunc=()=>{}, 
  email='',
  setEmail=()=>{},
  password='',
  setPassword=()=>{},
  logInFunc=()=>{},
}) {
  /* Password Visibility */
  const [passVisible, setPassVisible] = useState(false);

  return (
    <Paper
      id="signup-modal"
      style={{
        position: "absolute",
        minWidth: window.innerWidth < 600 ? "90%" : "600px",
        backgroundColor: "white",
        padding: "0rem",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "2rem",
        maxHeight: "90%",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Grid
        style={{
          backgroundColor: "#0DA7A7",
          display: "flex",
          justifyContent: "center",
          padding: "2rem",
          borderRadius: "2rem 2rem 0rem 0rem",
        }}
      >
        <img
          src={require("../../../../assets/onboarding/logo-white.png")}
          style={{ maxWidth: "100%", width: "120px" }}
        />
      </Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-2rem",
          marginRight: "-2rem",
          marginRight: window.innerWidth < 500 ? "1rem" : "2rem",
          marginLeft: window.innerWidth < 500 ? "1rem" : "2rem",
        }}
      >
        <Typography
          gutterBottom
          color="primary"
          style={{
            textAlign: "center",
            marginTop: "3rem",
            fontSize: "1.5rem",
            fontWeight: "900",
          }}
        >
          Sign in to view {username} Stories
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        id="login-link-signup"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="body2">Don't have an account?</Typography>
        <Button
          // ref={buttonRef}
          size="small"
          variant="text"
          onClick={() => switchFunc()}
          style={{ textDecoration: "underline" }}
        >
          Sign Up
        </Button>
      </Grid>
      <FormControl fullWidth variant="outlined">
        <Grid container id="signup-boxes" style={{}}>
          <Grid
            item
            xs={12}
            id="email-login"
            style={{
              paddingLeft: window.innerWidth < 500 ? "1rem" : "2rem",
              paddingRight: window.innerWidth < 500 ? "1rem" : "2rem",

              marginTop: "1rem",
            }}
          >
            <Typography
              variant="body2"
              gutterBottom
              color="primary"
              style={{ textAlign: "left", fontWeight: "bold" }}
            >
              Email
            </Typography>
            <TextField
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              placeholder="Enter your email"
              fullWidth
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid
            xs={12}
            item
            id="password-signup"
            style={{
              paddingLeft: window.innerWidth < 500 ? "1rem" : "2rem",
              paddingRight: window.innerWidth < 500 ? "1rem" : "2rem",

              marginTop: "1rem",
            }}
          >
            <Typography
              variant="body2"
              gutterBottom
              color="primary"
              style={{ textAlign: "left", fontWeight: "bold" }}
            >
              Password
            </Typography>
            <TextField
              id="password"
              value={password}
              type={passVisible ? '' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              placeholder="Choose a password"
              fullWidth
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid
            xs={12}
            item
            id="showpassword-signup"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "2rem",
            }}
          >
            <FormControlLabel
              style={{
                color: "#0DA7A7",
                fontSize: "0.5rem",
              }}
              value="Start"
              control={
                <Checkbox
                  style={{
                    color: "#0DA7A7",
                  }}
                  checked={passVisible}
                  onClick={() => setPassVisible(!passVisible)}
                />
              }
              label="Show Password"
              labelPlacement="start"
            />
          </Grid>
          <Grid
            item
            xs={12}
            id="create-button-signup"
            style={{ 
              display: "flex", 
              justifyContent: "center",
              marginBottom: "2rem", 
            }}
          >
            <CallToActionButton 
              title="Sign In" 
              clickFunction={() => logInFunc()}
              disabled={
                email?.length === 0 ||
                password?.length === 0
              }
            />
          </Grid>

          {/* <Grid
            item
            xs={12}
            id="google-signup"
            style={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
          >
            <GoogleLogin
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "14px",
                padding: "10px",
                borderRadius: "2px",
                fontFamily: "OpenSans",
                fontWeight: "400",
                textAlign: "left",
                textTransform: "none",
                height: "41px",
              }}
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Sign in with Google"
              onSuccess={(e) => this.responseGoogle(e)}
              onFailure={(e) => this.responseGoogle(e)}
              cookiePolicy="single_host_origin"
            />
          </Grid> */}
        </Grid>
      </FormControl>
    </Paper>
  );
}
