import styled from 'styled-components';

export const StorybookDesktopWrapper = styled.div`
  padding: 20px;
  max-width: 220px;
  height: 100vh;
  background: linear-gradient(135deg, #e26713, #e0a614);
`;

export const StorybookMobileWrapper = styled.div`
  padding: 20px;
  max-width: 450px;
  height: 400px;
  background: linear-gradient(135deg, #e26713, #e0a614);
`;

export const SignOutButtonWrapper = styled.div`
  button {
    background: none;
    color: white !important;
  }
  .MuiSkeleton-root {
    text-align: right !important;
    margin-left: 60%;
  }
  text-align: right !important;
  margin-bottom: 0.5rem;
`;

export const UserCardBase = styled.div`

  ${'' /* border: 1px solid white; */}
  ${'' /* padding: 1rem; */}
  margin: 0 auto;
  ${'' /* marginBottom: 2rem; */}
  width: 100%;
  ${'' /* max-width: 450px; */}
  border-radius: 0.5rem !important;
  color: blue;
  ${'' /* background-image: linear-gradient(
    to bottom right,
    rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.08) */}
  );
`;

export const UserAvatarStyles = styled.div`
  .mobile {
    display: flex !important;
  }
  .MuiAvatar-root,
  .MuiSkeleton-root {
    width: 80px !important;
    height: 80px !important;
    margin: 0rem auto 0.25rem;
    font-size: 2rem;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    transition: all 0.25 ease;
  }
  .MuiSkeleton-root {
    opacity: 0.75;
  }
`;

export const UsernameStyles = styled.div`
  h5 {
    color: white;
    word-break: break-word;
    font-size: 1.5rem;
    margin: 0rem 0rem 0.5rem;
  }
  .MuiSkeleton-root {
    height: 2.25rem;
    margin: 0rem 0rem 0.5rem;
    opacity: 0.5;
  }
`;

export const SecondaryButtonsWrapper = styled.div`
  text-align: center;
  button {
    margin: 0rem auto 0.5rem !important;
    color: white !important;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  .MuiSkeleton-root {
    margin: -1rem auto 0.1rem !important;
    width: 100% !important;
    opacity: 0.75;
  }
`;

export const AddMemoryButtonWrapper = styled.div`
  .MuiSkeleton-root {
    margin: -1.25rem auto -0.75rem auto;
  }
`;
