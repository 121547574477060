import styled, { keyframes } from 'styled-components';
import { IternalTheme } from '../../../--Theme/IternalThemeLight';

export const IconWrapper = styled.div`
  svg {
    fill: ${IternalTheme.palette.secondary.light};
  }
  margin: 0 auto;
  text-align: center !important;
`;

export const ButtonStyle = {
  // background: '#0A2156',
  background: '#0A2156',

  padding: '1rem',
  width: '100%',
  borderRadius: '0.5rem',
  boxShadow: '0rem 0rem 3px 1px #0A2156',
  marginBottom:"1rem",
  border: '1px solid white',
  minHeight: '130px',
};



export const SpinningGenerator = styled.div`
  b {
    float: left;
    overflow: hidden;
    text-align: left;
    position: relative;
    height: 45px;
    width: 95%;
    margin: 0.5rem;
  }
  b:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(10, 33, 86, 1), rgba(255,255,255,0),rgba(255,255,255,0),rgba(10, 33, 86, 1) 100%);
    width: 100%;
    height: 45px;
  }
  span {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    ${'' /* color: ${IternalTheme.palette.secondary.dark1}; */}
    color: white;
    font-size: 1.2rem;
    top: 0;
    left: 0;
    animation: move 1s;
    animation-iteration-count: 2;
  }

  @keyframes move {
    0%  { top: 0px; }
    10% { top: -15px; }
    20% { top: -30px; }
    30% { top: -45px; }
    40% { top: -60px; }
    50%  { top: -75px; }
    60% { top: -90px; }
    70% { top: -105px; }
    80% { top: -120px; }
    90% { top: -135px; }
    100% { top: -150px; }
  }
`;

export const PromptTextStyle = {
  color: 'white',
  padding: '0.1rem',
  textAlign: 'left',
  fontSize: '1.15rem',
  lineHeight: '1.25',
};

export const AfterPromptTextStyle = {
  color: 'white',
  padding: '0.1rem',
  textAlign: 'left',
  fontSize: '1.25rem',
  lineHeight: '1.25',
};

export const CategoryTextStyle = {
  color: 'white',
  textAlign: 'left',
  fontSize: '0.80rem',
  padding: '0.1rem',
  textTransform: 'uppercase',
  letterSpacing: '0.15rem',
  opacity: '0.75',
};

export const SpinAgainTextStyle = {
  color: 'white',
  textAlign: 'center',
  fontSize: '0.7rem',
  padding: '0.1rem',
  textTransform: 'uppercase',
  letterSpacing: '0.1rem',
  opacity: '0.75',

  // position: 'relative',
  // top: '50%',
  // left: "50%", 


};

