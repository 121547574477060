import styled from 'styled-components';

export const ReactionsSectionWrapper = styled.div`
  padding: 1.5rem;
  margin: 0.5rem;
  background: rgba(251, 251, 251, 1);
  border-radius: 0.25rem;

  .title {
    font-weight: bold;
  }

  .subtitle {
    opacity: 0.75;
  }

  button {
    width: 97%;
    padding: 0.25rem;
    margin: 0.25rem;
  }

  .nostalgicIcon {
    width: 1.5rem;
    height: 1.5rem;
    path {
      fill: currentColor !important;
    }
  }
  .movingIcon {
    width: 1.5rem;
    height: 1.5rem;
    path {
      fill: currentColor !important;
    }
  }

  .counterVisible {
    opacity: 1;
    transition: 0.15s all ease;
  }
  .counterHidden {
    opacity: 0;
    transition: 0.15s all ease;
  }
`;
