import React from 'react';
// import PropTypes from "prop-types";
import * as S from './ProfileMenu.style';
import Icon from './Icon/Icon';
import {
  BASICS_URL,
  ABOUT_URL,
  THISORTHAT_URL,
  MEMORIES_URL,
} from '../../constants/constantUrls';

const ProfileMenu = props => (
  <S.Menu>
    <nav>
      <S.StyledLink
        className="notActive"
        activeClassName="active"
        to={BASICS_URL}
      >
        <Icon icon="basics" height="2.5em" width="2.5em" />
        <p>Basics</p>
      </S.StyledLink>

      <S.StyledLink
        className="notActive"
        activeClassName="active"
        exact
        to={ABOUT_URL}
      >
        <Icon icon="about" height="2.5em" width="2.5em" />
        <p>About</p>
      </S.StyledLink>

      <S.StyledLink
        className="notActive"
        activeClassName="active"
        to={THISORTHAT_URL}
      >
        <Icon icon="thisorthat" height="2.5em" width="2.5em" />
        <p>This/That</p>
      </S.StyledLink>

      <S.StyledLink
        className="notActive"
        activeClassName="active"
        to={MEMORIES_URL}
      >
        <Icon icon="memories" height="2.5em" width="2.5em" />
        <p>Memories</p>
      </S.StyledLink>
    </nav>
  </S.Menu>
);

// ProfileMenu.defaultProps = {};

// ProfileMenu.propTypes = {};

export default ProfileMenu;
