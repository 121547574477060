import styled from 'styled-components';
import { breakpoints, devices } from '../../../constants/breakPoints';

// solve moving the create memory option temporary
export const MainOptionsWrapper = styled.section`
  display: ${({ type }) => (type === 'main' ? 'flex' : 'block')};
  flex-direction: column;

  & :nth-child(4) {
    order: -1;
  }
`;

export const LoadWrapper = styled.section`
  max-width: 100rem;

  @media ${breakpoints.laptop} {
    padding-top: 10rem;
    padding-left: 320px;
  }

  @media ${devices.tablet} and (orientation: portrait) {
    padding-top: 5rem;
  }

  @media ${devices.mobile} {
    padding-top: 4rem;
  }
`;

export const Wrapper = styled.section`
  ${'' /* background: linear-gradient(90deg, #0A2156, #1d6a91,  #0bafaa); */}
  display: flex;
  flex-direction: column;
  max-width: 100rem;
  margin: 0 auto;
  padding: 1rem 0.5rem 1rem;
  ${'' /* height: 100vh; */}

  .screen-title {
    font-size: 28px;
    text-transform: none;
    color: #1a1a1a;
    margin: 0.5rem 0rem 1em 0em;
  }

  @media ${breakpoints.laptop} {
    /* padding: 2rem 2rem 8rem 250px; */
    padding: 1rem 4rem 1rem 320px;
    .screen-title {
      font-size: 36px;
    }
  }

  @media ${devices.tablet} and (orientation: portrait) {
    padding-top: 4rem;
    padding: 3rem 2rem 4rem 2rem;
    .noActivityBlock {
      margin-top: 40px;
    }
  }
`;

// copyed from here :"D https://leaverou.github.io/bubbly/
export const Message = styled.span`
  position: relative;
  border: ${({ type }) =>
    type === 'user' ? '2px solid #0bafaa' : '2px solid #e26713'};
  background-color: ${({ type }) => (type === 'user' ? '#0bafaa' : '#e26713')};
  border-radius: 0.4em;
  padding: 0.5rem;
  min-width: 100px;
  min-height: 40px;
  display: flex;
  align-items: center;
  /* box-shadow: ${({ type }) =>
    type === 'user' ? 'var(--primary-shadow)' : 'var(--primary-shadow)'}; */
  color: white;
  ::after {
    /* content: ''; */
    position: absolute;
    bottom: 0;
    left: ${({ type }) => type === 'bot' && '5%'};
    right: ${({ type }) => type === 'user' && '5%'};
    width: 0;
    height: 0;
    border: 0.625em solid transparent;
    border-top-color: ${({ type }) =>
      type === 'bot' ? 'var(--primary)' : 'var(--primary)'};
    border-bottom: 0;
    border-left: ${({ type }) => type === 'bot' && '0'};
    border-right: ${({ type }) => type === 'user' && '0'};
    margin-left: -0.312em;
    margin-bottom: -0.625em;
  }
`;

export const MessageContainer = styled.section`
  display: flex;
  align-items: flex-end;
  justify-content: 'flex-end';
  padding-bottom: ${({ starterConv }) => (starterConv ? '3rem' : '2rem')};
  flex-direction: ${({ type }) => (type === 'user' ? 'row-reverse' : 'row')};
`;

export const Image = styled.img`
  height: 25px;
  margin: ${({ type }) => (type === 'user' ? '0 0 0 1rem' : '0 1rem 0.4rem 0')};
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 1rem;
  width: 100%;
  transition: all ease-in 0.2s;
  cursor: pointer;

  :hover,
  :active {
    transform: scale(1.02);
  }
`;

export const OptionButton = styled.div`
  border: 2px solid var(--secondary-aqua);
  background-color: var(--secondary-aqua);
  border-radius: 4px;
  padding: 0.5em;
  width: 18rem;
  max-width: 75%;
  display: flex;
  flex-flow: row no-wrap;
  justify-content: space-between;
`;

export const Option = styled.span`
  color: var(--neutral-white);
  font-weight: bold;
  font-size: 0.9rem;
  padding-right: 1rem;
  width: 80%;
`;

export const Arrow = styled.span``;

export const RightSelection = styled.span`
  color: ${({ color }) => {
    if (color === 'left') {
      return '#e26713';
    }
    if (color === 'right') {
      return '#0bafaa';
    }
    return '#747373';
  }};
  line-height: 25px;
  font-size: 30px;
  width: 50%;
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

export const LeftSelection = styled(RightSelection)`
  border-right: 1px solid var(--neutral-mist);
`;

export const UpperSelectionWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid var(--neutral-mist);
  width: 100%;
`;

export const SelectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
`;

export const FinishButton = styled.button`
  font-weight: 600;
  text-transform: uppercase;
  border: solid 2px var(--primary);
  border-radius: 4px;
  font-size: 1.2rem;
  transition: 0.5s;
  padding: 0.3rem 0.5rem;
  margin: 0 auto;
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  display: flex;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  height: auto;
  ::after {
    content: '';
    position: absolute;
    width: calc(100% - 1rem);
    height: 1rem;
    border-bottom: ${({ inputActive }) =>
      inputActive
        ? '2px var(--secondary-aqua) solid'
        : '2px var(--primary-tangerine) solid'};
    bottom: 1rem;
    margin-right: 1rem;
  }
  @media ${breakpoints.laptop} {
    padding-top: 7rem;
  }
`;

export const InputChat = styled.textarea`
  border: none;
  outline: none;
  width: 100%;
  position: relative;
  align-self: flex-end;
  overflow: auto;
  resize: none;
  line-height: 1.5rem;
  height: auto;
  box-sizing: border-box;
  padding: 0.5rem;

  :focus,
  :active {
    height: auto;
  }
`;

export const Button = styled.button`
  border: none;
  background: none;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  padding-bottom: 0.5rem;
  -webkit-transition: 0.5s;
  margin: 0 auto;
  cursor: pointer;
  outline: none;
  color: var(--secondary-aqua);
  background-color: var(--neutral-white);

  :active {
    color: var(--secondary-aqua);
  }
`;

// wrapper for finish and cancel buttons
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 4rem;
`;
