import React from "react";
import { CardScroll } from "../../../NewComponents/Albums/CardScroll/CardScroll";
import { ALBUM_URL } from '../../../constants/constantUrls';

import Img1950s from "../../../assets/1950.jpeg";
import Img1960s from "../../../assets/1960.jpeg";
import Img1970s from "../../../assets/1970.jpeg";
import Img1980s from "../../../assets/1980.jpeg";
import Img1990s from "../../../assets/1990.jpeg";
import Img2000s from "../../../assets/2000.jpeg";

// Components
import { Paper, Typography, Button, Grid, Avatar } from "@material-ui/core";

export default function PublicAlbums({}) {


        const decade = ([
          {
            title: "1950s",
            link: "https://www.iternal.app/album/6284cdf499a5f500186243c8",
            picture: Img1950s,
            id: "6284cdf499a5f500186243c8",
          },
          {
            title: "1960s",
            link: "https://www.iternal.app/album/6284ceca99a5f500186243f0",
            picture: Img1960s,
            id: "6284ceca99a5f500186243f0",
          },
          {
            title: "1970s",
            link: "https://www.iternal.app/album/6284d00599a5f50018624413",
            picture: Img1970s,
            id: "6284d00599a5f50018624413",
          },
          {
            title: "1980s",
            link: "https://www.iternal.app/album/6284d0d999a5f50018624432",
            picture: Img1980s,
            id: "6284d0d999a5f50018624432",
          },
          {
            title: "1990s",
            link: "https://www.iternal.app/album/6284d14e99a5f50018624457",
            picture: Img1990s,
            id: "6284d14e99a5f50018624457",
          },
          {
            title: "2000s",
            link: "https://www.iternal.app/album/6284d1c299a5f50018624479",
            picture: Img2000s,
            id: '6284d1c299a5f50018624479',
          },
        ]);

  return (
      <div             style={{
        display: "flex",
        flexDirection: "row",
        overflowY: "scroll",
        flexWrap: 'wrap',
        justifyContent: 'center',
        maxHeight: "45vh",
        marginTop: "2rem",
        // marginLeft: window.innerWidth < 600 ? "0rem" : "1.5rem",
        marginBottom: "2rem",
      }}>
      {decade.map((decade) => (
   
   <div
     style={{
       padding: "0.2rem",
       maxWidth: "230px",
       width:"100%",
       height: "265px",
       background: "#F2F2F1",
       marginLeft: "2rem",
       marginRight: "1rem",
       boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
       marginBottom: "2rem",
       marginTop: "2rem,",
     }}
   >
     <a
       style={{ cursor: "pointer", textDecoration: "none" }}
       href={`${ALBUM_URL.replace(':albumId', decade.id)}`}
     >
       <div style={{ padding: "1rem" }}>
         <CardScroll text="1950s" cover={{imagePath:decade.picture}} albumId={decade.id} />
         <div style={{ height: "100%" }}>
           <Typography color="primary" variant="body1" align="center">
            {decade.title}
           </Typography>
         </div>
       </div>
     </a>
     </div>
     ))}
      </div>
  );
}
