import axios from 'axios';
import Swal from 'sweetalert2';

import {
  API_GIFT_MEMORY_URL,
} from '../constants/apiRoutes';

import makeError from './makeError';

const getGiftMemories = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${API_GIFT_MEMORY_URL}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const postGiftMemory = async ({ memory, recipientEmail, recipientName, options }) => {
  try {
    const { data } = await axios.post(API_GIFT_MEMORY_URL, {
      recipientEmail,
      recipientName,
      memory,
    });
    if (data) {
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const giftMemory = {
    getGiftMemories,
    postGiftMemory,
};

export default giftMemory;
