import axios from 'axios';

import { API_TAGS_URL } from '../constants/apiRoutes';

import makeError from './makeError';

// get all tags created by this user
const getAllUserTags = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${API_TAGS_URL}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

// get all tags for a specific memory
const getAllTagsMemory = async ({ memoryId, options } = {}) => {
  try {
    const { data } = await axios.get(`${API_TAGS_URL}/${memoryId}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const batchCRUDTags = async ({ memoryId, tagsArray, options } = {}) => {
  try {
    const { data } = await axios.patch(`${API_TAGS_URL}`, {
      memoryId,
      tagsArray,
    });
    if (data) {
      return data;
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const tags = {
  getAllUserTags,
  getAllTagsMemory,
  batchCRUDTags,
};

export default tags;
