import styled from 'styled-components';

export const FeaturesBlockStyles = styled.div`
  transition: all 0.25s ease !important;

  a {
    text-decoration: none !important;
    color: black !important;
  }

  .paper {
    padding: 1rem;
    min-height: 40.5vh;
    margin: 0.5rem;
  }
  .scrollButtonsContainer {
    display: flex;
    flex-direction: row;
    margin: 0rem;
    padding: 1.25rem 1.25rem 0rem 1.25rem;
    button {
      font-size: 1.25rem;
      float: left;
      width: 50%;
      margin-right: 2%;
    }
  }

  .featureContainer {
    display: flex;
    flex-direction: row;
    padding: 1.25rem 0.75rem;
    margin: 1rem;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .featureCard {
    position: relative;
    display: inline-block;
    border-radius: 0.5rem;
    margin: 0rem 0.75rem 0rem 0rem;
    padding: 0.25rem 1.25rem;
    min-width: 200px !important;
    min-height: 300px;
    text-align: center;
    transition: all 0.25s ease !important;
    :hover {
      transform: scale(1.075);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      transition: all 0.25s ease !important;
    }
    .icon {
      svg {
        position: absolute;
        float: bottom;
        width: 50%;
        right: 25%;
        bottom: 12.5rem;
        transform: scale(2.5);
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .text {
      font-size: 1.5rem;
      line-height: 1.2;
      position: absolute;
      float: bottom;
      width: 85%;
      right: 7.5%;
      bottom: 5rem;
      color: rgba(0, 0, 0, 0.7);
    }
    .CTAtext {
      position: absolute;
      float: bottom;
      width: 80%;
      right: 10%;
      bottom: 1.5rem;
    }
  }
`;
