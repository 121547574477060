import React from 'react';
// import PropTypes from "prop-types";
import * as S from './Link.style';

const Link = props => (
  <S.Link to={props.path} {...props}>
    {props.text}
  </S.Link>
);

// Link.defaultProps = {
//   path: "/"
// };

// Link.propTypes = {
//   path: PropTypes.string.isRequired,
//   text: PropTypes.string,
//   secondary: PropTypes.bool,
//   black: PropTypes.bool
// };

export default Link;
