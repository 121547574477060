// this does client side redirecting away from protected routes if not logged in

import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { LOGIN_URL } from '../../constants/constantUrls';

// COMMON
import Header from './Header/Header';

// import { Wrapper, SpinWrapper } from "./PrivateRoute.style";

const PrivateRoute = ({
  isMounted,
  Component,
  path,
  exact,
  header,
  ...rest
}) =>
  isMounted ? (
    <>
      <Route
        path={path}
        {...rest}
        render={LinkProps =>
          rest.isLoggedIn ? (
            <>
              {header && <Header {...LinkProps} {...rest} />}
              <Component {...LinkProps} {...rest} />
            </>
          ) : (
            <Redirect to={LOGIN_URL} />
          )
        }
      />
    </>
  ) : (
    <>
      <h1>Loading...</h1>
    </>
  );

export default PrivateRoute;
