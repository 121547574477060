import React from "react";
import { Paper, Typography, Grid } from "@material-ui/core";
import Close from "@material-ui/icons/CancelOutlined";

import CallToActionButton from "../../Common/GroupComponents/Buttons/CallToActionButton";

export default function Warning({
  warningName = "",
  warningText = "",
  warningPicture = "",
  postiveButtonText = "",
  positiveStateButton = "",
  negativeButtonText = "",
  negativeStateButton = "",
  clickFunction = () => {},
  positiveFunction = () => {},
  negativeFunction = () => {},
  
}) {
  return (
    <Paper
      elevation={1}
      style={{
        position: "absolute",
        textAlign: "center",
        backgroundColor: "",
        color: "",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      width: window.innerWidth < 1174 ? "80%" : "30%",
        maxHeight:"80%",
        borderRadius: "2rem",
        overflow: "scroll",
        padding: window.innerWidth < 1345 ? "2rem" :"3rem",
      }}
    >
      <div style={{ position: "absolute", top: "20px", right: "20px" }}>
        <Close
          color="primary"
          style={{ cursor: "pointer" }}
          onClick={() => clickFunction()}
        />
      </div>
      <Typography variant="h4" color="primary" align="left" gutterBottom>
        {warningName}
      </Typography>
      <Typography
        variant="body1"
        align="left"
        gutterBottom
        style={{ marginBottom: window.innerHeight < 800 ? "0rem" :"1rem" }}
      >
        {warningText}
      </Typography>

      <div style={{display: window.innerHeight < 700 ? "none" : "inline-block"}}>
        <img
          src={warningPicture}
          style={{ maxWidth: "80%", height: "300px", objectFit: "contain" }}
        />
      </div>
      <Grid
        container
        spacing={3}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item>
          <CallToActionButton
            variant="contained"
            title={postiveButtonText}
            clickFunction={() => positiveFunction()}
          />
        </Grid>
        <Grid item>
          <CallToActionButton
            variant="outlined"
            title={negativeButtonText}
            clickFunction={() => negativeFunction()}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
