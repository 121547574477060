import React from 'react';

const Share = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="3.0306" cy="9.71948" rx="3.0306" ry="3.23999" fill="#E8529B" />
    <ellipse
      cx="16.1634"
      cy="3.23999"
      rx="3.0306"
      ry="3.23999"
      fill="#E8529B"
    />
    <ellipse
      cx="16.1634"
      cy="16.1999"
      rx="3.0306"
      ry="3.23999"
      fill="#E8529B"
    />
    <line
      y1="-0.569528"
      x2="13.7121"
      y2="-0.569528"
      transform="matrix(0.903244 -0.429127 0.383834 0.923402 3.03076 10.2041)"
      stroke="#E8529B"
      strokeWidth="1.13906"
    />
    <line
      y1="-0.569528"
      x2="13.8236"
      y2="-0.569528"
      transform="matrix(0.903234 0.429149 -0.383855 0.923393 2.02026 10.7998)"
      stroke="#E8529B"
      strokeWidth="1.13906"
    />
  </svg>
);

export default Share;
