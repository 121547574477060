import React from 'react';

import logo from '../../../assets/i-logo.png';

import * as S from './style';

import { MEMORIES_URL, BASICS_URL } from '../../../constants/constantUrls';

const Loading = props => {
  // memoryLoading state will need to be changed from within Memory chat flow
  const { memoryLoading, backToChat, history, flow } = props;
  return (
    <>
      <S.Main>
        {memoryLoading ? (
          <S.Section>
            <S.Img alt="Spinning Iternal logo" src={logo} />
            <S.H1>
              Storing your{' '}
              {flow === 'messageFuture' ? 'message...' : 'memory...'}
            </S.H1>
          </S.Section>
        ) : (
          <S.Section>
            <S.ImgStill alt="Iternal logo" src={logo} />
            <S.H1Caps>Success!</S.H1Caps>
            {flow === 'messageFuture' ? (
              <S.P>Message stored.</S.P>
            ) : (
              <S.P>Memory stored.</S.P>
            )}
            <S.P>What's next?</S.P>
            <S.ButtonSection>
              {flow === 'memory' ? (
                <S.ButtonLoading
                  text="Review my memory"
                  onClick={() => history.push(MEMORIES_URL)}
                />
              ) : (
                <S.ButtonLoading
                  text="Check out my profile"
                  onClick={() => history.push(BASICS_URL)}
                />
              )}
              <S.ButtonLoading text="Back to chat" onClick={backToChat} />
            </S.ButtonSection>
          </S.Section>
        )}
      </S.Main>
    </>
  );
};

export default Loading;
