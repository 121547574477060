// React
import React, { useState, useEffect } from 'react';
import history from '../../../history';
import Swal from 'sweetalert2';

import {
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Fade,
  Collapse,
  Zoom,
  Grid,
} from '@material-ui/core';

import { formatJSDate } from '../../../helpers';

import {
  Divider
} from '../../../NewComponents/Divider/Divider';

import {
  users,
  memories,
  giftMemory
} from '../../../api';

import {
  Date as DateInput,
  ImageWrapper,
  Title,
  VoiceOrDescription,
  AddEmailWrapper,
  ShareLinkWrapper,
  PrivacyWrapper,
} from '../../Common/Inputs';

import { CREATE_MEMORY_URL, SUCCESS_SAVE_OTHER_URL } from '../../../constants/constantUrls';

import { BackgroundWrapper, Main, StageTwoMain } from './style';

import { createErrors, validate } from '../../../helpers/validation';

export default (props) => {
  // Steps
  const [userId, setUserId] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({});
  const [focus, setFocus] = useState(false);
  const [voice, setVoice] = useState('');
  const [tempVoice, setTempVoice] = useState(null);
  const [video, setVideo] = useState('');
  const [tempVideo, setTempVideo] = useState(null);
  const [description, setDescription] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [category, setCategory] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState(new Date);
  const [dateType, setDateType] = useState('full');
  const [dateAge, setDateAge] = useState(0);
  const [imagesArrayRequest, setImagesArrayRequest] = useState([]);
  const [imagesArray, setImagesArray] = useState([]);
  const [tempImages, setTempImages] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState(null);
  const [showPopup, setShowPopup] = useState(true);
  const [screenNo, setScreenNo] = useState(0);

  const reset = () => {
    setError({});
    setVoice('');
    setTempVoice(null);
    setVideo('');
    setTempVideo(null);
    setDescription('');
    setIsRecording(false);
    setTitle('');
    setDate(new Date);
    setImagesArrayRequest([]);
    setImagesArray([]);
    setTempImages([]);
    setName('');
    setEmail('');
    setErrors(null);
    setScreenNo(0);
  }

  const onFocusChange = (e, ref) => {
    if (e === false) {
      // onblur
      setFocus(false);
      return;
    }
    // onfocus
    setFocus(true);
    if (ref) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }, 250);
    }
  };

  const validateAndStoreMemory = async options => {
    try {
      // type can be saveDraft / saveShare
      // previewMode can be preview / previewExisting
      let voiceLink = '';
      let videoLink = '';

      // upload audio to firebase and get the name

      if (voice && typeof voice !== 'string') {
        const {
          data: { generatedName },
        } = await memories.uploadAudio({
          voice,
          userId: userId,
        });
        voiceLink = generatedName;
      }

      if (video && typeof video !== 'string') {
        const {
          data: { generatedName },
        } = await memories.uploadVideo({
          video,
          userId: userId,
        });
        videoLink = generatedName;
      }
      // create object of inputs
      const rawData = {
        title,
        date,
        dateType,
        dateAge,
        images: imagesArrayRequest,
        // check if either voice or description is present
        voiceOrDescriptionIncluded:
          (voiceLink && voiceLink.length > 0) ||
          (description && description.length > 0) ||
          (voice && voice.length > 0) ||
          (voice && typeof voice === 'object') ||
          (video && video.length > 0) ||
          (video && typeof video === 'object'),
        voice: voiceLink.length > 0 ? voiceLink : voice,
        video: videoLink.length > 0 ? videoLink : video,
        description: description.length ? description : '',
        uploaderName: name,
        uploaderEmail: email,
      };

      const validatedData = await validate('createMemoryForFriend', rawData);
      if (validatedData) {
        await createMemory(rawData);
      }
    } catch (err) {
      const submitErrors = createErrors(err);
      if (submitErrors.voiceOrDescriptionIncluded) {
        Swal.fire({
          title: 'Please tell us what happened',
          timer: 3000,
          type: 'warning'
        });
      } else if (submitErrors.date) {
        Swal.fire({
          title: 'Please enter a valid date',
          timer: 3000,
          type: 'warning'
        });
      } else if (submitErrors.title) {
        Swal.fire({
          title: 'Please enter a title',
          timer: 3000,
          type: 'warning'
        });
      } else if (submitErrors.uploaderName) {
        Swal.fire({
          title: 'Please enter a name',
          timer: 3000,
          type: 'warning'
        });
      } else if (submitErrors.uploaderEmail) {
        Swal.fire({
          title: 'Please enter a valid email',
          timer: 3000,
          type: 'warning'
        });
      }

      setErrors(submitErrors);
    }
  };

  const createMemory = async (memoryObj) => {
    // type can be saveShare / saveDraft
    const {
      title,
      date,
      description,
      voice,
      video,
      images,
      voiceOrDescriptionIncluded,
      uploaderName: recipientName,
      uploaderEmail: recipientEmail,
    } = memoryObj
    // store new memory
    const { data, error } = await giftMemory.postGiftMemory({
      memory: {
        title,
        date: formatJSDate(date),
        voice,
        video,
        description,
        images,
        voiceOrDescriptionIncluded,
      },
      recipientEmail,
      recipientName,
    });
  
    if (!error) {
      setScreenNo(1);
    }
  };

  useEffect(() => {
    setUserId(props.id);
  }, []);
  
  return (
    <>
      {screenNo === 0 && (
        <BackgroundWrapper>
          <Main>
            <div style={{ width: '100%', flexDirection: 'column' }}>
              <Typography 
                variant="h3"
                gutterBottom
                style={{ textAlign: 'center', color: 'white', fontWeight: 'bold' }}
              >
                Give A Gift That Will Last Forever
              </Typography>
              <Paper elevation={3} style={{ padding: '2rem', margin: '2rem auto 0', maxWidth: '800px', width: 'auto' }}>
                <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                  What do you want to gift them? *
                </Typography>
                <Typography style={{ marginBottom: '2rem' }}>Is it a story? A favourite memory? A shared experience? Or maybe you just want to tell them how much they mean to you. Whatever it is, choose how you present your gift (pardon the pun) from the following three options:</Typography>
                <VoiceOrDescription
                  onFocusChange={onFocusChange}
                  voice={voice}
                  tempVoice={tempVoice}
                  setVoice={setVoice}
                  video={video}
                  tempVideo={tempVideo}
                  setVideo={setVideo}
                  description={description}
                  setDescription={setDescription}
                  setError={setError}
                  setIsRecording={setIsRecording}
                  isRecording={isRecording}
                  setTitle={setTitle}
                  title={title}
                  category={category}
                  error={error}
                  createForOther={true}
                />
                {errors?.voiceOrDescriptionIncluded && (
                  <Typography style={{ color: 'var(--secondary-cherry)', textAlign: 'center' }}>* Please tell us what happened</Typography>
                )}

                <Divider logo />
                <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                  Add some pics
                </Typography>
                <Typography style={{ marginBottom: '2rem' }}>Do you have any photos that would really make this gift standout?</Typography>
                <ImageWrapper
                  onFocusChange={onFocusChange}
                  showIcon
                  imagesArray={imagesArray}
                  setImagesArray={setImagesArray}
                  setImagesArrayRequest={setImagesArrayRequest}
                  imagesArrayRequest={imagesArrayRequest}
                  tempImages={tempImages}
                  setTempImages={setTempImages}
                  error={error}
                  createForOther={true}
                  uploaderId={userId}
                />
                <Divider logo />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                      Give it a title *
                    </Typography>
                    <Title 
                      onFocusChange={onFocusChange}
                      title={title}
                      setTitle={setTitle}
                      setError={setError}
                      // error={error}
                    />
                    {errors?.title && (
                      <Typography style={{ color: 'var(--secondary-cherry)', textAlign: 'center' }}>* Please enter a title</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                      When did it happen? *
                    </Typography>
                    <DateInput
                      onFocusChange={onFocusChange}
                      date={date}
                      setDate={setDate}
                      setError={setError}
                      dateType={dateType}
                      setDateType={setDateType}
                      dateAge={dateAge}
                      setDateAge={setDateAge}
                      error={error}
                      noDate
                    />
                    {errors?.date && (
                      <Typography style={{ color: 'var(--secondary-cherry)', textAlign: 'center' }}>* Please enter a valid date</Typography>
                    )}
                  </Grid>
                </Grid>

                <Divider logo />
                <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                  Their details *
                </Typography>
                {/* <Typography style={{ marginBottom: '2rem' }}>
                  We need this so that we can send them.
                </Typography> */}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" gutterBottom>
                      Their name
                    </Typography>
                    <TextField 
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      variant="outlined"
                      label="Their name"
                      placeholder="Write their name here..."
                    />
                    {errors?.uploaderName && (
                      <Typography style={{ color: 'var(--secondary-cherry)', textAlign: 'center' }}>* Please enter their name</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" gutterBottom>
                      Their email address
                    </Typography>
                    <TextField 
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      variant="outlined"
                      label="Their email address"
                      placeholder="Write their email here..."
                    />
                    {errors?.uploaderEmail && (
                      <Typography style={{ color: 'var(--secondary-cherry)', textAlign: 'center' }}>* Please enter a valid email</Typography>
                    )}
                  </Grid>
                </Grid>
                <div
                  style={{ marginTop: '2rem', textAlign: 'center' }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => validateAndStoreMemory()}
                  >
                    Send
                  </Button>
                </div>
              </Paper>
            </div>
          </Main>
        </BackgroundWrapper>
      )}
      {screenNo === 1 && (
        <BackgroundWrapper>
          <StageTwoMain>
            <div style={{ width: '100%', flexDirection: 'column' }}>
              <Paper elevation={3} style={{ padding: '2rem', margin: '2rem auto 0', maxWidth: '800px', width: 'auto', textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
                  You gifted {name} a memory!
                </Typography>
                <div style={{ textAlign: 'center' }}>
                  <img src={'https://iternal.life/wp-content/uploads/2021/09/send_it.gif'} style={{ maxWidth: '300px', width: '100%' }}/>
                </div>
                <Button 
                  style={{ width: '100%', marginBottom: '1rem' }} 
                  variant='contained' 
                  color='primary'
                  onClick={() => reset()}
                >Gift Another</Button>
                <Button 
                  color='primary' 
                  variant='outlined'
                  onClick={() => history.push('/')}
                >Back to timeline</Button>
                
              </Paper>
            </div>
          </StageTwoMain>
        </BackgroundWrapper>
      )}
    </>
  );
};
