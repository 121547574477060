import React, { useState, useEffect } from "react";

// Components
import {
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Fade,
  Collapse,
  Zoom,
  Grid,
  Modal,
  Select,
  InputLabel,
  Switch,
} from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import * as yup from "yup";
import Swal from "sweetalert2";

import { FaPlus } from "react-icons/fa";

import SocialShare from "../SocialShare";
import Icon from "../Icon/Icon";
import CallToActionButton from "./Buttons/CallToActionButton";

const emailSchema = yup
  .array(
    yup
      .string()
      .email("please enter valid email or emails")
      .required("required")
  )
  .max(5, "Sorry, you can add up to 5 emails in one go");

export default function InviteMemberPopup({
  groupShortCode = "",
  groupName = "",
  groupId = "",
  onCloseFunction = () => {},
}) {
  const [emails, setEmails] = useState("");
  const [message, setMessage] = useState("");
  const [shareLink, setShareLink] = useState("");
  const [shareMessage, setShareMessage] = useState("");

  useEffect(() => {
    setShareLink(`${window.location.origin}/group/${groupShortCode}`);
    setShareMessage(
      `Hey! I'd like to invite you to ${groupName} on Iternal! To join the group, click this link`
    );
  }, [groupName, groupShortCode]);

  const inviteMembers = async () => {
    try {
      if (emails && emails.length > 0) {
        emailSchema
          .validate(splitEmailsList(emails))
          .then(async (sharedWithEmails) => {
            const { data } = await axios.post("/api/invite-group-member", {
              sharedWithEmails,
              message,
              group: {
                groupName,
                groupShortCode,
                _id: groupId,
              },
            });
            Swal.fire({
              type: "success",
              title: "You successfully invited people to your group!",
            });
            setEmails("");
          })
          .catch((err) =>
            Swal.fire({
              type: "warning",
              title: err.message,
            })
          );
      } else {
        Swal.fire({
          title: "Please enter an email address first",
          timer: 3000,
          type: "warning",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const splitEmailsList = (emailsAsString) => {
    // split on "," & ";" and " "
    const splittedEmails = emailsAsString.split(/[, ;]/g);
    return splittedEmails.filter((item) => !!item).map((item) => item.trim());
  };

  const messageTitle = "I'd like to invite you to my group!";

  return (
    <Paper
      id="invite-member-popup"
      elevation={1}
      style={{
        position: "absolute",
        textAlign: "left",
        width: window.innerWidth < 700 ? "60%" : "60%",
        padding: window.innerWidth < 1024 ? "1rem" : "2rem",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "90%",
        borderRadius: "2rem",
        overflowY: "scroll",
      }}
    >
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <Button
          variant="text"
          color="primary"
          onClick={() => onCloseFunction()}
          style={{
            postion: "absolute",
            top: "-0.5rem",
            right: "-1rem",
          }}
        >
          <Icon  icon="close" />
        </Button>
      </Grid>
      <Grid container spacing={12} id="invite-member-content">
        <Grid item xs={12} style={{ marginBottom: "1rem" }}>
          <Typography
            variant="h4"
            color="primary"
            style={{ marginBottom: "1rem", marginTop: window.innerWidth < 1024 ? "0rem" : "-2rem" }}
          >
            Add New Member
          </Typography>
          <Typography variant="body1">
            This is where you can add new members to your group. Everyone you
            add by email will be able to see your group and add new memories.
          </Typography>
          <Typography
            variant="body1"
            style={{ display: window.innerWidth < 800 ? "none" : "block" }}
          >
            To invite them, enter their email address and click Add Member.
          </Typography>
          {/* Will hook up on email branch in future */}
          <TextField
            id="email"
            value={emails}
            onChange={(text) => setEmails(text.target.value)}
            variant="outlined"
            placeholder="Enter the email of the person you want to invite"
            label="Email"
            fullWidth
            style={{ marginBottom: "1rem", marginTop: "1rem" }}
            helperText="Tip: You can invite multiple friends by seperating their emails with a comma"
          />

          {/* <TextField
            id="introduction"
            value={message}
            onChange={text => setMessage(text.target.value)}
            variant="outlined"
            placeholder="Send a custom message to the person you are inviting"
            label="Invite Message"
            fullWidth
            style={{ marginBottom: '1rem' }}
            multiline
            rows={4}
          /> */}
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "1rem" }}>
          <Grid
            container
            spacing={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1rem",
            }}
          >
            <CallToActionButton
              style={{
               
              }}
              variant="contained"
              id="submitButton"
              title="Add Member"
              startIcon=<FaPlus style={{ fontSize: "0.8rem" }} />
              clickFunction={async () => await inviteMembers()}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "2rem" }}>
          <Typography variant="body1">
            Alternatively click the button below to copy your unique sign-up
            link to share the with others or share through social media
            platforms.
          </Typography>
        </Grid>

        <Grid
          container
          spacing={1}
          c
          alignItems="center"
          justify="center"
          style={{ marginBotton: "1rem" }}
        >
          <Grid
            item
            xs={12}
       
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              marginBottom: "1rem",
            }}
          >
            {navigator.share ? (
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                style={{
                  marginRight: "1rem",
                }}
                onClick={() => {
                  if (navigator.share) {
                    navigator.share({
                      title: "Iternal",
                      text: `Join ${groupName} on Iternal`,
                      url: shareLink,
                    });
                  }
                }}
              >
                Copy Link
              </Button>
            ) : (
              <CopyToClipboard text={shareLink}>
                <Button
                  color="secondary"
                  variant="contained"
                  // fullWidth
                  style={{
                    marginTop: "0rem",
                    // borderRadius: "100px",
                    minWidth: "164px",
                    minHeight: "50px",
                    fontWeight: "700",
                    boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                  }}
                >
                  Copy Link
                </Button>
              </CopyToClipboard>
            )}
          </Grid>
          <Grid
            item
            xs={12}
       
          
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "2rem",
            }}
          >
            <SocialShare
              link={shareLink}
              messageBody={shareMessage}
              messageTitle={messageTitle}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{}}>
        <Typography variant="body1">
          If someone joins through a signup link, an admin will have to approve
          them in the members area before they can access the group.
        </Typography>
      </Grid>
    </Paper>
  );
}
