import styled from 'styled-components';

import { breakpointsMax } from '../../../constants/breakPoints';

export const Main = styled.main`
  display: flex;
  max-width: 100rem;
  margin: 0 auto;
  flex-direction: column;
  padding: 2rem 3rem 1rem 300px;
  align-items: center;
  @media ${breakpointsMax.laptop} {
    padding: 1rem;
  }

  .grid {
    display: grid;
    grid-template-rows: 
      repeat(4, 180px);
    grid-template-columns: 
      repeat(3, 1fr);
    grid-gap: 5px;
  }
  
  .item:nth-child(1) {
    grid-row: 1 / 4;
  }
  
  .item:nth-child(2) {
    grid-column: 2 / 4;
    grid-row: 1 / 3;
  }
  
  .item:nth-child(3) {
    grid-column: 3 / 4;
    grid-row: 3 / 5;
  }
  
  .item:nth-child(4) {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }
  
  .item:nth-child(5) {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
  }

  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    max-height: 70vh;
    overflow: scroll;
  }
  .my-masonry-grid_column {
    background-clip: padding-box;
  }
  
  /* Style your items */
  .masonry-object {
    display: flex;
    padding: 7px;
    justify-content: center;
    margin-top: -3px;
  }
`
