const cardsData = {
  titleCard: {
    title: 'Name your story',
    isRequired: true,
    description:
      '',
    label: 'Memory Title',
    inputType: 'title',
  },
  DateCard: {
    title: 'When did it happen?',
    isRequired: true,
    description:
      "",
    label: 'Add A Date',
    inputType: 'date',
  },
  detailsCard: {
    title: 'What is your story?',
    isRequired: true,
    description: 'You can tell your story in three ways, by writing it down, filming it or recording a voice note. Click the button that best suits your tale.',
    inputType: 'voiceOrDescriptionIncluded',
  },
  imageCard: {
    title: 'Add some photos',
    isRequired: false,
    description: 'A picture is worth a thousand words so enhance your story by uploading some photos and never forget the moment.',
    inputType: 'images',
  },
  menuCard: {
    title: 'Story Steps',
    isRequired: false,
    description: '',
  },
  privacyCard: {
    title: 'Privacy settings',
    isRequired: false,
    description: '',
    inputType: 'privacy',
  },
};

export default cardsData;
