import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { Paper, Typography, Button } from '@material-ui/core';
import { FaFacebook } from 'react-icons/fa';
import Grid from '@material-ui/core/Grid';
import ReactGA from 'react-ga';
import { sizeNum } from '../../../constants/breakPoints';
import HeaderLogout from '../../Common/HeaderLogout';

// Icons
import { createErrors, validate } from '../../../helpers/validation';

import logo from '../../../assets/og-logo.svg';
import whitelogo from '../../../assets/onboarding/logo-white.png';

import * as S from './style';

import { LogoWrapper } from './style';

import HeaderShareMemory from '../../Common/HeaderShareMemory';
import Spinner from '../../Common/Spinner';

// ROUTES
import {
  DASHBOARD_URL,
  MY_FRIEND_MEMORIES,
  ONBOARDING_FLOW,
  SIGNUP_URL,
} from '../../../constants/constantUrls';
import {
  API_LOGIN_URL,
  API_GOOGLE_LOGIN_URL,
  API_GOOGLE_SIGNUP_URL,
  API_FACEBOOK_LOGIN_URL,
  API_FACEBOOK_SIGNUP_URL,
} from '../../../constants/apiRoutes';

// API
import { users } from '../../../api';

// ---------------- login components ----------------
// import { Login as LogInComponent } from '../../../NewComponents/LoginSignUp/Login/Login';

export default class LogIn extends Component {
  state = {
    email: '',
    password: '',
    errors: {},
    nameMemoryOwner: '',
    isLoading: false,
    showSubmitErrors: false,
    singleError: false,
    showPassword: false,
  };

  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const { id } = this.props.match.params;
    // shareId
    this.setState({ isLoading: true });
    if (id) {
      // get the name and id form the shareId
      const {
        data: { name } = {},
        error,
      } = await users.getNameAndIdFromLinkToken({
        shortId: id,
        options: { showModal: false },
      });

      if (error) {
        // handle invalid shareId
        this.setState({ isLoading: false });
        return this.props.history.push(DASHBOARD_URL);
      }
      this.setState({ isLoading: false });
      this.setState({
        nameMemoryOwner: name,
      });
    }

    this.setState({ isLoading: false });
    return undefined;
  }

  handleInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // used for field validations
  validator = async e => {
    const { email, password, errors } = this.state;

    let { singleError } = this.state;
    // get current input
    const {
      target: { name },
    } = e;

    this.setState({ showSubmitErrors: false });
    try {
      const validated = await validate('login', { email, password });

      if (validated) {
        this.setState({ errors: {}, singleError: '' });
      }
    } catch (error) {
      const submitErrors = createErrors(error);

      if (submitErrors[name]) {
        singleError = { [name]: submitErrors[name] };
      } else {
        singleError = '';
      }

      this.setState({
        errors: { ...errors, submitErrors },
        singleError,
      });
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { errors, email, password } = this.state;
    const { history, handleChangeState, match } = this.props;
    const { id: shortId } = match.params;

    this.setState({ isLoading: true });

    try {
      // validate form
      const loginData = { email, password };
      const validated = await validate('login', loginData);

      if (validated) {
        this.setState({ errors: {} });
        const loggedIn = await axios.post(API_LOGIN_URL, loginData);

        if (loggedIn) {
          ReactGA.event({
            category: 'Login',
            action: 'Logged In',
          });
          const { data } = loggedIn;
          handleChangeState({ ...data, isLoggedIn: true });
          this.setState({ isLoading: false });
          return history.push(
            shortId
              ? MY_FRIEND_MEMORIES.replace(':shortId', shortId)
              : DASHBOARD_URL,
          );
        }
      }
    } catch (error) {
      this.setState({ isLoading: false });

      console.log(error.response);
      if (error.name === 'ValidationError') {
        const submitErrors = createErrors(error);

        this.setState({
          errors: { ...errors, submitErrors },
        });
      }

      if (error.response && error.response?.status === 429) {
        errors.submitError = error.response.data;
        this.setState({
          errors: {
            ...errors,
          },
        });
      }

      if (error.response && error.response.data.error) {
        errors.submitError = error.response.data.error;

        this.setState({
          errors: {
            ...errors,
          },
        });
      }
    }
  };

  createValidationError = async error => {
    // frontend validation
    this.setState({ isLoading: false });
    if (error.name === 'ValidationError') {
      const submitErrors = createErrors(error);

      this.setState({
        errors: { ...error, submitErrors },
      });
    }
    if (
      error.response &&
      error.response.data.error.includes(
        'Login failed. Email already used by Iternal account',
      )
    ) {
      error.submitError = error.response.data.error;

      this.setState({
        errors: {
          ...error,
        },
      });
    }
    if (
      error.response &&
      error.response.data.error.includes('Email already taken')
    ) {
      error.submitError = error.response.data.error;

      this.setState({
        errors: {
          ...error,
        },
      });
    }
  };

  responseFacebook = async response => {
    const { email } = response;
    const facebookId = response.id;
    const { history, handleChangeState, match } = this.props;
    const { id: shortId } = match.params;

    this.setState({ isLoading: true });
    try {
      const facebookLoginData = { email, facebookId };
      const validated = await validate('facebookLogin', facebookLoginData);
      if (validated) {
        this.setState({ errors: {} });
        const loggedIn = await axios.post(
          API_FACEBOOK_LOGIN_URL,
          facebookLoginData,
        );
        if (!loggedIn.data.no_account_found) {
          const { data } = loggedIn;
          handleChangeState({ ...data, isLoggedIn: true });
          this.setState({ isLoading: false });
          return history.push(
            shortId
              ? MY_FRIEND_MEMORIES.replace(':shortId', shortId)
              : DASHBOARD_URL,
          );
        }
        try {
          const signUpData = { email, facebookId, agreeTerms: true };
          const validated = await validate('facebookSignUp', signUpData);
          if (validated) {
            this.setState({ errors: {} });
            const userInfo = { email, facebookId, mailingList: true };
            const signedUp = await axios.post(API_FACEBOOK_SIGNUP_URL, {
              userInfo,
            });
            if (signedUp) {
              const { data } = signedUp;
              handleChangeState({ ...data, isLoggedIn: true });
              this.setState({ isLoading: false });
              return history.push({
                pathname: ONBOARDING_FLOW,
                state: { isReferral: !!shortId, shortId },
              });
            }
          }
        } catch (error) {
          this.createValidationError(error);
        }
      }
    } catch (error) {
      this.createValidationError(error);
    }
  };

  responseGoogle = async response => {
    this.setState({ isLoading: true });
    try {
      const { googleId, email } = response.profileObj;
      const { history, handleChangeState, match } = this.props;
      const { id: shortId } = match.params;

      const googleLoginData = { email, googleId };
      const validated = await validate('googleLogin', googleLoginData);
      if (validated) {
        this.setState({ errors: {} });
        const loggedIn = await axios.post(
          API_GOOGLE_LOGIN_URL,
          googleLoginData,
        );
        if (!loggedIn.data.no_account_found) {
          const { data } = loggedIn;
          handleChangeState({ ...data, isLoggedIn: true });
          this.setState({ isLoading: false });
          return history.push(
            shortId
              ? MY_FRIEND_MEMORIES.replace(':shortId', shortId)
              : DASHBOARD_URL,
          );
        }
        try {
          // validate form
          const signUpData = { email, googleId, agreeTerms: true };
          const validated = await validate('googleSignUp', signUpData);
          if (validated) {
            this.setState({ errors: {} });
            const userInfo = { email, googleId, mailingList: true };
            const signedUp = await axios.post(API_GOOGLE_SIGNUP_URL, {
              userInfo,
            });
            if (signedUp) {
              const { data } = signedUp;
              handleChangeState({ ...data, isLoggedIn: true });
              this.setState({ isLoading: false });
              return history.push({
                pathname: ONBOARDING_FLOW,
                state: { isReferral: !!shortId, shortId },
              });
            }
          }
        } catch (error) {
          this.createValidationError(error);
        }
      }
    } catch (error) {
      this.createValidationError(error);
    }
  };

  render() {
    const {
      errors,
      nameMemoryOwner,
      isLoading,
      showSubmitErrors,
      singleError,
      showPassword,
      password,
    } = this.state;
    const { windowWidth, history, match } = this.props;
    const { id: shortId } = match.params;
    return (
      <S.SignInBackgroundWrapper
        style={{ margin: window.innerWidth < 400 ? '-1rem' : '0rem' }}
      >
        {/* {windowWidth >= sizeNum.laptop && (
          <HeaderLogout windowWidth={windowWidth} noBackground/>
        )} */}
        <Grid container xs={12}>
          <Grid item xs={6}>
            <img
              src={whitelogo}
              style={{
                maxHeight: '40px',
                marginLeft: '1.5rem',
                marginTop: '1.5rem',
                display: window.innerWidth > 900 ? 'none' : 'inline-flex',
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <S.StyledExtLink
                href={SIGNUP_URL}
                rel="noopener noreferrer"
                target=""
                style={{
                  backgroundColor: '#0A2156',
                  padding: '1rem',
                  color: 'white',
                  borderRadius: '100px',
                  boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',
                  textDecoration: 'none',
                  marginTop: '1rem',
                  marginRight: '1rem',
                  display: window.innerWidth < 500 ? 'none' : 'block',
                }}
              >
                Sign Up
              </S.StyledExtLink>
            </div>
          </Grid>
        </Grid>

        <div
          style={{
            display: 'flex',
            minHeight: '90vh',
            marginLeft: '2rem',
            marginRight: '2rem',
            boxSizing: 'border-box',
          }}
        >
          <section
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              display: window.innerWidth < 900 ? 'none' : 'inline-flex',
            }}
          >
            <div style={{}}>
              <img
                src={whitelogo}
                style={{ maxWidth: '80%', alignItems: 'center' }}
              />
            </div>
          </section>

          <section
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <form onSubmit={this.handleSubmit} style={{ flex: 0 }}>
              <Grid
                style={{
                  borderRadius: '20px',
                  padding: window.innerWidth < 600 ? '1rem' : '3rem',

                  maxWidth: '550px',
                  margin: '2rem auto',
                  backgroundColor: 'white',
                }}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{
                    textAlign: 'left',
                    fontWeight: 'bolder',
                    fontSize: window.innerWidth < 430 ? '2rem' : '2.5rem',
                  }}
                >
                  Welcome Back
                </Typography>
                {shortId && (
                  <HeaderShareMemory
                    nameMemoryOwner={nameMemoryOwner}
                    contentType="login"
                  />
                )}

                <p
                  style={{
                    fontSize: window.innerWidth < 430 ? '0.8rem' : '1rem',
                    marginBottom: window.innerWidth < 430 ? '1.5rem' : '0rem',
                  }}
                >
                  Don't have an account?{' '}
                  <S.StyledExtLink
                    href={SIGNUP_URL}
                    rel="noopener noreferrer"
                    target=""
                    style={{ color: '#0A2156' }}
                  >
                    Sign up
                  </S.StyledExtLink>
                </p>
                <S.Legend>Sign Up Form</S.Legend>
                <S.Row
                  style={{
                    marginTop: window.innerWidth < 430 ? '1rem' : '2rem',
                  }}
                >
                  <label
                    className="label"
                    style={{
                      marginBottom: window.innerWidth < 430 ? '0rem' : '0.5rem',
                      fontWeight: 'bold',
                      fontSize: window.innerWidth < 430 ? '0.8rem' : '1rem',
                    }}
                  >
                    Email Address
                  </label>
                  <S.Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your email address"
                    onChange={this.handleInput}
                    onBlur={e => this.validator(e)}
                    // onKeyPress={e => this.validator(e)}
                    error={errors.submitErrors && errors.submitErrors.email}
                  />
                  <S.Label
                    htmlFor="email"
                    style={{
                      marginBottom: window.innerWidth < 430 ? '0rem' : '0.5rem',
                      fontWeight: 'bold',
                      fontSize: window.innerWidth < 430 ? '0.8rem' : '1rem',
                    }}
                  >
                    Email address
                  </S.Label>
                  {/* <TextField
                    type="email"
                    name="email"
                    id="email"
                    variant="filled"
                    label="Email Address"
                    placeholder=""
                    onChange={this.handleInput}
                    onBlur={e => this.validator(e)}
                    error={errors.submitErrors && errors.submitErrors.email}
                  /> */}

                  {showSubmitErrors ? (
                    <S.ErrorMsg>
                      {errors.submitErrors && errors.submitErrors.email}
                    </S.ErrorMsg>
                  ) : (
                    <S.ErrorMsg>
                      {singleError.email && singleError.email}
                    </S.ErrorMsg>
                  )}
                </S.Row>
                <S.Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                  <label
                    className="label"
                    style={{
                      marginBottom: window.innerWidth < 430 ? '0rem' : '0.5rem',
                      fontWeight: 'bold',
                      fontSize: window.innerWidth < 430 ? '0.8rem' : '1rem',
                    }}
                  >
                    Password
                  </label>
                  <S.Input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    id="password"
                    placeholder="Your password"
                    value={(password || '').replace(/\s+/gi, ' ')}
                    onChange={this.handleInput}
                    onBlur={e => this.validator(e)}
                    // onKeyPress={e => this.validator(e)}
                    error={errors.submitErrors && errors.submitErrors.password}
                  />
                  <S.Label htmlFor="password">Password</S.Label>
                  {/* <TextField
                    type="password"
                    name="password"
                    id="password"
                    variant="filled"
                    label="Password"
                    onChange={this.handleInput}
                    onBlur={e => this.validator(e)}
                    error={errors.submitErrors && errors.submitErrors.password}
                  />
                  <S.Label htmlFor="password">Password</S.Label> */}
                  {showSubmitErrors ? (
                    <S.ErrorMsg>
                      {errors.submitErrors && errors.submitErrors.password}
                    </S.ErrorMsg>
                  ) : (
                    <S.ErrorMsg>
                      {singleError.password && singleError.password}
                    </S.ErrorMsg>
                  )}
                  <S.FlexWrapper>
                    <S.CheckboxLabel>
                      <S.Checkbox
                        type="checkbox"
                        name="mailingList"
                        id="mailingList"
                      />
                      <S.StyledCheckbox />
                      Remember Password
                    </S.CheckboxLabel>
                    <S.ForgotPassword
                      onClick={() => history.push('/reset-password')}
                    >
                      Forgot password?
                    </S.ForgotPassword>
                  </S.FlexWrapper>
                </S.Row>
                <S.ButtonWrapper>
                  <Button
                    style={{
                      height: '3rem',
                      fontSize: window.innerWidth < 430 ? '0.9rem' : '1.1rem',
                      fontWeight: 'bold',
                      borderRadius: '100px',
                      boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',
                    }}
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      this.setState({ showSubmitErrors: true });
                    }}
                  >
                    Login
                  </Button>
                  {isLoading && <Spinner />}
                </S.ButtonWrapper>
                <S.SignInText>
                  <p
                    style={{
                      color: 'grey',
                      fontSize: window.innerWidth < 430 ? '0.8rem' : '1rem',
                    }}
                  >
                    <span>or Login with</span>
                  </p>
                </S.SignInText>
                <S.SocialLoginWrapper>
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    buttonText="Login with Google"
                    onSuccess={e => this.responseGoogle(e)}
                    onFailure={e => this.responseGoogle(e)}
                    cookiePolicy="single_host_origin"
                  />
                  {/* <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    fields="name,email,picture"
                    callback={(e) => this.responseFacebook(e)}
                    textButton="Login with Facebook"
                    icon={<FaFacebook />}
                    disableMobileRedirect
                  /> */}
                </S.SocialLoginWrapper>
                {errors.submitError && (
                  <S.SubmitErrorMsg>{errors.submitError}</S.SubmitErrorMsg>
                )}
              </Grid>
            </form>
            {/* <S.LogInWrapper>
              <p style={{ marginTop: '15px', color: 'white' }}>Don't have an account?</p>
              <Link to={SIGNUP_URL} style={{ fontSize: '20px', fontWeight: 'bolder', textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  color="secondary"
                >
                  Sign Up  
                </Button>
                
              </Link>
            </S.LogInWrapper> */}
            {/* <LogInComponent
              isLoading={this.state.isLoading}
              emailVal={this.state.email}
              passwordVal={this.state.password}
              handleInput={this.handleInput}
              validator={this.validator}
              forgotPasswordFunc={() => history.push('/reset-password')}
              errors={this.state.errors}
              submitFunc={this.handleSubmit}
              responseGoogle={this.responseGoogle}
              responseFacebook={this.responseFacebook}
            /> */}
          </section>
        </div>
      </S.SignInBackgroundWrapper>
    );
  }
}
