// React
import React, { Component } from 'react';
import * as C from "../../../constants/constantUrls";

// MUI Icons
import MemoryIcon from "@material-ui/icons/Book";
import Plus from "@material-ui/icons/Add";
import MilestoneIcon from "@material-ui/icons/Star";
import Photos from "@material-ui/icons/PhotoCamera";
import Groups from "@material-ui/icons/People";
import Friends from "@material-ui/icons/EmojiPeople";
import Profile from "@material-ui/icons/Face";
import Stories from "@material-ui/icons/MenuBook";
import Camera from "@material-ui/icons/PhotoCamera"


import history from "../../../history";


// MUI
import {
  Button,
  TextField,
  Paper,
  Typography,
  Grid,
  AppBar,
  Tab,
  Tabs,
} from '@material-ui/core';

// Sweet Alert
import Swal from 'sweetalert2';

// Field Validation
import * as yup from 'yup';

// Assets
import loader from '../../../assets/loader.svg';

// Constants
import { CREATE_MEMORY_URL } from '../../../constants/constantUrls';

// API
import { memories, permissionAccess, linkTokens, users } from '../../../api';

// User Context
import userContext from '../../../context/user';

// Components
import MemoriesTable from '../../Common/Tables/MemoriesTable';
import { NewTimeline } from '../../../NewComponents/Dashboard/Blocks/NewTimeline/NewTimeline';
// import PaymentPopup from '../../Common/PaymentPopup';
import { RemainingAllowance } from '../../../NewComponents/Dashboard/Blocks/NewTimeline/RemainingAllowance/RemainingAllowance';
import LoadingAnimation from '../../Common/Animations/Loading';
// MUI Components

import * as S from './style';

import { ShareLinkWrapper } from '../../Common/Inputs';
import ReactGA from 'react-ga';

const emailSchema = yup
  .array(
    yup
      .string()
      .email('please enter a valid email')
      .required('required'),
  )
  .max(1, 'Sorry, you can only add up to 1 email at a time');

class Memories extends Component {
  state = {
    memories: undefined,
    shareLink: '',
    createLink: '',
    emailInput: '',
    permissionAccessEmails: [],
    isLoading: true,
    isShowing: false,
    tabsValue: 1,
    extraMemories: 0,
    profile: {},
  };



  async componentDidMount() {
    this.loadData();
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  async loadData() {
    const { data, error } = await memories.getAll({});
    if (!error) {
      this.setState({
        memories: data,
      });
    }
    await this.fetchLinkToken();
    await this.fetchPermissionAccess();
    await this.fetchExtraMemories();
    await this.fetchProfile();
    this.setState({ isLoading: false });
  }

  fetchProfile = async () => {
    const { data } = await users.getUserBasicProfile();
    if (data) {
      this.setState({
        profile: {name: data?.name, picture: data?.image?.imagePath}
      });
    }
  }

  fetchExtraMemories = async () => {
    const { data } = await users.getUserInfoFromToken({
      options: { showModal: false },
    });
    if (data) {
      this.setState({
        extraMemories: data.extraMemories ? data.extraMemories : 0,
      });
    }
  };

  renderListOrNothing = () =>
    this.state.memories && this.state.memories?.length > 0 ? (
      <MemoriesTable memories={this.state.memories} profile={this.state.profile} />
    ) : (
      <S.A href={CREATE_MEMORY_URL}>
        <S.P>
          You haven&apos;t added a memory yet. <br />
          Why not add one now!
        </S.P>
   
      </S.A>
    );

  renderTimelineOrNothing = () =>
    this.state.memories && this.state.memories?.length > 0 ? (
      <div
        style={{
          height: '64vh',
          overflow: 'auto',
          position: 'initial',
          // background: 'hsla(178, 88%, 36%, 0.08)',
          borderRadius: '1rem 1rem 0 0',
        }}
      >
        <NewTimeline 
          memories={this.state.memories} 
          milestones={[]}
          getMilestonesFunc={() => {}}
          prompts={[]}
          profile={this.state.profile} 
          showPrompts={false}
          viewingFriendsMemory={false}
          highlightedElement={1}
          editMilestoneFunc={() => {}}
          style={{
            // height: "64vh",
            // overflow: "auto",
            // position: "initial",
            // background: "none",
            // borderRadius: "1rem 1rem 0 0",
          }}
        />
      </div>
    ) : (
      <S.A href={CREATE_MEMORY_URL}>
        <S.P >
          You haven&apos;t added a memory yet. <br />
          Why not add one now!
        </S.P>
        
      </S.A>
    );

  toggleSubscripeModal = () => {
    this.setState(state => ({ isShowing: !state.isShowing }));
  };

  done = () => {
    this.context.update({ isPremium: true });
  };

  /* eslint-disable-next-line */
  splitEmailsList(emailsAsString) {
    // split on "," & ";" and " "
    const splittedEmails = emailsAsString.split(/[, ;]/g);
    return splittedEmails.filter(item => !!item).map(item => item.trim());
  }

  async fetchPermissionAccess() {
    const {
      data,
      error,
    } = await permissionAccess.getAllGivenPermissionAccess();
    if (!error && data.length > 0) {
      const permissionAccessEmails = data.map(obj => obj.sharedWithEmail);
      this.setState({
        permissionAccessEmails,
      });
    }
  }

  async fetchLinkToken() {
    const {
      data,
      error,
    } = await linkTokens.getActiveLinkTokenForLoggedInUser();
    if (!error) {
      this.setState({
        shareLink: `${window.location.origin}/share/${data.shortId}`,
        createLink: `${window.location.origin}/create/${data.shortId}`
      });
    }
  }

  render() {
    const { memories, shareLink, createLink, tabsValue, extraMemories, isLoading } = this.state;
    const { isPremium } = this.context;
    let filteredMemories = [];
    let memoryLength = 0;
    if (memories) {
      filteredMemories = memories?.filter(memory => memory?.author && memory?.author?.name);
      memoryLength = memories?.length - filteredMemories.length;
    }
    const handleClick = async () => {
      if (this.state.emailInput && this.state.emailInput.length > 0) {
        const splitEmail = this.splitEmailsList(this.state.emailInput);
        emailSchema
          .validate(splitEmail)
          .then(async () => {
            let duplicateEmail = false;
            splitEmail.map(email => {
              duplicateEmail = this.state.permissionAccessEmails.includes(
                email,
              );
              return email;
            });
            const ownEmail = splitEmail.includes(this.context.email);
            if (ownEmail) {
              return Swal.fire({
                title: "You can't add yourself!",
                timer: 3000,
                type: 'warning'
              })
            }
            if (!duplicateEmail) {
              const { error } = await permissionAccess.create({
                sharedWithEmails: splitEmail,
              });
              if (!error) {
                this.fetchPermissionAccess();
                return Swal.fire({
                  title: 'Email added',
                  timer: 3000,
                  type: 'success',
                });
              }
            } else {
              return Swal.fire({
                title: 'You have already added this email',
                timer: 3000,
                type: 'warning',
              });
            }
          })
          .catch(error =>
            Swal.fire({
              title: 'Please enter a valid email address',
              timer: 3000,
              type: 'warning',
            }),
          );
      } else {
        Swal.fire({
          title: 'Please enter an email address first',
          timer: 3000,
          type: 'warning',
        });
      }
    };

    const handleChange = e => {
      this.setState({ emailInput: e.target.value });
    };

    const switchTabs = (event, newValue) => {
      this.setState({ tabsValue: newValue });
    };

    const calculateAvailableMemories = () =>
      !extraMemories ? 20 : Math.max(20 + extraMemories);

    const shareLinkCopy = <>Share all your public stories with your friends by sharing this link with them.</>;
    const createLinkCopy = <>You can ask your friends to share their favourite stories of you by sharing this link with them. </>;
    
    if (isLoading) {
      return (
        <S.LoadWrapper>
          <LoadingAnimation width={window.innerWidth < 600 ? window.innerWidth * 0.9 : 500} height={window.innerWidth < 600 ? window.innderWidth * 0.9 : 500} />
        </S.LoadWrapper>
      );
    } else {
      return (
        <>
          <S.Wrapper  style={{marginLeft: window.innerWidth < 1024 ? "0rem" : '3rem'}}>
       
      
          <Typography variant="h3" style={{ fontWeight: 'bold', color: 'white', textAlign:'center', marginBottom:'2rem' }}>
            Share Your Stories
            </Typography>
            <Grid container spacing={2} style={{paddingLeft: window.innerWidth < 600 ? "0rem" : '2rem', paddingRight: window.innerWidth < 600 ? "0rem" : '2rem'}}>
             

              <Grid item xs={12} lg={8}>
                <Paper elevation={3} style={{ padding: '1rem', marginBottom: window.innerWidth > 1023 ? "none" : "6rem", }}>
                  <div style={{ maxHeight: '95vh', overflow: 'auto', position: 'initial'}}>
                    <RemainingAllowance
                      contentType="stories"
                      isPremium={isPremium}
                      used={memoryLength < 0 ? 0 : memoryLength}
                      totalAvailable={calculateAvailableMemories()}
                      style={{ margin: '0 auto' }}
                    />
                    <div className="contentSection">
                      <AppBar color="default" position="static">
                        <Tabs
                          value={tabsValue}
                          variant="fullWidth"
                          centered
                          indicatorColor="primary"
                          textColor="primary"
                          onChange={switchTabs}
                         
                        >
                          <Tab
                            label="Story List"
                            disabled={!calculateAvailableMemories()}
                            style={{fontSize:'1.5rem', fontWeight:'bold'}}
                          />
                          <Tab
                            label="Timeline"
                            disabled={!calculateAvailableMemories()}
                            style={{fontSize:'1.5rem', fontWeight:'bold'}}

                          />
                        </Tabs>
                      </AppBar>
                      {this.state.isLoading && <img alt="loader" src={loader} />}
                      <div
                        style={{
                          display: `${tabsValue === 0 ? 'block' : 'none'}`,
                         
                        }}
                      >
                        {this.renderListOrNothing()}
                      </div>
                      <div
                        style={{
                          display: `${tabsValue === 1 ? 'block' : 'none'}`,
                      
                        }}
                      >
                        {this.renderTimelineOrNothing()}
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={4}>
                {/* <Paper
                  elevation={3}
                  style={{ padding: '2rem', marginBottom: '1.5rem' }}
                >
                  <Typography variant="h4" gutterBottom>
                    Invite Friends
                  </Typography>
                  <br />
                  <p style={{ lineHeight: '1.5rem' }}>
                    Add your friend’s email here to share your memories with them
                  </p>
                  <br />
                  <Grid container spacing={0}>
                    <Grid item xs={9}>
                      <TextField
                        type="text"
                        id="emailInput"
                        variant="outlined"
                        label="Email"
                        placeholder="Add Friend's Email"
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        style={{ width: '100%', height: '100%' }}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Paper> */}
                <Paper elevation={3} style={{ padding: '1rem' }}>
                  <Typography variant="h5" color="primary" style={{ }}>
                    Share Stories
                  </Typography>
                  <br />
                  <ShareLinkWrapper shareLink={shareLink} copy={shareLinkCopy} share={true}/>
                </Paper>
                <Paper elevation={3} style={{ padding: '1rem', marginTop: '1rem',  }}>
                  <Typography variant="h5" color="primary"  style={{  }}>
                    Gather Stories
                  </Typography>
                  <br />
                  <ShareLinkWrapper shareLink={createLink} copy={createLinkCopy} share={false}/>
                </Paper>
              </Grid>
            </Grid>
            
          </S.Wrapper>
        </>
      );
    }
  }
}

Memories.contextType = userContext;

export default Memories;
