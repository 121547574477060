// React
import React, { useState, useEffect, useRef } from "react";
// import Gallery from "react-photo-gallery";

// Styled Components
import { Main } from "./style";
import FileUploader from "../../Common/FileUploader";
import { PolaroidPhoto } from "../../../NewComponents/PolaroidPhoto/Single/PolaroidPhoto";
import ReactGA from "react-ga";
import imageCompression from "browser-image-compression";
import folder from "../../../assets/folder.png";
import Warning from "./warning";
import Groups from "@material-ui/icons/People";
import Friends from "@material-ui/icons/EmojiPeople";
import Stories from "@material-ui/icons/MenuBook";
import Camera from "@material-ui/icons/PhotoCamera";
import Gallery from "react-photo-gallery";
import * as C from "../../../constants/constantUrls";
import history from "../../../history";
import Masonry from "react-masonry-css";
import {
  Paper,
  Typography,
  Modal,
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Fade,
  Collapse,
  Zoom,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
  Avatar,
} from "@material-ui/core";
import Swal from "sweetalert2";

//Icons
import Edit from "@material-ui/icons/Edit";
import Share from "@material-ui/icons/Share";
import AddPhoto from "@material-ui/icons/AddAPhoto";
import Trash from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/CancelOutlined";

import DateFnsUtils from "@date-io/date-fns";
import {
  DatePicker as MaDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { album, comments, memories } from "../../../api";
import Icon from "../../Common/Icon/Icon";
import { formatCleanDate } from "../../../helpers/formatDate";
import CallToActionButton from "../../Common/GroupComponents/Buttons/CallToActionButton";
import SocialShare from "../../Common/SocialShare";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TumblrShareCount } from "react-share";

const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

export default (props) => {
  const [albumId, setAlbumId] = useState("");
  const [files, setFiles] = useState([]);
  const [albumData, setAlbumData] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [photos, setPhotos] = useState([]);
  const [editingTitle, setEditingTitle] = useState("");
  const [editingDate, setEditingDate] = useState(new Date());
  const [editingPhoto, setEditingPhoto] = useState({});
  const [showEditPhotoModal, setShowEditPhotoModal] = useState(false);
  const [showAddPhotosModal, setShowAddPhotosModal] = useState(false);
  const [showShareAlbumModal, setShowShareAlbumModal] = useState(false);
  const [showEditAlbumModal, setShowEditAlbumModal] = useState(false);
  const [showDeleteAlbumWarning, setShowDeleteAlbumWarning] = useState(false);
  const [showDeletePhotoWarning, setShowDeletePhotoWarning] = useState(false);
  const [showDeleteCommentWarning, setShowDeleteCommentWarning] = useState(
    false
  );
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [showingPhoto, setShowingPhoto] = useState({});
  const [commenterName, setCommenterName] = useState("");
  const [comment, setComment] = useState("");
  const [photoComments, setPhotoComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [coverPhoto, setCoverPhoto] = useState({});
  const [tempCoverPhoto, setTempCoverPhoto] = useState({});
  const [albumName, setAlbumName] = useState("");
  const [tempAlbumName, setTempAlbumName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [inviteLink, setInviteLink] = useState("");

  const imageInput = useRef();

  const handleChange = (files) => {
    const allFiles = Array.from(files);
    const fileData = allFiles.map((file) => {
      return { file, date: new Date(file?.lastModified) };
    });
    setFiles(fileData);
  };
  useEffect(() => {
    setAlbumId(props?.match?.params?.albumId);
    setUserId(props.id);
    setUserName(props.name);
    setInviteLink(window.location.href);
    getAlbum();
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // deals with uploading to firebase
  const directUploadToGoogle = async (uploadFiles) => {
    setIsUploading(true);
    const allFiles = Array.from(uploadFiles);
    const generatedFiles = [];
    const errors = [];
    const imgURLs = [];
    const compressionOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    setShowSpinner(true);
    await Promise.all(
      allFiles.map(async (image) => {
        const date = new Date(image?.lastModified);
        const compressedImage = await imageCompression(
          image,
          compressionOptions
        );
        const {
          data: { generatedName } = {},
          error,
        } = await memories.uploadImage({
          image: compressedImage,
          userId: albumId,
        });
        const { data } = await album.postAlbumPhoto({
          albumPhoto: {
            albumId,
            owner: {
              userId,
              name: userName,
            },
            image: {
              imagePath: generatedName,
            },
            title: "",
            date,
          },
        });
        const imgURL = URL.createObjectURL(compressedImage);
        generatedFiles.push({ imagePath: generatedName, image: imgURL });
        imgURLs.push({
          image: imgURL,
          date,
          _id: data?._id,
          title: "",
          owner: data?.owner,
          tempImage: { imagePath: generatedName },
          albumId,
        });

        if (error) errors.push(error);
      })
    );
    // setShowSpinner(false);

    // state to save the imageName to database
    // setShowSpinner(false);
    if (errors.length === 0) {
      setPhotos([...generatedFiles, ...photos]);
      // state for display images for preview
      setFiles([...imgURLs, ...files]);
      setIsUploading(false);
      setShowAddPhotosModal(false);
    }
  };

  const getAlbum = async () => {
    const { data } = await album.getAlbum({
      albumId: props?.match?.params?.albumId,
    });
    if (data) {
      setAlbumData(data);
      setCoverPhoto(data?.cover);
      setTempCoverPhoto(data?.tempCover);
      setAlbumName(data?.name);
      setTempAlbumName(data?.name);
      const photoPaths = data?.photos.map((photo) => {
        return {
          image: photo?.image?.imagePath,
          date: photo?.date,
          _id: photo?._id,
          title: photo?.title || "",
          owner: photo?.owner,
          tempImage: photo?.tempImage,
          albumId,
        };
      });
      setFiles(photoPaths);
      setIsLoading(false);
    }
  };

  const deleteAlbumPhoto = async (photoId) => {
    const { data } = await album.deleteAlbumPhoto({ photoId });
    if (data) {
      setShowEditPhotoModal(false);
      setShowPhotoModal(false);
      await getAlbum();
    }
  };

  // On click of save close modal  setShowEditPhotoModal(false)
  const editAlbumPhoto = async () => {
    const { data } = await album.updateAlbumPhoto({
      photo: {
        photoId: editingPhoto._id,
        date: editingDate,
        title: editingTitle,
        albumId,
        owner: editingPhoto?.owner,
        image: editingPhoto?.tempImage,
      },
    });
    if (data) {
      getAlbum();
      setShowEditPhotoModal(false);
    }
  };

  const onDateChange = (calDate) => {
    setEditingDate(calDate);
  };

  const openPhotoModal = async (photo) => {
    await getAlbumPhoto(photo?._id);
    setShowingPhoto(photo);
    setEditingPhoto(photo);
    setEditingDate(photo.date);
    setEditingTitle(photo.title);
    setShowPhotoModal(true);

    //get comments for photo
  };

  const decideName = () => {
    if (props?.name) {
      return props?.name;
    } else if (commenterName?.length > 0) {
      return commenterName;
    } else {
      return "Anonymous";
    }
  };

  const getAlbumPhoto = async (photoId) => {
    const { data } = await album.getAlbumPhoto({ photoId });
    if (data) {
      setPhotoComments(data?.comments);
    }
  };

  const postComment = async () => {
    const posterName = decideName();
    const { data } = await album.postAlbumComment({
      albumComment: {
        photoId: showingPhoto?._id,
        author: props?.id
          ? {
              name: posterName,
              userId: props?.id,
            }
          : {
              name: posterName,
            },
        text: comment,
        isOwner: props?.id
          ? albumData?.userId.toString() === props?.id.toString()
          : false,
      },
    });
    if (data) {
      await getAlbumPhoto(showingPhoto?._id);
      setComment("");
    }
  };

  const deleteComment = async (commentId) => {
    const { data } = await album.deleteAlbumComment({ commentId });
    if (data) {
      await getAlbumPhoto(showingPhoto?._id);
    }
  };

  const updateAlbum = async () => {
    const { data } = await album.updateAlbum({
      album: {
        albumId,
        name: albumName,
        cover: tempCoverPhoto,
        userId: albumData?.userId,
      },
    });
    if (data) {
      await getAlbum();
      setShowEditAlbumModal(false);
    }
  };

  const coverUploadToGoogle = async (e) => {
    // const userId = createForOther ? uploaderId : user.id;
    setIsUploading(true);
    const { files } = e.target;
    const allFiles = Array.from(files);
    const compressionOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    allFiles.map(async (image) => {
      const compressedImage = await imageCompression(image, compressionOptions);

      const {
        data: { generatedName } = {},
        error,
      } = await memories.uploadImage({
        image: compressedImage,
        userId: props.id,
      });

      const imgUrl = URL.createObjectURL(compressedImage);
      setCoverPhoto({ imagePath: imgUrl });
      setTempCoverPhoto({ imagePath: generatedName });
      const { data } = await album.updateAlbum({
        album: {
          albumId,
          name: albumName,
          userId: albumData?.userId,
          cover: { imagePath: generatedName },
        },
      });
      if (data) {
        await getAlbum();
      }
      setIsUploading(false);
    });
  };

  if (isLoading) {
    return <Main />;
  } else {
    return (
      <Main>
        <Typography
          align="center"
          variant="h3"
          gutterBottom
          style={{
            marginBottom: "2rem",
            color: "white",
            fontSize: window.innerWidth < 1024 ? "3rem" : "4rem",
          }}
        >
          {albumData?.name}
        </Typography>
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          {props?.id === albumData?.userId && (
            <Grid item>
              <CallToActionButton
                clickFunction={() => setShowEditAlbumModal(true)}
                startIcon={<Edit />}
                title="Edit Album"
              />
            </Grid>
          )}
          {props?.id && (
            <Grid item>
              <CallToActionButton
                clickFunction={() => setShowAddPhotosModal(true)}
                startIcon={<AddPhoto />}
                title="Add Photos"
              />
            </Grid>
          )}
          {props?.id === albumData?.userId && (
            <Grid item>
              <CallToActionButton
                clickFunction={() => setShowShareAlbumModal(true)}
                startIcon={<Share />}
                title="Share Album"
              />
            </Grid>
          )}
        </Grid>

        {/* WARNING MODALS */}

        {/* Delete Album Warning  */}

        <Modal
          open={showDeleteAlbumWarning}
          onClose={() => showDeleteAlbumWarning(false)}
          id="warning-delete-album"
        >
          <Warning
            clickFunction={() => setShowDeleteAlbumWarning(false)}
            setState="showDeleteAlbumWarning"
            warningName="Delete Album"
            warningText="Are you sure you want to delete this album and all of its pictures? You will not be able to undo this."
            // To do - Replace source with main image from album
            warningPicture=""
            postiveButtonText="Keep Album"
            negativeButtonText="Delete Album"
            positiveFunction={() => setShowDeleteAlbumWarning(false)}
            // To Do - Get negative function to delete the album
            // negativeFunction=
          />
        </Modal>

        {/* Delete Photo Warning  */}

        <Modal
          open={showDeletePhotoWarning}
          onClose={() => setShowDeletePhotoWarning(false)}
          id="warning-delete-photo"
        >
          <Warning
            clickFunction={() => setShowDeletePhotoWarning(false)}
            warningName="Delete Photo"
            warningText="Are you sure you want to delete this photo? You will not be able to undo this."
            // To do - Replace source with photo
            warningPicture="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Amanita_muscaria_%28fly_agaric%29.JPG/220px-Amanita_muscaria_%28fly_agaric%29.JPG"
            postiveButtonText="Keep Photo"
            negativeButtonText="Delete Photo"
            // To Do - Get Warning Click Fuctions to work and delete album
            positiveFunction={() => setShowDeletePhotoWarning(false)}
            // To Do - Get negative function to delete the photo from album
            // negativeFunction=
          />
        </Modal>

        {/* Delete Comment Warning  - */}

        <Modal
          open={showDeleteCommentWarning}
          onClose={() => setShowDeleteCommentWarning(false)}
          id="warning-comment-photo"
        >
          <Warning
            clickFunction={() => setShowDeleteCommentWarning(false)}
            warningName="Delete Comment"
            warningText="Are you sure you want to delete this comment? You will not be able to undo this."
            // To do - Replace source with photo
            warningPicture={folder}
            postiveButtonText="Keep Comment"
            negativeButtonText="Delete Comment"
            // To Do - Get Warning Click Fuctions to work and delete album
            positiveFunction={() => setShowDeleteCommentWarning(false)}
            // To Do - Get negative function to delete the comment. Currently not working
            // negativeFunction={() => deleteComment(comment?._id)}
          />
        </Modal>

        {/* MAIN MODALS */}
        {/* Edit Album Modal */}
        <Modal
          open={showEditAlbumModal}
          onClose={() => setShowEditAlbumModal(false)}
          id="add-photo-popup"
        >
          <Paper
            elevation={1}
            style={{
              position: "absolute",
              textAlign: "center",
              backgroundColor: "",
              color: "",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: window.innerWidth < 1024 ? "80%" : "50%",
              borderRadius: "2rem",
              overflow: "scroll",
              padding: "2rem",
              marginTop: window.innerHeight < 700 ? "5rem" : "1rem",
              marginBottom: "1rem",
            }}
          >
            <div style={{ position: "absolute", top: "20px", right: "20px" }}>
              <Close
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => setShowEditAlbumModal(false)}
              />
            </div>
            <Typography variant="h4" color="primary" align="left" gutterBottom>
              Edit Album
            </Typography>

            <Typography variant="body1" align="left" gutterBottom>
              What would you like to call this album?
            </Typography>
            <TextField
              id="album-title"
              value={albumName}
              onChange={(text) => setAlbumName(text.target.value)}
              variant="outlined"
              placeholder="Title your album"
              // label="Story Title"
              fullWidth
              style={{}}
            />
            <Typography
              variant="h5"
              color="primary"
              align="left"
              gutterBottom
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
            >
              Pick Cover Photo
            </Typography>

            <input
              ref={imageInput}
              accept="image/*"
              type="file"
              onChange={coverUploadToGoogle}
              style={{ display: "none" }}
              multiple={false}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection:
                  window.innerWidth < 900 ? "column-reverse" : "row",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-evenly",
                marginBottom: "2rem",
              }}
            >
              <CallToActionButton
                clickFunction={() => imageInput.current.click()}
                variant="contained"
                title="Pick Cover Photo"
                style={{
                  minHeight: "80px",
                  borderRadius: "100px",
                }}
              >
                {/* <AddAPhotoIcon style={{ marginBottom: '1rem' }} />  */}
              </CallToActionButton>
              {isUploading && (
                <CircularProgress color="secondary" width={200} height={200} />
              )}
              {coverPhoto?.imagePath && !isUploading && (
                <img
                  src={coverPhoto.imagePath}
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "1rem",
                    objectFit: "cover",
                  }}
                />
              )}
            </div>

            {/* <Grid container style={{ marginTop: "2rem", marginBottom: window.innerHeight < 700 ? "1rem" : "2rem" }}>
              <Grid item xs={7} sm={5}>
                <Typography variant="body1" align="left" gutterBottom>
                  Do you want to permenantly delete this album? 
                </Typography>
              </Grid>
              <Grid item xs={5} sm={2}>
                <Button
                  variant="outlined"
                  color="var(--primary-tangerine)"
                  onClick={() => setShowDeleteAlbumWarning(true)}
                  style={{
                    color: "#E26713",
                    borderRadius: "100px",
                    height: "40px",
                    border: "1px solid #E26713",
                    padding:'1rem',
                    marginLeft:'1rem',
                  }}
                >
                  <Trash style={{ fontSize: "1.5rem" }} />
                  &nbsp;Delete
                </Button>
              </Grid>
            </Grid> */}
            <CallToActionButton
              variant="contained"
              title="Save Changes"
              disabled={albumName.length === 0}
              clickFunction={async () => await updateAlbum()}
            />
          </Paper>
        </Modal>

        {/* Add Photos Modal */}

        <Modal
          open={showAddPhotosModal}
          onClose={() => setShowAddPhotosModal(false)}
          id="add-photo-popup"
        >
          <Paper
            elevation={1}
            style={{
              position: "absolute",
              textAlign: "center",
              backgroundColor: "",
              color: "",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: window.innerWidth < 900 ? "80%" : "50%",
              borderRadius: "2rem",
              overflow: "scroll",
              padding: window.innerWidth < 520 ? "1rem" : "2rem",
            }}
          >
            <div style={{ position: "absolute", top: "20px", right: "20px" }}>
              <Close
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => setShowAddPhotosModal(false)}
              />
            </div>
            <Typography
              variant="h4"
              color="primary"
              align="left"
              gutterBottom
              style={{ marginRight: "2rem" }}
            >
              Upload Photos
            </Typography>
            <div>
              {/* <div style={{ display:"flex", justifyContent:'center' }}>
                <img src={folder} style={{ marginBottom: "2rem" }} />
              </div> */}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FileUploader
                  handleChange={directUploadToGoogle}
                  name="file"
                  isUploading={isUploading}
                  types={fileTypes}
                  maxSize={200000}
                  multiple={true}
                />
              </div>
            </div>
            {/* {isUploading && (
              <CircularProgress color="secondary" width={200} height={200} style={{ marginTop: '1rem' }}/>
            )} */}
            <CallToActionButton
              variant="contained"
              title="Back To Album"
              clickFunction={() => setShowAddPhotosModal(false)}
            />
          </Paper>
        </Modal>

        {/* Share Modal */}

        {/* // Create Album Modal */}
        <Modal
          open={showShareAlbumModal}
          onClose={() => setShowShareAlbumModal(false)}
          id="create-group-popup"
        >
          <Paper
            elevation={1}
            style={{
              position: "absolute",
              textAlign: "center",
              backgroundColor: "",
              color: "",
              left: "50%",
              transform: "translate(-50%, -50%)",
              top: "50%",
              maxHeight: "80%",
              width: window.innerWidth < 1024 ? "80%" : "50%",
              borderRadius: "2rem",
              overflow: "scroll",
              padding: "2rem",
            }}
          >
            <div style={{ position: "absolute", top: "20px", right: "20px" }}>
              <Close
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => setShowShareAlbumModal(false)}
              />
            </div>
            <Typography variant="h4" color="primary" align="left" gutterBottom>
              Share Album
            </Typography>

            <Typography
              variant="body1"
              align="center"
              gutterBottom
              style={{ marginBottom: "2rem" }}
            >
              <span
                style={{
                  display: window.innerWidth < 605 ? "none" : "inline-block",
                }}
              >
                {" "}
                Building albums is a lot easier when we do it together.
              </span>{" "}
              Every friend or family member you add here will be able to see all
              the photos in this album and add their own photos.
            </Typography>
            {/* To do Connect Friends To Album */}
            {/* <Grid container spacing={1}>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  label="Add friends' emails"
                  placeholder="friend@email.com"
                  helperText={
                    "Tip: Seperate multiple emails with a comma"
                  }
                  variant="outlined"
                  className="addFriendInputField"
                  // value={newEmail}
                  // onFocus={() => setInputFocused(true)}
                  // onBlur={() => setInputFocused(false)}
                  // onChange={(e) => setNewEmail(e.target.value)}
                >
                  Enter a name
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  className="addFriendButton"
                  // disabled={waitingToAdd}
                  // onClick={() => addFriendFunc(newEmail)}
                  style={{
                    minHeight: "50px",
                    fontWeight: "700",
                    boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                  }}
                >
                  Add friend
                </Button>
              </Grid>
            </Grid> */}
            <Grid item xs={12}>
              {/* <Typography align="center" style={{ marginBottom: "1rem" }}>
                - OR -
              </Typography> */}
              <Typography >
                Click the button below to copy your unique sign-up link&nbsp;
                <span
                  style={{
                    display: window.innerWidth < 430 ? "none" : "inline-block",
                  }}
                >
                  to share with your loved ones,
                </span>
               
              </Typography>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {navigator.share ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      if (navigator.share) {
                        navigator.share({
                          title: "Iternal",
                          text: "Invite loved ones to your Iternal photo book",
                          url: inviteLink,
                        });
                      }
                    }}
                    style={{
                      marginTop: "2rem",
                      // borderRadius: "100px",
                      minWidth: "164px",
                      minHeight: "50px",
                      fontWeight: "700",
                      boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                    }}
                  >
                    Click to Copy Link
                  </Button>
                ) : (
                  <CopyToClipboard text={inviteLink}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        Swal.fire(
                          "Done!",
                          "The link has been copied",
                          "success"
                        )
                      }
                      style={{
                        marginTop: "2rem",
                        // borderRadius: "100px",
                        minWidth: "164px",
                        minHeight: "50px",
                        fontWeight: "700",
                        boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                      }}
                    >
                      Click to Copy Link
                    </Button>
                 
                  </CopyToClipboard>
                )}
              </div>
              <Typography align="center" style={{marginTop:"1rem"}}>
                  -OR-
              <br></br>
                  share through social media platforms </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
              >
            
              
                <SocialShare
                  link={inviteLink}
                  messageBody={"Hey! Check out my Iternal photo album!"}
                  messageTitle={"Check out my Iternal photo album!"}
                />
              </div>
              {/* <div style={{marginTop: "2rem", marginBottom:'2rem', justifyContent:"center"}}>
                <Typography
                  variant="h5"
                  color="primary"
                  align="left"
                  gutterBottom
                  style={{  }}
                >
                  Current Contributors
                </Typography>
  
  
                <div
                  style={{
                    marginTop: "2rem",
                    display: "flex",
                    justifyContent:'center',
                    marginBottom: "2rem",
                  }}
                >
                  <Paper
                    elevation={0}
                    style={{
                      padding: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "150px",
                      height: "150px",
                      borderRadius: "20px",
                      border: "solid 2px #0bafaa",
                      color: "#0bafaa",
                      background: "white",
                      flexDirection: "column",
                    }}
                  >
                    <Avatar
                      src="http://clipart-library.com/img/1788199.png"
                      alt="Your profile avatar"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        width: "70px",
                        height: "70px",
  
                        margin: "0 auto",
                      }}
                    />
                    <Typography variant="h5" style={{}}>
                      Name
                    </Typography>
                    <Grid
                      container
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{ width: "50%" }}
                          color="secondary"
                        >
                          View
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          style={{
                            width: "50%",
                            color: "#E26713",
                            border: "1px solid #E26713",
                          }}
                        >
                          {" "}
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </div> */}

              <CallToActionButton
                variant="contained"
                color="secondary"
                title="Back To Album"
                clickFunction={() => setShowShareAlbumModal(false)}
              ></CallToActionButton>
              {/* </div> */}
            </Grid>
          </Paper>
        </Modal>

        {/* Edit Individual Photo Modal */}
        <Modal
          open={showEditPhotoModal}
          onClose={() => setShowEditPhotoModal(false)}
          id="edit-photo-popup"
        >
          <Paper
            elevation={1}
            style={{
              position: "absolute",
              textAlign: "center",
              backgroundColor: "",
              color: "",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "90%",
              maxHeight: "700px",
              maxWidth: "1250px",
              width: "95%",
              borderRadius: "2rem",
              overflow: "hidden",
              background: "white",
            }}
          >
            <Grid container style={{ height: "100%" }}>
              {window.innerWidth > 959 && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    height: "100%",
                    padding: "1rem",
                  }}
                >
                  {/* {showingPhoto?.title && (
                    <Typography>{showingPhoto?.title}</Typography>
                  )}
                  {showingPhoto?.date && (
                    <Typography>{formatCleanDate(showingPhoto?.date)}</Typography>
                  )} */}

                  <img
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                    }}
                    src={showingPhoto?.image}
                  />
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={6}
                style={{ padding: "2rem", height: "100%", background: "white" }}
              >
                <div
                  style={{ position: "absolute", top: "20px", right: "20px" }}
                >
                  <Close
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowEditPhotoModal(false)}
                  />
                </div>

                <Typography
                  variant="h4"
                  color="primary"
                  align="left"
                  gutterBottom
                >
                  Edit Photo
                </Typography>
                <Typography variant="body1" align="left" gutterBottom>
                  What would you like to call this photo ?
                </Typography>
                <TextField
                  id="photo-title"
                  value={editingTitle}
                  onChange={(text) => setEditingTitle(text.target.value)}
                  variant="outlined"
                  placeholder="Title your photo"
                  // label="Story Title"
                  fullWidth
                  style={{ marginBottom: "1rem" }}
                />

                <Typography variant="body1" align="left" gutterBottom>
                  When was this photo taken?
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <MaDatePicker
                    openTo="year"
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    placeholder="Select a date"
                    fullWidth
                    onChange={onDateChange}
                    maxDate={new Date().toISOString().split("T")[0]}
                    value={editingDate || new Date()}
                    style={{ marginBottom: "1rem" }}
                  />
                </MuiPickersUtilsProvider>
                {/* <Grid
                  container
                  id="add-to-timeline-check"
                  style={{ marginTop: "2rem" }}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" align="left" gutterBottom>
                      Do you want to add this photo to your favourite stories on
                      your timeline?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justify="center"
                    >
                      <Grid item>No</Grid>
                      <Grid item>
                        <Switch
                          color="primary"
                          style={{ color: "#10c1b8" }}
                        />
                      </Grid>
  
                      <Grid item>Yes</Grid>
                    </Grid>
                  </Grid>
                </Grid> */}
                <Grid
                  container
                  id="add-to-timeline-check"
                  style={{ marginTop: "2rem" }}
                >
                  <Grid item xs={12} sm={7}>
                    <Typography variant="body1" align="left" gutterBottom>
                      Do you want to permenantly delete this photo?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You won't be able to revert this!",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#66CC66",
                          cancelButtonColor: "#8a8787",
                          confirmButtonText: "Yes, delete",
                        }).then((result) => {
                          if (result.value) {
                            deleteAlbumPhoto(editingPhoto._id);
                          }
                        })
                      }
                      style={{
                        color: "#E26713",
                        borderRadius: "100px",
                        height: "40px",
                        border: "1px solid #E26713",
                      }}
                    >
                      <Trash style={{ fontSize: "1.5rem" }} />
                      &nbsp;Delete Photo
                    </Button>
                  </Grid>
                </Grid>

                {/* // To Do - Close Modal */}
                <Grid style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                  <CallToActionButton
                    variant="contained"
                    color="primary"
                    title="Save Changes"
                    clickFunction={() => editAlbumPhoto()}
                  ></CallToActionButton>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Modal>
        {/* Add Comment Modal */}
        <Modal open={showPhotoModal} onClose={() => setShowPhotoModal(false)}>
          <Paper
            elevation={1}
            style={{
              position: "absolute",
              textAlign: "center",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "90%",
              maxHeight: "700px",
              width: "95%",
              maxWidth: "1250px",
              borderRadius: "2rem",
              overflow: "scroll",
            }}
          >
            <Grid container style={{ height: "100%" }}>
              {window.innerWidth > 0 && (
                <Grid
                  item
                  id="picture-album-zoom"
                  xs={12}
                  sm={6}
                  style={{
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    maxHeight: window.innerWidth < 600 ? "50vh" : "100%",
                    padding: "1rem",
                  }}
                >
                  {/* {showingPhoto?.title && (
                    <Typography>{showingPhoto?.title}</Typography>
                  )}
                  {showingPhoto?.date && (
                    <Typography>{formatCleanDate(showingPhoto?.date)}</Typography>
                  )} */}

                  <img
                    style={{
                      objectFit: "contain",
                      maxHeight: window.innerWidth < 600 ? "50vh" : "100%",
                      width: "100%",
                    }}
                    src={showingPhoto?.image}
                  />
                </Grid>
              )}
              <Grid
                item
                id="text-album-zoom"
                xs={12}
                sm={6}
                style={{
                  padding: "2rem",
                  height: "100%",
                  background: "white",
                  overflowY: "scroll",
                }}
              >
                <div
                  style={{ position: "absolute", top: "20px", right: "20px" }}
                >
                  <Close
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPhotoModal(false)}
                  />
                </div>
                {showingPhoto?.title && (
                  <Typography variant={"h5"}>{showingPhoto.title}</Typography>
                )}
                <div
                  style={{
                    marginTop: window.innerWidth < 600 ? "-1rem" : "1rem",
                  }}
                >
                  {!props?.name && (
                    <TextField
                      id="commenter-name"
                      value={commenterName}
                      onChange={(text) => setCommenterName(text.target.value)}
                      variant="outlined"
                      placeholder="Your Name"
                      fullWidth
                      style={{ marginBottom: "1rem" }}
                    />
                  )}
                  <TextField
                    id="comment"
                    value={comment}
                    onChange={(text) => setComment(text.target.value)}
                    variant="outlined"
                    placeholder={
                      props?.id === albumData?.userId
                        ? "Tell us about this photo"
                        : "What do you remember?"
                    }
                    fullWidth
                    multiline
                    rows={3}
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <CallToActionButton
                      variant="contained"
                      color="primary"
                      clickFunction={() => postComment()}
                      title={
                        props?.id === albumData?.userId ? "Add" : "Add Comment"
                      }
                    />
                  </div>
                </div>
                <Typography
                  color="primary"
                  variant="h4"
                  align="left"
                  style={{ margin: "0.5rem 0" }}
                >
                  {" "}
                  Comments{" "}
                </Typography>
                <div style={{ height: window.innerWidth < 600 ? "40vh" :"25vh" }}>
                  {photoComments.length > 0 && (
                    <div
                      style={{
                        padding: "0.5rem",
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: "20vh",
                        overflow: "scroll",
                      }}
                    >
                      {photoComments?.map((comment) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            alignItems: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              textAlign: "left",
                              marginBottom: "2rem",
                            }}
                          >
                            <Typography>{comment?.text}</Typography>
                            <Typography color="primary">
                              {comment?.author?.name}
                            </Typography>
                            {/* <Typography>
                            {formatCleanDate(comment?.createdAt)}
                          </Typography> */}
                          </div>
                          {props?.id === albumData?.userId && (
                            <Button
                              variant="text"
                              // color="var(--primary-tangerine)"
                              // onClick={() => deleteComment(comment?._id)}
                              // onClick={() => setShowDeleteCommentWarning(true)}
                              onClick={() =>
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: "You won't be able to revert this!",
                                  type: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#66CC66",
                                  cancelButtonColor: "#8a8787",
                                  confirmButtonText: "Yes, delete",
                                }).then((result) => {
                                  if (result.value) {
                                    deleteComment(comment?._id);
                                  }
                                })
                              }
                              style={{
                                color: "var(--secondary-cherry)",
                                marginLeft: "2rem",
                              }}
                            >
                              <Trash style={{ fontSize: "1.5rem" }} />
                            </Button>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {props?.id === albumData?.userId && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                     
                    }}
                  >
                    <CallToActionButton
                      clickFunction={() => setShowEditPhotoModal(true)}
                      title="Edit Photo"
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Modal>

        {/* Main Page */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: "1rem",
            maxWidth: "1000px",
            width: "90%",
            marginBottom: "100px",
            padding: "1rem",
          }}
        >
          {files.length === 0 ? (
            <FileUploader
              handleChange={directUploadToGoogle}
              isUploading={isUploading}
              name="file"
              types={fileTypes}
              maxSize={200000}
              multiple={true}
            />
          ) : (
            <Masonry
              breakpointCols={{
                default: 3,
                1100: 2,
                700: 2,
                500: 1,
              }}
            >
              {files.map((f) => {
                return (
                  <div
                    className="masonry-object"
                    // style={{ padding: "0.5rem" }}
                    key={f._id}
                    onClick={() => {
                      openPhotoModal(f);
                    }}
                  >
                    <PolaroidPhoto
                      // width={200}
                      image={f.image}
                      slideIn={false}
                      date={formatCleanDate(f.date)}
                      isOwner={true}
                      title={f?.title}
                      deleteFunction={() => deleteAlbumPhoto(f._id)}
                      editFunction={() => {
                        setEditingPhoto(f);
                        setEditingDate(f.date);
                        setEditingTitle(f.title);
                        setShowEditPhotoModal(true);
                      }}
                    />
                  </div>
                );
              })}
            </Masonry>
          )}
        </div>
       
      </Main>
    );
  }
};
