// React
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

// Bottom nav
import Groups from "@material-ui/icons/People";
import Friends from "@material-ui/icons/EmojiPeople";
import Profile from "@material-ui/icons/Face";
import Stories from "@material-ui/icons/MenuBook";
import Camera from "@material-ui/icons/PhotoCamera"


import * as C from "../../../constants/constantUrls";

// MUI
import { Button, Grid, Paper, Typography } from '@material-ui/core';

// Components
import HeaderShareMemory from '../../Common/HeaderShareMemory';
import imagePlaceHolder from '../../../assets/person-placeholder.jpg';
import MemoriesTable from '../../Common/Tables/MemoriesTable';
import { NewTimeline } from '../../../NewComponents/Dashboard/Blocks/NewTimeline/NewTimeline';

// Constants
import { SIGNUP_URL } from '../../../constants/constantUrls';
import { linkTokens } from '../../../api';

// Styles
import { Main, MemoryButtonWrapper } from './style';
import ReactGA from 'react-ga';

const MyFriendMemories = ({ match, history }) => {
  const { shortId } = match.params;
  const [friendName, setFriendName] = useState('');
  const [friendMemories, setFriendMemories] = useState([]);
  const [friendImage, setFriendImage] = useState({});
  const [isList, setIsList] = useState(false);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    const getFriendMemories = async () => {
      const { data, error } = await linkTokens.getUserInfoAndMemoriesAndByToken(
        {
          shortId,
        },
      );
      if (error) {
        // handle invalid shareId
        return history.push(SIGNUP_URL);
      }
      const sortedMemories = data.user.memories
        .slice()
        .sort((a, b) => new Date(b.date) - new Date(a.date));
      setFriendMemories(sortedMemories);
      if (data.user.abouts[0]?.image) {
        setFriendImage(data.user.abouts[0].image);
      }
      return setFriendName(data.user?.name ? data.user?.name : data.user?.email );
    };
    getFriendMemories();
  }, [shortId, history]);

  const renderListOrNothing = () =>
    friendMemories && friendMemories.length > 0 ? (
      <MemoriesTable memories={friendMemories} viewingFriendsMemory={true} profile={{picture: friendImage?.imagePath, name: friendName}}/>
    ) : (
      <p>{friendName} hasn&apos;t added any memories yet!</p>
    );

  const renderTimelineOrNothing = () =>
    friendMemories && friendMemories.length > 0 ? (
      <div
        style={{                               
          height: '59vh', 
          overflow: 'auto', 
          position: 'initial', 
          background: 'none',
          borderRadius: '1rem 1rem 0 0' 
        }}
      >
        <NewTimeline memories={friendMemories} showPrompts={false} viewingFriendsMemory={true} profile={{picture: friendImage?.imagePath, name: friendName}}/>
      </div>
    ) : (
      <p>{friendName} hasn&apos;t added any memories yet!</p>
    );

  return (
    <Main style={{marginLeft: window.innerWidth < 1024 ? "0rem" : '3rem'}}>
      <HeaderShareMemory nameMemoryOwner={friendName} contentType="share" />
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
       
          <Paper elevation={3} style={{ marginRight:'1rem', marginLeft:'1rem', padding:  window.innerWidth < 1350 ? "1rem" : '2rem', textAlign: 'center' }}>
            <div
              className="bg_image mb-4"
              style={{
                backgroundImage: `url(${friendImage.imagePath ||
                  imagePlaceHolder})`,
                backgroundSize: 'cover',
                height: window.innerWidth <  700 ? "80px" : '100px',
                width: window.innerWidth <  700 ? "80px" :'100px',
                borderRadius: '1000px',
                backgroundPosition: 'center',
                margin: '0 auto',
                color: '#f5f5f5',
              }}
            />
            <h1>{friendName}</h1>
            <p>
              {friendMemories.length}{' '}
              {friendMemories.length > 1 ? 'memories' : 'memory'}
            </p>
            <NavLink
              to={`/create/${shortId}`}
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                color="secondary"
                style={{
          marginTop: '1rem',
          borderRadius: '100px',
          Width: '164px',
          minHeight: '50px',
          fontWeight:"700",
          boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',}}
              >
                Send A Memory
              </Button>
            </NavLink>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9} >
          <Paper elevation={0} style={{ background:'none', paddingLeft:  '0rem', marginTop:'-2rem', paddingRight:'0rem', marginBottom: window.innerWidth < 1024 ? "4rem" : "2rem",
 }}>
            {friendMemories && friendMemories.length > 0 && (
              <MemoryButtonWrapper>
                {/* <Button
                  variant="contained"
                  color="secondary"
                  fullwidth
                  onClick={() => setIsList(true)}
                  style={{
          marginTop: '1rem',
          minHeight: '50px',
          fontWeight:"800",
          boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',}}
                >
                  Story List
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  fullwidth
                  onClick={() => setIsList(false)}
                  style={{
          marginTop: '1rem',
          minHeight: '50px',
          fontWeight:"800",
          boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',}}
                >
                   Timeline
                </Button> */}
              </MemoryButtonWrapper>
            )}
            {isList ? renderListOrNothing() : renderTimelineOrNothing()}
          </Paper>
        </Grid>
      </Grid>
    </Main>
  );
};

export default MyFriendMemories;
