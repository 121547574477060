import React from "react";

import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

import { FaEnvelope } from 'react-icons/fa';

import { FacebookIcon, WhatsappIcon, TwitterIcon, LinkedinIcon } from "react-share";

import { Wrapper, SocialItem } from "./style";

const SocialShare = ({ link, messageTitle, messageBody }) => {
  return (
    <Wrapper>
      <SocialItem>
        <WhatsappShareButton url={link} title={messageBody} separator=": ">
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </SocialItem>

      <SocialItem>
        <FacebookShareButton url={link} quote={messageBody}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </SocialItem>

      <SocialItem>
        <TwitterShareButton url={link} quote={messageBody}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </SocialItem>

      <SocialItem>
        <LinkedinShareButton url={link} quote={messageBody}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </SocialItem>

      <SocialItem>
        <EmailShareButton
          url={link}
          subject={messageTitle}
          body={messageBody}
          separator=": "
        >
          <FaEnvelope size={32}  />
        </EmailShareButton>
      </SocialItem>
    </Wrapper>
  );
};

export default SocialShare;
