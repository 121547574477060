import React, { useState, useEffect, useRef } from "react";
import history from "../../../history";

import {
  Paper,
  Typography,
  Button,
  Grid,
  // Slide,
  Fade,
} from "@material-ui/core";

// Styled Components
import { PaperWrapper, AddFirstMemoryButton } from "../Onboarding.style";
import { PromptGenerator } from "../../Pages/CreateMemory/PromptGenerator/PromptGenerator";
import { prompts } from "../../../api";
// MUI

// Custom components
import { ImageButton } from "../../ImageButton/ImageButton";
import { ReactComponent as GiftIcon } from "../../../assets/gift.svg";
import child from "../../../assets/onboarding/grandad.jpg";

export function OnboardingStep4({
  category = "",
  changeCategory,
  pathToNextStep = "",
  prompt = {},
  setPrompt = () => {},
  competition = {},
  ...props
}) {
  /* Transition States */
  const [cardTransition, setCardTransition] = useState(false);
  const [titleTransition, setTitleTransition] = useState(false);
  const [descriptionTransition, setDescriptionTransition] = useState(false);
  const [inputTransition, setInputTransition] = useState(false);
  const [buttonTransition, setButtonTransition] = useState(false);
  const [allPrompts, setAllPrompts] = useState([]);
  /* Scroll Anchor */
  const buttonRef = useRef();

  /* Transition Timings */
  useEffect(() => {
    getPrompts();
    setTimeout(() => {
      setCardTransition(true);
    }, 500);
    setTimeout(() => {
      setTitleTransition(true);
    }, 1000);
    setTimeout(() => {
      setDescriptionTransition(true);
    }, 1500);
    setTimeout(() => {
      setInputTransition(true);
    }, 2000);
    setTimeout(() => {
      setButtonTransition(true);
    }, 2500);
  });

  const getPrompts = async () => {
    const { data: allData } = await prompts.getAllPrompts();
    setAllPrompts(allData);
  };

  function ImageButtonFunc(buttonCategory) {
    // Select category
    if (category !== buttonCategory) {
      return () => {
        changeCategory(buttonCategory, buttonRef);
      };
    }
    //               return history.push({
    //   pathname: ONBOARDING_FLOW,
    //   state: { isReferral: !!shortId, shortId },
    // });
    // Or proceed if category already selected
    return () => {
      history.push({
        pathname: pathToNextStep,
        state: { firstMem: true },
        search: prompt && prompt?._id ? `?prompt=${prompt?._id}` : ``,
      });
    };
  }

  const getRandomPrompt = () => {
    const randomPos = Math.floor(Math.random() * allPrompts.length);
    setPrompt(allPrompts[randomPos]);
    changeCategory(allPrompts[randomPos].category);
  };

  return (
    <Grid container spacing={0} style={{ margin: "0px", padding: "0" }}>
      <Grid
        item
        id=""
        xs={12}
        md={5}
        lg={6}
        style={{
          backgroundColor: "",
          display: window.innerWidth < 1280 ? "none" : "block",
          height: "100vh",
          height: "100vh",
        }}
      >
        <img
          src={child}
          style={{
            height: window.innerWidth < 960 ? "300px" : "100%",
            width: "100%",
            objectFit: window.innerWidth < 600 ? "fill" : "fill",
            backgroundPosition: "top",
            padding: "-1rem",
          }}
        />
      </Grid>
      <Grid item id="profile-text" xs={12} md={12} lg={6}>
        <Paper
          elevation={0}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            borderRadius: "20px",
            padding: window.innerWidth < 600 ? "1rem" : "2rem",
            maxWidth: "550px",
            margin: window.innerWidth < 600 ? "1rem" : "5rem auto",
            backgroundColor: "white",
            textAlign: "center",
            minHeight: '80vh',
          }}
        >
          <Typography
            gutterBottom
            style={{
              textAlign: "left",
              fontWeight: "bolder",
              fontSize: "2rem",
              display: window.innerWidth < 450 ? "none" : "block",
            }}
          >
            Create your first story
          </Typography>
          <Typography
            gutterBottom
            style={{
              textAlign: "left",
              fontWeight: "bolder",
              fontSize: "2rem",
              display: window.innerWidth > 450 ? "none" : "block",
            }}
          >
            Create your story
          </Typography>
          {/* </Fade> */}
          <br />
          {/* <Fade in={descriptionTransition} timeout={1000}> */}
          <>
            <Typography
              margin="normal"
              variant="body1"
              style={{ marginBottom: "2rem", textAlign: "left" }}
            >
              We know getting started can be daunting. To make things easier,
              why not spin the story prompter for an idea of what to write
              about.
            </Typography>
            {competition && competition?.data && (
              <div
                style={{
                  padding: "1rem",
                  backgroundColor: "#e26713",
                  borderRadius: "1rem",
                  margin: "1rem",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                    <GiftIcon width={50} height={50} />
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      style={{
                        color: "var(--neutral-white)",
                        fontWeight: "bold",
                      }}
                    >
                      As part of the competition, each memory you create gives
                      you an additional two entries to win!
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}
          </>
          {/* </Fade> */}
          <br />
          {/* <div style={{backgroundColor:"#0A2156", color:"white", height: "106px", maxWidth:'full', marginTop:"2rem", marginBottom: "2rem", paddingTop:'2rem', textAlign:"center"}}> Prompt Generator goes here </div> */}

          <Paper
            elevation={0}
            style={{
              marginBottom: window.innerHeight < 700 ? "1rem" : "2rem",
              width: "100%",
              textAlign: "center",
            }}
          >
            <PromptGenerator
              prompt={prompt?.prompt}
              promptCategory={category}
              showRandomPrompt={getRandomPrompt}
            />
          </Paper>
          <Grid container spacing={1}>
            {/* <Grid item xs={12} sm={6}>
                    <ImageButton
                      isHighlighted={category === 'Food'}
                      text="Food"
                      image="food"
                      onClick={ImageButtonFunc('Food')}
                    />
                  </Grid> */}
            {/* <Grid item xs={12} sm={6}>
                    <ImageButton
                      isHighlighted={category === 'Good Old Days'}
                      text="Good Old Days"
                      image="goodOldDays"
                      onClick={ImageButtonFunc('Good Old Days')}
                    />
                  </Grid> */}
            {/* <Grid item xs={12} sm={6}>
                    <ImageButton
                      isHighlighted={category === 'Family'}
                      text="Family"
                      image="family"
                      onClick={ImageButtonFunc('Family')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ImageButton
                      isHighlighted={category === 'Holidays'}
                      text="Holidays"
                      image="holidays"
                      onClick={ImageButtonFunc('Holidays')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ImageButton
                      isHighlighted={category === 'Love'}
                      text="Love"
                      image="love"
                      onClick={ImageButtonFunc('Love')}
                    />
                  </Grid> */}
            {/* <Grid item xs={12} sm={6}>
                    <ImageButton
                      isHighlighted={category === 'Hobbies'}
                      text="Hobbies"
                      image="hobbies"
                      onClick={ImageButtonFunc('Hobbies')}
                    />
                  </Grid> */}
            {/* <Grid item xs={12} sm={6}>
                    <ImageButton
                      isHighlighted={category === 'Milestones'}
                      text="Milestones"
                      image="milestones"
                      onClick={ImageButtonFunc('Milestones')}
                    />
                  </Grid> */}
            {/* <Grid item xs={12} sm={12}>
                    <ImageButton
                      isHighlighted={category === 'Create Your Own'}
                      text="Create Your Own"
                      image="createYourOwn"
                      onClick={ImageButtonFunc('Create Your Own')}
                    />
                  </Grid> */}
          </Grid>
          {/* </Fade> */}
          <br />
          {/* <Fade in={buttonTransition} timeout={1000}> */}
          <AddFirstMemoryButton>
            {pathToNextStep !== "" ? (
              <Button
                ref={buttonRef}
                fullWidth
                // disabled={category === ''}
                // size="large"
                variant="contained"
                color="secondary"
                style={{
                  height: "3rem",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  borderRadius: "100px",
                  boxShadow: "rgba(0, 0, 0, 0.95) 0px 25px 20px -20px",
                  maxWidth: "432px",
                  marginBottom: window.innerHeight < 700 ? "1rem" : "2rem",
                }}
                disabled={!prompt?._id}
                onClick={() => {
                  history.push({
                    pathname: pathToNextStep,
                    state: { firstMem: true },
                    search:
                      prompt && prompt?._id ? `?prompt=${prompt?._id}` : ``,
                  });
                }}
              >
                Create This Story
              </Button>
            ) : (
              <Button
                ref={buttonRef}
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
                style={{
                  height: "3rem",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  borderRadius: "100px",
                  boxShadow: "rgba(0, 0, 0, 0.95) 0px 25px 20px -20px",
                  maxWidth: "432px",
                  marginBottom:"2rem"
                }}
              >
                Create This Story
              </Button>
            )}
          </AddFirstMemoryButton>
          <AddFirstMemoryButton>
            {pathToNextStep !== "" ? (
              <Button
                ref={buttonRef}
                fullWidth
                // disabled={category === ''}
                // size="large"
                variant="text"
                style={{
                  fontSize: "1rem",
                }}
                onClick={() => {
                  history.push({
                    pathname: pathToNextStep,
                    state: { firstMem: true },
                  });
                }}
              >
                <Grid
                  container
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid item>Already have an idea?&nbsp;</Grid>
                  <Grid
                    item
                    style={{
                      textDecoration: "underline",
                      color: " #0A2156",
                      fontWeight: "bold",
                    }}
                  >
                    Create your own
                  </Grid>
                </Grid>
              </Button>
            ) : (
              <Button
                ref={buttonRef}
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
                style={{
                  height: "3rem",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  borderRadius: "100px",
                  boxShadow: "rgba(0, 0, 0, 0.95) 0px 25px 20px -20px",
                  maxWidth: "432px",
                }}
              >
                Already have an idea? Create your own
              </Button>
            )}
          </AddFirstMemoryButton>
        </Paper>
      </Grid>
    </Grid>
  );
}
