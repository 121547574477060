import styled from 'styled-components';

import { breakpoints, breakpointsMax } from '../../../constants/breakPoints';

export const Main = styled.main`
  padding: 2rem 1rem 1rem 1rem;
  max-width: 100rem;
  margin: 0 auto;

  @media ${breakpointsMax.tablet} {
    padding: 6rem 1rem 1rem 1rem;
  }

  @media ${breakpoints.tablet} {
    padding: 2rem 3rem 1rem 280px;
  }
  .help-center-title {
    margin-bottom: 1rem;
  }
  section {
    h1 {
      text-align: center;
      margin-bottom: 2rem;
    }
  }
`;

export const MainTitle = styled.h1`
  margin-bottom: 3rem;
`;

export const VideoGrid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 3rem;
  text-align: center;
  height: 450px;
  @media ${breakpointsMax.mobileXL} {
    grid-template-columns: 1fr;
    gap: 2rem;
    height: 100%;
  }
`;
export const Video = styled.div`
  text-align: center;
  border: 1px solid;
`;

export const VideoTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const ArticleGrid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 3rem;
  @media ${breakpointsMax.tablet} {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;
export const Article = styled.div`
  text-align: center;
  border: 1px solid;
`;

export const FaqSection = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;

  .accordion {
    background: white;
    border: none;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
    .accordion-item {
      .title {
        background: white;
        font-size: 1rem;
        margin-bottom: 0px;
      }
    }
  }
`;
export const Faq = styled.div`
  text-align: left;
  border: 1px solid;
`;

export const GridItem = styled.div`
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
  cursor: pointer;

  .video-player {
    padding: 20px 0px 30px 0px;
  }
`;

// Modal styles

export const ModalButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--primary);
  font-weight: bold;
  text-decoration: underline;
  align-self: ${props => (props.right ? 'flex-end' : 'auto')};
  margin-bottom: 2rem;
`;

export const ModalBackground = styled.div`
  justify-content: center;
  align-items: center;
  background-color: rgb(0 0 0 / 31%);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  overflow: scroll;

  /* gives a small margin to all descendants */
  & > * {
    margin: 0.5rem 0;
  }
`;

export const ModalContent = styled.div`
  width: 80%;
  height: 80%;
  background: white;

  @media ${breakpointsMax.tablet} {
    width: 100%;
    height: 100%;
  }
`;

export const PageTitle = styled.h1`
  margin: 0.5rem auto;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 4rem;
`;
