import React, { Component } from "react";
import axios from "axios";
import * as S from "./style";
import { Paper, Typography, Grid, Button } from "@material-ui/core";

import { API_SHARE_MEMORY } from "../../../constants/apiRoutes";
import { LOGIN_URL, SIGNUP_URL } from "../../../constants/constantUrls";
import { sizeNum } from "../../../constants/breakPoints";
import loader from "../../../assets/loader.svg";
import HeaderLogout from "../../Common/HeaderLogout";
import Header from "../../Common/Header/Header";
import ViewAndPreviewMemory from "../ProfileMemoriesViewEdit";
import ReactGA from "react-ga";
import logo from "../../../assets/onboarding/logo-white.png";


export default class ShareMemory extends Component {
  state = {
    memory: {},
    isLoading: true,
    memoryId: "",
    loggedIn: false,
    props: {},
  };

  componentDidMount = async () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    try {
      if (this.props.match && this.props.match.params) {
        const { id: memoryId } = this.props.match.params;

        const { data } = await axios.get(`${API_SHARE_MEMORY}/${memoryId}`);
        this.setState({
          memory: data,
          memoryId,
          isLoading: false,
          loggedIn: this.props.isLoggedIn,
          props: this.props,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // from ProfileMemoriesView.js
  formatDate = (date) => date.split("T")[0].replace(/-/g, "/");

  // TODO: Render memory on page and style.
  // include links to login and signup for mobile
  //removed View Memory title in HeaderLogout
  render() {
    const {
      title,
      date,
      memory,
      voiceRecording,
      videoRecording,
      images,
      user,
    } = this.state.memory;
    const { loggedIn, props } = this.state;
    const { windowWidth, history } = this.props;
    return (
      <S.Wrapper>
        {loggedIn ? (
          <Header {...this.props} />
        ) : (
          <HeaderLogout title="" windowWidth={windowWidth} />
        )}

        {this.state.isLoading ? (
          <img alt="loader" src={loader} />
        ) : (
          <>
            <ViewAndPreviewMemory
              viewMode="viewOther"
              data={{
                _id: this.state.memoryId,
                userId: user,
                title,
                date: this.formatDate(date),
                memory,
                voiceRecording,
                videoRecording,
                images,
                loggedIn,
                props,
              }}
              location={{}}
            />
            {windowWidth < sizeNum.laptop && !loggedIn && (
              <S.ButtonWrapper>
                <Grid
                  container
                  spacing={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2rem",
                  }}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      name="signup"
                      fullWidth
                      style={{
                        marginTop: "1rem",
                        marginBottom:'1rem',
                        borderRadius: "100px",
                        minWidth: "164px",
                        minHeight: "50px",
                        fontWeight: "700",
                        boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                      }}
                      onClick={() => history.push(SIGNUP_URL)}
                    >
                      Create Your Own Story
                    </Button>
                  </Grid>
                  <Grid item>
                   <img src={logo} style={{width:'70%'}}/>
                  </Grid>
                </Grid>
              </S.ButtonWrapper>
            )}
          </>
        )}
      </S.Wrapper>
    );
  }
}
