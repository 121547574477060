import axios from 'axios';
import Swal from 'sweetalert2';

import {
  API_PROFILE_MEMORIES_URL,
} from '../constants/apiRoutes';

import { formatCleanJSDate, formatCleanJSYear  } from '../helpers';

import makeError from './makeError';

import JSZip from "jszip";
import { saveAs } from 'file-saver';

import { jsPDF } from 'jspdf';

import logo from '../assets/logoUrl';

const getAll = async ({ options } = {}) => {
  try {
    const { data: userMemories } = await axios.get(API_PROFILE_MEMORIES_URL);

    if (userMemories) {
      if (userMemories.length === 0) {
        return Swal.fire({
          title: 'You need to create a memory first!',
          type: 'warning',
          confirmButtonColor: '#66CC66',
          cancelButtonColor: '#8a8787',
          confirmButtonText: 'Ok',
        });
      }
    }



    const zip = new JSZip();
    await Promise.all(
      userMemories.map(async memory => {
        const {
          title,
          tempVideo,
          tempAudio,
          description,
          tempImages,
          date,
          dateType,
        } = memory;

        if (tempVideo && tempVideo.length > 0) {
          const { data } = await axios.get(tempVideo, { responseType: 'blob' });
          const videoFile = new File([data], 'video.mp4');
          zip.file(`${title}/${title}.mp4`, videoFile);
        } 
        
        if (tempAudio && tempAudio.length > 0) {
          const { data } = await axios.get(tempAudio, { responseType: 'audio' });
          const audioFile = new File([data], 'audio.mp3');
          zip.file(`${title}/${title}.mp3`, audioFile);
        }

        if (tempImages && tempImages.length > 0) {
          await Promise.all(
            tempImages.map(async image => {
              const { data } = await axios.get(image.imagePath, { responseType: 'blob' });
              const imageFile = new File([data], 'image.jpg');
              zip.file(`${title}/images/${tempImages.indexOf(image)}.jpg`, imageFile);
            }),
          );
        }

        if (description && description.length > 0) {
          const pageWidth = 8.5;
          const lineHeight = 1.2;
          const margin = 0.5;
          const maxLineWidth = pageWidth - margin * 2;
          const fontSize = 24;
          const ptsPerInch = 72;
          const oneLineHeight = (fontSize * lineHeight) / ptsPerInch;

          const doc = new jsPDF({
            unit: "in",
            lineHeight: lineHeight
          });

          const titleLines = doc
            .setFont("helvetica", "bold")
            .setFontSize(fontSize)
            .splitTextToSize(title, maxLineWidth);
          
          doc.addImage(logo, 'PNG', pageWidth/2 - margin*1.75, margin, margin*3.5, margin);

          if (dateType === "age") {
            doc.text(formatCleanJSYear(date).toString(), pageWidth - margin, margin + 3 * oneLineHeight, null, null, "right");
          } else {
            doc.text(formatCleanJSDate(date).toString(), pageWidth - margin, margin + 3 * oneLineHeight, null, null, "right");
          }

          doc.text(titleLines, margin, margin + 4 * oneLineHeight);

          const textLines = doc
            .setFont("helvetica", "normal")
            .setFontSize(fontSize*0.6)
            .splitTextToSize(description, maxLineWidth);

          doc.text(textLines, margin, margin + 7 * oneLineHeight);

          
          

          const pdfFile = new File([doc.output('blob')], `${title}.pdf`);
          zip.file(`${title}/${title}.pdf`, pdfFile);
        }
      }),
    );

    zip.generateAsync({type:"blob"})
      .then(function (blob) {
          saveAs(blob, "IternalData.zip");
      });

    return { data: userMemories };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const downloadAllData = {
  getAll,
};

export default downloadAllData;