export const LANDING_URL = '/';
export const LOGIN_URL = '/login';
export const SIGNUP_URL = '/sign-up';
export const PRIVACY_URL = 'https://iternal.life/terms-and-conditions/';
export const CHAT_URL = '/chat';
export const BASICS_URL = '/basics';
export const MEMORIES_URL = '/memories';
export const ABOUT_URL = '/about';
export const ABOUT_SECTION_URL = '/about-section';
export const THISORTHAT_URL = '/thisorthat';
export const MEMORY_URL = '/memories/:id';
export const RESET_URL = '/reset-password';
export const SHARE_MEMORY_URL = '/share/memory/:id';
export const CREATE_MEMORY_URL = '/create-memory';
export const FRIENDS_AND_FAMILY_URL = '/friends-and-family';
export const NOT_FOUND_URL = '/404';
export const UNAUTHORIZED_URL = '/unauthorized';
export const SERVER_ERROR_URL = '/500';
export const ONBOARDING_FLOW = '/onboarding';
export const MY_FRIEND_MEMORIES = '/share/:shortId';
export const MY_FRIENDS_MEMORIES = '/my-friends-memories';
export const PREVIEW_MEMORY_URL = '/preview-memory';
export const SUCCESS_SAVE_MEMORY_URL = '/create-memory-success';
export const SUCCESS_SAVE_OTHER_URL = '/create-success';
export const GO_PREMIUM = '/go-premium';
export const GOOGLE_LOGIN_URL = '/login/google';
export const GOOGLE_SIGNUP_URL = '/signup/google';
export const DASHBOARD_URL = '/dashboard';
export const HELP_CENTER_URL = '/help-center';
export const EMAIL_THE_FUTURE_URL = '/email-the-future';
export const BONUS_URL = '/bonus';
export const GIFT_URL = '/gift';
export const MILESTONES_URL = '/milestone';
export const CREATE_OTHER_URL = '/create/:shortId';
export const GIFT_MEMORY_URL = '/memory-gift';
export const GROUPS_URL = '/groups';
export const GROUPS_CREATE_URL = '/groups/create';
export const GROUP_URL = '/group/:shortId';
export const GROUP_ADMIN_URL = '/group/admin/:shortId';
export const GROUP_MEMBER_URL = '/group/members/:shortId';
export const ALBUMS_URL = '/albums';
export const ALBUM_URL = '/album/:albumId';
export const PROMPTS_URL = '/prompts'
export const INVITE_URL = '/invite'