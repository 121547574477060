import React from 'react';

const Checkmark = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3125 6.25L9.375 18.75L4.6875 14.0625"
      stroke={color || 'black'}
      strokeWidth="1.5625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Checkmark;
