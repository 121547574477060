import styled from 'styled-components';

export const NoContentWrapper = styled.div`
  background: rgba(0, 0, 0, 0.0125);
  border-radius: 0.5rem;
  max-width: 85%;
  margin: 0 auto;
  padding: 1rem;

  .miroodleImage {
    max-width: 12rem;
    padding: 1rem;
  }
`;
