import styled from 'styled-components';

export const EditRelationshipStyles = styled.div`
  .paperStyles {
    padding: 1.75rem 1.5rem;
  }

  .groupContainer {
    display: block;
    padding: 1rem;
    margin: 0.5rem 0.15rem;
    background: rgba(0, 0, 0, 0.015);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
  }

  .scrollContainer {
    max-height: 22rem;
    border-radius: 0.5rem;
    padding: 0.75rem;
    overflow: scroll;
    background: rgba(0, 0, 0, 0.03);
  }

  .checkboxesGroup {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding: 0.75rem;
    background: white;
    margin: 0.2rem 0rem 0.5rem 0rem;

    .groupTitle {
      font-size: 1.5rem;
      margin-bottom: 0.75rem;
    }

    .unchecked {
      background: hsla(43, 85%, 50%, 0);
      transition: all 0.5s ease;
    }
    .checked {
      background: hsla(43, 85%, 50%, 0.2);
      transition: all 0.5s ease;
      padding-left: 1%;
    }
  }
`;
