import Swal from 'sweetalert2';
import history from '../history';

import {
  NOT_FOUND_URL,
  UNAUTHORIZED_URL,
  SERVER_ERROR_URL,
} from '../constants/constantUrls';

const handleRedirect = status => {
  switch (status) {
    case 404:
      return history.push(NOT_FOUND_URL);

    case 401:
    case 403:
      return history.push(UNAUTHORIZED_URL);

    default:
      return history.push(SERVER_ERROR_URL);
  }
};

const handleShowModal = message =>
  Swal.fire({
    title: message,
    type: 'warning',
  });

const makeError = (error, options = {}) => {
  const { redirectToRelatedPage = false, showModal = true } = options;
  let errorMessage = error.message || 'Something went wrong';
  let status = 500;

  errorMessage =
    (error.response && error.response.data && error.response.data.error) ||
    errorMessage;

  status = (error.response && error.response.status) || status;

  if (redirectToRelatedPage) handleRedirect(status);
  if (showModal) handleShowModal(errorMessage);
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV !== 'production') console.error(error);

  return errorMessage;
};

export default makeError;
