// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// nanoid
import { nanoid } from "nanoid";

// Swal
import Swal from "sweetalert2";

// MUI
import {
  Avatar,
  Paper,
  Typography,
  Grid,
  FormLabel,
  FormGroup,
  Divider,
  FormControlLabel,
  Tooltip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Checkbox,
  Button,
  Chip,
  Collapse,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

// MUI Icons
import FaceIcon from "@material-ui/icons/Face";
import CopyIcon from "@material-ui/icons/FileCopy";
import OnlyMeIcon from "@material-ui/icons/Lock";
import FriendsAndFamilyIcon from "@material-ui/icons/SupervisedUserCircle";
import SpecificGroupsIcon from "@material-ui/icons/GroupWork";
import PublicIcon from "@material-ui/icons/Public";
import Close from "@material-ui/icons/CancelOutlined";

// Relationships
import {
  friendRelationships,
  familyRelationships,
} from "../../FriendsAndFamily/EditRelationship/DefinedRelationships";

// Styled components
import { ShareModalStyles } from "./ShareModal.style";

// API (memories, friends, tags)
import {
  memories as memoriesAPI,
  permissionAccess as friendsAPI,
  tags as tagsAPI,
} from "../../../../api";

// Custom Components
import { SocialMediaButtons } from "../../../SocialMediaButtons/SocialMediaButtons";

// Put all relationships in one array
const definedRelationships = [...friendRelationships, ...familyRelationships];

export const ShareModal = ({
  memory,
  setPrivacyStateFunc,
  closeFunc,
  ...props
}) => {
  const [privacySetting, setPrivacySetting] = useState(memory?.privacySetting);
  const [friends, setFriends] = useState(undefined);
  const [tags, setTags] = useState(undefined);
  const [checklist, setChecklist] = useState(undefined);

  const toggleCheckbox = (e, state, setState) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    });
  };

  // State updates - Friends, Tags. Checklist
  useEffect(() => {
    const getFriends = async () => {
      const { data } = await friendsAPI.getAllGivenPermissionAccess();
      setFriends(data);
    };

    const getTags = async () => {
      const { data } = await tagsAPI.getAllTagsMemory({
        memoryId: memory?._id,
      });
      setTags(data);
    };

    const generateChecklist = () => {
      const generatedChecklist = {};

      // Add friends to checklist
      definedRelationships.forEach((rel) => {
        generatedChecklist[rel] = false;
      });

      // Add relationships to checklist
      if (friends) {
        friends.forEach((friend) => {
          // console.log(
          //  `Theoretically, I'm unticking ${friend?.sharedWithEmail}`,
          // );
          generatedChecklist[friend?.sharedWithEmail] = false;
        });
      }

      if (tags) {
        // console.log(tags);
        // Tick friends/groups already tagged
        tags.forEach((tag) => {
          // Tick friends
          if (tag?.taggedEmail) {
            // console.log(`Tried to tick ${tag?.taggedEmail}`);
            generatedChecklist[tag?.taggedEmail] = true;
          }
          // Tick groups
          if (tag?.taggedGroup) {
            // console.log(`Tried to tick ${tag?.taggedGroup}`);
            generatedChecklist[tag?.taggedGroup] = true;
          }
        });
      }

      setChecklist(generatedChecklist);
    };

    const getData = async () => {
      if (!friends) {
        await getFriends();
      }
      if (!tags) {
        await getTags();
      }
      if (!checklist) {
        generateChecklist();
      }
    };

    getData();
    /* eslint-disable-next-line */
  }, [tags]);

  const updateFunctionBatch = async () => {
    // Update privacy status, if needed
    if (memory?.privacySetting !== privacySetting) {
      // Update
      memoriesAPI.updateMemory({
        memory: {
          ...memory,
          memoryId: memory?._id,
          voiceOrDescriptionIncluded: true,
          privacySetting,
        },
      });
      // Render optimistically
      setPrivacyStateFunc(privacySetting);
    }

    // Update all the tags based on the checklist
    if (privacySetting === "Specific Groups") {
      let tagsArray = [];
      if (checklist) {
        tagsArray = Object.entries(checklist).map((checkListItem) => {
          // Make function easier to read
          const emailOrGroupName = checkListItem[0];
          const isTicked = checkListItem[1];

          // Generate array of existing tags (if any)
          const existingTagsArr = tags
            ? tags?.map(
                ({ taggedEmail, taggedGroup }) => taggedEmail || taggedGroup
              )
            : [];

          // Check if current tag already exists
          const tagAlreadyExists = existingTagsArr.includes(emailOrGroupName);

          // Collect Tag Object
          let tag = {};
          if (tagAlreadyExists) {
            tags.forEach((el) => {
              if (
                el?.taggedEmail === emailOrGroupName ||
                el?.taggedGroup === emailOrGroupName
              ) {
                tag = { ...el };
              }
            });
          } else if (emailOrGroupName.includes("@")) {
            tag.taggedEmail = emailOrGroupName;
          } else {
            tag.taggedGroup = emailOrGroupName;
          }

          // Determine operation
          let operation = false;

          switch (true) {
            case tagAlreadyExists && isTicked:
              operation = "Update";
              break;
            case tagAlreadyExists && !isTicked:
              operation = "Delete";
              break;
            case !tagAlreadyExists && isTicked:
              operation = "Create";
              break;
            default:
              return;
          }

          return operation === false
            ? null
            : {
                tag,
                operation,
              };
        });
      }

      // Remove NULL values
      tagsArray = tagsArray.filter((el) => el);

      const res = await tagsAPI.batchCRUDTags({
        memoryId: memory?._id,
        tagsArray,
      });
      return res;
    }
  };

  const memoryIsntPrivate = privacySetting !== "Only Me";
  const userHasAddedFriends = friends?.length > 0;
  // const userHasTags = tags?.length > 0;

  const renderTitle = () =>
    `Share ${memory?.title ? `"${memory?.title}"` : "memory"}`;

  const privacySettingButtons = [
    {
      text: "Friends & Family",
      icon: <FriendsAndFamilyIcon />,
      description: `Your memory is shared with everyone that you've added as a friend or family member.`,
    },
    {
      text: "Only Me",
      icon: <OnlyMeIcon />,
      description: `Your memory is 100% private - your friends and family can't see it, and it can't be shared or accessed via any links.`,
    },
    // {
    //   text: 'Specific Groups',
    //   icon: <SpecificGroupsIcon />,
    //   description: `Your memory is visible only by specific friends or groups that you select. This gives you the finest level of control on how to share.`,
    // },
    {
      text: "Public",
      icon: <PublicIcon />,
      description: `Your memory is visible not just to your friends and family, but also to anyone with or without an Iternal account. You can also get a public link to your memory to share on Social Media.`,
    },
  ];

  const renderPrivacySettingSection = () => (
    <div className="section">
      <Typography color="primary" variant="h6">
        Visibility
      </Typography>
      <div
        className="priavcyButtonsSection"
        style={{
          display: "flex",
          flexDirection: "row",
          height: window.innerWidth > 600 ? "100%" : "100%",
        }}
      >
        {/* Show all privacy setting buttons */}
        {privacySettingButtons.map((button) => (
          <Button
            key={nanoid()}
            className="privacyButton"
            variant={privacySetting === button.text ? "contained" : "outlined"}
            color={privacySetting === button.text ? "primary" : "default"}
            onClick={() => setPrivacySetting(button.text)}
          >
            <div
              className={
                privacySetting === button.text
                  ? "privacyIconHighlighted"
                  : "privacyIcon"
              }
            >
              {button.icon}
            </div>
            <br />
            <span className="privacyText">{button.text}</span>
          </Button>
        ))}
      </div>
      {/* Show a description of the current privacy setting applied */}
      {privacySettingButtons.map(({ text, description }) => (
        <div key={nanoid()}>
          <Collapse in={text === privacySetting}>
            <Alert className="privacyInfo" severity="info">
              <AlertTitle>{text}</AlertTitle>
              {description}
            </Alert>
          </Collapse>
        </div>
      ))}
      <Button
        size="large"
        variant="contained"
        color="secondary"
        onClick={updateFunctionBatch}
        fullWidth
        style={{ fontWeight: "bold" }}
      >
        Update Visibility
      </Button>
    </div>
  );

  const renderFriendsAndGroupsSection = () => {
    const renderGroups = () => {
      const relsWithCountsArray = definedRelationships.map((relationship) => {
        const relObj = {
          relationship: relationship.toString(),
          count: 0,
          friends: [],
        };

        if (friends && friends?.length > 0) {
          friends.forEach((friend) => {
            if (friend?.relationships?.[relationship]) {
              relObj.count += 1;
              relObj.friends.push(friend?.name || friend?.sharedWithEmail);
            }
          });
        }

        return relObj;
      });

      const generateTooltipProps = (el) => {
        const count = el?.count;
        const friendsArr = el?.friends;

        if (count === 0) {
          return {
            title: `You haven't added anyone to the ${el?.relationship} group yet.`,
          };
        }
        if (count > 0) {
          const tooltipProps = { title: "" };
          if (count === 1) {
            tooltipProps.title = `You've added ${friendsArr[0]} to the ${el?.relationship} group`;
          }
          if (count === 2) {
            tooltipProps.title = `You've added ${friendsArr[0]} and ${friendsArr[1]} to the ${el?.relationship} group`;
          }
          if (count === 3) {
            tooltipProps.title = `You've added ${friendsArr[0]}, ${friendsArr[1]} and ${friendsArr[2]} to the ${el?.relationship} group`;
          }
          if (count > 3) {
            tooltipProps.title = `You've added ${friendsArr[0]}, ${
              friendsArr[1]
            }, ${el[2]} and ${count - 3} ${
              count - 3 === 1 ? "other person" : "other people"
            } to the ${el?.relationship} group`;
          }

          return tooltipProps;
        }
      };
      return (
        <List className="listItemsWrapper">
          <Typography className="listTitle" variant="body2">
            Groups
          </Typography>
          <Divider />
          {relsWithCountsArray?.length > 0 &&
            checklist &&
            tags &&
            relsWithCountsArray.map((el) => (
              <div key={nanoid()}>
                <FormControlLabel
                  key={nanoid()}
                  className={`listItem ${
                    checklist?.[el?.relationship] ? "checked" : "unchecked"
                  }`}
                  control={
                    <Checkbox
                      checked={checklist?.[el?.relationship]}
                      onChange={(e) =>
                        toggleCheckbox(e, checklist, setChecklist)
                      }
                      name={el?.relationship}
                    />
                  }
                  label={
                    <ListItem className="listItemDetails">
                      <Tooltip {...generateTooltipProps(el)} arrow>
                        <ListItemText
                          primary={el?.relationship}
                          secondary={
                            el?.count > 0 &&
                            `${el?.count} ${
                              el?.count === 1 ? "person" : "people"
                            } added`
                          }
                        />
                      </Tooltip>
                    </ListItem>
                  }
                />
                <Divider className="listItemDivider" />
              </div>
            ))}
        </List>
      );
    };

    const renderFriends = () => (
      <List className="listItemsWrapper">
        <Typography className="listTitle" variant="body2">
          Specific Friends
        </Typography>
        <Divider />
        {friends?.length > 0 &&
          friends.map((el) => {
            const hasName = el.name && el.name !== "";
            const renderAvatar = () => {
              if (el?.abouts[0]?.image?.imagePath) {
                return <Avatar src={el?.abouts[0]?.image?.imagePath} />;
              }
              if (hasName) {
                const namesArr = el.name.split(" ");

                const nameStr = namesArr.reduce((acc, cur) => acc + cur[0], "");

                return <Avatar>{nameStr}</Avatar>;
              }
              return <Avatar />;
            };

            const renderUsername = () => {
              const liText = {};

              if (hasName) {
                liText.primary = el.name;
                liText.secondary = el.sharedWithEmail;
              } else {
                liText.primary = el.sharedWithEmail;
              }
              return <ListItemText {...liText} />;
            };

            const renderRelationships = ({ limit } = { limit: 3 }) => {
              const { relationships } = el;

              if (relationships) {
                const relsArray = Object.entries(relationships).filter(
                  (relationship) => relationship[1] === true && relationship[0]
                );
                const shortenedRels = relsArray.slice(0, limit);
                return (
                  <div className="relationshipsSection">
                    {shortenedRels.map((replationship) => (
                      <Chip
                        size="small"
                        className="relationshipChip"
                        key={nanoid()}
                        color="secondary"
                        icon={<FaceIcon />}
                        label={replationship}
                      />
                    ))}
                    {relationships.length > shortenedRels.length && (
                      <Chip
                        key={nanoid()}
                        size="small"
                        className="relationshipChip"
                        color="default"
                        label="..."
                      />
                    )}
                  </div>
                );
              }

              return (
                <div className="relationshipsSection">
                  <Chip
                    size="small"
                    className="relationshipChip"
                    color="secondary"
                    icon={<FaceIcon />}
                    label="Friend"
                  />
                </div>
              );
            };

            return (
              <>
                <FormControlLabel
                  key={nanoid()}
                  className={`listItem ${
                    checklist?.[el?.sharedWithEmail] ? "checked" : "unchecked"
                  }`}
                  control={
                    <Checkbox
                      checked={checklist?.[el?.sharedWithEmail]}
                      onChange={(e) =>
                        toggleCheckbox(e, checklist, setChecklist)
                      }
                      name={el?.sharedWithEmail}
                    />
                  }
                  label={
                    <ListItem className="listItemDetails">
                      <ListItemAvatar>{renderAvatar()}</ListItemAvatar>
                      {renderUsername()}
                      {renderRelationships({ limit: 3 })}
                    </ListItem>
                  }
                />
                <Divider className="listItemDivider" />
              </>
            );
          })}
      </List>
    );

    return (
      <Collapse
        in={
          privacySetting === "Specific Groups" &&
          userHasAddedFriends &&
          checklist
        }
      >
        <div className="section specificGroupsSection">
          <FormLabel>
            <Typography className="sectionTitle" variant="body2">
              Choose Groups & Friends Who Can View This Memory
            </Typography>
          </FormLabel>
          <div className="scrollContainer">
            {renderGroups()}
            {renderFriends()}
          </div>
        </div>
      </Collapse>
    );
  };

  const renderMemoryLinkSection = () => {
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text);
      Swal.fire({
        title: "Copied to Clipboard",
        type: "success",
        timer: 2000,
      });
    };

    const shareLink = `${window.location.origin}/share/memory/${memory?._id}`;

    return (
      <Collapse in={memoryIsntPrivate && memory?._id}>
        <div className="section memoryLinkSection">
          <Typography color="primary" variant="h6">
            Memory Link
          </Typography>
          <Grid item xs={12}>
            <div
              role="button"
              tabIndex={0}
              className="memoryLinkWrapper"
              onClick={() => copyToClipboard(shareLink)}
            >
              <Grid container spacing={1}>
                <Grid item xs={11} style={{ overflow: "auto" }}>
                  <Typography align="left" className="memoryLinkText">
                    {shareLink}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <CopyIcon
                    color="primary"
                    fontSize="normal"
                    className="copyIcon"
                  />
                </Grid>
              </Grid>
            </div>
            {/* <Typography
              variant="body2"
              align="left"
              className="copyLinkHelperText"
            >
              * Click to copy to clipboard
            </Typography> */}
          </Grid>
        </div>
      </Collapse>
    );
  };

  const renderSocialMediaSection = () => {
    const shareLink = memory?._id
      ? `${window.location.host}/share/memory/${memory?._id}`
      : false;

    const messageBody = memory?.title
      ? `View my memory ${memory?.title} on Iternal, the storytelling platform`
      : "View on of my recent memories on Iternal, the storytelling platform";

    return (
      <Collapse in={memoryIsntPrivate}>
        <div className="section">
          <Typography
            color="primary"
            variant="h6"
            style={{ marginBottom: "-1rem" }}
          >
            Share on Social Media
          </Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SocialMediaButtons
              shareLink={shareLink}
              messageBody={messageBody}
            />
          </div>
        </div>
      </Collapse>
    );
  };

  return (
    <ShareModalStyles>
      <Paper
        style={{
          maxHeight: "89vh",
          padding: window.innerWidth < 600 ? "1rem" : "1.5rem",
          marginLeft:'-1rem',
          zIndex: "2000",

        }}
        elevation={3}
        {...props}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Close
            color="primary"
            style={{
              cursor: "pointer",
              marginTop: "-0.5rem",
              marginBottom: window.innerWidth < 600 ? "0rem" : "-1rem",
            }}
            onClick={closeFunc}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              maxWidth: "90%",
     
            }}
          >
            <Typography
              color="primary"
              style={{
                textAlign: "center",
                fontSize: window.innerWidth < 500 ? "1.5rem" : "1.8rem",
                marginBottom:'1rem',
              }}
            >
              {renderTitle()}
            </Typography>
          </div>
        </div>
        <FormGroup>{renderFriendsAndGroupsSection()}</FormGroup>
        {renderMemoryLinkSection()}
        {renderSocialMediaSection()}
        {renderPrivacySettingSection()}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {/* <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={updateFunctionBatch}
              fullWidth
              style={{fontWeight:'bold'}}
            >
              Update Visibility
            </Button> */}
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <Button
              size="large"
              variant="outlined"
              onClick={closeFunc}
              fullWidth
            >
              Close
            </Button> */}
          </Grid>
        </Grid>
      </Paper>
    </ShareModalStyles>
  );
};

ShareModal.propTypes = {
  /**
   * Please provide the memory for which to generate this sharing modal
   */
  memory: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    date: PropTypes.string,
    dateAge: PropTypes.number,
    dateType: PropTypes.oneOf(["full", "age"]),
    description: PropTypes.string,
    images: PropTypes.arrayOf({
      _id: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    isDraft: PropTypes.bool,
    isPublic: PropTypes.bool,
    privacySetting: PropTypes.oneOf([
      "Only Me",
      "Friends & Family",
      "Specific Groups",
      "Public",
    ]),
    tempImages: PropTypes.arrayOf({
      _id: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    user: PropTypes.string,
    video: PropTypes.string,
    voice: PropTypes.string,
  }),
  /**
   * Please provide a function to optimistaclly render changes to the privacySettingChip
   */
  setPrivacyStateFunc: PropTypes.func,
  /**
   * Please provide a function to close the modal
   */
  closeFunc: PropTypes.func,
};

ShareModal.defaultProps = {
  memory: {},
  setPrivacyStateFunc: () => {},
  closeFunc: () => {},
};
