import styled from 'styled-components';
import { breakpoints } from '../../../constants/breakPoints';

export const FooterWrapper = styled.footer`
  position: fixed;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  right: 0;
  left: 0;
  height: 4.25rem;
  z-index: 750;
  background: var(--primary-navy);

  @media ${breakpoints.laptop} {
    /* padding: 2rem 2rem 8rem 250px; */
    padding-left: 280px;
  }
`;

export const IconWrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: none;
  border: none;
  width: 33.33%;
  cursor: pointer;
  padding-top: 0.3rem;
  color: white;
`;
