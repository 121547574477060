import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// MUI
import { Typography, Button, LinearProgress } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';

// Styled Components
import { FourOhFourWrapper } from './404.style';

export const FourOhFour = ({ text, homePageURL, ...props }) => {
  const [timer, setTimer] = useState(10);

  useEffect(() => {
    // Redirect
    if (timer === 0) {
      window.location.href = homePageURL;
    }
    // Countdown
    if (timer !== 0) {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
  }, [homePageURL, timer]);

  return (
    <FourOhFourWrapper {...props}>
      <div className="titleSection">
        <Typography variant="h3" color="primary">
          404
        </Typography>
        <Typography variant="body1" color="primary">
          Not Found
        </Typography>
      </div>

      <Typography className="descriptonSection" variant="body2">
        Our bad! It seems that this page is missing.
      </Typography>
      <Typography className="timerSection" variant="body2">
        Taking you to your dashboard in {`${timer}...`}
        <LinearProgress
          color="primary"
          variant="determinate"
          value={100 - timer * 10}
        />
      </Typography>
      <Link
        style={{ textDecoration: 'none' }}
        to={{
          pathname: homePageURL,
        }}
      >
        <Button
          className="homeButton"
          variant="contained"
          color="primary"
          size="large"
        >
          <HomeIcon />
          &nbsp;&nbsp;Take me back now
        </Button>
      </Link>
    </FourOhFourWrapper>
  );
};

FourOhFour.propTypes = {
  /**
   * Enter a description for each property above it like so
   */
  text: PropTypes.string,
  /**
   * Specify the desired URL for the button
   */
  homePageURL: PropTypes.string,
};

FourOhFour.defaultProps = {
  text: 'hello',
  homePageURL: '/',
};
