import styled from 'styled-components';

export const ClaimBonusStyles = styled.div`
  margin: 0 auto !important;

  .paper {
    max-width: 35rem !important;
    padding: 1.25rem !important;
  }

  .loading {
    .loadingText {
      margin-bottom: 1.5rem;
    }

    .skeleton,
    .skeletonText,
    .skeletonImage,
    .skeletonButton {
      text-align: center !important;
      margin: 0 auto;
    }

    .skeleton {
      margin: 0 auto !important;
    }
    .skeletonText {
      margin-bottom: 0.1rem !important;
    }

    .skeletonImage {
      margin: 1.5rem auto !important;
    }

    .skeletonButton {
      margin-top: 1rem;
    }
  }

  .bonusQuantityBig {
    font-size: 5rem;
    font-weight: 200;
    letter-spacing: 0.5 !important;
    line-height: 1;
  }

  .bonusTypeBig {
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.1rem;
    line-height: 1;
  }

  .bonusIcon,
  .checkboxIcon {
    margin: 1rem auto !important;
    width: 100%;
    max-height: 10rem;
  }

  .checkboxIcon {
    font-size: 4rem !important;
  }

  .bonusTitle {
    letter-spacing: 0.45;
    font-size: 2.5rem;
    text-transform: capitalize;
    margin-bottom: 2rem;
  }

  .bonusDescription {
    margin-bottom: 1rem;
  }

  .ctaButton {
    margin-bottom: 0.5rem;
  }

  .buttonLink {
    text-decoration: none !important;
  }
`;
