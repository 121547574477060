import styled from 'styled-components';

import { breakpoints, breakpointsMax, devices } from '../../../constants/breakPoints';

export const Main = styled.main`
  margin: 5rem 1rem 5rem 1rem;
  display: flex;
  flex-direction: column;
  max-width: 100rem;
  margin: 0 auto;
  align-items: center;
  padding-top: 1.5rem;

  @media ${breakpoints.laptop} {
    padding: 0rem 1rem 1rem 200px;
  }
`;

export const MemoryButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0rem 3rem 1rem 280px;
  button {
    margin: 0px 10px;
    width: 200px;
  }
  @media ${breakpointsMax.tablet} {
    padding: 0rem;
  }
`;

export const LoadWrapper = styled.section`
  max-width: 100rem;

  @media ${breakpoints.laptop} {
    padding-top: 10rem;
    padding-left: 320px;
  }

  @media ${devices.tablet} and (orientation: portrait) {
    padding-top: 5rem;
  }

  @media ${devices.mobile} {
    padding-top: 4rem;
  }
`;


export const Wrapper = styled.div`
  display: flex;
  ${'' /* background: linear-gradient(90deg, #0A2156, #1d6a91,  #0bafaa); */}
  flex-direction: column;
  /* may need to change back - padding: 5rem 1rem 8rem 1rem; */
  padding: 1rem 0.5rem 2rem;
  .share-card-block {
    height: 300px;
  }

  .title {
    margin-top: 2rem !important;
  }

  .contentSection {
    margin-top: 1.5rem !important;
    width: 100%;
  }

  @media ${breakpointsMax.mobileXL} {
    .timeline-tile {
      .box {
        padding: 3.25rem 0rem;
      }
    }
  }

  @media ${breakpoints.laptop} {
    /* padding: 2rem 2rem 8rem 250px; */
    padding: 2rem 3rem 2rem 280px;
  }
`;

export const H1 = styled.h1`
  font-size: 2.6rem;
  margin: 2rem 0;
  text-align: left;
`;

export const P = styled.p`
  margin: 2rem auto;
  text-align: left;

  a {
    font-weight: 700;
    color: var(--secondary);
    text-decoration: underline;
  }
  :last-child {
    margin-bottom: 0px;
  }
`;

export const A = styled.a`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  margin-top: 1.5rem;

  p {
    font-weight: 700;
    color: var(--black);
    font-size: 1.25rem;
  }
`;

export const Nav = styled.nav`
  position: fixed;
  bottom: 0px;
  background-color: var(--white);
`;

export const LimitWrapper = styled.div`
  padding: 2rem;
  width: 100%;
  font-weight: 600;
  text-align: center;

  @media ${breakpoints.laptop} {
    width: auto;
  }
`;

export const LimitText = styled.p`
  font-size: ${({ big }) => (big ? '18px' : '14px')};
  color: var(--neutral-dark);
`;

export const LimitNumber = styled.span`
  font-size: 28px;
  color: var(--secondary-aqua);
`;

export const LimitCTA = styled.button`
  font-size: 14px;
  text-decoration-line: underline;
  color: var(--secondary-aqua);
  border: none;
  background: none;
  cursor: pointer;
  font-weight: bold;
`;

export const DateWrapper = styled.div`
  display: flex;
  font-weight: bold;
  width: 96px;
  span {
    margin-right: 0.2rem;
  }
  @media ${breakpointsMax.tablet} {
    flex-direction: column;
  }
`;

export const TimelineWrapper = styled.div`
  .vertical-timeline::before {
    background: black;
  }

  .vertical-timeline-element-icon {
    box-shadow: none;
    border: 1px solid grey;
  }

  .vertical-timeline-element-content {
    border: 1px solid grey;
    box-shadow: none;
    border-top: 3px solid #2196f3;
  }

  .vertical-timeline-element-date {
    margin: 0rem 1rem;
    font-weight: bold !important;
  }
`;

export const CardTitle = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: var(--neutral-dark);
  /* margin: 2rem auto 0.5rem; */
  @media ${breakpointsMax.tablet} {
    font-size: 18px;
  }
`;

export const CardWrapper = styled.div`
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  display: block;
  padding: 3.25rem;
  margin-bottom: 2rem;
  text-align: left;
  width: 100%;
  @media ${breakpointsMax.tablet} {
    padding: 2rem;
  }
`;
