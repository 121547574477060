import styled from 'styled-components';

export const ModalBackground = styled.div`
  position: fixed;
  z-index: 500;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  & > * {
    margin: 0.5rem 0;
  }
  .StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;

export const ModalContent = styled.div`
  z-index: 1000;

  top: 50%;
  left: 60%;
  position: relative;
  transform: translate(-50%, -50%);
  width: 50%;
  min-width: 320px;
  @media (max-width: 1024px) {
      width:80%;
      margin: 'auto o';
      left: 50%;
    }

  .withBackground {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
  }

  .noBackground {
    background: none !important;
    padding: none !important;
    border: none !important;
  }
`;
