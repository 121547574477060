import React from 'react';
import ShareLink from './ShareLink';
import Swal from 'sweetalert2';

import { CardSubText, FeaturesBlockStyles } from './style';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Button, Typography, ButtonBase, Grid } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import SocialShare from '../SocialShare';

const ShareLinkWrapper = ({ shareLink, hasCopy = true, copy='', share=true }) => {
  const messageBody = share ?
    "Hey! I'd like to share my memories with you! To view my memories, click this link" :
    "Hey! I'd like you to leave your favourite memory of us on my Iternal timeline! To add to my timeline, click this link";
  const messageTitle = share ? 
    "I'd like to share my memories with you!" :
    "I'd like you to leave your favourite memory of us on my Iternal timeline!";

  return (
    <>
      {hasCopy && (
        <>
          <Typography>
            {copy}
          </Typography>
          <br />
        </>
      )}
      <FeaturesBlockStyles>
        {navigator.share ? (
          <ButtonBase
            style={{
              background: 'rgb(11 175 170 / 30%)',
              border: 'solid 2px var(--secondary-aqua)',
              width: '100%',
              borderRadius: '0.5rem',
              padding: '0.5rem',
              maxWidth: '500px',
            }}
            className="featureCard"
            onClick={() => {
              if (navigator.share) {
                navigator.share({
                  title: 'Iternal',
                  text: share ? 'Share memories on Iternal' : 'Gather memories on Iternal',
                  url: shareLink,
                })
              }
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {share ? <ShareIcon style={{ width: '100%', height: '100%', maxHeight: '2em' }} /> : <MenuBookIcon style={{ width: '100%', height: '100%', maxHeight: '2em' }} />} 
                </div>
              </Grid>
              <Grid item xs={9}>
                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="h5">
                    Click here to copy
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </ButtonBase>
        ) : (
          <CopyToClipboard text={shareLink}>
            <ButtonBase
              style={{
                background: 'rgb(11 175 170 / 30%)',
                border: 'solid 2px var(--secondary-aqua)',
                width: '100%',
                borderRadius: '0.5rem',
                padding: '0.5rem',
                maxWidth: '500px',
              }}
              className="featureCard"
              onClick={() => Swal.fire('Done!', 'The link has been copied to your clipboard', 'success')}
            >
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {share ? <ShareIcon style={{ width: '100%', height: '100%', maxHeight: '2em' }} /> : <MenuBookIcon style={{ width: '100%', height: '100%', maxHeight: '2em' }} />} 
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h5">
                      Click here to copy
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </ButtonBase>
          </CopyToClipboard>
        )}

        
      </FeaturesBlockStyles>
      {/* <Button variant="contained" color="primary" onClick={() => onIconClick(shareLink)}>
        Click to copy your unique code!
      </Button> */}
      <CardSubText>Share on:</CardSubText>
      <SocialShare
        link={shareLink}
        messageBody={messageBody}
        messageTitle={messageTitle}
      />
    </>
  );
};

export default ShareLinkWrapper;
