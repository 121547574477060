import React from 'react';

import Typography from '@material-ui/core/Typography';
import Footer from './Footer';

import {
  PageWrapper,
  HeadingWrapper,
  InfoLink,
  Body,
  HeadingDescription,
} from './style';

const GreenBlackHeading = ({
  heading,
  headingLink,
  children,
  FooterElements,
  headingDescription = ' ',
  validateMemory,
  memoryId,
  loading,
  setEditExisting,
}) => (
  <PageWrapper style={{marginLeft: window.innerWidth < 1024 ? "0rem" : '3rem'}}>
    <HeadingWrapper>
      <Typography variant="h3" style={{ fontWeight: 'bold', color: 'white', fontSize: window.innerWidth < 400 ? "2rem" : '3rem' }}>
        {heading}
      </Typography>
      {headingLink && (
        <InfoLink to={headingLink.to}>{headingLink.text}</InfoLink>
      )}
      {headingDescription && typeof headingDescription === 'string' ? (
        <HeadingDescription>{headingDescription}</HeadingDescription>
      ) : (
        headingDescription.map(headingDescriptionLine => (
          <HeadingDescription>{headingDescriptionLine}</HeadingDescription>
        ))
      )}
    </HeadingWrapper>
    <Body>{children}</Body>
    {FooterElements && (
      <Footer>
        <FooterElements
          viewMode="creation"
          loading={loading}
          validateMemory={validateMemory}
          setEditExisting={setEditExisting}
          memoryId={memoryId}
        />
      </Footer>
    )}
  </PageWrapper>
);
export default GreenBlackHeading;
