/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import history from "../../../history";
import Swal from "sweetalert2";

// helpers
import useModal from "../../../helpers/hooks/useModal";
import { formatJSDate } from "../../../helpers";

// api
import { prompts } from "../../../api";

import { createErrors, validate } from "../../../helpers/validation";

// api and navigation
import { memories, tags } from "../../../api";
import cardsData from "./cardsData";
import { SUCCESS_SAVE_MEMORY_URL } from "../../../constants/constantUrls";

// components
import Card from "../../Common/Card";
import UserContext from "../../../context/user";
import GreenBlackHeading from "../../Layouts/GreenBlackHeading";
import Loading from "../Loading";
import { PromptGenerator } from "../../../NewComponents/Pages/CreateMemory/PromptGenerator/PromptGenerator";
import PromptPicker from "../../Common/PromptPicker";

// styles
import { JoyrideButtonWrapper, GlowingButton } from "./style";
import { ButtonWrapper } from "../../Common/Inputs/style";
import { FaCheckCircle } from "react-icons/fa";

// Material Components
import { Paper, Button, Grid, Modal, Typography } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import Groups from "@material-ui/icons/People";
import Friends from "@material-ui/icons/EmojiPeople";
import Profile from "@material-ui/icons/Face";
import Stories from "@material-ui/icons/MenuBook";
import Camera from "@material-ui/icons/PhotoCamera";
import Close from "@material-ui/icons/CancelOutlined";

import * as C from "../../../constants/constantUrls";

// Divider
import { Divider } from "../../../NewComponents/Divider/Divider";

// React Joyride
import Joyride from "react-joyride";
import ReactGA from "react-ga";
import CallToActionButton from "../../Common/GroupComponents/Buttons/CallToActionButton";

// Creates or Updates Memory
const createUpdateMemory = async (memoryObj, type, memoryId) => {
  // type can be saveShare / saveDraft
  const {
    title,
    date,
    dateType,
    dateAge,
    description,
    voice,
    video,
    images,
    voiceOrDescriptionIncluded,
    privacySetting,
    isPublic,
    taggedEmails,
    checklist,
    promptId,
  } = memoryObj;
  
  // editing existing memory triggers update
  if (memoryId) {
    const { data, error } = await memories.updateMemory({
      memory: {
        title,
        date: formatJSDate(date),
        dateType,
        dateAge,
        voice,
        video,
        description,
        images,
        voiceOrDescriptionIncluded,
        privacySetting,
        type,
        memoryId,
        isPublic,
        taggedEmails: [],
        promptId,
      },
    });

    if (!error) {
      ReactGA.event({
        category: "Memory",
        action: "Updated Memory",
      });
      await updateTags({ privacySetting, checklist, taggedEmails, memoryId });
      history.push(SUCCESS_SAVE_MEMORY_URL, { ...data });
    }
  } else {
    // store new memory
    const { data, error } = await memories.createMemory({
      memory: {
        title,
        date: formatJSDate(date),
        dateType,
        dateAge,
        voice,
        video,
        description,
        images,
        voiceOrDescriptionIncluded,
        type,
        privacySetting,
        isPublic,
        taggedEmails: [],
        promptId,
      },
    });

    if (!error) {
      ReactGA.event({
        category: "Memory",
        action: "Created Memory",
      });
      await updateTags({
        privacySetting,
        checklist,
        taggedEmails,
        memoryId: data._id,
      });
      history.push(SUCCESS_SAVE_MEMORY_URL, { ...data });
    }
  }
};

const updateTags = async ({
  privacySetting,
  checklist,
  taggedEmails,
  memoryId,
}) => {
  if (privacySetting === "Specific Groups") {
    let tagsArray = [];
    if (checklist) {
      tagsArray = Object.entries(checklist).map((checkListItem) => {
        // Make function easier to read
        const emailOrGroupName = checkListItem[0];
        const isTicked = checkListItem[1];

        // Generate array of existing tags (if any)
        const existingTagsArr = taggedEmails
          ? taggedEmails?.map(
              ({ taggedEmail, taggedGroup }) => taggedEmail || taggedGroup
            )
          : [];
        // Check if current tag already exists
        const tagAlreadyExists = existingTagsArr.includes(emailOrGroupName);
        // Collect Tag Object
        let tag = {};
        if (tagAlreadyExists) {
          taggedEmails.forEach((el) => {
            if (
              el?.taggedEmail === emailOrGroupName ||
              el?.taggedGroup === emailOrGroupName
            ) {
              tag = { ...el };
            }
          });
        } else if (emailOrGroupName.includes("@")) {
          tag.taggedEmail = emailOrGroupName;
        } else {
          tag.taggedGroup = emailOrGroupName;
        }

        // Determine operation
        let operation = false;

        switch (true) {
          case tagAlreadyExists && isTicked:
            operation = "Update";
            break;
          case tagAlreadyExists && !isTicked:
            operation = "Delete";
            break;
          case !tagAlreadyExists && isTicked:
            operation = "Create";
            break;
          default:
            return;
        }
        return operation === false
          ? null
          : {
              tag,
              operation,
            };
      });
    }

    // Remove NULL values
    tagsArray = tagsArray.filter((el) => el);
    const res = await tags.batchCRUDTags({
      memoryId,
      tagsArray,
    });
    return res;
  }
};

export default function CreateAndEditMemory({
  location,
  uploadObject,
  memoryId,
  setMemoryId,
  editExisting,
  setEditExisting,
}) {
  const [step, setStep] = useState(0);
  const user = useContext(UserContext);
  // local inputs
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(Date());
  const [errors, setErrors] = useState(null);
  const [dateType, setDateType] = useState("");
  const [dateAge, setDateAge] = useState(0);
  const [isRecording, setIsRecording] = useState(null);
  const [runJoyride, setRunJoyride] = useState(false);
  const [promptQuestions, setPromptQuestions] = useState([]);
  const [allPrompts, setAllPrompts] = useState([]);
  const [chosenPrompt, setChosenPrompt] = useState("");
  const [prompt, setPrompt] = useState("");
  const [category, setCategory] = useState("");
  const [checklist, setChecklist] = useState(undefined);
  const [firstTime, setFirstTime] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [showPromptModal, setShowPromptModal] = useState(false);

  // inputs coming from parent component (component index)
  // all uploads to firebase
  const joyrideSteps = [
    {
      target: "#prompt-card",
      content:
        "Click here to generate a random prompt if you can't think about something to write",
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },
    {
      target: "#prompt-button",
      content:
        "Click this button to view all the story prompts and change the story you're telling.",
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },

    {
      target: "#voice-description-video-card",
      content:
        "Add content in for your memory. This can be done through a written description, a voice recording, or a video.",
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },
    {
      target: "#image-card",
      content: "Add images here to bring the memory to life.",
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },
    {
      target: "#title-card",
      content:
        "Add a title for your memory here. If you use a prompt it will suggest a title for you.",
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },

    {
      target: "#date-card",
      content:
        "Add the date when the memory occured or the age you remember being when it happened.",
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },
    {
      target: "#privacy-card",
      content:
        "You can set your memory to be publicly viewable to anyone with a link, or kept private for the people you have added to your account.",
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },
  ];

  const {
    imagesArray,
    voice,
    video,
    imagesArrayRequest,
    setVoice,
    setVideo,
    setImagesArray,
    setImagesArrayRequest,
    tempImages,
    setTempImages,
    tempVoice,
    setTempVoice,
    tempVideo,
    setTempVideo,
    isPublic,
    setIsPublic,
    taggedEmails,
    setTaggedEmails,
    privacySetting,
    setPrivacySetting,
  } = uploadObject;

  // resets all form inputs
  const resetInputs = () => {
    setTitle("");
    setDescription("");
    setDate(new Date());
    setDateType("full");
    setDateAge(0);
    setImagesArray([]);
    setImagesArrayRequest([]);
    setVoice(null);
    setVideo(null);
    setMemoryId(null);
    setTempVoice(null);
    setTempVideo(null);
    setTempImages([]);
    setIsPublic(false);
    setTaggedEmails([]);
    setChosenPrompt("");
    setPrivacySetting("Friends & Family");
  };

  // sets inputs from an existing memory
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (location.pathname) {
      if (location.search.includes("prompt")) {
        getPrompt(location.search.replace("?prompt=", ""));
      } else if (location.search.includes("category")) {
        const stripped = location.search.replace("?category=", "");
        if (stripped.length > 0) {
          getPrompts(stripped.replace("/", ""), true);
          setCategory(stripped.replace("/", ""));
        } else {
          getPrompts("", true);
        }
      } else {
        getPrompts("", true);
      }
    } else {
      getPrompts("", true);
    }
  }, []);

  useEffect(() => {
    if (location.state && location.state?.firstMem) {
      setFirstTime(true);
    }
  }, []);

  useEffect(() => {
    if (editExisting && location.state && location.state.allData) {
      const data = location.state.allData;
      if (location.state.memoryId) {
        const { memoryId: mId } = location.state;
        setMemoryId(mId);
      }
      setTitle(data.title);
      setDescription(data.description);
      setDate(new Date(data.date));
      setDateType(data.dateType);
      setDateAge(data.dateAge);
      setImagesArray(data.images);
      setImagesArrayRequest(data.images);
      setVoice(data.voice);
      setVideo(data.video);
      setPrivacySetting(data.privacySetting);
      setChosenPrompt(data?.promptId);

      // set temp images and voice URLs
      setTempVoice(data.tempVoice);
      setTempImages(data.tempImages);
      setTempVideo(data.tempVideo);
      if (!data.isPublic) {
        setIsPublic(false);
      } else {
        setIsPublic(data.isPublic);
      }
      if (!data.taggedEmails) {
        setTaggedEmails([]);
      } else {
        setTaggedEmails(data.taggedEmails);
      }
      // end setting parameters
      setEditExisting(false);
    }
  }, [
    editExisting,
    location.state,
    setImagesArray,
    setImagesArrayRequest,
    setVoice,
    setVideo,
    setMemoryId,
    setEditExisting,
    description,
    setTempVoice,
    setTempVideo,
    setTempImages,
    setIsPublic,
    setTaggedEmails,
    setPrivacySetting,
  ]);

  // case when user comes from 'create new' menu
  // needs to reset state
  if (location.state && location.state.refreshNeeded === true) {
    resetInputs();
    location.state.refreshNeeded = false;
  }

  const setSingleError = ({ error, key }) => {
    setErrors({ ...errors, [key]: error });
  };

  const dataWithHandlers = {
    title,
    setTitle,
    description,
    setDescription,
    date,
    setDate,
    dateType,
    setDateType,
    dateAge,
    setDateAge,
    imagesArray,
    setImagesArray,
    voice,
    setVoice,
    video,
    setVideo,
    imagesArrayRequest,
    setImagesArrayRequest,
    setTempImages,
    tempImages,
    setTempVoice,
    tempVoice,
    setTempVideo,
    tempVideo,
    setSingleError,
    setIsRecording,
    isRecording,
    isPublic,
    setIsPublic,
    taggedEmails,
    setTaggedEmails,
    step,
    setStep,
    category,
    privacySetting,
    setPrivacySetting,
    checklist,
    setChecklist,
    setIsUploading,
    isUploading,
    chosenPrompt,
  };
  // either returns validated memory object or passes validated date to create memory function
  const validateAndStoreMemory = async (options) => {
    try {
      setIsLoading(true);
      // type can be saveDraft / saveShare
      // previewMode can be preview / previewExisting
      const { type, previewMode, memoryId: memoId } = options;
      let voiceLink = "";
      let videoLink = "";

      // upload audio to firebase and get the name

      if (voice && typeof voice !== "string") {
        const {
          data: { generatedName },
        } = await memories.uploadAudio({
          voice,
          userId: user.id,
        });
        voiceLink = generatedName;
      }

      if (video && typeof video !== "string") {
        const {
          data: { generatedName },
        } = await memories.uploadWebcam(
          {
            video,
            userId: user.id,
          },
          setProgress
        );
        videoLink = generatedName;
      } else if (typeof video === "string") {
        videoLink = video;
      }
      // create object of inputs
      let voiceOrDescriptionIncluded = false;
      if (
        (voiceLink && voiceLink.length > 0) ||
        (description && description.length > 0) ||
        (voice && voice.length > 0) ||
        (voice && typeof voice === "object") ||
        (video && video.length > 0) ||
        (video && typeof video === "object")
      ) {
        voiceOrDescriptionIncluded = true;
      }

      const rawData = {
        title,
        date,
        dateType,
        dateAge,
        images: imagesArrayRequest,
        // check if either voice or description is present
        voiceOrDescriptionIncluded,
        voice: voiceLink.length > 0 ? voiceLink : voice,
        video: videoLink.length > 0 ? videoLink : video,
        description: description.length ? description : "",
        isPublic,
        taggedEmails,
        privacySetting,
        checklist,
        promptId: chosenPrompt,
      };
      const validatedData = await validate("createEditMemory", rawData);
      if (validatedData) {
        setIsLoading(false);
        setErrors({});
        // check if in preview mode of unsaved memo and return validated obj
        if (previewMode === "preview") {
          return validatedData;
        }
        // check if memory id exists and store / update memory
        await createUpdateMemory(validatedData, type, memoryId || memoId);
        setVoice(undefined);
        setVideo(undefined);
        setImagesArray([]);
        setImagesArrayRequest([]);
        setTempVoice(null);
        setTempVideo(null);
        setTempImages([]);
        setIsPublic(false);
        setTaggedEmails([]);
        setChosenPrompt("");
        setPrivacySetting("Friends & Family");
      }
    } catch (err) {
      setIsLoading(false);

      const submitErrors = createErrors(err);
      if (submitErrors.voiceOrDescriptionIncluded) {
        setStep(0);
        Swal.fire({
          title: "Memory details are required",
          timer: 3000,
          type: "warning",
        });
      } else if (submitErrors.date) {
        setStep(1);
        Swal.fire({
          title: "A valid date is required",
          timer: 3000,
          type: "warning",
        });
      } else if (submitErrors.title) {
        setStep(2);
        Swal.fire({
          title: "A title is required",
          timer: 3000,
          type: "warning",
        });
      }

      setErrors(submitErrors);
    }
  };

  const categoryScroll = (direction) => {
    const container = document.querySelector(".prompt-badges");
    const value = direction === "left" ? -126 : 126;
    container.scrollBy({
      left: value,
      behavior: "smooth",
    });
  };

  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);
  const buttons = () => {
    return (
      <GlowingButton>
        <Button
          // size='large'
          variant="contained"
          color="secondary"
          className={title === "" && description === "" ? "" : "glowing-button"}
          disabled={(title === "" && description === "") || isUploading}
          style={{
            height: "3rem",
            fontSize: "1.1rem",
            fontWeight: "bold",
            borderRadius: "100px",
            maxWidth: "432px",
            color: "#0A2156",
            boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
          }}
          onClick={() => {
            validateAndStoreMemory({
              type: "saveShare",
              memoryId,
            });
          }}
        >
          <FaCheckCircle />
          &nbsp;Finish
        </Button>
      </GlowingButton>
    );
  };

  const startJoyride = () => {
    setRunJoyride(true);
  };

  const joyrideCallback = (data) => {
    const { status, action } = data;
    if (
      status.includes("skipped") ||
      status.includes("finished") ||
      action.includes("close")
    ) {
      setRunJoyride(false);
    }
  };

  const getRandomPrompt = (allPrompts = {}) => {
    if (allPrompts.data) {
      const randomPos = Math.floor(Math.random() * allPrompts.data.length);
      setPrompt(allPrompts.data[randomPos].prompt);
      setTitle(allPrompts.data[randomPos].title);
      setChosenPrompt(allPrompts.data[randomPos]?._id);
    } else {
      const randomPos = Math.floor(Math.random() * promptQuestions.length);
      setPrompt(promptQuestions[randomPos].prompt);
      setTitle(promptQuestions[randomPos].title);
      setCategory(promptQuestions[randomPos].category);
      setChosenPrompt(promptQuestions[randomPos]?._id);
    }
  };

  const getPrompts = async (categoryTitle, fromOnboarding) => {
    if (categoryTitle && categoryTitle.length > 0) {
      const { data } = await prompts.getCategoryPrompts({
        category: categoryTitle,
      });
      if (data.length > 0) {
        setPromptQuestions(data);
        if (fromOnboarding) {
          getRandomPrompt({ data });
        }
      } else {
        const { data: allData } = await prompts.getAllPrompts();
        setPromptQuestions(allData);
      }
    } else {
      const { data } = await prompts.getAllPrompts();
      setPromptQuestions(data);
    }
  };

  const getPrompt = async (promptId) => {
    const { data } = await prompts.getPrompt({ promptId });
    setPrompt(data[0].prompt);
    setTitle(data[0].title);
    setChosenPrompt(data[0]._id);
    setCategory(data[0].category);
    await getPrompts("", false);
  };

  const selectPrompt = (prompt) => {
    setPrompt(prompt?.prompt);
    setTitle(prompt?.title);
    setChosenPrompt(prompt?._id);
    setCategory(prompt?.category);
    setShowPromptModal(false);
  }

  return isLoading ? (
    <Loading memoryLoading />
  ) : (
    <GreenBlackHeading
      heading={memoryId ? "Edit Your Story" : "Create A Story"}
      // FooterElements={Footer}
      validateMemory={(options) => validateAndStoreMemory(options)}
      memoryId={memoryId}
      loading={isLoading}
      headingDescription=" "
      //  headingDescription="Think of this as the story of your life. What’s your earliest memory? What’s the most exciting thing that happened to your last year?
      //  What would you want to tell your great, great grandchildren about? Most people have added at least ten memories, but we’re sure you’ve lived a much richer life than most!"
    >
      <Joyride
        callback={(e) => joyrideCallback(e)}
        steps={joyrideSteps}
        continuous={true}
        run={runJoyride}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        styles={{
          options: {
            primaryColor: "#0A2156",

            textColor: "#222222",
            zIndex: 1000,
          },
        }}
      />

      <JoyrideButtonWrapper>
        <Button
          onClick={() => startJoyride()}
          color="primary"
          variant="text"
          style={{ fontWeight: "bold", color: "white" }}
        >
          <HelpIcon />
          &nbsp; Show me how
        </Button>
      </JoyrideButtonWrapper>
      <Modal
        open={showPromptModal}
        onClose={() => setShowPromptModal(false)}
        id="add-photo-popup"
      >
        {/* <Paper
          elevation={1}
          style={{
            position: "absolute",
            textAlign: "center",
            backgroundColor: "",
            color: "",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: window.innerWidth < 1024 ? "90%" : "70%",
            borderRadius: "2rem",
            overflow: "scroll",
            paddingTop: "2rem",
            paddingBottom: "2rem",
         
            marginTop: window.innerHeight < 700 ? "5rem" : "1rem",
            marginBottom: "1rem",
            backgroundColor: "white",
          }}
        > */}
        <div 
          style={{             
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: window.innerWidth < 1024 ? "90%" : "70%", 
          }}
        >
          <div style={{ position: "absolute", top: "-40px", right: "0px" }}>
            <Close
              style={{ cursor: "pointer", color: 'white', fill: 'white', stroke: 'white' }}
              onClick={() => setShowPromptModal(false)}
            />
          </div>

          <PromptPicker isModal selectPromptFunc={selectPrompt} chosenCategory={category || 'all' } />
        </div>

        {/* </Paper> */}
      </Modal>
      <Modal
        open={firstTime}
        aria-labelledby="loading-modal"
        aria-describedby="loading-modal"
      >
        <div
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
          }}
        >
          <Paper
            elevation={3}
            style={{
              padding: "2rem",
              maxWidth: "500px",
              textAlign: "left",
              width: "90vw",
              margin: "2rem",
            }}
          >
            <Typography variant="h5" gutterBottom>
              Welcome to the Create Story page!
            </Typography>
            <Typography>
              This is where you will tell the stories that will help you to be
              remembered forever.
              <br /> <br />
              With this being your first time, did you want to go through the
              walkthrough?
            </Typography>
            <Grid
              container
              spacing={1}
              style={{ textAlign: "center", marginTop: "1rem" }}
            >
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    height: "3rem",
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    borderRadius: "100px",
                    boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                    maxWidth: "432px",
                  }}
                  fullWidth
                  onClick={() => {
                    setFirstTime(false);
                    setRunJoyride(true);
                  }}
                >
                  Yes Please!
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    setFirstTime(false);
                  }}
                  style={{ textDecoration: "underline", color: "#0A2156" }}
                >
                  No Thanks
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Modal>

      <Paper
        elevation={3}
        style={{
          width: "100%",
          maxWidth: "1000px",
          margin: "0 auto 5rem auto",
          marginBottom: window.innerWidth < 1024 ? "8rem" : "2rem",
        }}
      >
        <Paper
          elevation={0}
          style={{
            padding: "2rem 2rem 0rem",
            textAlign: "center",
            marginBottom: "0.5rem",
          }}
        >
          <Grid
            container
            spacing={3}
            id="prompt-selection"
            style={{
              display: "flex",
              flexDirection:
                window.innerWidth > 1279 ? "row" : "column-reverse",
            }}
          >
            <Grid item xs={12} lg={9} id="prompt-generator">
              <PromptGenerator
                prompt={prompt}
                promptCategory={category}
                showRandomPrompt={getRandomPrompt}
                id="prompt-card"
              />
            </Grid>
            <Grid
              xs={12}
              md={3}
              id="prompt-button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
                marginBottom: window.innerWidth < 600 ? "1rem" : "2rem",
                marginTop: window.innerWidth < 600 ? "-1rem" : "0rem",
              }}
            >
              <CallToActionButton
                title="Show All Prompts"
                clickFunction={() => setShowPromptModal(true)}
              />
            </Grid>
          </Grid>
        </Paper>
        <Card
          error={
            errors &&
            (errors["description"] || errors["voiceOrDescriptionIncluded"])
          }
          card={cardsData.detailsCard}
          dataWithHandlers={dataWithHandlers}
          id="voice-description-video-card"
        />
        <Divider logo />
        <Card
          error={errors && errors[cardsData.imageCard.inputType]}
          card={cardsData.imageCard}
          dataWithHandlers={dataWithHandlers}
          id="image-card"
        />
        <Divider logo />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Card
              error={errors && errors[cardsData.titleCard.inputType]}
              card={cardsData.titleCard}
              dataWithHandlers={dataWithHandlers}
              id="title-card"
            />
          </Grid>
          {window.innerWidth < 960 && (
            <Grid item xs={12}>
              <Divider logo />
            </Grid>
          )}
          <Grid item xs={12} lg={6}>
            <Card
              error={errors && errors[cardsData.DateCard.inputType]}
              card={cardsData.DateCard}
              dataWithHandlers={dataWithHandlers}
              id="date-card"
            />
          </Grid>
        </Grid>
        <Divider logo />
        <Card
          error={errors && errors[cardsData.privacyCard.inputType]}
          buttons={buttons}
          card={cardsData.privacyCard}
          dataWithHandlers={dataWithHandlers}
          id="privacy-card"
        />
      </Paper>
      {/* <div
        id="bottom-nav"
        style={{
          background: "#071b44",
          position: "fixed",
          left: 0,
          right: 0,
          bottom: 0,
          height: "90px",
          width: "100%",
          zIndex: "2",
          // opacity: 0.4
          color: "white",
          display: window.innerWidth > 1023 ? "none" : "block",
        }}
      >
        <Grid
          id="bottomnav"
          container
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignContent: "center",
          }}
        >
          <Grid
            class="bottomnav-button"
            item
            id="friends-nav"
            style={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
            onClick={() => history.push(C.MY_FRIENDS_MEMORIES)}
          >
            <Friends style={{ fontSize: "2rem", alignSelf: "center" }} />

            <Typography>Friends</Typography>
          </Grid>
          <Grid
            class="bottomnav-button"
            item
            id="groups-nav"
            style={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
            onClick={() => history.push(C.GROUPS_URL)}
          >
            <Groups
              style={{
                marginBottom: "0.3rem",
                fontSize: "2rem",
                alignSelf: "center",
              }}
            />
            Groups
          </Grid>
          <Grid
            class="bottomnav-button"
            id="photos-nav"
            item
            style={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
            onClick={() => history.push(C.DASHBOARD_URL)}
          >
            <Stories style={{ fontSize: "2rem", alignSelf: "center" }} />
            <Typography>Stories</Typography>
          </Grid>
          <Grid
            class="bottomnav-button"
            id="profile-nav"
            item
            style={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Camera
              style={{
                marginBottom: "0rem",
                fontSize: "2rem",
                alignSelf: "center",
              }}
              onClick={() => history.push(C.ALBUMS_URL)}
            />
            <Typography>Photos</Typography>
          </Grid>
        </Grid>
      </div> */}
    </GreenBlackHeading>
  );
}
