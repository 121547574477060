import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  Button,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Avatar,
} from "@material-ui/core";

import Close from "@material-ui/icons/CancelOutlined";
import CallToActionButton from "../../GroupComponents/Buttons/CallToActionButton";

export default function AcceptModal({ 
  username='',
  profilePicture={},
  acceptFunc=()=>{},
  declineFunc=()=>{}, 
}) {
  /* Password Visibility */

  return (
    <Paper
      id="accept-friend-modal"
      style={{
        position: "absolute",
        width: window.innerWidth < 600 ? "90%" : "600px",
        backgroundColor: "white",
        padding: "0rem",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "2rem",
        maxHeight: "90%",
        overflowY: "scroll",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        style={{
          backgroundColor: "#0DA7A7",
          display: "flex",
          justifyContent: "center",
          padding: "2rem",
          borderRadius: "2rem 2rem 0rem 0rem",
        }}
      >
        <img
          src={require("../../../../assets/onboarding/logo-white.png")}
          style={{ maxWidth: "100%", width: "120px" }}
        />
      </Grid>
      <Grid
        id="friends-picture"
        style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
      >
        <Avatar 
          src={profilePicture?.imagePath || ''}
          style={{ width: window.innerHeight < 700 ? "80px" : "120px", height: window.innerHeight < 700 ? "80px" : "120px" }}
        >
          {username[0]}
        </Avatar> 
      </Grid>
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "-2rem",
          marginRight: "-2rem",
          marginRight: window.innerWidth < 500 ? "1rem" : "2rem",
          marginLeft: window.innerWidth < 500 ? "1rem" : "2rem",
        }}
      >
        <Typography
          gutterBottom
          color="primary"
          style={{
            textAlign: "center",
            marginTop: "3rem",
            fontSize: "1.5rem",
            fontWeight: "900",
          }}
        >
          {username} wants to share their stories with you
        </Typography>
        <Typography
          gutterBottom

          variant="body1"
          style={{
            textAlign: "center",
            marginBottom:'-1rem',
          }}
        >
          By accepting this invitation, you also give {username} permission to
          view and comment on any stories you choose to make and share with your
          friends and family.
        </Typography>
      </Grid>
      <Grid
        xs={12}
        id="accept-friend-buttons"
        style={{
          display: "flex",
          flexWrap:'wrap',
          justifyContent: "space-around",
          alignItems: "center",
          marginBottom: "1rem",
          padding:'2rem'
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          style={{
            borderRadius:'100px',
            fontWeight:'bold',
            padding:'1rem 2rem',
            boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',
            marginTop:'1rem'
          }}
          onClick={() => acceptFunc()}
        >
          Accept Invitation
        </Button>
        <Button
         style={{
            borderRadius:'100px',
            fontWeight:'bold',
            padding:'1rem 2rem',
            boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',
            marginTop:'1rem',
          }}
          variant="outlined"
          color="primary"
          onClick={() => declineFunc()}
        > 
          Decline Invitation
        </Button>
      
      </Grid>
    </Paper>
  );
}
