import styled from 'styled-components';

import Link from '../../Common/Link';
import { breakpoints, size } from '../../../constants/breakPoints';
import Button from '../../Common/Button';

// styles taken from ProfileMemoriesView.style.js

export const Main = styled.main`
  max-width: 100rem;
  margin: 0 auto;
  margin: 2rem 1rem 5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;

  @media ${breakpoints.laptop} {
    padding: 4rem 1rem 1rem 23rem;
    flex-direction: row;
    align-items: left;
    justify-content: space-around;
    align-items: flex-start;
  }
`;

export const Wrapper = styled.div`
  footer {
    display: none;
  }
`;

export const TitleWrapper = styled.h1`
  font-size: 1.7rem;
  margin: 1.5rem 0;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: var(--primary);
`;

export const DateWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;
  font-weight: bold;
`;

export const HeaderWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  @media ${breakpoints.laptop} {
    max-width: 20rem;
    min-width: 20rem;
  }
`;

export const Img = styled.img`
  width: 50%;
  padding-bottom: 1.5rem;
`;

export const P = styled.p`
  margin-bottom: 1.5rem;
`;

export const MemorySection = styled.section`
  align-self: flex-start;
  @media ${breakpoints.laptop} {
    max-width: 20rem;
    margin-top: 4rem;
  }
`;

// end styles taken from ProfileMemoriesView.style.js

// styles taken from Landing.style.js

export const ButtonWrapper = styled.section`
  display: flex;
  /* flex-flow: column nowrap; */
  align-items: center;
  z-index: 1;
  text-align: center;
  justify-content: center;

  @media (min-width: ${size.laptop}) {
    /* margin-right: 15%; */
  }
`;

export const LandingLink = styled(Link)`
  /* margin-top: 20%; */
  text-transform: uppercase;
`;

// end styles taken from Landing.style.js

export const ColorButton = styled(Button)`
  background-color: var(--primary-navy);
  color: var(--neutral-white);
  margin: 0px 10px;
  margin-bottom: 20px;
`;
