import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { breakpoints, breakpointsMax } from '../../../constants/breakPoints';

import books from '../../../assets/onboarding/books.jpg'


export const Header = styled.header`
  /* height: 155px;
    width: 100vw;
    padding: 1rem 1rem 0 1rem;
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 2rem; */
`;

export const Main = styled.main`
  padding: 2rem 3rem 1rem 2rem;

  @media ${breakpointsMax.tablet} {
    padding: 1rem 1rem 1rem 1rem;
  }

  @media ${breakpoints.tablet} {
    padding: 2rem 1rem 1rem 2rem;
  }
`;


export const BackgroundWrapper = styled.div`
  min-height: 100vh;
  background: linear-gradient(0deg, var(--primary-navy), var(--secondary-blue), var(--secondary-aqua)) !important; 
`;



export const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  img {
    margin: auto;
    width: 200px;
  }
`;

export const H1 = styled.h1`
  font-size: 38px;
  font-weight: 900;
  position: absolute;
  bottom: 0;
  line-height: 2.1rem;
  color: var(--white);
  padding-left: 1rem;
  /* position: relative; */
`;

export const Fieldset = styled.fieldset`
  padding: 2rem;
  margin: 0 auto;
  max-width: 600px;
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  display: block;
  padding: 3.25rem;
  margin-bottom: 2rem;
  @media ${breakpointsMax.tablet} {
    padding: 2rem;
  }
`;

export const SignInBackgroundWrapper = styled.div`
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-image:  url(${books}), linear-gradient(90deg, var(--primary-navy), var(--secondary-aqua)); 
  
  @media ${breakpointsMax.laptopL} {
    background-image: linear-gradient(90deg, #0A2156, #0C999D);
  }
`


export const StyledExtLink = styled.a`
  color: var(--secondary-aqua);
  text-decoration: underline;

  :hover,
  :active,
  :focus {
    color: var(--secondary);
  }
`;

export const Input = styled.input`
  border: ${({ error }) =>
    error
      ? 'solid 2px var(--secondary-cherry)'
      : 'solid 2px rgb(225, 225, 225)'};
  font-weight: 400;
  outline: none;
  transition: all ease 0.2s;
  background: none;
  padding: 15px;
  border-width: 2px;
  border-radius: 5px;
  font-size: initial;
  :active,
  :focus {
    font-size: initial;
    border: solid 2px rgb(195, 195, 195);
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    width: 100%;
  }
`;

export const Legend = styled.legend`
  /* hide legend but keep for accessibility */
  position: absolute !important;
  opacity: 0;
  pointer-events: none;
`;

export const Row = styled.div`
  margin-bottom: 0rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0rem;
  label {
    margin: 0px;
  }
`;

export const Label = styled.label`
  /* hide label but keep for accessibility */
  position: absolute !important;
  opacity: 0;
  pointer-events: none;
`;

export const StyledLink = styled(Link)`
  color: var(--primary);
  text-decoration: underline;

  :hover,
  :active,
  :focus {
    color: var(--secondary);
  }
`;

export const ForgotPassword = styled.p`
  color: var(--primary);
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  align-self: flex-end;
  padding-top: 0.25rem;
`;

export const LogInWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 0.5rem;
    font-weight: 300;
    font-size: 14px;
  }
  a {
    margin-bottom: 3rem;
  }
`;

export const ErrorMsg = styled.p`
  color: var(--secondary-cherry);
  font-size: 14px;
  align-self: flex-end;
  padding-top: 0.25rem;
`;

export const SubmitErrorMsg = styled.p`
  color: var(--error);
  font-size: 14px;
  width: 100%;
  text-align: center;
  margin: 1rem 0;
`;

export const SignInText = styled.div`
  text-align: center;
  margin: 1.5rem 0rem;
  p {
    display: inline-block;
    position: relative;
    text-align: center;
    span {
      background: #fff;
      padding: 0 10px;
      position: relative;
      z-index: 1;
    }
    &:before {
      background: #ddd;
      content: '';
      height: 1px;
      position: absolute;
      top: 50%;
      width: calc(100% + 300px);
      left: -150px;
    }
  }
  @media ${breakpointsMax.laptop} {
    p {
      &:before {
        height: 0px;
        position: relative;
        top: 0;
        width: 100%;
        left: 0px;
      }
    }
  }
`;

export const SocialLoginWrapper = styled.div`
  justify-content: center;
  .kep-login-facebook {
    font-size: 14px !important;
    padding: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
      rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    border-radius: 2px !important;
    font-family: 'Open Sans' !important;
    font-weight: 400 !important;
    text-align: left;
    text-transform: none;
    height: 41px !important;
    svg {
      margin-right: 22px;
      transform: scale(1.2);
    }
    :hover {
      opacity: 0.9;
    }
  }
  button {
    margin: 5px 0px 5px 0px;
    width: 100%;
  }
`;

// checkbox styles

export const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const StyledCheckbox = styled.span`
  position: absolute;
  left: 0px;
  height: 1rem;
  width: 1rem;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--neutral-mist);
  border: ${({ error }) => error && '1px solid red'};
  cursor: pointer;
  transition: all 0.3s ease-out;
  -webkit-transform: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;

  :after {
    position: absolute;
    content: '';
    left: 0.5rem;
    top: 0.5rem;
    height: 0px;
    width: 0px;
    border-radius: 4px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
    -webkit-transform: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
  }
`;

export const CheckboxLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 1rem;
  padding-left: 2rem;
  font-weight: 300;
  line-height: 1rem;
  font-size: 14px;

  input:checked ~ span {
    background-color: var(--secondary-aqua);
    border-radius: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }

  input:checked ~ span::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 5px;
    top: 1px;
    width: 4px;
    height: 0.5rem;
    border: solid white;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
  }
`;
