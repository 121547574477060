import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import {
  Paper,
  Grid,
  Typography,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//Badges
import AllPrompts from "../../../assets/prompts/all.png";
import Autobiography from "../../../assets/prompts/autobiography.png";
import Wellbeing from "../../../assets/prompts/wellbeing.png";
import Family from "../../../assets/prompts/family.png";
import Childhood from "../../../assets/prompts/childhood.png";
import Teenager from "../../../assets/prompts/teenager.png";
import Adult from "../../../assets/prompts/adult.png";
import Hobbies from "../../../assets/prompts/hobbies.png";
import Relationships from "../../../assets/prompts/relationships.png";
import Entertainment from "../../../assets/prompts/entertainment.png";
import Reminisce from "../../../assets/prompts/reminisce.png";
import Future from "../../../assets/prompts/future.png";
import LeftArrow from "../../../assets/prompts/leftarrow.png";
import RightArrow from "../../../assets/prompts/rightarrow.png";
import Left from "@material-ui/icons/ArrowLeft";
import Right from "@material-ui/icons/ArrowRight";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#E9F3F5",
  },
  inline: {
    display: "inline",
  },
}));

const PromptButtons = (props) => {
  const badges = [
    {
      id: 1,
      src: AllPrompts,
      name: "all prompts badge",
      category: "all"
    },
    {
      id: 2,
      src: Autobiography,
      name: "autobiography badge",
      category: "autobiography"
    },
    {
      id: 3,
      src: Wellbeing,
      name: "wellbeing badge",
      category: "wellbeing"
    },
    {
      id: 4,
      src: Family,
      name: "family badge",
      category: "family"
    },
    {
      id: 5,
      src: Childhood,
      name: "childhood badge",
      category: "childhood"
    },
    {
      id: 6,
      src: Teenager,
      name: "teenager badge",
      category: "teenager"
    },
    {
      id: 7,
      src: Adult,
      name: "adult badge",
      category: "adult"
    },
    {
      id: 9,
      src: Hobbies,
      name: "hobbies badge",
      category: "hobbies"
    },
    {
      id: 10,
      src: Relationships,
      name: "relationship badge",
      category: "relationships"
    },
    {
      id: 11,
      src: Entertainment,
      name: "entertainment badge",
      category: "entertainment"
    },
    {
      id: 12,
      src: Future,
      name: "future badge",
      category: "future"
    },
    {
      id: 13,
      src: Reminisce,
      name: "reminisce badge",
      category: "reminisce"
    },
  ];

  const classes = useStyles();

  const { allPrompts } = props;

  const calculatePercentage = (category) => {
    if (category === 'all') {
      const completed = allPrompts.filter(prompt => prompt?.completed);
      const percentage = (completed?.length / allPrompts?.length) * 100;
      return percentage ? Math.round(percentage) : 0
    } else {
      const filteredPrompts = allPrompts?.filter(prompt => prompt?.category?.toLowerCase() === category.toLowerCase())
      const completed = filteredPrompts.filter(prompt => prompt?.completed);
      const percentage = (completed?.length / filteredPrompts?.length) * 100;
      return percentage ? Math.round(percentage) : 0
    }
  }

  return (
    <Grid id="prompt-picker">
      <Grid container style={{ display: "flex", justifyContent: "space-evenly", }}>
        <Grid
          item
          xs={1}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Left
            onClick={() => props.scrollFunc('left')}
            color="secondary"
            style={{
              marginBottom: "0rem",
              fontSize: window.innerWidth < 400 ? "5rem" :"6rem",
              alignSelf: "center",
              cursor: 'pointer',
            
            }}
          
          />
        </Grid>
        <Grid
          xs={8}
          id="prompt-badges"
          className="prompt-badges"
          spacing={1}
          style={{
            paddingRight: "0.5rem",
            overflowX: "",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
          }}
        >
          {badges.map((badge) => (
            <Grid
              container
              style={{
                padding: window.innerWidth < 1024 ? "0.5rem" : "0.5rem",
                marginRight: "0.1rem",
              }}
            >
              {props?.clickFunction ? (
                <div 
                  style={{ 
                    position: 'relative', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: window.innerWidth < 1024 ? "flex-start" : "center", 
                    padding: '5px' 
                  }}
                >
                  <img
                    src={badge.src}
                    alt={badge.name}
                    onClick={() => props?.clickFunction(badge?.category)}
                    style={{
                      maxHeight: window.innerWidth < 1024 ? "70px" : "95px",
                      borderRadius: "100%",
                    
                      cursor: 'pointer',
                      zIndex: '2',
                    }}
                  />
                  {/* Hook up value to completion */}
                  <CircularProgress 
                    variant="determinate" 
                    color="secondary" 
                    value={calculatePercentage(badge?.category)} 
                    size={window.innerWidth < 1024 ? 80 : 105} 
                    style={{ 
                      position: 'absolute', 
                      top: 0, 
                      left: 0, 
                      background:'white',
                      borderRadius:'100%',
                    }} 
                  />
                </div>

              ) : (
                <NavLink
                  to={{
                    pathname: '/prompts',
                    state: {
                      category: badge.category
                    },
                  }}
                >
                  <div 
                    style={{ 
                      position: 'relative', 
                      display: 'flex', 
                      justifyContent: 'center', 
                      alignItems: window.innerWidth < 1024 ? "flex-start" : "center", 
                      padding: '5px' 
                    }}
                  >
                    <img
                      src={badge.src}
                      alt={badge.name}
                      style={{
                        maxHeight: window.innerWidth < 1024 ? "70px" : "95px",
                        borderRadius: "100%",
                        cursor: 'pointer',
                        zIndex: '2',
                      }}
                    />
                    {/* Hook up value to completion */}
                    <CircularProgress 
                      variant="determinate" 
                      color="secondary" 
                      value={calculatePercentage(badge?.category)} 
                      size={window.innerWidth < 1024 ? 80 : 105} 
                      style={{ 
                        position: 'absolute', 
                        top: 0, 
                        left: 0 
                      }} 
                    />
                  </div>
                </NavLink>
              )}


            </Grid>
          ))}
        </Grid>
        <Grid
          xs={1}
          style={{
            display: "flex",
            justifyContent: "flex-start",
        
          }}
        >
          <Right
            onClick={() => props.scrollFunc('right')}
            color="secondary"
            style={{
              marginBottom: "0rem",
              fontSize: window.innerWidth < 400 ? "5rem" :"6rem",
              alignSelf: "center",
              cursor: 'pointer'
            }}
          
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PromptButtons;
