import axios from 'axios';
import Swal from 'sweetalert2';
import {
  API_PERMISSION_ACCESS,
  API_PERMISSION_ACCESS_MEMORY_SEND,
} from '../constants/apiRoutes';
import makeError from './makeError';

const create = async ({ 
  sharedWithEmails,
  customMessage='', 
  memory={}, 
  options 
} = {}) => {
  try {
    const { data } = await axios.post(API_PERMISSION_ACCESS, {
      sharedWithEmails,
      memory,
      customMessage
    });
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const updatePermissionAccess = async ({
  _id,
  owner,
  sharedWithId,
  sharedWithEmail,
  relationships,
  nickname,
  options,
} = {}) => {
  try {
    const permissionId = _id;
    const { data } = await axios.patch(
      API_PERMISSION_ACCESS,
      {
        _id,
        owner,
        sharedWithId,
        sharedWithEmail,
        relationships,
        nickname,
        permissionId,
      },
      permissionId,
    );
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const sendMemory = async ({ sharedWithEmail, memory = {}, options } = {}) => {
  try {
    const { data } = await axios.post(API_PERMISSION_ACCESS_MEMORY_SEND, {
      sharedWithEmail,
      memory,
    });
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const deletePermissionAccess = async ({
  id,
  user = 'This user',
  options,
} = {}) => {
  try {
    const popup = await Swal.fire({
      title: 'Are you sure?',
      text: `After clicking the remove button, ${user} will lose all access to your memories`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Remove',
    });
    if (popup.value) {
      await axios.delete(`${API_PERMISSION_ACCESS}/${id}`);
      return { data: true };
    }
    return {};
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const getAllGivenPermissionAccess = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(API_PERMISSION_ACCESS, {
      params: { given: true },
    });
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const getAllTakenPermissionAccess = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(API_PERMISSION_ACCESS, {
      params: { taken: true },
    });
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const permissionAccess = {
  create,
  updatePermissionAccess,
  sendMemory,
  deletePermissionAccess,
  getAllGivenPermissionAccess,
  getAllTakenPermissionAccess,
};

export default permissionAccess;
