// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// nanoid
import { nanoid } from 'nanoid';

// MUI
import {  
  Avatar,
  Paper,
  Typography,
  Grid,
  FormLabel,
  FormGroup,
  Divider,
  FormControlLabel,
  Tooltip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Checkbox,
  Button,
  Chip,
  Collapse,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

// MUI Icons
import FaceIcon from '@material-ui/icons/Face';
import OnlyMeIcon from '@material-ui/icons/Lock';
import FriendsAndFamilyIcon from '@material-ui/icons/SupervisedUserCircle';
import SpecificGroupsIcon from '@material-ui/icons/GroupWork';
import PublicIcon from '@material-ui/icons/Public';

// Styled components
import { PrivacySettingsStyles } from './PrivacySettings.style';

// API (update memory)
import { memories as memoriesAPI } from '../../../../api';

export const PrivacySettings = ({
  privacySetting = '',
  setPrivacySetting = () => {},
  taggedEmails = [],
  setTaggedEmails = () => {},
  friends = [],
  checklist = {},
  setChecklist = () => {},
  definedRelationships = [],
  ...props
}) => {
  // const [privacySetting, setPrivacySetting] = useState(memory?.privacySetting);
  const [groupsAndFriends, setGroupsAndFriends] = useState(undefined);
  // const noChangesMade = privacySetting === memory?.privacySetting;
  const toggleCheckbox = (e, state, setState) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    });
  }; 

  // console.log(friends);
  // console.log(taggedEmails);
  // console.log(checklist);
  // console.log(groupsAndFriends)
  // useEffect(() => {
  //   setGroupsAndFriends(renderFriendsAndGroupsSection());
  // }, [checklist, taggedEmails])

  const privacySettingButtons = [
    {
      text: 'Friends & Family',
      icon: <FriendsAndFamilyIcon />,
      description: `Your story is shared with everyone that you've added as a friend or family member.`,
    },
    {
      text: 'Only Me',
      icon: <OnlyMeIcon />,
      description: `Your story is 100% private - your friends and family can't see it, and it can't be shared or accessed via any links.`,
    },
    // { 
    //   text: 'Specific Groups', 
    //   icon: <SpecificGroupsIcon />, 
    //   description: `Your memory is visible only by specific friends or groups that you select. This gives you the finest level of control on how to share.`, 
    // },
    {
      text: 'Public',
      icon: <PublicIcon />,
      description: `Your story is visible not just to your friends and family, but also to anyone with or without an Iternal account. You can also get a public link to your story to share on Social Media.`,
    },
  ];
  const renderFriendsAndGroupsSection = () => {
    const renderGroups = () => {
      const relsWithCountsArray = definedRelationships.map(relationship => {
        const relObj = {
          relationship: relationship.toString(),
          count: 0,
          friends: [],
        };

        if (friends && friends?.length > 0) {
          friends.forEach(friend => {
            if (friend?.relationships?.[relationship]) {
              relObj.count += 1;
              relObj.friends.push(friend?.name || friend?.sharedWithEmail);
            }
          });
        }

        return relObj;
      });

      const generateTooltipProps = el => {
        const count = el?.count;
        const friendsArr = el?.friends;

        if (count === 0) {
          return {
            title: `You haven't added anyone to the ${el?.relationship} group yet.`,
          };
        }
        if (count > 0) {
          const tooltipProps = { title: '' };
          if (count === 1) {
            tooltipProps.title = `You've added ${friendsArr[0]} to the ${el?.relationship} group`;
          }
          if (count === 2) {
            tooltipProps.title = `You've added ${friendsArr[0]} and ${friendsArr[1]} to the ${el?.relationship} group`;
          }
          if (count === 3) {
            tooltipProps.title = `You've added ${friendsArr[0]}, ${friendsArr[1]} and ${friendsArr[2]} to the ${el?.relationship} group`;
          }
          if (count > 3) {
            tooltipProps.title = `You've added ${friendsArr[0]}, ${
              friendsArr[1]
            }, ${el[2]} and ${count - 3} ${
              count - 3 === 1 ? 'other person' : 'other people'
            } to the ${el?.relationship} group`;
          }

          return tooltipProps;
        }
      };
      return (
        <List className="listItemsWrapper">
          <Typography className="listTitle" variant="body2">
            Groups
          </Typography>
          <Divider />
          {relsWithCountsArray?.length > 0 &&
            checklist &&
            taggedEmails &&
            relsWithCountsArray.map(el => (
              <div key={nanoid()}>
                <FormControlLabel
                  key={nanoid()}
                  className={`listItem ${
                    checklist?.[el?.relationship] ? 'checked' : 'unchecked'
                  }`}
                  control={
                    <Checkbox
                      checked={checklist?.[el?.relationship]}
                      onChange={e => toggleCheckbox(e, checklist, setChecklist)}
                      name={el?.relationship}
                    />
                  }
                  label={
                    <ListItem className="listItemDetails">
                      <Tooltip {...generateTooltipProps(el)} arrow>
                        <ListItemText
                          primary={el?.relationship}
                          secondary={
                            el?.count > 0 &&
                            `${el?.count} ${
                              el?.count === 1 ? 'person' : 'people'
                            } added`
                          }
                        />
                      </Tooltip>
                    </ListItem>
                  }
                />
                <Divider className="listItemDivider" />
              </div>
            ))}
        </List>
      );
    };

    const renderFriends = () => (
      <List className="listItemsWrapper">
        <Typography className="listTitle" variant="body2">
          Specific Friends
        </Typography>
        <Divider />
        {friends?.length > 0 &&
          friends.map(el => {
            const hasName = el.name && el.name !== '';

            const renderAvatar = () => {
              if (el.image) {
                return <Avatar src={el.image} />;
              }
              if (hasName) {
                const namesArr = el.name.split(' ');

                const nameStr = namesArr.reduce((acc, cur) => acc + cur[0], '');

                return <Avatar>{nameStr}</Avatar>;
              }
              return <Avatar />;
            };

            const renderUsername = () => {
              const liText = {};

              if (hasName) {
                liText.primary = el.name;
                liText.secondary = el.sharedWithEmail;
              } else {
                liText.primary = el.sharedWithEmail;
              }
              return <ListItemText {...liText} />;
            };

            const renderRelationships = ({ limit } = { limit: 3 }) => {
              const { relationships } = el;

              if (relationships) {
                const relsArray = Object.entries(relationships).filter(
                  relationship => relationship[1] === true && relationship[0],
                );
                const shortenedRels = relsArray.slice(0, limit);
                return (
                  <div className="relationshipsSection">
                    {shortenedRels.map(replationship => (
                      <Chip
                        size="small"
                        className="relationshipChip"
                        key={nanoid()}
                        color="secondary"
                        icon={<FaceIcon />}
                        label={replationship}
                      />
                    ))}
                    {relationships.length > shortenedRels.length && (
                      <Chip
                        key={nanoid()}
                        size="small"
                        className="relationshipChip"
                        color="default"
                        label="..."
                      />
                    )}
                  </div>
                );
              }

              return (
                <div className="relationshipsSection">
                  <Chip
                    size="small"
                    className="relationshipChip"
                    color="secondary"
                    icon={<FaceIcon />}
                    label="Friend"
                  />
                </div>
              );
            };

            return (
              <>
                <FormControlLabel
                  key={nanoid()}
                  className={`listItem ${
                    checklist?.[el?.sharedWithEmail] ? 'checked' : 'unchecked'
                  }`}
                  control={
                    <Checkbox
                      checked={checklist?.[el?.sharedWithEmail]}
                      onChange={e => toggleCheckbox(e, checklist, setChecklist)}
                      name={el?.sharedWithEmail}
                    />
                  }
                  label={
                    <ListItem className="listItemDetails">
                      <ListItemAvatar>{renderAvatar()}</ListItemAvatar>
                      {renderUsername()}
                      {renderRelationships({ limit: 3 })}
                    </ListItem>
                  }
                />
                <Divider className="listItemDivider" />
              </>
            );
          })}
      </List>
    );

    return(
      <Collapse
        in={
          privacySetting === 'Specific Groups' && 
          userHasAddedFriends &&
          checklist
        }
      >
        <div className="section specificGroupsSection">
          <FormLabel>
            <Typography className="sectionTitle" variant="body2">
              Choose Groups & Friends Who Can View This Story
            </Typography>
          </FormLabel>
          <div className="scrollContainer">
            {renderGroups()}
            {renderFriends()}
          </div>
        </div>
      </Collapse>
    );
  };

  const memoryIsntPrivate = privacySetting !== 'Only Me';
  const userHasAddedFriends = friends?.length > 0;

  return (
    <PrivacySettingsStyles {...props}>
      <div className="priavcyButtonsSection">
        {privacySettingButtons.map(button => (
          <Button
            className="privacyButton"
            variant={privacySetting === button.text ? 'contained' : 'outlined'}
            color={privacySetting === button.text ? 'primary' : 'default'}
            onClick={() => setPrivacySetting(button.text)}
          >
            <div
              className={
                privacySetting === button.text
                  ? 'privacyIconHighlighted'
                  : 'privacyIcon'
              }
            >
              {button.icon}
            </div>
            <br />
            <span className="privacyText">{button.text}</span>
          </Button>
        ))}
      </div>
      {privacySettingButtons.map(({ text, description }) => (
        <Collapse in={text === privacySetting}>
          <Alert className="privacyInfo" severity="info">
            <AlertTitle>{text}</AlertTitle>
            {description}
          </Alert>
        </Collapse>
      ))}
      {privacySetting === 'Specific Groups' && (
        <FormGroup style={{ display: 'initial' }}>{renderFriendsAndGroupsSection()}</FormGroup>
      )}
    </PrivacySettingsStyles>
  );
};

PrivacySettings.propTypes = {
  /**
   * Please provide a functiont to update the privacy setting of this memory / piece of content
   */
  updateFunc: PropTypes.func,
};

PrivacySettings.defaultProps = {
  updateFunc: () => {},
};
