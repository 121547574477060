import axios from 'axios';
import Swal from 'sweetalert2';
import history from '../history';
import { CREATE_MEMORY_URL } from '../constants/constantUrls';

import {
  API_STOREMEMORY_URL,
  API_PROFILE_MEMORY_URL,
  API_UPDATE_MEMORY_URL,
  API_GENERATE_STORAGE_URL,
  API_PROFILE_MEMORIES_URL,
  API_CREATE_OTHER_URL,
  API_CREATE_COMP_URL,
} from '../constants/apiRoutes';

import firebase from './firebase';

import makeError from './makeError';

const getAll = async ({ isCheck, options } = {}) => {
  try {
    const { data: userMemories } = await axios.get(API_PROFILE_MEMORIES_URL);

    if (isCheck && userMemories) {
      if (userMemories.length < 1) {
        Swal.fire({
          title: 'You need to create a memory first!',
          type: 'warning',
          confirmButtonColor: '#66CC66',
          cancelButtonColor: '#8a8787',
          confirmButtonText: 'Ok',
        }).then(() => {
          history.push(CREATE_MEMORY_URL);
        });
      }
    }

    return { data: userMemories };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const getMemoryById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${API_PROFILE_MEMORY_URL}/${id}`);

    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

// get all memories created by this user
const getUserMemories = async ({ user, options }) => {
  try {
    const { data } = await axios.get(`${API_PROFILE_MEMORY_URL}`, {
      params: { user },
    });
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const createMemory = async ({ memory, options }) => {
  const {
    title,
    date,
    dateType,
    dateAge,
    description,
    voice,
    video,
    images,
    voiceOrDescriptionIncluded,
    privacySetting,
    type,
    isPublic,
    taggedEmails,
    promptId,
  } = memory;

  try {
    const { data } = await axios.post(`${API_STOREMEMORY_URL}`, {
      title,
      date,
      dateType,
      dateAge,
      description,
      voice,
      video,
      images,
      voiceOrDescriptionIncluded,
      privacySetting,
      type,
      isPublic,
      taggedEmails,
      promptId,
    });

    if (data) {
      Swal.fire({
        title: 'Memory created!',
        type: 'success',
      });
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const createForFriend = async ({ memory, options }) => {
  const {
    title,
    date,
    dateType,
    dateAge,
    description,
    voice,
    video,
    images,
    voiceOrDescriptionIncluded,
    userId,
    author,
  } = memory;

  try {
    const { data } = await axios.post(API_CREATE_OTHER_URL, {
      title,
      date,
      dateType,
      dateAge,
      description,
      voice,
      video,
      images,
      voiceOrDescriptionIncluded,
      userId,
      author,
    });

    if (data) {
      Swal.fire({
        title: `You've left a memory on their timeline!`,
        type: 'success',
      });
      return { data };
    } 
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const createForCompetition = async ({ memory, options }) => {
  const {
    title,
    date,
    dateType,
    dateAge,
    description,
    voice,
    video,
    images,
    voiceOrDescriptionIncluded,
    userId,
    author,
  } = memory;

  try {
    const { data } = await axios.post(API_CREATE_COMP_URL, {
      title,
      date,
      dateType,
      dateAge,
      description,
      voice,
      video,
      images,
      voiceOrDescriptionIncluded,
      userId,
      author,
    });

    if (data) {
      Swal.fire({
        title: `You've successfully entered the competition!`,
        type: 'success',
      });
      return { data };
    } 
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

// gets firebase storage links for  images
const getStorageLink = async ({ memory, options }) => {
  const { images, voice, video } = memory;

  try {
    const { data } = await axios.post(`${API_GENERATE_STORAGE_URL}`, {
      images,
      voice,
      video,
    });
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const updateMemory = async ({ memory, options }) => {
  const {
    title,
    date,
    dateType,
    dateAge,
    description,
    voice,
    video,
    images,
    voiceOrDescriptionIncluded,
    privacySetting,
    type,
    memoryId,
    isPublic,
    taggedEmails,
    promptId,
  } = memory;

  try {
    const { data } = await axios.patch(`${API_UPDATE_MEMORY_URL}`, {
      title,
      date,
      dateType,
      dateAge,
      description,
      voice,
      video,
      images,
      memoryId,
      voiceOrDescriptionIncluded,
      privacySetting,
      type,
      isPublic,
      taggedEmails,
      promptId,
    });
    if (data) {
      // TODO: Re-enable this
      Swal.fire({
        title: 'Memory updated!',
        type: 'success',
      });
      return { data };
    }
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

// uploads image file to firebase
const uploadImage = async ({ image, userId, options }) =>
  firebase.uploadImage({
    image,
    userId,
    isProfile: true,
    options,
  });

const uploadAudio = async ({ voice, userId, options }) => {
  try {
    // Maybe need validation
    if (!voice || !userId) {
      return {
        error: makeError(new Error('Data is missing'), options),
      };
    }

    const generatedName = firebase.generateFileName({
      userId,
      extension: '.mp3',
      isAudio: true,
    });

    await firebase.uploadFile(generatedName, voice);

    return {
      data: {
        generatedName,
      },
    };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const uploadWebcam = async ({ video, userId, options }) => {
  try {
    // Maybe need validation
    if (!video || !userId) {
      return {
        error: makeError(new Error('Data is missing'), options),
      };
    }

    const generatedName = firebase.generateFileName({
      userId,
      extension: '.mp4',
      isVideo: true,
    });

    await firebase.uploadFile(generatedName, video);

    return {
      data: {
        generatedName,
      },
    };
  } catch (error) {
    return { error: makeError(error, options) };
  }
}

const uploadVideo = async ({ video, userId, options }, setUploadProgress) => {
  try {
    if (!video || !userId) {
      return {
        error: makeError(new Error('Data is missing'), options),
      };
    }

    // const generatedName = firebase.generateFileName({
    //   userId,
    //   extension: '.mp4',
    //   isVideo: true,
    // });

    const { data: { generatedName } } = await firebase.uploadVideo({video, userId, isProfile: true, options}, setUploadProgress);

    return {
      data: {
        generatedName,
      },
    };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const deleteMemory = async ({ id, options }) => {
  try {
    const { data } = await axios.delete(`${API_PROFILE_MEMORY_URL}/${id}`);
    return { data };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const memories = {
  // not used
  getAll,
  getMemoryById,
  // not used
  getUserMemories,
  // not used
  createMemory,
  // not used
  updateMemory,
  deleteMemory,
  uploadImage,
  uploadAudio,
  uploadVideo,
  uploadWebcam,
  getStorageLink,
  createForFriend,
  createForCompetition,
};

export default memories;
