import { red } from '@material-ui/core/colors';
import styled from 'styled-components';

export const PaperStyle = styled.div`
  padding: 1rem;
  text-align: center;

  @media (min-width: 1280px) {
    margin: 0 auto;
    position: inherit;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const ProgressWrapper = styled.div`
  width: 100px;
  height: 100px;
  margin: auto;
`;

