import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { Information as InformationPage } from '../../../NewComponents/Pages/EmailFutureSelf/Information/Information';
import { Recepient as RecepientPage } from '../../../NewComponents/Pages/EmailFutureSelf/Recepient/Recepient';
import { EmailTheFuture as EmailTheFuturePage } from '../../../NewComponents/Pages/EmailFutureSelf/EmailTheFuture/EmailTheFuture';

import { API_FUTUREMESSAGE_URL } from '../../../constants/apiRoutes';
import { EMAIL_THE_FUTURE_URL } from '../../../constants/constantUrls';

import { createErrors, validate } from '../../../helpers/validation';

import UserContext from '../../../context/user';
import { Main } from './style';
import ReactGA from 'react-ga';

export default () => {
  // Get user email
  const { name, email } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [emails, setEmails] = useState([]);
  const [emailVal, setEmailVal] = useState('');
  const [textVal, setTextVal] = useState('');
  const [activeButton, setActiveButton] = useState({});
  const [dateVal, setDateVal] = useState(undefined);
  const [errors, setErrors] = useState({});

  const getFutureEmails = async () => {
    const { data } = await axios.get(API_FUTUREMESSAGE_URL);
    setEmails(data);
  };

  const selectDate = ({ val, dur }) => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    let sendDate = null;
    switch (dur) {
      case 'day':
        sendDate = new Date(year, month, day + val);
        break;
      case 'week':
        sendDate = new Date(year, month, day + val * 7);
        break;
      case 'month':
        sendDate = new Date(year, month + val, day);
        break;
      case 'year':
        sendDate = new Date(year + val, month, day);
        break;
      default:
        sendDate = new Date(year + val, month, day);
        break;
    }
    setDateVal(sendDate);
    setActiveButton({ val, dur });
  };

  const fireEmail = async () => {
    try {
      const futureEmailData = {
        email: emailVal,
        text: textVal,
        dateToSend: dateVal,
      };
      const validated = await validate('futureEmail', futureEmailData);
      if (validated) {
        setErrors({});
        const { data } = await axios.post(API_FUTUREMESSAGE_URL, {
          message: [textVal],
          dateToSend: dateVal,
          email: emailVal,
        });
        if (data) {
          window.location.href = EMAIL_THE_FUTURE_URL;
        }
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        const submitErrors = createErrors(error);
        setErrors({ ...errors, submitErrors });
        console.log(submitErrors);
      }
    }
  };

  useEffect(() => {
    getFutureEmails();
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, []);

  const renderPageOne = () => (
    <InformationPage futureEmails={emails} nextFunc={() => setPage(2)} />
  );

  const renderPageTwo = () => (
    <RecepientPage
      sendToMeFunc={() => {
        setEmailVal(email);
        setTextVal(
          `Hi future ${name}! This is ${name} writing to you from the past...`,
        );
        setPage(3);
      }}
      sendToSomeoneElseFunc={() => setPage(3)}
    />
  );

  const renderPageThree = () => (
    <EmailTheFuturePage
      emailVal={emailVal}
      setEmailVal={setEmailVal}
      textVal={textVal}
      setTextVal={setTextVal}
      dateVal={dateVal}
      setDateVal={selectDate}
      activeButton={activeButton}
      nextFunc={fireEmail}
      errors={errors}
    />
  );

  return (
    <Main>
      {page === 1 && renderPageOne()}

      {page === 2 && renderPageTwo()}

      {page === 3 && renderPageThree()}
    </Main>
  );
};
