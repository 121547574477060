import React from 'react';
import { 
  Typography, 
  AppBar,
  Toolbar,
  useScrollTrigger,
  Button,
  Badge,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as IternalLogo } from '../../../../assets/iternal-new.svg';
import { ReactComponent as IternalCircleLogo } from '../../../../assets/iternal_logo_symbol.svg';

import { LogoContainer } from './style';

import { GROUP_ADMIN_URL, GROUP_MEMBER_URL } from '../../../../constants/constantUrls';
import CallToActionButton from '../Buttons/CallToActionButton';

export default (props) => {
  const {
    isLoggedIn,
    hasJoinedGroup,
    groupMemberStatus,
    groupId,
    groupShortCode,
    customTheme,
    pendingInvites=0,
    joinGroupFunction
  } = props;

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: 14,
      top: 5,
      // border: `1px solid ${theme.palette.primary.contrastText}`,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
      padding: '0 4px',
    },
  }))(Badge);

  const renderButtons = () => {
    if (!hasJoinedGroup) {
      return (
        <Button 
          variant='outlined'
          style={{ 
            color: customTheme.palette.primary.contrastText,
            borderColor: customTheme.palette.primary.contrastText,
            borderRadius:'100px',
            minWidth:"200px",
            minHeight: "50px",
            fontWeight:"bold",
         
          }}
          onClick={() => joinGroupFunction()}
        >
          Join Group
        </Button>
      )
    }
    if (groupMemberStatus === 'owner' || groupMemberStatus === 'admin') {
      return (
        <>
          <StyledBadge badgeContent={pendingInvites > 9 ? '9+' : pendingInvites} color="primary">
            <Button 
              variant='outlined'
              style={{ 
                color: customTheme.palette.primary.contrastText,
                borderColor: customTheme.palette.primary.contrastText,
                marginRight: '0.5rem',
                borderRadius: '100px',
                minWidth:"100px",
                marginTop:"0.2rem",
marginBottom: "0.2rem",
              }}
              onClick={() => props.history.push(GROUP_MEMBER_URL.replace(':shortId', groupShortCode))}
            >
              Members
            </Button>
    
          </StyledBadge>
          
          
          <Button 
            variant='outlined'
            style={{ 
              color: customTheme.palette.primary.contrastText,
              borderColor: customTheme.palette.primary.contrastText,
              borderRadius: '100px',
              minWidth:"100px",
              marginRight:"0.5rem",
              marginTop:"0.2rem",
              marginBottom: "0.2rem"

            }}
            onClick={() => props.history.push(GROUP_ADMIN_URL.replace(':shortId', groupShortCode))}
          >
            Settings
          </Button>
        </>
      )
      
    }
  }
  
  const ElevationScroll = (props) => {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }
  return (
    <ElevationScroll {...props}>
      <AppBar style={{ backgroundColor: customTheme.palette.primary.main }}>
        <Toolbar>
          <LogoContainer theme={customTheme}>
            {window.innerWidth < 600 ? (
              <IternalCircleLogo 
                style={{
                  maxHeight: '50px',
                  cursor: 'pointer',
                }}
                fill='black'
                onClick={() => props.history.push('/')}
              />
            ) : (
              <IternalLogo 
                style={{
                  maxHeight: '50px',
                  cursor: 'pointer',
                }}
                fill='black'
                onClick={() => props.history.push('/')}
              />
            )}
          </LogoContainer>
          <div className="buttonContainer" style={{ width: window.innerWidth < 600 ? '175%' : '100%', textAlign: 'right' }}>
            {renderButtons()}
          </div>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}
