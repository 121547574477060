// React
import React, { useState } from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import history from '../../../../history';

//bottom nav
import Groups from "@material-ui/icons/People";
import Friends from "@material-ui/icons/EmojiPeople";
import Profile from "@material-ui/icons/Face";
import Stories from "@material-ui/icons/MenuBook";
import Camera from "@material-ui/icons/PhotoCamera"

import * as C from "../../../../constants/constantUrls";

// MUI
import {
  Paper,
  Typography,
  Button,
  Chip,
  TextField,
  LinearProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  Avatar,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

// Icons
import FaceIcon from "@material-ui/icons/Face";
import EditIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/HighlightOff";

// Styled components
import { FriendsListStyles } from "./FriendsList.style";

import SocialShare from "../../../../Components/Common/SocialShare";

import { Divider as IternalDivider } from "../../../Divider/Divider";

export const FriendsList = ({
  permissionAccessArr,
  newEmail,
  setNewEmail,
  addFriendFunc,
  editFriendFunc,
  deleteFriendFunc,
  waitingToAdd,
  inviteLink = "",
  copyInviteLinkFunc = () => {},
  ...props
}) => {
  const [inputFocused, setInputFocused] = useState(false);

  const hasFriends =
    permissionAccessArr !== undefined && permissionAccessArr?.length > 0;

  const isSmallScreen = window.innerWidth < 900;

  return (
    <FriendsListStyles {...props}>
      {/* <Typography
        align="center"
        variant="h4"
        gutterBottom
        style={{
          marginBottom: "2rem",
          color: "white",
          fontSize: window.innerWidth < 1024 ? "3rem" : "4rem",
        }}
      >
      Add Friends
      </Typography> */}

      {/* <Paper
        elevation={3}
        style={{
          maxHeight: "80vh",
          overflow: 'scroll',
          borderRadius:'20px',
          padding:  window.innerWidth < 400 ? "1rem" : "2rem",
          marginLeft:  window.innerWidth < 400 ? "1rem" : "2rem",
          marginRight:  window.innerWidth < 400 ? "1rem" : "2rem",
          marginBottom: window.innerWidth < 1024 ? "6rem" : "2rem",

        }}
        className="paperStyles"
      > */}
        {/* <Typography color="primary" variant="h4" gutterBottom>
          Your friends list
        </Typography>
        <Typography variant="body1" gutterBottom>
          Every friend or family member you add here can see your public and
          shared memories
        </Typography>
        <br /> */}
        {/* <div className="addFriendsSection">
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Add friends' emails"
                placeholder="friend@email.com"
                helperText={
                  inputFocused
                    ? "Tip: Add multiple friends by seperating their emails with a comma"
                    : " "
                }
                variant="outlined"
                className="addFriendInputField"
                value={newEmail}
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
                onChange={(e) => setNewEmail(e.target.value)}
              >
                Enter a name
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                className="addFriendButton"
                disabled={waitingToAdd}
                onClick={() => addFriendFunc(newEmail)}
                style={{
                  
                  minHeight: "50px",
                  fontWeight: "700",
                  boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                }}
              >
                Add friend
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" style={{ marginBottom: "1rem" }}>
                - OR -
              </Typography>
              <Typography>
                Click the button below to copy your unique sign-up link to share
                with your loved ones, or share through social media platforms
              </Typography>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (navigator.share) {
                      navigator.share({
                        title: "Iternal",
                        text: "Invite loved ones to Iternal",
                        url: inviteLink,
                      });
                    } else {
                      copyInviteLinkFunc(inviteLink);
                    }
                  }}
                  style={{
                    marginTop: "2rem",
                    borderRadius: "100px",
                    minWidth: "164px",
                    minHeight: "50px",
                    fontWeight: "700",
                    boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                  }}
                >
                  Click to Copy
                </Button>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <SocialShare
                    link={inviteLink}
                    messageBody={
                      "Hey! I'd like to share my memories with you! To sign up to Iternal, click this link"
                    }
                    messageTitle={"I'd like to share my memories with you"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div> */}
        {/* <IternalDivider logo /> */}
        <List style={{ width: '100%', maxHeight: '50vh', overflow: 'scroll' }}>
          {hasFriends &&
            permissionAccessArr.map((el) => {
              const hasName = el?.name && el.name !== "";
              const renderAvatar = () => {
                if (el.image) {
                  return <Avatar src={el.image} />;
                }
                if (hasName) {
                  const namesArr = el.name.split(" ");

                  const nameStr = namesArr.reduce(
                    (acc, cur) => acc + cur[0],
                    ""
                  );

                  return <Avatar>{nameStr}</Avatar>;
                }
                return <Avatar />;
              };

              const renderUsername = () => {
                const liText = {};

                if (hasName) {
                  // Name (and nickname, if present)
                  liText.primary = `${el.name} ${
                    el?.nickname ? `(${el?.nickname})` : ""
                  }`;
                  liText.secondary = el.sharedWithEmail;
                } else if (!hasName && el?.nickname) {
                  liText.primary = el?.nickname;
                  liText.secondary = el.sharedWithEmail;
                } else {
                  liText.primary = el.sharedWithEmail;
                }
                return <ListItemText {...liText} />;
              };

              const renderRelationships = ({ limit = 3 }) => {
                const { relationships } = el;

                if (relationships) {
                  const relsArray = Object.entries(relationships).filter(
                    (relationship) =>
                      relationship[1] === true && relationship[0]
                  );
                  const shortenedRels = relsArray
                    .slice(0, limit)
                    .map((vals) => vals[0]);

                  const showEllipsis =
                    relsArray?.length > shortenedRels?.length;
                  return (
                    <div className="relationshipsSection" key={nanoid()}>
                      {shortenedRels.map((relationship) => (
                        <Chip
                          key={nanoid()}
                          size="small"
                          className="relationshipChip"
                          color="secondary"
                          icon={<FaceIcon />}
                          label={relationship}
                        />
                      ))}
                      {showEllipsis && (
                        <Chip
                          key={nanoid()}
                          size="small"
                          className="relationshipChip"
                          color="default"
                          label="..."
                        />
                      )}
                    </div>
                  );
                }

                return (
                  <div className="relationshipsSection">
                    <Chip
                      size="small"
                      className="relationshipChip"
                      color="secondary"
                      icon={<FaceIcon />}
                      label="Friend"
                    />
                  </div>
                );
              };

              return (
                <>
                  <ListItem
                    key={el._id}
                    className={isSmallScreen ? "displayBlock" : ""}
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div 
                      style={{ 
                        display: 'flex', 
                        flexDirection: 'row', 
                        minWidth: '70%', 
                        alignItems: 'center' 
                      }}
                    >
                      <ListItemAvatar>{renderAvatar()}</ListItemAvatar>
                      <div
                        className="displayBlock"
                        style={{ width: isSmallScreen ? "100%" : "70%" }}
                      >
                        {renderUsername()}
                        {/* {renderRelationships({ limit: 3 })} */}
                      </div>
                    </div>

                    {/* <Button
                      className="manageFriendButtons"
                      fullWidth={isSmallScreen}
                      color="secondary"
                      onClick={() => editFriendFunc(el)}
                      variant="outlined"
                    >
                      <EditIcon />
                      &nbsp;Edit
                    </Button> */}
                    <Button
                      id={el._id}
                      fullWidth={isSmallScreen}
                      className="manageFriendButtons"
                      onClick={() =>
                        deleteFriendFunc({
                          id: el._id,
                          user: hasName ? el.name : el.sharedWithEmail,
                        })
                      }
                      variant="outlined"
                    >
                      <DeleteIcon />
                      &nbsp;Remove
                    </Button>
                  </ListItem>
                  <Divider />
                </>
              );
            })}
        </List>

        {(permissionAccessArr === undefined || waitingToAdd === true) && (
          <div>
            <Typography
              color="primary"
              variant="body1"
              align="center"
              gutterBottom
            >
              Loading...
            </Typography>
            <LinearProgress />
          </div>
        )}

        {permissionAccessArr?.length === 0 && (
          <Typography variant="body1" style={{display:'flex', justifyContent:  window.innerWidth < 500 ? "left" : 'center', marginTop:'1rem', }}>
            <Paper severity="info" style={{background:'#D9F1F1', padding:'1rem'}}>
              <AlertTitle style={{}}>Invite your first friend or family member</AlertTitle>
              You&apos;ll be able to:
              <br></br>
              <ul>
                <li>
                  <span role="img" aria-label="bullet point">
                    &nbsp;&nbsp;✅
                  </span>{" "}
                  Share memories with them
                </li>
                <li>
                  <span role="img" aria-label="bullet point">
                    &nbsp;&nbsp;✅
                  </span>{" "}
                  Tag them in your memories
                </li>
                <li>
                  <span role="img" aria-label="bullet point">
                  &nbsp; &nbsp; &nbsp;&nbsp;✅
                  </span>{" "}
                  See memories they&apos;ve shared 
                </li>
              </ul>
            </Paper>
          </Typography>
        )}
      {/* </Paper> */}
  
    </FriendsListStyles>
  );
};

FriendsList.propTypes = {
  /**
   * Provide an array of friends (permissionAccess objects)
   */
  permissionAccessArr: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      owner: PropTypes.string,
      relationships: PropTypes.object,
      sharedWithEmail: PropTypes.string,
      sharedWithId: PropTypes.string,
    })
  ),

  /**
   * Provide a function for adding a friend
   */
  addFriendFunc: PropTypes.func,

  /**
   * Provide a function for editing a friend
   */
  editFriendFunc: PropTypes.func,

  /**
   * Provide a function for removing a friend
   */
  deleteFriendFunc: PropTypes.func,

  /**
   * Has the user requested that (a) friend(s) be added to their friends list?
   */
  waitingToAdd: PropTypes.bool,
};

FriendsList.defaultProps = {
  permissionAccessArr: undefined,
  addFriendFunc: () => {},
  editFriendFunc: () => {},
  deleteFriendFunc: () => {},
  waitingToAdd: false,
};
