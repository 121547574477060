import React from 'react';
import PropTypes from 'prop-types';

// MUI
import { Paper, Typography } from '@material-ui/core';
import {
  TitleStyle,
  SubtitleStyle,
  MainPaperStyle,
  ImageButtonStyle,
} from './Recepient.style';
import { ImageButton } from '../../../ImageButton/ImageButton';

// Images
import emailYourself from './emailYourself.jpg';
import emailSomeoneElse from './emailSomeoneElse.jpg';

export function Recepient({
  title = '',
  subtitle = '',
  sendToMeFunc = () => {},
  sendToSomeoneElseFunc = () => {},
  ...props
}) {
  return (
    <Paper style={MainPaperStyle} {...props}>
      <Typography style={TitleStyle} variant="h4" color="primary">
        {title}
      </Typography>
      <Typography style={SubtitleStyle} variant="h6">
        {subtitle}
      </Typography>

      <ImageButton
        onClick={() => sendToMeFunc()}
        text="Email Yourself"
        width="100%"
        image={emailYourself}
        style={ImageButtonStyle}
      />
      <ImageButton
        onClick={() => sendToSomeoneElseFunc()}
        text="Email someone else"
        width="100%"
        image={emailSomeoneElse}
        style={ImageButtonStyle}
      />
    </Paper>
  );
}

Recepient.propTypes = {
  /**
   * What should the title of this page be?
   */
  title: PropTypes.string,
  /**
   * What should the subtitle of this page be?
   */
  subtitle: PropTypes.string,
  /**
   * Please provide a function to advance to the next page
   */
  sendToMeFunc: PropTypes.func,
  /**
   * What should the title of the list of your memories be?
   */
  sendToSomeoneElseFunc: PropTypes.func,
};

Recepient.defaultProps = {
  title: 'Select a recepient',
  subtitle: 'Who would you like to send this email to?',
  sendToMeFunc: () => {},
  sendToSomeoneElseFunc: () => {},
};
