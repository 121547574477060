import React, { Component } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

// COMMON
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { API_RESET_PASSWORD } from '../../../constants/apiRoutes';

import * as S from './style';

const recaptchaRef = React.createRef();

export default class EmailInput extends Component {
  state = {
    errors: {},
    requiredFields: {},
    passedCaptcha: false,
  };

  emailValidate = () => {
    const { requiredFields, errors } = this.state;
    const { email } = requiredFields;

    // regular expression for email validation
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
    );

    if (!pattern.test(email)) {
      errors.email = '* Please enter valid email';
    } else if (errors.email === '* Please enter valid email') {
      delete errors.email;
    }

    this.setState({ errors });
    const errorsArr = Object.keys(errors);
    return errorsArr.length < 1;
  };

  handleBlur = e => {
    const { errors } = this.state;
    if (e.target.value === '') {
      errors[e.target.name] = '* This input is required';
    } else if (errors[e.target.name]) {
      delete errors[e.target.name];
    }

    this.setState({ errors });

    if (e.target.name === 'confirmPassword' || e.target.name === 'password') {
      this.passwordValidate();
    }
  };

  handleInput = e => {
    const { requiredFields } = this.state;
    requiredFields[e.target.name] = e.target.value;
    this.setState({ requiredFields });
  };

  handleSubmit = e => {
    const {
      requiredFields: { email },
    } = this.state;
    const { toggleLoading } = this.props;
    if (email && this.emailValidate()) {
      const { showMessage } = this.props;
      toggleLoading();
      axios
        .post(API_RESET_PASSWORD, {
          email,
        })
        .then(() => {
          toggleLoading();
          showMessage('A password reset link was emailed to you', true);
        })
        .catch(err => {
          toggleLoading();
          showMessage('this email is not registered in our database');
        });
    }
  };

  render() {
    const { errors } = this.state;

    const onCaptchaChange = async value => {
      try {
        const { data } = await axios.post('/api/auth/captcha', {
          response: value,
        });
        if (data) {
          console.log(data);
          this.setState({ passedCaptcha: data?.success });
        }
      } catch (err) {
        console.log(err);
      }
    };

    return (
      <div style={{ textAlign: 'center' }}>
        <S.Message>
          Enter your email and we’ll send you a link to reset your password
        </S.Message>

        <TextField
          variant="outlined"
          type="email"
          name="email"
          id="email"
          label="Email Address"
          placeholder="Your email address"
          onChange={this.handleInput}
          onBlur={this.handleBlur}
          error={errors.email}
          style={{ width: '100%' }}
        />
        <S.Label htmlFor="email">Email address</S.Label>
        <S.ErrorMsg>{errors.email}</S.ErrorMsg>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '0.5rem',
          }}
        >
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            onChange={onCaptchaChange}
          />
        </div>

        <S.ButtonWrapper>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            disabled={!this.state.passedCaptcha}
            onClick={this.handleSubmit}
            style={{ fontWeight: 700 }}
          >
            Send
          </Button>
        </S.ButtonWrapper>
      </div>
    );
  }
}
