import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { permissionAccess } from "../../../api";
import imagePlaceHolder from "../../../assets/person-placeholder.jpg";
import { MY_FRIEND_MEMORIES } from "../../../constants/constantUrls";

import { Subtitle } from "./style";

const FriendsList = ({
  friends,
  viewingFriendsMemory,
  givenFriendsList,
  setGivenFriendsList,
  followList=false,
}) => {
  const shareOnClick = async (friend) => {
    const { error } = await permissionAccess.create({
      sharedWithEmails: [friend.owner.email],
    });
    if (!error) {
      Swal.fire({
        title: "User added",
        timer: 3000,
        type: "success",
      });
      const {
        data,
        error: resetError,
      } = await permissionAccess.getAllGivenPermissionAccess({
        options: { redirectToRelatedPage: true },
      });

      if (!resetError) {
        setGivenFriendsList(data);
      }
    }
  };

  if (friends && friends.length) {
    return friends.map((data, i) => {
      return (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          key={data._id}
          style={{
            display: "flex",
            justifyContent: "center",
        
          }}
        >
          <div
            style={{
              padding: "1rem",
              width: "100%",
              borderRadius: "20px",
              textAlign: "center",
              border: 'solid 2px #0bafaa',
              color: "#0bafaa",
              background: "white",
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="bg_image "
                style={{
              
                  backgroundImage: `url(${
                    data.owner.abouts[0]?.image
                      ? data.owner.abouts[0].image.imagePath
                      : imagePlaceHolder
                  })`,
                  backgroundSize: "cover",
                  height: "70px",
                  width: "70px",
                  borderRadius: "1000px",
                  backgroundPosition: "center",
                  color: "#f5f5f5",
                
                }}
              />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginLeft: '0.5rem' }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ fontWeight: "800", textAlign: 'left', fontSize: '1.2rem' }}
                >
                  {data.owner.name}
                </Typography>
                <Subtitle
                  style={{fontWeight: "200" }}
                >
                  {data.memoriesSize} {data.memoriesSize > 1 ? "memories" : "memory"}
                </Subtitle>
              </div>

            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', width: '30%' }}>
              <Link
                to={{
                  pathname: `${MY_FRIEND_MEMORIES.replace(
                    ":shortId",
                    data.owner.linktoken.shortId
                  )}`,
                  state: {
                    details: friends[i]._id,
                    viewingFriendsMemory,
                  },
                }}
                style={{ textDecoration: "none", width: '100%' }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  style={{ fontWeight: "bold", borderRadius: "100px" }}
                >
                  View
                </Button>
              </Link>
              {givenFriendsList.filter((f) => f.sharedWithId === data.owner._id)
                .length === 0 && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => shareOnClick(data)}
                  fullWidth
                  style={{ margin: "0.5rem", background: "white", borderRadius: '100px'}}
                >
                  {window.innerWidth > 1226 ? "Add Back" : "Add"}
                </Button>
              )}
            </div>

          </div>
        </Grid>
      )
    });
    
  }

  return (
    <div
      style={{
        margin: "1rem",
   
        textAlign: "center",
        width: "100%",
        fontSize:'1rem'
      }}
    >
    <div style={{display:'flex', justifyContent:'center'}}>
    <div style={{background:"var(--secondary-aqua)", padding:'1rem', borderRadius:'10px', marginBottom:'2rem'}}>
    <Typography style={{color:'white'}} variant="body1" >
      <p>Sorry! No one has shared their stories with you yet.</p> 
      </Typography>
      </div>
      </div>
 
      <Typography   variant="body1" style={{fontWeight:'bolder', }} >
       Click the Invite Button above to start adding friends.
      </Typography>
    </div>

  );
};

export default FriendsList;
