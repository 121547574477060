import React, { Component } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

import { Paper, Typography, Button } from '@material-ui/core';
import { CHAT_URL } from '../../../constants/constantUrls';
import { API_PROFILE_THISORTHAT_URL } from '../../../constants/apiRoutes';
import { AnswerDisplay } from './ProfileThisOrThatComponents';

import * as S from './style';
import ThisOrThatLogo from './this_or_that_logo.svg';

export default class ThisOrThat extends Component {
  state = {
    answers: [],
    loaded: false,
  };

  componentDidMount = () => {
    axios
      .get(API_PROFILE_THISORTHAT_URL)
      .then(({ data }) => {
        this.setState({ answers: data, loaded: true });
      })
      .catch(error => {
        // what is the best way to handle this
        console.log(error);
      });
  };

  deleteThisOrThat = id => () =>
    Swal.fire({
      title: 'Are you sure you want to delete this answer?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E82C48',
      confirmButtonText: 'Yes, delete it',
    }).then(async result => {
      if (result.value) {
        try {
          const deletedAnswer = await axios.delete(
            `${API_PROFILE_THISORTHAT_URL}/${id}`,
          );

          if (deletedAnswer) {
            const answers = this.state.answers.filter(({ _id }) => _id !== id);
            this.setState({ answers, loaded: true });
          }
        } catch (err) {
          throw new Error('Error deleting Answer!');
        }
      }
    });

  renderAnswer = data =>
    data.map(el => (
      <AnswerDisplay
        deleteThisOrThat={this.deleteThisOrThat(el._id)}
        key={el._id}
        answerData={el}
      />
    ));

  render() {
    const { answers, loaded } = this.state;

    if (!loaded) return <h1>Loading...</h1>;

    return (
      <S.Main>
        <Paper elevation={3} className="paperStyle">
          <div className="paperContents">
            <img
              src={ThisOrThatLogo}
              alt="This or That?"
              style={{ maxWidth: '250px', margin: '1rem' }}
            />
            <Typography variant="body1">
              In 100 years time your descendants will want to know all the
              important things that made you, you. Are you a summer and sunshine
              person or do you prefer wrapping up in the winter? Should they
              follow your love of Game of Thrones or Doctor Who? Settle the
              debate for them now. <br />
              <br />
              If you change your mind on anything, just delete the answer by
              clicking the ‘X’ beside it and then get back to choosing. <br />{' '}
              <br />
              <S.A to={{ pathname: CHAT_URL, state: 'thisOrThat' }}>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  style={{
                    marginBottom: '2rem',
                    width: '100%',
                    fontSize: '1.15rem',
                  }}
                >
                  Start Playing &quot;This or That&quot;
                </Button>
              </S.A>
            </Typography>
            {answers.length !== 0 && this.renderAnswer(answers)}
          </div>
        </Paper>
      </S.Main>
    );
  }
}
