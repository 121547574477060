import axios from 'axios';
import copyTextToClipboard from './copyTextToClipboard';
import { formatDate, formatCleanDate, formatCleanYear } from './formatDate';

// API ROUTES
import { API_LOGOUT_URL } from '../constants/apiRoutes';

// NAV ROUTES
import { LOGIN_URL } from '../constants/constantUrls';

import initialState from '../constants/initialState';

export const handleLogout = (history, handleChangeState) => {
  axios
    .get(API_LOGOUT_URL)
    .then(() => {
      handleChangeState({
        ...initialState,
        windowWidth: window.innerWidth,
      });
      history.push(LOGIN_URL);
    })
    .catch(err => console.error(err));
};

export const copyToClipboard = copyTextToClipboard;
export const formatJSDate = formatDate;
export const formatCleanJSDate = formatCleanDate;
export const formatCleanJSYear = formatCleanYear;
