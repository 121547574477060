import React from 'react';

const Save = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0209 5.14595L16.8541 1.97915C16.663 1.78701 16.4357 1.63468 16.1854 1.53098C15.9351 1.42728 15.6667 1.37427 15.3957 1.37501H4.125C3.39565 1.37501 2.69618 1.66474 2.18046 2.18046C1.66473 2.69619 1.375 3.39566 1.375 4.12501V17.875C1.375 18.6044 1.66473 19.3038 2.18046 19.8196C2.69618 20.3353 3.39565 20.625 4.125 20.625H17.875C18.6043 20.625 19.3038 20.3353 19.8195 19.8196C20.3353 19.3038 20.625 18.6044 20.625 17.875V6.6043C20.6257 6.33335 20.5727 6.06493 20.469 5.8146C20.3653 5.56427 20.213 5.337 20.0209 5.14595V5.14595ZM5.15625 4.81251H12.7188C12.8099 4.81251 12.8974 4.84872 12.9618 4.91319C13.0263 4.97766 13.0625 5.06509 13.0625 5.15626V7.21876C13.0625 7.30993 13.0263 7.39736 12.9618 7.46183C12.8974 7.52629 12.8099 7.56251 12.7188 7.56251H5.15625C5.06508 7.56251 4.97765 7.52629 4.91318 7.46183C4.84872 7.39736 4.8125 7.30993 4.8125 7.21876V5.15626C4.8125 5.06509 4.84872 4.97766 4.91318 4.91319C4.97765 4.84872 5.06508 4.81251 5.15625 4.81251V4.81251ZM11.1611 18.5586C10.4683 18.5912 9.7818 18.4133 9.19188 18.0485C8.60196 17.6837 8.1362 17.1489 7.85581 16.5145C7.57542 15.8801 7.4935 15.1757 7.62083 14.4939C7.74816 13.8121 8.07878 13.1847 8.56924 12.6942C9.0597 12.2038 9.68706 11.8732 10.3689 11.7458C11.0507 11.6185 11.7551 11.7004 12.3895 11.9808C13.0239 12.2612 13.5587 12.727 13.9235 13.3169C14.2883 13.9068 14.4662 14.5933 14.4336 15.2861C14.3929 16.1409 14.0351 16.9499 13.43 17.555C12.8248 18.1601 12.0159 18.5179 11.1611 18.5586V18.5586Z"
      fill="black"
    />
    <path
      d="M11 17.1875C12.1391 17.1875 13.0625 16.2641 13.0625 15.125C13.0625 13.9859 12.1391 13.0625 11 13.0625C9.86091 13.0625 8.9375 13.9859 8.9375 15.125C8.9375 16.2641 9.86091 17.1875 11 17.1875Z"
      fill={color || 'black'}
    />
  </svg>
);

export default Save;
