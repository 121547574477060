export const TitleStyle = {
  lineHeight: 1.25,
  paddingBottom: '1rem',
};

export const MainPaperStyle = {
  padding: '2rem',
  maxWidth: '600px',
  margin: '0.5rem auto 1rem',
  textAlign: 'center',
};

export const SubPaperStyle = {
  padding: '1.5rem',
  maxWidth: '500px',
  margin: '0rem auto 1rem',
  textAlign: 'left',
};

export const SubtitleStyle = {
  lineHeight: 1.25,
  marginBottom: '1.5rem',
  textAlign: 'left',
};

export const BlurbStyle = {
  lineHeight: 1.25,
  marginBottom: '1.5rem',
  textAlign: 'left',
};

export const YourEmailsTitleStyle = {
  lineHeight: 1.25,
  padding: '1rem 0 2rem',
};
