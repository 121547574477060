import React, { useState } from "react";
import ReactDOM from "react-dom";

import "./styles.css";

import {
    Button,
    Paper,
    Grid,
    Typography,
    Modal,
    Backdrop,
    ButtonBase,
    Avatar,
    TextField,
  } from "@material-ui/core";

const EmailList = ({ 
  emails=[], 
  setEmails=()=>{} 
}) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      if (value && isValid(value.trim())) {
        setEmails([...emails, value.trim()]);
        setValue("");
      }
    }
  };

  const handleChange = (evt) => {
    setValue(evt.target.value);
    setError(null);
  };

  const handleDelete = (item) => {
    setEmails(emails.filter((i) => i !== item));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !this.isInList(email));

      setEmails([...emails, ...toBeAdded]);
    }
  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setError(error);

      return false;
    }

    return true;
  };

  const isInList = (email) => {
    return emails.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  return (
    <div>
      <TextField
        className={"input " + (error && " has-error")}
        value={value}
        variant='outlined'
        // placeholder="Type email and press 'Enter'..."
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onPaste={handlePaste}
        style={{ marginBottom: '1rem', }}
      />
      {error && <p className="error">{error}</p>}
      <div style={{marginTop:'0.5rem', display:'flex', flexWrap:'wrap', justifyContent:'flex-start', maxHeight: '70%', overflowY: 'scroll', marginBottom:'1rem'}}>
        {emails.map((item) => (
          <div className="tag-item" key={item}>
            {item}
            <button
              type="button"
              className="button"
              onClick={() => handleDelete(item)}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmailList;