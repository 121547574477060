// React
import React from 'react';
import PropTypes from 'prop-types';

// Moment (Date formatting)
import moment from 'moment';

// MUI Icons - Timeline Dot
import TextMemoryIcon from '@material-ui/icons/Subject';
import PhotoMemoryIcon from '@material-ui/icons/Photo';
import VideoMemoryIcon from '@material-ui/icons/Videocam';
import MixedMediaMemoryIcon from '@material-ui/icons/PermMedia';

// MUI Lab
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@material-ui/lab';

// Lazyload
import LazyLoad from 'react-lazyload';

// Memory Card
import { Typography } from '@material-ui/core';
import { MemoryCard } from './ContentCards/MemoryCard/MemoryCard';
// Group Memory Card
import { GroupMemoryCard } from './ContentCards/GroupMemoryCard/GroupMemoryCard';
// Prompt Card
import { PromptCard } from './ContentCards/PromptCard/PromptCard';
// Milestone Card
import { MilestoneCard } from './ContentCards/MilestoneCard/MilestoneCard';

// Styled Components
import { TimelineStyles } from './NewTimeline.style';

export function GroupTimeline({
  memories,
  customTheme,
  milestones,
  getMilestonesFunc,
  prompts,
  showPrompts,
  minimumTimelineLength,
  highlightedElement,
  profile = {},
  viewingFriendsMemory = false,
  isCompetition = false,
  editMilestoneFunc,
  noLogin = false,
  groupMemberStatus = '',
  userId = '',
  openEditMemoryFunc = () => {},
  ...props
}) {
  const renderMemory = (memory, i) => {
    // Determine Date Display
    const renderDate = () => {
      const { dateType, date } = memory;
      const ddmmyyyy = moment(date).format('DD MMM YYYY');
      const yyyy = moment(date).format('YYYY');

      return dateType === 'full' ? ddmmyyyy : ddmmyyyy;
    };

    // Determine icon
    const renderIcon = () => {
      const { icon, tempImages, tempVideo, tempVoice, description } = memory;

      // Has none - render blank timeline dot
      return null;
    };

    // Determine whether the memory is highlighted
    // FIXME: CHANGED BOTH TO NO HIGHLIGHT UNTIL SCROLL FIX
    const highlight =
      highlightedElement === i + 1 ? 'no_highlight' : 'no_highlight';

    const formattedDate = renderDate();
    return (
      <LazyLoad height={200} offset={600} overflow once >
        <TimelineItem
          key={memory._id}
          className={`singleMemoryWrapper ${highlight}`}
        >
          <TimelineOppositeContent className="timelineDatesWrapper" />
          <TimelineSeparator className="timelineDotsAndConnectorsWrapper" style={{display: window.innerWidth < 600 ? "none" :'flex'}}>
            <TimelineDot
              style={{ backgroundColor: customTheme.palette.primary.main,  padding:'0.4rem' }}

            >
              {renderIcon()}
            </TimelineDot>
            <TimelineConnector className="timelineConnector"
              style={{ backgroundColor: customTheme.palette.primary.main }}
            />
          </TimelineSeparator>
          <TimelineContent className="timelineMemoryCardsWrapper">
            <GroupMemoryCard
              {...memory}
              customTheme={customTheme}
              profile={profile}
              viewingFriendsMemory={viewingFriendsMemory}
              formattedDate={formattedDate}
              isCompetition={isCompetition}
              noLogin={noLogin}
              groupMemberStatus={groupMemberStatus}
              openEditMemoryFunc={openEditMemoryFunc}
              userId={userId}
            />
          </TimelineContent>
        </TimelineItem>
      </LazyLoad>
    );
  };

  // Collect, organize, and render all timeline content
  const generateTimelineContent = () => {
    const memoriesToRender = memories?.map(el => ({ ...el, type: 'memory' }));

    // Collect all content to render
    let timelineContent = [];
    if (memoriesToRender) {
      timelineContent.push(...memoriesToRender);
    }

    // Sort by date - newest first
    // timelineContent = timelineContent.sort(
    //   (a, b) => Date.parse(b.date) - Date.parse(a.date),
    // );

    return timelineContent.map((content, i) => {
      switch (content.type) {
        case 'memory':
          return renderMemory(content, i);
        default:
          return null;
      }
    });
  };

  return (
    <TimelineStyles {...props}>
      <Timeline align="alternate" className="timeline">
        {generateTimelineContent()}
      </Timeline>
    </TimelineStyles>
  );
}

GroupTimeline.propTypes = {
  /**
   * Provide an array of memories
   */
  memories: PropTypes.arrayOf({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    date: PropTypes.string,
    dateAge: PropTypes.string,
    dateType: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf({
      _id: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    isDraft: PropTypes.bool,
    isPublic: PropTypes.bool,
    taggedEmails: PropTypes.array,
    tempImages: PropTypes.arrayOf({
      _id: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    user: PropTypes.string,
    video: PropTypes.string,
    voice: PropTypes.string,
  }),
  /**
   * Provide an array of milestones
   */
  milestones: PropTypes.arrayOf({
    _id: PropTypes.string,
    user: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    date: PropTypes.string,
  }),
  /**
   * Provide a function for re-fetching all milestones currently displayed
   */
  getMilestonesFunc: PropTypes.func,
  /**
   * Provide an array of memory prompts
   */
  prompts: PropTypes.arrayOf({
    _id: PropTypes.string,
    prompt: PropTypes.string,
    title: PropTypes.string,
    category: PropTypes.string,
  }),
  /**
   * Should memory prompts be displayed for this timeline?
   */
  showPrompts: PropTypes.bool,
  /**
   * If the sum of memories and milestones is lower than this number, the remainder will be populated with memory prompts.
   * `minimumTimelineLength - (memories.length + milestones.length)`
   */
  minimumTimelineLength: PropTypes.number,

  /**
   * Which element in the timeline should be highlighted as the current 'active' element?
   */
  highlightedElement: PropTypes.number,
};

GroupTimeline.defaultProps = {
  // Memories and Milestones
  memories: undefined,
  milestones: undefined,
  getMilestonesFunc: () => {},
  // Prompts
  prompts: undefined,
  showPrompts: true,
  minimumTimelineLength: 3,
  // Visual
  highlightedElement: undefined,
};
