// React
import React, { useState, useEffect } from "react";
import history from "../../../history";
import Swal from "sweetalert2";

import {
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Fade,
  Collapse,
  Zoom,
  Grid,
} from "@material-ui/core";

// Bottom nav
import Groups from "@material-ui/icons/People";
import Friends from "@material-ui/icons/EmojiPeople";
import Profile from "@material-ui/icons/Face";
import Stories from "@material-ui/icons/MenuBook";
import Camera from "@material-ui/icons/PhotoCamera"


import * as C from "../../../constants/constantUrls";

import { formatJSDate } from "../../../helpers";

import { Divider } from "../../../NewComponents/Divider/Divider";

import { users, memories } from "../../../api";

import {
  Date as DateInput,
  ImageWrapper,
  Title,
  VoiceOrDescription,
  AddEmailWrapper,
  ShareLinkWrapper,
  PrivacyWrapper,
} from "../../Common/Inputs";

import {
  CREATE_MEMORY_URL,
  SUCCESS_SAVE_OTHER_URL,
} from "../../../constants/constantUrls";

import { BackgroundWrapper, Main } from "./style";

import { createErrors, validate } from "../../../helpers/validation";

import ReactGA from "react-ga";

export default (props) => {
  // Steps
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({});
  const [focus, setFocus] = useState(false);
  const [voice, setVoice] = useState("");
  const [tempVoice, setTempVoice] = useState(null);
  const [video, setVideo] = useState("");
  const [tempVideo, setTempVideo] = useState(null);
  const [description, setDescription] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(new Date());
  const [dateType, setDateType] = useState("full");
  const [dateAge, setDateAge] = useState(0);
  const [imagesArrayRequest, setImagesArrayRequest] = useState([]);
  const [imagesArray, setImagesArray] = useState([]);
  const [tempImages, setTempImages] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState(null);
  const [showPopup, setShowPopup] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);


  const onFocusChange = (e, ref) => {
    if (e === false) {
      // onblur
      setFocus(false);
      return;
    }
    // onfocus
    setFocus(true);
    if (ref) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }, 250);
    }
  };

  const validateAndStoreMemory = async (options) => {
    try {
      // type can be saveDraft / saveShare
      // previewMode can be preview / previewExisting
      let voiceLink = "";
      let videoLink = "";

      // upload audio to firebase and get the name

      if (voice && typeof voice !== "string") {
        const {
          data: { generatedName },
        } = await memories.uploadAudio({
          voice,
          userId: userDetails.userId,
        });
        voiceLink = generatedName;
      }

      if (video && typeof video !== "string") {
        const {
          data: { generatedName },
        } = await memories.uploadVideo({
          video,
          userId: userDetails.userId,
        }, setProgress);
        videoLink = generatedName;
      } else if (typeof video === 'string') {
        videoLink = video;
      }
      // create object of inputs
      const rawData = {
        title,
        date,
        dateType,
        dateAge,
        images: imagesArrayRequest,
        // check if either voice or description is present
        voiceOrDescriptionIncluded:
          (voiceLink && voiceLink.length > 0) ||
          (description && description.length > 0) ||
          (voice && voice.length > 0) ||
          (voice && typeof voice === "object") ||
          (video && video.length > 0) ||
          (video && typeof video === "object"),
        voice: voiceLink.length > 0 ? voiceLink : voice,
        video: videoLink.length > 0 ? videoLink : video,
        description: description.length ? description : "",
        userId: userDetails.userId,
        uploaderName: name,
        uploaderEmail: email,
      };

      const validatedData = await validate("createMemoryForFriend", rawData);
      if (validatedData) {
        await createMemory(rawData);
      }
    } catch (err) {
      const submitErrors = createErrors(err);
      if (submitErrors.voiceOrDescriptionIncluded) {
        Swal.fire({
          title: "Please tell us what happened",
          timer: 3000,
          type: "warning",
        });
      } else if (submitErrors.date) {
        Swal.fire({
          title: "Please enter a valid date",
          timer: 3000,
          type: "warning",
        });
      } else if (submitErrors.title) {
        Swal.fire({
          title: "Please enter a title",
          timer: 3000,
          type: "warning",
        });
      } else if (submitErrors.uploaderName) {
        Swal.fire({
          title: "Please enter a name",
          timer: 3000,
          type: "warning",
        });
      } else if (submitErrors.uploaderEmail) {
        Swal.fire({
          title: "Please enter a valid email",
          timer: 3000,
          type: "warning",
        });
      }

      setErrors(submitErrors);
    }
  };

  const createMemory = async (memoryObj) => {
    // type can be saveShare / saveDraft
    const {
      title,
      date,
      dateType,
      dateAge,
      description,
      voice,
      video,
      images,
      voiceOrDescriptionIncluded,
      userId,
      uploaderName,
      uploaderEmail,
    } = memoryObj;
    // store new memory
    const { data, error } = await memories.createForFriend({
      memory: {
        title,
        date: formatJSDate(date),
        dateType,
        dateAge,
        voice,
        video,
        description,
        images,
        voiceOrDescriptionIncluded,
        userId,
        author: { name: uploaderName, email: uploaderEmail },
      },
    });

    if (!error) {
      ReactGA.event({
        category: "Memory",
        action: "Created Memory On Other Timeline",
      });
      history.push(SUCCESS_SAVE_OTHER_URL, {
        ...data,
        shortId: props.match.params.shortId,
        userName: userDetails.name,
      });
    }
  };

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await users.getNameAndIdFromLinkToken({
        shortId: props.match.params.shortId,
      });
      if (props.isLoggedIn) {
        if (props?.id === data.userId) {
          history.push(CREATE_MEMORY_URL, { refreshNeeded: true });
        }
        setName(props.name);
        setEmail(props.email);
      }
      setUserDetails(data);
      if (props.location.state?.dontShowPopup) {
        setShowPopup(false);
      } else {
        if (props?.id !== data.userId) {
          Swal.fire({
            title: "Hey!",
            text: `${data.name} has invited you to leave a story on their timeline! Make sure to leave your favourite memory of them and help them be remembered forever.`,
          });
        }
      }
      setLoading(false);
    };
    getUserDetails();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return loading ? (
    <Main>
      <Paper></Paper>
    </Main>
  ) : (
    <BackgroundWrapper>
      <Main>
        <div style={{ width: "100%", flexDirection: "column" }}>
          <Typography
            align="center"
            variant="h4"
            gutterBottom
            style={{
              marginBottom: "2rem",
              color: "white",
              fontSize: window.innerWidth < 1024 ? "3rem" : "4rem",
            }}
          >
            Create a memory for {userDetails.name}
          </Typography>
          <Paper
            elevation={3}
            style={{
              padding: "2rem",
              margin: "2rem auto 0",
              maxWidth: "800px",
              width: "auto",
              marginBottom: window.innerWidth < 1024 ? "8rem" : "2rem",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              color="primary"
              style={{ fontWeight: "bold", marginBottom: "1rem" }}
            >
              What happened? *
            </Typography>
            <Typography style={{ marginBottom: "2rem" }}>
              You can tell the story in three ways, by writing it down, filming
              it or recording a voice note. Click the button that best suits
              your tale.
            </Typography>
            <VoiceOrDescription
              onFocusChange={onFocusChange}
              voice={voice}
              tempVoice={tempVoice}
              setVoice={setVoice}
              video={video}
              tempVideo={tempVideo}
              setVideo={setVideo}
              description={description}
              setDescription={setDescription}
              setError={setError}
              setIsRecording={setIsRecording}
              isRecording={isRecording}
              setTitle={setTitle}
              title={title}
              category={category}
              error={error}
              createForOther={true}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
            />
            {errors?.voiceOrDescriptionIncluded && (
              <Typography
                style={{
                  color: "var(--secondary-cherry)",
                  textAlign: "center",
                }}
              >
                * Please tell us what happened
              </Typography>
            )}

            <Divider logo />
            <Typography
              color="primary"
              variant="h5"
              gutterBottom
              style={{ fontWeight: "bold", marginBottom: "1rem" }}
            >
              Add some pics
            </Typography>
            <Typography style={{ marginBottom: "2rem" }}>
              A picture is worth a thousand words so enhance your memory by
              uploading some photos and never forget the moment.
            </Typography>
            <ImageWrapper
              onFocusChange={onFocusChange}
              showIcon
              imagesArray={imagesArray}
              setImagesArray={setImagesArray}
              setImagesArrayRequest={setImagesArrayRequest}
              imagesArrayRequest={imagesArrayRequest}
              tempImages={tempImages}
              setTempImages={setTempImages}
              error={error}
              createForOther={true}
              uploaderId={userDetails.userId}
            />
            <Divider logo />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  color="primary"
                  variant="h5"
                  gutterBottom
                  style={{ fontWeight: "bold", marginBottom: "1rem" }}
                >
                  Give it a title *
                </Typography>
                <Title
                  onFocusChange={onFocusChange}
                  title={title}
                  setTitle={setTitle}
                  setError={setError}
                  // error={error}
                />
                {errors?.title && (
                  <Typography
                    style={{
                      color: "var(--secondary-cherry)",
                      textAlign: "center",
                    }}
                  >
                    * Please enter a title
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h5"
                  color="primary"
                  gutterBottom
                  style={{ fontWeight: "bold", marginBottom: "1rem" }}
                >
                  When did it happen? *
                </Typography>
                <DateInput
                  onFocusChange={onFocusChange}
                  date={date}
                  setDate={setDate}
                  setError={setError}
                  dateType={dateType}
                  setDateType={setDateType}
                  dateAge={dateAge}
                  setDateAge={setDateAge}
                  error={error}
                  noDate
                />
                {errors?.date && (
                  <Typography
                    style={{
                      color: "var(--secondary-cherry)",
                      textAlign: "center",
                    }}
                  >
                    * Please enter a valid date
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Divider logo />
            <Typography
              color="primary"
              variant="h5"
              gutterBottom
              style={{ fontWeight: "bold", marginBottom: "1rem" }}
            >
              Your details *
            </Typography>
            <Typography style={{ marginBottom: "2rem" }}>
              We need this so that {userDetails.name} can see who wrote this
              amazing memory.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" gutterBottom>
                  Your full name
                </Typography>
                <TextField
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  variant="outlined"
                  label="Your full name"
                  placeholder="Write your name here..."
                />
                {errors?.uploaderName && (
                  <Typography
                    style={{
                      color: "var(--secondary-cherry)",
                      textAlign: "center",
                    }}
                  >
                    * Please enter a name
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" gutterBottom>
                  Your email address
                </Typography>
                <TextField
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  label="Your email address"
                  placeholder="Write your email here..."
                />
                {errors?.uploaderEmail && (
                  <Typography
                    style={{
                      color: "var(--secondary-cherry)",
                      textAlign: "center",
                    }}
                  >
                    * Please enter a valid email
                  </Typography>
                )}
              </Grid>
            </Grid>
            <div style={{ marginTop: "2rem", textAlign: "center" }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => validateAndStoreMemory()}
                style={{
          marginTop: '1rem',
          borderRadius: '100px',
          Width: '164px',
          minHeight: '50px',
          fontWeight:"700",
          boxShadow: 'rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px',}}
              >
                Create Story
              </Button>
            </div>
          </Paper>
        </div>
      </Main>
    </BackgroundWrapper>
  );
};
