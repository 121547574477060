import styled from 'styled-components';

export const TimelineStyles = styled.div`
  margin: 0.5rem auto !important;
  text-align: left;

  .timeline {
    margin: 0 auto !important;
    padding: 0 !important;
  }

  .timelineMemoryCardsWrapper {
    width: 70%;
    padding: 0.25rem 0.5rem !important;
  }

  .singleMemoryWrapper {
    padding: 0.5rem 0.35rem;
    margin: 0.1rem 0rem;
  }

  .timelineDatesWrapper {
    max-width: 0rem;
    padding: 0.75rem 0.25rem 0rem 0rem !important;
    // p {
    //   font-size: 0.9rem !important;
    //   text-align: right !important;
    // }
  }

  .timelineDotsAndConnectorsWrapper {
    max-width: 1.5rem;
    // padding: 0rem 0.25rem !important;
    margin-right: 1rem;
    svg {
      width: 0.85rem;
      height: 0.85rem;
    }
  }

  .timelineConnector {
    background-color: white;
    opacity: 0.4;
  }

  /* Highlight Active Memory */
  .highlight {
    opacity: 1 !important;
    transition: 0.1s all ease;
    border-radius: 0.5rem;
    padding: 0.75rem 0.35rem;
    margin: 0.5rem 0rem;
    color: hsla(178, 88%, 36%, 0.8) !important;
    background: hsla(178, 88%, 36%, 0.1) !important;
  }

  .no_highlight {
    transition: 0.1s all ease;
    background: hsla(178, 88%, 36%, 0) !important;
  }
`;
