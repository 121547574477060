import React from "react";

const ZigzagVertical = ({ width, height, color }) => (
  <svg
    width={width || "12"}
    height={height || "47"}
    viewBox="0 0 12 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="2.97066"
      y1="1.29289"
      x2="10.6338"
      y2="8.95605"
      stroke={color || "#66CC66"}
      stroke-width="2"
    />
    <line
      x1="1.81963"
      y1="16.3564"
      x2="9.48318"
      y2="8.69282"
      stroke={color || "#66CC66"}
      stroke-width="2"
    />
    <line
      x1="2.70711"
      y1="16.0722"
      x2="10.3703"
      y2="23.7353"
      stroke={color || "#66CC66"}
      stroke-width="2"
    />
    <line
      x1="1.55608"
      y1="31.1347"
      x2="9.21963"
      y2="23.4711"
      stroke={color || "#66CC66"}
      stroke-width="2"
    />
    <line
      x1="2.70711"
      y1="30.8505"
      x2="10.3703"
      y2="38.5137"
      stroke={color || "#66CC66"}
      stroke-width="2"
    />
    <line
      x1="1.55608"
      y1="45.914"
      x2="9.21963"
      y2="38.2504"
      stroke={color || "#66CC66"}
      stroke-width="2"
    />
  </svg>
);

export default ZigzagVertical;
