import React, { useState, useEffect } from "react";

// MUI Icons
import MemoryIcon from "@material-ui/icons/Book";
import Plus from "@material-ui/icons/Add";
import MilestoneIcon from "@material-ui/icons/Star";
import Photos from "@material-ui/icons/PhotoCamera";
import Groups from "@material-ui/icons/People";
import Friends from "@material-ui/icons/EmojiPeople";
import Profile from "@material-ui/icons/Face";
import Stories from "@material-ui/icons/MenuBook";
import Camera from "@material-ui/icons/PhotoCamera";
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/CancelOutlined";

// import Modal from '../../Common/FixedModal/index';
import * as C from "../../../constants/constantUrls";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InformationPopUp from "../../Common/FriendsModals/InformationModal"
import SignUpModal from "../../Common/FriendsModals/SignUpModal"
import LoginModal from "../../Common/FriendsModals/LoginModal"
import AcceptModal from "../../Common/FriendsModals/AcceptRequestModal"

// MUI
import {
  Grid,
  Typography,
  Paper,
  AppBar,
  Tabs,
  Tab,
  Divider,
  Avatar,
  Modal,
  Backdrop,
  TextField,
  Button,
} from "@material-ui/core";
import CallToActionButton from "../../Common/GroupComponents/Buttons/CallToActionButton";
// Legacy Components
import FriendsList from "../../Common/Tables/FriendsList";
import LoadingAnimation from "../../Common/Animations/Loading";
import { InviteFriends } from "../../../NewComponents/Dashboard/Blocks/InviteFriends/InviteFriends";

import { LoadWrapper } from "./style";
import * as S from "../Chat/style";

// API
import { permissionAccess, users, linkTokens } from "../../../api";
import ReactGA from "react-ga";

import { FRIENDS_AND_FAMILY_URL } from "../../../constants/constantUrls";
import Swal from "sweetalert2";
import * as yup from "yup";
import { FriendsList as GivenFriendsList } from "../../../NewComponents/Pages/FriendsAndFamily/FriendsList/FriendsList";

import SocialShare from "../../Common/SocialShare";
import { FeaturesBlockStyles } from "../../Common/Inputs/style";
import FriendModal from "../../Common/FriendModal";

const emailSchema = yup
  .array(
    yup
      .string()
      .email("please enter valid email")
      .required("required")
  )
  .max(5, "Sorry, you can only add up to 5 emails at a time");

const handleChange = (e) => {
  this.setState({ emailInput: e.target.value });
};

const handleClick = async () => {
  if (this.state.emailInput && this.state.emailInput.length > 0) {
    const splitEmail = this.splitEmailsList(this.state.emailInput);
    emailSchema
      .validate(splitEmail)
      .then(async () => {
        let duplicateEmail = false;
        splitEmail.map((email) => {
          duplicateEmail = this.state.permissionAccessEmails.includes(email);
          return email;
        });
        const ownEmail = splitEmail.includes(this.props.email);
        if (ownEmail) {
          return Swal.fire({
            title: "You can't add yourself!",
            timer: 3000,
            type: "warning",
          });
        }
        if (!duplicateEmail) {
          const { error } = await permissionAccess.create({
            sharedWithEmails: splitEmail,
          });
          if (!error) {
            this.fetchPermissionAccess();
            document.getElementById("emailInput").value = "";
            return Swal.fire({
              title: "Email added",
              timer: 3000,
              type: "success",
            });
          }
        } else {
          return Swal.fire({
            title: "You have already added this email",
            timer: 3000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        return Swal.fire({
          title: "Please enter a valid email address",
          timer: 3000,
          type: "error",
        });
      });
  } else {
    return Swal.fire({
      title: "Please enter an email address first",
      timer: 3000,
      type: "error",
    });
  }
};

const MyFriendsMemories = ({ match, history, name }) => {
  const { shortId } = match.params;
  const [friendsList, setFriendsList] = useState([]);
  const [friendsListNotGiven, setFriendsListNotGiven] = useState([]);
  const [givenFriendsList, setGivenFriendsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tabsValue, setTabsValue] = useState(0);
  const [showShareModal, setShowShareModal] = useState(false);
  const [inviteLink, setInviteLink] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [showInformationPopUp, setShowInformationPopUp] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showAcceptModal, setAcceptModal] = useState(false);

  const loadData = async () => {
    await getFriendMemories();
    await getGivenFriendsList();
    await fetchLinkToken();
    setIsLoading(false);
  };

  const fetchLinkToken = async () => {
    const {
      data,
      error,
    } = await linkTokens.getActiveLinkTokenForLoggedInUser();
    if (!error) {
      setInviteLink(`${window.location.origin}/sign-up/${data.shortId}`);
    }
  };

  const getFriendMemories = async () => {
    const { data, error } = await permissionAccess.getAllTakenPermissionAccess({
      options: { redirectToRelatedPage: true },
    });

    if (!error) {
      setFriendsList(data);
    }
  };

  const getGivenFriendsList = async () => {
    const { data, error } = await permissionAccess.getAllGivenPermissionAccess({
      options: { redirectToRelatedPage: true },
    });
    if (!error) {
      await Promise.all(
        data.map(async (d) => {
          if (d.sharedWithId) {
            const { data, error } = await users.getUserNameById({
              id: d.sharedWithId,
            });
            if (error) {
              console.log(error);
            }
            d.name = data?.name;
            d.image = d?.abouts[0]?.image?.imagePath;
          }
        })
      );
      setGivenFriendsList(data);
    }
  };

  const deletePermissionAccess = async ({ id, user }) => {
    const { data, error } = await permissionAccess.deletePermissionAccess({
      id,
      user,
    });

    if (data && !error) {
      await loadData();
      ReactGA.event({
        category: "Friends",
        action: "Deleted Friend",
      });
      return Swal.fire({
        title: "Email removed!",
        timer: 3000,
        type: "success",
      });
    }
  };

  const splitEmailsList = (emailsAsString) => {
    // split on "," & ";" and " "
    const splittedEmails = emailsAsString.split(/[, ;]/g);
    return splittedEmails.filter((item) => !!item).map((item) => item.trim());
  };

  const addNewEmail = async (email) => {
    // check if email is already in list of emails
    const sharedWith = givenFriendsList.map((e) => e.sharedWithEmail);
    const emailExists = sharedWith.includes(email);

    if (emailExists) {
      return Swal.fire({
        title: "This email has already access to your memories",
        text: "Please choose a different one",
        type: "warning",
        confirmButtonColor: "#66CC66",
        cancelButtonColor: "#8a8787",
        confirmButtonText: "Ok",
      });
    }
    if (!emailExists) {
      const { error } = await permissionAccess.create({
        sharedWithEmails: splitEmailsList(email),
      });

      if (!error) {
        await loadData();
        setNewEmail("");
        ReactGA.event({
          category: "Friends",
          action: "Added Friend",
        });
        return Swal.fire({
          // this Email || Emails
          title: "Email added",
          timer: 3000,
          type: "success",
        });
      }
    }
  };

  const switchTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  useEffect(() => {
    loadData();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [shortId, history]);

  if (isLoading) {
    return (
      <LoadWrapper>
        <LoadingAnimation
          width={window.innerWidth < 600 ? window.innerWidth * 0.9 : 500}
          height={window.innerWidth < 600 ? window.innderWidth * 0.9 : 500}
        />
      </LoadWrapper>
    );
  } else {
    return (
      <>

      <Modal
       open={showInformationPopUp}
          onClose={() => setShowInformationPopUp(false)}
         
      >
        <InformationPopUp           onClose={() => setShowInformationPopUp(false)}
text="Your Profile has been shared"/>
      </Modal>


      <Modal
       open={showSignupModal}
          onClose={() => setShowSignupModal(false)}
         
      >
        <SignUpModal username="Becki's"/>

      </Modal>

      <Modal
       open={showLoginModal}
          onClose={() => setShowLoginModal(false)}
         
      >
        <LoginModal username="Becki's"/>

      </Modal>


      <Modal
       open={showAcceptModal}
          onClose={() => setAcceptModal(false)}
         
      >
        <AcceptModal username="Becki's"/>

      </Modal>
      <Modal
        open={showShareModal}
        onClose={() => setShowShareModal(false)}
      >
        <FriendModal 
          name={name}
          onClose={() => setShowShareModal(false)}
        />
      </Modal>

        <Modal
          open={false}
          onClose={() => setShowShareModal(false)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1000",
            borderRadius: "2rem",
          }}
          // hideBackground
        >
          <Paper style={{ maxWidth: "800px", width: "90%", padding: "2rem" }}>
            <div className="addFriendsSection">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Close
                  color="primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowShareModal(false)}
                />
              </div>
              <Typography
                variant="h4"
                gutterBottom
                color="primary"
                style={{ textAlign: "left", marginBottom: "1.5rem" }}
              >
                Invite Friends
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={8}>
                  <TextField
                    fullWidth
                    label="Add friends' emails"
                    placeholder="friend@email.com"
                    helperText={
                      inputFocused
                        ? "Tip: Add multiple friends by seperating their emails with a comma"
                        : " "
                    }
                    variant="outlined"
                    className="addFriendInputField"
                    value={newEmail}
                    onFocus={() => setInputFocused(true)}
                    onBlur={() => setInputFocused(false)}
                    onChange={(e) => setNewEmail(e.target.value)}
                  >
                    Enter a name
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    className="addFriendButton"
                    disabled={false}
                    onClick={() => addNewEmail(newEmail)}
                    style={{
                      minHeight: "50px",
                      fontWeight: "700",
                      boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                    }}
                  >
                    Add friend
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" style={{ marginBottom: "1rem" }}>
                    - OR -
                  </Typography>
                  <Typography>
                    Click the button below to copy your unique sign-up link to
                    share with your loved ones, or share through social media
                    platforms
                  </Typography>
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {navigator.share ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          if (navigator.share) {
                            navigator.share({
                              title: "Iternal",
                              text: "Invite loved ones to Iternal",
                              url: inviteLink,
                            });
                          }
                        }}
                        style={{
                          marginTop: "2rem",
                          borderRadius: "100px",
                          minWidth: "164px",
                          minHeight: "50px",
                          fontWeight: "700",
                          boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                        }}
                      >
                        Click to Copy
                      </Button>
                    ) : (
                      <CopyToClipboard text={inviteLink}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            Swal.fire(
                              "Done!",
                              "The link has been copied",
                              "success"
                            )
                          }
                          style={{
                            marginTop: "2rem",
                            borderRadius: "100px",
                            minWidth: "164px",
                            minHeight: "50px",
                            fontWeight: "700",
                            boxShadow:
                              "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                          }}
                        >
                          Click to Copy
                        </Button>
                      </CopyToClipboard>
                    )}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <SocialShare
                        link={inviteLink}
                        messageBody={
                          "Hey! I'd like to share my memories with you! To sign up to Iternal, click this link"
                        }
                        messageTitle={"I'd like to share my memories with you"}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setShowShareModal(false)}
                      style={{
                        margin: "1rem auto 0",
                        borderRadius: "100px",
                        minWidth: "164px",
                        minHeight: "50px",
                        fontWeight: "700",
                        boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                      }}
                    >
                      Close
                    </Button> */}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Modal>

        <S.Wrapper
          style={{ marginLeft: window.innerWidth < 1024 ? "0rem" : "2rem" }}
        >
        
          <main
          // style={{
          //   display: 'block',
          //   maxWidth: '100rem !important',
          //   padding: '1rem 0.5rem',
          //   marginLeft: window.innerWidth >= 1023 ? '260px' : '0px',

          // }}
          >
            <Typography
              align="center"
              variant="h3"
              gutterBottom
              style={{
                marginBottom: "2rem",
                color: "white",
                fontSize: window.innerWidth < 1024 ? "3rem" : "4rem",
              }}
            >
              Your Friends
            </Typography>

            <Grid
              container
              spacing={3}
              style={{
                display: "flex",
                flexDirection: window.innerWidth < 1024 ? "column" : "row",
                // paddingLeft: window.innerWidth < 1024 ? "2rem" : "0rem",
                // paddingRight: window.innerWidth < 1024 ? "2rem" : "0rem",
              }}
            >
              {/* <Grid item xs={12} lg={4} xl={3}>
                <Paper
                  elevation={3}
                  style={{
                    margin: "0 auto",
                    textAlign: "center",
                    borderRadius: "20px",
                    // backgroundColor: 'white',
                    minHeight: window.innerWidth < 1280 ? "10vh" : "40vh",
                    padding: window.innerWidth < 400 ? "1rem" : "2rem",
                  }}
                >
                  <Typography
                    align="left"
                    variant="h4"
                    color="primary"
                    gutterBottom
                    style={{ marginBottom: "2rem",  }}
                  >
                    Invite Friends
                  </Typography>

                  <Grid item xs={12}>
                    <InviteFriends
                      id="invite-friends"
                      history={history}
                      shareMemoriesLink={FRIENDS_AND_FAMILY_URL}
                      handleChange={handleChange}
                      handleClick={handleClick}
                      style={{}}
                    />
                    <Typography align="left" variant="body1" style={{marginBottom:'1rem'}} >
                    Share your public stories with your favourite people by inviting them to be your friend. 
                
                  </Typography>

                  <CallToActionButton  clickFunction={() => history.push(C.FRIENDS_AND_FAMILY_URL)} variant="contained" title="Invite Friends" />                
                  </Grid>
                </Paper>
              </Grid> */}
              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  style={{
                    maxWidth: "1000px",
                    margin: "0 auto",
                    textAlign: "center",
                    borderRadius: "20px",
                    backgroundColor: "none",

                    minHeight: window.innerWidth < 1024 ? "70vh" : "75vh",
                    padding: window.innerWidth < 400 ? "1rem" : "2rem",
                    marginBottom: window.innerWidth < 1023 ? "6rem" : "0rem",
                  }}
                >
                  <Typography align="left" variant="body1" style={{}}>
                    This is where you can see all the people that have shared
                    their stories with you and invite friends to see the stories you choose to share. 
                    </Typography>
                    <br></br>
                    <Typography
                      align="left"
                      variant="body1"
                      style={{
                        display: window.innerWidth < 700 ? "none" : "block",
                      }}
                    >
                      Why not invite a few close friends and family today and
                      see what stories they have to share?
                  
                  </Typography>
                  <CallToActionButton
                    title="Invite"
                    clickFunction={() => setShowShareModal(true)}
                    style={{ marginBottom: "1rem", marginTop: window.innerWidth < 700 ? "-2rem" : "0rem"}}
                    startIcon={
                      <Add
                        style={{
                          fontSize: "1.5rem",
                          marginRight: "-0.3rem",
                        }}
                      />
                    }
                  />
                  <AppBar
                    color="default"
                    position="static"
                    style={{ marginTop: "2rem" }}
                  >
                    <Tabs
                      value={tabsValue}
                      variant="fullWidth"
                      centered
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={switchTabs}
                    >
                      <Tab
                        label="People whose stories you can see"
                        style={{
                          fontSize:
                            window.innerWidth < 1200 ? "1rem" : "1.2rem",
                          fontWeight: "bold",
                        }}
                      />
                      <Tab
                        label="People who can see your stories"
                        style={{
                          fontSize:
                          window.innerWidth < 1200 ? "1rem" : "1.2rem",
                          fontWeight: "bold",
                        }}
                      />
                    </Tabs>
                  </AppBar>
                  {tabsValue === 0 && (
                    <>
                      {/* <Typography
                        align="left" 
                        variant="body1"
                        style={{ margin: '2rem 0' }}
                      >
                        You can see these friends stories. Click to see what their latest story is.
                      </Typography> */}
                      <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                        <Grid item xs={12}>
                          {/* <Typography
                            align='left'
                            variant='body1'
                            style={{ fontSize: window.innerWidth < 760 ? '0.85rem' : '1.1rem' }}
                          >
                            You can see each other's stories. Click to see what their latest story is.
                          </Typography> */}
                          <Grid
                            container
                            spacing={2}
                            style={{
                              marginTop: "1rem",
                              maxHeight: "55vh",
                              overflowY: "scroll",
                            }}
                          >
                            <FriendsList
                              viewingFriendsMemory
                              friends={friendsList}
                              givenFriendsList={givenFriendsList}
                              setGivenFriendsList={setGivenFriendsList}
                              followList={false}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {tabsValue === 1 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: "1rem",
                        marginLeft: "0rem",
                        marginRight: "0rem",
                      }}
                    >
                      <Typography
                        align="center"
                        variant="body1"
                        style={{
                          width: "100%",
                          fontSize:
                            window.innerWidth < 760 ? "1rem" : "1.1rem",
                        
                        }}
                      >
                        These are all the people that you have shared your
                        stories with. 
                        </Typography>
                        <Typography
                        align="center"
                        variant="body1"
                        style={{
                          width: "100%",
                          fontSize:
                            window.innerWidth < 760 ? "1rem" : "1.1rem",
                            display:   
                            window.innerHeight < 700 ? "none" : "block",
                        }}
                      >
                      <br></br>
                        They will be able to view your shared
                        memories until you remove them from this list.
                      </Typography>
                      <GivenFriendsList
                        permissionAccessArr={givenFriendsList}
                        deleteFriendFunc={deletePermissionAccess}
                      />
                    </div>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </main>
        </S.Wrapper>
      </>
    );
  }
};

export default MyFriendsMemories;
