import React, { Component } from 'react';
import { Router } from 'react-router-dom';

// CSS
import './styles/reset.css';
import './styles/app.scss';
import './NewComponents/--Typography/Fonts.css';
import './NewComponents/--Color-and-Gradients/Color.css';

// Material Theme Provider
import {
  createMuiTheme,
  ThemeProvider as MaterialThemeProvider,
} from '@material-ui/core/styles';

// React
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ReactGA from 'react-ga';
import { IternalTheme } from './NewComponents/--Theme/IternalThemeLight';
import { UserProvider } from './context/user';
import history from './history';
import Routes from './Components';
import initialState from './constants/initialState';
// API
import { users } from './api';

// Loader
import { Loading } from './NewComponents/Loading/Loading';

// Stripe
const stripePromise = loadStripe('pk_live_I6TEPerNpJ3kfLb4Mt78L30Z00dUbSkMXq');

// MUI
const theme = createMuiTheme(IternalTheme);

class App extends Component {
  state = {
    ...initialState,
  };

  componentDidMount() {
    this.getUserInfo();

    // FUNC to track the screen width
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  // updates the initial state - mainly for user authentication
  handleChangeState = data => {
    this.setState({ ...data, isMounted: true });
  };

  updateWindowDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  getUserInfo = async () => {
    const { data = initialState, error } = await users.getUserInfoFromToken({
      options: { showModal: false },
    });
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID, {
        gaOptions: {
          userId: data?.id ? data?.id : '',
        },
      });
    }
    this.setState({
      ...data,
      isMounted: true,
      windowWidth: window.innerWidth,
      error,
    });
  };

  render() {
    const { isLoggedIn, isMounted } = this.state;

    if (!isMounted)
      return (
        <MaterialThemeProvider theme={theme}>
          <Loading />
        </MaterialThemeProvider>
      );
    return (
      <Elements stripe={stripePromise}>
        <Router history={history}>
          <>
            <MaterialThemeProvider theme={theme}>
              <UserProvider
                value={{ ...this.state, update: this.handleChangeState }}
              >
                <Routes
                  handleChangeState={this.handleChangeState}
                  isLoggedIn={isLoggedIn}
                  {...this.state}
                />
              </UserProvider>
            </MaterialThemeProvider>
          </>
        </Router>
      </Elements>
    );
  }
}

export default App;
