import styled from 'styled-components';

export const ConversationView = styled.div`
  width: 100%;
  /* min-height: 80vh; */
  /* padding: 20px 40px; */
  display: flex;
  flex-direction: column;
`;

export const MessageBox = styled.div`
  width: 100%;
  /* padding: 20px 20px; */
  /* display: block; */
`;

export const Form = styled.form``;

export const FormInput = styled.input`
  width: 100%;
  border: 1px solid #925de5;
  font-size: 1rem;
  border-radius: 1rem;
  padding: 0.5rem;
  outline: none;
  padding-left: 1rem;
`;

export const BottomDiv = styled.div`
  float: left;
  clear: both;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
