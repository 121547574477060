import React, { useRef, useState, useEffect } from "react";

import { Grid, CircularProgress, Typography } from "@material-ui/core";

import DrawTypes from "./DrawTypes";
import useDragging from "./useDragging";
import ImageAdd from "./ImageAdd";
import folder from "../../../assets/folder.png";

import { acceptedExt, checkType, getFileSizeMB } from "./utils";
import {
  UploaderWrapper,
  DescriptionWrapper,
  Description,
  HoverMsg,
} from "./style";

const drawDescription = (currFile, uploaded, typeError, disabled, label, isUploading) => {
  console.log(isUploading)
  return typeError ? (
    <span>File type/size error, Hovered on types!</span>
  ) : (
    <Description>
      {disabled ? (
        <span>Upload disabled</span>
      ) : !currFile && !uploaded || isUploading ? (
        <>
          {label ? (
            <>
              <span>{label.split(" ")[0]}</span>{" "}
              {label.substr(label.indexOf(" ") + 1)}
            </>
          ) : (
            <>
              Drag your photo here to start uploading <br></br>
              <br></br>- OR - <br></br> <br></br>
              <span>Click to Upload </span>
              <br></br> <br></br>

              <br/><br/>
            </>
          )}
        </>
      ) : (
        <>
          <span>Uploaded Successfully!.</span> Upload another?
        </>
      )}
    </Description>
  );
};

/**
 * File uploading main function
 * @param props - {name,
    hoverTitle,
    types,
    handleChange,
    classes,
    children,
    maxSize,
    minSize,
    fileOrFiles,
    onSizeError,
    onTypeError,
    onSelect,
    onDrop,
    onTypeError,
    disabled,
    label,
    multiple,
    onDraggingStateChange
  }
 * @returns JSX Element
 */
const FileUploader = (props) => {
  const {
    name,
    hoverTitle,
    types,
    handleChange,
    classes,
    children,
    maxSize,
    minSize,
    fileOrFiles,
    onSizeError,
    onTypeError,
    onSelect,
    onDrop,
    disabled,
    label,
    multiple,
    isUploading=false,
    onDraggingStateChange,
  } = props;
  const labelRef = useRef(null);
  const inputRef = useRef(null);
  const [uploaded, setUploaded] = useState(false);
  const [currFiles, setFile] = useState(null);
  const [error, setError] = useState(false);
  const validateFile = (file) => {
    if (types && !checkType(file, types)) {
      // types included and type not in them
      setError(true);
      console.log("File type is not supported");
      if (onTypeError) onTypeError("File type is not supported");
      return false;
    }
    if (maxSize && getFileSizeMB(file.size) > maxSize) {
      setError(true);
      console.log("File size is too big");
      if (onSizeError) onSizeError("File size is too big");
      return false;
    }
    if (minSize && getFileSizeMB(file.size) < minSize) {
      setError(true);
      console.log("File size is too small");
      if (onSizeError) onSizeError("File size is too small");
      return false;
    }
    return true;
  };

  const handleChanges = (files) => {
    let checkError = false;
    if (files) {
      if (files instanceof File) {
        checkError = !validateFile(files);
      } else {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          checkError = !validateFile(file) || checkError;
        }
      }
      if (checkError) return false;
      if (handleChange) handleChange(files);
      setFile(files);

      setUploaded(true);
      setError(false);
      return true;
    }
    return false;
  };
  const handleInputChange = (ev) => {
    const allFiles = ev.target.files;
    const files = multiple ? allFiles : allFiles[0];
    const success = handleChanges(files);
    if (onSelect && success) onSelect(files);
  };
  const dragging = useDragging({
    labelRef,
    inputRef,
    multiple,
    handleChanges,
    onDrop,
  });

  // useEffect(() => {
  //   onDraggingStateChange?.(dragging);
  // }, [dragging]);

  useEffect(() => {
    if (fileOrFiles) {
      setUploaded(true);
      setFile(fileOrFiles);
    } else {
      setUploaded(false);
      setFile(null);
    }
  }, [fileOrFiles]);

  return (
    <UploaderWrapper
      overRide={children}
      className={`${classes || ""} ${disabled ? "is-disabled" : ""}`}
      ref={labelRef}
      htmlFor={name}
    >
      <input
        onChange={handleInputChange}
        accept={acceptedExt(types)}
        ref={inputRef}
        type="file"
        name={name}
        disabled={disabled}
        multiple={multiple}
      />
      {dragging && (
        <HoverMsg>
          <span>{hoverTitle || "Drop Here"}</span>
        </HoverMsg>
      )}
      {!children && (
        <>
          {/* <ImageAdd /> */}
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: window.innerWidth < 900 ? "column" : "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item>
              <img src={folder} style={{marginTop:  window.innerWidth < 993 ? "1rem" : "-1rem"}}></img>
            </Grid>
            <Grid item style={{marginTop:  window.innerWidth < 993 ? "0rem" : "3rem"}}>
              <DescriptionWrapper error={error}>
                {drawDescription(currFiles, uploaded, error, disabled, label, isUploading)}
                {isUploading && (
                  <CircularProgress style={{ marginBottom: '1rem' }}/>
                )}
                {/* <DrawTypes types={types} minSize={minSize} maxSize={maxSize} /> */}
              </DescriptionWrapper>
            </Grid>
          </Grid>
        </>
      )}
      {children}
    </UploaderWrapper>
  );
};
export default FileUploader;
