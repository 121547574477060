import React from "react";
import { Button, Typography } from "@material-ui/core";
import CallToActionButton from "../CallToActionButton";

export default function CreateMemoryButton({
  clickFunction = () => {},
  Icon,
  text = "",
  theme,
}) {
  const convertHSLToHSLA = (value) => {
    const sep = value.indexOf(",") > -1 ? "," : "";
    const hsl = value
      .substr(4)
      .split(")")[0]
      .split(sep);

    let h = hsl[0],
      s = hsl[1],
      l = hsl[2];

    const hsla = `hsla(${h} ${s} ${l} / 0.12)`;
    return hsla;
  };

  return (
    <div
      onClick={() => clickFunction()}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "20%",
          height: "8rem",
          width: "8rem",
          backgroundColor: convertHSLToHSLA(theme.palette.primary.light),
          border: `solid 0.25rem ${theme.palette.primary.main}`,
          boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
          // backgroundBlendMode: 'lighten',
        }}
      >
        <div>
          <Icon
            fill={theme.palette.primary.dark}
            style={{
              color: "black",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              marginBottom: "0.5rem",
            }}
          />
          <div
            style={{
              textAlign: "center",
              color: `${theme.palette.primary.dark}`,
            }}
          >
            {text}
          </div>
        </div>
      </div>
    </div>
  );
}
