// React
import React from "react";
import PropTypes from "prop-types";

// MUI
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// Custom style
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { Fade } from "@material-ui/core";
import { IternalTheme } from "../--Theme/IternalThemeLight";

// Images
import food from "./images/v2/food.webp";
import goodOldDays from "./images/v2/goodOldDays.webp";
import family from "./images/v2/family.webp";
import holidays from "./images/v2/holidays.webp";
import love from "./images/v2/love.webp";
import hobbies from "./images/v2/hobbies.webp";
import milestones from "./images/v2/milestones.webp";
import createYourOwn from "./images/v2/createYourOwn.webp";

const images = {
  food,
  goodOldDays,
  family,
  holidays,
  love,
  hobbies,
  milestones,
  createYourOwn,
};

const theme = createMuiTheme(IternalTheme);

const useStyles = makeStyles({
  image: {
    position: "relative",
    height: 200,
    borderRadius: '7px',
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: '7px',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
    borderRadius: '7px',
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create("opacity"),
    borderRadius: '7px',
  },
  imageBackdropHighlighted: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.8,
    transition: theme.transitions.create("opacity"),
    borderRadius: '7px',
  },
  imageTitle: {
    marginTop: "-16.5px",
    fontSize: "1.3rem",
    lineHeight: "1",
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) +
      6}px`,
    textAlign: "center",
    textTransform: "capitalize",
  },
  linkContainer: {
    borderRadius: '7px',
    '&:hover': {
      '& $attributionLink': {
        display: 'block',
      }
    }
  },
  imageTitleNoText: {
    fontSize: "1.3rem",
    lineHeight: "1",
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) +
      6}px`,
    textAlign: "center",
    textTransform: "capitalize",
  },
  attributionLink: {
    bottom: 0, 
    left: 0,
    color: 'white', 
    textOverflow: 'ellipsis', 
    position: 'absolute',
    textAlign: 'left',
    boxSizing: 'border-box',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    display: 'block',
    background: '#0004',
    borderRadius: '0px 0px 7px 7px',
    "&:hover": {
      background: '#000B',
      color: 'white'
    },
    transition: theme.transitions.create("opacity"),
  },
});

export const ImageButton = ({
  text,
  image,
  userName,
  userLink,
  width,
  background,
  isHighlighted,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div {...props}>
      <ButtonBase
        focusRipple
        key={userName}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
        style={{
          width,
        }}
      >
        <span
          className={classes.imageSrc}
          style={{
            backgroundImage: `url(${image in images ? images[image] : image})`,
          }}
        />
        <span
          className={
            isHighlighted
              ? classes.imageBackdropHighlighted
              : classes.imageBackdrop
          }
        />
        <span className={classes.imageButton}>
          <Typography
            component="span"
            variant="body2"
            color="inherit"
            className={text?.length > 0 ? classes.imageTitle : classes.imageTitleNoText}
          >
            <Fade
              in={isHighlighted}
              style={{ display: "block", margin: "0 auto" }}
            >
              <CheckCircleIcon style={{ fontSize: "1.25rem" }} />
            </Fade>
              {text}
            <span className={classes.imageMarked} />
          </Typography>
        </span>
        {userName?.length > 0 && (
          <div
            className={classes.linkContainer}
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <a 
              href={userLink} 
              target='_blank'
              className={classes.attributionLink}
              style={{ 
                padding: '2px 14px'
              }}
            >
              {userName}
            </a>
          </div>
        )}
      </ButtonBase>
    </div>
  );
};

ImageButton.propTypes = {
  /**
   * What text should this Image Button contain?
   */
  text: PropTypes.string,
  /**
   * What is the width of this button as a percentage?
   */
  width: PropTypes.string,
  /**
   * What is the improted image for this Image Button?
   */
  image: PropTypes.string,
};

ImageButton.defaultProps = {
  text: "",
  width: "100%",
  image: null,
};
