// React
import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// MUI Core
import { Paper, Typography, Button, Chip } from "@material-ui/core";

// MUI Icons - Memory Prompt
import LightbulbIcon from "@material-ui/icons/EmojiObjects";

// MUI Lab
import TimelineContent from "@material-ui/lab/TimelineContent";

// Constants
import { CREATE_MEMORY_URL } from "../../../../../../constants/constantUrls";

// Styled Components
import { ContentCardStyles } from "../ContentCards";

export function PromptCard({
  _id,
  prompt,
  title,
  category,
  isHighlighted,
  ...props
}) {
  const renderPromptChip = () => {
    const milestoneChipProps = {
      label: "Here's an idea for you",
      icon: <LightbulbIcon />,
      color: "secondary",
      variant: "outlined",
    };
    return <Chip {...milestoneChipProps} className="promptChip" />;
  };

  return (
    <ContentCardStyles {...props}>
      <TimelineContent className="cardWrapper">
        <Paper elevation={isHighlighted ? 15 : 3} className="card promptCard">
          <div className="cardContentWrapper">
            {/* <>{renderPromptChip()}</> */}
            <Typography
              className="title promptTitle"
              variant="h5"
              component="h1"
              color="primary"
              style={{textTransform:'capitalize'}}
            >
              {title}
            </Typography>
            <Typography className="description">{prompt}</Typography>
            <div>
              <NavLink
                className="CTAButton"
                to={{
                  pathname: `${CREATE_MEMORY_URL}`,
                  search: `?prompt=${_id}`,
                  state: { refreshNeeded: true },
                }}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth
                  style={{
                    fontWeight: "bold",
                    height: "3rem",
                    fontSize: window.innerWidth < 430 ? "0.9rem" : "1.1rem",
                    borderRadius: "100px",
                    boxShadow: "rgba(0, 0, 0, 0.45) 3.95px 3.95px 4.6px",
                  }}
                >
                  Create this story &rarr;
                </Button>
              </NavLink>
            </div>
          </div>
        </Paper>
      </TimelineContent>
    </ContentCardStyles>
  );
}

PromptCard.propTypes = {
  /**
   * The unique ID of this memory prompt
   */
  _id: PropTypes.string,

  /**
   * The description/question for the prompt
   */
  prompt: PropTypes.string,

  /**
   * The title of the memory to be created from the prompt
   */
  title: PropTypes.string,

  /**
   * The category of the memory prompt or milestone
   */
  category: PropTypes.string,

  // Other props
  /**
   * Should this card be highlighted?
   */
  isHighlighted: PropTypes.bool,
};

PromptCard.defaultProps = {
  // Memory
  _id: undefined,
  prompt: undefined,
  title: undefined,
  category: undefined,
  isHighlighted: false,
};
