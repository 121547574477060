import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

export const init = () => {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY, {
    console: {
      isEnabled: true,
      shouldAggregateConsoleErrors: true,
    },
  });
  setupLogRocketReact(LogRocket);
};

export const identify = user =>
  LogRocket.identify(user.id, {
    name: user.name,
    email: user.email,
    isPremium: user.isPremium,
  });

export const error = (message, err) => {
  // eslint-disable-next-line no-console
  console.error(err);
  LogRocket.error(message, err);
};
