import React, { Component } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import robotImg from '../../../assets/i-logo-orange.png';
import {
  API_THISORTHAT_URL,
  API_FUTUREMESSAGE_URL,
  API_PROFILE_BASICS_URL,
  API_PROFILE_MEMORIES_URL,
} from '../../../constants/apiRoutes';

import { 
  BASICS_URL, 
  CREATE_MEMORY_URL,
  THISORTHAT_URL
} from '../../../constants/constantUrls';

import { Link } from 'react-router-dom';

import DialogFlowChat from '../DialogflowChat';
import Loading from '../Loading';

import * as S from './style';

// COMMON
import Icon from '../../Common/Icon/Icon';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

// placeholder for user image
import imgUser from '../../../assets/person-placeholder.jpg';

const initialState = {
  storing: false,
  memoryLoading: false,
  loadingMessage: false,
  index: 0,
  storedPostRequest: {},
  flow: 'main', // about - fireQuestion - messageFuture - memory - profile
  questionType: 'options', // radio - openText - datePicker - endFlow - options
  staticQuestions: {
    firingQuestion:
      'Cool, I’ll keep firing questions at you. Why am I asking you these? Imagine in 100 years time your descendants are having a furious debate about whether you preferred Star Wars or Star Trek. Well, now they’ll know! Right, let’s get to it...',
    finishThisOrThat:
      'Seems you finished all the questions! Anything else you want to do?',
    finishFlow: 'What would you like to do now?',
  },

  options: [
    {
      key: Date.now() / Math.random(),
      text: 'Get to know me',
      action: 'aboutMe',
    },
    {
      key: Date.now() / Math.random(),
      text: 'Answer quick fire questions',
      action: 'fireQuestion',
    },
    {
      key: Date.now() / Math.random(),
      text: 'Send a message to the future',
      action: 'messageFuture',
    },
    // {
    //   key: Date.now() / Math.random(),
    //   text: 'Create a memory',
    //   action: 'memory',
    // },
    // {
    //   key: Date.now() / Math.random(),
    //   text: 'Check out my profile',
    //   action: 'profile',
    // },
  ],
  textRows: 1,
  minTextRows: 1,
  maxTextRows: 3,
  inputActive: false,
  inputValue: '',
  loaded: false,
};

const onBoardingQuestions = {
  initialOnboardingInfo:
    "Welcome! To get started I'd like to ask a few basic questions about yourself.",
};

const mapOrderToField = {
  [initialState.options[2].action]: {
    2: 'message',
    4: 'email',
    3: 'year',
    5: 'year',
    // 4: 'year',
  },
  // [initialState.options[3].action]: {
  //   3: 'memory',
  //   4: 'title',
  //   5: 'year',
  //   6: 'month',
  //   7: 'day',
  // },
};

export const MessageWrapper = props => {
  const { type, text, staticQuestions, flow, image } = props;
  let starterConv = false;

  // check it's the every day start to conversation
  if (flow === 'main') {
    const { initialQuestion, finishFlow } = staticQuestions;
    starterConv = [initialQuestion, finishFlow].includes(text);
  }

  return (
    <S.MessageContainer type={type} starterConv={starterConv}>
      <S.Image src={type === 'user' ? image : robotImg} type={type} />
      <S.Message type={type}>{text}</S.Message>
    </S.MessageContainer>
  );
};

export const OptionWrapper = props => {
  const { option, onClick } = props;

  return (
    <S.OptionContainer onClick={onClick}>
      <S.OptionButton>
        <S.Option>{option}</S.Option>{' '}
        <Icon
          icon="rightArrow"
          width="1em"
          color="var(--neutral-white)"
          margin="0"
        />
      </S.OptionButton>
    </S.OptionContainer>
  );
};

const RadioWrapper = props => {
  const { questions, index, selectionThisOrThat } = props;

  return (
    <>
      <S.SelectionWrapper>
        <S.UpperSelectionWrapper>
          <S.LeftSelection
            color="left"
            onClick={selectionThisOrThat(questions[index].options[0])}
          >
            {questions[index].options[0]}
          </S.LeftSelection>
          <S.RightSelection
            color="right"
            onClick={selectionThisOrThat(questions[index].options[1])}
          >
            {questions[index].options[1]}
          </S.RightSelection>
        </S.UpperSelectionWrapper>
        <S.RightSelection onClick={selectionThisOrThat('Neither')}>
          Neither
        </S.RightSelection>
      </S.SelectionWrapper>
      <Link
        to={THISORTHAT_URL}
        style={{ textDecoration: 'none' }}
      >
        <Button
          variant="contained"
          color="primary"
        >Finish</Button>
      </Link>
    </>
  );
};

export default class Chat extends Component {
  state = {
    ...initialState,
    questions: [], // questions for the flow
    message: [], // all messages
  };

  inputField = React.createRef();

  handleChange = e => {
    const textareaLineHeight = 24;
    const { maxTextRows, minTextRows } = this.state;
    const previousRows = e.target.rows;
    e.target.rows = minTextRows;

    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    if (currentRows >= maxTextRows) {
      e.target.rows = maxTextRows;
      e.target.scrollTop = e.target.scrollHeight;
    }

    this.setState({
      textRows: currentRows < maxTextRows ? currentRows : maxTextRows,
      inputActive: true,
      inputValue: e.target.value,
    });
  };

  sendMsgToUser = msg => {
    this.focusInput();
    return this.setState({
      message: [
        ...this.state.message,
        {
          key: Date.now() / Math.random(),
          type: 'bot',
          text: msg,
        },
      ],
    });
  };

  deactivateInput = () => this.setState({ inputActive: false });

  activateInput = () => this.setState({ inputActive: true });

  // listens for history location state changes
  getSnapshotBeforeUpdate(prevProps) {
    return {
      updateStateReq: prevProps.location.state !== this.props.location.state,
    };
  }

  componentDidMount() {
    // CHECK USER BASIC INFO AND UPDATE STATE ACCODRDINGLY
    axios
      .get(API_PROFILE_BASICS_URL)
      .then(({ data }) => {
        const validUserInfo = data && Object.keys(data).length > 0;
        if (!validUserInfo) {
          const { initialOnboardingInfo } = onBoardingQuestions;
          // set messages
          const onboardingBotMessages = this.state.message.concat([
            {
              key: Date.now() / Math.random(),
              type: 'bot',
              text: initialOnboardingInfo,
            },
          ]);
          // update state
          this.setState({
            message: onboardingBotMessages,
            questionType: 'dialogflow',
            flow: 'initialOnboarding',
            image: imgUser,
            loaded: true,
          });

          // if user has already basic info
        } else {
          // show the main options and display the first message from the bot
          let image = imgUser;
          if (data.image) {
            image = data.image.url;
          }
          this.setState({
            userBasicInfo: data,
            image,
            loaded: true,
            message: [
              {
                key: Date.now() / Math.random(),
                text: `Hey ${
                  data.name.split(' ')[0]
                }, welcome to our Iternal Lab. This is where we put the latest things we're working on for you to be able play with and test.`,
                type: 'bot',
              },
            ],
          });
        }
      })
      .catch(err => console.log(err));

    // takes the user straight to the about flow if coming from the link on the about page
    if (this.props.location.state === 'about') {
      const { options } = initialState;
      this.setState({
        questionType: 'dialogflow',
        flow: options[0].action,
      });
    }
    if (this.props.location.state === 'thisOrThat') {
      const { options } = initialState;
      return this.handleSelectMainOption(options[1].action)();
    }
  }

  // updates state if user decides to 'do something else' coming from about me flows (DF Component)
  // stores conversation
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.storing) this.scrollToBottom();

    if (snapshot.updateStateReq) {
      const { message } = this.state;
      const { location } = this.props;
      // check if redirected from df component
      if (location.state && location.state.conversation) {
        // update state
        const { conversation } = location.state;
        const newMessages = message.concat(conversation);
        this.setState({
          loadingMessage: false,
          storing: false,
          flow: 'main',
          options: initialState.options,
          questionType: 'options',
          questions: [],
          index: 0,
          message: newMessages,
        });
      }
    }
  }

  handleSeletSharedEmail = action => () => {
    // action me || else
    if (action === 'me') {
    } else {
    }
  };

  handleEnter = e => {
    const key = e.key || e.keyCode;
    if (key === 13 || key === 'Enter') {
      this.clickButton.focus();
    }
  };

  focusInput = () => {
    this.inputField.current.focus();
  };

  waitForInput = () => {};

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
  };

  selectionThisOrThat = selection => () => {
    const {
      questions,
      index,
      staticQuestions: { finishThisOrThat },
    } = this.state;
    axios
      .post(API_THISORTHAT_URL, {
        user: this.props.id,
        question: questions[index]._id,
        text: selection,
      })
      .then(({ data }) => {
        const newMessage = [];
        const newState = {};
        if (!questions[index + 1]) {
          // show message when the user finish all the thisOrThat questions
          // show main optinos
          newMessage.push({
            key: Date.now() / Math.random(),
            type: 'bot',
            text: finishThisOrThat,
          });
          // .
          newState.flow = 'main';
          newState.options = initialState.options;
          newState.index = 0;
          newState.questionType = 'options';
        } else {
          // show the next quesiton
          newMessage.push({
            key: Date.now() / Math.random(),
            type: 'bot',
            text: questions[index + 1].text,
          });
        }
        this.setState({
          index: index + 1,
          message: this.state.message.concat([
            {
              key: Date.now() / Math.random(),
              type: 'user',
              text: data.text,
            },
            ...newMessage,
          ]),
          ...newState,
        });
      });
  };

  handleSelectMainOption = action => () => {
    // after select option the option list will disapper
    let newState = {};
    // i take options from initial State so we can change options state
    // when display the years for future message
    const { options, staticQuestions } = initialState;
    const { message, index } = this.state;

    const { text: actionText } = options.find(
      option => option.action === action,
    );
    newState.message = message.concat([
      {
        key: Date.now() / Math.random(),
        type: 'user',
        text: actionText,
      },
    ]);
    // every option have action so we can do the logic
    if (action === options[0].action) {
      // aboutMe
      // update state
      this.setState({
        questionType: 'dialogflow',
        flow: options[0].action,
      });
    } else if (action === options[1].action) {
      // fireQuestion

      newState.loadingMessage = true;
      axios.get(API_THISORTHAT_URL).then(({ data }) => {
        newState = {
          ...newState,
          questions: data,
          loadingMessage: false,
          questionType: 'radio',
          index: 0,
          flow: initialState.options[1].action,
        };
        if (data[0]) {
          newState.message = newState.message.concat([
            {
              key: Date.now() / Math.random(),
              type: 'bot',
              text: staticQuestions.firingQuestion,
            },
            {
              key: Date.now() / Math.random(),
              type: 'bot',
              text: data[0].text,
            },
          ]);
        } else {
          newState.message = newState.message.concat({
            key: Date.now() / Math.random(),
            type: 'bot',
            text: staticQuestions.finishThisOrThat,
          });
          // return to the initialState
          newState.questionType = 'options';
          newState.options = initialState.options;
          newState.flow = 'main';
        }
        this.setState(newState);
      });
      // error handling
    } else if (action === options[2].action) {
      // messageFuture
      axios.get(API_FUTUREMESSAGE_URL).then(({ data }) => {
        const {
          message: newMessages,
          questionType,
          order,
        } = this.consumeQuestions(data, index, true);
        this.setState(
          {
            message: newState.message.concat(newMessages),
            questionType,
            index: order,
            questions: data,
            flow: options[2].action,
          },
          () => {
            // this.focusInput();
          },
        );
      });
    } else if (action === options[3].action) {
      // memory
      const { history } = this.props;
      history.push(CREATE_MEMORY_URL);
    } else if (action === options[4].action) {
      // profile
      const { history } = this.props;

      history.push(BASICS_URL);
    }
    // this.setState(newState);
  };

  finishThisOrThatFlow = () => {
    this.setState({
      flow: 'main',
      options: initialState.options,
      questionType: 'options',
      questions: [],
      index: 0,
      message: this.state.message.concat({
        key: Date.now() / Math.random(),
        type: 'bot',
        text: this.state.staticQuestions.finishFlow,
      }),
    });
  };

  consumeQuestions = (questions, order, typeBefore, message = []) => {
    if (
      (questions[order] && questions[order].questionType === 'noReply') ||
      typeBefore
    ) {
      message.push({
        key: Date.now() / Math.random(),
        type: 'bot',
        text: questions[order].text,
      });
      return this.consumeQuestions(
        questions,
        order + 1,
        questions[order].questionType === 'noReply',
        message,
      );
    }
    return {
      order: message[0] ? order - 1 : order,
      message,
      questionType: questions[message[0] ? order - 1 : order].questionType,
    };
  };

  handleSubmitMessage = async () => {
    // const value = this.refs.inputField.value;
    const {
      flow,
      questionType,
      storedPostRequest,
      index,
      inputValue,
    } = this.state;

    let action;
    let key;
    if (flow === 'memory') {
      action = initialState.options[3].action;
      key = mapOrderToField[action][index + 1];
    } else {
      // futuremessage flow
      action = initialState.options[2].action;
      if (index === 3) {
        // someone else flow
        key = mapOrderToField[action][index];
      } else {
        key = mapOrderToField[action][index];
      }
    }
    const emailSchema = yup
      .string()
      .email('Please enter a valid email')
      .required('Please enter a valid emaill');
    if (inputValue.trim() === '' && index === 2) {
      const message =
        'It looks like you haven’t typed anything or you need to click return to post your message (just like a text message). Once you’re ready to message the future, then click the Finish button.';
      return this.sendMsgToUser(message);
    }
    if (index === 4) {
      try {
        await emailSchema.validate(inputValue);
      } catch (err) {
        const message = 'please enter valid email';
        return this.sendMsgToUser(message);
      }
    }
    if (inputValue.trim() === '') {
      return this.sendMsgToUser(
        'It looks like you haven’t typed anything or you need to click return to post your message (just like a text message). Once you’re ready to message the future, then click the Finish button.',
      );
    }

    // 3-title 4-year 5-month 6-day
    const arrayOne = [3, 4, 5, 6];
    // check for single inputs
    if (arrayOne.includes(index)) {
      if (questionType === 'datePicker') {
        if (+inputValue < 0) {
          return this.sendMsgToUser('Please, enter a valid number!');
        }
        // check for valid year
        if (index === 4) {
          const year = +inputValue;
          if (
            isNaN(year) ||
            inputValue.length !== 4 ||
            year > new Date().getFullYear()
          ) {
            return this.sendMsgToUser(
              'Please enter a valid year (YYYY) or if you would like to skip enter 0000',
            );
          }
          // check for valid month
        } else if (index === 5) {
          const month = +inputValue;
          if (isNaN(month) || inputValue.length > 2 || month > 12) {
            return this.sendMsgToUser(
              'Please enter a valid month (MM) or if you would like to skip enter 00',
            );
          }
          // check for valid day
        } else if (index === 6) {
          const day = +inputValue;
          if (isNaN(day) || inputValue.length > 2 || day > 31) {
            return this.sendMsgToUser(
              'Please enter a valid date (DD) or if you would like to skip enter 00',
            );
          }
        }
      }
    }

    const newState = {
      message: this.state.message.concat({
        key: Date.now() / Math.random(),
        type: 'user',
        text: inputValue,
      }),
      storedPostRequest: {
        ...storedPostRequest,
        [key]: storedPostRequest[key]
          ? Array.isArray(storedPostRequest[key])
            ? storedPostRequest[key].concat(inputValue)
            : [storedPostRequest[key]].concat(inputValue)
          : inputValue,
      },
      textRows: 1,
      inputValue: '',
    };
    this.setState(newState, () => {
      if (arrayOne.includes(index)) {
        this.handleFinishMessage();
      }
    });
    // this.focusInput();
  };

  handleFinishMessage = () => {
    // finish button should work in memory and messageFuture flow;
    const { storedPostRequest, flow, index, message, questions } = this.state;
    const { options } = initialState;
    if (options[2].action === flow) {
      // messageFuture;
      if (index === 1) {
        // someone else flow
        const {
          message: newMessages,
          questionType,
          order,
        } = this.consumeQuestions(questions, index + 1, true);
        const newState = {
          message: message.concat(newMessages),
          questionType,
          index: order,
        };
        if (questionType === 'options') {
          newState.options = this.state.questions[
            newState.index
          ].options.reduce(
            (acc, option) => acc.concat({ action: option[0], text: option }),
            [],
          );
        }
        this.setState(newState);
        return;
      }
      const userMsgs = message.filter(msg => msg.type === 'user');

      // stop the user from entering empty msgs
      if (userMsgs.length === 1) {
        return this.sendMsgToUser(
          'It looks like you haven’t typed anything or you need to click return to post your message (just like a text message). Once you’re ready to message the future, then click the Finish button.',
        );
      }

      const {
        message: newMessages,
        questionType,
        order,
      } = this.consumeQuestions(questions, index + 1, true);

      const newState = {
        message: message.concat(newMessages),
        questionType,
        index: order,
      };
      if (questionType === 'options') {
        newState.options = this.state.questions[newState.index].options.reduce(
          (acc, option) => acc.concat({ action: option[0], text: option }),
          [],
        );
      }
      this.setState(newState);
    } else if (options[3].action === flow) {
      // memery;
      if (
        !storedPostRequest.memory ||
        (!Array.isArray(storedPostRequest.memory) &&
          storedPostRequest.memory.trim() === '')
      ) {
        return this.sendMsgToUser(
          'It looks like you haven’t typed anything or you need to click return to post your message (just like a text message). Once you’re ready to message the future, then click the Finish button.',
        );
      }
      const {
        message: newMessages,
        questionType,
        order,
      } = this.consumeQuestions(questions, index + 1, true);
      const newState = {
        message: message.concat(newMessages),
        questionType,
        index: order,
      };
      this.setState(newState, () => {
        const { storedPostRequest, questionType } = this.state;
        if (questionType === 'endFlow') {
          let memoryToStore = {};
          if (!Array.isArray(storedPostRequest.memory)) {
            memoryToStore = { ...storedPostRequest };
            memoryToStore.memory = [memoryToStore.memory];
          } else {
            memoryToStore = storedPostRequest;
          }

          this.setState({
            storing: true,
            memoryLoading: true,
          });

          axios
            .post(API_PROFILE_MEMORIES_URL, memoryToStore)
            .then(() => {
              this.setState({
                memoryLoading: false,
              });
            })
            .catch(err => console.log(err));
        }
      });
    }
    this.focusInput();
  };

  backToChat = () => {
    this.setState({
      ...initialState,
      loaded: true,
      message: [
        {
          key: Date.now() / Math.random(),
          type: 'bot',
          text: initialState.staticQuestions.finishFlow,
        },
      ],
    });
  };

  handleSelectFutureMessage = actionSelect => () => {
    // select year for future message flow and finsih the flow
    const { storedPostRequest, index, message, questions } = this.state;
    const { action } = initialState.options[2];
    const key = mapOrderToField[action][index];
    if (index === 3) {
      // future flow
      let nextQuestion = {};
      if (actionSelect === 'm') {
        // me flow
        // get questions when select me
        nextQuestion = this.consumeQuestions(questions, index + 2, true);
        // put options from year question
        nextQuestion.options = this.state.questions[index + 2].options.reduce(
          (acc, option) => acc.concat({ action: option[0], text: option }),
          [],
        );
      } else {
        // get questions when select someone esle
        nextQuestion = this.consumeQuestions(questions, index + 1, true);
      }
      const {
        message: newMessages,
        questionType,
        order,
        options,
      } = nextQuestion;
      const newState = {
        message: message.concat(newMessages),
        questionType,
        index: order,
        options,
      };
      this.setState({
        ...newState,
      });
      return;
    }
    const newState = {
      message: this.state.message.concat([
        {
          key: Date.now() / Math.random(),
          type: 'user',
          text: actionSelect,
        },
        {
          key: Date.now() / Math.random(),
          type: 'bot',
          text: this.state.questions[index + 1].text,
        },
      ]),
      storedPostRequest: {
        [key]: actionSelect,
        ...storedPostRequest,
      },
    };
    // send post request future
    this.setState(newState, () => {
      const { year: afterYear, message, email } = this.state.storedPostRequest;
      // get date after selected years
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const afterDate = new Date(year + +afterYear, month, day);

      this.setState({
        storing: true,
        memoryLoading: true,
      });

      axios
        .post(API_FUTUREMESSAGE_URL, { dateToSend: afterDate, message, email })
        .then(() => {
          setTimeout(() => {
            this.setState({
              memoryLoading: false,
            });
          }, 1000);
        });
    });
  };

  render() {
    const {
      options,
      loadingMessage,
      index,
      questions,
      questionType,
      flow,
      staticQuestions,
      textRows,
      inputActive,
      inputValue,
      image,
      memoryLoading,
      storing,
      loaded,
    } = this.state;
    const { id, history } = this.props;
    let { message } = this.state;
    if (loadingMessage) {
      message = message.concat({
        key: Date.now() / Math.random(),
        type: 'bot',
        text: '...',
      });
    }

    return storing ? (
      <Loading
        history={history}
        backToChat={this.backToChat}
        memoryLoading={memoryLoading}
        flow={flow}
      />
    ) : (
      <S.Wrapper>
        <Paper elevation={3} style={{ padding: '2rem' }}>
          <div style={{ width: '100%', marginTop: '-1rem' }}></div>
          {message.map(({ key, type, text }) => (
            <MessageWrapper
              key={`${key}_${text}`}
              type={type}
              image={image}
              text={text}
              flow={flow}
              staticQuestions={staticQuestions}
            />
          ))}
          {questionType === 'dialogflow' && (
            <DialogFlowChat
              user={id}
              flow={flow}
              history={history}
              textRows={textRows}
              activateInput={this.activateInput}
              deactivateInput={this.deactivateInput}
              inputActive={inputActive}
              handleEnter={this.handleEnter}
              message={message}
            />
          )}
          <S.MainOptionsWrapper type={flow}>
            {questionType === 'options' &&
              loaded &&
              options.map((option, index) => (
                <OptionWrapper
                  key={index}
                  option={option.text}
                  onClick={
                    flow === 'main'
                      ? this.handleSelectMainOption(option.action)
                      : // futrueMessage flow
                        this.handleSelectFutureMessage(option.action)
                  }
                />
              ))}
          </S.MainOptionsWrapper>
          {questionType === 'radio' && (
            <RadioWrapper
              index={index}
              questions={questions}
              selectionThisOrThat={this.selectionThisOrThat}
              finishFlow={this.finishThisOrThatFlow}
            />
          )}
          {(questionType === 'openText' || questionType === 'datePicker') && (
            <>
              {/* <S.FinishButton onClick={this.handleFinishMessage}>
                finish
              </S.FinishButton> */}
              {flow === 'memory' && index >= 3 ? null : index === 4 &&
                flow === 'messageFuture' ? null : (
                <S.ButtonsWrapper>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleFinishMessage}
                  >Finish</Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleFinishMessage}
                  >Cancel</Button>
                </S.ButtonsWrapper>
              )}
              <S.InputWrapper inputActive={inputActive}>
                <S.InputChat
                  ref={this.inputField}
                  autoFocus
                  placeholder="Type here..."
                  rows={textRows}
                  onChange={this.handleChange}
                  onFocus={this.activateInput}
                  onBlur={this.deactivateInput}
                  inputActive={inputActive}
                  value={inputValue}
                  onKeyDown={this.handleEnter}
                />
                <S.Button
                  onClick={this.handleSubmitMessage}
                  inputActive={inputActive}
                  ref={el => {
                    this.clickButton = el;
                  }}
                >
                  <Icon
                    icon="send"
                    width="2em"
                    margin="0"
                    // color="var(--primary)"
                  />
                </S.Button>
              </S.InputWrapper>
            </>
          )}
          <div
            style={{ float: 'left', clear: 'both' }}
            ref={el => {
              this.messagesEnd = el;
            }}
          />
        </Paper>
      </S.Wrapper>
    );
  }
}
