// React
import React, { Component } from 'react';

// MUI
import { Button, Paper, Typography } from '@material-ui/core';

// Constants
import { SIGNUP_URL } from '../../../constants/constantUrls';

// API
import { linkTokens } from '../../../api';

// Assets
import loader from '../../../assets/loader.svg';
import imagePlaceHolder from '../../../assets/person-placeholder.jpg';

// Styles
import * as S from './style';

// Components
import HeaderLogout from '../../Common/HeaderLogout';
import HeaderShareMemory from '../../Common/HeaderShareMemory';
import MemoriesTable from '../../Common/Tables/MemoriesTable';
import { NewTimeline } from '../../../NewComponents/Dashboard/Blocks/NewTimeline/NewTimeline';
import ReactGA from 'react-ga';

export default class ShareMyFriendMemories extends Component {
  state = {
    memory: {},
    isLoading: true,
    friendName: '',
    friendMemories: [],
    friendImage: {},
    isList: false,
  };

  componentDidMount = async () => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    const { shortId } = this.props.match.params;
    const getFriendMemories = async () => {
      const {
        data,
        error,
      } = await linkTokens.getSharedUserInfoAndMemoriesAndByToken({
        shortId,
      });
      if (error) {
        console.log(error);
      }
      this.setState({ friendMemories: data.user.memories });
      if (data.user.abouts[0].image) {
        this.setState({ friendImage: data.user.abouts[0].image });
      }
      this.setState({ friendName: data.user.name, isLoading: false });
    };
    getFriendMemories();
  };

  renderMemoryOrNothing = () =>
    this.state.friendMemories && this.state.friendMemories.length > 0 ? (
      <MemoriesTable memories={this.state.friendMemories} noLogin />
    ) : (
      <div
        className="is-flex is-flex-direction-column is-justify-content-center"
        style={{
          height: '100%',
          width: '80%',
          margin: 'auto',
          textAlign: 'center',
          color:'white',
        }}
      >
        <p
          style={{ marginBottom: '20px', fontSize: '20px', lineHeight: '24px', color:'white' }}
        >
          {`${this.state.friendName} doesn't seem to have any public memories! Get
          them to share their profile with you so you can see their private
          memories.`}
        </p>
        <Button
          isPrimaryButton
          color="secondary"
          onClick={() => this.props.history.push(SIGNUP_URL)}
        >
          Sign Up Now
        </Button>
      </div>
    );

  renderTimelineOrNothing = () =>
    this.state.friendMemories && this.state.friendMemories.length > 0 ? (
      <Paper style={{ padding: '2rem' }}>
        <div
          style={{
            height: '58vh', 
            overflow: 'auto', 
            position: 'initial', 
            background: 'hsla(178, 88%, 36%, 0.08)',
            borderRadius: '1rem 1rem 0 0'
          }}
        >
          <NewTimeline
            memories={this.state.friendMemories}
            showPrompts={false}
            noLogin={true}
            viewingFriendsMemory={true}
          />
        </div>
      </Paper>
    ) : (
      <div
        className="is-flex is-flex-direction-column is-justify-content-center"
        style={{
          height: '100%',
          width: '80%',
          margin: 'auto',
          textAlign: 'center',
        }}
      >
        <p
          style={{ marginBottom: '20px', fontSize: '20px', lineHeight: '24px', color:'white' }}
        >
          {this.state.friendName} doesn&apos;t seem to have any public
          memories! Get them to share their profile with you so you can see
          their private memories.
        </p>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => this.props.history.push(SIGNUP_URL)}
        >
          Sign Up Now
        </Button>
      </div>
    );

  // TODO: Render memory on page and style.
  // include links to login and signup for mobile
  // removed View Memory title in HeaderLogout
  render() {
    const { windowWidth } = this.props;
    const { friendName, friendImage, friendMemories, isList } = this.state;

    return (
      <div>
        <HeaderLogout title="" windowWidth={windowWidth} />
        {this.state.isLoading ? (
          <img alt="loader" src={loader} />
        ) : (
          <>
            <S.Main>
              <HeaderShareMemory
                nameMemoryOwner={friendName}
                contentType="sharePublic"
              />
              <Paper
                style={{
                  padding: '2rem',
                  textAlign: 'center',
                  marginBottom: '1rem',
                }}
              >
                <div>
                  <img
                    alt="Your avatar"
                    src={`${friendImage.imagePath || imagePlaceHolder}`}
                    style={{
                      width: '100px',
                      height: '100px',
                      borderRadius: '999rem',
                      display: 'inline-block',
                      margin: '0 auto !important',
                    }}
                  />
                  <Typography variant="h4">{friendName}</Typography>
                  <Typography variant="body1">
                    {friendMemories.length}{' '}
                    {friendMemories.length === 1 ? 'memory' : 'memories'}
                  </Typography>
                </div>
              </Paper>
              <div>
                <div>
                  {/* {friendMemories && friendMemories.length > 1 && (
                    <S.MemoryButtonWrapper>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.setState({ isList: true })}
                      >
                        View memory list
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.setState({ isList: false })}
                      >
                        View memory timeline
                      </Button>
                    </S.MemoryButtonWrapper>
                  )} */}
                  {isList
                    ? this.renderMemoryOrNothing()
                    : this.renderTimelineOrNothing()}
                </div>
              </div>
            </S.Main>
          </>
        )}
      </div>
    );
  }
}
