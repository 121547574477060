// React
import React, { useState, useEffect } from 'react';

// Styled Components
import { Main } from './style';
import PromptPicker from '../../Common/PromptPicker';
import BackArrow from '../../../assets/prompts/backcircle.png';
import history from '../../../history';

import ReactGA from 'react-ga';
import { Typography } from '@material-ui/core';

export default (props) => {
  return (
    <Main>
      <img 
        style={{
          height: window.innerWidth < 600 ? "35px" :'50px', 
          width: window.innerWidth < 600 ? "35px" :'50px',                       
          display: window.innerWidth > 450 ? "block" : "none",
          cursor: 'pointer',
          zIndex: 2,
          marginLeft:'1rem'
        }}
        onClick={() => history.goBack()}
        src={BackArrow}
      />
      <Typography 
        align="center"  
        style={{
          color: "white",       
          fontSize: window.innerWidth < 1024 ? "3rem" : "4rem", marginTop: window.innerWidth < 800 ? "0rem" :'-4rem', marginBottom:'1rem',
        }}
      >
        Story Prompts
      </Typography>
      <div style={{marginRight: window.innerWidth < 1024 ? "0.5rem" :'2rem', marginLeft: window.innerWidth < 1024 ? "0.5rem" :'4rem'}}>
      <PromptPicker chosenCategory={props?.location?.state?.category || 'all'} />
      </div>
    </Main>
  );
};
