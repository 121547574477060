import styled from 'styled-components';

export const TitleStyle = {
  lineHeight: 1.25,
  paddingBottom: '2rem',
};

export const SubtitleStyle = {
  lineHeight: 1.25,
  marginBottom: '1.5rem',
};

export const MainPaperStyle = {
  padding: '2rem',
  maxWidth: '600px',
  margin: '0.5rem auto 1rem',
  textAlign: 'center',
};

export const BlurbStyle = {
  lineHeight: 1.25,
  marginBottom: '1.5rem',
};

export const InputFieldSpacing = styled.div`
  margin-bottom: 0.25rem;
`;

export const DateSelectorWrapper = styled.div`
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.025);
  padding: 2rem;
  margin: 1rem;

  .dateDisplay {
    border-radius: 0.5rem;
    text-align: center !important;
    margin: 0 auto !important;
    background: rgba(255, 255, 255, 0.5) !important;
    padding: 1rem;
  }
`;
