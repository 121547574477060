import React from 'react';

const Arrow = props => (
  <svg
    width={props.height}
    height={props.width}
    viewBox="0 0 38 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2322 39.2678C18.2085 40.2441 19.7915 40.2441 20.7678 39.2678L36.6777 23.3579C37.654 22.3816 37.654 20.7986 36.6777 19.8223C35.7014 18.846 34.1184 18.846 33.1421 19.8223L19 33.9645L4.85786 19.8223C3.88155 18.846 2.29864 18.846 1.32233 19.8223C0.346019 20.7986 0.346019 22.3816 1.32233 23.3579L17.2322 39.2678ZM16.5 3.05979e-08L16.5 37.5L21.5 37.5L21.5 -3.05981e-08L16.5 3.05979e-08Z"
      fill={props.color || 'currentColor'}
    />
  </svg>
);

export default Arrow;
