import React from 'react';

const Trash = ({ width, height, color, theme }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2298 2.875H12.2188V0.71875C12.2188 0.528126 12.143 0.345309 12.0082 0.210517C11.8734 0.0757252 11.6906 0 11.5 0H5.75C5.55938 0 5.37656 0.0757252 5.24177 0.210517C5.10698 0.345309 5.03125 0.528126 5.03125 0.71875V2.875H0.0202147L0 4.67188H1.48242L2.3849 18.7773C2.40774 19.1421 2.56867 19.4845 2.83495 19.7348C3.10124 19.9852 3.45288 20.1247 3.81836 20.125H13.4316C13.7969 20.125 14.1485 19.9858 14.4149 19.736C14.6813 19.4861 14.8426 19.1441 14.866 18.7796L15.7676 4.67188H17.25L17.2298 2.875ZM5.03125 17.25L4.62695 5.75H6.10938L6.51367 17.25H5.03125ZM9.34375 17.25H7.90625V5.75H9.34375V17.25ZM10.4219 2.875H6.82812V1.61719C6.82812 1.56953 6.84706 1.52383 6.88075 1.49013C6.91445 1.45643 6.96016 1.4375 7.00781 1.4375H10.2422C10.2898 1.4375 10.3355 1.45643 10.3692 1.49013C10.4029 1.52383 10.4219 1.56953 10.4219 1.61719V2.875ZM12.2188 17.25H10.7363L11.1406 5.75H12.623L12.2188 17.25Z"
      fill={color ? color : 'white'}
    />
  </svg>
);

export default Trash;
