import React from "react";

import advice from "../../../assets/groups/advice.png";
import group70s from "../../../assets/groups/70s.png";
import group80s from "../../../assets/groups/80s.png";
import group90s from "../../../assets/groups/90s.png";
import groupPets from "../../../assets/groups/pets.png";

// Components
import { Paper, Typography, Button, Grid, Avatar } from "@material-ui/core";

export default function GroupPublic({}) {
  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        xs={12}
        spacing={3}
        style={{
          padding: "1rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "center",
          // paddingLeft: window.innerWidth < 600 ? "2rem" : "1.5rem",
          // paddingRight: window.innerWidth < 600 ? "2rem" :"1.5rem",
        }}
      >
        <Grid
          item
          spacing={3}
          style={{
            padding: "0.5rem",
          }}
          className="scale"
        >
          <a
            style={{ cursor: "pointer", textDecoration: "none" }}
            href="https://www.iternal.app/group/9HaK_tzpW"
          >
            <img
              src={advice}
              style={{
                borderRadius: "20px",
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 1.6px",
                maxHeight:'220px'
              }}
            />
          </a>
        </Grid>
        <Grid item spacing={3}>
          <a
            style={{ cursor: "pointer", textDecoration: "none" }}
            href="https://www.iternal.app/group/CiEzAd3cD"
          >
            <img
              src={group70s}
              style={{
                borderRadius: "20px",
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 1.6px",
                maxHeight:'220px'
              }}
            />
          </a>
        </Grid>
        <Grid item spacing={3}>
          <a
            style={{ cursor: "pointer", textDecoration: "none" }}
            href="https://www.iternal.app/group/ZxPEkUwHa"
          >
            <img
              src={group80s}
              style={{
                borderRadius: "20px",
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 1.6px",
                maxHeight:'220px'
              }}
            />
          </a>
        </Grid>
        <Grid item spacing={2}>
          <a
            style={{ cursor: "pointer", textDecoration: "none" }}
            href="https://www.iternal.app/group/o0XZen448"
          >
        <img
              src={group90s}
              style={{
                borderRadius: "20px",
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 1.6px",
                maxHeight:'220px'
              }}
            />
          </a>
        </Grid>
        <Grid item spacing={3}>
          <a
            style={{ cursor: "pointer", textDecoration: "none" }}
            href="https://www.iternal.app/group/RvzeLNCpN"
          >
        <img
              src={groupPets}
              style={{
                borderRadius: "20px",
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 1.6px",
                maxHeight:'220px',
   
              }}
            />
          </a>
        </Grid>
      </Grid>
    </div>
  );
}
