import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import { permissionAccess, users, memories } from '../../../api';

import { SuccessScreenOther } from '../../../NewComponents/Pages/SuccessScreen/SuccessScreenOther';

import {
  MEMORIES_URL,
} from '../../../constants/constantUrls';
import {
  SuccessPageWrapper,
} from './style';
import ReactGA from 'react-ga';

const Sucess = props => {
  const [newMemory, setNewMemory] = useState({});
  
  const fetchNewMemory = async () => {
    const {
      data,
      error,
    } = await memories.getMemoryById({id: props.location.state._id});
    if (!error) {
      setNewMemory(data);

    }
  }

  useEffect(() => {
    fetchNewMemory();
    ReactGA.pageview(window.location.pathname + window.location.search)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history, props.name]);

  if (props.location.state === undefined) {
    return <Redirect to={MEMORIES_URL} />;
  }

  return(
    <SuccessPageWrapper>
      <SuccessScreenOther memory={newMemory} userName={props.location.state.userName} shortId={props.location.state.shortId} isLoggedIn={props.isLoggedIn} />
    </SuccessPageWrapper>
  )
};

export default Sucess;
