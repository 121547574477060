import styled from "styled-components";

export const CarouselStyles = styled.div`
  /* Iternal Styles - Container */
  .carousel {
    width: 100%;
    max-height: 20rem !important;
    overflow: hidden;
    margin: 0 auto !important;
    background: rgba(0, 20, 120, 0.05);
  }

  /* Iternal Styles */
  /* Photos */
  .photoWrapper {
    aspect-ratio: 16 / 9;
    display: flex !important;
    align-items: center !important;
    width: 100%;
    user-select: none;
    overflow: hidden;
    width: 100% !important;
    max-height: 20rem;
    .photoMain {
      display: block;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 10 !important;
      width: auto !important;
      height: 100% !important;
    }
    .photoBackgroundBlur {
      z-index: 5;
      position: relative;
      margin-top: -20rem;
      width: 100%;
      height: 100%;
      overflow: hidden !important;
      filter: blur(2rem);
      transform: scale(1.5);
      opacity: 0.85 !important;
    }
  }

  .slide {
    background: black;
  }

  /* Video */
  /* TODO: The ideal solution here would be to get a random frame of the video and blur it as a background */
  .videoWrapper {
    aspect-ratio: 16 / 9;
    width: 100%;
    video {
      width: 100%;
      height: auto;
      max-height: 20rem;
      padding-bottom: 1.75rem;
      background: black;
    }
  }

  /* Voice */
  .voiceWrapper {
    aspect-ratio: 4 / 1;
    height: 100%;
    display: block;
    width: 100%;
    background: #00000;
    min-height: 12rem;
    ${"" /* background: linear-gradient(180deg, #000000, #030b1f, #061333,  #1d6a91,  #0bafaa,  #1d6a91,  #061333, #030b1f, #000000); */}
    ${"" /* background: linear-gradient(180deg, #0A2156, #1d6a91,  #0bafaa); */}
    // audio {
    //   width: 82.5%;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   box-shadow: 0px 0px 2rem rgba(0, 0, 0, 0.1);
    //   border-radius: 999rem;
    // }
    border-bottom: 2px solid var(--primary-tangerine);
    .voiceSpectrum {
    }

    .rhap_container {
      height: 100%;
      padding: 2rem 3rem;
      @media (max-width: 500px) {
        padding: 1rem 1.5rem;
      }

    }

    .rhap_button-clear {
      color: var(--primary-tangerine);
      overflow: visible;

    }
    .rhap_play-pause-button {
      width: 5rem;
      height: 5rem;
      svg {
        width: 5rem;
        height: 5rem;
      }
    }

    .rhap_progress-indicator {
      background: var(--primary-tangerine);
    }

    .rhap_volume-indicator {
      background: var(--primary-tangerine);
    }

    .rhap_progress-filled {
      background: var(--primary-tangerine);
    }

    .rhap_progress-section {
      align-items: flex-end;
      flex: 1 1 auto;
      margin-bottom: 1rem;
    }

    .rhap_controls-section {
      align-items: flex-start;
      flex: 1 1 auto;
      margin-top: 1rem;
    }

    .rhap_progress-container {
      height: 100%;
      display: flex;
      align-items: flex-end;
      padding-bottom: 0.3rem;
    }

    .rhap_additional-controls {
      margin-top: 1.7rem;
      justify-content: space-evenly;
    }

    .rhap_volume-controls {
      margin-top: 1.7rem;
      justify-content: space-evenly;
    }

  }

  /* BUILT-IN NPM PACKAGE STYLES - DO NOT TOUCH! */
  /* These were converted from minified css to a styled component to prevent style overrides */
  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    -webkit-transition: all 0.25s ease-in;
    -moz-transition: all 0.25s ease-in;
    -ms-transition: all 0.25s ease-in;
    -o-transition: all 0.25s ease-in;
    transition: all 0.25s ease-in;
    opacity: 0.4;
    filter: alpha(opacity=40);
    position: absolute;
    z-index: 2;
    top: 20px;
    background: none;
    border: 0;
    font-size: 32px;
    cursor: pointer;
  }
  .carousel .control-arrow:focus,
  .carousel .control-arrow:hover {
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .carousel .control-arrow:before,
  .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: "";
  }
  .carousel .control-disabled.control-arrow {
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: inherit;
    display: none;
  }
  .carousel .control-prev.control-arrow {
    left: 0;
  }
  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #fff;
  }
  .carousel .control-next.control-arrow {
    right: 0;
  }
  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #fff;
  }
  .carousel-root {
    outline: none;
  }
  .carousel {
    position: relative;
    width: 100%;
  }
  .carousel * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none;
  }
  .carousel .carousel {
    position: relative;
  }
  .carousel .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
    margin-top: -13px;
    font-size: 18px;
  }
  .carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
  }
  .carousel .thumbs {
    -webkit-transition: all 0.15s ease-in;
    -moz-transition: all 0.15s ease-in;
    -ms-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap;
  }
  .carousel .thumb {
    -webkit-transition: border 0.15s ease-in;
    -moz-transition: border 0.15s ease-in;
    -ms-transition: border 0.15s ease-in;
    -o-transition: border 0.15s ease-in;
    transition: border 0.15s ease-in;
    display: inline-block;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px;
  }
  .carousel .thumb:focus {
    border: 3px solid #ccc;
    outline: none;
  }
  .carousel .thumb.selected,
  .carousel .thumb:hover {
    border: 3px solid #333;
  }
  .carousel .thumb img {
    vertical-align: top;
  }
  .carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden;
  }
  .carousel.carousel-slider .control-arrow {
    top: 0;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
    background: rgba(0, 0, 0, 0.1);
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    -webkit-transition: height 0.15s ease-in;
    -moz-transition: height 0.15s ease-in;
    -ms-transition: height 0.15s ease-in;
    -o-transition: height 0.15s ease-in;
    transition: height 0.15s ease-in;
  }
  .carousel .slider-wrapper.axis-horizontal .slider {
<<<<<<< HEAD
    minHeight: 120px;
=======
    minheight: 120px;
>>>>>>> f1df75ca5fcd2380a5a3453c2291c046d7b465b3
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
  }
  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column;
  }
  .carousel .slider-wrapper.axis-vertical {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
  }
  .carousel .slider-wrapper.axis-vertical .slider {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%;
  }
  .carousel .slider.animated {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
  }
  .carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
  }
  .carousel .slide iframe {
    display: inline-block;
    width: calc(100% - 80px);
    margin: 0 40px 40px;
    border: 0;
  }
  .carousel .slide .legend {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    opacity: 0.25;
    -webkit-transition: opacity 0.35s ease-in-out;
    -moz-transition: opacity 0.35s ease-in-out;
    -ms-transition: opacity 0.35s ease-in-out;
    -o-transition: opacity 0.35s ease-in-out;
    transition: opacity 0.35s ease-in-out;
  }
  .carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1;
    opacity: ${(props) => (props.isSingle ? "0" : "1")};
  }
  @media (min-width: 960px) {
    .carousel .control-dots {
      bottom: 0;
    }
  }
  .carousel .control-dots .dot {
    -webkit-transition: opacity 0.25s ease-in;
    -moz-transition: opacity 0.25s ease-in;
    -ms-transition: opacity 0.25s ease-in;
    -o-transition: opacity 0.25s ease-in;
    transition: opacity 0.25s ease-in;
    opacity: 0.3;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    background: #fff;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
  }
  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color: #fff;
  }
  .carousel:hover .slide .legend {
    opacity: 1;
  }
`;
