import React from 'react';

const Copy = ({ width }) => (
  <svg
    width={width || '27'}
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13.5" cy="13.5" r="13.5" fill="#EAEAEA" />
    <path
      d="M17.9238 9.75H11.4199C10.4976 9.75 9.75 10.4976 9.75 11.4199V17.9238C9.75 18.8461 10.4976 19.5938 11.4199 19.5938H17.9238C18.8461 19.5938 19.5938 18.8461 19.5938 17.9238V11.4199C19.5938 10.4976 18.8461 9.75 17.9238 9.75Z"
      stroke="#B0B0B0"
      strokeWidth="0.9375"
      strokeLinejoin="round"
    />
    <path
      d="M17.2354 9.75L17.25 9.04688C17.2488 8.61213 17.0755 8.19555 16.7681 7.88814C16.4607 7.58073 16.0441 7.40749 15.6094 7.40625H9.28125C8.78442 7.40772 8.30836 7.60573 7.95705 7.95705C7.60573 8.30836 7.40772 8.78442 7.40625 9.28125V15.6094C7.40749 16.0441 7.58073 16.4607 7.88814 16.7681C8.19555 17.0755 8.61213 17.2488 9.04688 17.25H9.75"
      stroke="#B0B0B0"
      strokeWidth="0.9375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Copy;
