import styled from 'styled-components';
import { breakpointsMax} from '../../../constants/breakPoints';

export const Input = styled.input`
  border: ${({ error }) =>
    error ? 'var(--input-error-border)' : 'var(--input-border)'};
  width: 100%;
  font-weight: 400;
  outline: none;
  transition: all ease 0.2s;
  background: none;
  padding: 15px;
  border-width: 2px;
  border-radius:5px;

  :active,
  :focus {
    border: var(--input-focus-border);
  }
`;

export const Label = styled.label`
  /* hide label but keep for accessibility */
  position: absolute !important;
  opacity: 0;
  pointer-events: none;
`;

export const ErrorMsg = styled.p`
  color: var(--error);
  font-size: 14px;
  align-self: flex-end;
  padding-top: 0.25rem;
`;

export const Row = styled.div`
  margin-bottom: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Fieldset = styled.fieldset`
  padding: 2rem;
  margin: 0 auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
  color: #4a4a4a;
  display: block;
  padding: 3.25rem;
  @media ${breakpointsMax.tablet}  {
      padding: 2rem;
    }
`;

export const Message = styled.p`
  text-align:center;
  color: var(--primary);
  margin-bottom: 2rem;
  font-size: ${props => props.size || '1.5rem'};
  line-height: 2rem;  
`;

export const LogoWrapper = styled.section`
  width: 100%;
  display: flex;
`;

export const H1 = styled.h1`
  font-size: 38px;
  font-weight: 900;
  position: absolute;
  bottom: 0;
  line-height: 2.1rem;
  color: var(--white);
  padding-left: 1rem;
`;

export const Header = styled.header`
  /* height: 155px; */
  /* width: 100vw;
  padding: 1rem 1rem 0 1rem;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem; */
`;

export const Main = styled.main`
  padding: 2rem 3rem 1rem 280px;

@media ${breakpointsMax.laptop}  {
  padding: 2rem 1rem 1rem 1rem;
}
`;

export const Button = styled.button`
  padding-bottom: 1rem;
  border: none;
  background: none;
  align-self: flex-start;
  cursor: pointer;
  button {
    width:100%;
  }

`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    width:100%;
    margin-top:1rem;
  }
`;