import React, { Component } from 'react';
import { FourOhFour } from '../../../NewComponents/Pages/404/404';

export default class Error404 extends Component {
  state = {};

  render() {
    return <FourOhFour />;
  }
}
