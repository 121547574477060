import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: 38px;
  font-weight: 900;
  position: absolute;
  bottom: 0;
  line-height: 2.1rem;
  color: var(--white);
  padding-left: 1rem;
`;

export const Header = styled.header`
  /* height: 155px;
  width: 100vw;
  padding: 1rem 1rem 0 1rem;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem; */
`;

export const LogoWrapper = styled.section`
  /* width: 100%; */
  width: 7rem;
  display: flex;
`;
