import BackspaceOutlinedIcon from "@material-ui/icons/BackspaceOutlined";
import BackspaceSharpIcon from "@material-ui/icons/ArrowBack";
import React from "react";

export default function BackArrow({ clickFunction = () => {} }) {
  return (
    <div
      style={{ paddingRight: "2rem", marginTop: "1rem" }}
      onClick={() => clickFunction()}
      style={{ cursor: "pointer" }}
    >
      <BackspaceSharpIcon
        style={{ fontSize: window.innerWidth < 700 ? "32px" : "48px", color: "white", marginLeft: "1rem" }}
      />
      <h1
        style={{
          textAlign: "left",
          marginLeft: window.innerWidth < 700 ?  "1rem" : "1.5rem",
          color: "white",
        }}
      >
        {" "}
        Back{" "}
      </h1>
    </div>
  );
}