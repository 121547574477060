import axios from 'axios';

import { API_UPLOAD_SIGNED_URL } from '../constants/apiRoutes';
import makeError from './makeError';

import { initializeApp } from 'firebase/app';
import { ref, getStorage, uploadBytesResumable } from 'firebase/storage';

var firebaseConfig = {
  apiKey: "AIzaSyAFqjUozf8wsklRcrAkFpVndXeu-81YGIw",
  authDomain: "death-io.firebaseapp.com",
  databaseURL: "https://death-io.firebaseio.com",
  storageBucket: "death-io.appspot.com",
  messagingSenderId: '1064666944078',
};

initializeApp(firebaseConfig);

const storage = getStorage()

const generateFileName = ({ isProfile, userId, extension, isAudio, isVideo }) => {
  // set the container file => profiles || memories/audio ||  memories/image
  let baseName = ""
  if (isProfile) {
    baseName = 'profiles';
  } else if (isAudio) {
    baseName = 'memories/audio';
  } else if (isVideo) {
    baseName = 'memories/video';
  } else {
    baseName = 'memories/image';
  }
  baseName = `${baseName}/${userId}/${Date.now()}${extension}`;

  const generatedName = encodeURI(baseName);
  return generatedName;
};

const uploadFile = async (fileName, file) => {
  // get signed url from google
  const {
    data: { signedUrl /* , bucketName */ },
  } = await axios.get(`${API_UPLOAD_SIGNED_URL}?fileName=${fileName}`);

  const headers = {
    'Content-Type': 'application/octet-stream',
  };
  await axios.put(signedUrl, file, { headers });
};

const uploadVideo = async ({ video, userId, isProfile, options }, setUploadProgress) => {
  try {
    if (video) {
      if (video.size > 2e8) {
        return {
          error: makeError(new Error('File too large, "max size 200MB"'), options),
        };
      }
      const generatedName = generateFileName({
        isProfile,
        userId,
        extension: video?.name?.replace(/ /g, '') || '.mp4',
      });
      const storageRef = ref(storage, generatedName);
      const uploadTask = uploadBytesResumable(storageRef, video)
      //initiates the firebase side uploading 
      uploadTask.on('state_changed', 
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        const percentage = (snapShot.bytesTransferred / snapShot.totalBytes  * 100)
        setUploadProgress(Math.round(percentage));
      }, (err) => {
        console.log(err)
      }, () => {
        console.log('done')
  
      })
      return {
        data: {
          generatedName,
        },
      };
    }
  } catch (error) {
    return { error: makeError(error, options) }
  }
}

// uploads image file to firebase
const uploadImage = async ({ image, userId, isProfile, options }) => {
  try {
    if (!image) {
      return {
        error: makeError(new Error('please upload a file'), options),
      };
    }
    if (image.size > 2e7) {
      return {
        error: makeError(new Error('File too large, "max size 20MB"'), options),
      };
    }

    const generatedName = generateFileName({
      isProfile,
      userId,
      extension: image.name.replace(/ /g, ''),
    });

    await uploadFile(generatedName, image);

    return {
      data: {
        generatedName,
      },
    };
  } catch (error) {
    return { error: makeError(error, options) };
  }
};

const firebase = { 
  uploadImage, 
  generateFileName, 
  uploadFile, 
  uploadVideo
};

export default firebase;
