// React
import React from 'react';

// MUI
import { 
  Slide, 
  Fade, 
  Typography,
  Button 
} from '@material-ui/core';

// Style
import { PolaroidWrapper } from './PolaroidPhoto.style';

// Images
import placeholder from '../images/river.webp';

export const PolaroidPhoto = ({
  image,
  width,
  slideIn = true,
  date,
  deleteFunction=()=>{},
  editFunction=()=>{},
  isOwner=false,
  title = '',
  altText = 'Polaroid',
  ...props
}) => (
  <>
    {slideIn ? (
      <Slide in timeout={2500} >
        <PolaroidWrapper {...props}>
          <img style={{ width }} src={image || placeholder} alt={altText} />
        </PolaroidWrapper>
      </Slide>
    ) : (
      <Fade in timeout={2500}>
        <PolaroidWrapper {...props}>
          {/* {title && (
            <Typography gutterBottom>{title}</Typography>
          )} */}
          <img style={{maxWidth:"100%"  }} src={image || placeholder} alt={altText} />
          {/* <Typography>{date}</Typography>
          {isOwner && (
          <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
            <Button
              onClick={() => deleteFunction()}
            >
              Delete
            </Button>
            <Button
              onClick={() => editFunction()}
            >
              Edit
            </Button>
          </div>
        )} */}
        </PolaroidWrapper>

      </Fade>
    )}
  </>

);
