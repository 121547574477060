// React
import React from 'react';
import PropTypes from 'prop-types';
import history from "../../../../../history"

// MUI
import {
  Typography,
  CircularProgress,
  LinearProgress,
  Grid,
} from '@material-ui/core';

// URLs
import { GO_PREMIUM } from '../../../../../constants/constantUrls';

// Styled components
import { RemainingAllowanceStyles } from './RemainingAllowance.style';
import PremiumButton from '../../../../../Components/Common/PremiumButton';

export const RemainingAllowance = ({
  contentType,
  isPremium,
  used,
  totalAvailable,
  ...props
}) => {
  const isLoading = used === undefined || totalAvailable === undefined;
  const noAllowanceLeft = used === totalAvailable;

  const renderRemainingText = () => {
    if (isPremium) {
      return (
        <>
          You can store <span className="emphasis">unlimited stories</span> as
          a premium member
          <div style={{height:"1rem"}}></div>
        </>
      );
    }
    if (used === 0) {
      return (
        <>
          You have{' '}
          <span className="emphasis">
            {' '}
            all {totalAvailable - used} {contentType}
          </span>{' '}
          available
        </>
      );
    }
    if (noAllowanceLeft) {
      return (
        <>
         <span className="emphasis" style={{fontSize:'1.2rem',  }}>You have ran out of free {contentType}</span> <br></br>
    <PremiumButton onClick={() => history.push(GO_PREMIUM)} style={{marginTop: '1rem', marginBottom: '1rem'}}/>
      Upgrade to Premium to record unlimited Stories </>
    
      );
    }
    const amountLeft = totalAvailable - used;
    return (
      <>
    <span className="emphasis" style={{fontSize:'1.2rem',  }}>You have {amountLeft > 0 ? amountLeft : 0} {contentType} remaining</span> <br></br>
    <PremiumButton onClick={() => history.push(GO_PREMIUM)} style={{marginTop: '1rem', marginBottom: '1rem'}}/>
      Upgrade to Premium to record unlimited Stories </>
      
      );
    }

  return (
    
    <RemainingAllowanceStyles {...props}>
     <Grid container>
            {noAllowanceLeft === false && (
              <>
                <Grid item xs={8} sm={10} md={11}>
                  <LinearProgress
                    className="linearProgress"
                    variant="determinate"
                    color="secondary"
                    value={isPremium ? 100 : (used / totalAvailable) * 100}
                  />
                </Grid>
                <Grid item xs={4} sm={2} md={1}>
                  <Typography variant="body2">
                    {used} / {isPremium ? '∞' : totalAvailable} used
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
      {isLoading ? (
        <CircularProgress color="secondary" className="circularProgress" />
      ) : (
        <>
          <Typography className="allowanceText" align="center" variant="body1">
            {renderRemainingText()}
          </Typography>
         
        </>
      )}
    </RemainingAllowanceStyles>
  );
};

RemainingAllowance.propTypes = {
  /**
   * What is this allowance counter measuring? (eg. `memories`)
   */
  contentType: PropTypes.oneOf([
    'stories',
    'milestones',
    'this or thats',
    'future emails',
    'colourizations',
    'days of premium',
  ]),

  /**
   * How much of this content has been used?
   */
  used: PropTypes.number,

  /**
   * Does this user have a currently active Premium membership?
   */
  isPremium: PropTypes.bool,

  /**
   * How much is the total available allowance for this type of content
   */
  totalAvailable: PropTypes.number,
};

RemainingAllowance.defaultProps = {
  contentType: 'stories',
  isPremium: false,
  used: undefined,
  totalAvailable: undefined,
};
