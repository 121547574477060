import React from 'react';

const MemoryZigzag = ({ width, color }) => (
  <svg
    width={width}
    height="18"
    viewBox="0 0 100 18"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="-1"
      x2="20.4676"
      y2="-1"
      transform="matrix(0.705608 -0.708602 0.705608 0.708602 2 16.002)"
      stroke={color || '#66CC66'}
      strokeWidth="2"
    />
    <line
      y1="-1"
      x2="20.4686"
      y2="-1"
      transform="matrix(-0.705608 -0.708602 0.705608 -0.708602 30.3882 15.5039)"
      stroke={color || '#66CC66'}
      strokeWidth="2"
    />
    <line
      y1="-1"
      x2="20.4676"
      y2="-1"
      transform="matrix(0.705608 -0.708602 0.705608 0.708602 29.8525 16.501)"
      stroke={color || '#66CC66'}
      strokeWidth="2"
    />
    <line
      y1="-1"
      x2="20.4686"
      y2="-1"
      transform="matrix(-0.705608 -0.708602 0.705608 -0.708602 58.2407 16.0029)"
      stroke={color || '#66CC66'}
      strokeWidth="2"
    />
    <line
      y1="-1"
      x2="20.4676"
      y2="-1"
      transform="matrix(0.705608 -0.708602 0.705608 0.708602 57.7053 16.501)"
      stroke={color || '#66CC66'}
      strokeWidth="2"
    />
    <line
      y1="-1"
      x2="20.4686"
      y2="-1"
      transform="matrix(-0.705608 -0.708602 0.705608 -0.708602 86.0935 16.0029)"
      stroke={color || '#66CC66'}
      strokeWidth="2"
    />
    <line
      y1="-1"
      x2="20.4676"
      y2="-1"
      transform="matrix(0.705609 -0.708602 0.705609 0.708602 85.5579 17)"
      stroke={color || '#66CC66'}
      strokeWidth="2"
    />
  </svg>
);

export default MemoryZigzag;
