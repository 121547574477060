import React from 'react';

const Pencil = ({ width, color, theme }) => (
  <svg
    width={width}
    height="24"
    viewBox="0 0 24 24"
    fill="#222"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8103 6.05957L4.05422 18.8471L3.28125 20.7183L5.1525 19.9454L17.94 7.18926L16.8103 6.05957Z"
      stroke="#222"
      strokeWidth="2.0625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.363 3.50795L18.8103 4.06014L19.94 5.18983L20.4926 4.63717C20.6377 4.49205 20.7191 4.29529 20.7191 4.09014C20.7191 3.88499 20.6377 3.68823 20.4926 3.54311L20.4575 3.50795C20.3856 3.43608 20.3003 3.37907 20.2064 3.34017C20.1125 3.30127 20.0119 3.28125 19.9102 3.28125C19.8086 3.28125 19.7079 3.30127 19.614 3.34017C19.5201 3.37907 19.4348 3.43608 19.363 3.50795Z"
      stroke="#222"
      strokeWidth="2.0625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Pencil;
